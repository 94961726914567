import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import {
    Splash,
    Colors,
    Container,
    Alert,
    Sizes,
    Column,
    CheckBox,
    PrimaryButton,
    SecondaryButton,
    getScreenSize,
    isMobile as tellIfMobile,
    getPageValidationInfo
} from 'metrix-common';

import ProfileSummaryMenu from '../CommonComponents/Menu';
import ProfileSummaryHeader from './Header';
import ProfileSummaryAction from './Action';
import ProfileSummaryQuotes from '../CommonComponents/Quotes';
import ProfileSummaryRisks from '../CommonComponents/Risks';
import ProfileSummaryRecap from '../CommonComponents/Recap';
import Signature from '../CommonComponents/Signature';

const DecisionButton = styled(Container)`
    justify-content: center;
`;

const AbsoluteContainer = styled.div`
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translate(50%, 0);
`;

const RelativeContainer = styled.div`
    width: 100%;
    position: relative;
`;

class ProfileSummary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            documentAccepted: false,
            displaySignaturePad: false,
            paymentEmail: null
        };
    };

    getOperationMessages = () => {
        if (!this.props.context.operation || (this.props.context.operation && (!this.props.context.operation.errors && !this.props.context.operation.warnings && !this.props.context.operation.confirmations))) {
            return null;
        }

        let hasErrors = (this.props.context.operation.errors && this.props.context.operation.errors.length > 0);
        let hasWarnings = (this.props.context.operation.warnings && this.props.context.operation.warnings.length > 0);
        let hasConfirmations = (this.props.context.operation.confirmations && this.props.context.operation.confirmations.length > 0);

        let mode = hasErrors ? `danger` : hasWarnings ? `info` : ``;
        let header = hasErrors ? <FormattedMessage id={`app.components.CardList.ErrorCard.errorMessage`} /> : hasWarnings ? <FormattedMessage id={`app.components.Profile.Summary.warning`} /> : null;

        let messages = [];

        if (hasErrors) {
            this.props.context.operation.errors.forEach(item => messages.push({ code: item.code, value: item.value ? item.value : item.code, isError: true }));
        }

        if (hasWarnings) {
            this.props.context.operation.warnings.forEach(item => messages.push({ code: item.code, value: item.value ? item.value : item.code, isError: false }));
        }

        if (hasConfirmations) {
            this.props.context.operation.confirmations.forEach(item => messages.push({ code: item.code, value: item.value ? item.value : item.code, isError: false }));
        }

        return (<Alert mode={mode} header={header} items={messages} />);
    }

    closeSignaturePad = () => {
        this.setState({ displaySignaturePad: false });
    }

    openSignaturePad = () => {
        this.setState({ displaySignaturePad: true });
    }

    render() {
        let baseProps = {
            ...this.state,
            ...this.props
        };

        let screenSize = getScreenSize();

        const isMobile = tellIfMobile(screenSize);
        let isClient = (this.props.context.session.userType === `client`);
        let clientStatus = this.props.policySummary && this.props.policySummary.status;
        let hasSignature = this.props.policySummary && this.props.policySummary.hasSignature;
        
        const { error, warning } = getPageValidationInfo(this.props.pageCode, this.props.validationInfo);

        return (
            this.props.policySummary &&
            <Splash color={Colors.LightMode}>
                <ProfileSummaryHeader {...this.props} />
                <Container right={Sizes.xs} top={Sizes.md} left={Sizes.xs}>                    
                    {
                        (this.props.context.session.userType === 'broker' || this.props.context.session.userType === 'administrator') &&
                        <Container fullwidth bottom={Sizes.md}>

                        {/*// TODO: Fix absolutely positioned ProfileSummaryMenu to put it over line*/}
                        {/*<RelativeContainer fullwidth bottom={Sizes.md}>*/}
                        {/*    <AbsoluteContainer>*/}
                                <ProfileSummaryMenu {...this.props} />
                            {/*</AbsoluteContainer>*/}
                            {this.getOperationMessages()}
                            {(this.props.policySummary.messages && this.props.policySummary.messages.length > 0) &&
                                <Alert
                                    header={<FormattedMessage id={`app.components.Profile.Summary.attention`} />}
                                    items={this.props.policySummary.messages} 
                                />
                            }
                            {
                                error.message.length !== 0 &&
                                <Alert
                                    mode='danger'
                                    header={error.title}
                                    items={error.message}
                                />
                            }
                            {
                                warning.message.length !== 0 &&
                                <Alert
                                    mode='warning'
                                    header={warning.title}
                                    items={warning.message}
                                />
                            }
                        </Container>
                    }
                    <ProfileSummaryAction {...this.props} />                    
                    {
                        (this.props.policySummary.status === 'quoteAccepted' ||
                        this.props.policySummary.status === 'quoteIssued' ||
                        this.props.policySummary.status === 'bindRequested' ||
                        this.props.policySummary.status === 'bound' ||
                        this.props.policySummary.status === 'issued') &&
                        <ProfileSummaryRecap {...this.props} />
                    }
                    <ProfileSummaryRisks {...this.props} risks={this.props.policySummary.risks} />
                    {
                        this.props.policySummary.status === 'quoted' &&
                        <ProfileSummaryQuotes {...this.props} />
                    }
                </Container>
            </Splash>
        );
    }
}

export default ProfileSummary;