import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    BackButton,
    Form,
    AddressForm,
    FormInput,
    FormSwitch
} from 'metrix-common';

class ProfileInterwestFp2AdditionalLocation extends React.Component {
    state = {
        mode: 'locationView',
    };

    changeAddress = (dotProp, value) => {

        if (dotProp === `address`) {
            this.props.doReducePolicyElement({ code: `${this.props.dotProp}address`, value })
        } else {
            this.props.doReducePolicyElement({ code: `${this.props.dotProp}address.${dotProp}`, value })
        }
    }

    getLocationName = () => {
        let locationName = null;

        if (this.props.location && this.props.location.locationName) {
            locationName = `${this.props.location.locationName}`;
        }
        else
        {
            locationName = 'New Location';
        }

        return locationName;
    }

    saveLocationHandler = () => {
        this.props.doUpsertPolicy();
    }

    render() {
        let locationName = this.getLocationName();
        return (
            <React.Fragment>
                {
                    this.state.mode === 'locationView' &&
                    <React.Fragment>
                        <BackButton clickEvent={this.props.backEvent} />
                        <Form
                            rowId={this.props.rowId}
                            pageCode={this.props.pageCode}
                            sectionCode='locationView'
                            saveTooltipInfo={this.props.saveTooltipInfo}
                            reduceTooltipInfoElement={this.props.reduceTooltipInfoElement}
                            tooltipInfo={this.props.tooltipInfo}
                            validationInfo={this.props.validationInfo}
                            header={<FormattedMessage id={locationName} />}
                            label={'Details'}
                            saveEvent={this.saveLocationHandler}
                            cancelEvent={this.props.doGetPolicy}
                            allowEdit={this.props.allowEdit}
                        >
                                <FormInput
                                    fieldCode='facilitySquareFootage'
                                    label={<FormattedMessage id={'Square feet (sq ft)'} />}
                                    value={this.props.location?.facilitySquareFootage}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}facilitySquareFootage`, value })}
                                    allowEdit
                                />
                                <FormInput
                                    fieldCode='estimateYearOccupiedBuildingBuilt'
                                    label={<FormattedMessage id={'What is the estimated year built of the building you occupy?'} />}
                                    value={this.props.location?.estimateYearOccupiedBuildingBuilt}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}estimateYearOccupiedBuildingBuilt`, value })}
                                    allowEdit
                                />
                                <FormInput
                                    fieldCode='employeeCount'
                                    label={<FormattedMessage id={'Employees at this location'} />}
                                    value={this.props.location?.employeeCount}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}employeeCount`, value })}
                                    allowEdit
                                />
                                <FormInput
                                    fieldCode='totalAnnualSalesAmount'
                                    label={<FormattedMessage id={'What are your total annual sales?'} />}
                                    value={this.props.location?.totalAnnualSalesAmount}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}totalAnnualSalesAmount`, value })}
                                    allowEdit
                                />
                                <FormSwitch
                                    fieldCode='hasBuildingSprinklerSystem'
                                    label={<FormattedMessage id={'Does your building have a sprinkler system?'} />}
                                    value={this.props.location?.hasBuildingSprinklerSystem}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasBuildingSprinklerSystem`, value: value })}
                                    allowEdit
                                />
                        </Form>
                        <AddressForm
                            label={'Location Address'}
                            header={<FormattedMessage id={'Address Details'} />}
                            sectionCode='addressDetails'
                            rowId={this.props.rowId}
                            pageCode={this.props.pageCode}
                            validationInfo={this.props.validationInfo}
                            enableAddressSearch
                            addressSearchCountryCode={'USA'}
                            changeEvent={(dotProp, value) => this.changeAddress(dotProp, value)}
                            saveEvent={this.saveLocationHandler}
                            cancelEvent={this.props.doGetPolicy}
                            reference={this.props.reference}
                            address={this.props.location && this.props.location.address}
                            allowEdit={this.props.allowEdit}
                            {...this.props}
                        />
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default ProfileInterwestFp2AdditionalLocation;