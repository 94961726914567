import React from 'react';

import Questionnaire from './Questionnaire';

class LiabilityExposures extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            <React.Fragment>
                <Questionnaire {...this.props} />
            </React.Fragment>
        );
    }
}

export default LiabilityExposures;