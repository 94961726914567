import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Form,
    FormDropDown,
    FormInput, FormSwitch
} from 'metrix-common';

class ProfileInterwestGlpcLocation extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            <>
                <Form
                    header={<FormattedMessage id='Location' />}
                    label='Details'
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormDropDown
                        label={<FormattedMessage id='Are you the owner or tenant?' />}
                        value={{ code: this.props.policy.interWestGLPCCustomInfo.locationInfo.ownerOrTenant }}
                        references={this.props.reference?.references['InterWest-GLPC'] && this.props.reference.references['InterWest-GLPC'].OwnerOrTenant}
                        referencesEvent={() => this.props.reference.doGetReferences('InterWest-GLPC.OwnerOrTenant')}
                        changeEvent={value => {
                            this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.locationInfo.ownerOrTenant', value: value.code });
                        }}
                        allowEdit
                    />
                    <FormDropDown
                        label={<FormattedMessage id='Inside or Outside city limits?' />}
                        value={{ code: this.props.policy.interWestGLPCCustomInfo.locationInfo.insideOrOutsideCityLimits }}
                        references={this.props.reference?.references['InterWest-GLPC'] && this.props.reference.references['InterWest-GLPC'].InsideOrOutsideCityLimits}
                        referencesEvent={() => this.props.reference.doGetReferences('InterWest-GLPC.InsideOrOutsideCityLimits')}
                        changeEvent={value => {
                            this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.locationInfo.insideOrOutsideCityLimits', value: value.code });
                        }}
                        allowEdit
                    />
                    <FormInput
                        label='Year Built:'
                        value={this.props.policy.interWestGLPCCustomInfo.locationInfo.buildingBuiltYear}
                        type='number'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.locationInfo.buildingBuiltYear', value })}
                        maxValue={9999}
                        allowEdit
                    />
                    <FormInput
                        label='# Stories:'
                        value={this.props.policy.interWestGLPCCustomInfo.locationInfo.buildingStoriesCount}
                        type='number'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.locationInfo.buildingStoriesCount', value })}
                        maxValue={999}
                        allowEdit
                    />
                    <FormDropDown
                        label={<FormattedMessage id='Construction Type:' />}
                        value={{ code: this.props.policy.interWestGLPCCustomInfo.locationInfo.constructionTypeCode }}
                        references={this.props.reference?.references['InterWest-GLPC'] && this.props.reference.references['InterWest-GLPC'].ConstructionType}
                        referencesEvent={() => this.props.reference.doGetReferences('InterWest-GLPC.ConstructionType')}
                        changeEvent={value => {
                            this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.locationInfo.constructionTypeCode', value: value.code });
                        }}
                        allowEdit
                    />
                    <FormDropDown
                        label={<FormattedMessage id='Roof Type:' />}
                        value={{ code: this.props.policy.interWestGLPCCustomInfo.locationInfo.roofTypeCode }}
                        references={this.props.reference?.references['InterWest-GLPC'] && this.props.reference.references['InterWest-GLPC'].RoofType}
                        referencesEvent={() => this.props.reference.doGetReferences('InterWest-GLPC.RoofType')}
                        changeEvent={value => {
                            this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.locationInfo.roofTypeCode', value: value.code });
                        }}
                        allowEdit
                    />
                    <FormInput
                        label='Total Building Sq. Ft.'
                        value={this.props.policy.interWestGLPCCustomInfo.locationInfo.buildingSquareFeetTotal}
                        type='number'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.locationInfo.buildingSquareFeetTotal', value })}
                        allowEdit
                    />
                    <FormInput
                        label='Sq. Ft. of area occupied by your business:'
                        value={this.props.policy.interWestGLPCCustomInfo.locationInfo.buildingOccupiedSquareFeetTotal}
                        type='number'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.locationInfo.buildingOccupiedSquareFeetTotal', value })}
                        allowEdit
                    />
                </Form>
                <Form
                    header={<FormattedMessage id='Updated' />}
                    label='Details'
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormSwitch
                        label={<FormattedMessage id='Have you had prior Property Coverage for the last 3 years?' />}
                        value={this.props.policy.interWestGLPCCustomInfo.locationInfo.isBuildingOverFifteenYearsOld ?? false}
                        changeEvent={
                            value => {
                                this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.locationInfo.isBuildingOverFifteenYearsOld', value: value });
                            }}
                        allowEdit
                    />
                    {
                        this.props.policy.interWestGLPCCustomInfo.locationInfo.isBuildingOverFifteenYearsOld &&
                        <FormInput
                            label='Roof'
                            value={this.props.policy.interWestGLPCCustomInfo.locationInfo.updatedRoofYear}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.locationInfo.updatedRoofYear', value })}
                            maxValue={9999}
                            allowEdit
                        />
                    }
                    {
                        this.props.policy.interWestGLPCCustomInfo.locationInfo.isBuildingOverFifteenYearsOld &&
                        <FormInput
                            label='Plumbing'
                            value={this.props.policy.interWestGLPCCustomInfo.locationInfo.updatedPlumbingYear}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.locationInfo.updatedPlumbingYear', value })}
                            maxValue={9999}
                            allowEdit
                        />
                    }
                    {
                        this.props.policy.interWestGLPCCustomInfo.locationInfo.isBuildingOverFifteenYearsOld &&
                        <FormInput
                            label='Electrical'
                            value={this.props.policy.interWestGLPCCustomInfo.locationInfo.updatedElectricalYear}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.locationInfo.updatedElectricalYear', value })}
                            maxValue={9999}
                            allowEdit
                        />
                    }
                    {
                        this.props.policy.interWestGLPCCustomInfo.locationInfo.isBuildingOverFifteenYearsOld &&
                        <FormInput
                            label='Heating'
                            value={this.props.policy.interWestGLPCCustomInfo.locationInfo.updatedHeatingYear}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.locationInfo.updatedHeatingYear', value })}
                            maxValue={9999}
                            allowEdit
                        />
                    }
                    {
                        this.props.policy.interWestGLPCCustomInfo.locationInfo.isBuildingOverFifteenYearsOld &&
                        <FormInput
                            label='AC'
                            value={this.props.policy.interWestGLPCCustomInfo.locationInfo.updatedACYear}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.locationInfo.updatedACYear', value })}
                            maxValue={9999}
                            allowEdit
                        />
                    }
                </Form>
                <Form
                    header={<FormattedMessage id='Home Based' />}
                    label='Details'
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormSwitch
                        label={<FormattedMessage id='Is business located at home?' />}
                        value={this.props.policy.interWestGLPCCustomInfo.locationInfo.isHomeBasedBusiness ?? false}
                        changeEvent={
                            value => {
                                this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.locationInfo.isHomeBasedBusiness', value: value });
                            }}
                        allowEdit
                    />
                    {
                        this.props.policy.interWestGLPCCustomInfo.locationInfo.isHomeBasedBusiness &&
                        <FormInput
                            label='Provide the amount of square footage that is used in the home to operate your business.'
                            value={this.props.policy.interWestGLPCCustomInfo.locationInfo.updatedRoofYear}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.locationInfo.updatedRoofYear', value })}
                            allowEdit
                        />
                    }
                </Form>
                <Form
                    header={<FormattedMessage id='Security and Fire' />}
                    label='Details'
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormDropDown
                        label={<FormattedMessage id='Burglar Alarm System Type' />}
                        value={{ code: this.props.policy.interWestGLPCCustomInfo.locationInfo.burglarAlarmCode }}
                        references={this.props.reference?.references['InterWest-GLPC'] && this.props.reference.references['InterWest-GLPC'].BurglarAlarms}
                        referencesEvent={() => this.props.reference.doGetReferences('InterWest-GLPC.BurglarAlarms')}
                        changeEvent={value => {
                            this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.locationInfo.burglarAlarmCode', value: value.code });
                        }}
                        allowEdit
                    />
                    <FormDropDown
                        label={<FormattedMessage id='Fire Alarm System Type' />}
                        value={{ code: this.props.policy.interWestGLPCCustomInfo.locationInfo.fireAlarmCode }}
                        references={this.props.reference?.references['InterWest-GLPC'] && this.props.reference.references['InterWest-GLPC'].FireAlarms}
                        referencesEvent={() => this.props.reference.doGetReferences('InterWest-GLPC.FireAlarms')}
                        changeEvent={value => {
                            this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.locationInfo.fireAlarmCode', value: value.code });
                        }}
                        allowEdit
                    />
                    <FormInput
                        label='Approximate distance in feet to the closest fire hydrant'
                        value={this.props.policy.interWestGLPCCustomInfo.locationInfo.feetToFireHydrant}
                        type='number'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.locationInfo.feetToFireHydrant', value })}
                        maxValue={99999}
                        allowEdit
                    />
                    <FormInput
                        label='Responding Fire Department'
                        value={this.props.policy.interWestGLPCCustomInfo.locationInfo.respondingFireDepartment}
                        type='text'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.locationInfo.respondingFireDepartment', value })}
                        allowEdit
                    />
                    <FormInput
                        label='Distance to responding fire department in miles.'
                        value={this.props.policy.interWestGLPCCustomInfo.locationInfo.milesToFireDepartment}
                        type='number'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.locationInfo.milesToFireDepartment', value })}
                        maxValue={999}
                        allowEdit
                    />
                </Form>
            </>
        );
    }
}

export default ProfileInterwestGlpcLocation;