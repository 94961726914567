import { put, call } from 'redux-saga/effects';
import { reduceOperation, reduceLoading, getSessionSaga } from 'metrix-common';
import { reduceBoardPolicies } from '../../actions/policies/reduceBoardPolicies';

export function* getBoardPoliciesSaga(action) {
    try {
        yield put(reduceLoading({ code: 'getBoardPolicies', value: true }));
        const session = yield getSessionSaga();

        const response = yield call(fetch, '/api/policyTransaction/policies', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Authorization': session.authenticationToken.token,
                'Content-Type': 'application/json'
            }
        });
        
        let result = yield call([response, 'json']);
        yield put(reduceOperation(result.operation));
        
        if (!result.operation.status) return;
        
        yield put(reduceBoardPolicies(result.policyBoardItems));
    }
    catch (error) {
        console.log('Error while calling Get Board Policies API - ', error);
    }
    finally {
        yield put(reduceLoading({ code: 'getBoardPolicies', value: false }));
    }
}
