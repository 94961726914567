import React from 'react';
import { FormattedMessage } from 'react-intl';

import {    
    BackButton,
    Form, 
    FormInput,
    FormDropDown
} from 'metrix-common';

class ProfileBtisWcClassification extends React.Component {
    state = {
        mode: `classificationView`,
        current: null
    };

    getClassificationName = () => {
        const classificationName = this.props.classification?.classificationCode !== undefined ? this.props.classification?.classificationName : this.props.classification?.classificationPageName !== undefined ? this.props.classification.classificationPageName : 'New Classification';

        return classificationName;
    }

    render() {
        return (
            <React.Fragment>
                {this.state.mode === `classificationView` &&
                    <React.Fragment>
                        <BackButton clickEvent={this.props.backEvent} />                        
                        <Form
                            header={<FormattedMessage id={this.getClassificationName()} />}
                            label={`Classification Details`}
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            allowEdit={this.props.allowEdit}
                        >
                        <FormDropDown
                            label={<FormattedMessage id={`Classification`} />}
                            value={this.props.classification?.classificationCode && { code: this.props.classification.classificationCode, value: this.props.classification?.classificationName }}
                            references={this.props.reference?.references[`BTIS-WC`] && this.props.reference.references[`BTIS-WC`][`Classifications`][this.props.locationStateCode]}
                            referencesEvent={() => this.props.reference.doGetReferences(`BTIS-WC.Classifications.${this.props.locationStateCode}`)}
                            changeEvent={(value) => {
                                this.props.doReducePolicyElement({ code: `${this.props.dotProp}classificationCode`, value: value.code });
                                this.props.doReducePolicyElement({ code: `${this.props.dotProp}classificationName`, value: value.value });
                            }}
                            allowEdit
                        />
                        <FormInput
                            label={<FormattedMessage id={`Payroll Amount`} />}
                            value={this.props.classification?.payrollAmount}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}payrollAmount`, value })}
                            type='currency'
                            allowEdit
                        />
                        <FormInput
                            label={<FormattedMessage id={`Number of Full Time Employees`} />}
                            value={this.props.classification?.fullTimeEmployeesCount}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}fullTimeEmployeesCount`, value })}
                            type='number'
                            allowEdit
                        />
                        <FormInput
                            label={<FormattedMessage id={`Number of Part Time Employees`} />}
                            value={this.props.classification?.partTimeEmployeesCount}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}partTimeEmployeesCount`, value })}
                            type='number'
                            allowEdit
                        />
                        </Form>  
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default ProfileBtisWcClassification;