import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Form, FormInput
} from 'metrix-common';

class ProfileInterwestPlAuthorizedSigner extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            <>
                <Form
                    header={<FormattedMessage id='Authorized Signer'/>}
                    label='Authorized Representative'
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormInput
                        label='Authorized Representative Full Name'
                        value={this.props.policy.interWestPLCustomInfo.authorizationInfo?.authorizedRepresentativeFullName || ''}
                        type='text'
                        changeEvent={value => this.props.doReducePolicyElement({
                            code: 'interWestPLCustomInfo.authorizationInfo.authorizedRepresentativeFullName',
                            value
                        })}
                    />
                    <FormInput
                        label='Authorized Representative Title'
                        value={this.props.policy.interWestPLCustomInfo.authorizationInfo?.authorizedRepresentativeTitle || ''}
                        type='text'
                        changeEvent={value => this.props.doReducePolicyElement({
                            code: 'interWestPLCustomInfo.authorizationInfo.authorizedRepresentativeTitle',
                            value
                        })}
                    />
                    <FormInput
                        label='Authorized Representative Email Address'
                        value={this.props.policy.interWestPLCustomInfo.authorizationInfo?.authorizedRepresentativeEmailAddress || ''}
                        type='text'
                        changeEvent={value => this.props.doReducePolicyElement({
                            code: 'interWestPLCustomInfo.authorizationInfo.authorizedRepresentativeEmailAddress',
                            value
                        })}
                    />
                </Form>
                <Form
                    label='Executive Director'
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormInput
                        label='Executive Director Full Name'
                        value={this.props.policy.interWestPLCustomInfo.authorizationInfo?.executiveDirectorFullName || ''}
                        type='text'
                        changeEvent={value => this.props.doReducePolicyElement({
                            code: 'interWestPLCustomInfo.authorizationInfo.executiveDirectorFullName',
                            value
                        })}
                    />
                    <FormInput
                        label='Executive Director Title'
                        value={this.props.policy.interWestPLCustomInfo.authorizationInfo?.authorizedRepresentativeTitle || ''}
                        type='text'
                        changeEvent={value => this.props.doReducePolicyElement({
                            code: 'interWestPLCustomInfo.authorizationInfo.authorizedRepresentativeTitle',
                            value
                        })}
                    />
                </Form>
            </>
        );
    }
}

export default ProfileInterwestPlAuthorizedSigner;