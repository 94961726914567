export const GET_POLICY = 'GET_POLICY';
export const GET_LOGS = 'GET_LOGS';
export const GET_LOG_CONTENT = 'GET_LOG_CONTENT';
export const GET_POLICY_SUMMARY = 'GET_POLICY_SUMMARY';
export const GET_POLICY_VALIDATION_INFO = 'GET_POLICY_VALIDATION_INFO';
export const GET_TOOLTIP_INFO = 'GET_TOOLTIP_INFO';
export const QUERY_POLICY = 'QUERY_POLICY';
export const EXECUTE_POLICY_ACTION = 'EXECUTE_POLICY_ACTION';
export const EXECUTE_POLICY_TRANSACTION_CUSTOM_ACTION = 'EXECUTE_POLICY_TRANSACTION_CUSTOM_ACTION';
export const UPSERT_POLICY = 'UPSERT_POLICY';
export const UPSERT_TOOLTIP_INFO = 'UPSERT_TOOLTIP_INFO';
export const REDUCE_POLICY = 'REDUCE_POLICY';
export const REDUCE_POLICY_SUMMARY = 'REDUCE_POLICY_SUMMARY';
export const REDUCE_PRODUCT_SETTINGS = 'REDUCE_PRODUCT_SETTINGS';
export const REDUCE_POLICY_HISTORY = 'REDUCE_POLICY_HISTORY';
export const REDUCE_POLICY_LOGS = 'REDUCE_POLICY_LOGS';
export const REDUCE_POLICY_LOG_CONTENT = 'REDUCE_POLICY_LOG_CONTENT';
export const REDUCE_POLICY_ELEMENT = 'REDUCE_POLICY_ELEMENT';
export const REDUCE_ENTITY = 'REDUCE_ENTITY';
export const REDUCE_PARAMETER = 'REDUCE_PARAMETER';
export const REDUCE_PARAMETERS = 'REDUCE_PARAMETERS';
export const REDUCE_POLICY_VALIDATION_INFO = 'REDUCE_POLICY_VALIDATION_INFO';
export const REDUCE_TOOLTIP_INFO = 'REDUCE_TOOLTIP_INFO';
export const REDUCE_TOOLTIP_INFO_ELEMENT = 'REDUCE_TOOLTIP_INFO_ELEMENT';
export const GET_OPTA = 'GET_OPTA';
export const REDUCE_ALLOW_EDIT = 'REDUCE_ALLOW_EDIT';
export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST';
export const UPDATE_PLACE = 'UPDATE_PLACE';
export const REDUCE_RISK_CHANGED = 'REDUCE_RISK_CHANGED';
export const REDUCE_NOTES = 'REDUCE_NOTES';
export const REDUCE_SINGLE_NOTE = 'REDUCE_SINGLE_NOTE';
export const GET_NOTES = 'GET_NOTES';
export const UPSERT_NOTE = 'UPSERT_NOTE';
