import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
    Column,
    Grid,
    Row,
    Sizes,
    Alignments,
    Container,
    Divider,
    DropDown,
    Header,
    Document,
    PrimaryButton,
    UploadButton,
    getScreenSize, CheckBox, isMobile, GenericModal, Label, Colors, IconButton, RenewalSmallIcon
} from 'metrix-common';

const CustomContainer = styled(Container)`
    height: 10rem;
    padding: 0 1rem;
    border: 1px solid ${Colors.StrokeDark};
    border-radius: 5px;
    overflow-y: auto;
`;

const productCodeMap = {
    'flux-gl': [{ code: 'GENERATEPDF', value: 'PDF form' }, {
        code: 'CREATEEXCELDOCUMENT',
        value: 'Locations (Excel)'
    }],
    'interwest-fp': [{
        code: 'GENERATEAPPLICATIONSUMMARYDOCUMENT',
        value: 'Application Summary'
    }, { code: 'CREATEEXCELDOCUMENT', value: 'Location Information' }],
    'interwest-fp2': [{ code: 'GENERATEAPPLICATIONSUMMARYDOCUMENT', value: 'Application Summary' }],
    'btis-wc': [{
        code: 'GENERATEAPPLICATIONSUMMARYDOCUMENT',
        value: 'Application Summary'
    }, { code: 'CREATEEXCELDOCUMENT', value: 'Other Information' }],
    'interwest-cyber': [{
        code: 'GENERATEAPPLICATIONSUMMARYDOCUMENT',
        value: 'Application Summary'
    }, { code: 'GENERATEPDF', value: 'PDF form' }],
    'interwest-glpc': [{
        code: 'GENERATEAPPLICATIONSUMMARYDOCUMENT',
        value: 'Application Summary'
    }, { code: 'GENERATEPDF', value: 'PDF form' }],
    'interwest-pl': [{
        code: 'GENERATEAPPLICATIONSUMMARYDOCUMENT',
        value: 'Application Summary'
    }, { code: 'GENERATEPDF', value: 'PDF form' }],
    'employers-wc': [{
        code: 'GENERATEAPPLICATIONSUMMARYDOCUMENT',
        value: 'Application Summary'
    }, { code: 'CREATEEXCELDOCUMENT', value: 'Other Information' }],
    'employers-wc-2': [{
        code: 'GENERATEAPPLICATIONSUMMARYDOCUMENT',
        value: 'Application Summary'
    }, { code: 'CREATEEXCELDOCUMENT', value: 'Other Information' }]
};

const CheckmarkIcon = ({ color }) => (
    <svg width='33'
         height='32'
         viewBox='0 0 33 32'
         fill={color}
         xmlns='http://www.w3.org/2000/svg'
    >
        <path d='M32.641 1.25231C32.3463 0.854486 31.9485 0.618738 31.4475 0.545067C30.2835 0.48613 28.8101 1.09023 27.0272 2.35738C24.1983 4.67066 21.3693 7.26389 18.5403 10.1371L11.269 18.2704C7.92427 16.517 5.29421 15.4709 3.37875 15.132C2.21474 15.0141 1.44856 15.2941 1.08021 15.9718C0.682381 16.6496 0.689748 17.2169 1.10231 17.6737C3.35665 19.7217 5.33104 21.954 7.02548 24.3704C8.71992 26.7721 10.1786 29.1295 11.4016 31.4428C11.6226 31.7522 11.8804 31.8996 12.1751 31.8848C12.4698 31.8554 12.7276 31.6712 12.9487 31.3323C14.702 27.0299 16.7354 22.8969 19.0486 18.9334C20.581 16.3255 22.1428 14.0638 23.7341 12.1483C24.8834 10.5865 26.1579 9.08357 27.5577 7.63962L32.0001 3.32984C32.0001 3.31511 32.0074 3.30774 32.0222 3.30774C32.0369 3.27827 32.059 3.2488 32.0885 3.21934C32.2653 2.98359 32.42 2.76994 32.5526 2.5784C32.8326 2.0627 32.862 1.62067 32.641 1.25231Z' />
    </svg>
);

const ProfileDocuments = (props) => {
    const [generateFileCode, setGenerateFileCode] = useState({ code: 'GENERATEPDF', value: 'Generate PDF form' });
    const [showSendEmailModal, setShowSendEmailModal] = useState(false);
    const [showGenerateDocumentModal, setShowGenerateDocumentModal] = useState(false);
    const [showSharingModal, setShowSharingModal] = useState(false);
    const [sharedDocuments, setSharedDocuments] = useState({});
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const searchParams = new URLSearchParams(window.location.search);
    const entityFromSearch = searchParams.get('entity');
    const entity = props.entity || entityFromSearch;

    const mobile = isMobile(getScreenSize());
    const allowUpload = props.context.session.userType === 'administrator' || props.context.session.userType === 'broker';

    useEffect(() => {
        props.document.doReduceDocumentQueryElement({ code: 'relatedEntity', value: entity });
    }, []);

    useEffect(() => {
        props.document.doQueryDocuments();
    }, [props.riskChanged]);

    useEffect(() => {
        if (!props.document.shareableDocuments && (showSharingModal || showSendEmailModal)) {
            props.document.doQueryShareableDocuments(entity);
        }
    }, [showSharingModal, showSendEmailModal]);

    const product = props.policy?.product;
    const references = productCodeMap[product];

    const getDocuments = type => {
        let documents = [];
        if (props.document?.documents?.length > 0 && props.document.documents.some(x => x.type === type)) {
            documents = props.document.documents.filter(x => x.type === type);
        }
        return documents;
    }

    const generateDocumentBtnClick = () => {
        setShowGenerateDocumentModal(true);
    };

    const sendEmailBtnClick = () => {
        setShowSendEmailModal(true);
    };

    const handleShareBtnClick = () => {
        setShowSharingModal(true);
    };

    const handleSharingApply = () => {
        const documentSharingProperties = Object.keys(sharedDocuments).map(guid => ({
            documentGuid: guid,
            isShared: sharedDocuments[guid]
        }));

        props.document.doShareUnshareDocuments({
            policyTransactionGuid: props.entity,
            documentSharingProperties
        });

        props.document.doQueryDocuments()
        handleSharingModalClose();
        window.location.reload();
    };

    const handleGenerateDocument = () => {
        props.doExecutePolicyAction({ code: generateFileCode.code });
        setShowGenerateDocumentModal(false);
    }

    const handleSendEmailModalClose = () => {
        setShowSendEmailModal(false);
        setSelectedTemplate(null);
        setSelectedDocuments([]);
    };

    const handleGenerateDocumentModalClose = () => {
        setShowGenerateDocumentModal(false);
        setGenerateFileCode('')
    };

    const handleSharingModalClose = () => {
        setSharedDocuments({});
        setShowSharingModal(false);
    };

    const handleTemplateSelect = (template) => {
        setSelectedTemplate(template);
    };

    const handleDocumentSelect = (documentId) => {
        setSelectedDocuments(prevState => {
            if (prevState.includes(documentId)) {
                return prevState.filter(id => id !== documentId);
            } else {
                return [...prevState, documentId];
            }
        });
    };

    const handleDocumentShare = (guid, status) => {
        setSharedDocuments(prevState => ({
                ...prevState,
                [guid]: status
            })
        );
    };

    const handleSendEmail = () => {
        props.doSendEmailRequest({
            policyTransactionGuid: props.entity,
            templateType: selectedTemplate.code,
            documentAttachmentGuids: selectedDocuments
        });

        handleSendEmailModalClose();
    }

    const policyDocuments = getDocuments('policyDocument');
    const policyAttachments = getDocuments('policyAttachment');

    return (
        <Container>
            <Divider top={Sizes.xl} />
            <Header size={Sizes.lg}
                    textAlign={Alignments.left}
                    text='Documents'
            />
            <Divider top={Sizes.lg} />
            <Header size={Sizes.md}
                    bottom={Sizes.xs}
                    textAlign={Alignments.left}
                    text='Action'
            />
            <Container top={Sizes.xs}
                       bottom={Sizes.sm}
                       inline={!mobile}
            >
                <IconButton text='Refresh Documents'
                            size={Sizes.sm}
                            bottom={mobile ? Sizes.sm : Sizes.none}
                            icon={<RenewalSmallIcon color={Colors.LightMode} />}
                            clickEvent={props.document.doQueryDocuments}
                />
                {
                    references &&
                    <PrimaryButton text='Generate Document'
                                   size={Sizes.sm}
                                   left={mobile ? Sizes.none : Sizes.sm}
                                   bottom={mobile ? Sizes.sm : Sizes.none}
                                   clickEvent={generateDocumentBtnClick}
                    />
                }
                <PrimaryButton text='Send Email to Client'
                               size={Sizes.sm}
                               left={(references || !mobile) ? Sizes.sm : Sizes.none}
                               bottom={mobile ? Sizes.sm : Sizes.none}
                               clickEvent={sendEmailBtnClick}
                />
                <PrimaryButton text='Share Documents'
                               size={Sizes.sm}
                               left={mobile ? Sizes.none : Sizes.sm}
                               clickEvent={handleShareBtnClick}
                />
            </Container>
            <Grid>
                <Row nogutter>
                    <Column xs={10}
                            md={11}
                            nogutter
                    >
                        <Header top={Sizes.xl}
                                size={mobile ? Sizes.sm : Sizes.md}
                                textAlign={Alignments.left}
                                text='Policy Documents'
                        />
                     </Column>
                    <Column nogutter
                            xs={2}
                            md={1}
                    >
                        <Header top={Sizes.xl}
                                size={mobile ? Sizes.sm : Sizes.md}
                                textAlign={Alignments.left}
                                text='Shared'
                        />
                    </Column>
                </Row>
                {
                    policyDocuments?.map(document => (
                        <Row key={`${document.displayName}-${document.id}`}
                             nogutter
                        >
                            <Column xs={10}
                                    md={11}>
                                <Document document={document}
                                          downloadEvent={props.document.doGetDocument}
                                          mobile={mobile}
                                />
                            </Column>
                            <Column nogutter
                                    xs={2}
                                    md={1}
                            >
                                <Container fullSize
                                           verticalAlignment={Alignments.center}
                                           horizontalAlignment={Alignments.center}
                                >
                                    {
                                        document.isShared ? <CheckmarkIcon color={Colors.Success} /> : null
                                    }
                                </Container>
                            </Column>
                        </Row>
                    ))
                }
            </Grid>
            <Container inline
                       top={Sizes.md}
            >
                {
                    allowUpload &&
                    <UploadButton text={<FormattedMessage id={'covera.common.general.upload'} />}
                                  changeEvent={(e) => {
                                      props.document.doUpsertDocument({
                                          entity: props.entity,
                                          type: 'policyDocument',
                                          file: e.target.files[0]
                                      });
                                  }}
                    />
                }
            </Container>
            <Grid>
                <Row nogutter>
                    <Column nogutter
                            xs={mobile ? 10 : 11}
                    >
                        <Header top={Sizes.xl}
                                size={mobile ? Sizes.sm : Sizes.md}
                                textAlign={Alignments.left}
                                text='Policy Attachments'
                        />
                    </Column>
                    <Column nogutter
                            xs={mobile ? 2 : 1}
                    >
                        <Header top={Sizes.xl}
                                size={mobile ? Sizes.sm : Sizes.md}
                                textAlign={Alignments.left}
                                text='Shared'
                        />
                    </Column>
                </Row>
                {
                    policyAttachments?.map(document => (
                        <Row key={`${document.displayName}-${document.id}`}
                             nogutter
                        >
                            <Column xs={11}>
                                <Document document={document}
                                          downloadEvent={props.document.doGetDocument}
                                />
                            </Column>
                            <Column nogutter
                                    xs={1}
                            >
                                <Container fullSize
                                           verticalAlignment={Alignments.center}
                                           horizontalAlignment={Alignments.center}
                                >
                                    {
                                        document.isShared && <CheckmarkIcon color={Colors.Success} />
                                    }
                                </Container>
                            </Column>
                        </Row>
                    ))
                }
            </Grid>
            {
                allowUpload &&
                <Container inline
                           top={Sizes.md}
                >
                    <UploadButton
                        text={<FormattedMessage id='covera.common.general.upload' />}
                        changeEvent={(e) => {
                            props.document.doUpsertDocument({
                                entity: props.entity,
                                type: 'policyAttachment',
                                file: e.target.files[0]
                            });
                        }}
                    />
                </Container>
            }
            {
                showSendEmailModal &&
                <GenericModal
                    animate
                    visible
                    closeEvent={handleSendEmailModalClose}
                    items={
                        <React.Fragment>
                            <Header text='Send Email to Client'
                                    size={Sizes.md}
                                    bottom={Sizes.md}
                            />
                            <Container>
                                <Label text='Template:'
                                       textAlign={Alignments.left}
                                       size={Sizes.lg}
                                       bottom={Sizes.xs}
                                />
                                <DropDown
                                    value={selectedTemplate}
                                    references={props.reference?.references[`CustomReference|PolicyReferenceService|EmailTemplate|${props.entity}`]}
                                    referencesEvent={() => props.reference?.doGetReferences(`CustomReference|PolicyReferenceService|EmailTemplate|${props.entity}`)}
                                    changeEvent={handleTemplateSelect}
                                    bottom={Sizes.md}
                                />
                                {
                                    props.document?.shareableDocuments && props.document.shareableDocuments.length > 0 &&
                                    <>
                                        <Label text='Documents (optional)'
                                              textAlign={Alignments.left}
                                              size={Sizes.lg}
                                              bottom={Sizes.xs}
                                    />
                                        <CustomContainer>
                                            {
                                                props.document.shareableDocuments.map(document => (
                                                    <CheckBox label={document.displayName}
                                                              key={document.guid}
                                                              value={selectedDocuments.includes(document.guid)}
                                                              clickEvent={() => handleDocumentSelect(document.guid)}
                                                              bottom={Sizes.xs}
                                                    />
                                                ))
                                            }
                                        </CustomContainer>
                                    </>
                                }
                            </Container>
                        </React.Fragment>
                    }
                    actionButtons={[
                        {
                            primary: false,
                            text: 'Cancel',
                            disabled: false,
                            clickEvent: handleSendEmailModalClose
                        },
                        {
                            primary: true,
                            text: 'Send',
                            disabled: false,
                            clickEvent: () => handleSendEmail()
                        }
                    ]}
                />
            }
            {
                showGenerateDocumentModal &&
                <GenericModal
                    animate
                    visible
                    closeEvent={handleGenerateDocumentModalClose}
                    items={
                        <React.Fragment>
                            <Header text='Generate Document'
                                    size={Sizes.md}
                                    bottom={Sizes.md}
                            />
                            <Container>
                                <Label text='Please select what you want to generate'
                                       textAlign={Alignments.left}
                                       size={Sizes.lg}
                                       bottom={Sizes.xs}
                                />
                                <DropDown
                                    value={generateFileCode}
                                    references={references}
                                    changeEvent={value => setGenerateFileCode(value)}
                                />
                            </Container>
                        </React.Fragment>
                    }
                    actionButtons={[
                        {
                            primary: false,
                            text: 'Cancel',
                            disabled: false,
                            clickEvent: handleGenerateDocumentModalClose
                        },
                        {
                            primary: true,
                            text: 'Generate',
                            disabled: false,
                            clickEvent: handleGenerateDocument
                        }
                    ]}
                />
            }
            {
                showSharingModal &&
                <GenericModal
                    animate
                    visible
                    closeEvent={handleSharingModalClose}
                    items={
                        <React.Fragment>
                            <Header text='Share Documents'
                                    size={Sizes.md}
                                    bottom={Sizes.xs}
                            />
                            <Container>
                                <CustomContainer>
                                    {
                                        props.document?.shareableDocuments?.map(document => (
                                            <CheckBox label={document.displayName}
                                                      key={document.guid}
                                                      value={sharedDocuments[document.guid] !== undefined ? sharedDocuments[document.guid] : document.isShared}
                                                      clickEvent={value => handleDocumentShare(document.guid, value)}
                                                      bottom={Sizes.xs}
                                            />
                                        ))
                                    }
                                </CustomContainer>
                            </Container>
                        </React.Fragment>
                    }
                    actionButtons={[
                        {
                            primary: false,
                            text: 'Cancel',
                            disabled: false,
                            clickEvent: handleSharingModalClose
                        },
                        {
                            primary: true,
                            text: 'Apply',
                            disabled: Object.keys(sharedDocuments).length === 0,
                            clickEvent: handleSharingApply
                        }
                    ]}
                />
            }
        </Container>
    );
}

export default ProfileDocuments;

ProfileDocuments.propTypes = {
    context: PropTypes.object,
    reference: PropTypes.object,
    document: PropTypes.object,
    entity: PropTypes.string,
    policy: PropTypes.object,
    doExecutePolicyAction: PropTypes.func,
    doUpsertDocument: PropTypes.func,
    doGetDocument: PropTypes.func,
    doQueryDocuments: PropTypes.func,
    doReduceDocumentQueryElement: PropTypes.func,
    doQueryShareableDocuments: PropTypes.func,
    doShareUnshareDocuments: PropTypes.func,
    doSendEmailRequest: PropTypes.func,
    doGetReferences: PropTypes.func,
    riskChanged: PropTypes.bool
};

CheckmarkIcon.propTypes = {
    color: PropTypes.string
}