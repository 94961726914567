import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Form,
    FormDropDown,
    Label

} from 'metrix-common';

class ProfileBtisWcCoverage extends React.Component {
    componentDidMount() {        
    }

    render() {
        return (
            <React.Fragment>
                {                    
                    <Form
                        header={<FormattedMessage id={`Coverage`} />}
                        label={`Details`}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.policy?.participants?.insured?.party?.address?.stateCode !== 'CA'}
                    >
                        <FormDropDown
                        label={<FormattedMessage id={`Please select the limits you wish to apply for (Accident/Policy/Employee)`} />}
                        value={{ code: this.props.policy.btisWcCustomInfo?.coverage?.limitsCode, value: this.props.policy.btisWcCustomInfo?.coverage?.limitsName }}
                        references={this.props.reference?.references[`BTIS-WC`] && this.props.reference.references[`BTIS-WC`][`Limits`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`BTIS-WC.Limits`)}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `btisWcCustomInfo.coverage.limitsCode`, value: value.code });
                            this.props.doReducePolicyElement({ code: `btisWcCustomInfo.coverage.limitsName`, value: value.value });
                        }}
                        allowEdit={this.props.policy?.participants?.insured?.party?.address?.stateCode !== 'CA'}
                        />
                    </Form>
                }                                
            </React.Fragment>
        );
    }
}

export default ProfileBtisWcCoverage;