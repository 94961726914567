import React from 'react';
import {FormattedMessage} from 'react-intl';
import {
    Form,
    FormSwitch
} from 'metrix-common';

class ProfileInterwestFpAdditionalCoverages extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();

        const pageHasTooltipInfo = this.props.tooltipInfo && this.props.tooltipInfo.pages && this.props.tooltipInfo.pages.length && this.props.tooltipInfo.pages.some(p => p.code === this.props.pageCode);
        if (!pageHasTooltipInfo) {
            this.props.doGetTooltipInfo(this.props.policy.product);
        }
    }

    render() {
        return (
            <Form
                pageCode={this.props.pageCode}
                sectionCode='auto'
                saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                tooltipInfo={this.props.tooltipInfo}
                validationInfo={this.props.validationInfo}
                header={<FormattedMessage id='Auto'/>}
                saveEvent={this.props.doUpsertPolicy}
                cancelEvent={this.props.doGetPolicy}
                allowEdit={this.props.allowEdit}
            >
                <FormSwitch
                    fieldCode='hasAutoBusinessVehicle'
                    label={<FormattedMessage
                        id='Do you own a business automobile?'/>}
                    value={this.props.policy.interWestFPCustomInfo.otherCoverageInfo.hasAutoBusinessVehicle ?? false}
                    changeEvent={
                        value => {
                            this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.otherCoverageInfo.hasAutoBusinessVehicle',
                                value: value
                            });
                            this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.otherCoverageInfo.isAutoUsedByEmployees',
                                value: false
                            });
                        }}
                    allowEdit
                />
                {this.props.policy.interWestFPCustomInfo.otherCoverageInfo.hasAutoBusinessVehicle &&
                    <FormSwitch
                        fieldCode='isAutoUsedByEmployees'
                        label={<FormattedMessage
                            id='Do you have your employees driving on behalf of your business? (For example: bank runs, pick up water bottles for group exercise, etc.)'/>}
                        value={this.props.policy.interWestFPCustomInfo.otherCoverageInfo.isAutoUsedByEmployees ?? false}
                        changeEvent={
                            value => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.otherCoverageInfo.isAutoUsedByEmployees',
                                    value: value
                                });
                            }}
                        allowEdit
                    />
                }
            </Form>
        )
    }


}


export default ProfileInterwestFpAdditionalCoverages