import React from 'react';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns'
import {
    Alignments,
    BackButton,
    Container,
    Divider,
    Form,
    FormDate,
    FormDropDown,
    FormInput,
    FormSwitch,
    Header,
    Label,
    Sizes,
    SummaryGroup
} from 'metrix-common';

class ProfileBtisWcClaim extends React.Component {
    state = {
        mode: `claimView`,
        current: null
    };


    render() {
        let claimsPageName = this.props.claim.date !== undefined ? format(this.props.claim.date, 'YYYY-MM-DD') : this.props.claim.claimPageName !== undefined ? this.props.claim.claimPageName : 'New Claim';
        return (
            <React.Fragment>
                {
                    this.state.mode === `claimView` &&

                    <React.Fragment>
                        <BackButton clickEvent={this.props.backEvent} />
                        <Divider top={Sizes.md} />
                            <Header size={Sizes.md} textAlign={Alignments.left} text={claimsPageName} />
                        <Form
                            label={`Claim details`}
                            allowEdit={this.props.allowEdit}
                            saveEvent={() => this.props.doUpsertPolicy()}
                            cancelEvent={() => this.props.doGetPolicy()}
                        >
                            <FormDate
                                label={<FormattedMessage id={`Date of claim placed`} />}
                                value={this.props.claim?.date}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}date`, value })}
                                mode={`past`}
                                culture={this.props.context.session.culture}
                                allowEdit
                            />
                            <FormInput
                                label={`Description of the claim made`}
                                value={this.props.claim?.description}
                                type='text'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}description`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={`Describe corrective actions / procedures implemented to prevent future similar claims.`}
                                value={this.props.claim?.correctiveActionDescription}
                                type='text'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}correctiveActionDescription`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={`Reserved amount of the claim`}
                                value={this.props.claim?.reserveAmount}
                                type='number'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}reserveAmount`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={`Paid amount in the claim`}
                                value={this.props.claim?.paidAmount}
                                type='currency'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}paidAmount`, value })}
                                allowEdit
                            />
                            <FormSwitch
                                label={<FormattedMessage id={`Is it medical or Indemnity?`} />}
                                value={this.props.claim?.isMedicalOrIndemnity}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}isMedicalOrIndemnity`, value: value })}
                                allowEdit
                            />
                            <FormSwitch
                                label={<FormattedMessage id={`Is the claim closed?`} />}
                                value={this.props.claim?.isClosed}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}isClosed`, value: value })}
                                allowEdit
                            />
                        </Form>
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default ProfileBtisWcClaim;