
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    convertFromBoolean,
    convertToBoolean,
    Sizes,
    Alignments,
    Divider,
    Header,
    BackButton,
    Form,
    AddressForm,
    FormInput,
    FormRadio,
    FormDropDown
} from 'metrix-common';


class ProfileFluxAdditionalInsured extends React.Component {
    state = {
        mode: `additionalInsuredView`,
        current: null
    };

    getAdditionalInsuredName = () => {
        let additionalInsuredName = null;

        if (this.props.additionalInsured && this.props.additionalInsured.additionalInsuredName) {
            additionalInsuredName = `${this.props.additionalInsured.additionalInsuredName}`;
        }

        return additionalInsuredName;
    }

    render() {
        let additionalInsuredName = this.getAdditionalInsuredName() || `New additional insured`;
        return (
            <React.Fragment>
                {this.state.mode === `additionalInsuredView` &&
                    <React.Fragment>
                        <BackButton clickEvent={this.props.backEvent} />
                        <Divider top={Sizes.md} />
                        <Header size={Sizes.md} textAlign={Alignments.left} text={additionalInsuredName} />
                        <Form
                            label={`Additional insured details`}
                            allowEdit={this.props.allowEdit}
                            saveEvent={() => this.props.doUpsertPolicy()}
                            cancelEvent={() => this.props.doGetPolicy()}
                        >
                            <FormInput
                                label={<FormattedMessage id={`Additional insured name`} />}
                                value={this.props.additionalInsured?.additionalInsuredName}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}additionalInsuredName`, value })}
                                allowEdit
                            />

                            <FormDropDown
                                label={<FormattedMessage id={`Please select an additional insured type:`} />}
                                value={{ code: this.props.additionalInsured?.additionalInsuredTypeCode, value: this.props.additionalInsured?.additionalInsuredTypeName }}
                                references={this.props.reference.references && this.props.reference.references[`FLUX-GL`] && this.props.reference.references[`FLUX-GL`][`AdditionalInsuredTypes`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`FLUX-GL.AdditionalInsuredTypes`)}
                                changeEvent={(value) => {
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}additionalInsuredTypeCode`, value: value.code });
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}additionalInsuredTypeName`, value: value.value });
                                }}
                                allowEdit
                            />
                            {this.props.additionalInsured?.additionalInsuredTypeCode === 'OTHER' &&
                                <FormInput
                                label={<FormattedMessage id={`Please provide details:`} />}
                                value={this.props.additionalInsured?.additionalInsuredTypeNameOtherDescription}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}additionalInsuredTypeNameOtherDescription`, value })}
                                    type='text'
                                    allowEdit
                                />
                            }

                            <FormRadio
                                label={<FormattedMessage id={`Waiver of subrogation - provide copy of requirements`} />}
                                value={this.props.additionalInsured?.hasWaiverOfSubrogation !== undefined && { code: convertFromBoolean(this.props.additionalInsured.hasWaiverOfSubrogation) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasWaiverOfSubrogation`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Primary/Non-contributory wording - provide copy of requirements`} />}
                                value={this.props.additionalInsured?.hasPrimaryOrNonContributoryWording !== undefined && { code: convertFromBoolean(this.props.additionalInsured.hasPrimaryOrNonContributoryWording) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasPrimaryOrNonContributoryWording`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />

                        </Form>  

                        <AddressForm
                            {...this.props}
                            enableAddressSearch
                            addressSearchCountryCode={`USA`}
                            header={<FormattedMessage id={`Mailing address Details`} />}
                            label={`${additionalInsuredName} mailing address`}
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            reference={this.props.reference}
                            changeEvent={(dotProp, value) => {
                                dotProp === `address` ?
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}address`, value })
                                    :
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}address.${dotProp}`, value })
                            }}
                            allowEdit
                            address={this.props.additionalInsured && this.props.additionalInsured.address
                            }
                        />
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default ProfileFluxAdditionalInsured;