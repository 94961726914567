import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormDropDown, FormInput } from 'metrix-common';

const ProfileSupernovaCommercialCoverages = ({
    pageCode,
    doUpsertPolicy,
    doGetPolicy,
    allowEdit,
    policy,
    doReducePolicyElement,
    reference,
    validationInfo,
    tooltipInfo,
    doUpsertTooltipInfo,
    doReduceTooltipInfoElement
}) => (
    <>
        <Form
            pageCode={pageCode}
            sectionCode='contentCoverage'
            header='Content Coverage'
            tooltipInfo={tooltipInfo}
            validationInfo={validationInfo}
            saveTooltipInfo={() => doUpsertTooltipInfo(policy.product)}
            reduceTooltipInfoElement={doReduceTooltipInfoElement}
            saveEvent={doUpsertPolicy}
            cancelEvent={doGetPolicy}
            allowEdit={allowEdit}
        >
            <FormInput
                type='currency'
                fieldCode='snCommercialCoverages.coverageContentLimit'
                label='Content limit (must be over $25,000)'
                value={policy.snCommercialCoverages.coverageContentLimit}
                changeEvent={value => doReducePolicyElement({
                    code: 'snCommercialCoverages.coverageContentLimit',
                    value
                })}
                allowEdit
            />
            <FormInput
                type='currency'
                fieldCode='snCommercialCoverages.coverageElectronicContentAmount'
                label='Electronics Content'
                value={policy.snCommercialCoverages.coverageElectronicContentAmount}
                changeEvent={value => doReducePolicyElement({
                    code: 'snCommercialCoverages.coverageElectronicContentAmount',
                    value
                })}
                allowEdit
            />
            <FormInput
                type='currency'
                fieldCode='snCommercialCoverages.coveragePerishableGoodsAmount'
                label='What is the value of the Contents that is subject to spoilage (i.e. perishable goods)?'
                value={policy.snCommercialCoverages.coveragePerishableGoodsAmount}
                changeEvent={value => doReducePolicyElement({
                    code: 'snCommercialCoverages.coveragePerishableGoodsAmount',
                    value
                })}
                allowEdit
            />
            <FormDropDown
                fieldCode='snCommercialCoverages.coverageContentDeductibleCode'
                label='Content Deductible'
                value={{ code: policy.snCommercialCoverages.coverageContentDeductibleCode }}
                references={reference?.references['sn-commercial']?.CoverageContentDeductible}
                referencesEvent={() => reference.doGetReferences('sn-commercial.CoverageContentDeductible')}
                changeEvent={value => doReducePolicyElement({
                    code: 'snCommercialCoverages.coverageContentDeductibleCode',
                    value: value.code
                })}
                allowEdit
            />
        </Form>
        <Form
            pageCode={pageCode}
            sectionCode='liabilityCoverage'
            header='Liability Coverage'
            tooltipInfo={tooltipInfo}
            validationInfo={validationInfo}
            saveTooltipInfo={() => doUpsertTooltipInfo(policy.product)}
            reduceTooltipInfoElement={doReduceTooltipInfoElement}
            saveEvent={doUpsertPolicy}
            cancelEvent={doGetPolicy}
            allowEdit={allowEdit}
        >
            <FormDropDown
                fieldCode='snCommercialCoverages.coverageLiabilityLimitCode'
                label="What amount of coverage does your customer's business require for liability, and what is the preferred deductible amount?"
                value={{ code: policy.snCommercialCoverages.coverageLiabilityLimitCode }}
                references={reference?.references['sn-commercial']?.LiabilityLimit}
                referencesEvent={() => reference.doGetReferences('sn-commercial.LiabilityLimit')}
                changeEvent={value => doReducePolicyElement({
                    code: 'snCommercialCoverages.coverageLiabilityLimitCode',
                    value: value.code
                })}
                allowEdit
            />
            <FormDropDown
                fieldCode='snCommercialCoverages.coverageDeductibleForLiabilityCode'
                label='Liability Deductible'
                value={{ code: policy.snCommercialCoverages.coverageDeductibleForLiabilityCode }}
                references={reference?.references['sn-commercial']?.DeductibleForLiability}
                referencesEvent={() => reference.doGetReferences('sn-commercial.DeductibleForLiability')}
                changeEvent={value => doReducePolicyElement({
                    code: 'snCommercialCoverages.coverageDeductibleForLiabilityCode',
                    value: value.code
                })}
                allowEdit
            />
        </Form>
    </>
);

export default ProfileSupernovaCommercialCoverages;

ProfileSupernovaCommercialCoverages.propTypes = {
    policy: PropTypes.object,
    pageCode: PropTypes.string,
    doGetPolicy: PropTypes.func,
    doUpsertPolicy: PropTypes.func,
    allowEdit: PropTypes.bool,
    reference: PropTypes.object,
    validationInfo: PropTypes.object,
    tooltipInfo: PropTypes.object,
    doReducePolicyElement: PropTypes.func,
    doReduceTooltipInfoElement: PropTypes.func,
    doUpsertTooltipInfo: PropTypes.func
};