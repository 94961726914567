import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    AddressForm,
    Alignments,
    BackButton,
    Form,
    FormInput,
    Label,
    FormTextArea,
} from 'metrix-common';

class ProfileSupernovaCommercialInsured extends React.Component {
    state = {
        mode: 'insuredView',
        current: null
    };

    changeAddress = (dotProp, value) => {

        if (dotProp === `address`) {
            this.props.doReducePolicyElement({ code: `${this.props.dotProp}address`, value })
        } else {
            this.props.doReducePolicyElement({ code: `${this.props.dotProp}address.${dotProp}`, value })
        }
    }

    saveAddressHandler = () => {
        this.props.doUpsertPolicy();
    }

    render() {
        return (
            this.state.mode === 'insuredView' &&
            <React.Fragment>
                <BackButton clickEvent={this.props.backEvent} />
                <Form
                    rowId={this.props.rowId}
                    sectionCode='additionalInsuredDetails'
                    pageCode={this.props.pageCode}
                    saveTooltipInfo={this.props.saveTooltipInfo}
                    reduceTooltipInfoElement={this.props.reduceTooltipInfoElement}
                    tooltipInfo={this.props.tooltipInfo}
                    validationInfo={this.props.validationInfo}
                    header={<FormattedMessage id={this.props.additionalInsured?.label} />}
                    label={'Additional Insured details'}
                    allowEdit={this.props.allowEdit}
                    saveEvent={() => this.props.doUpsertPolicy()}
                    cancelEvent={() => this.props.doGetPolicy()}
                >
                    <FormInput
                        fieldCode='legalBusinessName'
                        label={'Business Name'}
                        value={this.props.additionalInsured?.legalBusinessName}
                        type='text'
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}legalBusinessName`, value })}
                        allowEdit
                    />
                        <Label bold text={<FormattedMessage id={'Reason they are required to be added as an additional insured '} />} textAlign={Alignments.left} />
                    <FormTextArea
                        fieldCode='note'
                        text={this.props.additionalInsured?.note}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}note`, value })}
                    />
                    </Form>
                    <AddressForm
                        {...this.props}
                        enableAddressSearch
                        pageCode={this.props.pageCode}
                        sectionCode='additionalInsuredAddressDetails'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        addressSearchCountryCode={'CAN'}
                        header={<FormattedMessage id='Address Details' />}
                        label='Location Details'
                        saveEvent={this.saveAddressHandler}
                        cancelEvent={this.props.doGetPolicy}
                        reference={this.props.reference}
                        changeEvent={(dotProp, value) => this.changeAddress(dotProp, value)}
                        allowEdit={this.props.allowEdit}
                        address={this.props.additionalInsured && this.props.additionalInsured.address}
                    />
            </React.Fragment>
        );
    }
}

export default ProfileSupernovaCommercialInsured;