import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import {
    Sizes,
    Alignments,
    Colors,
    getColor,
    Opacity,
    Grid,
    Row,
    Column,
    Container,
    Tabbed,
    Header,
    Label,
    Link,
    ToolTip,
    Separator,
    HomeIcon,
    VehicleIcon,
    getFormattedCurrency
} from 'metrix-common';

import ProfileMessages from '../Messages';

const HeaderCell = styled(Container)`
    background: ${Colors.StrokeVeryLight};
`;

const BodyCell = styled(Container) `
    ${({ firstRow }) => firstRow && `border-top: 1px solid ${Colors.StrokeVeryLight};`}
    border-bottom: 1px solid ${Colors.StrokeVeryLight};
    ${({ firstCell }) => firstCell && `border-left: 1px solid ${Colors.StrokeVeryLight};`}
    border-right: 1px solid ${Colors.StrokeVeryLight};
`;

const FooterCell = styled(Container) `
    background: ${getColor(Colors.StrokeVeryLight)};
`;

const MobileCell = styled(Container)`
    ${({ title }) => title && `background: ${Colors.StrokeVeryLight};`}
    border: 1px solid ${getColor(Colors.StrokeDark, Opacity.Lighter)};
    flex: 100%;
`;

const MobileCellInner = styled(Container)`
    flex: 100%;
`;

const ToolTipContainer = styled(Container)`
    padding-top: 4px
`;

class ProfileRisks extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentRisk: 0
        };
    };

    getTable = (coverages, showPrice, total) => {

        return (
            <Grid>
                {!this.context.screen.isMobile &&
                    <Row nogutter>
                        <Column nogutter xs={showPrice ? 3 : 4} md={6}>
                            <HeaderCell fullSize />
                        </Column>
                        <Column nogutter xs={showPrice ? 3 : 4} md={showPrice ? 2 : 3}>
                            <HeaderCell fullSize>
                                <Label bold left={Sizes.xs} top={Sizes.xs} bottom={Sizes.xs} textAlign={Alignments.left} text={<FormattedMessage id={`app.components.MainContentsCard.limit`} />} />
                            </HeaderCell>
                        </Column>
                        <Column nogutter xs={showPrice ? 3 : 4} md={showPrice ? 2 : 3}>
                            <HeaderCell fullSize>
                                <Label bold left={Sizes.xs} top={Sizes.xs} bottom={Sizes.xs} textAlign={Alignments.left} text={<FormattedMessage id={`app.components.MainContentsCard.deductible`} />} />
                            </HeaderCell>
                        </Column>
                        {showPrice &&
                            <Column nogutter xs={3} md={2}>
                                <HeaderCell fullSize>
                                    <Label bold left={Sizes.xs} top={Sizes.xs} bottom={Sizes.xs} textAlign={Alignments.left} text={<FormattedMessage id={`app.components.MainContentsCard.price`} />} />
                                </HeaderCell>
                            </Column>
                        }
                    </Row>
                }
                {coverages.map((coverage, index) => {
                    var limitAmount = coverage.formattedLimit;
                    var deductibleAmount = coverage.formattedDeductible;
                    var premiumAmount = coverage.formattedPremium;
                    let available = coverage.status !== `rejected`;
                    let included =
                        coverage.type === `Mandatory` ||
                        (coverage.status !== `declined` &&
                            (coverage.status === `accepted` ||
                                (coverage.status === `proposed` && (!coverage.grossPremium || coverage.grossPremium.value === 0))));

                    let labelSuffix =
                        !available
                            ? `[i][pc] - not available[pc][i]`
                            : !included
                                ? `[i][pc] - not included[pc][i]`
                                : ``;

                    if (available && !included && isNaN(coverage.premium && coverage.premium.value)) {
                        premiumAmount = ``;
                    }

                    let label = coverage.label + labelSuffix;

                    let messages = (this.props.context.session.userType === `broker` && coverage.messages && coverage.messages.length > 0) && coverage.messages;

                    return (
                        this.context.screen.isMobile ?
                            <Row nogutter>
                                <Column xs={12} nogutter>
                                    <MobileCell title verticalAlignment={Alignments.center} inline>
                                        <Label bold left={Sizes.xs} top={Sizes.xs} bottom={Sizes.xs} right={Sizes.xs} textAlign={Alignments.left} text={label} />
                                        {messages &&
                                            <ToolTipContainer><ToolTip><ProfileMessages messages={messages} size={Sizes.sm} title={coverage.label} titleSize={Sizes.xs} /></ToolTip></ToolTipContainer>}
                                    </MobileCell>
                                </Column>
                                <Column xs={showPrice ? 4 : 6} nogutter>
                                    <MobileCell fullSize>
                                        <MobileCellInner fullSize left={Sizes.xs} top={Sizes.xs} bottom={Sizes.xs}>
                                            <Label bold textAlign={Alignments.right} text={<FormattedMessage id={`app.components.MainContentsCard.limit`} />} />
                                            <Label textAlign={Alignments.right} text={limitAmount} />
                                        </MobileCellInner>
                                    </MobileCell>
                                </Column>
                                <Column xs={showPrice ? 4 : 6} nogutter>
                                    <MobileCell fullSize>
                                        <MobileCellInner fullSize left={Sizes.xs} top={Sizes.xs} bottom={Sizes.xs}>
                                            <Label bold textAlign={Alignments.right} text={<FormattedMessage id={`app.components.MainContentsCard.deductible`} />} />
                                            <Label textAlign={Alignments.right} text={deductibleAmount} />
                                        </MobileCellInner>
                                    </MobileCell>
                                </Column>
                                {showPrice &&
                                    <Column xs={showPrice ? 4 : 6} nogutter>
                                        <MobileCell fullSize>
                                            <MobileCellInner fullSize left={Sizes.xs} top={Sizes.xs} bottom={Sizes.xs}>
                                                <Label bold textAlign={Alignments.right} text={<FormattedMessage id={`app.components.MainContentsCard.price`} />} />
                                                <Label textAlign={Alignments.right} text={premiumAmount} />
                                            </MobileCellInner>
                                        </MobileCell>
                                    </Column>
                                }
                            </Row>
                            :
                            <Row nogutter>
                                <Column nogutter xs={showPrice ? 3 : 4} md={6}>
                                    <BodyCell firstCell firstRow={index === 0} fullSize inline verticalAlignment={Alignments.center}>
                                        <Label left={Sizes.xs} top={Sizes.xs} bottom={Sizes.xs} right={Sizes.xs} textAlign={Alignments.left} text={label} />
                                        {messages &&
                                            <ToolTipContainer><ToolTip><ProfileMessages messages={messages} size={Sizes.sm} title={coverage.label} titleSize={Sizes.xs} /></ToolTip></ToolTipContainer>}
                                    </BodyCell>
                                </Column>
                                <Column nogutter xs={showPrice ? 3 : 4} md={showPrice ? 2 : 3}>
                                    <BodyCell lastCell={showPrice === false} firstRow={index === 0} fullSize>
                                        <Label left={Sizes.xs} top={Sizes.xs} bottom={Sizes.xs} textAlign={Alignments.right} text={limitAmount} />
                                    </BodyCell>
                                </Column>
                                <Column nogutter xs={showPrice ? 3 : 4} md={showPrice ? 2 : 3}>
                                    <BodyCell firstRow={index === 0} fullSize>
                                        <Label left={Sizes.xs} top={Sizes.xs} bottom={Sizes.xs} textAlign={Alignments.right} text={deductibleAmount} />
                                    </BodyCell>
                                </Column>
                                {showPrice &&
                                    <Column nogutter xs={3} md={2}>
                                        <BodyCell lastCell firstRow={index === 0} fullSize>
                                            <Label right={Sizes.xs} top={Sizes.xs} bottom={Sizes.xs} textAlign={Alignments.right} text={premiumAmount} />
                                        </BodyCell>
                                    </Column>
                                }
                            </Row>
                    );
                })}
                {total &&
                    <Row nogutter>
                        <Column nogutter xs={6}>
                            <FooterCell fullSize>
                                <Label bold top={Sizes.xs} bottom={Sizes.xs} left={Sizes.xs} textAlign={Alignments.left} text={<FormattedMessage id={`app.components.MainContentsCard.total`} />} />
                            </FooterCell>
                        </Column>
                        <Column nogutter xs={6}>
                            <FooterCell fullSize>
                                <Label bold top={Sizes.xs} bottom={Sizes.xs} right={Sizes.xs} textAlign={Alignments.right} text={total} />
                            </FooterCell>
                        </Column>
                    </Row>
                }
            </Grid>
        );
    }

    getRisk = (risk) => {
        let coverages = risk.coverages;

        if (coverages.some(x => x.coverageCode.startsWith(`ERQK`) && x.status !== `accepted`)) {
            coverages.filter(x => x.coverageCode.startsWith(`ERQK`)).forEach(x => x.status = `declined`);
        }

        let mandatoryCoverages = coverages.filter(x => x.type === `Mandatory`);
        let optionalCoverages = coverages.filter(x => x.type === `Optional`);
        let showPrice = this.props.policySummary.status !== `lost`;
        let showOptionalCoverages = optionalCoverages && optionalCoverages.length > 0;

        // TODO: Currency should be dynamic
        let currency = `CAD`;
        let culture = this.props.context.session.culture;
        let annualPremiumAmount = this.props.policySummary.estimatedAnnualPremium.value;        
        let taxesAmount = this.props.policySummary.estimatedTaxes.value;
        let feesAmount = this.props.policySummary.estimatedFees.value;

        let totalAmount = annualPremiumAmount + taxesAmount + feesAmount;
        let formattedTotalAmount = getFormattedCurrency(totalAmount, currency, culture);

        let icon = this.props.policySummary.lineOfBusiness === `auto` ? <VehicleIcon primary={Colors.Secondary} /> : <HomeIcon primary={Colors.TertiaryText} />

        return (
            <Container bottom={Sizes.sm}>
                <Container inline>
                    {icon}
                    <Container verticalAlignment={Alignments.bottom} >
                        <Header size={Sizes.md} left={Sizes.sm} textAlign={Alignments.left} text={risk.fullDescription} />
                    </Container>
                </Container>
                <Container top={Sizes.sm} bottom={Sizes.sm}>
                    <Header size={Sizes.sm} top={Sizes.xs} bottom={Sizes.xs} text={<FormattedMessage id={`app.components.MainContentsCard.mainCoverage`} />} textAlign={Alignments.left} />
                    {this.getTable(mandatoryCoverages, showPrice, showOptionalCoverages ? null : formattedTotalAmount)}
                </Container>
                {showOptionalCoverages &&
                    <Container bottom={Sizes.sm}>
                    <Header size={Sizes.sm} top={Sizes.xs} bottom={Sizes.xs} text={<FormattedMessage id={`app.components.MainContentsCard.extraProtection`} />} textAlign={Alignments.left} />
                        {this.getTable(optionalCoverages, showPrice, formattedTotalAmount)}
                    </Container>
                }
                <Separator orientation={`horizontal`} top={Sizes.xs} bottom={Sizes.xs} solid />
                <Container top={Sizes.sm} bottom={Sizes.sm}>
                    <Header size={Sizes.sm} text={<FormattedMessage id={`app.components.MainContentsCard.informationRecap`} />} textAlign={Alignments.left} />
                    <Grid>
                        <Row>
                            {risk.recaps && risk.recaps.map((recap) => {
                                return (
                                    <Column xs={12} md={6}>
                                        <Container inline>
                                            <li />
                                            <Label inline text={recap.code} bold />
                                            <Label inline text={` ${recap.value || ``}`} />
                                        </Container>
                                    </Column>
                                );
                            })}
                        </Row>
                    </Grid>
                </Container>
                <Separator orientation={`horizontal`} top={Sizes.xs} bottom={Sizes.xs} solid />
            </Container>
        );
    }

    getTabs = () => {
        let tabs = [];

        if (this.props.risks &&
            this.props.risks.length > 0) {
            this.props.risks.map((risk, index) => {
                tabs.push({
                    code: risk.description,
                    description: risk.fullDescription.substring(risk.description.length).trim()
                });
            });
        }

        return tabs;
    }

    render() {
        let riskCount = this.props.risks && this.props.risks.length || 0;
        let multiRisk = riskCount > 1;
        let currentRisk = this.props.risks && this.props.risks[this.state.currentRisk];

        let tabsPerColumn =
            multiRisk === true &&
                riskCount <= 2
                ? 6
                : riskCount <= 3
                    ? 4
                    : 3;

        return (
            <React.Fragment>
                {multiRisk &&
                    <Container>
                        <Tabbed
                            items={this.getTabs()}
                            clickEvent={(value) => this.setState({ currentRisk: value })}
                            lg={tabsPerColumn} />
                    </Container>
                }
                <Grid>
                    <Row>
                        <Column nogutter>
                            {currentRisk && this.getRisk(currentRisk) }
                        </Column>
                    </Row>
                </Grid>
            </React.Fragment>
        );
    }
}

ProfileRisks.contextTypes = {
    screen: PropTypes.object
};

export default ProfileRisks;
