export default {

    hasLiabilityExposures(productCode)
    {
        return productCode === "home" || productCode === "ushome";
    },

    hasCGOtherTabInMenu(activeTab) {
        return activeTab === "cg-other.other" ||
            activeTab === "cg-auto.other" ||
            activeTab === "cg-generalliability.other" ||
            activeTab === "cg-buildersrisk.other" ||
            activeTab === "cg-bop.other" ||
            activeTab === "cg-pl.other" ||
            activeTab === "cg-property.other" ||
            activeTab === "cg-workerscomp.other" ||
            activeTab === "cg-bonds.other";
    },

    hasCGClassificationTabInMenu(activeTab) {
        return activeTab === "cg-other.classification" ||
            activeTab === "cg-auto.classification" ||
            activeTab === "cg-generalliability.classification" ||
            activeTab === "cg-buildersrisk.classification" ||
            activeTab === "cg-bop.classification" ||
            activeTab === "cg-pl.classification" ||
            activeTab === "cg-property.classification" ||
            activeTab === "cg-workerscomp.classification" ||
            activeTab === "cg-bonds.classification";
    },

    truncateString (str, maxLength) {
        if (str.length <= maxLength) {
            return str;
        }

        let truncatedStr = str.substr(0, maxLength); // Trim the string to the maximum length
        truncatedStr = truncatedStr.substr(0, Math.min(truncatedStr.length, truncatedStr.lastIndexOf(" "))); // Trim to the last word boundary
        truncatedStr += "..."; // Add an ellipsis at the end

        return truncatedStr;
    }
}

