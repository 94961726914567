import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    convertFromBoolean,
    convertToBoolean,
    Sizes,
    Alignments,
    Container,
    Divider,
    Header,
    BackButton,
    Form,
    Label,
    AddressForm,
    FormInput,
    FormRadio,
    FormDropDown,
    FormDate,
    FormTextArea,
    FormSwitch,
    SummaryGroup
} from 'metrix-common';

import ProfileFluxExposure from './Exposure';
import ProfileFluxAdditionalInsured from './AdditionalInsured';

class ProfileFluxLocation extends React.Component {
    state = {
        mode: `locationView`,
        currentExposure: null,
        currentAdditionalInsured: null
    };

    getLocationName = () => {
        let locationName = null;

        if (this.props.location && this.props.location.locationName) {
            locationName = `${this.props.location.locationName}`;
        }

        return locationName;
    }

    getExposures = () => {
        let exposures = [];

        // Only retrieve exposures for the current location
        if (this.props.exposures &&
            this.props.exposures.filter(x => x.locationGuid === this.props.location.guid).length > 0) {
            this.props.exposures.filter(x => x.locationGuid === this.props.location.guid).forEach((exposure) => {
                    let option = { code: `exposureOption`, value: exposure.guid };

                    let exposureName = `${exposure.exposureName || `New Operation`}`;

                    option.elements = [];
                    option.elements.push({ code: `Label`, value: exposureName });

                    exposures.push(option);
            });
        }

        return exposures;
    }

    getAdditionalInsureds = () => {
        let additionalInsureds = [];

        if (this.props.additionalInsureds &&
            this.props.additionalInsureds.filter(x => x.locationGuid === this.props.location.guid).length > 0) {
            this.props.additionalInsureds.filter(x => x.locationGuid === this.props.location.guid).forEach((additionalInsured) => {
                let option = { code: `additionalInsuredOption`, value: additionalInsured.guid };

                let additionalInsuredName = `${additionalInsured.additionalInsuredName || `New additional insured`}`;

                option.elements = [];
                option.elements.push({ code: `Label`, value: additionalInsuredName });

                additionalInsureds.push(option);
            });
        }

        return additionalInsureds;
    }

    render() {
        let locationName = this.getLocationName() || `New Location`;
        return (
            <React.Fragment>
                {this.state.mode === `locationView` &&
                    <React.Fragment>
                        <BackButton clickEvent={this.props.backEvent} />                      
                            <Form
                                header={<FormattedMessage id={locationName} />}
                                label={`Details`}
                                saveEvent={this.props.doUpsertPolicy}
                                cancelEvent={this.props.doGetPolicy}
                                allowEdit={this.props.allowEdit}
                            >
                            <FormInput
                                label={<FormattedMessage id={`Location Number`} />}
                                value={this.props.location?.locationNo}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}locationNo`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Building Number`} />}
                                value={this.props.location?.buildingNo}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}buildingNo`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Location Name`} />}
                                value={this.props.location?.locationName}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}locationName`, value })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Is this location fully open and operational?`} />}
                                value={this.props.location?.isFullyOpened !== undefined && { code: convertFromBoolean(this.props.location.isFullyOpened) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}isFullyOpened`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            {this.props.location?.isFullyOpened === false &&
                                <FormDate
                                    label={<FormattedMessage id={`When do you expect this location to be open and fully operational?`} />}
                                    value={this.props.location?.futureOpeningDate}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}futureOpeningDate`, value })}
                                    culture={this.props.context.session.culture}
                                    mode={`future`}
                                    allowEdit
                                />
                            }
                            <FormRadio
                                label={<FormattedMessage id={`Is there any oil extraction done at this location?`} />}
                                value={this.props.location?.hasOilExtraction !== undefined && { code: convertFromBoolean(this.props.location.hasOilExtraction) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasOilExtraction`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`What year was the building built?`} />}
                                value={this.props.location?.yearBuildingBuilt}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}yearBuildingBuilt`, value })}
                                type='number'
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`What is the square footage of the building?`} />}
                                value={this.props.location?.totalBuildingSquareFootageAmount}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}totalBuildingSquareFootageAmount`, value })}
                                type='number'
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`What percentage of the location is occupied by the insured?`} />}
                                value={this.props.location?.occupiedPercentage}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}occupiedPercentage`, value })}
                                type='number'
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`How many floors are in the building?`} />}
                                value={this.props.location?.numberOfStories}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}numberOfStories`, value })}
                                type='number'
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`How old is the roof?`} />}
                                value={this.props.location?.ageOfRoof}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}ageOfRoof`, value })}
                                type='number'
                                allowEdit
                            />
                            <FormDropDown
                                label={<FormattedMessage id={`Please select the roof type?`} />}
                                value={this.props.location && this.props.location.roofTypeCode && { code: this.props.location.roofTypeCode, value: this.props.location?.roofTypeName  }}
                                references={this.props.reference.references && this.props.reference.references[`FLUX-GL`] && this.props.reference.references[`FLUX-GL`][`RoofTypes`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`FLUX-GL.RoofTypes`)}
                                changeEvent={(value) => {
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}roofTypeCode`, value: value.code });
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}roofTypeName`, value: value.value });
                                }}
                                allowEdit
                            />
                            <FormDropDown
                                label={<FormattedMessage id={`Please select the construction type?`} />}
                                value={this.props.location && this.props.location.constructionTypeCode && { code: this.props.location.constructionTypeCode, value: this.props.location?.constructionTypeName}}
                                references={this.props.reference.references && this.props.reference.references[`FLUX-GL`] && this.props.reference.references[`FLUX-GL`][`ConstructionType`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`FLUX-GL.ConstructionType`)}
                                changeEvent={(value) => {
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}constructionTypeCode`, value: value.code });
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}constructionTypeName`, value: value.value });
                                }}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Please describe which ISO Fire Protection Class the building has`} />}
                                value={this.props.location?.isoFireProtectionClass}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}isoFireProtectionClass`, value })}
                                type='text'
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Are there fire sprinklers?`} />}
                                value={this.props.location?.hasFireSprinklers !== undefined && { code: convertFromBoolean(this.props.location.hasFireSprinklers) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasFireSprinklers`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            {this.props.location?.hasFireSprinklers === true &&
                                <FormInput
                                    label={<FormattedMessage id={`Percentage of the sprinklered building:`} />}
                                    value={this.props.location?.sprinkledBuildingPercentage}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}sprinkledBuildingPercentage`, value })}
                                    type='number'
                                    allowEdit
                                />
                            }
                            <FormRadio
                                label={<FormattedMessage id={`Does the applicant own the building?`} />}
                                value={this.props.location?.isApplicantOwnerBuilding !== undefined && { code: convertFromBoolean(this.props.location.isApplicantOwnerBuilding) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}isApplicantOwnerBuilding`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Is the building currently undergoing or planning to undergo any renovations, repairs, construction, etc.?`} />}
                                value={this.props.location?.isBuildingRenovationInProgressOrPlaning !== undefined && { code: convertFromBoolean(this.props.location.isBuildingRenovationInProgressOrPlaning) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}isBuildingRenovationInProgressOrPlaning`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Does the applicant have an approved safe for secure product storage (Minimum safe requirements: 800lb with a 1-hour fire rating; under 2000lb must be bolted to the ground)`} />}
                                value={this.props.location?.hasApprovedSafe !== undefined && { code: convertFromBoolean(this.props.location.hasApprovedSafe) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasApprovedSafe`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Does the applicant have a vault room?`} />}
                                value={this.props.location?.hasVaultRoom !== undefined && { code: convertFromBoolean(this.props.location.hasVaultRoom) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasVaultRoom`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            {this.props.location?.hasVaultRoom === true &&
                                <Label bold text={<FormattedMessage id={`Please describe in detail`} />} textAlign={Alignments.left} />
                            }
                            {this.props.location?.hasVaultRoom === true &&
                                <FormTextArea
                                    text={this.props.location?.vaultRoomDescription}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}vaultRoomDescription`, value })}
                                />
                            }
                            <FormRadio
                                label={<FormattedMessage id={`Is there a vacuum oven, centrifuge, distillation column and/or Rotovaps in the building?`} />}
                                value={this.props.location?.hasSpecialEquipment !== undefined && { code: convertFromBoolean(this.props.location.hasSpecialEquipment) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasSpecialEquipment`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            {this.props.location?.hasSpecialEquipment === true &&
                                <Label bold text={<FormattedMessage id={`Please provide manufacturer, model number, replacement cost, and motor’s HP for each`} />} textAlign={Alignments.left} />
                            }
                            {this.props.location?.hasSpecialEquipment === true &&
                                <FormTextArea
                                    text={this.props.location?.specialEquipmentDescription}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}specialEquipmentDescription`, value })}
                                />
                            }
                            <FormRadio
                                label={<FormattedMessage id={`Is there an electrical back up system?`} />}
                                value={this.props.location?.hasElectricalBackUp !== undefined && { code: convertFromBoolean(this.props.location.hasElectricalBackUp) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasElectricalBackUp`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />

                            <Label bold text={<FormattedMessage id={`How are the plants watered?`} />} textAlign={Alignments.left} />
                            <FormTextArea
                                text={this.props.location?.plantsWateringDescription}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}plantsWateringDescription`, value })}
                            />

                            <FormInput
                                label={<FormattedMessage id={`What is the estimated value of the building?`} />}
                                value={this.props.location?.buildingValueAmount}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}buildingValueAmount`, value })}
                                type='number'
                                allowEdit
                            />
                        </Form>
                        <AddressForm 
                            {...this.props}
                            enableAddressSearch
                            addressSearchCountryCode={`USA`}
                            header={<FormattedMessage id={`Address Details`} />}
                            label={`Location Address`}
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            reference={this.props.reference}
                            changeEvent={(dotProp, value) => {
                                dotProp === `address` ?
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}address`, value })
                                    :
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}address.${dotProp}`, value })
                            }}
                            allowEdit={this.props.allowEdit}
                            address={this.props.location &&
                                this.props.location.address
                            }
                        />
                        <Form
                            header={<FormattedMessage id={`Premises information`} />}
                            label={`Details`}
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            allowEdit={this.props.allowEdit}
                        >
                            <FormDropDown
                                label={<FormattedMessage id={`Describe the type of crime area in which applicant’s premises is located`} />}
                                value={this.props.location && this.props.location.crimeLevelTypeCode && { code: this.props.location.crimeLevelTypeCode, value: this.props.location?.crimeLevelTypeName }}
                                references={this.props.reference.references && this.props.reference.references[`FLUX-GL`] && this.props.reference.references[`FLUX-GL`][`CrimeLevelTypes`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`FLUX-GL.CrimeLevelTypes`)}
                                changeEvent={(value) => {
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}crimeLevelTypeCode`, value: value.code });
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}crimeLevelTypeName`, value: value.value });
                                }}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Square footage of building occupied by insured:`} />}
                                value={this.props.location?.occupiedSquareFootageByInsuredAmount}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}occupiedSquareFootageByInsuredAmount`, value })}
                                type='number'
                                allowEdit
                            />
                            <FormDropDown
                                label={<FormattedMessage id={`Describe the area in which the applicant’s business is located`} />}
                                value={this.props.location && this.props.location.areaTypeCode && { code: this.props.location.areaTypeCode, value: this.props.location.areaTypeName }}
                                references={this.props.reference.references && this.props.reference.references[`FLUX-GL`] && this.props.reference.references[`FLUX-GL`][`AreaTypes`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`FLUX-GL.AreaTypes`)}
                                changeEvent={(value) => {
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}areaTypeCode`, value: value.code });
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}areaTypeName`, value: value.value });
                                }}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Is the nature of the business advertised on the outside of the building?`} />}
                                value={this.props.location?.hasAdvertisingOutsideBuilding !== undefined && { code: convertFromBoolean(this.props.location.hasAdvertisingOutsideBuilding) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasAdvertisingOutsideBuilding`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Does applicant occupy the entire building?`} />}
                                value={this.props.location?.isEntireBuildingOccupied !== undefined && { code: convertFromBoolean(this.props.location.isEntireBuildingOccupied) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}isEntireBuildingOccupied`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            {this.props.location?.isEntireBuildingOccupied === false &&
                                <FormRadio
                                    label={<FormattedMessage id={`Are there connecting doors to adjacent units?`} />}
                                    value={this.props.location?.hasConnectingDoorsToAdjacentUnits !== undefined && { code: convertFromBoolean(this.props.location.hasConnectingDoorsToAdjacentUnits) }}
                                    references={this.props.reference?.references[`YesNo`]}
                                    referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasConnectingDoorsToAdjacentUnits`, value: convertToBoolean(value.code) })}
                                    allowEdit
                                />
                            }
                            {this.props.location?.hasConnectingDoorsToAdjacentUnits === true &&
                                <FormInput
                                    label={<FormattedMessage id={`How are the connecting doors secured (i.e., deadbolts, alarms, etc.)?`} />}
                                    value={this.props.location?.doorsSecureConnectionDescription}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}doorsSecureConnectionDescription`, value })}
                                    type='text'
                                    allowEdit
                                />
                            }
                            <FormRadio
                                label={<FormattedMessage id={`Does anyone live on the premises?`} />}
                                value={this.props.location?.hasAnyoneLiveOnPremises !== undefined && { code: convertFromBoolean(this.props.location.hasAnyoneLiveOnPremises) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasAnyoneLiveOnPremises`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            {this.props.location?.hasAnyoneLiveOnPremises === true &&
                                <FormInput
                                    label={<FormattedMessage id={`Please describe occupancy`} />}
                                    value={this.props.location?.occupancyDescription}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}occupancyDescription`, value })}
                                    type='text'
                                    allowEdit
                                />
                            }
                            {this.props.location?.hasAnyoneLiveOnPremises === true &&
                                <FormRadio
                                    label={<FormattedMessage id={`Is separate homeowner’s insurance coverage in place?`} />}
                                    value={this.props.location?.hasSeparateHomeownersInsuranceCoverage !== undefined && { code: convertFromBoolean(this.props.location.hasSeparateHomeownersInsuranceCoverage) }}
                                    references={this.props.reference?.references[`YesNo`]}
                                    referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasSeparateHomeownersInsuranceCoverage`, value: convertToBoolean(value.code) })}
                                    allowEdit
                                />
                            }
                            <FormRadio
                                label={<FormattedMessage id={`Does the premises have a pool, pond, or other water exposure?`} />}
                                value={this.props.location?.hasWaterExposure !== undefined && { code: convertFromBoolean(this.props.location.hasWaterExposure) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasWaterExposure`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            {this.props.location?.hasWaterExposure === true &&
                                <Label bold text={<FormattedMessage id={`Please explain:`} />} textAlign={Alignments.left} />
                            }
                            {this.props.location?.hasWaterExposure === true &&
                                <FormTextArea
                                    text={this.props.location?.waterExposureDescription}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}waterExposureDescription`, value })}
                                />
                            }
                        </Form>
                        <Form
                            header={<FormattedMessage id={`Which of the following security systems are utilized (please check all that apply):`} />}
                            label={`Details`}
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            allowEdit={this.props.allowEdit}
                        >
                            <FormSwitch
                                label={<FormattedMessage id={`Automatic Sprinkler System`} />}
                                value={this.props.location?.hasAutomaticSprinklerSystem}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasAutomaticSprinklerSystem`, value: value })}
                                allowEdit
                            />
                            <FormSwitch
                                label={<FormattedMessage id={`Central station burglar alarm`} />}
                                value={this.props.location?.hasCentralStationBurglarAlarm}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasCentralStationBurglarAlarm`, value: value })}
                                allowEdit
                            />
                            <FormSwitch
                                label={<FormattedMessage id={`Door greeter/ID checker`} />}
                                value={this.props.location?.hasDoorGreeterOrIdChecker}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasDoorGreeterOrIdChecker`, value: value })}
                                allowEdit
                            />
                            <FormSwitch
                                label={<FormattedMessage id={`Fencing`} />}
                                value={this.props.location?.hasFencing}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasFencing`, value: value })}
                                allowEdit
                            />
                            <FormSwitch
                                label={<FormattedMessage id={`Gated doors`} />}
                                value={this.props.location?.hasGatedDoors}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasGatedDoors`, value: value })}
                                allowEdit
                            />
                            <FormSwitch
                                label={<FormattedMessage id={`Gated windows`} />}
                                value={this.props.location?.hasGatedWindows}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasGatedWindows`, value: value })}
                                allowEdit
                            />
                            <FormSwitch
                                label={<FormattedMessage id={`Hold-up button/panic button`} />}
                                value={this.props.location?.hasHoldupOrPanicButton}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasHoldupOrPanicButton`, value: value })}
                                allowEdit
                            />
                            <FormSwitch
                                label={<FormattedMessage id={`Interior motion detectors`} />}
                                value={this.props.location?.hasInteriorMotionDetectors}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasInteriorMotionDetectors`, value: value })}
                                allowEdit
                            />
                            <FormSwitch
                                label={<FormattedMessage id={`Interior video cameras`} />}
                                value={this.props.location?.hasInteriorVideoCameras}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasInteriorVideoCameras`, value: value })}
                                allowEdit
                            />
                            <FormSwitch
                                label={<FormattedMessage id={`Exterior video cameras`} />}
                                value={this.props.location?.hasExteriorVideoCameras}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasExteriorVideoCameras`, value: value })}
                                allowEdit
                            />
                            <FormSwitch
                                label={<FormattedMessage id={`Safe or vault`} />}
                                value={this.props.location?.hasSafeOrVault}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasSafeOrVault`, value: value })}
                                allowEdit
                            />
                            <FormSwitch
                                label={<FormattedMessage id={`Security guards – armed`} />}
                                value={this.props.location?.hasArmedSecurityGuards}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasArmedSecurityGuards`, value: value })}
                                allowEdit
                            />
                            <FormSwitch
                                label={<FormattedMessage id={`Security guards – unarmed`} />}
                                value={this.props.location?.hasUnarmedSecurityGuards}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasUnarmedSecurityGuards`, value: value })}
                                allowEdit
                            />
                            <FormSwitch
                                label={<FormattedMessage id={`Dog(s)`} />}
                                value={this.props.location?.hasDogs}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasDogs`, value: value })}
                                allowEdit
                            />

                            {this.props.location?.hasDogs === true &&
                                <Label bold text={<FormattedMessage id={`Breed and Number:`} />} textAlign={Alignments.left} />
                            }
                            {this.props.location?.hasDogs === true &&
                                <FormTextArea
                                    text={this.props.location?.dogsDescription}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}dogsDescription`, value })}
                                />
                            }

                            <FormRadio
                                label={<FormattedMessage id={`Are all security measures fully operational during nonbusiness hours?`} />}
                                value={this.props.location?.isNonbusinessHoursFullySecured !== undefined && { code: convertFromBoolean(this.props.location.isNonbusinessHoursFullySecured) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}isNonbusinessHoursFullySecured`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            {this.props.location?.isNonbusinessHoursFullySecured === false &&
                                <Label bold text={<FormattedMessage id={`Which ones are not?`} />} textAlign={Alignments.left} />
                            }
                            {this.props.location?.isNonbusinessHoursFullySecured === false &&
                                <FormTextArea
                                    text={this.props.location?.nonFullySecurityDescription}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}nonFullySecurityDescription`, value })}
                                />
                            }
                            <FormRadio
                                label={<FormattedMessage id={`If guards and/or greeters are used are they employees?`} />}
                                value={this.props.location?.hasGuardsAsEmployees !== undefined && { code: convertFromBoolean(this.props.location.hasGuardsAsEmployees) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasGuardsAsEmployees`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            {this.props.location?.hasGuardsAsEmployees === false &&
                                <FormRadio
                                    label={<FormattedMessage id={`Do independent contractors acting as security guards or greeters/ID checkers carry their own insurance and name applicant as an additional insured?`} />}
                                    value={this.props.location?.isSecurityGuardHaveOwnInsuranceForApplicant !== undefined && { code: convertFromBoolean(this.props.location.isSecurityGuardHaveOwnInsuranceForApplicant) }}
                                    references={this.props.reference?.references[`YesNo`]}
                                    referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}isSecurityGuardHaveOwnInsuranceForApplicant`, value: convertToBoolean(value.code) })}
                                    allowEdit
                                />
                            }
                            <FormRadio
                                label={<FormattedMessage id={`Does the applicant get certificates of insurance (COIs) evidencing limits and AI status for the applicant?`} />}
                                value={this.props.location?.hasCOIAndAI !== undefined && { code: convertFromBoolean(this.props.location.hasCOIAndAI) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasCOIAndAI`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <Label bold text={<FormattedMessage id={`What limits do independent contractors carry?`} />} textAlign={Alignments.left} />
                            <FormTextArea
                                text={this.props.location?.independentContractorsLimitsDescription}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}independentContractorsLimitsDescription`, value })}
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Are there any firearms on the property (including any firearms carried by security guards?)`} />}
                                value={this.props.location?.hasFirearmsOnProperty !== undefined && { code: convertFromBoolean(this.props.location.hasFirearmsOnProperty) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasFirearmsOnProperty`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            {this.props.location?.hasFirearmsOnProperty === true &&
                                <Label bold text={<FormattedMessage id={`Please explain:`} />} textAlign={Alignments.left} />
                            }
                            {this.props.location?.hasFirearmsOnProperty === true &&
                                <FormTextArea
                                    text={this.props.location?.firearmsOnPropertyDescription}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}firearmsOnPropertyDescription`, value })}
                                />
                            }
                            <FormRadio
                                label={<FormattedMessage id={`Does applicant have a written plan or manual that describes business security procedures including what to do in the event of a robbery or other crime?`} />}
                                value={this.props.location?.hasSecurityProceduresManual !== undefined && { code: convertFromBoolean(this.props.location.hasSecurityProceduresManual) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasSecurityProceduresManual`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Are employees instructed to cooperate and obey the robber’s instructions and not to resist?`} />}
                                value={this.props.location?.hasSecurityInstructedEmployees !== undefined && { code: convertFromBoolean(this.props.location.hasSecurityInstructedEmployees) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasSecurityInstructedEmployees`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                        </Form>
                        {(this.props.location.yearBuildingBuilt && (new Date().getFullYear() - this.props.location.yearBuildingBuilt >= 20)) &&
                            <Form
                                header={<FormattedMessage id={`For buildings 20 years old and more, additional information is required`} />}
                                label={`Details`}
                                saveEvent={this.props.doUpsertPolicy}
                                cancelEvent={this.props.doGetPolicy}
                                allowEdit={this.props.allowEdit}
                            >
                                <FormInput
                                    label={<FormattedMessage id={`When roof was updated?`} />}
                                    value={this.props.location?.roofUpdatedYear}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}roofUpdatedYear`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`When plumbing was updated?`} />}
                                    value={this.props.location?.plumbingUpdatedYear}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}plumbingUpdatedYear`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`When electrical was updated?`} />}
                                    value={this.props.location?.electricalUpdatedYear}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}electricalUpdatedYear`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`When HVAC was updated?`} />}
                                    value={this.props.location?.hvacUpdatedYear}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hvacUpdatedYear`, value })}
                                    type='number'
                                    allowEdit
                                />
                            </Form>
                        }
                        {this.props.location?.isBuildingRenovationInProgressOrPlaning === true &&
                            <Form
                                header={<FormattedMessage id={`For buildings that are currently being renovated or have a renovation plan, we need more information`} />}
                                label={`Details`}
                                saveEvent={this.props.doUpsertPolicy}
                                cancelEvent={this.props.doGetPolicy}
                                allowEdit={this.props.allowEdit}
                            >
                                <FormInput
                                    label={<FormattedMessage id={`What stage are the renovations currently at?`} />}
                                    value={this.props.location?.currentRenovationStage}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}currentRenovationStage`, value })}
                                    type='text'
                                    allowEdit
                                />
                                <FormDate
                                    label={<FormattedMessage id={`If not currently occurring, for when are the renovations planned?`} />}
                                    value={this.props.location?.renovationStartDay}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}renovationStartDay`, value })}
                                    culture={this.props.context.session.culture}
                                    mode={`future`}
                                    allowEdit
                                />
                                <FormDate
                                    label={<FormattedMessage id={`When do you expect the renovations to be completed?`} />}
                                    value={this.props.location?.renovationEndDay}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}renovationEndDay`, value })}
                                    culture={this.props.context.session.culture}
                                    mode={`future`}
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`What is the total estimated value of the renovation?`} />}
                                    value={this.props.location?.renovationAmount}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}renovationAmount`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormRadio
                                    label={<FormattedMessage id={`Is there coverage on the building currently?`} />}
                                    value={this.props.location?.hasCoverage !== undefined && { code: convertFromBoolean(this.props.location.hasCoverage) }}
                                    references={this.props.reference?.references[`YesNo`]}
                                    referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasCoverage`, value: convertToBoolean(value.code) })}
                                    allowEdit
                                />
                                <FormRadio
                                    label={<FormattedMessage id={`Do you currently have a Builder’s Risk policy?`} />}
                                    value={this.props.location?.hasBuilderRiskPolicy !== undefined && { code: convertFromBoolean(this.props.location.hasBuilderRiskPolicy) }}
                                    references={this.props.reference?.references[`YesNo`]}
                                    referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasBuilderRiskPolicy`, value: convertToBoolean(value.code) })}
                                    allowEdit
                                />
                                {this.props.location?.hasBuilderRiskPolicy === true &&
                                    <FormInput
                                        label={<FormattedMessage id={`Please provide a coverage certificate`} />}
                                        value={this.props.location?.coverageCertificateName}
                                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}coverageCertificateName`, value })}
                                        type='text'
                                        allowEdit
                                    />
                                }
                                {this.props.location?.hasBuilderRiskPolicy === false &&
                                    <FormInput
                                        label={<FormattedMessage id={`Please provide name of contractor`} />}
                                        value={this.props.location?.contractorName}
                                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}contractorName`, value })}
                                        type='text'
                                        allowEdit
                                    />
                                }
                            </Form>
                        }
                        {this.props.location?.hasOilExtraction === true &&
                            <Form
                                header={<FormattedMessage id={`Type of oil extractions at this location`} />}
                                label={`Details`}
                                saveEvent={this.props.doUpsertPolicy}
                                cancelEvent={this.props.doGetPolicy}
                                allowEdit={this.props.allowEdit}
                            >
                                <FormSwitch
                                    label={<FormattedMessage id={`CO2`} />}
                                    value={this.props.location?.oilExtractionCO2}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}oilExtractionCO2`, value: value })}
                                    allowEdit
                                />
                                <FormSwitch
                                    label={<FormattedMessage id={`Butane`} />}
                                    value={this.props.location?.oilExtractionButane}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}oilExtractionButane`, value: value })}
                                    allowEdit
                                />
                                <FormSwitch
                                    label={<FormattedMessage id={`Propane`} />}
                                    value={this.props.location?.oilExtractionPropane}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}oilExtractionPropane`, value: value })}
                                    allowEdit
                                />
                                <FormSwitch
                                    label={<FormattedMessage id={`Other`} />}
                                    value={this.props.location?.oilExtractionOther}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}oilExtractionOther`, value: value })}
                                    allowEdit
                                />
 
                                {this.props.location?.oilExtractionOther === true &&
                                    <Label bold text={<FormattedMessage id={`Please describe the type of oil extraction`} />} textAlign={Alignments.left} />
                                }
                                {this.props.location?.oilExtractionOther === true &&
                                    <FormTextArea
                                        text={this.props.location?.oilExtractionOtherDescription}
                                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}oilExtractionOtherDescription`, value })}
                                    />
                                }
                            </Form>
                        }

                        <Divider top={Sizes.xl} />
                        <Header size={Sizes.lg} textAlign={Alignments.left} text={`Operations / Exposures at ` + locationName} />
                        <Container top={Sizes.xl} bottom={Sizes.xl}>
                            <SummaryGroup
                                allowAdd={this.props.allowEdit}
                                addEvent={() => {
                                    this.props.doReduceParameter({ code: `LocationGuid`, value: this.props.location.guid });
                                    this.props.doExecutePolicyAction({ code: `FLUX-GL.CREATEEXPOSURE` });
                                }}
                                allowEdit
                                editEvent={(exposure) => {
                                    this.setState({ currentExposure: exposure, mode: `exposureView` });
                                }}
                                allowDelete={this.props.allowEdit}
                                deleteEvent={(exposure) => {
                                    this.props.doReduceParameter({ code: `ExposureGuid`, value: exposure });
                                    this.props.doExecutePolicyAction({ code: `FLUX-GL.DELETEEXPOSURE` });
                                    this.setState({ deleteModal: false });
                                    this.setState({ currentExposure: null, mode: `locationView` });
                                }}
                                entities={this.getExposures()}
                                icon={`home`}
                            />
                        </Container>

                        <Divider top={Sizes.xl} />
                        <Header size={Sizes.lg} textAlign={Alignments.left} text={`Additional insured at ` + locationName} />
                        <Container top={Sizes.xl} bottom={Sizes.xl}>
                            <SummaryGroup
                                allowAdd={this.props.allowEdit}
                                addEvent={() => {
                                    this.props.doReduceParameter({ code: `LocationGuid`, value: this.props.location.guid });
                                    this.props.doExecutePolicyAction({ code: `FLUX-GL.CREATEADDITIONALINSURED` });
                                }}
                                allowEdit
                                editEvent={(additionalInsured) => {
                                    this.setState({ currentAdditionalInsured: additionalInsured, mode: `additionalInsuredView` });
                                }}
                                allowDelete={this.props.allowEdit}
                                deleteEvent={(additionalInsured) => {
                                    this.props.doReduceParameter({ code: `AdditionalInsuredGuid`, value: additionalInsured });
                                    this.props.doExecutePolicyAction({ code: `FLUX-GL.DELETEADDITIONALINSURED` });
                                    this.setState({ deleteModal: false });
                                    this.setState({ currentAdditionalInsured: null, mode: `locationView` });
                                }}
                                entities={this.getAdditionalInsureds()}
                                icon={`person`}
                            />
                        </Container>
                    </React.Fragment>
                }
                {this.state.mode === `exposureView` &&

                    <ProfileFluxExposure exposure={this.props.exposures.find(x => x.guid === this.state.currentExposure)}
                        dotProp={`fluxCustomInfo.exposures.${this.props.exposures.findIndex(x => x.guid === this.state.currentExposure)}.`}
                        backEvent={() => this.setState({ mode: `locationView`, currentExposure: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReduceParameter={this.props.doReduceParameter}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        doExecutePolicyAction={this.props.doExecutePolicyAction}
                        reference={this.props.reference}
                        context={this.props.context}
                         allowEdit={this.props.allowEdit}
                    />
                }
                {this.state.mode === `additionalInsuredView` &&

                    <ProfileFluxAdditionalInsured additionalInsured={this.props.additionalInsureds.find(x => x.guid === this.state.currentAdditionalInsured)}
                        dotProp={`fluxCustomInfo.additionalInsureds.${this.props.additionalInsureds.findIndex(x => x.guid === this.state.currentAdditionalInsured)}.`}
                        backEvent={() => this.setState({ mode: `locationView`, currentAdditionalInsured: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReduceParameter={this.props.doReduceParameter}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        doExecutePolicyAction={this.props.doExecutePolicyAction}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
            </React.Fragment>
        );
    }
}

export default ProfileFluxLocation;