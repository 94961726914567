import React from 'react';
import {FormattedMessage} from 'react-intl';
import {
    Form, FormChecklist,
    FormDropDown,
    FormInput, FormSwitch
} from 'metrix-common';

class ProfileInterwestFpCoverage extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();

        const pageHasTooltipInfo = this.props.tooltipInfo && this.props.tooltipInfo.pages && this.props.tooltipInfo.pages.length && this.props.tooltipInfo.pages.some(p => p.code === this.props.pageCode);
        if (!pageHasTooltipInfo) {
            this.props.doGetTooltipInfo(this.props.policy.product);
        }
    }

    render() {
        return (
            <>
                <Form
                    pageCode={this.props.pageCode}
                    sectionCode='coverageSelected'
                    saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                    reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                    tooltipInfo={this.props.tooltipInfo}
                    validationInfo={this.props.validationInfo}
                    header={<FormattedMessage id='Coverage Selected'/>}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormChecklist
                        fieldCode='coverageSelectedItems'
                        label={<FormattedMessage
                            id='Please check all that of the coverage types that you are seeking:'/>}
                        value={this.props.policy.interWestFPCustomInfo.coverageInfo.coverageSelectedItems}
                        references={this.props.reference.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].Coverage}
                        referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.Coverage')}
                        changeEvent={values => {
                            this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.coverageInfo.coverageSelectedItems',
                                value: values
                            });
                        }}
                        allowEdit
                    />
                </Form>
                {
                    this.props.policy.interWestFPCustomInfo.coverageInfo.coverageSelectedItems.find(value => value?.code === 'General Liability') &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='пeneralLiability'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='General Liability'/>}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormInput
                            fieldCode='glTotalMember'
                            label={<FormattedMessage id='What are the total number of members?'/>}
                            value={this.props.policy.interWestFPCustomInfo.coverageInfo.glTotalMember}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.coverageInfo.glTotalMember',
                                value
                            })}
                            allowEdit
                        />
                        <FormInput
                            fieldCode='glEstimateGuestPerMonthCount'
                            label={<FormattedMessage id='What is the average number of Guests each month?'/>}
                            value={this.props.policy.interWestFPCustomInfo.coverageInfo.glEstimateGuestPerMonthCount}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.coverageInfo.glEstimateGuestPerMonthCount',
                                value
                            })}
                            allowEdit
                        />
                        <FormSwitch
                            fieldCode='isGLAssumptionRiskSigned'
                            label={<FormattedMessage
                                id='Are all members are required to sign an assumption of risk disclosure and waiver of releasing liability?'/>}
                            value={this.props.policy.interWestFPCustomInfo.coverageInfo.isGLAssumptionRiskSigned ?? false}
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.coverageInfo.isGLAssumptionRiskSigned',
                                        value: value
                                    });
                                }}
                            allowEdit
                        />
                        <FormInput
                            fieldCode='glHoursOfOperation'
                            label={<FormattedMessage id='What are the hours of operation?'/>}
                            value={this.props.policy.interWestFPCustomInfo.coverageInfo.glHoursOfOperation}
                            type='text'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.coverageInfo.glHoursOfOperation',
                                value
                            })}
                            allowEdit
                        />
                        <FormSwitch
                            fieldCode='hasGLEvacuationMedicalEmergencyProcedures'
                            label={<FormattedMessage
                                id='Are there written medical emergency and evacuation procedures in place?'/>}
                            value={this.props.policy.interWestFPCustomInfo.coverageInfo.hasGLEvacuationMedicalEmergencyProcedures ?? false}
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.coverageInfo.hasGLEvacuationMedicalEmergencyProcedures',
                                        value: value
                                    });
                                }}
                            allowEdit
                        />
                        <FormSwitch
                            fieldCode='hasGLAutomaticExternalDefibrillator'
                            label={<FormattedMessage id='Do you have an automatic External Defibrillator (AED)?'/>}
                            value={this.props.policy.interWestFPCustomInfo.coverageInfo.hasGLAutomaticExternalDefibrillator ?? false}
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.coverageInfo.hasGLAutomaticExternalDefibrillator',
                                        value: value
                                    });
                                }}
                            allowEdit
                        />
                        <FormSwitch
                            fieldCode='hasGLCprAedFadEmployeeOnDuty'
                            label={<FormattedMessage
                                id='Is there at least one CPR/AED/First Aid Cerified employee on duty?'/>}
                            value={this.props.policy.interWestFPCustomInfo.coverageInfo.hasGLCprAedFadEmployeeOnDuty ?? false}
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.coverageInfo.hasGLCprAedFadEmployeeOnDuty',
                                        value: value
                                    });
                                }}
                            allowEdit
                        />
                        <FormSwitch
                            fieldCode='isGLIncidentLogKept'
                            label={<FormattedMessage id='Is an incident log kept of all injuries and accidents?'/>}
                            value={this.props.policy.interWestFPCustomInfo.coverageInfo.isGLIncidentLogKept ?? false}
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.coverageInfo.isGLIncidentLogKept',
                                        value: value
                                    });
                                }}
                            allowEdit
                        />
                    </Form>
                }
                {
                    this.props.policy.interWestFPCustomInfo.coverageInfo.coverageSelectedItems.find(value => value?.code === 'Workers Compensation') &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='workersCompensation'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='Workers Compensation'/>}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormSwitch
                            fieldCode='hasWCSubcontractors'
                            label={<FormattedMessage id='Do you have any subcontractors or 1099s'/>}
                            value={this.props.policy.interWestFPCustomInfo.coverageInfo.hasWCSubcontractors ?? false}
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.coverageInfo.hasWCSubcontractors',
                                        value: value
                                    });
                                }}
                            allowEdit
                        />
                        <FormInput
                            fieldCode='wcEmployeePayrollFitnessAmount'
                            label={<FormattedMessage id='Fitness Employee Payroll'/>}
                            value={this.props.policy.interWestFPCustomInfo.coverageInfo.wcEmployeePayrollFitnessAmount}
                            type='money'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.coverageInfo.wcEmployeePayrollFitnessAmount',
                                value
                            })}
                            allowEdit
                        />
                        <FormInput
                            fieldCode='wcEmployeePayrollClericalAmount'
                            label={<FormattedMessage id='Clericial Employee Payroll'/>}
                            value={this.props.policy.interWestFPCustomInfo.coverageInfo.wcEmployeePayrollClericalAmount}
                            type='money'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.coverageInfo.wcEmployeePayrollClericalAmount',
                                value
                            })}
                            allowEdit
                        />
                        <FormInput
                            fieldCode='wcEmployeePayrollOutsideSalesAmount'
                            label={<FormattedMessage id='Outside Sales Employee Payroll'/>}
                            value={this.props.policy.interWestFPCustomInfo.coverageInfo.wcEmployeePayrollOutsideSalesAmount}
                            type='money'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.coverageInfo.wcEmployeePayrollOutsideSalesAmount',
                                value
                            })}
                            allowEdit
                        />
                        <FormSwitch
                            fieldCode='hasWCEmployeeCriminalBackgroundCheck'
                            label={<FormattedMessage id='Are you running criminal background checks on employees?'/>}
                            value={this.props.policy.interWestFPCustomInfo.coverageInfo.hasWCEmployeeCriminalBackgroundCheck ?? false}
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.coverageInfo.hasWCEmployeeCriminalBackgroundCheck',
                                        value: value
                                    });
                                }}
                            allowEdit
                        />
                    </Form>
                }
                {
                    this.props.policy.interWestFPCustomInfo.coverageInfo.coverageSelectedItems.find(value => value?.code === 'Employment Practices Liability Coverage') &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='employmentPracticesLiabilityCoverage'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='Employment Practices Liability Coverage'/>}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormSwitch
                            fieldCode='hasEPLIInsruance'
                            label={<FormattedMessage id='Do you currently have EPLI Insurance?'/>}
                            value={this.props.policy.interWestFPCustomInfo.coverageInfo.hasEPLIInsruance ?? false}
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.coverageInfo.hasEPLIInsruance',
                                        value: value
                                    });
                                }}
                            allowEdit
                        />
                        <FormInput
                            fieldCode='epliEmployeeTerminationCount'
                            label={<FormattedMessage
                                id='How many employees have been terminated or demoted in the past 12 months?'/>}
                            value={this.props.policy.interWestFPCustomInfo.coverageInfo.epliEmployeeTerminationCount}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.coverageInfo.epliEmployeeTerminationCount',
                                value
                            })}
                            allowEdit
                        />
                        <FormSwitch
                            fieldCode='hasEPLIVolunteers'
                            label={<FormattedMessage id='Do you have volunteers?'/>}
                            value={this.props.policy.interWestFPCustomInfo.coverageInfo.hasEPLIVolunteers ?? false}
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.coverageInfo.hasEPLIVolunteers',
                                        value: value
                                    });
                                }}
                            allowEdit
                        />
                        <FormSwitch
                            fieldCode='hasEPLIReductionEmployeesAnticipated'
                            label={<FormattedMessage
                                id='Do you anticipate a reduction of employees in the next year? '/>}
                            value={this.props.policy.interWestFPCustomInfo.coverageInfo.hasEPLIReductionEmployeesAnticipated ?? false}
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.coverageInfo.hasEPLIReductionEmployeesAnticipated',
                                        value: value
                                    });
                                }}
                            allowEdit
                        />
                    </Form>
                }
                {
                    this.props.policy.interWestFPCustomInfo.coverageInfo.coverageSelectedItems.find(value => value?.code === 'Directors and Officers') &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='directorsAndOfficers'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='Directors and Officers'/>}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormSwitch
                            fieldCode='hasDOBoardMembers'
                            label={<FormattedMessage id='Do you have board members? '/>}
                            value={this.props.policy.interWestFPCustomInfo.coverageInfo.hasDOBoardMembers ?? false}
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.coverageInfo.hasDOBoardMembers',
                                        value: value
                                    });

                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.coverageInfo.doBoardMembersCount',
                                        value: null
                                    })
                                }}
                            allowEdit
                        />
                        {
                            this.props.policy.interWestFPCustomInfo.coverageInfo.hasDOBoardMembers &&
                            <FormInput
                                fieldCode='doBoardMembersCount'
                                label={<FormattedMessage id='How many board members does your business have?'/>}
                                value={this.props.policy.interWestFPCustomInfo.coverageInfo.doBoardMembersCount}
                                type='number'
                                changeEvent={value => this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.coverageInfo.doBoardMembersCount',
                                    value
                                })}
                                allowEdit
                            />
                        }
                    </Form>
                }
                {
                    this.props.policy.interWestFPCustomInfo.coverageInfo.coverageSelectedItems.find(value => value?.code === 'Cyber') &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='cyber'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='Cyber'/>}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormChecklist
                            fieldCode='cyDetailsSelectedItems'
                            label={<FormattedMessage
                                id='Please check all of the following that you may store or handle:'/>}
                            value={this.props.policy.interWestFPCustomInfo.coverageInfo.cyDetailsSelectedItems}
                            references={this.props.reference.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].CYDetails}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.CYDetails')}
                            changeEvent={values => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.coverageInfo.cyDetailsSelectedItems',
                                    value: values
                                });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {
                    this.props.policy.interWestFPCustomInfo.coverageInfo.coverageSelectedItems.find(value => value?.code === 'Umbrella') &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='umbrella'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='Umbrella'/>}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormDropDown
                            fieldCode='umLimitsCode'
                            label={<FormattedMessage id='What limit are you looking for?'/>}
                            value={{code: this.props.policy.interWestFPCustomInfo.coverageInfo.umLimitsCode}}
                            references={this.props.reference?.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].UMLimits}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.UMLimits')}
                            changeEvent={value => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.coverageInfo.umLimitsCode',
                                    value: value.code
                                });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {
                    this.props.policy.interWestFPCustomInfo.coverageInfo.coverageSelectedItems.find(value => value?.code === 'Accident Coverage') &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='accidentCoverage'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='Accident Coverage'/>}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormInput
                            fieldCode='acAnnualParticipationUnderCount'
                            label={<FormattedMessage id='Annual number of participants age 17 and under?'/>}
                            value={this.props.policy.interWestFPCustomInfo.coverageInfo.acAnnualParticipationUnderCount}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.coverageInfo.acAnnualParticipationUnderCount',
                                value
                            })}
                            allowEdit
                        />
                        <FormInput
                            fieldCode='acAnnualParticipationOverCount'
                            label={<FormattedMessage id='Annual number of participants age 18 and over?'/>}
                            value={this.props.policy.interWestFPCustomInfo.coverageInfo.acAnnualParticipationOverCount}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.coverageInfo.acAnnualParticipationOverCount',
                                value
                            })}
                            allowEdit
                        />
                    </Form>
                }
                {
                    this.props.policy.interWestFPCustomInfo.coverageInfo.coverageSelectedItems.find(value => value?.code === 'Abuse and Molestation') &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='abuseAndMolestation'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='Abuse and Molestation'/>}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormSwitch
                            fieldCode='hasAMClaimsOrAllegations'
                            label={<FormattedMessage
                                id='Have any abuse or molestation claims been filed or allegations been made against you?'/>}
                            value={this.props.policy.interWestFPCustomInfo.coverageInfo.hasAMClaimsOrAllegations ?? false}
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.coverageInfo.hasAMClaimsOrAllegations',
                                        value: value
                                    });
                                }}
                            allowEdit
                        />
                        <FormSwitch
                            fieldCode='isAMAwareOfPossibleClaim'
                            label={<FormattedMessage
                                id='Are you aware of any abuse or molestation occurences that could lead to a claim?'/>}
                            value={this.props.policy.interWestFPCustomInfo.coverageInfo.isAMAwareOfPossibleClaim ?? false}
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.coverageInfo.isAMAwareOfPossibleClaim',
                                        value: value
                                    });
                                }}
                            allowEdit
                        />
                        <FormChecklist
                            fieldCode='amScreeningDetailsSelectedItems'
                            label={<FormattedMessage
                                id='Please indicate which, if any, screening controls are in place for employees:'/>}
                            value={this.props.policy.interWestFPCustomInfo.coverageInfo.amScreeningDetailsSelectedItems}
                            references={this.props.reference.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].AMScreening}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.AMScreening')}
                            changeEvent={values => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.coverageInfo.amScreeningDetailsSelectedItems',
                                    value: values
                                });

                                if (!this.props.policy.interWestFPCustomInfo.coverageInfo.amScreeningDetailsSelectedItems.find(value => value?.code === 'Background checks')) {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.coverageInfo.amBackgroundCheckCode',
                                        value: null
                                    });
                                }

                            }}
                            allowEdit
                        />
                        {
                            this.props.policy.interWestFPCustomInfo.coverageInfo.amScreeningDetailsSelectedItems.find(value => value?.code === 'Background checks') &&
                            <FormDropDown
                                fieldCode='amBackgroundCheckCode'
                                label={<FormattedMessage id='What level of background checks are performed?'/>}
                                value={{code: this.props.policy.interWestFPCustomInfo.coverageInfo.amBackgroundCheckCode}}
                                references={this.props.reference?.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].AMBackgroundCheck}
                                referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.AMBackgroundCheck')}
                                changeEvent={value => {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.coverageInfo.amBackgroundCheckCode',
                                        value: value.code
                                    });
                                }}
                                allowEdit
                            />
                        }
                    </Form>
                }
            </>
        )
    }
}


export default ProfileInterwestFpCoverage