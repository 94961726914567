import { put, call, select } from 'redux-saga/effects';
import { reduceOperation, reduceLoading, getSessionSaga } from 'metrix-common';
import { reducePolicy } from '../../actions/policy/reducePolicy';
import { selectPolicy } from '../../selectors/policy/selectPolicy';

export function* getOptaSaga() {
    try {
        yield put(reduceLoading({ code: 'getOpta', value: true }));
        const session = yield getSessionSaga();
        const policy = yield select(selectPolicy());
        
        const response = yield call(fetch, '/api/policyTransaction/action/getOpta', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Authorization': session.authenticationToken.token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ policyTransaction: policy })
        });
        
        const result = yield call([response, 'json']);
        
        if (result.operation.status) {
            yield put(reducePolicy(result.policyTransaction));
        }
        
        yield put(reduceOperation(result.operation));
    }
    catch (error) {
        console.log('Error while calling Get Opta API - ', error);
    }
    finally {
        yield put(reduceLoading({ code: 'getOpta', value: false }));
    }
}
