import { put, call, select } from 'redux-saga/effects';
import { reduceOperation, reduceLoading, getSessionSaga } from 'metrix-common';
import { reduceProduct } from '../../actions/product/reduceProduct';

export function* getProductSaga() {
    yield put(reduceLoading({ code: `getProduct`, value: true }));
    let session = yield getSessionSaga();
    let response = yield call(fetch, `/api/product/broker-company-products`, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
            'Accept': 'application/json',
            'Authorization': session.authenticationToken.token,
            'Content-Type': 'application/json'
        }
    });

    let result = yield call([response, 'json']);

    yield put(reduceOperation(result.operation));
    if (result.operation.status) {
        yield put(reduceProduct(result.products));
    }
    else {
        console.error("Error while Get Product API");
        yield put(reduceLoading({ code: `getProduct`, value: false }));
        return;
    }
    yield put(reduceLoading({ code: `getProduct`, value: false }));
    return;
}
