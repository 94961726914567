import React from 'react';
import styled from 'styled-components';
import { Label, Header, Container, Row, Column, getColor, Colors, Opacity, Radius, Sizes, Alignments } from 'metrix-common'

const SliderComponent = styled.input`
    -webkit-appearance: none;
    overflow: visible;
    width: 100%;
    background: transparent;
    outline: none;

    --min: ${({ min }) => `${min}`};
    --max: ${({ max }) => `${max}`};
    --value: ${({ value }) => `${value}`};
    --range: calc(var(--max) - var(--min));
    --ratio: calc((var(--value) - var(--min))/var(--range));
    --sx: calc(.5*1.5em + var(--ratio)*(100% - 1.5em));

    ::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        background: linear-gradient(${({ error }) => error ? Colors.Error : Colors.Primary}, ${({ error }) => error ? Colors.Error : Colors.Primary}) 0/var(--sx) 100% no-repeat ${({ color }) => color === 'dual' ? Colors.Secondary : getColor(Colors.Secondary, Opacity.Light)};
        height: 10px;
        border-radius: ${Radius.large};
      }

    ::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: ${Colors.Primary};
        border: 2px solid ${Colors.LightMode};
        margin-top: -5px;
     }

    ::-moz-range-thumb {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: ${Colors.Primary};
        border: 2px solid ${Colors.LightMode};
        margin-top: -5px;
    }

   ::-moz-range-progress {
        height: 10px;
        border-radius: ${Radius.large};
        background-color: ${({ error }) => error ? Colors.Error : Colors.Primary}; 
      }

    ::-moz-range-track {  
        height: 10px;
        border-radius: ${Radius.large};
        background-color: ${({ unit }) => unit === '%' ? Colors.Secondary : Colors.StrokeLight};
      }
`;

export default class CustomSlider extends React.Component {

    getSlider() {
        let type = this.props.type;
        let unit = this.props.unit || ``;
        let unitAlignment = this.props.unitAlignment || `right`;
        let header = this.props.header || false;
        let min = this.props.min;
        let max = this.props.max;
        let labelL = this.props.leftLabel;
        let labelR = this.props.rightLabel;
        let increment = this.props.increment || 1;
        let valSeparator = this.props.valueSeparator || false;
        let value = this.props.value || min || 0;

        let labels = this.props.Labels || false;
        let labelSize = 0;
        if (labels) {
            labelSize = 12 / (labels.length + 2);
        }

        let displayValue = value;
        let minLabelText = min || 0;
        let maxLabelText = max || 100;

        type === `ratio` ? unit = `%` : unit = unit;

        increment >= max ? increment = 1 : increment = increment;

        if (valSeparator) {
            displayValue = displayValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            minLabelText = minLabelText.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            maxLabelText = maxLabelText.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }

        if (unitAlignment === `right`) {
            minLabelText = `${minLabelText} ${unit}`;
            maxLabelText = `${maxLabelText} ${unit}`;
        }
        else {
            minLabelText = `${unit} ${minLabelText}`;
            maxLabelText = `${unit} ${maxLabelText}`;
        }


        const isDesktop = this.props.isDesktop;
        const isTablet = this.props.isTablet;
        const isMobile = this.props.isMobile;

        return (
            <React.Fragment>
                    {
                        header &&
                        <Row>
                            <Column xs={6}>
                                {
                                    unitAlignment === `right` ?
                                        <Header size={Sizes.md} textAlign={Alignments.left} color={Colors.Primary} text={`${displayValue || `0`} ${unit}`} />
                                        :
                                        <Header size={Sizes.md} textAlign={Alignments.left} color={Colors.Primary} text={`${unit} ${displayValue || `0`}`} />
                                }

                            </Column>
                            {
                                type === `ratio` &&
                                <Column xs={6}>
                                    <Header size={Sizes.md} textAlign={Alignments.right} color={Colors.Secondary} text={`${100 - value || `0`} ${unit}`} />
                                </Column>
                            }
                        </Row>
                    }
                    <Row>
                    <Column md={12}>
                            {
                                type === `ratio` ?

                                    <Container inline>
                                        < Column xs={isMobile ? 2 : 1}>
                                            <Container fullSize>
                                                <Label size={Sizes.md}
                                                    text={`${labelL}`}
                                                    textAlign={Alignments.right}
                                                    color={Colors.Secondary}
                                                />
                                            </Container>
                                        </Column>

                                        <SliderComponent
                                            type={`range`}
                                            min={0}
                                            max={100}
                                            step={1}
                                            onInput={(e) => { this.props.changeEvent(e.target.value) }}
                                            value={value || 0}
                                            error={this.props.error}
                                            color={`dual`} />

                                        < Column xs={isMobile ? 2 : 1}>
                                            <Container fullSize>
                                                <Label size={Sizes.md}
                                                    text={`${labelR}`}
                                                    textAlign={Alignments.left}
                                                    color={Colors.Secondary}
                                                />
                                            </Container>
                                        </Column>
                                    </Container>
                                    :

                                    <SliderComponent
                                        type={`range`}
                                        min={min}
                                        max={max}
                                        step={`${increment}`}
                                        onInput={(e) => { this.props.changeEvent(e.target.value) }}
                                        value={value || min}
                                        error={this.props.error} />
                            }
                        </Column>
                    </Row>
                    <Row>
                        {

                            type === `ratio` && labelL && labelR ?

                                <>
                                    <Column xs={labelSize}></Column>
                                    {
                                        labels &&
                                        labels.map((label) => (
                                            <Column xs={labelSize}>
                                                <Container fullSize>
                                                    <Label size={Sizes.md}
                                                        text={`${label}`}
                                                        color={Colors.Primary}
                                                    />
                                                </Container>
                                            </Column>
                                        ))
                                    }
                                    <Column xs={labelSize}> </Column>
                                </>

                                :

                                <Row>
                                    < Column xs={6}>
                                        <Container fullSize>
                                            <Label size={Sizes.md}
                                                text={`${minLabelText}`}
                                                textAlign={Alignments.left}
                                            />
                                        </Container>
                                    </Column>
                                    < Column xs={6}>
                                        <Container fullSize>
                                            <Label size={Sizes.md}
                                                text={`${maxLabelText}`}
                                                textAlign={Alignments.right}
                                            />
                                        </Container>
                                    </Column>
                                </Row>

                        }
                    </Row>
            </React.Fragment>
        );
    }

    render() {
        return (
                <Row>
                    <Column md={12}>
                        {this.getSlider()}
                    </Column>
                </Row>
        );
    }
}