import React from 'react';
import { FormattedMessage } from 'react-intl';

import {    
    BackButton,
    Form, 
    FormInput,
    FormDropDown
} from 'metrix-common';

class ProfileEmployersWcClassification extends React.Component {

    render() {
        const classificationName = this.props.classification?.classificationTypeCode !== undefined && this.props.classification?.classificationTypeValue !== '' ? this.props.classification?.classificationTypeValue : this.props.classification?.classificationPageName !== undefined && this.props.classification?.classificationPageName !== '' ? this.props.classification.classificationPageName : 'New Classification';
        return (
            <React.Fragment>
                <BackButton clickEvent={this.props.backEvent} />
                <Form
                    rowId={this.props.rowId}
                    pageCode={this.props.pageCode}
                    sectionCode='classificationDetails'
                    saveTooltipInfo={this.props.saveTooltipInfo}
                    reduceTooltipInfoElement={this.props.reduceTooltipInfoElement}
                    tooltipInfo={this.props.tooltipInfo}
                    validationInfo={this.props.validationInfo}
                    header={<FormattedMessage id={classificationName} />}
                    label={`Classification Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormDropDown
                        fieldCode='classificationTypeCode'
                        label={<FormattedMessage id={`Classification`} />}
                        value={this.props.classification?.classificationTypeCode && { code: this.props.classification.classificationTypeCode, value: this.props.classification?.classificationTypeValue }}
                        references={this.props.reference?.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`Classifications`][this.props.locationStateCode]}
                        referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.Classifications.${this.props.locationStateCode}`)}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `${this.props.dotProp}classificationTypeCode`, value: value.code });
                            this.props.doReducePolicyElement({ code: `${this.props.dotProp}classificationTypeValue`, value: value.value });
                        }}
                        allowEdit={this.props.classification?.isCompanionClassification !== true}
                    />
                    <FormInput
                        fieldCode='payrollAmount'
                        label={<FormattedMessage id={`Payroll Amount`} />}
                        value={this.props.classification?.payrollAmount}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}payrollAmount`, value })}
                        type='currency'
                        allowEdit
                    />
                </Form>
            </React.Fragment>
        );
    }
}

export default ProfileEmployersWcClassification;