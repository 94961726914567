import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Colors, Label, Sizes } from 'metrix-common';

const BadgeContainer = styled.div`
	background-color: ${Colors.StrokeVeryLight};
	border-radius: 1rem;
	padding: .25rem .75rem .25rem .5rem;
	display: flex;
	gap: .5rem;
	justify-content: center;
	align-items: center;
	line-height: 1rem;
`;

const BadgeCircle = styled.div`
	width: .5rem;
	height: .5rem;
	border-radius: 50%;
	background-color: ${props => props.color};
`;

const Badge = ({ text, color }) => (
	<BadgeContainer>
		<BadgeCircle color={color} />
		<Label text={text} size={Sizes.sm} />
	</BadgeContainer>
);

Badge.propTypes = {
	text: PropTypes.string,
	color: PropTypes.string,
};

export default Badge;