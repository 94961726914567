import { put, call, select } from 'redux-saga/effects';
import { reduceOperation, reduceLoading, getSessionSaga } from 'metrix-common';
import { reduceListPolicies } from '../../actions/policies/reduceListPolicies';
import { selectQuery } from '../../selectors/policies/selectQuery';
import { selectView } from '../../selectors/policies/selectView';

export function* queryPoliciesSaga(action) {
    try {
        yield put(reduceLoading({ code: `queryPolicies`, value: true }));
        const session = yield getSessionSaga();
        let view = yield select(selectView());
        let query = yield select(selectQuery());

        query = query[`${view}`];

        let response = yield call(fetch, `/api/policyTransaction/search`, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Authorization': session.authenticationToken.token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ policyQuery: { ...action.payload, ...query } })
        });
        let result = yield call([response, 'json']);
        yield put(reduceOperation(result.operation));
        if (!result.operation.status) {
            yield put(reduceLoading({ code: `queryPolicies`, value: false }));
            return;
        }
        yield put(reduceListPolicies(result.searchResult));
        yield put(reduceLoading({ code: `queryPolicies`, value: false }));
        return;
    }
    catch (error) {
        console.log("Error while calling Query Policies API - ", error);
        yield put(reduceLoading({ code: `queryPolicies`, value: false }));
        return;
    }
}
