import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Container,
    Form,
    FormInput,
    FormDate,
    FormTextArea,
    FormRadio,
    FormLabel,    
    convertFromBoolean,
    convertToBoolean
} from 'metrix-common';

class ProfileFluxGlInsuranceHistory extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (this.props.policy.fluxCustomInfo.businessInfo?.isNewVenture === true || this.props.policy.fluxCustomInfo.businessInfo?.isNewVenture === undefined)
            ? <React.Fragment>
                <Form
                    header={<FormattedMessage id={`Insurance History`} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                <FormRadio
                    label={<FormattedMessage id={`Are you new in this business?`} />}
                    value={this.props.policy.fluxCustomInfo.businessInfo?.isNewVenture !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.businessInfo.isNewVenture) }}
                    references={this.props.reference?.references[`YesNo`]}
                    referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.isNewVenture`, value: convertToBoolean(value.code) })}
                />
                </Form>
            </React.Fragment>
            : (
                <React.Fragment>
                    <Form
                        header={<FormattedMessage id={`Insurance History`} />}
                        label={`Details`}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormRadio
                            label={<FormattedMessage id={`Were you previously insured?`} />}
                            value={this.props.policy.fluxCustomInfo.insuranceHistory?.hasPriorInsurance !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.insuranceHistory.hasPriorInsurance) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.hasPriorInsurance`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                        {this.props.policy.fluxCustomInfo.insuranceHistory?.hasPriorInsurance === true &&
                        <FormRadio
                            label={<FormattedMessage id={`Has any application for similar insurance made on behalf of the applicant and/or any principal, partner, owner, officer, director, employee, manager or managing member thereof or any predecessor, subsidiary or affiliated organization thereof ever been declined, cancelled, or non-renewed?`} />}
                            value={this.props.policy.fluxCustomInfo.insuranceHistory?.hasAnyDeclinedInsurance !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.insuranceHistory.hasAnyDeclinedInsurance) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.hasAnyDeclinedInsurance`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                    }
                    {this.props.policy.fluxCustomInfo.insuranceHistory?.hasPriorInsurance === true &&
                        <FormRadio
                            label={<FormattedMessage id={`Were there any claims in the last 5 years?`} />}
                            value={this.props.policy.fluxCustomInfo.insuranceHistory?.hasClaims !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.insuranceHistory.hasClaims) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.hasClaims`, value: convertToBoolean(value.code) })}
                            allowEdit
                            />                                               
                    }
                        {this.props.policy.fluxCustomInfo.insuranceHistory?.hasPriorInsurance === true && this.props.policy.fluxCustomInfo.insuranceHistory?.hasClaims === true &&
                        <FormLabel
                            text={`In the Claims Info sub-sections below, list prior coverage / claims information for past 5 years (currently valued loss runs are REQUIRED - must be current w/in 60 days of the requested effective date)`}
                        />                                                       
                    }
                    </Form>
                        {this.props.policy.fluxCustomInfo.insuranceHistory?.hasPriorInsurance === true &&
                        <Container>
                            <Form
                                header={<FormattedMessage id={'Last year insurance information'} />}
                                label={`Details`}
                                saveEvent={this.props.doUpsertPolicy}
                                cancelEvent={this.props.doGetPolicy}
                                allowEdit={this.props.allowEdit}
                            >
                                <FormInput
                                    label={<FormattedMessage id={`Carrier`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.firstPriorYearInsuranceInfo?.carrier}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.firstPriorYearInsuranceInfo.carrier`, value })}
                                    type='text'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`Limits`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.firstPriorYearInsuranceInfo?.limits}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.firstPriorYearInsuranceInfo.limits`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`Deductible`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.firstPriorYearInsuranceInfo?.deductible}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.firstPriorYearInsuranceInfo.deductible`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormDate
                                    label={<FormattedMessage id={`Retro date`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.firstPriorYearInsuranceInfo?.retroDate}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.firstPriorYearInsuranceInfo.retroDate`, value })}
                                    culture={this.props.context.session.culture}
                                    mode={`past`}
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`Premium`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.firstPriorYearInsuranceInfo?.premium}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.firstPriorYearInsuranceInfo.premium`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`Exposure base/rate`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.firstPriorYearInsuranceInfo?.exposureBaseRate}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.firstPriorYearInsuranceInfo.exposureBaseRate`, value })}
                                    type='number'
                                    allowEdit
                                />
                            </Form>
                            <Form
                                header={<FormattedMessage id={'2nd prior year insurance information'} />}
                                label={`Details`}
                                saveEvent={this.props.doUpsertPolicy}
                                cancelEvent={this.props.doGetPolicy}
                                allowEdit={this.props.allowEdit}
                            >
                                <FormInput
                                    label={<FormattedMessage id={`Carrier`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.secondPriorYearInsuranceInfo?.carrier}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.secondPriorYearInsuranceInfo.carrier`, value })}
                                    type='text'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`Limits`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.secondPriorYearInsuranceInfo?.limits}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.secondPriorYearInsuranceInfo.limits`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`Deductible`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.secondPriorYearInsuranceInfo?.deductible}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.secondPriorYearInsuranceInfo.deductible`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormDate
                                    label={<FormattedMessage id={`Retro date`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.secondPriorYearInsuranceInfo?.retroDate}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.secondPriorYearInsuranceInfo.retroDate`, value })}
                                    culture={this.props.context.session.culture}
                                    mode={`past`}
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`Premium`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.secondPriorYearInsuranceInfo?.premium}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.secondPriorYearInsuranceInfo.premium`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`Exposure base/rate`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.secondPriorYearInsuranceInfo?.exposureBaseRate}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.secondPriorYearInsuranceInfo.exposureBaseRate`, value })}
                                    type='number'
                                    allowEdit
                                />
                            </Form>
                            <Form
                                header={<FormattedMessage id={'3rd prior year insurance information'} />}
                                label={`Details`}
                                saveEvent={this.props.doUpsertPolicy}
                                cancelEvent={this.props.doGetPolicy}
                                allowEdit={this.props.allowEdit}
                            >
                                <FormInput
                                    label={<FormattedMessage id={`Carrier`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.thirdPriorYearInsuranceInfo?.carrier}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.thirdPriorYearInsuranceInfo.carrier`, value })}
                                    type='text'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`Limits`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.thirdPriorYearInsuranceInfo?.limits}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.thirdPriorYearInsuranceInfo.limits`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`Deductible`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.thirdPriorYearInsuranceInfo?.deductible}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.thirdPriorYearInsuranceInfo.deductible`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormDate
                                    label={<FormattedMessage id={`Retro date`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.thirdPriorYearInsuranceInfo?.retroDate}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.thirdPriorYearInsuranceInfo.retroDate`, value })}
                                    culture={this.props.context.session.culture}
                                    mode={`past`}
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`Premium`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.thirdPriorYearInsuranceInfo?.premium}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.thirdPriorYearInsuranceInfo.premium`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`Exposure base/rate`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.thirdPriorYearInsuranceInfo?.exposureBaseRate}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.thirdPriorYearInsuranceInfo.exposureBaseRate`, value })}
                                    type='number'
                                    allowEdit
                                />
                            </Form>
                        </Container>
                    }
                    {this.props.policy.fluxCustomInfo.insuranceHistory?.hasPriorInsurance === true && this.props.policy.fluxCustomInfo.insuranceHistory?.hasClaims === true &&
                        <Container>
                            <Form
                                header={<FormattedMessage id={'Last year claims info'} />}
                                label={`Details`}
                                saveEvent={this.props.doUpsertPolicy}
                                cancelEvent={this.props.doGetPolicy}
                                allowEdit={this.props.allowEdit}
                            >
                                <FormInput
                                    label={<FormattedMessage id={`Year`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.firstPriorYearClaim?.year}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.firstPriorYearClaim.year`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`# Of Claims`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.firstPriorYearClaim?.totalClaims}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.firstPriorYearClaim.totalClaims`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`Total Paid`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.firstPriorYearClaim?.totalPaid}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.firstPriorYearClaim.totalPaid`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`Total Reserves`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.firstPriorYearClaim?.totalReserves}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.firstPriorYearClaim.totalReserves`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`Total Incurred`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.firstPriorYearClaim?.totalIncurred}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.firstPriorYearClaim.totalIncurred`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormDate
                                    label={<FormattedMessage id={`Valuation Date`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.firstPriorYearClaim?.valuationDate}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.firstPriorYearClaim.valuationDate`, value })}
                                    culture={this.props.context.session.culture}
                                    mode={`past`}
                                    allowEdit
                                />
                            </Form>
                            <Form
                                header={<FormattedMessage id={'2nd year prior claims info'} />}
                                label={`Details`}
                                saveEvent={this.props.doUpsertPolicy}
                                cancelEvent={this.props.doGetPolicy}
                                allowEdit={this.props.allowEdit}
                            >
                                <FormInput
                                    label={<FormattedMessage id={`Year`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.secondPriorYearClaim?.year}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.secondPriorYearClaim.year`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`# Of Claims`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.secondPriorYearClaim?.totalClaims}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.secondPriorYearClaim.totalClaims`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`Total Paid`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.secondPriorYearClaim?.totalPaid}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.secondPriorYearClaim.totalPaid`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`Total Reserves`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.secondPriorYearClaim?.totalReserves}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.secondPriorYearClaim.totalReserves`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`Total Incurred`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.secondPriorYearClaim?.totalIncurred}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.secondPriorYearClaim.totalIncurred`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormDate
                                    label={<FormattedMessage id={`Valuation Date`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.secondPriorYearClaim?.valuationDate}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.secondPriorYearClaim.valuationDate`, value })}
                                    culture={this.props.context.session.culture}
                                    mode={`past`}
                                    allowEdit
                                />
                            </Form>
                            <Form
                                header={<FormattedMessage id={'3rd year prior claims info'} />}
                                label={`Details`}
                                saveEvent={this.props.doUpsertPolicy}
                                cancelEvent={this.props.doGetPolicy}
                                allowEdit={this.props.allowEdit}
                            >
                                <FormInput
                                    label={<FormattedMessage id={`Year`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.thirdPriorYearClaim?.year}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.thirdPriorYearClaim.year`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`# Of Claims`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.thirdPriorYearClaim?.totalClaims}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.thirdPriorYearClaim.totalClaims`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`Total Paid`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.thirdPriorYearClaim?.totalPaid}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.thirdPriorYearClaim.totalPaid`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`Total Reserves`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.thirdPriorYearClaim?.totalReserves}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.thirdPriorYearClaim.totalReserves`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`Total Incurred`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.thirdPriorYearClaim?.totalIncurred}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.thirdPriorYearClaim.totalIncurred`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormDate
                                    label={<FormattedMessage id={`Valuation Date`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.thirdPriorYearClaim?.valuationDate}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.thirdPriorYearClaim.valuationDate`, value })}
                                    culture={this.props.context.session.culture}
                                    mode={`past`}
                                    allowEdit
                                />
                            </Form>
                            <Form
                                header={<FormattedMessage id={'4th year prior claims info'} />}
                                label={`Details`}
                                saveEvent={this.props.doUpsertPolicy}
                                cancelEvent={this.props.doGetPolicy}
                                allowEdit={this.props.allowEdit}
                            >
                                <FormInput
                                    label={<FormattedMessage id={`Year`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.fourthPriorYearClaim?.year}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.fourthPriorYearClaim.year`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`# Of Claims`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.fourthPriorYearClaim?.totalClaims}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.fourthPriorYearClaim.totalClaims`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`Total Paid`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.fourthPriorYearClaim?.totalPaid}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.fourthPriorYearClaim.totalPaid`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`Total Reserves`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.fourthPriorYearClaim?.totalReserves}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.fourthPriorYearClaim.totalReserves`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`Total Incurred`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.fourthPriorYearClaim?.totalIncurred}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.fourthPriorYearClaim.totalIncurred`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormDate
                                    label={<FormattedMessage id={`Valuation Date`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.fourthPriorYearClaim?.valuationDate}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.fourthPriorYearClaim.valuationDate`, value })}
                                    culture={this.props.context.session.culture}
                                    mode={`past`}
                                    allowEdit
                                />
                            </Form>
                            <Form
                                header={<FormattedMessage id={'5th year prior claims info'} />}
                                label={`Details`}
                                saveEvent={this.props.doUpsertPolicy}
                                cancelEvent={this.props.doGetPolicy}
                                allowEdit={this.props.allowEdit}
                            >
                                <FormInput
                                    label={<FormattedMessage id={`Year`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.fifthPriorYearClaim?.year}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.fifthPriorYearClaim.year`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`# Of Claims`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.fifthPriorYearClaim?.totalClaims}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.fifthPriorYearClaim.totalClaims`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`Total Paid`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.fifthPriorYearClaim?.totalPaid}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.fifthPriorYearClaim.totalPaid`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`Total Reserves`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.fifthPriorYearClaim?.totalReserves}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.fifthPriorYearClaim.totalReserves`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormInput
                                    label={<FormattedMessage id={`Total Incurred`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.fifthPriorYearClaim?.totalIncurred}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.fifthPriorYearClaim.totalIncurred`, value })}
                                    type='number'
                                    allowEdit
                                />
                                <FormDate
                                    label={<FormattedMessage id={`Valuation Date`} />}
                                    value={this.props.policy.fluxCustomInfo.insuranceHistory.fifthPriorYearClaim?.valuationDate}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.fifthPriorYearClaim.valuationDate`, value })}
                                    culture={this.props.context.session.culture}
                                    mode={`past`}
                                    allowEdit
                                />
                            </Form>
                            <Form
                                header={<FormattedMessage id={'Explanation of any single loss(es) exceeding $10,000'} />}
                                label={`Details`}
                                saveEvent={this.props.doUpsertPolicy}
                                cancelEvent={this.props.doGetPolicy}
                                allowEdit={this.props.allowEdit}
                            >
                                <FormTextArea
                                    text={this.props.policy.fluxCustomInfo.insuranceHistory.lossesOver10000Explanation}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.insuranceHistory.lossesOver10000Explanation`, value })}
                                />
                            </Form>
                        </Container>
                    }
                </React.Fragment>);
    }
}

export default ProfileFluxGlInsuranceHistory;