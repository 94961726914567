import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Sizes,
    Alignments,
    Container,
    Divider,
    Header,
    BackButton,
    Form,
    FormDropDown,
    FormInput,
    SummaryGroup,
} from 'metrix-common';

import ProfileEmployersWcLocation from './Location';

class ProfileEmployersWcInsured extends React.Component {
    state = {
        mode: `insuredView`,
        currentLocation: null
    };

    componentDidMount() {
        if ((this.props.insured?.insuredName === undefined && this.props.legalBusinessName !== undefined) || this.props.insured?.insuredName !== this.props.legalBusinessName) {
            this.props.doReducePolicyElement({ code: `${this.props.dotProp}insuredName`, value: this.props.legalBusinessName });
            this.props.doUpsertPolicy();
        }

        if ((this.props.insured?.federalEmployerIdentificationNumber === undefined && this.props.federalEmployerIdentificationNumber !== undefined) || this.props.insured?.federalEmployerIdentificationNumber !== this.props.federalEmployerIdentificationNumber) {
            this.props.doReducePolicyElement({ code: `${this.props.dotProp}federalEmployerIdentificationNumber`, value: this.props.federalEmployerIdentificationNumber });
            this.props.doUpsertPolicy();
        }

        if ((this.props.insured?.legalEntityTypeCode === undefined && this.props.legalEntityTypeCode !== undefined) || this.props.insured?.legalEntityTypeCode !== this.props.legalEntityTypeCode) {
            this.props.doReducePolicyElement({ code: `${this.props.dotProp}legalEntityTypeCode`, value: this.props.legalEntityTypeCode });
            this.props.doReducePolicyElement({ code: `${this.props.dotProp}legalEntityTypeValue`, value: this.props.legalEntityTypeValue });
            this.props.doUpsertPolicy();
        }
    }

    getLocationData = () => {
        let locations = [];
        let states = [];

        if (this.props.locations && this.props.locations.filter(x => x.insuredGuid === this.props.insured.guid).length > 0) {
            this.props.locations.filter(x => x.insuredGuid === this.props.insured.guid).forEach((location) => {
                let option = { code: 'locationOption', value: location.guid, unDeletable: location.isPrimary };

                let locationName = location.locationName || 'New Location';

                option.elements = [];
                option.elements.push({ code: 'Label', value: locationName });

                locations.push(option);

                states.push(location?.address?.stateCode);
            });
        }

        return [locations, states];
    }

    getInsuredName = () => {
        let insuredName = null;

        if (this.props.insured && this.props.insured.insuredName) {
            insuredName = `${this.props.insured.insuredName}`;
        }

        return insuredName ?? `New Insured`;
    }

    render() {
        let insuredName = this.getInsuredName();
        let [locations, states] = this.getLocationData();
        let hasPartner = (states.some(x => x === 'CA') && (this.props.insured.legalEntityTypeCode === `GP` || this.props.insured.legalEntityTypeCode === `LP` || this.props.insured.legalEntityTypeCode === `LT`));

        return (
            <React.Fragment>
                {
                    this.state.mode === 'insuredView' &&
                    <React.Fragment>
                        <BackButton clickEvent={this.props.backEvent} />
                        <Form
                            rowId={this.props.rowId}
                            sectionCode='insuredReadonlyView'
                            pageCode={this.props.pageCode}
                            saveTooltipInfo={this.props.saveTooltipInfo}
                            reduceTooltipInfoElement={this.props.reduceTooltipInfoElement}
                            tooltipInfo={this.props.tooltipInfo}
                            validationInfo={this.props.validationInfo}
                            header={<FormattedMessage id={insuredName} />}
                            label={'Insured Baseline Data'}
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            allowEdit={false}
                        >
                            <FormInput
                                fieldCode='insuredName'
                                label={<FormattedMessage id={'Label'} />}
                                value={this.props.insured?.insuredName ?? this.props.legalBusinessName}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}insuredName`, value })}
                                allowEdit={false}
                            />
                            <FormInput
                                fieldCode='primaryRiskStateCode'
                                label={<FormattedMessage id={'Primary Risk State'} />}
                                value={this.props?.primaryRiskStateCode}
                                allowEdit={false}
                            />
                            <FormInput
                                fieldCode='federalEmployerIdentificationNumber'
                                label={`FEIN Number`}
                                value={this.props.insured?.federalEmployerIdentificationNumber ?? this.props.federalEmployerIdentificationNumber}
                                type='number'
                                allowEdit={false}
                                changeEvent={value => this.props.doReducePolicyElement({ code: `${this.props.dotProp}federalEmployerIdentificationNumber`, value })}
                                overrideMaxLength={9}
                            />
                            <FormDropDown
                                fieldCode='legalEntityTypeCode'
                                label={<FormattedMessage id={`Legal Entity Type`} />}
                                value={{ code: this.props.insured?.legalEntityTypeCode, value: this.props.insured?.legalEntityTypeValue }}
                                references={this.props.reference?.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`LegalEntities`] && this.props.reference.references[`EMPLOYERS-WC`][`LegalEntities`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.LegalEntities`)}
                                changeEvent={(value) => {
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}legalEntityTypeCode`, value: value.code });
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}legalEntityTypeValue`, value: value.value });
                                }}
                                allowEdit={false}
                            />
                            </Form>
                            {/*At least two partner names are required to bind when Named Insureds have CA Locations with a Legal Entity of Partnership (GP), Limited Partnership (LP), or Limited Liability Partnership (LT).*/}
                            {/*If no partner names are passed, the first partner name will be defaulted to the name of the first named insured.*/}
                            {hasPartner &&
                                <Form
                                    rowId={this.props.rowId}
                                    sectionCode='insuredView'
                                    pageCode={this.props.pageCode}
                                    saveTooltipInfo={this.props.saveTooltipInfo}
                                    reduceTooltipInfoElement={this.props.reduceTooltipInfoElement}
                                    tooltipInfo={this.props.tooltipInfo}
                                    validationInfo={this.props.validationInfo}
                                    label={'Details'}
                                    saveEvent={this.props.doUpsertPolicy}
                                    cancelEvent={this.props.doGetPolicy}
                                    allowEdit={this.props.allowEdit}
                                >
                                    <FormInput
                                        fieldCode='firstPartnerFirstName'
                                        label={<FormattedMessage id={`First Partner First Name`} />}
                                        value={this.props.insured?.firstPartnerFirstName}
                                        changeEvent={(value) => this.props.doReducePolicyElement({
                                            code: `${this.props.dotProp}firstPartnerFirstName`,
                                            value
                                        })}
                                        allowEdit
                                    />
                                    <FormInput
                                        fieldCode='firstPartnerLastName'
                                        label={<FormattedMessage id={`First Partner Last Name`} />}
                                        value={this.props.insured?.firstPartnerLastName}
                                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}firstPartnerLastName`, value })}
                                        allowEdit
                                    />
                                    <FormInput
                                        fieldCode='secondPartnerFirstName'
                                        label={<FormattedMessage id={`Second Partner First Name`} />}
                                        value={this.props.insured?.secondPartnerFirstName}
                                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}secondPartnerFirstName`, value })}
                                        allowEdit
                                    />
                                    <FormInput
                                        fieldCode='secondPartnerLastName'
                                        label={<FormattedMessage id={`Second Partner Last Name`} />}
                                        value={this.props.insured?.secondPartnerLastName}
                                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}secondPartnerLastName`, value })}
                                        allowEdit
                                    />
                                </Form>  
                        }
                        
                        <Container>
                            {
                                this.state.mode === `insuredView` &&
                                <React.Fragment>
                                    <Divider top={Sizes.xl} />
                                    <Header size={Sizes.lg} textAlign={Alignments.left} text={`Locations`} />
                                    <Container top={Sizes.xl} bottom={Sizes.xl}>
                                        <SummaryGroup
                                            allowAdd={this.props.allowEdit && (this.props.locations === undefined || locations.length < 4)}
                                            addEvent={() => {
                                                this.props.doReduceParameter({ code: `AdditionalInsuredGuid`, value: this.props.insured.guid });
                                                this.props.doExecutePolicyAction({ code: `EMPLOYERS-WC.CREATELOCATION` });
                                            }}
                                            allowEdit
                                            editEvent={(location) => {
                                                this.setState({ currentLocation: location, mode: `locationView` });
                                                this.props.doReduceParameter([{ code: `LocationGuid`, value: location }]);
                                            }}
                                            allowDelete={this.props.allowEdit && locations.length > 1}
                                            deleteEvent={(location) => {
                                                this.props.doReduceParameter({ code: `LocationGuid`, value: location });
                                                this.props.doExecutePolicyAction({ code: `EMPLOYERS-WC.DELETELOCATION` });
                                            }}
                                            entities={locations}
                                            icon={`home`}
                                        />
                                    </Container>
                                </React.Fragment>
                            }
                        </Container>
                    </React.Fragment>
                }
                {
                    this.state.mode === `locationView` &&
                    <ProfileEmployersWcLocation
                        rowId={this.state.currentLocation}
                        pageCode={this.props.pageCode}
                        saveTooltipInfo={this.props.saveTooltipInfo}
                        reduceTooltipInfoElement={this.props.reduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        location={this.props.locations.find(x => x.guid === this.state.currentLocation)}
                        hasOwners={this.props.insured?.hasOwners}
                        owners={this.props.owners}
                        legalEntityTypeCode={this.props.insured?.legalEntityTypeCode}
                        classifications={this.props.classifications}
                        dotProp={`employersWcCustomInfo.locations.${this.props.locations.findIndex(x => x.guid === this.state.currentLocation)}.`}
                        backEvent={() => this.setState({ mode: `insuredView`, currentLocation: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReduceParameter={this.props.doReduceParameter}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        doExecutePolicyAction={this.props.doExecutePolicyAction}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
            </React.Fragment>
        );
    }
}

export default ProfileEmployersWcInsured;