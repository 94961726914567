import { put, call } from 'redux-saga/effects';
import { reduceOperation, reduceLoading, getSessionSaga } from 'metrix-common';
import { reduceBoundPolicies } from '../../actions/policies/reduceBoundPolicies';

export function* getBoundPoliciesSaga(action) {
    try {
        yield put(reduceLoading({ code: 'getBoundPolicies', value: true }));
        const session = yield getSessionSaga();

        const response = yield call(fetch, '/api/policyTransaction/bound', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Authorization': session.authenticationToken.token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ policyQuery: { take: 25, skip: 0 } })
        });

        const result = yield call([response, 'json']);
        
        yield put(reduceOperation(result.operation));
        
        if (!result.operation.status) return;

        yield put(reduceBoundPolicies(result.searchResult));
    }
    catch (error) {
        console.log('Error while calling Get Board Policies API - ', error);
    }
    finally {
        yield put(reduceLoading({ code: 'getBoundPolicies', value: false }));
    }
}
