import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Alignments,
    Form,
    FormDropDown,
    FormTextArea,
    FormRadio,
    FormSwitch,
    Label,
    convertFromBoolean,
    convertToBoolean
} from 'metrix-common';
class ProfileFluxGlProductLiability extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            <React.Fragment>
                <Form
                    header={<FormattedMessage id={`Product Liability`} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormDropDown
                        label={<FormattedMessage id={`Product Liability Limits`} />}
                        value={{ code: this.props.policy.fluxCustomInfo.productLiabilityInfo?.productLiabilityLimitTypeCode, value: this.props.policy.fluxCustomInfo.productLiabilityInfo?.productLiabilityLimitTypeName }}
                        references={this.props.reference?.references[`FLUX-GL`] && this.props.reference.references[`FLUX-GL`][`ProductLiabilityLimitTypes`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`FLUX-GL.ProductLiabilityLimitTypes`)}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `fluxCustomInfo.productLiabilityInfo.productLiabilityLimitTypeCode`, value: value.code });
                            this.props.doReducePolicyElement({ code: `fluxCustomInfo.productLiabilityInfo.productLiabilityLimitTypeName`, value: value.value });
                        }}
                        allowEdit
                    />

                    <FormDropDown
                        label={<FormattedMessage id={`Retro Date (No automatically included, if adding retro date, please include loss runs and premiums for each prior year)`} />}
                        value={{ code: this.props.policy.fluxCustomInfo.productLiabilityInfo?.productLiabilityRetroDateTypeCode, value: this.props.policy.fluxCustomInfo.productLiabilityInfo?.productLiabilityRetroDateTypeName }}
                        references={this.props.reference?.references[`FLUX-GL`] && this.props.reference.references[`FLUX-GL`][`ProductLiabilityRetroDateTypes`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`FLUX-GL.ProductLiabilityRetroDateTypes`)}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `fluxCustomInfo.productLiabilityInfo.productLiabilityRetroDateTypeCode`, value: value.code });
                            this.props.doReducePolicyElement({ code: `fluxCustomInfo.productLiabilityInfo.productLiabilityRetroDateTypeName`, value: value.value });
                        }}
                        allowEdit
                    />

                    <FormDropDown
                        label={<FormattedMessage id={`Product Withdrawal Coverage Limits & Deductibles`} />}
                        value={{ code: this.props.policy.fluxCustomInfo.productLiabilityInfo?.productLiabilityWithdrawalCoverageLimitTypeCode, value: this.props.policy.fluxCustomInfo.productLiabilityInfo?.productLiabilityWithdrawalCoverageLimitTypeName }}
                        references={this.props.reference?.references[`FLUX-GL`] && this.props.reference.references[`FLUX-GL`][`ProductLiabilityWithdrawalCoverageLimitTypes`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`FLUX-GL.ProductLiabilityWithdrawalCoverageLimitTypes`)}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `fluxCustomInfo.productLiabilityInfo.productLiabilityWithdrawalCoverageLimitTypeCode`, value: value.code });
                            this.props.doReducePolicyElement({ code: `fluxCustomInfo.productLiabilityInfo.productLiabilityWithdrawalCoverageLimitTypeName`, value: value.value });
                        }}
                        allowEdit
                    />

                    <FormRadio //1
                        label={<FormattedMessage id={`Are there any employed professionals (e.g. physicians or pharmacists)?`} />}
                        value={this.props.policy.fluxCustomInfo.productLiabilityInfo?.hasEmployedProfessionals !== undefined //one
                            && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.productLiabilityInfo.hasEmployedProfessionals) }} //two
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({
                            code: `fluxCustomInfo.productLiabilityInfo.hasEmployedProfessionals`, //three
                            value: convertToBoolean(value.code)
                        })}
                        allowEdit />
                    {
                        (this.props.policy.fluxCustomInfo.productLiabilityInfo.hasEmployedProfessionals) === true &&
                            <FormRadio //2
                                label={<FormattedMessage id={`Do the employed professionals carry their own professional liability insurance?`} />}
                                value={this.props.policy.fluxCustomInfo.productLiabilityInfo?.hasOwnProfessionalLiabilityInsurance !== undefined //one
                                    && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.productLiabilityInfo.hasOwnProfessionalLiabilityInsurance) }} //two
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({
                                    code: `fluxCustomInfo.productLiabilityInfo.hasOwnProfessionalLiabilityInsurance`, //three
                                    value: convertToBoolean(value.code)
                                })}
                                allowEdit />
                    }
                    {/* Question 3 is checklist*/}
                    <FormDropDown //4
                        label={<FormattedMessage id={`How much inventory is displayed to customers?`} />}
                        value={{ code: this.props.policy.fluxCustomInfo.productLiabilityInfo?.displayedInventoryToCustomersCode, value: this.props.policy.fluxCustomInfo.productLiabilityInfo?.displayedInventoryToCustomersName }}
                        references={this.props.reference?.references[`FLUX-GL`] && this.props.reference.references[`FLUX-GL`][`DisplayedInventoryToCustomers`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`FLUX-GL.DisplayedInventoryToCustomers`)}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `fluxCustomInfo.productLiabilityInfo.displayedInventoryToCustomersCode`, value: value.code });
                            this.props.doReducePolicyElement({ code: `fluxCustomInfo.productLiabilityInfo.displayedInventoryToCustomersName`, value: value.value });
                        }}
                        allowEdit
                    />
                    
                    <FormRadio //5
                        label={<FormattedMessage id={`Is any on-site consumption of cannabis or products containing cannabis permitted?`} />}
                        value={this.props.policy.fluxCustomInfo.productLiabilityInfo?.isOnsiteConsumptionPermitted !== undefined //one
                            && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.productLiabilityInfo.isOnsiteConsumptionPermitted) }} //two
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({
                            code: `fluxCustomInfo.productLiabilityInfo.isOnsiteConsumptionPermitted`, //three
                            value: convertToBoolean(value.code)
                        })}
                        allowEdit />
                    {/*6*/}
                    {
                        (this.props.policy.fluxCustomInfo.productLiabilityInfo.isOnsiteConsumptionPermitted) === true &&
                        <Label bold text={<FormattedMessage id={`If "Yes", please explain what is allowed:`} />} textAlign={Alignments.left} />
                    }
                    {(this.props.policy.fluxCustomInfo.productLiabilityInfo.isOnsiteConsumptionPermitted) === true &&
                        <FormTextArea
                        text={this.props.policy.fluxCustomInfo.productLiabilityInfo?.onsiteConsumptionPermittedDescription}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.productLiabilityInfo.onsiteConsumptionPermittedDescription`, value })}
                        />
                    }
                    <FormRadio //7
                        label={<FormattedMessage id={`Does applicant offer delivery of cannabis products?`} />}
                        value={this.props.policy.fluxCustomInfo.productLiabilityInfo?.isDeliveryProductsOffered !== undefined //one
                            && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.productLiabilityInfo.isDeliveryProductsOffered) }} //two
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({
                            code: `fluxCustomInfo.productLiabilityInfo.isDeliveryProductsOffered`, //three
                            value: convertToBoolean(value.code)
                        })}
                        allowEdit />
                    {/*8*/}
                    <Label bold text={<FormattedMessage id={`What is the highest concentration (%) and dosage (mg) of active cannabinoids per serving contained in the applicant's strongest (i.e. highest dosage) product? Please provide product name, concentration (%) and dosage (mg) of active cannabinoids per serving:`} />} textAlign={Alignments.left} />
                    <FormTextArea
                        text={this.props.policy.fluxCustomInfo.productLiabilityInfo?.strongestProductDescription}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.productLiabilityInfo.strongestProductDescription`, value })}
                    />
                    <FormRadio //9
                        label={<FormattedMessage id={`If the applicant distributes cannabis oils or concentrates with concentrations greater than 70% or dosages per serving greater than 50mg, are these products only distributed to patients who have a physician recommendation for high dose product(s) or documented tolerances built up over time?`} />}
                        value={this.props.policy.fluxCustomInfo.productLiabilityInfo?.isHighDoseProductsDistributed !== undefined //one
                            && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.productLiabilityInfo.isHighDoseProductsDistributed) }} //two
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({
                            code: `fluxCustomInfo.productLiabilityInfo.isHighDoseProductsDistributed`, //three
                            value: convertToBoolean(value.code)
                        })}
                        allowEdit />
                    {/*10*/}
                    {
                        (this.props.policy.fluxCustomInfo.productLiabilityInfo.isHighDoseProductsDistributed) === false &&
                        <Label bold text={<FormattedMessage id={`If "No", please explain how the applicant controls access to these high dose/concentration products:`} />} textAlign={Alignments.left} />
                    }
                    {(this.props.policy.fluxCustomInfo.productLiabilityInfo.isHighDoseProductsDistributed) === false &&
                        <FormTextArea
                        text={this.props.policy.fluxCustomInfo.productLiabilityInfo?.highDoseAccessControlDescription}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.productLiabilityInfo.highDoseAccessControlDescription`, value })}
                        />
                    }
                    <FormRadio //11
                        label={<FormattedMessage id={`If the applicant distributes cannabis oil or concentrates manufactured by others, does the applicant only obtain these products from manufacturers that utilize a closed-loop extraction system, and non-volatile solvents in their extraction process?`} />}
                        value={this.props.policy.fluxCustomInfo.productLiabilityInfo?.isClosedLoopExtractionSystem !== undefined //one
                            && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.productLiabilityInfo.isClosedLoopExtractionSystem) }} //two
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({
                            code: `fluxCustomInfo.productLiabilityInfo.isClosedLoopExtractionSystem`, //three
                            value: convertToBoolean(value.code)
                        })}
                        allowEdit />
                    {/*12*/}
                    {
                        (this.props.policy.fluxCustomInfo.productLiabilityInfo.isClosedLoopExtractionSystem) === false &&
                        <Label bold text={<FormattedMessage id={`If "No", what type of extraction system and solvents are used by the insured's manufacturers/suppliers?`} />} textAlign={Alignments.left} />
                    }
                    {(this.props.policy.fluxCustomInfo.productLiabilityInfo.isClosedLoopExtractionSystem) === false &&
                        <FormTextArea
                        text={this.props.policy.fluxCustomInfo.productLiabilityInfo?.extractionSystemDescription}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.productLiabilityInfo.extractionSystemDescription`, value })}
                        />
                    }


                    <FormRadio //13
                        label={<FormattedMessage id={`Does the applicant maintain a ledger with a record of the quantity of cannabis or products containing cannabis dispensed in each transaction, the type and source of cannabis dispensed, the total amount paid by the customer for all goods and services provided, and the date and time dispensed?`} />}
                        value={this.props.policy.fluxCustomInfo.productLiabilityInfo?.hasLedger !== undefined //one
                            && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.productLiabilityInfo.hasLedger) }} //two
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({
                            code: `fluxCustomInfo.productLiabilityInfo.hasLedger`, //three
                            value: convertToBoolean(value.code)
                        })}
                        allowEdit />
                    <FormRadio //14
                        label={<FormattedMessage id={`Does the applicant grow medical or recreational cannabis or are there cannabis plants on the premises? `} />}
                        value={this.props.policy.fluxCustomInfo.productLiabilityInfo?.isProductGrownOnPremise !== undefined //one
                            && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.productLiabilityInfo.isProductGrownOnPremise) }} //two
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({
                            code: `fluxCustomInfo.productLiabilityInfo.isProductGrownOnPremise`, //three
                            value: convertToBoolean(value.code)
                        })}
                        allowEdit />
                    {
                        (this.props.policy.fluxCustomInfo.isProductGrownOnPremise) === true &&
                        <FormRadio //15
                            label={<FormattedMessage id={`I have used, or will use a licensed and insured contractor for all electrical work at our cultivation facility.`} />}
                            value={this.props.policy.fluxCustomInfo.productLiabilityInfo?.isLicensedAndInsuredContractorUsedAgreement !== undefined //one
                                && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.productLiabilityInfo.isLicensedAndInsuredContractorUsedAgreement) }} //two
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({
                                code: `fluxCustomInfo.productLiabilityInfo.isLicensedAndInsuredContractorUsedAgreement`, //three
                                value: convertToBoolean(value.code)
                            })}
                            allowEdit />
                    }
                    {
                        (this.props.policy.fluxCustomInfo.isProductGrownOnPremise) === true &&
                        <FormRadio //16
                            label={<FormattedMessage id={`I have, or will have, within 30 days of the insurance effective date, all of the wiring at the cultivation facility inspected by a licensed and insured contractor.`} />}
                            value={this.props.policy.fluxCustomInfo.productLiabilityInfo?.isLicensedAndInsuredContractorInspectionAgreement !== undefined //one
                                && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.productLiabilityInfo.isLicensedAndInsuredContractorInspectionAgreement) }} //two
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({
                                code: `fluxCustomInfo.productLiabilityInfo.isLicensedAndInsuredContractorInspectionAgreement`, //three
                                value: convertToBoolean(value.code)
                            })}
                            allowEdit />
                    }
                    <FormRadio //17
                        label={<FormattedMessage id={`Are any products containing cannabis manufactured, mixed, labelled, or relabeled by the applicant including: cannabis infused edible products, infused oils or lotions, other food products, or smoking accessories?`} />}
                        value={this.props.policy.fluxCustomInfo.productLiabilityInfo?.isProductManufacturedMixedLabelledRelabelled !== undefined //one
                            && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.productLiabilityInfo.isProductManufacturedMixedLabelledRelabelled) }} //two
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({
                            code: `fluxCustomInfo.productLiabilityInfo.isProductManufacturedMixedLabelledRelabelled`, //three
                            value: convertToBoolean(value.code)
                        })}
                        allowEdit />
                    <FormRadio //18
                        label={<FormattedMessage id={`Do any products, ingredients, or components originate from outside of the United States?`} />}
                        value={this.props.policy.fluxCustomInfo.productLiabilityInfo?.isProductOutsideUnitedStates !== undefined //one
                            && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.productLiabilityInfo.isProductOutsideUnitedStates) }} //two
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({
                            code: `fluxCustomInfo.productLiabilityInfo.isProductOutsideUnitedStates`, //three
                            value: convertToBoolean(value.code)
                        })}
                        allowEdit />
                    {/*19*/}
                    {
                        (this.props.policy.fluxCustomInfo.productLiabilityInfo.isProductOutsideUnitedStates) === true &&
                        <Label bold text={<FormattedMessage id={`If "Yes", what products are imported, and what are their respective country(ies) of origin?`} />} textAlign={Alignments.left} />
                    }
                    {
                        (this.props.policy.fluxCustomInfo.productLiabilityInfo.isProductOutsideUnitedStates) === true &&
                        <FormTextArea
                            text={this.props.policy.fluxCustomInfo.productLiabilityInfo?.importedProductDetails}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.productLiabilityInfo.importedProductDetails`, value })}
                    />
                    }
                    <FormRadio //20
                        label={<FormattedMessage id={`Are imported products and components tested for contamination and verification that they match what was ordered?`} />}
                        value={this.props.policy.fluxCustomInfo.productLiabilityInfo?.isContaminationAndVerification !== undefined //one
                            && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.productLiabilityInfo.isContaminationAndVerification) }} //two
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({
                            code: `fluxCustomInfo.productLiabilityInfo.isContaminationAndVerification`, //three
                            value: convertToBoolean(value.code)
                        })}
                        allowEdit />
                    <FormRadio //21
                        label={<FormattedMessage id={`For products that the applicant does not produce or manufacture, does the applicant obtain certificates of insurance (COI's) evidencing products coverage and Additional Insured status from all US based manufacturers or suppliers?`} />}
                        value={this.props.policy.fluxCustomInfo.productLiabilityInfo?.hasCertificateOfInsurance !== undefined //one
                            && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.productLiabilityInfo.hasCertificateOfInsurance) }} //two
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({
                            code: `fluxCustomInfo.productLiabilityInfo.hasCertificateOfInsurance`, //three
                            value: convertToBoolean(value.code)
                        })}
                        allowEdit />
                    <FormRadio //22
                        label={<FormattedMessage id={`For products that the applicant does not produce, does applicant obtain certificates of analysis (COA's) evidencing that product testing was performed by the original manufacturer or by the insured's direct supplier? `} />}
                        value={this.props.policy.fluxCustomInfo.productLiabilityInfo?.hasCertificateOfAnalysis !== undefined //one
                            && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.productLiabilityInfo.hasCertificateOfAnalysis) }} //two
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({
                            code: `fluxCustomInfo.productLiabilityInfo.hasCertificateOfAnalysis`, //three
                            value: convertToBoolean(value.code)
                        })}
                        allowEdit />
                </Form>
                <Form
                    header={<FormattedMessage id={`How does the dispensary ensure compliance with state law?`} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormSwitch
                        label={<FormattedMessage id={`Checking photo identification and registration card of patient`} />}
                        value={this.props.policy.fluxCustomInfo.productLiabilityInfo?.hasCheckPhotoAndRegistration}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.productLiabilityInfo.hasCheckPhotoAndRegistration`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Confirming physician's recommendation`} />}
                        value={this.props.policy.fluxCustomInfo.productLiabilityInfo?.hasConfirmRecommendation}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.productLiabilityInfo.hasConfirmRecommendation`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Checking photo identification to verify consumer is over 21`} />}
                        value={this.props.policy.fluxCustomInfo.productLiabilityInfo?.hasCheckOver21}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.productLiabilityInfo.hasCheckOver21`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Other`} />}
                        value={this.props.policy.fluxCustomInfo.productLiabilityInfo?.hasStateLawEnsureOther}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.productLiabilityInfo.hasStateLawEnsureOther`, value: value })}
                        allowEdit
                    />
                    {this.props.policy.fluxCustomInfo.productLiabilityInfo?.hasStateLawEnsureOther === true &&
                        <Label bold text={<FormattedMessage id={`Please provide a description for other evidence of legal compliance`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.fluxCustomInfo.productLiabilityInfo?.hasStateLawEnsureOther === true &&
                        <FormTextArea
                            text={this.props.policy.fluxCustomInfo.productLiabilityInfo?.stateLawEnsureOtherDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.productLiabilityInfo.stateLawEnsureOtherDescription`, value })}
                        />
                    }
                </Form>
            </React.Fragment>
        );
    }
}

/*
 * 1. hasEmployedProfessionals HasEmployedProfessionals
 * 2. hasOwnProfessionalLiabilityInsurance HasOwnProfessionalLiabilityInsurance
 * 3. ensureComplianceWithStateLaw EnsureComplianceWithStateLaw -- Not yet implemented
 * 4. displayedInventoryToCustomers DisplayedInventoryToCustomers -- Not yet implemented
 * 5. isOnsiteConsumptionPermitted IsOnsiteConsumptionPermitted
 * 6. onsiteConsumptionPermittedDescription OnsiteConsumptionPermittedDescription 
 * 7. isDeliveryProductsOffered IsDeliveryProductsOffered
 * 8. strongestProductDescription StrongestProductDescription
 * 9. isHighDoseProductsDistributed IsHighDoseProductsDistributed
 * 10. highDoseAccessControlDescription HighDoseAccessControlDescription
 * 11. isClosedLoopExtractionSystem IsClosedLoopExtractionSystem
 * 12. extractionSystemDescription ExtractionSystemDescription
 * 13. hasLedger HasLedger
 * 14. isProductGrownOnPremise IsProductGrownOnPremise
 * 15. isLicensedAndInsuredContractorUsedAgreement IsLicensedAndInsuredContractorUsedAgreement
 * 16. isLicensedAndInsuredContractorInspectionAgreement IsLicensedAndInsuredContractorInspectionAgreement
 * 17. isProductManufacturedMixedLabelledRelabelled IsProductManufacturedMixedLabelledRelabelled
 * 18. isProductOutsideUnitedStates IsProductOutsideUnitedStates
 * 19. importedProductDetails ImportedProductDetails
 * 20. isContaminationAndVerification IsContaminationAndVerification
 * 21. hasCertificateOfInsurance HasCertificateOfInsurance
 * 22. hasCertificateOfAnalysis HasCertificateOfAnalysis
 * 
 * 
 */

export default ProfileFluxGlProductLiability;

