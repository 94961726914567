import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import {
    Sizes,
    Separator,
    Grid,
    Row,
    Column,
    Header,
    Label,
    Container
} from 'metrix-common';
import Badge from '../../Summary/Badge';

const HeaderContentFlex = styled.div`
	display: flex;
	flex: 1;
	gap: 1rem;
	justify-content: ${({ $align }) => ($align === 'left' ? 'flex-start' : $align === 'right' ? 'flex-end' : 'center')};
	align-items: center;
	padding: 0 5px;
    margin-top: 30px;
	box-sizing: border-box;
	overflow: hidden;
`;

class ProfileSummaryHeader extends React.Component {
    getBadgeColor(status, type) {
        const colors = {
            badgeDocumentIssueStatus: {
                'Policy Issued': 'green',
                'Proposal Issued': 'yellow',
                'Not Issued': 'red'
            },
            badgePaymentStatus: {
                'Paid': 'green',
                'Not Paid': 'red'
            },
            badgePolicyStatusDescription: {
                'None': 'grey',
                'Incomplete': 'yellow',
                'Submitted': 'yellow',
                'Abandoned': 'yellow',
                'Accepted': 'yellow',
                'Quoted': 'yellow',
                'Quoted (indicative)': 'orange',
                'Underwriting Required': 'yellow',
                'Referred': 'yellow',
                'Quote Issued': 'yellow',
                'Bind Requested': 'yellow',
                'Bound': 'yellow',
                'Issued': 'green',
                'Lost': 'red',
                'Cancelled': 'red',
                'Quote Accepted': 'yellow',
                'Quoting': 'yellow'
            }
        };
        return colors[type][status] || 'grey';
    }

    render() {
        const { policySummary } = this.props;
        const showIndicativeQuoteBadge = policySummary.isIndicativeQuote && policySummary.status  === 'quoted';
        const paymentStatus = policySummary.badgePaymentStatus;
        const policyStatus = showIndicativeQuoteBadge ? 'Quoted (indicative)' : policySummary.badgePolicyStatusDescription;
        const documentIssueStatus = policySummary.badgeDocumentIssueStatus;

        const showPaymentBadge = paymentStatus ?? false;
        const showPolicyBadge = policyStatus ?? false;
        const showDocumentBadge = documentIssueStatus ?? false;
        const showHeaderContentFlex = showPaymentBadge || showPolicyBadge || showDocumentBadge;

        const productName = this.props.policySummary.productName;

        return (
            <Container fullWidth>
                <Grid>
                    <Row>
                        <Column>
                            <Header
                                size={Sizes.xl}
                                top={Sizes.sm}
                                bottom={Sizes.xs}
                                text={
                                    <FormattedMessage
                                        id={`${productName} Summary`}
                                    />}
                            />
                            <Label
                                text={
                                    <FormattedMessage
                                        id={`View past quotes or create a new one.`}
                                    />
                                }
                            />
                            {showHeaderContentFlex && (
                                <HeaderContentFlex>
                                    {showPaymentBadge && <Badge text={paymentStatus} color={this.getBadgeColor(paymentStatus, 'badgePaymentStatus')} />}
                                    {showPolicyBadge && <Badge text={policyStatus} color={this.getBadgeColor(policyStatus, 'badgePolicyStatusDescription')} />}
                                    {showDocumentBadge && <Badge text={documentIssueStatus} color={this.getBadgeColor(documentIssueStatus, 'badgeDocumentIssueStatus')} />}
                                </HeaderContentFlex>
                            )}
                        </Column>
                    </Row>
                </Grid>
                <Separator solid top={Sizes.lg} />
            </Container>
        );
    }
}

export default ProfileSummaryHeader;
