import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Alert,
    Alignments,
    Container,
    Divider,
    Form,
    FormSwitch,
    Header,
    Sizes,
    SummaryGroup,
    getPageValidationInfo
} from 'metrix-common';

import ProfileEmployersWcClaim from './Claim';

class ProfileEmployersWcPriorInsurance extends React.Component {
    componentDidMount() {
        const pageHasTooltipInfo = this.props.tooltipInfo && this.props.tooltipInfo.pages && this.props.tooltipInfo.pages.length && this.props.tooltipInfo.pages.some(p => p.code === this.props.pageCode);
        if (!pageHasTooltipInfo) {
            this.props.doGetTooltipInfo(this.props.policy.product);
        }
    }
    
    state = {
        mode: `insuranceHistoryView`,
        currentClaim: null,
        currentHasWorkersCompensationInsuranceClaims: null
    };

    handleSave = () => {

        let currentHasWorkersCompensationInsuranceClaims = this.state.currentHasWorkersCompensationInsuranceClaims;
        this.props.doReducePolicyElement({ code: `employersWcCustomInfo.insuranceHistory.hasWorkersCompensationInsuranceClaims`, value: currentHasWorkersCompensationInsuranceClaims });

        if (currentHasWorkersCompensationInsuranceClaims === true && this.props.policy?.employersWcCustomInfo?.claims && this.props.policy?.employersWcCustomInfo?.claims.length === 0) {
            this.props.doExecutePolicyAction({ code: `EMPLOYERS-WC.CREATECLAIMS` });
        }
        if (currentHasWorkersCompensationInsuranceClaims === false && this.props.policy?.employersWcCustomInfo?.claims && this.props.policy?.employersWcCustomInfo?.claims.length > 0) {
            this.props.doExecutePolicyAction({ code: `EMPLOYERS-WC.DELETECLAIMS` });
        }

        return this.props.doUpsertPolicy;
    }

    getClaims = () => {
        let claims = [];

        if (this.props.policy?.employersWcCustomInfo?.claims && this.props.policy.employersWcCustomInfo.claims.length > 0) {
            this.props.policy.employersWcCustomInfo.claims.forEach((claim) => {
                let option = { code: `claimOption`, value: claim.guid };

                option.elements = [];
                option.elements.push({ code: `Label`, value: claim.claimPageName });

                claims.push(option);
            });
        }

        return claims;
    }

    handleHasWorkersCompensationInsuranceClaims = (value) =>
    {
        this.setState({ currentHasWorkersCompensationInsuranceClaims: value });
        this.props.doReducePolicyElement({ code: `employersWcCustomInfo.insuranceHistory.hasWorkersCompensationInsuranceClaims`, value });
    }

    render() {
        const { error, warning } = getPageValidationInfo(this.props.pageCode, this.props.validationInfo);
        return (
            <React.Fragment>
                {
                    (error.message.length !== 0 || warning.message.length !== 0) &&
                    <Container top={Sizes.md}>
                        {
                            error.message.length !== 0 &&
                            <Alert
                                mode='danger'
                                header={error.title}
                                items={error.message}
                            />
                        }
                        {
                            warning.message.length !== 0 &&
                            <Alert
                                mode='warning'
                                header={warning.title}
                                items={warning.message}
                            />
                        }
                    </Container>
                }
                {
                    this.state.mode === `insuranceHistoryView` &&
                    <React.Fragment>
                        <Form
                            pageCode={this.props.pageCode}
                            sectionCode='insuranceHistory'
                            saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                            reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                            tooltipInfo={this.props.tooltipInfo}
                            validationInfo={this.props.validationInfo}
                            header={<FormattedMessage id={`Insurance History`} />}
                            label={`Details`}
                            allowEdit={this.props.allowEdit}
                            saveEvent={() => this.handleSave()}
                            cancelEvent={() => this.props.doGetPolicy()}
                        >
                            <FormSwitch
                                fieldCode='hasWorkersCompensationInsuranceClaims'
                                label={<FormattedMessage id={`Have you had prior insurance workers compensation claims in the last 4 Years?`} />}
                                value={this.props.policy.employersWcCustomInfo.insuranceHistory?.hasWorkersCompensationInsuranceClaims ?? false}
                                changeEvent={(value) => this.handleHasWorkersCompensationInsuranceClaims(value)}
                                allowEdit
                            />
                        </Form>
                        <React.Fragment>
                            <Divider top={Sizes.xl} />
                            <Header size={Sizes.lg} textAlign={Alignments.left} text={`Claims`} />
                            <Container top={Sizes.xl} bottom={Sizes.xl}>
                                <SummaryGroup
                                    allowAdd={false}
                                    addEvent={() => {
                                        this.props.doReduceParameter([]);
                                        this.props.doExecutePolicyAction({ code: `EMPLOYERS-WC.CREATECLAIM` });
                                    }}
                                    allowEdit
                                    editEvent=
                                    {(claim) => {
                                        this.setState({ currentClaim: claim, mode: `claimView` });
                                    }}
                                    allowDelete={false}
                                    deleteEvent={(claim) => {
                                        this.props.doReduceParameter({ code: `ClaimGuid`, value: claim });
                                        this.props.doExecutePolicyAction({ code: `EMPLOYERS-WC.DELETECLAIM` });
                                    }}
                                    entities={this.getClaims()}
                                    icon={`person`}
                                />
                            </Container>
                        </React.Fragment>
                    </React.Fragment>
                }
                {
                    this.state.mode === `claimView` &&
                    <ProfileEmployersWcClaim
                        rowId={this.state.currentClaim}
                        pageCode={this.props.pageCode}
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        claim={this.props.policy.employersWcCustomInfo.claims.find(x => x.guid === this.state.currentClaim)}
                        dotProp={`employersWcCustomInfo.claims.${this.props.policy.employersWcCustomInfo.claims.findIndex(x => x.guid === this.state.currentClaim)}.`}
                        backEvent={() => this.setState({ mode: `insuranceHistoryView`, currentClaim: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReduceParameter={this.props.doReduceParameter}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        doExecutePolicyAction={this.props.doExecutePolicyAction}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
            </React.Fragment>
        );
    }
}

export default ProfileEmployersWcPriorInsurance;