import React from "react";
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';

import equal from 'fast-deep-equal';

import {
	Alignments,
	Colors,
	Sizes,
	Grid,
	Row,
	Column,
	Container,
	Focused,
	Label,
	Link,
	DropDown,
	ChevronIcon,
	Separator,
	SecondaryButton
} from 'metrix-common';

import ProductClassIcon from '../../../ProductClassIcon';
import InsurabilityScoreIcon from '../InsurabilityScoreIcon';
import Avatar from '../Avatar';

const glow = keyframes`{
    from {
      box-shadow: 0 0 3px -3px ${Colors.Primary};
    }
    to {
      box-shadow: 0 0 3px 3px ${Colors.Primary};
    }
  }`;

const CardComponent = styled(Container)`
	border: solid 1px ${Colors.StrokeLight};
	max-width: ${({ isMobile, isTablet }) => isMobile ? `calc(50vw - 15px)` : isTablet ? `calc(25vw - 20px)` : `222px`};
	min-width: ${({ isMobile, isTablet }) => isMobile ? `calc(50vw - 15px)` : isTablet ? `calc(25vw - 20px)` : `222px`};
    ${({ priority }) => priority && css`animation: ${glow} 1s infinite alternate;`}

`;

const AvatarContainer = styled(Container)`
	width: 23px;
	height: 23px;
`;

const NameContainer = styled(Container)`
  overflow: hidden;
  text-overflow: ellipsis; 
`

const StyledAddPointsSecondayButton = styled(SecondaryButton)`
	opacity: 1;
	margin-left:0;
	font-weight:500;
    font-size: 13px;
	height: auto;
	width:100%;
	@media {min-width: 12px;}
`;

class BoardCard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			collapsed: true,
			assignable: false,
			productEditable: false
		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		return !equal(this.props, nextProps) || !equal(this.state, nextState);
	}

	// Function to update child reference as per parent value
	updateChildRef = (childRef, code) => {

		let ref = childRef;

		if (code && ref) {
			ref = ref.filter((x) => x.elements[0].value === code);
		}

		return ref;
	}

	render() {
		let policy = this.props.policy;
		let assignees = <Focused leaveEvent={() => this.setState({ assignable: false })}>
			<DropDown
				value={this.props.policy.brokerReferenceGuid &&
					{ code: this.props.policy.brokerReferenceGuid }}
				references={this.props.reference.references && this.updateChildRef(this.props.reference.references[`ActiveBrokers`], policy?.brokerCompanyReferenceGuid)}
				referencesEvent={() => this.props.reference.doGetReferences(`ActiveBrokers`)}
				changeEvent={(value) => this.props.assignmentEvent(this.props.policy.policyTransactionGuid, value.code)}
				allowEdit
				blurEvent={() => this.setState({ assignable: false })}
			/>
		</Focused>;

		let assigned = <Container inline top={Sizes.xxs}>
			<AvatarContainer>
				<Avatar firstName={this.props.policy.brokerFirstName} lastName={policy.brokerLastName} />
			</AvatarContainer>
			<Label text={`${policy.brokerFirstName} ${policy.brokerLastName}`} textAlign={Alignments.left} left={Sizes.xs} />
		</Container>;

		let unassigned = <Container inline top={Sizes.xxs}>
			<Avatar color={Colors.StrokeLight} firstName={`U`} lastName={`A`} />
			<Label text={`Unassigned`} textAlign={Alignments.left} left={Sizes.xs} />
		</Container>;

		let assignedBrokerCompany = <Container inline top={Sizes.xxs}>			
			<Label text={`${policy.brokerCompanyName}`} textAlign={Alignments.left} left={Sizes.xs}/>
		</Container>;

		let unassignedBrokerCompany = <Container inline top={Sizes.xxs}>			
			<Label text={`Unassigned`} textAlign={Alignments.left} left={Sizes.xs} />
		</Container>;

		return (
			<CardComponent
				left={Sizes.xxs}
				right={Sizes.xxs}
				color={Colors.LightMode}
				isMobile={this.context.screen.isMobile}
				isTablet={this.context.screen.isTablet}>					
				<Grid>
					<Row nogutterbottom>
						<Column xs={2} md={2} lg={2}>
							<Container fullSize verticalAlignment={Alignments.top}>
								<ProductClassIcon productClass={policy.productClass} />
							</Container>
						</Column>
						<Column xs={5.5} md={6.5} lg={7.5}>
							<NameContainer fullSize verticalAlignment={Alignments.top} >
								<Link
									textAlign={Alignments.left}
									text={`${policy.insuredFirstName} ${policy.insuredLastName}`}
									href={`${this.props.context.configuration.policyWeb}/profile?entity=${policy.policyTransactionGuid}`}
								/>
								<Label bold textAlign={Alignments.left} text={policy.submissionReferenceNumber} />
							</NameContainer>
						</Column>
						{							
							(policy.productClass === `auto` || policy.productClass === `ppackage`) &&
							<Column xs={2} md={2} lg={2.5}>
								<Container fullSize verticalAlignment={Alignments.top}>
									<InsurabilityScoreIcon score={policy.insurabilityScore ?? 0} />									
								</Container>
							</Column>
						}
					</Row>		
					<Row nogutter>						
						<Column>
							<Container clickEvent={() => this.setState({ brokerCompanyAssignable: this.props.context.session.userType === 'administrator' })}>
								{policy.brokerCompanyReferenceGuid ? assignedBrokerCompany : unassignedBrokerCompany}								
							</Container>
						</Column>
					</Row>
					<Row nogutter>
						<Column>
							<Container clickEvent={() => this.setState({ assignable: true })}>
								{this.state.assignable ? assignees : policy.brokerReferenceGuid ? assigned : unassigned}
							</Container>
						</Column>
					</Row>
					<Row nogutter>
						<Column>
							<Separator solid top={Sizes.xxs}  />
						</Column>
					</Row>
					{
						!this.state.collapsed &&
						<>
							<Row nogutterbottom>

							<Column xs={4}>
								<Label size={Sizes.sm} bold textAlign={Alignments.left} text={`Status`} />
							</Column>
							<Column xs={8}>
								<Label size={Sizes.sm} textAlign={Alignments.left} text={policy.transactionStatus} />
							</Column>

							<Column xs={4}>
								<Label size={Sizes.sm} bold textAlign={Alignments.left} text={`Product`} />
							</Column>
							<Column xs={8}>
								<Label size={Sizes.sm} textAlign={Alignments.left} text={policy.productDescription} />
							</Column>

							<Column xs={4}>
								<Label size={Sizes.sm} bold textAlign={Alignments.left} text={`Effective`} />
							</Column>
							<Column xs={8}>
								<Label size={Sizes.sm} textAlign={Alignments.left} text={policy.effectiveDate} />
							</Column>

							<Column xs={4}>
								<Label size={Sizes.sm} bold textAlign={Alignments.left} text={`Description`} />
							</Column>
							<Column xs={8}>
								<Label size={Sizes.sm} textAlign={Alignments.left} text={policy.policyDescription} />
							</Column>

							{
								(policy.insuredStreetAddress || policy.insuredCityProvince || policy.insuredPostalCode) &&
								<React.Fragment>
									<Column xs={4}>
										<Label size={Sizes.sm} bold textAlign={Alignments.left} text={`Address`} />
									</Column>
									<Column xs={8}>								
										<Label size={Sizes.sm} textAlign={Alignments.left} text={policy.insuredStreetAddress} />
										<Label size={Sizes.sm} textAlign={Alignments.left} text={policy.insuredCityProvince} />
										<Label size={Sizes.sm} textAlign={Alignments.left} text={policy.insuredPostalCode} />
									</Column>
								</React.Fragment>
							}
							<Column>
								<Separator solid top={Sizes.xxs} />
							</Column>

							<Row nogutterbottom>
									<Column xs={6} >
										<StyledAddPointsSecondayButton text={`Call`}											
											clickEvent={() => {
												window.location = `tel:${policy.insuredPhone}`;
										}}
									/>
								</Column>
								<Column xs={6} >
										<StyledAddPointsSecondayButton text={`Email`}											
											clickEvent={() => {
												window.location = `mailto:${policy.insuredEmail}`;
										}}
									/>
								</Column>
							</Row>									
						</Row>						
						</>
					}
				</Grid>
				<Container fullwidth
					clickEvent={() => this.setState({ collapsed: !this.state.collapsed })}
					horizontalAlignment={Alignments.center}
				>
					<ChevronIcon mode={this.state.collapsed === true ? `down` : `up`} />
				</Container>
			</CardComponent>
		);
	}
}

BoardCard.contextTypes = {
	screen: PropTypes.object
};

export default BoardCard;