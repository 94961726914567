import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Form,
    FormInput,
    FormDropDown
} from 'metrix-common';

class ProfileApplicantSales extends React.Component {
    render() {
        return (
            <Form
                header={<FormattedMessage id={`Sales Stats`} />}
                label={`Lead Details`}
                saveEvent={() => this.props.doUpsertPolicy()}
                cancelEvent={() => this.props.doGetPolicy()}
                allowEdit={this.props.allowEdit}
            >
                <FormInput
                    label={<FormattedMessage id={`Lead Score`} />}
                    value={this.props.policy &&
                        this.props.policy.quote &&
                        this.props.policy.quote.leadScoreCategory
                    }
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `quote.leadScoreCategory`, value })}
                    allowEdit
                />
                <FormDropDown
                    label={<FormattedMessage id={`Rejected Reason`} />}
                    value={this.props.policy &&
                        this.props.policy.submission &&
                        this.props.policy.submission.rejectedReason
                    }
                    references={this.props.reference.references && this.props.reference.references[`RejectedReason`]}
                    referencesEvent={() => this.props.reference.doGetReferences(`RejectedReason`)}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `submission.rejectedReason`, value })}
                    allowEdit
                />
                <FormDropDown
                    label={<FormattedMessage id={`Lost Reason`} />}
                    value={this.props.policy &&
                        this.props.policy.quote &&
                        this.props.policy.quote.lostReason
                    }
                    references={this.props.reference.references && this.props.reference.references[`LostReason`]}
                    referencesEvent={() => this.props.reference.doGetReferences(`LostReason`)}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `quote.lostReason`, value })}
                    allowEdit
                />
                <FormInput
                    label={<FormattedMessage id={`Deal Score`} />}
                    value={this.props.policy &&
                        this.props.policy.quote &&
                        this.props.policy.quote.dealScore
                    }
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `quote.dealScore`, value })}
                    allowEdit
                />
                <FormInput
                    label={<FormattedMessage id={`Annual Savings`} />}
                    value={this.props.policy &&
                        this.props.policy.quote &&
                        this.props.policy.quote.deltaTermGross &&
                        this.props.policy.quote.deltaTermGross.value
                    }
                    changeEvent={(value) => {
                        this.props.doReducePolicyElement({ code: `quote.deltaTermGross.currency`, value: `cad` });
                        this.props.doReducePolicyElement({ code: `quote.deltaTermGross.value`, value });
                    }}
                    allowEdit
                />
            </Form>
        );
    }
}

export default ProfileApplicantSales;