import React from 'react';

class HighLowerScoreIcon extends React.Component {
        
    getIcon = (score) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} viewBox="0 0 138 138">
                <defs>
                    <style>{".prefix__cls-2{fill:#d83c90}"}</style>
                </defs>
                <g id="prefix__HIGH_61-75" data-name="HIGH 61-75">
                    <g id="prefix__Graph">
                        <g id="prefix__BG_Color" data-name="BG Color">
                            <path
                                className="prefix__cls-2"
                                d="M69 137.08A68.08 68.08 0 11137.08 69 68.16 68.16 0 0169 137.08zm0-126.16A58.08 58.08 0 10127.08 69 58.14 58.14 0 0069 10.92z"
                                id="prefix__Gradient_Base"
                                data-name="Gradient Base"
                                opacity={0.15}
                            />
                        </g>
                    </g>
                    <path
                        d="M25 114.51a63.22 63.22 0 1187.45.19"
                        fill="none"
                        stroke="#d83c90"
                        strokeLinecap="round"
                        strokeMiterlimit={10}
                        strokeWidth={10}
                    />                    
                </g>
                <text x="50%" y="50%" text-anchor="middle" stroke="#d83c90" fill="#d83c90" font-family="'Poppins'" stroke-width="2px" dy=".3em" fontSize="70px">{score}</text>                   
            </svg>
            );
        }

        render() {
            return (this.getIcon(this.props.score || 0));
        }    
}

export default HighLowerScoreIcon;