import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { Container, Colors } from 'metrix-common';

import Routes from '../../components/Routes';
import SupportPartner from '../../components/SupportPartner';

const Support = styled(SupportPartner)`
    position: fixed;
    cursor: pointer;
    bottom: 40px;
    right: 10px;
`;

class App extends React.Component {
    render() {

        window.zESettings = {
            webWidget: {
                color: {
                    theme: `${Colors.Primary}`
                }
            }
        };

        return (
            <Container>
                <Helmet>
                    <title>{process.env.REACT_APP_Brand}</title>
                    <link href="https://calendly.com/assets/external/widget.css" rel="stylesheet" />
                    <script src="https://calendly.com/assets/external/widget.js" type="text/javascript" />
                    {this.props.context &&
                        this.props.context.configuration &&
                        this.props.context.configuration.gtm && <script async src={`https://www.googletagmanager.com/gtm.js?id=${this.props.context.configuration.gtm}`} />
                    }
                    {this.props.context &&
                        this.props.context.configuration &&
                        this.props.context.configuration.hotjar &&
                        <script async type={`text/javascript`}>
                            {
                                `(function(h,o,t,j,a,r){
                                    h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
                                    h._hjSettings={hjid:${this.props.context.configuration.hotjar},hjsv:6};
                                    a=o.getElementsByTagName('head')[0];
                                    r=o.createElement('script');r.async=1;
                                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                                    a.appendChild(r);
                                  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
                            }
                        </script>
                    }
                </Helmet>
                <Routes {...this.props} />
                <Support {...this.props} />
            </Container>
        );
    }
}

export default App;