import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Form,
    FormDropDown,
    FormSwitch
} from 'metrix-common';

class ProfileFluxGlGeneralLiability extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            <React.Fragment>
                <Form
                    header={<FormattedMessage id={`General Liability`} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormDropDown
                        label={<FormattedMessage id={`General Liability Limits`} />}
                        value={{ code: this.props.policy.fluxCustomInfo.generalLiability?.generalLiabilityLimitTypeCode, value: this.props.policy.fluxCustomInfo.generalLiability?.generalLiabilityLimitTypeName }}
                        references={this.props.reference?.references[`FLUX-GL`] && this.props.reference.references[`FLUX-GL`][`GeneralLiabilityLimitTypes`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`FLUX-GL.GeneralLiabilityLimitTypes`)}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `fluxCustomInfo.generalLiability.generalLiabilityLimitTypeCode`, value: value.code });
                            this.props.doReducePolicyElement({ code: `fluxCustomInfo.generalLiability.generalLiabilityLimitTypeName`, value: value.value });
                        }}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Include Stop Gap? (WA, OH, & NE Only)`} />}
                        value={this.props.policy.fluxCustomInfo.generalLiability?.isIncludeStopGap}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.generalLiability.isIncludeStopGap`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Include Pesticide/Herbicide Applicators Endorsement? (WA & MA Only)`} />}
                        value={this.props.policy.fluxCustomInfo.generalLiability?.isIncludeWAOrMA}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.generalLiability.isIncludeWAOrMA`, value: value })}
                        allowEdit
                    />
                    <FormDropDown
                        label={<FormattedMessage id={`General Liability Pesticide / Herbicide Limits`} />}
                        value={{ code: this.props.policy.fluxCustomInfo.generalLiability?.generalLiabilityPesticideLimitTypeCode, value: this.props.policy.fluxCustomInfo.generalLiability?.generalLiabilityPesticideLimitTypeName }}
                        references={this.props.reference?.references[`FLUX-GL`] && this.props.reference.references[`FLUX-GL`][`GeneralLiabilityPesticideLimitTypes`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`FLUX-GL.GeneralLiabilityPesticideLimitTypes`)}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `fluxCustomInfo.generalLiability.generalLiabilityPesticideLimitTypeCode`, value: value.code });
                            this.props.doReducePolicyElement({ code: `fluxCustomInfo.generalLiability.generalLiabilityPesticideLimitTypeName`, value: value.value });
                        }}
                        allowEdit
                    />
                </Form>
            </React.Fragment>
        );
    }
}

export default ProfileFluxGlGeneralLiability;