import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Alignments,
    FormSwitch,
    Form,
    FormTextArea,
    FormInput,
    Label,
} from 'metrix-common';

class ProfileFluxGlManufacturing extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            <React.Fragment>
                <Form
                    header={<FormattedMessage id={`Manufacturing / Cooking`} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormSwitch
                        label={<FormattedMessage id={`Do you use any butane, propane, CO2 or other gases in the manufacturing process?`} />}
                        value={this.props.policy.fluxCustomInfo.manufacturing?.hasManufacturingProcessWithGases}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.manufacturing.hasManufacturingProcessWithGases`, value: value })}
                        allowEdit
                    />
                    {this.props.policy.fluxCustomInfo.manufacturing?.hasManufacturingProcessWithGases === true &&
                        <Label bold text={<FormattedMessage id={`Please list what gases/solvents are used:`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.fluxCustomInfo.manufacturing?.hasManufacturingProcessWithGases === true &&
                        <FormTextArea
                            text={this.props.policy.fluxCustomInfo.manufacturing?.manufacturingProcessWithGasesDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.manufacturing.manufacturingProcessWithGasesDescription`, value })}
                        />
                    }
                    {this.props.policy.fluxCustomInfo.manufacturing?.hasManufacturingProcessWithGases === true &&
                        <FormSwitch
                            label={<FormattedMessage id={`Do you test 100% of all products for any level of gas/solvent residue?`} />}
                            value={this.props.policy.fluxCustomInfo.manufacturing?.hasGasResidueTestingAllProducts}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.manufacturing.hasGasResidueTestingAllProducts`, value: value })}
                            allowEdit
                        />
                    }

                    {(this.props.policy.fluxCustomInfo.manufacturing?.hasManufacturingProcessWithGases === true && this.props.policy.fluxCustomInfo.manufacturing?.hasGasResidueTestingAllProducts === true) &&
                        <FormSwitch
                            label={<FormattedMessage id={`Will you destroy 100% of the products found with unsafe gas residues?`} />}
                            value={this.props.policy.fluxCustomInfo.manufacturing?.isDestroyProductsWithUnsafeGasResidues}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.manufacturing.isDestroyProductsWithUnsafeGasResidues`, value: value })}
                            allowEdit
                        />
                    }
                    <Label bold text={<FormattedMessage id={`Please provide a complete list of products manufactured:`} />} textAlign={Alignments.left} />
                    <FormTextArea
                        text={this.props.policy.fluxCustomInfo.manufacturing?.completeProductManufacturedDescription}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.manufacturing.completeProductManufacturedDescription`, value })}
                    />
                    <Label bold text={<FormattedMessage id={`Please provide a list of products that you manufacture, but which others will place their label:`} />} textAlign={Alignments.left} />
                    <FormTextArea
                        text={this.props.policy.fluxCustomInfo.manufacturing?.productsWithAnotherCompanyLabelDescription}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.manufacturing.productsWithAnotherCompanyLabelDescription`, value })}
                    />

                    <FormSwitch
                        label={<FormattedMessage id={`Will there be open flame cooking or fryer operations at this location?`} />}
                        value={this.props.policy.fluxCustomInfo.manufacturing?.hasFlameOrFryerOperationsAtThisLocation}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.manufacturing.hasFlameOrFryerOperationsAtThisLocation`, value: value })}
                        allowEdit
                    />
                    {this.props.policy.fluxCustomInfo.manufacturing?.hasFlameOrFryerOperationsAtThisLocation === true &&
                        <FormSwitch
                            label={<FormattedMessage id={`Are open flame cooking and/or frying operations conducted under a non-combustible power ventilation hood?`} />}
                            value={this.props.policy.fluxCustomInfo.manufacturing?.hasVentilationHoodForFlameOrFryerOperations}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.manufacturing.hasVentilationHoodForFlameOrFryerOperations`, value: value })}
                            allowEdit
                        />
                    }
                    {this.props.policy.fluxCustomInfo.manufacturing?.hasFlameOrFryerOperationsAtThisLocation === true &&
                        <Label bold text={<FormattedMessage id={`What products do you manufacture that require open flame cooking?`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.fluxCustomInfo.manufacturing?.hasFlameOrFryerOperationsAtThisLocation === true &&
                        <FormTextArea
                            text={this.props.policy.fluxCustomInfo.manufacturing?.productsWithFlameOrFryerOperationsDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.manufacturing.productsWithFlameOrFryerOperationsDescription`, value })}
                        />
                    }
                    {this.props.policy.fluxCustomInfo.manufacturing?.hasFlameOrFryerOperationsAtThisLocation === true &&
                        <FormSwitch
                            label={<FormattedMessage id={`Does this location have an UL-300 compliant automatic fire suppression system with nozzles extended over all cooking surfaces?`} />}
                            value={this.props.policy.fluxCustomInfo.manufacturing?.hasUL300CompliantAutomaticFireSuppressionSystem}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.manufacturing.hasUL300CompliantAutomaticFireSuppressionSystem`, value: value })}
                            allowEdit
                        />
                    }
                    {this.props.policy.fluxCustomInfo.manufacturing?.hasUL300CompliantAutomaticFireSuppressionSystem === true &&
                        <Label bold text={<FormattedMessage id={`What type?`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.fluxCustomInfo.manufacturing?.hasUL300CompliantAutomaticFireSuppressionSystem === true &&
                        <FormTextArea
                            text={this.props.policy.fluxCustomInfo.manufacturing?.uL300CompliantAutomaticFireSuppressionSystemTypeDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.manufacturing.uL300CompliantAutomaticFireSuppressionSystemTypeDescription`, value })}
                        />
                    }
                    {this.props.policy.fluxCustomInfo.manufacturing?.hasFlameOrFryerOperationsAtThisLocation === true &&
                        <FormSwitch
                            label={<FormattedMessage id={`Does your cooking/frying equipment have an automatic gas/propane cutoff?`} />}
                            value={this.props.policy.fluxCustomInfo.manufacturing?.hasCookingEquipmentWithAutomaticGasCutoff}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.manufacturing.hasCookingEquipmentWithAutomaticGasCutoff`, value: value })}
                            allowEdit
                        />
                    }
                    {this.props.policy.fluxCustomInfo.manufacturing?.hasFlameOrFryerOperationsAtThisLocation === true &&
                        <FormSwitch
                            label={<FormattedMessage id={`Does your cooking/frying equipment have an automatic gas/propane cutoff?`} />}
                            value={this.props.policy.fluxCustomInfo.manufacturing?.hasRegularCleaningAndInspectionHoodsAndFlueses}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.manufacturing.hasRegularCleaningAndInspectionHoodsAndFlueses`, value: value })}
                            allowEdit
                        />
                    }
                    {this.props.policy.fluxCustomInfo.manufacturing?.hasFlameOrFryerOperationsAtThisLocation === true &&
                        <FormSwitch
                            label={<FormattedMessage id={`Is fire suppression system regularly cleaned/inspected by an outside services?`} />}
                            value={this.props.policy.fluxCustomInfo.manufacturing?.hasRegularCleaningAndInspectionFireSuppresionSystem}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.manufacturing.hasRegularCleaningAndInspectionFireSuppresionSystem`, value: value })}
                            allowEdit
                        />
                    }
                    {this.props.policy.fluxCustomInfo.manufacturing?.hasRegularCleaningAndInspectionFireSuppresionSystem === true &&
                        <FormInput
                            label={<FormattedMessage id={`How often are these serviced?`} />}
                            value={this.props.policy.fluxCustomInfo.manufacturing?.cleaningAndInspectionServicesCount}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.manufacturing.cleaningAndInspectionServicesCount`, value })}
                            type='number'
                            allowEdit
                        />
                    }
                    <FormSwitch
                        label={<FormattedMessage id={`Have you had any past health or liquor violations that have resulted in business closure of license suspension?`} />}
                        value={this.props.policy.fluxCustomInfo.manufacturing?.hasPastBusinessClosureFromHealthOrLiquorViolations}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.manufacturing.hasPastBusinessClosureFromHealthOrLiquorViolations`, value: value })}
                        allowEdit
                    />
                </Form>
            </React.Fragment>
        );
    }
}

export default ProfileFluxGlManufacturing;