import React from "react";
import styled from 'styled-components';

import {
	getAvatarColor,
	Alignments,
	Colors,
	Sizes,
	Container,
	Label
} from 'metrix-common';

const AvatarContainer = styled(Container)`
	width: 23px;
	height: 23px;
`;

const AvatarComponent = styled(Container)`
	border-radius: 50%;
`;

class Avatar extends React.Component {

	render() {
		return (
			<AvatarContainer>
				<AvatarComponent fullSize verticalAlignment={Alignments.center} horizontalAlignment={Alignments.center} color={this.props.color || getAvatarColor(`${this.props.firstName} ${this.props.lastName}`, 80, 40)}>
					<Label size={Sizes.sm} textAlign={Alignments.left} text={`${this.props.firstName && this.props.firstName.charAt(0) || ``}${this.props.lastName && this.props.lastName.charAt(0)}` || ``} color={Colors.LightMode} />
				</AvatarComponent>
			</AvatarContainer>
		);
	}
}

export default Avatar;