import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Form,
    FormInput,
    FormDate,
    FormDropDown,
    FormSwitch,
    setAttributeEntity
} from 'metrix-common';


class ProfileApplicantVitals extends React.Component {
    componentDidMount() {
        const pageHasTooltipInfo = this.props.tooltipInfo && this.props.tooltipInfo.pages && this.props.tooltipInfo.pages.length && this.props.tooltipInfo.pages.some(p => p.code === this.props.pageCode);
        if (!pageHasTooltipInfo) {
            this.props.doGetTooltipInfo(this.props.policy.product);
        }
    }

    render() {
        return (
            <Form
                pageCode={this.props.pageCode}
                sectionCode='vitalStats'
                saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                tooltipInfo={this.props.tooltipInfo}
                validationInfo={this.props.validationInfo}
                header={<FormattedMessage id={`Vital Stats`} />}
                label={`Policy Details`}
                saveEvent={() => this.props.doUpsertPolicy()}
                cancelEvent={() => this.props.doGetPolicy()}
                allowEdit={this.props.allowEdit}
            >
                <FormInput
                    label={<FormattedMessage id={`Description`} />}
                    value={this.props.policy && this.props.policy.description}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `description`, value })}
                    allowEdit
                />
                <FormInput
                    label={<FormattedMessage id={`Submission Reference`} />}
                    value={this.props.policy && this.props.policy.submission && this.props.policy.submission.referenceNumber}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `submission.referenceNumber`, value })}
                />
                <FormInput
                    label={<FormattedMessage id={`Policy Number`} />}
                    value={this.props.policy && this.props.policy.number}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `number`, value })}
                    allowEdit
                />
                <FormInput
                    label={<FormattedMessage id={`Previous Policy Number`} />}
                    value={this.props.policy && this.props.policy.previousNumber}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `previousNumber`, value })}
                    allowEdit
                />
                <FormInput
                    label={<FormattedMessage id={`Product`} />}
                    value={this.props.policy && this.props.policy.product}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `product`, value })}
                />
                <FormInput
                    label={<FormattedMessage id={`Line of Business`} />}
                    value={this.props.policy && this.props.policy.lineOfBusiness}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `lineOfBusiness`, value })}
                />
                <FormInput
                    label={<FormattedMessage id={`Type`}  />}
                    value={this.props.policy && this.props.policy.type}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `type`, value })}
                />
                <FormInput
                    label={<FormattedMessage id={`Status`} />}
                    value={this.props.policy && this.props.policy.status}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `status`, value })}
                />
                <FormDate
                    fieldCode='effectiveDate'
                    label={<FormattedMessage id={'Effective Date'} />}
                    value={this.props.policy && this.props.policy.effectiveDate}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `effectiveDate`, value })}
                    culture={this.props.context.session.culture}
                    allowEdit
                />
                <FormDate
                    fieldCode='expiryDate'
                    label={<FormattedMessage id={`Expiry Date`}  />}
                    value={this.props.policy && this.props.policy.expiryDate}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `expiryDate`, value })}
                    culture={this.props.context.session.culture}
                    allowEdit
                />
                {
                    this.props.policy && this.props.policy.quote && this.props.policy.quote.quoteExpiryDate &&
                    <FormDate
                        label={<FormattedMessage id={`Quote Expiry Date`} />}
                        value={this.props.policy.quote.quoteExpiryDate}
                        culture={this.props.context.session.culture}
                        allowEdit={false}
                    />
                }
                <FormSwitch
                    label={<FormattedMessage id={`Bindable`} />}
                    value={this.props.policy && this.props.policy.bindable}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `bindable`, value })}
                    allowEdit
                />
                <FormDropDown
                    label={<FormattedMessage id={`Billing Method`} />}
                    value={this.props.policy && this.props.policy.billingMethod}
                    references={this.props.reference.references && this.props.reference.references[`BillingMethod`]}
                    referencesEvent={() => this.props.reference.doGetReferences(`BillingMethod`)}
                    changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}billingMethod`, value, this.props.doReducePolicyElement)}
                    allowEdit
                />
            </Form>
        );
    }
}

export default ProfileApplicantVitals;