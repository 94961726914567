import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Sizes,
    Alignments,
    Grid,
    Row,
    Column,
    Header,
    Container,
    Alert,
    PrimaryButton,
    Tabbed,
    GenericModal,
    Label,
    getElementValueByCode
} from 'metrix-common';

import ProfileRisks from '../Risks';
import ProfileDiscounts from '../DiscountBadges'

class ProfileQuotes extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentQuoteIndex: 0,
            acceptPopup: false
        };
    };

    componentDidMount() {
        if (this.props.reference &&
            this.props.reference.doGetReferences &&
            this.props.reference.references &&
            !this.props.reference.references[`Insurer`]) {
            this.props.reference.doGetReferences(`Insurer`);
        }
    }

    getQuoteTabs = () => {
        let quoteTabs = [];

        if (this.props.policySummary &&
            this.props.policySummary.quotes &&
            this.props.policySummary.quotes.length > 0) {
            this.props.policySummary.quotes.map((quote) => {
                if (this.props.reference &&
                    this.props.reference.references &&
                    this.props.reference.references[`Insurer`])
                quoteTabs.push({
                    code: getElementValueByCode(this.props.reference.references[`Insurer`], quote.insurer),
                    description: quote.formattedEstimatedAnnualGross || `N/A`
                });
            });
        }

        return quoteTabs;
    }

    render() {
        const isAcceptQuoteBtnHidden = this.props.productSettings?.isSingleQuote;
        let isClient = (this.props.context.session.userType === `client`);

        let quotesCount = this.props.policySummary.quotes && (this.props.policySummary.quotes.length || 0);
        let quotes = (this.props.policySummary.quotes && this.props.policySummary.quotes.length > 0) && this.props.policySummary.quotes;
        let currentQuote = quotes && quotes[this.state.currentQuoteIndex];

        let lgTabs =
            quotesCount === 1
                ? 12
                : quotesCount === 2 || quotesCount === 4
                    ? 6
                    : 4;

        let amounts = {
            estimatedAnnualPremium: currentQuote && currentQuote.formattedEstimatedAnnualPremium || 0,
            estimatedAnnualGross: currentQuote && currentQuote.formattedEstimatedAnnualGross || 0,
            estimatedMonthlyGross: currentQuote && currentQuote.formattedEstimatedMonthlyGross || 0
        };

        let estimatedTaxes = currentQuote && currentQuote.estimatedTaxes && currentQuote.estimatedTaxes.value || 0;

        return (
            <Container>
                {currentQuote &&
                <React.Fragment>
                    <Container bottom={Sizes.sm}>
                        <Tabbed items={this.getQuoteTabs()} clickEvent={(value) => this.setState({ currentQuote: value })} descriptionSize={Sizes.md} lg={lgTabs} />
                    </Container>
                    {this.props.context.session.userType === `broker` && 
                        <React.Fragment>
                            {currentQuote.messages && currentQuote.messages.length > 0 &&
                                <Alert
                                    mode={`danger`}
                                    header={<FormattedMessage id={`app.components.Profile.Summary.messagesHeader`} />}
                                    items={currentQuote.messages} />
                            }
                            {currentQuote.discounts &&
                                currentQuote.discounts.length > 0 &&
                                <Alert
                                    mode={`success`}
                                    header={<FormattedMessage id={`app.components.Profile.Summary.discounts`} />}
                                    items={currentQuote.discounts.map((x) => {
                                        return {
                                            code: x.code,
                                            value: `${x.code} - ${x.description}`
                                        }
                                    })} />
                            }
                            {currentQuote.surcharges &&
                                currentQuote.surcharges.length > 0 &&
                                <Alert
                                    mode={`info`}
                                    header={<FormattedMessage id={`app.components.Profile.Summary.surcharges`} />}
                                    items={currentQuote.surcharges.map((x) => {
                                        return {
                                            code: x.code,
                                            value: `${x.code} - ${x.description}`
                                        }
                                    })} />
                        }
                        </React.Fragment>
                    }
                    {this.props.context.session.userType !== `broker` &&
                        currentQuote.discounts &&
                        currentQuote.discounts.length > 0 &&
                        <ProfileDiscounts discounts={currentQuote.discounts} />
                    }
                    <Container top={Sizes.sm}>
                        <Grid>
                            <Row>
                                <Column xs={1} md={2} />
                                <Column xs={10} md={8}>
                                    <Header size={Sizes.md} bottom={Sizes.xs} text={estimatedTaxes > 0 && <FormattedMessage id={`app.components.RecapCard.taxesIncluded`} values={amounts} /> || amounts.estimatedAnnualGross} />
                                    <Header size={Sizes.sm} bottom={Sizes.xs} text={<FormattedMessage id={`app.components.RecapCard.savings`} values={amounts} />} />
                                    {
                                        !isAcceptQuoteBtnHidden &&
                                        <Container inline
                                                    horizontalAlignment={Alignments.center}
                                        >
                                            <PrimaryButton text={`Accept Quote`}
                                                           clickEvent={() => {
                                                               if (isClient) {
                                                                   this.setState({ acceptPopup: true });
                                                               } else {
                                                                   this.props.doReduceParameter({
                                                                       code: `AcceptedQuote`,
                                                                       value: currentQuote.guid
                                                                   });
                                                                   this.props.doExecutePolicyAction({ code: `ACCEPTQUOTE` });
                                                               }
                                                           }}
                                            />
                                            {
                                                this.state.acceptPopup && isClient &&
                                                <GenericModal
                                                    animate
                                                    visible
                                                    closeEvent={() => this.setState({ acceptPopup: false })}
                                                    items={
                                                        <React.Fragment>
                                                            <Container>
                                                                <Header text={`Accepting a quote`}
                                                                        size={Sizes.lg}
                                                                        bottom={Sizes.md}
                                                                />
                                                                <Label text={`Your documents will be sent to your broker to be processed. Are you sure?`}
                                                                       bold
                                                                       textAlign={Alignments.left}
                                                                       bottom={Sizes.md}
                                                                />
                                                                <Label text={`You will be notified by email when they are ready to sign, as soon as possible.`}
                                                                       textAlign={Alignments.left}
                                                                       bottom={Sizes.xs}
                                                                />
                                                            </Container>
                                                        </React.Fragment>
                                                    }
                                                    actionButtons={[
                                                        {
                                                            text:
                                                                <FormattedMessage id='covera.common.organisms.confirmationModal.yes' />,
                                                            clickEvent: () => {
                                                                this.props.doReduceParameter({
                                                                    code: `AcceptedQuote`,
                                                                    value: currentQuote.guid
                                                                });
                                                                this.props.doExecutePolicyAction({ code: `ACCEPTQUOTE` });
                                                                this.props.updateProgressBar && this.props.updateProgressBar(`quoteAccepted`);
                                                                this.setState({ acceptPopup: false });
                                                            }
                                                        },
                                                        {
                                                            text: <FormattedMessage id='covera.common.general.cancel' />,
                                                            clickEvent: () => {
                                                                this.setState({ acceptPopup: false })
                                                            }
                                                        }
                                                    ]}
                                                />
                                            }
                                        </Container>
                                    }
                                </Column>
                                <Column xs={1} md={2} />
                            </Row>
                        </Grid>
                    </Container>
                    <ProfileRisks
                        risks={currentQuote && currentQuote.risks || []}
                        {...this.props}
                    />
                </React.Fragment>
                }
            </Container>
        );
    }
}

export default ProfileQuotes;
