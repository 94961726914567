import React from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
    Alignments,
    Sizes,
    Grid,
    Row,
    Column,
    Container,
    Header,
    Separator,
    DropDown, Link
} from 'metrix-common';

const StyledLink = styled(RouterLink)`
  text-decoration: none;
  color: black;

  ${(props) => props.isActive && `
    pointer-events: none;
    color: gray;
  `}
`;

class PoliciesHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = ({
            filterCollapsed: true
        });
    }

    render() {
        const { pathname } = this.props.location;
        const query = this.props.query.board;

		return (
            <Grid>
                <Row>
                    {
                        this.context.screen.isMobile &&
                        <Column>
                            <Container fullSize verticalAlignment={Alignments.bottom}>
                                <Header size={Sizes.md} text='Policies' top={Sizes.xs} />
                            </Container>
                        </Column>
                    }
                    <Column xs={12} md={3}>
                        <Container inline horizontalAlignment={Alignments.left} top={Sizes.sm}>
                            <StyledLink
                                to={`${this.props.path}`}
                                isActive={pathname === this.props.path}
                            >
                                <Link
                                    text='All'
                                    right={Sizes.xs}
                                    disabled={pathname === this.props.path}
                                />
                            </StyledLink>
                            <StyledLink
                                to={`${this.props.path}/incomplete`}
                                isActive={pathname === `${this.props.path}/incomplete`}
                            >
                                <Link
                                    text='Incomplete'
                                    right={Sizes.xs}
                                    disabled={pathname === `${this.props.path}/incomplete`}
                                />
                            </StyledLink>
                            <StyledLink
                                to={`${this.props.path}/bound`}
                                isActive={pathname === `${this.props.path}/bound`}
                            >
                                <Link
                                    text='Bound'
                                    right={Sizes.xs}
                                    disabled={pathname === `${this.props.path}/bound`}
                                />
                            </StyledLink>
                            <StyledLink
                                to={`${this.props.path}/board`}
                                isActive={pathname === `${this.props.path}/board`}
                            >
                                <Link
                                    text='Board'
                                    right={Sizes.xs}
                                    disabled={pathname === `${this.props.path}/board`}
                                />
                            </StyledLink>
                        </Container>
                    </Column>
                    {
                        !this.context.screen.isMobile &&
                        <Column md={6}>
                            <Container fullSize verticalAlignment={Alignments.bottom}>
                                <Header size={Sizes.lg} text='Policies' />
                            </Container>
                        </Column>
                    }                   
                    <Column xs={12} md={3}>
                        <Container inline horizontalAlignment={Alignments.right} top={this.context.screen.isMobile && Sizes.xs}>
                            {(this.props.view === 'board') &&
                                <React.Fragment>
                                    <Container right={Sizes.xs}>
                                        <DropDown
                                            placeholder='Type'
                                            value={query &&
                                                { code: query.transactionType }}
                                            references={[{ code: null, value: 'All Transaction Type' }, { code: 'renewal', value: 'Renewals' }, {code: 'new', value: 'New Businesses' }]}
                                            changeEvent={(value) => this.props.doReduceQueryElement({ code: 'transactionType', value: value.code })}
                                            allowEdit
                                        />
                                    </Container>

                                {(this.props.context.session.userType === 'administrator') &&
                                    <DropDown
                                        placeholder='Brokerage'
                                        value={query &&
                                            { code: query.brokerCompanyReferenceGuid }}
                                        references={this.props.brokerCompanies}
                                        referencesEvent={() => this.props.reference.doGetReferences('ActiveBrokerCompanies')}
                                    changeEvent={(value) => this.props.doReduceQueryElement({ code: 'brokerCompanyReferenceGuid', value: value.code })}
                                        allowEdit
                                    />}
                                                                                                
                                {(this.props.context.session.userType === 'broker') &&
                                    <DropDown
                                        placeholder='Assigned To'
                                        value={query &&
                                            { code: query.brokerReferenceGuid }}
                                        references={this.props.assignees}
                                        referencesEvent={() => this.props.reference.doGetReferences('ActiveBrokers')}
                                        changeEvent={(value) => this.props.doReduceQueryElement({ code: 'brokerReferenceGuid', value: value.code })}
                                        allowEdit
                                    />}
                                </React.Fragment>
                            }
                        </Container>
                    </Column>                    
                </Row>
                <Row noguttertop>
                    <Column>
                        <Separator solid />
                    </Column>
                </Row>
            </Grid>
		);
	}
}

PoliciesHeader.contextTypes = {
    screen: PropTypes.object
};

export default withRouter(PoliciesHeader);