import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import {
	getColor,
	Alignments,
	Colors,
	Opacity,
	Sizes,
	Grid,
	Row,
	Column,
	Container,
	Header,
	Separator,
	Label,
	Link,
	isMobile
} from 'metrix-common';

import BoardColumn from './Column';

const Board = (props) => {
	const history = useHistory();
	const [currentColumn, setCurrentColumn] = useState(0);

	useEffect(() => {
		props.doReduceView('board');
		props.doGetBoardPolicies();
	}, []);
	
	const statusColumns = [
		{ name: 'Submitted', status: ['Submitted'] },
		{ name: 'Quote Issued', status: [`Quoted`, `Quote Issued`] },
		{ name: 'Quote Accepted', status: [`Quote Accepted`] },
		{ name: 'Bind Requested', status: [`Bind Requested`] }
	];

	const swimlanes = [
		{ code: `renewal`, value: `Renewal` },
		{ code: `new`, value: `New Business` }
	];

	const handleAssignment = (guid, assignedTo) => {
		props.doExecuteAssignmentAction({ guid, assignedTo });
		props.doGetBoardPolicies();
	}

	const getSwimlane = (priorityCategory, column, displayPolicies) => {
		let policies = priorityCategory ?
			displayPolicies?.some(x => column.status.includes(x.transactionStatus) && x.priorityCategory === priorityCategory) &&
			displayPolicies?.filter(x => column.status.includes(x.transactionStatus) && x.priorityCategory === priorityCategory) 
			:
			displayPolicies?.some(x => column.status.includes(x.transactionStatus) && !x.priorityCategory) &&
			displayPolicies?.filter(x => column.status.includes(x.transactionStatus) && !x.priorityCategory) 
			;
		return (
			<Column xs={isMobile() ? 12 : 12 / statusColumns.length} key={`${priorityCategory}${column.name}Column`} nogutter>
				<BoardColumn
					name={column.name}
					status={column.status}
					policies={policies}
					key={column.status}
					reference={props.reference}
					history={history}
					assignmentEvent={handleAssignment}
					columnCount={statusColumns.length}
					doExecuteAssignmentAction={props.doExecuteAssignmentAction}
					context={props.context}
					products={props.products}
				/>
			</Column>
		);
	}

	const handlePreviousColumn = () => {
		if (currentColumn === 0) {
			setCurrentColumn(statusColumns.length - 1);
		} else {
			setCurrentColumn(currentColumn - 1);
        }
    }

	const handleNextColumn = () => {
		if (currentColumn === statusColumns.length - 1) {
			setCurrentColumn(0);
		} else {
			setCurrentColumn(currentColumn + 1);
		}
	}

	const getSwimlaneColor = (swimlane) => {
		let color = null;

		switch (swimlane.code)
		{
			case 'new':
				color = getColor(Colors.Primary, Opacity.VeryLight);
				break;
			case 'renewal':
				color = getColor(Colors.Secondary, Opacity.VeryLight);
				break;
			case 'low':
				color = getColor(Colors.Tertiary, Opacity.VeryLight);
				break;
			case 'none':
				color = getColor(Colors.StrokeMedium, Opacity.Light);
				break;
		}

		return color;
	}

	const query = props.query.board;
	const policyType = query.transactionType;
	const broker = query.brokerReferenceGuid;
	const brokerage = query.brokerCompanyReferenceGuid;

	const policies = props.boardPolicies;
	let displayPolicies = [];
	let displaySwimlane = [];

	// Filter Transaction Type
	if (policyType === 'renewal') {
		displayPolicies = policies && policies.filter((policy) => policy.transactionType === 'Renewal');
		displaySwimlane = swimlanes && swimlanes.filter((lane) => lane.value === 'Renewal');
	}
	else if (policyType === 'new') {
		displayPolicies = policies && policies.filter((policy) => policy.transactionType === 'New Business');
		displaySwimlane = swimlanes && swimlanes.filter((lane) => lane.value === 'New Business');
    }
	else {
		displayPolicies = policies;
		displaySwimlane = swimlanes;
	}

	// Filter Broker or Brokerage
	if (broker) {
		displayPolicies = displayPolicies && displayPolicies.filter((policy) => policy.brokerReferenceGuid === broker);
	}
	if (brokerage) {
		displayPolicies = displayPolicies && displayPolicies.filter((policy) => policy.brokerCompanyReferenceGuid === brokerage);
	}


	let columns = statusColumns;
	let noOfColumns = columns.length;
	if (isMobile()) {
		columns = [columns[currentColumn]];
    }
	return (
		<Container>
			<Grid>
				<Row>
					{
						columns.map((column) => {
							return (
								isMobile()
								?
								<Column xs={isMobile() ? 12 : 12 / noOfColumns} key={Math.random().toString()}>
									<Container fullSize inline horizontalAlignment={isMobile() ? Alignments.center : Alignments.left}>
										{(noOfColumns > 1) && <Link text={`<<`} right={Sizes.xs} clickEvent={handlePreviousColumn} />}
										<Label bold textAlign={isMobile() ? Alignments.center : Alignments.left} text={`${column.name} (${displayPolicies.filter(x => column.status.includes(x.transactionStatus)).length || 0})`} />
										{(noOfColumns > 1) && <Link text={`>>`} left={Sizes.xs} clickEvent={handleNextColumn} />}
									</Container>
									<Separator solid bottom={Sizes.xxs} />
								</Column>
								:
								<Column xs={12 / noOfColumns} key={Math.random().toString()}>
									<Header size={Sizes.xxs} textAlign={Alignments.left} text={`${column.name} (${displayPolicies.filter(x => column.status.includes(x.transactionStatus)).length || 0})`} />
									<Separator solid bottom={Sizes.xxs} />
								</Column>
							);
						})
					}
				</Row>
				{
					displaySwimlane.map((swimlane) => {
						return (
							<Row nogutter key={Math.random().toString()}>
								<Column nogutter>
									<Container color={getSwimlaneColor(swimlane)}
										top={Sizes.xs}
										bottom={Sizes.xs}
										left={Sizes.xxs}
										right={Sizes.xxs}>
										<Label text={swimlane.value || 'None'} top={Sizes.xxs} bottom={Sizes.xxs} />
									</Container>
								</Column>
								{
									columns.map((column) => {
										return (
											getSwimlane(swimlane.value, column, displayPolicies)
										);
									})
								}
							</Row>
						);
                    })
                }						
			</Grid>
		</Container>
	);
}

Board.contextTypes = {
	screen: PropTypes.object
};

export default Board;