import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import {
    Sizes,
    Alignments,
    Colors,
    Grid,
    Row,
    Column,
    Container,
    Label,
    getSize,
    SecondaryButton,

    //All Product Icons
    AutoProduct,
    BundleProduct,
    CommercialProduct,
    CondoProduct,
    HomeProduct,
    LandlordProduct,
    MotorcycleProduct,
    RoommateProduct,
    TenantProduct,
    RVProduct,
    PleasurecraftProduct
} from 'metrix-common';

import ProfileSummaryDiscounts from '../../CommonComponents/DiscountBadges';

const PolicyFactsContainer = styled(Container)`
    border: 1px solid ${Colors.StrokeLight};
    border-radius: 4px;
`;

const HeaderContainer = styled(Container)`
    align-items: center;
`;

class ProfileSummaryAction extends React.Component {

    getProductIcon = (product, color) => {
        switch (product) {
            case `auto`:
            case `flux-commauto`:
                return (<AutoProduct color={color} />);
            case `bundle`:
                return (<BundleProduct color={color} />);
            case `condo`:
                return (<CondoProduct color={color} />);
            case `home`:
                return (<HomeProduct color={color} />);
            case `landlord`:
                return (<LandlordProduct color={color} />);
            case `motorcycle`:
                return (<MotorcycleProduct color={color} />);
            case `roomate`:
                return (<RoommateProduct color={color} />);
            case `tenant`:
                return (<TenantProduct color={color} />);
            case `rv`:
                return (<RVProduct color={color} />);
            case `pleasurecraft`:
                return (<PleasurecraftProduct color={color} />);
            default:
                return (<CommercialProduct color={color} />);

        }
    }

    render() {
        let policyStatus = this.props.policySummary.status;
        let insurerSelected = (policyStatus === `quoteAccepted` ||
                                policyStatus === `bindRequested` ||
                                policyStatus === `bound` ||
                                policyStatus === `issued`);

        let insurer = (insurerSelected &&
                        (this.props.policySummary.participants.insurer && 
                        this.props.policySummary.participants.insurer.commercialName && 
                        this.props.policySummary.participants.insurer.commercialName.name))
            ? this.props.policySummary.participants.insurer.commercialName.name
            : ``;

        let currency = `CAD`;
        let culture = this.props.context.session.culture;

        let premiumAmount = this.props.policySummary.estimatedAnnualPremium.value;
        let taxesAmount = this.props.policySummary.estimatedTaxes.value;
        let feesAmount = this.props.policySummary.estimatedFees.value;
        let clientFirstName = this.props.policySummary.participants.insured.party.name.firstName;
        let clientLastName = this.props.policySummary.participants.insured.party.name.lastName;
        let clientAddress = this.props.policySummary.participants.insured.party.address.formattedAddress;
        let clientContact = this.props.policySummary.participants.insured.party.communication.phone.fullNumber;
        let product = this.props.policySummary.productName;
        let priorPolicy = this.props.policySummary?.priorPolicyReference?.guid;
        let policyNo = this.props.policySummary.number;
        let expiryDate = this.props.policySummary.formattedExpiryDate;
        let annualPremium = this.props.policySummary.formattedEstimatedAnnualPremium;
        let expiresInDays = this.props.policySummary.expiresInDays;

        if (this.props.policySummary.risks) {
            for (var i = 0; i < this.props.policySummary.risks.length; i++) {
                let risk = this.props.policySummary.risks[i];
                for (var j = 0; j < risk.coverages.length; j++) {
                    let coverage = risk.coverages[0];

                }
            }
        }

        return (
                <PolicyFactsContainer top={Sizes.sm}
                    bottom={Sizes.sm}
                >
                <Grid>
                        {/*Heading*/}
                        <Row>
                        <Column gutterLeft={getSize(Sizes.sm)}>
                                <HeaderContainer inline>
                                        {this.getProductIcon(product, Colors.StrokeDark)}
                                        <Label
                                            size={Sizes.lg}
                                            top={Sizes.xs} bold
                                                bottom={Sizes.xs}
                                                left={Sizes.xs}
                                                text={<FormattedMessage id={`${product} Insurance`} />}
                                            />
                                </HeaderContainer>
                            </Column>
                        </Row>
                        {/*Information Row 1*/}
                        <Row>
                            <Column xs={4}>
                                <Container
                                    horizontalAlignment={Alignments.left}
                                    verticalAlignment={Alignments.center}
                                >
                                    <Label bold
                                        left={Sizes.xs}
                                        textAlign={Alignments.left}
                                        text={`Name`}
                                    />
                                    <Label 
                                        left={Sizes.xs}
                                        textAlign={Alignments.left}
                                        text={`${clientFirstName} ${clientLastName}`}
                                    />
                                </Container>
                            </Column>
                            <Column xs={4}>
                                <Container
                                    horizontalAlignment={Alignments.left}
                                    verticalAlignment={Alignments.center}
                                >
                                    <Label bold
                                        left={Sizes.xs}
                                        textAlign={Alignments.left}
                                        text={`Address`}
                                    />
                                    <Label 
                                        left={Sizes.xs}
                                        textAlign={Alignments.left}
                                        text={`${clientAddress}`}
                                    />
                                </Container>
                            </Column>
                            <Column xs={4}>
                                <Container
                                    horizontalAlignment={Alignments.left}
                                    verticalAlignment={Alignments.center}
                                >
                                    <Label bold
                                        left={Sizes.xs}
                                        textAlign={Alignments.left}
                                        text={`Contact`}
                                    />
                                    <Label 
                                        left={Sizes.xs}
                                        textAlign={Alignments.left}
                                        text={`${clientContact}`}
                                    />
                                </Container>
                            </Column>
                    </Row>
                    {
                        priorPolicy &&
                            <Row>
                                <Column>
                                    <SecondaryButton text={`View Prior Policy`} clickEvent={() => { window.location = `${this.props.context.configuration.policyWeb}/profile?entity=${priorPolicy}` }} />
                                </Column>
                            </Row>
                    }
                    {this.props.context.session.userType !== `broker` &&
                        this.props.policySummary.discounts &&
                        this.props.policySummary.discounts.length > 0 &&
                        <ProfileSummaryDiscounts discounts={this.props.policySummary.discounts} />
                    }
                    </Grid>
            </PolicyFactsContainer>
        );
    }
}
            
export default ProfileSummaryAction;
