import React, {Component} from 'react';
import styled from 'styled-components';
import {FormattedMessage, injectIntl} from 'react-intl';
import PropTypes from 'prop-types';

import {
    toPascalCase,
    Alignments,
    Sizes,
    Grid,
    Row,
    Column,
    Container,
    Label,
    Input,
    SecondaryButton,
    ZoomIcon,
    DropDown,
} from 'metrix-common';

const IconContainer = styled(Container)`
  cursor: pointer;
`

class SearchHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            displaySearchCriteria: false
        };
    };

    componentDidMount() {
        if (this.state.displaySearchCriteria) {
            this.props.doQueryBoundPolicies();
        }
    }

    getFilterText = () => {
        let filterText = [];
        let query = this.props.query.bound;

        if (query.brokerReferenceGuid) {
            let assignees = this.getAssignees();
            filterText.push(`[b]Assigned To[b]: ${assignees.find(x => x.code === query.brokerReferenceGuid).value}`);
        }

        if (query.product) {
            let productName = this.props.products.find((product) => product.code === query.product).name;
            filterText.push(`[b]Product[b]: ${productName}`);
        }

        if (query.status) {
            filterText.push(`[b]Transaction Status[b]: ${toPascalCase(query.status)}`);
        }

        if (query.policyTransactionType) {
            var displayedType = query.policyTransactionType;

            filterText.push(`[b]Transaction Type[b]: ${toPascalCase(displayedType)}`);
        }

        if (query.clientFirstName) {
            filterText.push(`[b]First Name[b]: ${query.clientFirstName}`);
        }

        if (query.clientLastName) {
            filterText.push(`[b]Last Name[b]: ${query.clientLastName}`);
        }

        if (query.clientPhoneFullNumber) {
            filterText.push(`[b]Phone[b]: ${query.clientPhoneFullNumber}`);
        }

        if (query.clientEmail) {
            filterText.push(`[b]Email[b]: ${query.clientEmail}`);
        }

        return filterText.join(`, `);
    }

    getAssignees = () => {
        let assignees = [{code: null, value: `All Brokers`}];

        if (this.props.reference &&
            this.props.reference.references &&
            this.props.reference.references[`ActiveBrokers`]) {
            this.props.reference.references[`ActiveBrokers`].map((activeBroker) => {
                assignees.push({code: activeBroker.code, value: activeBroker.value});
            });
        }

        return assignees;
    }

    getProducts = () => {
        let products = [];

        products.push({code: null, value: `All Products`});

        if (this.props.products) {
            this.props.products.map((product) => {
                products.push({code: product.code, value: product.name});
            });
        }

        return products;
    }

    render() {

        let query = this.props.query.bound;

        return (
            <Container>
                <Container top={Sizes.xs} right={Sizes.xs} left={Sizes.xs}>
                    <Container top={Sizes.none} inline verticalAlignment={Alignments.center}>
                        <Grid>
                            <Row>
                                <Column xs={11} nogutter>
                                        <Label text={this.getFilterText()} textAlign={Alignments.left}/>
                                </Column>
                                <Column xs={1}>
                                    <Container fullSize horizontalAlignment={Alignments.right}>
                                        <IconContainer
                                            clickEvent={() => this.setState({displaySearchCriteria: !this.state.displaySearchCriteria})}>
                                            <ZoomIcon size={Sizes.sm}/>
                                        </IconContainer>
                                    </Container>
                                </Column>
                            </Row>
                        </Grid>
                    </Container>

                    <Container top={Sizes.none} bottom={this.context.screen.isMobile && Sizes.xs}>
                        <Grid>
                            <Row nogutter>
                                <Column xs={0} md={0} lg={2}/>
                                <Column xs={12} md={12}>
                                    <Grid>
                                        <Row nogutter>
                                            <Column xs={6} md={3}>
                                                <DropDown
                                                    top={Sizes.xs}
                                                    placeholder={`Assigned To`}
                                                    value={{code: query.brokerReferenceGuid == null ? null : query.brokerReferenceGuid}}
                                                    references={this.getAssignees()}
                                                    referencesEvent={() => this.props.reference.doGetReferences('ActiveBrokers')}
                                                    changeEvent={(value) => this.props.doReduceQueryElement({
                                                        code: `brokerReferenceGuid`,
                                                        value: value.code
                                                    })}
                                                    allowEdit
                                                />
                                            </Column>
                                            <Column xs={6} md={3}>
                                                <DropDown
                                                    top={Sizes.xs}
                                                    placeholder={this.props.intl.formatMessage({id: 'Products'})}
                                                    value={{code: query.product == null ? null : query.product}}
                                                    references={this.getProducts()}
                                                    referencesEvent={() => {
                                                    }}
                                                    changeEvent={(value) => this.props.doReduceQueryElement({
                                                        code: 'product',
                                                        value: value.code
                                                    })}
                                                    allowEdit
                                                />
                                            </Column>
                                            <Column xs={6} md={3}>
                                                <Input top={Sizes.xs}
                                                       inverse
                                                       placeholder={this.props.intl.formatMessage({id: 'app.components.SearchBar.fname'})}
                                                       value={query.clientFirstName}
                                                       changeEvent={(value) => {
                                                           this.props.doReduceQueryElement({
                                                               code: 'clientFirstName',
                                                               value
                                                           });
                                                       }}/>
                                            </Column>
                                            <Column xs={6} md={3}>
                                                <Input top={Sizes.xs}
                                                       inverse
                                                       placeholder={this.props.intl.formatMessage({id: 'app.components.SearchBar.lname'})}
                                                       value={query.clientLastName}
                                                       changeEvent={(value) => {
                                                           this.props.doReduceQueryElement({
                                                               code: 'clientLastName',
                                                               value
                                                           });
                                                       }}/>
                                            </Column>
                                            <Column xs={6} md={3}>
                                                <Input top={Sizes.xs}
                                                       inverse
                                                       placeholder='Phone'
                                                       value={query.clientPhoneFullNumber}
                                                       changeEvent={(value) => {
                                                           this.props.doReduceQueryElement({
                                                               code: 'clientPhoneFullNumber',
                                                               value
                                                           });
                                                       }}/>
                                            </Column>
                                            <Column xs={6} md={3}>
                                                <Input top={Sizes.xs}
                                                       inverse
                                                       placeholder='Email'
                                                       value={query.clientEmail}
                                                       changeEvent={(value) => {
                                                           this.props.doReduceQueryElement({
                                                               code: 'clientEmail',
                                                               value
                                                           });
                                                       }}/>
                                            </Column>
                                            <Column xs={6} md={12} lg={12}>
                                                <Container inline fullSize top={Sizes.xs}
                                                           hoizontalAlignment={Alignments.center}>
                                                    <SecondaryButton
                                                        text={<FormattedMessage id='covera.common.general.search'/>}
                                                        bottom={Sizes.xs}
                                                        clickEvent={() => {
                                                            this.setState({displaySearchCriteria: false});
                                                            this.props.doReduceQueryElement({
                                                                code: 'search',
                                                                value: true
                                                            });
                                                            this.props.doQueryBoundPolicies();
                                                        }}/>
                                                    <SecondaryButton
                                                        left={Sizes.xs}
                                                        bottom={Sizes.xs}
                                                        text='Clear'
                                                        clickEvent={() => {
                                                            this.setState({displaySearchCriteria: false});
                                                            this.props.doReduceQueryElement({
                                                                code: 'search',
                                                                value: false
                                                            });
                                                            this.props.doGetBoundPolicies();
                                                        }}
                                                    />
                                                </Container>
                                            </Column>
                                        </Row>
                                    </Grid>
                                </Column>
                                <Column xs={0} md={0} lg={2}/>
                            </Row>
                        </Grid>
                    </Container>
                </Container>
            </Container>
        );
    }
}

SearchHeader.contextTypes = {
    screen: PropTypes.object
};

export default injectIntl(SearchHeader);