import React, { useEffect } from "react";

import {
	Container
} from 'metrix-common';

import BoundedHeader from './Header';
import BoundedResults from './Results';
import BoundedFooter from './Footer';

const Bound = (props) => {
	useEffect(() => {
		props.doReduceView('bound');
		if (props.query.bound.search) {
			props.doQueryBoundPolicies();
		} else {
			props.doGetBoundPolicies();
		}
	}, [props.query.bound.search]);

	return (
		<Container>
			<BoundedHeader {...props} />
			<BoundedResults {...props} />
			<BoundedFooter {...props} />
		</Container>
	);
}

export default Bound;