import React from 'react';

class HighUpperScoreIcon extends React.Component {
        
    getIcon = (score) => {
        return (


            <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} viewBox="0 0 138 138">
                <defs>
                    <style>{".prefix__cls-2{fill:#d83c90}"}</style>
                </defs>
                <g id="prefix__Graph">
                    <g id="prefix__BG_Color" data-name="BG Color">
                        <path
                            className="prefix__cls-2"
                            d="M69 137.08A68.08 68.08 0 11137.08 69 68.16 68.16 0 0169 137.08zm0-126.16A58.08 58.08 0 10127.08 69 58.14 58.14 0 0069 10.92z"
                            id="prefix__Gradient_Base"
                            data-name="Gradient Base"
                            opacity={0.15}
                        />
                    </g>
                </g>
                <path
                    className="prefix__cls-2"
                    d="M53.71 135a6 6 0 01-.93-.14 67.9 67.9 0 1133.53-.26 5 5 0 11-2.54-9.6 58.06 58.06 0 10-28.61.21 5 5 0 01-1.45 9.79z"
                />                
                <text x="50%" y="50%" text-anchor="middle" stroke="#d83c90" fill="#d83c90" font-family="'Poppins'" stroke-width="2px" dy=".3em" fontSize="70px">{score}</text>                                   
            </svg>
            );
        }  

        render() {
            return (this.getIcon(this.props.score || 0));
        }    
}

export default HighUpperScoreIcon;



/*
 * 
 * 
 * <svg id="auto-insurance" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
                <rect id="Bounding_box" data-name="Bounding box" width={size} height={size} fill="transparent" />
                <g id="Transportation_Cars_car-clouds" data-name="Transportation / Cars / car-clouds" transform="translate(1 1)">
                    <g id="Group_31" transform="translate(0)">
                        <g id="car-clouds" transform="translate(0)">
                            <path fill={color} id="Shape_75" d="M201.039,431.687h-5.868a.3.3,0,0,1-.06,0,2.476,2.476,0,0,1-2.057-1.708,2.619,2.619,0,0,1,.617-2.659,2.4,2.4,0,0,1,1.292-.67.461.461,0,0,0,.308-.206,3.179,3.179,0,0,1,2.678-1.465,3.386,3.386,0,0,1,3.187,2.37.468.468,0,0,0,.354.323,2.008,2.008,0,0,1,1.585,1.991,1.949,1.949,0,0,1-.526,1.416A2.233,2.233,0,0,1,201.039,431.687Z" transform="translate(-181.1 -424.979)" />
                            <path fill={color} id="Shape_76" d="M205.671,430.671a2.391,2.391,0,0,1,1.292-.671.459.459,0,0,0,.308-.2,3.177,3.177,0,0,1,2.678-1.462,3.387,3.387,0,0,1,3.187,2.37.468.468,0,0,0,.354.323,2.008,2.008,0,0,1,1.585,1.991,1.949,1.949,0,0,1-.526,1.416,2.239,2.239,0,0,1-1.51.609h-5.868a.6.6,0,0,1-.06,0,2.477,2.477,0,0,1-2.057-1.709,2.617,2.617,0,0,1,.617-2.658Z" transform="translate(-204.921 -425.018)" />
                            <path fill={color} id="Shape_77" d="M194.035,444a.45.45,0,0,1-.44.383h0a.72.72,0,0,0,0,1.438h2.081a.016.016,0,0,1,.014.009,1.853,1.853,0,0,0,1.582,1.18,1.83,1.83,0,0,0,1.72-.95.46.46,0,0,1,.4-.239h5.7a.457.457,0,0,1,.4.239,1.812,1.812,0,0,0,3.193,0,.46.46,0,0,1,.4-.239h.277a.936.936,0,0,0,.916-.835,3.419,3.419,0,0,0-.61-2.725,2.912,2.912,0,0,0-1.994-.959.464.464,0,0,1-.377-.287l-.19-.462a6.849,6.849,0,0,0-6.3-4.313,6.58,6.58,0,0,0-6.433,5.667Zm4.038-2.971a.455.455,0,0,1-.389-.223.5.5,0,0,1-.028-.461,3.615,3.615,0,0,1,6.646,0,.492.492,0,0,1-.027.461.457.457,0,0,1-.39.223Z" transform="translate(-188.345 -425.157)" fillRule="evenodd" />
                        </g>
                    </g>
                </g>
            </svg>
 */
// export default HighScoreIcon;