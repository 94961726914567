import { put, call, select } from 'redux-saga/effects';
import { reduceOperation, reduceLoading, getSessionSaga } from 'metrix-common';
import { reducePolicy } from '../../actions/policy/reducePolicy';
import { selectEntity } from '../../selectors/policy/selectEntity';
import { reduceAllowEdit } from '../../actions/policy/reduceAllowEdit';

export function* getPolicySaga() {
    try {
        yield put(reduceLoading({ code: `getPolicy`, value: true }));
        const session = yield getSessionSaga();
        const entity = yield select(selectEntity());

        const response = yield call(fetch, `/api/policyTransaction/${entity}`, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Authorization': session.authenticationToken.token,
                'Content-Type': 'application/json'
            }
        });

        const result = yield call([response, 'json']);
        yield put(reduceOperation(result.operation));

        if (!result.operation.status) {
            return;
        }

        yield put(reducePolicy(result.policyTransaction));
        yield put(reduceAllowEdit(result.isReadOnly))
        yield put(reduceLoading({ code: 'getPolicy', value: false }));
    }
    catch (error) {
        console.log('Error while calling Get Policy API - ', error);
    } finally {
        yield put(reduceLoading({ code: 'getPolicy', value: false }));
    }
}
