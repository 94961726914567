import React from 'react';
import styled from 'styled-components';

import {
    Container,
    Header,
    BulletList,
    Alignments,
    Colors,
    Sizes
} from 'metrix-common';

const MessagesContainer = styled(Container)`
    ${({ outline }) => outline && `
        border: 1px solid ${Colors.StrokeLight};
        border-radius: 4px;
        padding: 10px;`}
`;

class ProfileMessages extends React.Component {
    render() {
        return (
            <MessagesContainer {...this.props}>
                {this.props.title && <Header size={this.props.titleSize || Sizes.sm} textAlign={Alignments.left} text={this.props.title} />}
                <BulletList key={Math.random().toString()} references={this.props.messages} size={this.props.size || Sizes.md} />
            </MessagesContainer>
        );
    }
}

export default ProfileMessages;
