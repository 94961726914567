import { put, call, select } from 'redux-saga/effects';
import { reduceOperation, reduceLoading, getSessionSaga } from 'metrix-common';
import { reducePolicyLogContent } from '../../actions/policy/reducePolicyLogContent';

export function* getLogContentSaga(action) {
    try {
        yield put(reduceLoading({ code: `getLogContent`, value: true }));
        const session = yield getSessionSaga();
        let response = yield call(fetch, `/api/log/log-content?logName=${action.payload.logName}`, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Authorization': session.authenticationToken.token,
                'Content-Type': 'application/json'
            }
        });
        let result = yield call([response, 'json']);
        yield put(reduceOperation(result.operation));
        //if (!result.operation.status) {
        //    yield put(reduceLoading({ code: `getLogContent`, value: false }));
        //    return;
        //}
        yield put(reducePolicyLogContent({ code: action.payload.eventCode, value:result }));
        yield put(reduceLoading({ code: `getLogContent`, value: false }));
        return;
    }
    catch (error) {
        console.log("Error while calling Get API Log Content - ", error);
        yield put(reduceLoading({ code: `getLogContent`, value: false }));
        return;
    }
}
