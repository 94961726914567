import { put, call, select } from 'redux-saga/effects';
import { reduceOperation, reduceLoading, getSessionSaga } from 'metrix-common';
import { selectEntity } from '../../selectors/policy/selectEntity';
import { reducePolicySummary } from '../../actions/policy/reducePolicySummary';
import { reduceProductSettings } from '../../actions/policy/reduceProductSettings';

export function* getPolicySummarySaga() {
    try {
        yield put(reduceLoading({ code: `getPolicySummary`, value: true }));
        const session = yield getSessionSaga();
        const entity = yield select(selectEntity());
        let response = yield call(fetch, `/api/policyTransaction/summary/${entity}`, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Authorization': session.authenticationToken.token,
                'Content-Type': 'application/json'
            }
        });
        let result = yield call([response, 'json']);
        yield put(reduceOperation(result.operation));
        if (!result.operation.status) {
            yield put(reduceLoading({ code: `getPolicySummary`, value: false }));
            return;
        }
        yield put(reducePolicySummary(result.policySummary));
        yield put(reduceProductSettings(result.productSettings));
        yield put(reduceLoading({ code: `getPolicySummary`, value: false }));
        return;
    }
    catch (error) {
        console.log("Error while calling Get Policy Summary API - ", error);
        yield put(reduceLoading({ code: `getPolicySummary`, value: false }));
        return;
    }
}
