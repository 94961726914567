import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import {
    Sizes,
    Alignments,
    Colors,
    Separator,
    Grid,
    Row,
    Column,
    Container,
    Header,
    Label,
    PolicyAttention,
    PolicyBind,
    PolicyQuote,
    PolicyQuoteAccept,
    PolicyIssue,
    PolicyRenewal
} from 'metrix-common';

import Hero from '../../CommonComponents/Hero'


class ProfileRenewalHeader extends React.Component {
    renderHeader(policyStatus) {
        let icon = null;
        let mainHeader = 'Renewal Review';
        let subHeader = '';
        switch (policyStatus) {
            case 'incomplete':
            case 'submitted':
            case 'accepted':
                icon = <PolicyRenewal />;
                subHeader = 'Create a Renewal';
                break;
            case 'quoted':
            case 'quoteIssued':
                icon = <PolicyQuote />;
                subHeader = 'Create a Renewal';
                break;
            case 'quoteAccepted':
                icon = <PolicyQuoteAccept />;
                subHeader = 'Create a Renewal';
                break;
            case 'rejected':
            case 'abandoned':
            case 'lost':
                icon = <PolicyAttention />;
                break;
            case 'bindRequested':
                icon = <PolicyBind />;
                subHeader = 'Create a Renewal';
                break;
            case 'bound':
                icon = <PolicyBind />;
                subHeader = 'Bound';
                break;
            case 'issued':
                mainHeader = `You're all set!`;
                subHeader = `Renewal Policy`;
                icon = <PolicyIssue />;
                break;
            case 'renewal':
                icon = <PolicyQuote />;
                break;
            default:
                icon = <PolicyBind />;
                break;
        }

        return ({ icon, mainHeader, subHeader });
    }

    getDocuments = (type) => {
        let documents = [];
        if (this.props.document &&
            this.props.document.documents &&
            this.props.document.documents.length > 0 &&
            this.props.document.documents.some(x => x.type === type)) {
            documents = this.props.document.documents.filter(x => x.type === type);
        }

        return documents;
    }

    render() {


        const isDesktop = this.props.isDesktop;
        const isTablet = this.props.isTablet;
        const isMobile = this.props.isMobile;

        let isAdmin = (this.props.context.session.userType === `administrator`);
        let isBroker = (this.props.context.session.userType === `broker`);
        let isClient = (this.props.context.session.userType === `client`);

        const clientName = this.props.context.session.firstName;
        const clientStatus = this.props.policySummary.status;

        const { icon, mainHeader, subHeader } = this.renderHeader(this.props.policySummary.status);

        return (
            <Container>
                {
                    isClient ?
                        <Hero>
                            <Grid>
                                {
                                    isMobile ?
                                        <>
                                            <Row>
                                                <Column xs={12}>
                                                    <Header
                                                        color={Colors.LightMode}
                                                        size={Sizes.xl}
                                                        text={
                                                            <div>
                                                                <FormattedMessage id="app.components.ClientDashboard.welcome" />
                                                                <span> {clientName}!</span>
                                                            </div>
                                                        }
                                                    />
                                                </Column>
                                            </Row>
                                            <Row>
                                                <Column xs={12}>
                                                    <Label
                                                        top={Sizes.xs}
                                                        left={Sizes.sm}
                                                        right={Sizes.sm}
                                                        size={Sizes.lg}
                                                        color={Colors.LightMode}
                                                        text={<FormattedMessage id="app.components.ClientDashboard.subHeader" />}
                                                    />
                                                </Column>
                                            </Row>
                                        </>
                                        :
                                        <>
                                            <Row>
                                                <Column xs={12}>
                                                    <Header
                                                        color={Colors.LightMode}
                                                        size={Sizes.xl}
                                                        text={
                                                            <div>
                                                                <FormattedMessage id="app.components.ClientDashboard.welcome" />
                                                                <span> {clientName}!</span>
                                                            </div>
                                                        }
                                                    />
                                                </Column>
                                            </Row>
                                            <Row>
                                                <Column>
                                                    <Label
                                                        top={Sizes.md}
                                                        left={Sizes.xl}
                                                        right={Sizes.xl}
                                                        size={Sizes.lg}
                                                        color={Colors.LightMode}
                                                        text={<FormattedMessage id="app.components.ClientDashboard.subHeader" />}
                                                    />
                                                </Column>
                                            </Row>
                                        </>
                                }
                            </Grid>
                        </Hero>
                        :

                        <Grid>
                            <Row>
                                <Column>
                                    <Container inline top={Sizes.xl} horizontalAlignment={Alignments.center} >
                                        {icon}
                                    </Container>
                                    <Header
                                        size={Sizes.xl}
                                        top={Sizes.sm}
                                        bottom={Sizes.xs}
                                        text={`${mainHeader}`}
                                    />
                                    <Label bottom={Sizes.lg}
                                        text={`${subHeader}`}
                                    />
                                </Column>
                            </Row>
                        </Grid>
                }
                <Separator solid top={Sizes.xxs} />
            </Container>
        );
    }
}

export default ProfileRenewalHeader;
