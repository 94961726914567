import React from 'react';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns'
import {
    Alignments,
    BackButton,
    Divider,
    Form,
    FormDate,
    FormInput,
    Header,
    Sizes,
} from 'metrix-common';

class ProfileEmployersWcClaim extends React.Component {
    state = {
        mode: `claimView`,
        current: null
    };

    render() {
        return (
            this.state.mode === `claimView` &&
            <React.Fragment>
                <BackButton clickEvent={this.props.backEvent} />
                <Divider top={Sizes.md} />
                    <Header size={Sizes.md} textAlign={Alignments.left} text={this.props.claim.claimPageName} />
                <Form
                    rowId={this.props.rowId}
                    sectionCode='claimDetails'
                    pageCode={this.props.pageCode}
                    saveTooltipInfo={this.props.saveTooltipInfo}
                    reduceTooltipInfoElement={this.props.reduceTooltipInfoElement}
                    tooltipInfo={this.props.tooltipInfo}
                    label={`Claim details`}
                    allowEdit={this.props.allowEdit}
                    saveEvent={() => this.props.doUpsertPolicy()}
                    cancelEvent={() => this.props.doGetPolicy()}
                >
                    <FormDate
                        fieldCode='effectiveDate'
                        label={<FormattedMessage id={`Claim effective year placed`} />}
                        value={this.props.claim?.effectiveDate}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}effectiveDate`, value })}
                        mode={`past`}
                        culture={this.props.context.session.culture}
                        allowEdit
                    />
                    <FormInput
                        fieldCode='numberOfClaims'
                        label={`Number of Claims`}
                        value={this.props.claim?.numberOfClaims}
                        type='number'
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}numberOfClaims`, value })}
                        allowEdit
                    />
                    <FormInput
                        fieldCode='numberOfLostTimeClaims'
                        label={`Number of lost time claims`}
                        value={this.props.claim?.numberOfLostTimeClaims}
                        type='number'
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}numberOfLostTimeClaims`, value })}
                        allowEdit
                    />
                    <FormInput
                        fieldCode='amountPaidAndReserved'
                        label={`Paid and reserved amount`}
                        value={this.props.claim?.amountPaidAndReserved}
                        type='currency'
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}amountPaidAndReserved`, value })}
                        allowEdit
                    />
                    <FormInput
                        fieldCode='annualPayroll'
                        label={`Annual payroll`}
                        value={this.props.claim?.annualPayroll}
                        type='currency'
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}annualPayroll`, value })}
                        allowEdit
                    />
                </Form>
            </React.Fragment>
        );
    }
}

export default ProfileEmployersWcClaim;