import React, { Component } from 'react';
import styled from 'styled-components';

import {
    Alignments,
    Sizes,
    Grid,
    Row,
    Column,
    Container,
    Separator,
    CarouselButton
} from 'metrix-common';

const ButtonContainer = styled(Container)`
    overflow: hidden;
`;

class Footer extends Component {
    render() {
        return (
            <Grid>
                <Row noguttertop>
                    <Column>
                        <Separator solid />
                    </Column>
                </Row>
                <Row>
                    <Column>
                        <ButtonContainer inline fullSize horizontalAlignment={Alignments.right} verticalAlignment={Alignments.center}>
                            {
                                this.props.query.bound.skip > 0 &&
                                <CarouselButton direction='left'
                                    clickEvent={() => {
                                        this.props.doReduceQueryElement({ code: 'search', value: true });
                                        this.props.doReduceQueryElement({ code: 'skip', value: this.props.query.bound.skip - 25 });
                                        this.props.doQueryBoundPolicies();
                                    }}
                                />
                            }
                            {
                                this.props.boundPolicies?.length === 25 &&
                                <CarouselButton direction='right'
                                    left={Sizes.xs}
                                    clickEvent={() => {
                                        this.props.doReduceQueryElement({ code: 'search', value: true });
                                        this.props.doReduceQueryElement({ code: 'skip', value: this.props.query.bound.skip + 25 });
                                        this.props.doQueryBoundPolicies();
                                    }}
                                />
                            }
                        </ButtonContainer>
                    </Column>
                </Row>
            </Grid>
        );
    }
}

export default Footer;