import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Form,
    FormDropDown,
    FormSwitch
} from 'metrix-common';

class ProfileFluxGlProductSelection extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            <React.Fragment>
                <Form
                    header={<FormattedMessage id={'Description of operations / Industry'} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormDropDown
                        label={<FormattedMessage id={`Please select operation:`} />}
                        value={{ code: this.props.policy.fluxCustomInfo.businessInfo?.cannabisOperationsTypeCode, value: this.props.policy.fluxCustomInfo.businessInfo?.cannabisOperationsTypeName }}
                        references={this.props.reference?.references[`FLUX-GL`] && this.props.reference.references[`FLUX-GL`][`CannabisOperationsTypes`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`FLUX-GL.CannabisOperationsTypes`)}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.cannabisOperationsTypeCode`, value: value.code });
                            this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.cannabisOperationsTypeName`, value: value.value });
                        }}
                        allowEdit
                    />
                </Form>
                <Form
                    header={<FormattedMessage id={'Please select products:'} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormSwitch
                        label={<FormattedMessage id={`General Liability`} />}
                        value={this.props.policy.fluxCustomInfo.product?.hasGeneralLiability}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.product.hasGeneralLiability`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Property Insurance`} />}
                        value={this.props.policy.fluxCustomInfo.product?.hasPropertyInsurance}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.product.hasPropertyInsurance`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Product Liability`} />}
                        value={this.props.policy.fluxCustomInfo.product?.hasProductLiability}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.product.hasProductLiability`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Business Owners Package`} />}
                        value={this.props.policy.fluxCustomInfo.product?.hasBusinessOwnerPackage}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.product.hasBusinessOwnerPackage`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Workers Compensation`} />}
                        value={this.props.policy.fluxCustomInfo.product?.hasWorkersComp}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.product.hasWorkersComp`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Business Auto`} />}
                        value={this.props.policy.fluxCustomInfo.product?.hasBusinessAuto}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.product.hasBusinessAuto`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Excess Liability`} />}
                        value={this.props.policy.fluxCustomInfo.product?.hasExcessLiability}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.product.hasExcessLiability`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Employment Practices`} />}
                        value={this.props.policy.fluxCustomInfo.product?.hasEmploymentPractices}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.product.hasEmploymentPractices`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Directors and Officers`} />}
                        value={this.props.policy.fluxCustomInfo.product?.hasDirectorsAndOfficers}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.product.hasDirectorsAndOfficers`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Errors and Omissions`} />}
                        value={this.props.policy.fluxCustomInfo.product?.hasErrorsAndOmissions}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.product.hasErrorsAndOmissions`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Cyber`} />}
                        value={this.props.policy.fluxCustomInfo.product?.hasCyber}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.product.hasCyber`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Cannabis Bonds`} />}
                        value={this.props.policy.fluxCustomInfo.product?.hasCannabisBonds}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.product.hasCannabisBonds`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Cannabis Events`} />}
                        value={this.props.policy.fluxCustomInfo.product?.hasCannabisEvents}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.product.hasCannabisEvents`, value: value })}
                        allowEdit
                    />
                </Form>               
            </React.Fragment>
        );
    }
}

export default ProfileFluxGlProductSelection;