import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Sizes,
    Alignments,
    BackButton,
    Divider,
    Header,
    Form,
    FormDropDown,
    FormInput,
    FormDate,
    FormRadio
} from 'metrix-common';

class ProfileDriverSuspension extends React.Component {
    render() {
        let convictions = [];
        if (this.props.convictions) {
            this.props.convictions.forEach((conviction) => {
                convictions.push({ code: conviction.value, value: conviction.code });
            });
        }
        return (
            <React.Fragment>
                <BackButton clickEvent={this.props.backEvent} />
                <Divider top={Sizes.md} />
                <Header size={Sizes.md} textAlign={Alignments.left} text={`Driving Suspension`} />
                <Form
                    label={`Driving Suspension Details`}
                    allowEdit={this.props.allowEdit}
                    saveEvent={() => this.props.doUpsertPolicy()}
                    cancelEvent={() => this.props.doGetPolicy()}
                >
                    <FormDate
                        label={<FormattedMessage id={`Start Date`} />}
                        value={this.props.suspension && this.props.suspension.startDate}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}startDate`, value })}
                        culture={this.props.context.session.culture}
                        mode={`past`}
                        allowEdit
                    />
                    <FormDate
                        label={<FormattedMessage id={`End Date`} />}
                        value={this.props.suspension && this.props.suspension.endDate}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}endDate`, value })}
                        culture={this.props.context.session.culture}
                        mode={`past`}
                        allowEdit
                    />
                    <FormDate
                        label={<FormattedMessage id={`Reinstatement Date`} />}
                        value={this.props.suspension && this.props.suspension.reinstatementDate}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}reinstatementDate`, value })}
                        culture={this.props.context.session.culture}
                        mode={`past`}
                        allowEdit
                    />
                    <FormDropDown
                        label={<FormattedMessage id={`Related Conviction`} />}
                        value={this.props.suspension &&
                            {
                                code: this.props.suspension.relatedEvent
                            }
                        }
                        references={convictions}
                        referencesEvent={() => { }}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `${this.props.dotProp}relatedEvent`, value: value.code });
                        }}
                        allowEdit
                    />
                </Form>
            </React.Fragment>
        );
    }
}

export default ProfileDriverSuspension;