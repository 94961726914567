import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Form,
    FormChecklist,
    FormInput
} from 'metrix-common';

class ProfileInterwestCyberBusiness extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            <>
                <Form
                    header={<FormattedMessage id='Business Information' />}
                    label='Details'
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormInput
                        label={'Business Name'}
                        value={this.props.policy.interWestCyberCustomInfo.businessInfo.businessName}
                        type='text'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestCyberCustomInfo.businessInfo.businessName', value })}
                        allowEdit
                    />
                    <FormChecklist
                        label={<FormattedMessage id='Business Structure' />}
                        value={this.props.policy.interWestCyberCustomInfo.businessInfo.businessEntityTypeSelectedItems}
                        references={this.props.reference.references['InterWest-Cyber'] && this.props.reference.references['InterWest-Cyber'].EntityTypes}
                        referencesEvent={() => this.props.reference.doGetReferences('InterWest-Cyber.EntityTypes')}
                        changeEvent={values => {
                            this.props.doReducePolicyElement({ code: 'interWestCyberCustomInfo.businessInfo.businessEntityTypeSelectedItems', value: values });
                        }}
                        allowEdit
                    />
                    <FormInput
                        label='Website'
                        value={this.props.policy.interWestCyberCustomInfo.businessInfo.businessWebsite}
                        type='text'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestCyberCustomInfo.businessInfo.businessWebsite', value })}
                        allowEdit
                    />
                    <FormInput
                        label='Year the Business was Established'
                        value={this.props.policy.interWestCyberCustomInfo.businessInfo.businessYearEstablishedNumber}
                        type='number'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestCyberCustomInfo.businessInfo.businessYearEstablishedNumber', value })}
                        maxValue={9999}
                        allowEdit
                    />
                    <FormInput
                        label='NAICS Code'
                        value={this.props.policy.interWestCyberCustomInfo.businessInfo.businessNAICSNumber}
                        type='number'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestCyberCustomInfo.businessInfo.businessNAICSNumber', value })}
                        maxValue={999999}
                        allowEdit
                    />
                </Form>
                <Form
                    header={<FormattedMessage id='Business Assets and Revenues'/>}
                    label='Details'
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormInput
                        label='Total assets amount'
                        value={this.props.policy.interWestCyberCustomInfo.businessInfo.businessTotalAssetsAmount}
                        type='currency'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestCyberCustomInfo.businessInfo.businessTotalAssetsAmount', value })}
                        allowEdit
                    />
                    <FormInput
                        label='Annual revenues amount'
                        value={this.props.policy.interWestCyberCustomInfo.businessInfo.businessAnnualRevenuesAmount}
                        type='currency'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestCyberCustomInfo.businessInfo.businessAnnualRevenuesAmount', value })}
                        allowEdit
                    />
                </Form>
            </>
        );
    }
}

export default ProfileInterwestCyberBusiness;