import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Form, FormInput, FormDate
} from 'metrix-common';

class ProfileInterwestCyberLimit extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            <Form
                header={<FormattedMessage id='Limit' />}
                label='Details'
                saveEvent={this.props.doUpsertPolicy}
                cancelEvent={this.props.doGetPolicy}
                allowEdit={this.props.allowEdit}
            >
                <FormInput
                    label='Aggregate Limit Requested:'
                    value={this.props.policy.interWestCyberCustomInfo.limitsInfo.aggregateLimitRequestedAmount}
                    type='number'
                    changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestCyberCustomInfo.limitsInfo.aggregateLimitRequestedAmount', value })}
                    allowEdit
                />
                <FormInput
                    label='Retention Requested:'
                    value={this.props.policy.interWestCyberCustomInfo.limitsInfo.retentionRequestedAmount}
                    type='number'
                    changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestCyberCustomInfo.limitsInfo.retentionRequestedAmount', value })}
                    allowEdit
                />
                <FormDate
                    label={<FormattedMessage id='Effective Date' />}
                    value={this.props.policy.effectiveDate}
                    changeEvent={value => this.props.doReducePolicyElement({ code: 'effectiveDate', value })}
                    culture={this.props.context.session.culture}
                    allowEdit
                />
            </Form>
        );
    }
}

export default ProfileInterwestCyberLimit;