import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Sizes,
    Alignments,
    Grid,
    Row,
    Column,
    Container,
    Header,
    PrimaryButton
} from 'metrix-common';

class ProfileRecap extends React.Component {

    render() {
        let amounts = {
            estimatedAnnualGross: this.props.policySummary.formattedEstimatedAnnualGross,
            estimatedAnnualPremium: this.props.policySummary.formattedEstimatedAnnualPremium,
            estimatedMonthlyGross: this.props.policySummary.formattedEstimatedMonthlyGross
        };
        let estimatedTaxes = this.props.policySummary.estimatedTaxes && this.props.policySummary.estimatedTaxes.value || 0;

        return (
            <Container>
                <Grid>
                    <Row>
                        <Column bogutter>
                            <Header size={Sizes.md} bottom={Sizes.xs} text={estimatedTaxes > 0 && <FormattedMessage id={`app.components.RecapCard.taxesIncluded`} values={amounts} /> || amounts.estimatedAnnualGross} />
                            {
                                !this.props.policy.isSinglePayment &&
                                <Header size={Sizes.sm} bottom={Sizes.xs} text={<FormattedMessage id={`app.components.RecapCard.savings`} values={amounts} />} />
                            }
                            {
                                this.props.policySummary.status === 'bound' || this.props.policySummary.status === 'issued' &&
                                <Container inline horizontalAlignment={Alignments.center} top={Sizes.md} bottom={Sizes.xs}>
                                    <PrimaryButton clickEvent={() => this.props.changeTabTo('documents')}
                                                   text={<FormattedMessage id={'app.components.PolicySummaryCard.downloadBinder'} />}
                                    />
                                </Container>
                            }
                        </Column>
                    </Row>
                </Grid>
            </Container>
        );
    }
}

export default ProfileRecap;
