import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Alignments,
    FormSwitch,
    Form,
    FormDropDown,
    FormTextArea,
    FormRadio,
    Label,
    convertFromBoolean,
    convertToBoolean
} from 'metrix-common';

class ProfileFluxGlCultivationLighting extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            <React.Fragment>
                <Form
                    header={<FormattedMessage id={`Cultivation Lighting`} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormDropDown
                        label={<FormattedMessage id={`Type(s) of lighting`} />}
                        value={{ code: this.props.policy.fluxCustomInfo.cultivationLighting?.lightingTypeCode }}
                        references={this.props.reference?.references[`FLUX-GL`] && this.props.reference.references[`FLUX-GL`][`LightingTypes`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`FLUX-GL.LightingTypes`)}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.lightingTypeCode`, value: value.code });
                            this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.lightingTypeName`, value: value.value });
                        }}
                        allowEdit
                    />
                    {this.props.policy.fluxCustomInfo.cultivationLighting?.lightingTypeCode === 'OTHER' &&
                        <Label bold text={<FormattedMessage id={`Please describe the type of lighting used`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.fluxCustomInfo.cultivationLighting?.lightingTypeCode === 'OTHER' &&
                        <FormTextArea
                            text={this.props.policy.fluxCustomInfo.cultivationLighting?.lightingTypeOtherDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.lightingTypeOtherDescription`, value })}
                        />
                    }
                </Form>
                {this.props.policy.fluxCustomInfo.cultivationLighting?.lightingTypeCode !== 'LED' && this.props.policy.fluxCustomInfo.cultivationLighting?.lightingTypeCode !== undefined &&
                    <React.Fragment>
                        <Form
                            header={<FormattedMessage id={`Ballast information`} />}
                            label={`Details`}
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            allowEdit={this.props.allowEdit}
                        >
                            <Label bold text={<FormattedMessage id={`Name of ballast manufacturer(s)`} />} textAlign={Alignments.left} />
                            <FormTextArea
                                text={this.props.policy.fluxCustomInfo.cultivationLighting?.ballastManufacturerNames}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.ballastManufacturerNames`, value })}
                            />
                            <Label bold text={<FormattedMessage id={`Ballast model name(s)/number(s)`} />} textAlign={Alignments.left} />
                            <FormTextArea
                                text={this.props.policy.fluxCustomInfo.cultivationLighting?.ballastModelData}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.ballastModelData`, value })}
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Have you modified the ballasts beyond manufacturer specifications?`} />}
                                value={this.props.policy.fluxCustomInfo.cultivationLighting?.hasCustomModifiedBallasts !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationLighting.hasCustomModifiedBallasts) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.hasCustomModifiedBallasts`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            {this.props.policy.fluxCustomInfo.cultivationLighting?.hasCustomModifiedBallasts === true &&
                                <Label bold text={<FormattedMessage id={`Please explain any modifications below`} />} textAlign={Alignments.left} />
                            }
                            {this.props.policy.fluxCustomInfo.cultivationLighting?.hasCustomModifiedBallasts === true &&
                                <FormTextArea
                                    text={this.props.policy.fluxCustomInfo.cultivationLighting?.customModifiedBallastsDescription}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.customModifiedBallastsDescription`, value })}
                                />
                            }
                        </Form>
                        <Form
                            header={<FormattedMessage id={`Type of ballast(s) used in your operation`} />}
                            label={`Details`}
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            allowEdit={this.props.allowEdit}
                        >
                            <FormSwitch
                                label={<FormattedMessage id={`Magnetic`} />}
                                value={this.props.policy.fluxCustomInfo.cultivationLighting?.isBallastTypeMagnetic}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.isBallastTypeMagnetic`, value: value })}
                                allowEdit
                            />
                            <FormSwitch
                                label={<FormattedMessage id={`Digital/Electronic`} />}
                                value={this.props.policy.fluxCustomInfo.cultivationLighting?.isBallastTypeDigitalElectronic}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.isBallastTypeDigitalElectronic`, value: value })}
                                allowEdit
                            />
                            <FormSwitch
                                label={<FormattedMessage id={`Other`} />}
                                value={this.props.policy.fluxCustomInfo.cultivationLighting?.isBallastTypeOther}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.isBallastTypeOther`, value: value })}
                                allowEdit
                            />
                            {this.props.policy.fluxCustomInfo.cultivationLighting?.isBallastTypeOther === true &&
                                <Label bold text={<FormattedMessage id={`Please describe the type of ballast used`} />} textAlign={Alignments.left} />
                            }
                            {this.props.policy.fluxCustomInfo.cultivationLighting?.isBallastTypeOther === true &&
                                <FormTextArea
                                    text={this.props.policy.fluxCustomInfo.cultivationLighting?.ballastTypeOtherDescription}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.ballastTypeOtherDescription`, value })}
                                />
                            }
                        </Form>
                        <Form
                            header={<FormattedMessage id={`Light bulb information`} />}
                            label={`Details`}
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            allowEdit={this.props.allowEdit}
                        >
                            <Label bold text={<FormattedMessage id={`Name of light bulb manufacturer(s)`} />} textAlign={Alignments.left} />
                            <FormTextArea
                                text={this.props.policy.fluxCustomInfo.cultivationLighting?.lightBulbManufacturerNames}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.lightBulbManufacturerNames`, value })}
                            />
                            <Label bold text={<FormattedMessage id={`Bulb model(s) and type(s) used in your operation (model name/number, and type such as MH, HPS, LED, etc.)`} />} textAlign={Alignments.left} />
                            <FormTextArea
                                text={this.props.policy.fluxCustomInfo.cultivationLighting?.lightBulbModelData}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.lightBulbModelData`, value })}
                            />
                            <Label bold text={<FormattedMessage id={`Do you use single-ended (SE), or double-ended (DE) bulbs?`} />} textAlign={Alignments.left} />
                            <FormSwitch
                                label={<FormattedMessage id={`SE`} />}
                                value={this.props.policy.fluxCustomInfo.cultivationLighting?.isBulbsType_SE}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.isBulbsType_SE`, value: value })}
                                allowEdit
                            />
                            <FormSwitch
                                label={<FormattedMessage id={`DE`} />}
                                value={this.props.policy.fluxCustomInfo.cultivationLighting?.isBulbsType_DE}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.isBulbsType_DE`, value: value })}
                                allowEdit
                            />
                        </Form>
                        {this.props.policy.fluxCustomInfo.cultivationLighting?.isBallastTypeDigitalElectronic === true &&
                            <Form
                                header={<FormattedMessage id={`Type of bulb(s) used`} />}
                                label={`Details`}
                                saveEvent={this.props.doUpsertPolicy}
                                cancelEvent={this.props.doGetPolicy}
                                allowEdit={this.props.allowEdit}
                            >
                                <FormSwitch
                                    label={<FormattedMessage id={`MH - Metal Halide / Ceramic Metal Halide`} />}
                                    value={this.props.policy.fluxCustomInfo.cultivationLighting?.isDigitalElectronicTypeMH}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.isDigitalElectronicTypeMH`, value: value })}
                                    allowEdit
                                />
                                <FormSwitch
                                    label={<FormattedMessage id={`HPS - High Pressure Sodium`} />}
                                    value={this.props.policy.fluxCustomInfo.cultivationLighting?.isDigitalElectronicTypeHPS}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.isDigitalElectronicTypeHPS`, value: value })}
                                    allowEdit
                                />
                                <FormSwitch
                                    label={<FormattedMessage id={`MH & HPS`} />}
                                    value={this.props.policy.fluxCustomInfo.cultivationLighting?.isDigitalElectronicTypeMHHPS}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.isDigitalElectronicTypeMHHPS`, value: value })}
                                    allowEdit
                                />
                                <FormSwitch
                                    label={<FormattedMessage id={`Other`} />}
                                    value={this.props.policy.fluxCustomInfo.cultivationLighting?.isDigitalElectronicTypeOther}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.isDigitalElectronicTypeOther`, value: value })}
                                    allowEdit
                                />
                                {this.props.policy.fluxCustomInfo.cultivationLighting?.isDigitalElectronicTypeOther === true &&
                                    <Label bold text={<FormattedMessage id={`Please describe the type of bulb`} />} textAlign={Alignments.left} />
                                }
                                {this.props.policy.fluxCustomInfo.cultivationLighting?.isDigitalElectronicTypeOther === true &&
                                    <FormTextArea
                                        text={this.props.policy.fluxCustomInfo.cultivationLighting?.digitalElectronicTypeOtherDescription}
                                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.digitalElectronicTypeOtherDescription`, value })}
                                    />
                                }
                            </Form>
                        }
                        <Form
                            header={<FormattedMessage id={`Additional questions`} />}
                            label={`Details`}
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            allowEdit={this.props.allowEdit}
                        >
                            <FormRadio
                                label={<FormattedMessage id={`Do you use different types of bulbs in the vegetative phase versus the flower phase?`} />}
                                value={this.props.policy.fluxCustomInfo.cultivationLighting?.useDifferentBulbsInPhases !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationLighting.useDifferentBulbsInPhases) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.useDifferentBulbsInPhases`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Do you ever use Metal Halide and High Pressure Sodium bulbs interchangeably in your fixtures?`} />}
                                value={this.props.policy.fluxCustomInfo.cultivationLighting?.useMHAndHPSBulbs !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationLighting.useMHAndHPSBulbs) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.useMHAndHPSBulbs`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            {this.props.policy.fluxCustomInfo.cultivationLighting?.useMHAndHPSBulbs === true &&
                                <FormRadio
                                    label={<FormattedMessage id={`Do you ever use Metal Halide bulbs in High Pressure Sodium ballasts?`} />}
                                    value={this.props.policy.fluxCustomInfo.cultivationLighting?.useMHInHPSBallasts !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationLighting.useMHInHPSBallasts) }}
                                    references={this.props.reference?.references[`YesNo`]}
                                    referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.useMHInHPSBallasts`, value: convertToBoolean(value.code) })}
                                    allowEdit
                                />
                            }
                            <FormRadio
                                label={<FormattedMessage id={`Are the Light fittings and being used in accordance with the operating instructions supplied by the manufacturer?`} />}
                                value={this.props.policy.fluxCustomInfo.cultivationLighting?.useLightFittingsAccordinglyInstructions !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationLighting.useLightFittingsAccordinglyInstructions) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.useLightFittingsAccordinglyInstructions`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Is there any water contact with the lights?`} />}
                                value={this.props.policy.fluxCustomInfo.cultivationLighting?.hasLightsWithWaterContact !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationLighting.hasLightsWithWaterContact) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.hasLightsWithWaterContact`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Is the stock layout such that the position of lamps is within aisles to minimize the risk of hot particles falling onto combustible items in the event of an uncontained failure?`} />}
                                value={this.props.policy.fluxCustomInfo.cultivationLighting?.hasLampsWithinAisles !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationLighting.hasLampsWithinAisles) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.hasLampsWithinAisles`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Do you use lamps in close proximity to combustible items that can cause a fire hazard?`} />}
                                value={this.props.policy.fluxCustomInfo.cultivationLighting?.hasLampsNearCombustibleItems !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationLighting.hasLampsNearCombustibleItems) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.hasLampsNearCombustibleItems`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Do you only replace lamps with the correct type for the fitting and ensure all containment devices are in good order and replaced correctly?`} />}
                                value={this.props.policy.fluxCustomInfo.cultivationLighting?.useCorrectLampsType !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationLighting.useCorrectLampsType) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.useCorrectLampsType`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Did you modify existing fittings or retrofit non-approved containment barriers without manufacturers input and approval otherwise correct heat dissipation may be compromised causing lamp rupture?`} />}
                                value={this.props.policy.fluxCustomInfo.cultivationLighting?.hasCustomModifiedFittingsOrBarriers !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationLighting.hasCustomModifiedFittingsOrBarriers) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.hasCustomModifiedFittingsOrBarriers`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Where lamps are continuous operation, are they turned off once per week for 15 minutes per week?`} />}
                                value={this.props.policy.fluxCustomInfo.cultivationLighting?.hasMaintainingScheduleForLamps !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationLighting.hasMaintainingScheduleForLamps) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.hasMaintainingScheduleForLamps`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`As failure rate increases with age, are the lamp/bulbs or those installed in uncontained light fittings are group replaced at 70% of their rated life?`} />}
                                value={this.props.policy.fluxCustomInfo.cultivationLighting?.isReplacedBulbsAt70PercentLifeRate !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationLighting.isReplacedBulbsAt70PercentLifeRate) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.isReplacedBulbsAt70PercentLifeRate`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Some lamps are designed to contain hot fragments on catastrophic failure, are you completing routine replacements?`} />}
                                value={this.props.policy.fluxCustomInfo.cultivationLighting?.hasRoutineLampReplacements !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationLighting.hasRoutineLampReplacements) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.hasRoutineLampReplacements`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Are lights inspected on a regular basis and are any that are dim, flickering or not producing light being replaced?`} />}
                                value={this.props.policy.fluxCustomInfo.cultivationLighting?.hasLightInspectionAndDimLightsReplacing !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationLighting.hasLightInspectionAndDimLightsReplacing) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.hasLightInspectionAndDimLightsReplacing`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Are your lights changed by qualified electricians or by employees thoroughly trained on the process?`} />}
                                value={this.props.policy.fluxCustomInfo.cultivationLighting?.hasQualifiedLightsChangers !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationLighting.hasQualifiedLightsChangers) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.hasQualifiedLightsChangers`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Do you use any lamp that has been damaged or scratched?`} />}
                                value={this.props.policy.fluxCustomInfo.cultivationLighting?.hasDamagedLampsInUse !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationLighting.hasDamagedLampsInUse) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationLighting.hasDamagedLampsInUse`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                        </Form>
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default ProfileFluxGlCultivationLighting;