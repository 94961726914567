import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Form, FormChecklist,
    FormInput, FormSwitch,
} from 'metrix-common';

class ProfileInterwestPlAreaOfPractice extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        const validateForm = () =>
            (parseInt(this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopBookkeepingWriteupsPayrollPercent ?? 0)) +
            (parseInt(this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopBusinessTaxPercent ?? 0)) +
            (parseInt(this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopBusinessValuationsPercent ?? 0)) +
            (parseInt(this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopCompilationsPercent ?? 0)) +
            (parseInt(this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopEstateTrustTaxPercent ?? 0)) +
            (parseInt(this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopExecutorAdministratorTrusteePercent ?? 0)) +
            (parseInt(this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopForecastsProjectionsPercent ?? 0)) +
            (parseInt(this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopForensicLitigationInvestigativePercent ?? 0)) +
            (parseInt(this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopHardwareSoftwareConsultingSalesPercent ?? 0)) +
            (parseInt(this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopIndividualTaxPercent ?? 0)) +
            (parseInt(this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopLifeHealthInsuranceAgentPercent ?? 0)) +
            (parseInt(this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopLimitedPartnershipTaxShelterSyndicationPercent ?? 0)) +
            (parseInt(this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopManagementAdvisoryServicesPercent ?? 0)) +
            (parseInt(this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopMergersAcquisitionsPercent ?? 0)) +
            (parseInt(this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopNonFinancialStatementAuditPercent ?? 0)) +
            (parseInt(this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopPersonalFinancialPlanningInvestmentAdvisoryServicesPercent ?? 0)) +
            (parseInt(this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopPrivateEntityAuditPercent ?? 0)) +
            (parseInt(this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopPubliclyTradedEntityAuditPercent ?? 0)) +
            (parseInt(this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopRegisteredRepresentativePercent ?? 0)) +
            (parseInt(this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopReviewsPercent ?? 0)) +
            (parseInt(this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopSecuritiesPercent ?? 0)) +
            (parseInt(this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopOtherPercent ?? 0)) === 100;


        return (
            <>
                <Form
                    label={<FormattedMessage id='Other Services' />}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormInput
                        label={<FormattedMessage id='Please describe the Other area of Practice' />}
                        value={this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopOtherDescription}
                        type='text'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.areaOfPracticeInfo.aopOtherDescription', value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id='Please indicate if you have Engagement Letters on all matters for the other area of practice within the last 12 months.' />}
                        value={this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.hasOtherEngagementLetters ?? false}
                        changeEvent={
                            value => {
                                this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.areaOfPracticeInfo.hasOtherEngagementLetters', value: value });
                            }}
                        allowEdit
                    />
                </Form>
                {
                    this.props.policy.interWestPLCustomInfo.businessInfo.hasOverOneHundredRevenue &&
                    <Form
                        header={<FormattedMessage id='Area of Practice' />}
                        label={<FormattedMessage id='% of revenue' />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        validate={validateForm}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormInput
                            label={<FormattedMessage id='Bookkeeping - Write-ups - Payroll' />}
                            value={this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopBookkeepingWriteupsPayrollPercent}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.areaOfPracticeInfo.aopBookkeepingWriteupsPayrollPercent', value })}
                            maxValue={999}
                            allowEdit
                        />
                        <FormInput
                            label={<FormattedMessage id='Business Tax' />}
                            value={this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopBusinessTaxPercent}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.areaOfPracticeInfo.aopBusinessTaxPercent', value })}
                            maxValue={999}
                            allowEdit
                        />
                        <FormInput
                            label={<FormattedMessage id='Business Valuations' />}
                            value={this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopBusinessValuationsPercent}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.areaOfPracticeInfo.aopBusinessValuationsPercent', value })}
                            maxValue={999}
                            allowEdit
                        />
                        <FormInput
                            label={<FormattedMessage id='Compilations' />}
                            value={this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopCompilationsPercent}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.areaOfPracticeInfo.aopCompilationsPercent', value })}
                            maxValue={999}
                            allowEdit
                        />
                        <FormInput
                            label={<FormattedMessage id='Estate and Trust Tax' />}
                            value={this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopEstateTrustTaxPercent}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.areaOfPracticeInfo.aopEstateTrustTaxPercent', value })}
                            maxValue={999}
                            allowEdit
                        />
                        <FormInput
                            label={<FormattedMessage id='Executor - Administrator - Trustee' />}
                            value={this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopExecutorAdministratorTrusteePercent}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.areaOfPracticeInfo.aopExecutorAdministratorTrusteePercent', value })}
                            maxValue={999}
                            allowEdit
                        />
                        <FormInput
                            label={<FormattedMessage id='Forecasts and Projections' />}
                            value={this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopForecastsProjectionsPercent}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.areaOfPracticeInfo.aopForecastsProjectionsPercent', value })}
                            maxValue={999}
                            allowEdit
                        />
                        <FormInput
                            label={<FormattedMessage id='Forensic Accounting - Litigation Support - Investigative Accounting' />}
                            value={this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopForensicLitigationInvestigativePercent}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.areaOfPracticeInfo.aopForensicLitigationInvestigativePercent', value })}
                            maxValue={999}
                            allowEdit
                        />
                        <FormInput
                            label={<FormattedMessage id='Hardware - Software Consulting and Sales' />}
                            value={this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopHardwareSoftwareConsultingSalesPercent}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.areaOfPracticeInfo.aopHardwareSoftwareConsultingSalesPercent', value })}
                            maxValue={999}
                            allowEdit
                        />
                        <FormInput
                            label={<FormattedMessage id='Individual Tax' />}
                            value={this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopIndividualTaxPercent}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.areaOfPracticeInfo.aopIndividualTaxPercent', value })}
                            maxValue={999}
                            allowEdit
                        />
                        <FormInput
                            label={<FormattedMessage id='Life and Health Insurance Agent' />}
                            value={this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopLifeHealthInsuranceAgentPercent}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.areaOfPracticeInfo.aopLifeHealthInsuranceAgentPercent', value })}
                            maxValue={999}
                            allowEdit
                        />
                        <FormInput
                            label={<FormattedMessage id='Limited Partnership & Tax Shelter Syndication' />}
                            value={this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopLimitedPartnershipTaxShelterSyndicationPercent}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.areaOfPracticeInfo.aopLimitedPartnershipTaxShelterSyndicationPercent', value })}
                            maxValue={999}
                            allowEdit
                        />
                        <FormInput
                            label={<FormattedMessage id='Management Advisory Services' />}
                            value={this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopManagementAdvisoryServicesPercent}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.areaOfPracticeInfo.aopManagementAdvisoryServicesPercent', value })}
                            maxValue={999}
                            allowEdit
                        />
                        <FormInput
                            label={<FormattedMessage id='Mergers and Acquisitions' />}
                            value={this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopMergersAcquisitionsPercent}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.areaOfPracticeInfo.aopMergersAcquisitionsPercent', value })}
                            maxValue={999}
                            allowEdit
                        />
                        <FormInput
                            label={<FormattedMessage id='Non-Financial Statement Audit' />}
                            value={this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopNonFinancialStatementAuditPercent}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.areaOfPracticeInfo.aopNonFinancialStatementAuditPercent', value })}
                            maxValue={999}
                            allowEdit
                        />
                        <FormInput
                            label={<FormattedMessage id='Personal Financial Planning or Investment Advisory Services' />}
                            value={this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopPersonalFinancialPlanningInvestmentAdvisoryServicesPercent}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.areaOfPracticeInfo.aopPersonalFinancialPlanningInvestmentAdvisoryServicesPercent', value })}
                            maxValue={999}
                            allowEdit
                        />
                        <FormInput
                            label={<FormattedMessage id='Private Entity Audit' />}
                            value={this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopPrivateEntityAuditPercent}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.areaOfPracticeInfo.aopPrivateEntityAuditPercent', value })}
                            maxValue={999}
                            allowEdit
                        />
                        <FormInput
                            label={<FormattedMessage id='Publicly Traded Entity Audit' />}
                            value={this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopPubliclyTradedEntityAuditPercent}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.areaOfPracticeInfo.aopPubliclyTradedEntityAuditPercent', value })}
                            maxValue={999}
                            allowEdit
                        />
                        <FormInput
                            label={<FormattedMessage id='Registered Representative' />}
                            value={this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopRegisteredRepresentativePercent}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.areaOfPracticeInfo.aopRegisteredRepresentativePercent', value })}
                            maxValue={999}
                            allowEdit
                        />
                        <FormInput
                            label={<FormattedMessage id='Reviews' />}
                            value={this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopReviewsPercent}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.areaOfPracticeInfo.aopReviewsPercent', value })}
                            maxValue={999}
                            allowEdit
                        />
                        <FormInput
                            label={<FormattedMessage id='Securities' />}
                            value={this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopSecuritiesPercent}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.areaOfPracticeInfo.aopSecuritiesPercent', value })}
                            maxValue={999}
                            allowEdit
                        />
                        <FormInput
                            label={<FormattedMessage id='Other' />}
                            value={this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopOtherPercent}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.areaOfPracticeInfo.aopOtherPercent', value })}
                            maxValue={999}
                            allowEdit
                        />
                    </Form>
                }
                {
                    this.props.policy.interWestPLCustomInfo.businessInfo.hasOverOneHundredRevenue &&
                    <Form
                        label={<FormattedMessage id='Over 100K Revenuee' />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        {
                            parseInt(this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopLifeHealthInsuranceAgentPercent) > 0 &&
                            this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.hasOtherEngagementLetters &&
                            <FormSwitch
                                label={<FormattedMessage id='Did you want a quote for coverage for Life and Health Insruance Agents?' />}
                                value={this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.requiresQuoteForCoverageLifeAndHealthInsuranceAgent ?? false}
                                changeEvent={
                                    value => {
                                        this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.areaOfPracticeInfo.requiresQuoteForCoverageLifeAndHealthInsuranceAgent', value: value });
                                    }}
                                allowEdit
                            />
                        }
                        {
                            parseInt(this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.aopRegisteredRepresentativePercent) > 0 &&
                            this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.hasOtherEngagementLetters &&
                            <FormSwitch
                                label={<FormattedMessage id='Did you want a quote for coverage for Registered Representitives?' />}
                                value={this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.requiresQuoteForCoverageRegisteredRepresentitive ?? false}
                                changeEvent={
                                    value => {
                                        this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.areaOfPracticeInfo.requiresQuoteForCoverageRegisteredRepresentitive', value: value });
                                    }}
                                allowEdit
                            />
                        }
                        <FormChecklist
                            label={<FormattedMessage id='Please indicate if you have engagement letters on all matters' />}
                            value={this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.engagementLetterOptionsSelectedItems}
                            references={this.props.reference.references['InterWest-PL'] && this.props.reference.references['InterWest-PL'].EngagementLetterOptions}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-PL.EngagementLetterOptions')}
                            changeEvent={values => {
                                this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.areaOfPracticeInfo.engagementLetterOptionsSelectedItems', value: values });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {
                    !this.props.policy.interWestPLCustomInfo.businessInfo.hasOverOneHundredRevenue &&
                    <Form
                        label={<FormattedMessage id='Under 100K Revenue' />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormChecklist
                            label={<FormattedMessage id='Please indicate all areas of practice from which your firm derives revenue' />}
                            value={this.props.policy.interWestPLCustomInfo.areaOfPracticeInfo.ltoAreasOfPracticeSelectedItems}
                            references={this.props.reference.references['InterWest-PL'] && this.props.reference.references['InterWest-PL'].LTOAreasOfPractice}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-PL.LTOAreasOfPractice')}
                            changeEvent={values => {
                                this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.areaOfPracticeInfo.ltoAreasOfPracticeSelectedItems', value: values });
                            }}
                            allowEdit
                        />
                    </Form>
                }
            </>
        );
    }
}

export default ProfileInterwestPlAreaOfPractice;