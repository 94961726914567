import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Form, FormChecklist, Label
} from 'metrix-common';

class ProfileInterwestPlLossPrevention extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            this.props.policy.interWestPLCustomInfo.businessInfo.hasOverOneHundredRevenue
            ?
            <>
                <Form
                    header={<FormattedMessage id='Loss Prevention Risk Management'/>}
                    label={<FormattedMessage id='Client Screening Procedures and Suits for Fees'/>}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormChecklist
                        label={<FormattedMessage
                            id='Check all loss prevention and risk management controls below that are in place at the firm'/>}
                        value={this.props.policy.interWestPLCustomInfo.lossRiskInfo.clientScreeningProceduresAndSuitsForFeesSelectedItems}
                        references={this.props.reference.references['InterWest-PL'] && this.props.reference.references['InterWest-PL'].ClientScreeningProceduresAndSuitsForF}
                        referencesEvent={() => this.props.reference.doGetReferences('InterWest-PL.ClientScreeningProceduresAndSuitsForF')}
                        changeEvent={values => {
                            this.props.doReducePolicyElement({
                                code: 'interWestPLCustomInfo.lossRiskInfo.clientScreeningProceduresAndSuitsForFeesSelectedItems',
                                value: values
                            });
                        }}
                        allowEdit
                    />
                </Form>
                <Form
                    label={<FormattedMessage id='Control Systems and Conflict of Interest Procedures'/>}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormChecklist
                        label={<FormattedMessage
                            id='Check all loss prevention and risk management controls below that are in place at the firm'/>}
                        value={this.props.policy.interWestPLCustomInfo.lossRiskInfo.controlSystemsAndConflictOfInterestProceduresSelectedItems}
                        references={this.props.reference.references['InterWest-PL'] && this.props.reference.references['InterWest-PL'].ControlSystemsAndConflictOfInterestPr}
                        referencesEvent={() => this.props.reference.doGetReferences('InterWest-PL.ControlSystemsAndConflictOfInterestPr')}
                        changeEvent={values => {
                            this.props.doReducePolicyElement({
                                code: 'interWestPLCustomInfo.lossRiskInfo.controlSystemsAndConflictOfInterestProceduresSelectedItems',
                                value: values
                            });
                        }}
                        allowEdit
                    />
                </Form>
                <Form
                    label={<FormattedMessage id='Engagement Letters'/>}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormChecklist
                        label={<FormattedMessage
                            id='Check all loss prevention and risk management controls below that are in place at the firm'/>}
                        value={this.props.policy.interWestPLCustomInfo.lossRiskInfo.engagementLettersSelectedItems}
                        references={this.props.reference.references['InterWest-PL'] && this.props.reference.references['InterWest-PL'].EngagementLetters}
                        referencesEvent={() => this.props.reference.doGetReferences('InterWest-PL.EngagementLetters')}
                        changeEvent={values => {
                            this.props.doReducePolicyElement({
                                code: 'interWestPLCustomInfo.lossRiskInfo.engagementLettersSelectedItems',
                                value: values
                            });
                        }}
                        allowEdit
                    />
                </Form>
                <Form
                    label={<FormattedMessage id='Firm Loss Prevention Procedures'/>}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormChecklist
                        label={<FormattedMessage
                            id='Check all loss prevention and risk management controls below that are in place at the firm'/>}
                        value={this.props.policy.interWestPLCustomInfo.lossRiskInfo.firmLossPreventionProceduresIncludeSelectedItems}
                        references={this.props.reference.references['InterWest-PL'] && this.props.reference.references['InterWest-PL'].FirmLossPreventionProceduresInclude}
                        referencesEvent={() => this.props.reference.doGetReferences('InterWest-PL.FirmLossPreventionProceduresInclude')}
                        changeEvent={values => {
                            this.props.doReducePolicyElement({
                                code: 'interWestPLCustomInfo.lossRiskInfo.firmLossPreventionProceduresIncludeSelectedItems',
                                value: values
                            });
                        }}
                        allowEdit
                    />
                </Form>
                <Form
                    label={<FormattedMessage id='Firm Oversight'/>}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormChecklist
                        label={<FormattedMessage
                            id='Check all loss prevention and risk management controls below that are in place at the firm'/>}
                        value={this.props.policy.interWestPLCustomInfo.lossRiskInfo.firmOversightIncludesSelectedItems}
                        references={this.props.reference.references['InterWest-PL'] && this.props.reference.references['InterWest-PL'].FirmOversightIncludes}
                        referencesEvent={() => this.props.reference.doGetReferences('InterWest-PL.FirmOversightIncludes')}
                        changeEvent={values => {
                            this.props.doReducePolicyElement({
                                code: 'interWestPLCustomInfo.lossRiskInfo.firmOversightIncludesSelectedItems',
                                value: values
                            });
                        }}
                        allowEdit
                    />
                </Form>
            </>
            :
            <Label text='There is nothing to show for now'/>
        );
    }
}

export default ProfileInterwestPlLossPrevention;