import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    Alignments,
    Grid,
    Row,
    Column,
    Container,
    Label,
    Separator
} from 'metrix-common';

import PolicyListItem from './PolicyListItem';

class Results extends Component {
    render() {

        let policies = this.props.incompletePolicies;

        return (
            <Container>
                <Grid>
                    <Row>
                        {
                            !this.context.screen.isMobile &&

                            <Column md={this.context.screen.isTablet ? 2 : 1}>
                                <Label text={`Reference Number`} textAlign={Alignments.left} />
                            </Column>
                        }
                        {
                            this.context.screen.isDesktop &&
                            <Column md={1}>
                                <Label text={`Policy Number`} textAlign={Alignments.left} />
                            </Column>
                        }
                        {
                            this.context.screen.isMobile &&

                            <Column xs={5} md={2}>
                                    <Label text={`Doing Business As`} textAlign={Alignments.left} />
                            </Column>
                        }
                        {
                            !this.context.screen.isMobile &&
                            <>
                                <Column md={this.context.screen.isTablet ? 2 : 1}>
                                    <Label text={`Name`} textAlign={Alignments.left} />
                                </Column>
                                <Column md={2}>
                                    <Label text={`Doing Business As`} textAlign={Alignments.left} />
                                </Column>
                            </>
                        }
                        {
                            !this.context.screen.isMobile &&
                            <React.Fragment>
                                <Column md={this.context.screen.isTablet ? 4 : 2}>
                                    <Label text={`Product`} textAlign={Alignments.left} />
                                </Column>

                                <Column md={1}>
                                        <Label text={`Creation Date`} textAlign={Alignments.left} />
                                </Column>
                            </React.Fragment>
                        }
                        {
                            this.context.screen.isDesktop &&
                                <Column lg={2}>
                                    <Label text={`Assigned To`} textAlign={Alignments.left} />
                                </Column>
                        }
                        <Column xs={2} md={1} lg={1}>
                            <Label text={`Type`} textAlign={Alignments.left} />
                        </Column>
                    </Row>
                    <Row noguttertop>
                        <Column>
                            <Separator solid />
                        </Column>
                    </Row>
                </Grid>
                <Grid>
                    {policies && policies.map(
                        (policy, index) => {
                            return (
                                <PolicyListItem
                                    policy={policy}
                                    history={this.props.history}
                                    context={this.props.context}
                                />
                            );
                        }
                    )}
                </Grid>
            </Container>
        );
    }
}

Results.contextTypes = {
    screen: PropTypes.object
};

export default Results;