import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Form,
    FormDropDown
} from 'metrix-common';

class ProfileEmployersWcCoverage extends React.Component {
    componentDidMount() {
        if (this.props.policy.employersWcCustomInfo.coverage?.compositeLiabilityLimitsCode) {
            this.props.reference.doGetReferences(`EMPLOYERS-WC.EmployerLiabilityLimits.${this.props.policy.employersWcCustomInfo.coverage?.compositeLiabilityLimitsCode}`);
        }

        const pageHasTooltipInfo = this.props.tooltipInfo && this.props.tooltipInfo.pages && this.props.tooltipInfo.pages.length && this.props.tooltipInfo.pages.some(p => p.code === this.props.pageCode);
        if (!pageHasTooltipInfo) {
            this.props.doGetTooltipInfo(this.props.policy.product);
        }
    }

    render() {
        return (
            <React.Fragment>
                {                    
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='coverage'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id={'Coverage'} />}
                        label={'Details'}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        {this.props.reference?.references['EMPLOYERS-WC'] && this.props.reference.references['EMPLOYERS-WC']['EmployerLiabilityLimits'] && this.props.reference.references['EMPLOYERS-WC']['EmployerLiabilityLimits'][this.props.policy.employersWcCustomInfo.coverage?.compositeLiabilityLimitsCode] ?
                            <FormDropDown
                                fieldCode='employerLiabilityLimitCode'
                                label={<FormattedMessage id={'Please select the limits you wish to apply for (Accident/Policy/Employee)'} />}
                                value={{ code: this.props.policy.employersWcCustomInfo?.coverage?.employerLiabilityLimitCode, value: this.props.policy.employersWcCustomInfo?.coverage?.employerLiabilityLimitValue }}
                                references={this.props.reference.references['EMPLOYERS-WC']['EmployerLiabilityLimits'][this.props.policy.employersWcCustomInfo.coverage?.compositeLiabilityLimitsCode]}
                                referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.EmployerLiabilityLimits.${this.props.policy.employersWcCustomInfo.coverage?.compositeLiabilityLimitsCode}`)}
                                changeEvent={(value) => {
                                    this.props.doReducePolicyElement({ code: 'employersWcCustomInfo.coverage.employerLiabilityLimitCode', value: value.code });
                                    this.props.doReducePolicyElement({ code: 'employersWcCustomInfo.coverage.employerLiabilityLimitValue', value: value.value });
                                }}
                                allowEdit
                            />
                            :
                            <FormDropDown
                                fieldCode='employerLiabilityLimitCode'
                                label={<FormattedMessage id={'Please select the limits you wish to apply for (Accident/Policy/Employee)'} />}
                                value={{ code: this.props.policy.employersWcCustomInfo?.coverage?.employerLiabilityLimitCode, value: this.props.policy.employersWcCustomInfo?.coverage?.employerLiabilityLimitValue }}
                                references={this.props.reference.references['EMPLOYERS-WC'] && this.props.reference.references['EMPLOYERS-WC']['EmployerLiabilityLimits'] && this.props.reference.references['EMPLOYERS-WC']['EmployerLiabilityLimits']['OTHER']}
                                referencesEvent={() => this.props.reference.doGetReferences('EMPLOYERS-WC.EmployerLiabilityLimits.OTHER')}
                                changeEvent={(value) => {
                                    this.props.doReducePolicyElement({ code: 'employersWcCustomInfo.coverage.employerLiabilityLimitCode', value: value.code });
                                    this.props.doReducePolicyElement({ code: 'employersWcCustomInfo.coverage.employerLiabilityLimitValue', value: value.value });
                                }}
                                allowEdit
                            />
                        }
                    </Form>
                }                                
            </React.Fragment>
        );
    }
}

export default ProfileEmployersWcCoverage;