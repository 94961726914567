const fetchData = async (e, session, payload) => {
    if (e) {
        e.preventDefault();
    }
    try {
        const response = await fetch(`/api/route?siteCode=${payload.siteCode}&path=${payload.path}&entity=${payload.entity}`, {
            headers: {
                'Authorization': `Bearer ${session.authenticationToken.token}`
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.text();

        return data;
    } catch (error) {
        console.error('Error:', error);

        throw error;
    }
};

const applyURI = (e, uri, isBlankPage) => {
    if (e) {
        e.preventDefault();
    }

    if (isBlankPage) {
        window.open(uri, '_blank');
    } else {
        window.location = uri; 
    }
};

const getAndUseRoute = async (e, session, payload) => {
    if (e) {
        e.preventDefault();
    }

    const uri = await fetchData(e, session, payload);

    applyURI(e, uri, payload.isBlankPage);
};

export { fetchData, applyURI, getAndUseRoute };
