import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Form, FormDate,
    FormInput, FormSwitch
} from 'metrix-common';

class ProfileInterwestPlPriorInsurance extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        const carrierPolicyToDate = () => {
            if (this.props.policy.interWestPLCustomInfo.priorInsuranceInfo.hasFirmProfessionalLiabilityInsurance && !this.props.policy.interWestPLCustomInfo.priorInsuranceInfo.carrierPolicyToDate) {
                const carrierPolicyFromDate = this.props.policy.interWestPLCustomInfo.priorInsuranceInfo.carrierPolicyFromDate;
                const date = new Date(carrierPolicyFromDate);
                date.setFullYear(date.getFullYear() + 1);
                return date.toUTCString();
            } else if (this.props.policy.interWestPLCustomInfo.priorInsuranceInfo.carrierPolicyToDate) {
                return this.props.policy.interWestPLCustomInfo.priorInsuranceInfo.carrierPolicyToDate;
            }
        }

        return (
            <>
                <Form
                    header={<FormattedMessage id='Prior Insurance' />}
                    label={<FormattedMessage id='Cancelation' />}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    {
                        !(this.props.policy.participants.insured.party.address.stateCode === 'MO') &&
                        <FormSwitch
                            label={<FormattedMessage id='Has your coverage ever been cancelled?' />}
                            value={this.props.policy.interWestPLCustomInfo.priorInsuranceInfo.hasCoverageBeenCancelled ?? false}
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestPLCustomInfo.priorInsuranceInfo.hasCoverageBeenCancelled',
                                        value: value
                                    });
                                }}
                            allowEdit
                        />
                    }
                    {
                        this.props.policy.interWestPLCustomInfo.priorInsuranceInfo.hasCoverageBeenCancelled &&
                        <FormInput
                            label={<FormattedMessage id='Please provide details on the reason for the cancellation' />}
                            value={this.props.policy.interWestPLCustomInfo.priorInsuranceInfo.additionalInformationThree}
                            type='text'
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.priorInsuranceInfo.additionalInformationThree', value: value });
                                }}
                            allowEdit
                        />
                    }
                </Form>
                <Form
                    label={<FormattedMessage id='Current Insurance' />}
                    saveEvent={() => {
                        this.props.doUpsertPolicy()
                    }}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormSwitch
                        label={<FormattedMessage id='Does your firm currently carry professional liability insurance?' />}
                        value={this.props.policy.interWestPLCustomInfo.priorInsuranceInfo.hasFirmProfessionalLiabilityInsurance ?? false}
                        changeEvent={
                            value => {
                                this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.priorInsuranceInfo.hasFirmProfessionalLiabilityInsurance', value: value });
                            }}
                        allowEdit
                    />
                    {
                        this.props.policy.interWestPLCustomInfo.priorInsuranceInfo.hasFirmProfessionalLiabilityInsurance &&
                        <FormInput
                            label={<FormattedMessage id='Current Carrier Name' />}
                            value={this.props.policy.interWestPLCustomInfo.priorInsuranceInfo.currentCarrierName}
                            type='text'
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestPLCustomInfo.priorInsuranceInfo.currentCarrierName',
                                        value: value
                                    });
                                }}
                            allowEdit
                        />
                    }
                    {
                        this.props.policy.interWestPLCustomInfo.priorInsuranceInfo.hasFirmProfessionalLiabilityInsurance &&
                        <FormDate
                            label={<FormattedMessage id='From Date' />}
                            value={this.props.policy.interWestPLCustomInfo.priorInsuranceInfo.carrierPolicyFromDate}
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.priorInsuranceInfo.carrierPolicyFromDate', value })}
                            culture={this.props.context.session.culture}
                            mode='past'
                            allowEdit
                        />
                    }
                    {
                        this.props.policy.interWestPLCustomInfo.priorInsuranceInfo.hasFirmProfessionalLiabilityInsurance &&
                        <FormDate
                            label={<FormattedMessage id='To Date' />}
                            value={carrierPolicyToDate()}
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.priorInsuranceInfo.carrierPolicyToDate', value })}
                            culture={this.props.context.session.culture}
                            allowEdit
                        />
                    }
                    {
                        this.props.policy.interWestPLCustomInfo.priorInsuranceInfo.hasFirmProfessionalLiabilityInsurance &&
                        <FormInput
                            label={<FormattedMessage id='Limits' />}
                            value={this.props.policy.interWestPLCustomInfo.priorInsuranceInfo.carrierPolicyLimitsAmount}
                            type='number'
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.priorInsuranceInfo.carrierPolicyLimitsAmount', value: value });
                                }}
                            allowEdit
                        />
                    }
                    {
                        this.props.policy.interWestPLCustomInfo.priorInsuranceInfo.hasFirmProfessionalLiabilityInsurance &&
                        <FormInput
                            label={<FormattedMessage id='Deductible' />}
                            value={this.props.policy.interWestPLCustomInfo.priorInsuranceInfo.carrierPolicyDeductableAmount}
                            type='number'
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.priorInsuranceInfo.carrierPolicyDeductableAmount', value: value });
                                }}
                            allowEdit
                        />
                    }
                    {
                        this.props.policy.interWestPLCustomInfo.priorInsuranceInfo.hasFirmProfessionalLiabilityInsurance &&
                        <FormInput
                            label={<FormattedMessage id='Premium' />}
                            value={this.props.policy.interWestPLCustomInfo.priorInsuranceInfo.carrierPolicyPremiumAmount}
                            type='number'
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.priorInsuranceInfo.carrierPolicyPremiumAmount', value: value });
                                }}
                            allowEdit
                        />
                    }
                    {
                        this.props.policy.interWestPLCustomInfo.priorInsuranceInfo.hasFirmProfessionalLiabilityInsurance &&
                        <FormDate
                            label={<FormattedMessage id='Retroactive Date' />}
                            value={this.props.policy.interWestPLCustomInfo.priorInsuranceInfo.carrierPolicyRetroactiveDate}
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.priorInsuranceInfo.carrierPolicyRetroactiveDate', value })}
                            culture={this.props.context.session.culture}
                            allowEdit
                        />
                    }
                </Form>
                {
                    this.props.policy.interWestPLCustomInfo.businessInfo.hasOverOneHundredRevenue &&
                    <Form
                        label={<FormattedMessage id='General' />}
                        saveEvent={() => {
                            this.props.doUpsertPolicy()
                        }}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormSwitch
                            label={<FormattedMessage id='Are you renewing your policy?' />}
                            value={this.props.policy.interWestPLCustomInfo.priorInsuranceInfo.isRenewingPolicy ?? false}
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestPLCustomInfo.priorInsuranceInfo.isRenewingPolicy',
                                        value: value
                                    });
                                }}
                            allowEdit
                        />
                    </Form>
                }
            </>
        );
    }
}

export default ProfileInterwestPlPriorInsurance;