import "core-js/stable";
import "regenerator-runtime/runtime";

import 'typeface-poppins';
import 'typeface-roboto'; 

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import { unregisterServiceWorker } from './registerServiceWorker';

import {
    ContextProvider,
    ReferenceProvider    
} from 'metrix-common';

import App from './containers/App';

import enMessages from './messages/en.json';
import frMessages from './messages/fr.json';

const initialState = window.initialReduxState;

const store = configureStore(initialState);

const rootElement = document.getElementById('app');

ReactDOM.render(
    <Provider store={store}>
        <ContextProvider messages={{ en: enMessages, fr: frMessages }}>            
            <ReferenceProvider>
                <App />
            </ReferenceProvider>            
        </ContextProvider>
    </Provider>,
    rootElement);

unregisterServiceWorker();