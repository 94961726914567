import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    AddressForm,
    Alignments,
    BackButton,
    Form,
    FormInput,
    Label,
    FormTextArea,
} from 'metrix-common';

class ProfileSupernovaCommercialLossPayee extends React.Component {
    state = {
        mode: 'lossPayeeView',
        current: null
    };

    changeAddress = (dotProp, value) => {

        if (dotProp === `address`) {
            this.props.doReducePolicyElement({ code: `${this.props.dotProp}address`, value })
        } else {
            this.props.doReducePolicyElement({ code: `${this.props.dotProp}address.${dotProp}`, value })
        }
    }

    saveAddressHandler = () => {
        this.props.doUpsertPolicy();
    }

    render() {
        return (
            this.state.mode === 'lossPayeeView' &&
            <React.Fragment>
                <BackButton clickEvent={this.props.backEvent} />
                <Form
                    rowId={this.props.rowId}
                    pageCode={this.props.pageCode}
                    sectionCode='lossPayeesDetails'
                    saveTooltipInfo={this.props.saveTooltipInfo}
                    reduceTooltipInfoElement={this.props.reduceTooltipInfoElement}
                    tooltipInfo={this.props.tooltipInfo}
                    validationInfo={this.props.validationInfo}
                    header={this.props.lossPayee?.label}
                    label={'Loss Payee details'}
                    allowEdit={this.props.allowEdit}
                    saveEvent={() => this.props.doUpsertPolicy()}
                    cancelEvent={() => this.props.doGetPolicy()}
                >
                    <FormInput
                        fieldCode='legalBusinessName'
                        label={'Business Name'}
                        value={this.props.lossPayee?.legalBusinessName}
                        type='text'
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}legalBusinessName`, value })}
                        allowEdit
                    />
                        <Label bold text={<FormattedMessage id={'With respect to (specify equipment, contents, CEF, etc.)'} />} textAlign={Alignments.left} />
                    <FormTextArea
                        fieldCode='note'
                        text={this.props.lossPayee?.note}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}note`, value })}
                        allowEdit
                    />
                </Form>
                <AddressForm
                    {...this.props}
                    enableAddressSearch={ true }
                    pageCode={this.props.pageCode}
                    sectionCode='lossPayeeAddressDetails'
                    saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                    reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                    tooltipInfo={this.props.tooltipInfo}
                    validationInfo={this.props.validationInfo}
                    addressSearchCountryCode={'CAN'}
                    header={<FormattedMessage id='Address Details' />}
                    label='Location Details'
                    saveEvent={this.saveAddressHandler}
                    cancelEvent={this.props.doGetPolicy}
                    reference={this.props.reference}
                    changeEvent={(dotProp, value) => this.changeAddress(dotProp, value)}
                    allowEdit={this.props.allowEdit}
                    address={this.props.lossPayee && this.props.lossPayee.address}
                />
            </React.Fragment>
        );
    }
}

export default ProfileSupernovaCommercialLossPayee;