import React from 'react';
import PropTypes from 'prop-types';

import {
    Alignments,
    Grid,
    Row,
    Column,
    Label,
    Link, isMobile, getScreenSize, isTablet, isDesktop
} from 'metrix-common';

const DemoPolicyWeb = ({ policy, context }) => {
    const { 
        policyDescription, 
        insuredName, 
        assignedTo, 
        submissionReferenceNumber, 
        policyNumber, 
        doingBusinessAs, 
        productDescription, 
        createdOnDate, 
        transactionStatus, 
        transactionType, 
        policyTransactionGuid,
        quoteNumber,
        quoteColorTag
    } = policy

    const mobile = isMobile(getScreenSize());
    const tablet = isTablet(getScreenSize());
    const desktop = isDesktop(getScreenSize());

    const profileLink = `${context.configuration.policyWeb}/profile?entity=${policyTransactionGuid}`;

    let description = policyDescription || '';
    if (insuredName) {
        description = `${insuredName} - ${description}`;
    }

    return (
        <Grid>
            <Row>
                {
                    !mobile &&
                    <Column md={tablet ? 2 : 1}>
                        <Link text={submissionReferenceNumber} textAlign={Alignments.left} href={profileLink} />
                    </Column>
                }
                {
                    desktop &&
                    <Column md={1}>
                        <Label text={policyNumber} textAlign={Alignments.left} />
                    </Column>
                }
                {
                    desktop &&
                    <Column md={1}>
                        <Label text={quoteNumber} textAlign={Alignments.left} />
                    </Column>
                }
                {
                    mobile &&
                    <Column xs={5}>
                        <Label text={description} textAlign={Alignments.left} />
                        <Link text={submissionReferenceNumber} href={profileLink} textAlign={Alignments.left} />
                    </Column>
                }
                {
                    !mobile &&
                    <>
                        <Column md={tablet ? 2 : 1}>
                            <Label text={insuredName} textAlign={Alignments.left} />
                        </Column>
                        <Column md={2}>
                            <Label text={doingBusinessAs} textAlign={Alignments.left} />
                        </Column>
                    </>
                }
                {
                    !mobile &&
                    <>
                        <Column md={tablet ? 2 : 1}>
                            <Label text={productDescription} textAlign={Alignments.left} />
                        </Column>
                        <Column md={1}>
                            <Label text={createdOnDate} textAlign={Alignments.left} />
                        </Column>
                    </>
                }
                <Column xs={4} md={tablet ? 2 : 1}>
                    <Label text={transactionStatus} textAlign={Alignments.left} />
                </Column>
                {
                    desktop &&
                    <Column md={1}>
                        <Label text={assignedTo || ''} textAlign={Alignments.left} />
                    </Column>
                }
                {
                    desktop &&
                    <Column md={1}>
                        <Label text={quoteColorTag} textAlign={Alignments.left} />
                    </Column>
                }
                <Column xs={2} md={1} lg={1}>
                    <Label text={transactionType} textAlign={Alignments.left} />
                </Column>
            </Row>
        </Grid>
    );
};

DemoPolicyWeb.propTypes = {
    policy: PropTypes.object.isRequired,
    context: PropTypes.shape({
        configuration: PropTypes.shape({
            policyWeb: PropTypes.string.isRequired
        }).isRequired
    }).isRequired
};

export default DemoPolicyWeb;