import { all, takeLatest, fork } from 'redux-saga/effects';

import {
    GET_LIST_POLICIES,
    GET_TRIAGE_POLICIES,
    QUERY_POLICIES,
    QUERY_TRIAGE_POLICIES,
    EXECUTE_ASSIGNMENT_ACTION,
    QUERY_BOUND_POLICIES,
    GET_BOUND_POLICIES,
    GET_BOARD_POLICIES
} from '../../constants/actions/policies';

import { getBoundPoliciesSaga } from './getBoundPoliciesSaga';
import { getListPoliciesSaga } from './getListPoliciesSaga';
import { queryPoliciesSaga } from './queryPoliciesSaga';
import { getTriagePoliciesSaga } from './getTriagePoliciesSaga';
import { queryTriagePoliciesSaga } from './queryTriagePoliciesSaga';
import { executeAssignmentActionSaga } from './executeAssignmentActionSaga';
import { queryBoundPoliciesSaga } from './queryBoundPoliciesSaga';
import { getBoardPoliciesSaga } from './getBoardPoliciesSaga';

function* watchGetBoundPolicies() {
    yield takeLatest(GET_BOUND_POLICIES, getBoundPoliciesSaga);
}

function* watchGetListPolicies() {
    yield takeLatest(GET_LIST_POLICIES, getListPoliciesSaga);
}

function* watchQueryPolicies() {
    yield takeLatest(QUERY_POLICIES, queryPoliciesSaga);
}

function* watchGetTriagePolicies() {
    yield takeLatest(GET_TRIAGE_POLICIES, getTriagePoliciesSaga);
}

function* watchQueryTriagePolicies() {
    yield takeLatest(QUERY_TRIAGE_POLICIES, queryTriagePoliciesSaga);
}

function* watchExecuteAssignmentAction() {
    yield takeLatest(EXECUTE_ASSIGNMENT_ACTION, executeAssignmentActionSaga);
}

function* watchQueryBoundPolicies() {
    yield takeLatest(QUERY_BOUND_POLICIES, queryBoundPoliciesSaga);
}

function* watchGetBoardPolicies() {
    yield takeLatest(GET_BOARD_POLICIES, getBoardPoliciesSaga);
}

function* root() {
    return yield all([
        fork(watchGetBoundPolicies),
        fork(watchGetListPolicies),
        fork(watchQueryPolicies),
        fork(watchGetTriagePolicies),
        fork(watchQueryTriagePolicies),
        fork(watchExecuteAssignmentAction),
        fork(watchQueryBoundPolicies),
        fork(watchGetBoardPolicies)
    ]);
}

export default root;