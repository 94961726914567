import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Alignments,
    Form,
    FormTextArea,
    FormSwitch,
    Label
} from 'metrix-common';

class ProfileFluxGlRetailOperation extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            <React.Fragment>
                <Form
                    header={<FormattedMessage id={`Retail Operations`} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormSwitch
                        label={<FormattedMessage id={`Does the applicant require each supplier to have their products tested?`} />}
                        value={this.props.policy.fluxCustomInfo.retailOperation?.isRequireTestedProductFromSupplier}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.retailOperation.isRequireTestedProductFromSupplier`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Does the applicant maintain supplier contracts, records, and invoices for 5 years or more?`} />}
                        value={this.props.policy.fluxCustomInfo.retailOperation?.isMaintainSupplierRecordsAtLeast5Years}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.retailOperation.isMaintainSupplierRecordsAtLeast5Years`, value: value })}
                        allowEdit
                    />
                    {this.props.policy.fluxCustomInfo.retailOperation?.isMaintainSupplierRecordsAtLeast5Years === false &&
                        <Label bold text={<FormattedMessage id={`How long does the applicant maintain records?`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.fluxCustomInfo.retailOperation?.isMaintainSupplierRecordsAtLeast5Years === false &&
                        <FormTextArea
                            text={this.props.policy.fluxCustomInfo.retailOperation?.maintainSupplierRecordsLess5YearsDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.retailOperation.maintainSupplierRecordsLess5YearsDescription`, value })}
                        />
                    }
                </Form>
            </React.Fragment>
        );
    }
}

export default ProfileFluxGlRetailOperation;