import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Form,
    FormInput,
    FormDropDown
} from 'metrix-common';

class ProfileApplicantServicing extends React.Component {
    render() {
        return (
            <Form
                header={<FormattedMessage id={`Servicing Stats`} />}
                label={`Particpant Details`}
                saveEvent={() => this.props.doUpsertPolicy()}
                cancelEvent={() => this.props.doGetPolicy()}
                allowEdit={this.props.allowEdit}
            >
                <FormInput
                    label={<FormattedMessage id={`Insurer`} />}
                    value={this.props.policy &&
                        this.props.policy.participants &&
                        this.props.policy.participants.insurer &&
                        this.props.policy.participants.insurer.commercialName &&
                        this.props.policy.participants.insurer.commercialName.name
                    }
                />
                <FormDropDown
                    label={<FormattedMessage id={`Previous Insurer`} />}
                    value={this.props.policy &&
                        this.props.policy.quote &&
                        this.props.policy.quote.previousTermInsurer &&
                        {
                            code: this.props.policy.quote.previousTermInsurer
                        }
                    }
                    references={this.props.reference.references && this.props.reference.references[`Insurer`]}
                    referencesEvent={() => this.props.reference.doGetReferences(`Insurer`)}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `quote.previousTermInsurer`, value: value.code })}
                    allowEdit
                />
                <FormInput
                    label={<FormattedMessage id={`Broker`} />}
                    value={this.props.policy &&
                        this.props.policy.participants &&
                        this.props.policy.participants.broker &&
                        this.props.policy.participants.broker.party &&
                        this.props.policy.participants.broker.party.name &&
                        `${this.props.policy.participants.broker.party.name.firstName} ${this.props.policy.participants.broker.party.name.lastName}`
                    }
                />
                <FormInput
                    label={<FormattedMessage id={`Partner`} />}
                    value={this.props.policy &&
                        this.props.policy.participants &&
                        this.props.policy.participants.partner &&
                        this.props.policy.participants.partner.commercialName &&
                        this.props.policy.participants.partner.commercialName.name
                    }
                />
                <FormInput
                    label={<FormattedMessage id={`Epic Lookup Code`} />}
                    value={this.props.policy &&
                        this.props.policy.participants &&
                        this.props.policy.participants.insured &&
                        this.props.policy.participants.insured.code
                    }
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `participants.insured.code`, value })}
                    allowEdit
                />
            </Form>
        );
    }
}

export default ProfileApplicantServicing;