import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    convertFromBoolean,
    convertToBoolean,
    getFormattedDate,
    setAttributeEntity,
    Sizes,
    Alignments,
    Container,
    Divider,
    Header,
    BackButton,
    Form,
    NameForm,
    PersonForm,
    FormInput,
    FormRadio,
    FormDropDown,
    FormDate,
    SummaryGroup
} from 'metrix-common';

import ProfileDriverConviction from './Conviction';
import ProfileDriverSuspension from './Suspension';
import ProfileDriverCriminalConviction from './CriminalConviction';
import ProfileDriverClaim from './Claim';
import ProfileDriverCancellationReason from './CancellationReason';

class ProfileDriver extends React.Component {
    state = {
        mode: `driver`,
        current: null
    };

    getDriverConvictions = () => {
        let driverConvictions = [];

        if (this.props.driver &&
            this.props.driver.license &&
            this.props.driver.license.convictions &&
            this.props.driver.license.convictions.length > 0) {
            this.props.driver.license.convictions.forEach((conviction) => {
                let option = { code: conviction.reason && conviction.reason.value, value: conviction.guid };

                let convictionName = `New Conviction`;

                if (conviction.reason && conviction.reason.value) {
                    convictionName = `${conviction.reason.value}`;
                }

                option.elements = [];

                option.elements.push({ code: `Label`, value: convictionName });

                driverConvictions.push(option);
            });
        }

        return driverConvictions;
    }

    getDriverSuspensions = () => {
        let driverSuspensions = [];

        if (this.props.driver &&
            this.props.driver.license &&
            this.props.driver.license.suspensions &&
            this.props.driver.license.suspensions.length > 0) {
            this.props.driver.license.suspensions.forEach((suspension) => {
                let option = { code: `suspension`, value: suspension.guid };

                let suspensionName = `New Suspension`;

                if (suspension.reinstatementDate) {
                    suspensionName = `${getFormattedDate(suspension.reinstatementDate, this.props.context.session.culture)}`;
                }

                option.elements = [];

                option.elements.push({ code: `Label`, value: suspensionName });

                driverSuspensions.push(option);
            });
        }

        return driverSuspensions;
    }

    getDriverCriminalConvictions = () => {
        let driverCriminalConvictions = [];

        if (this.props.driver &&
            this.props.driver.criminalConvictions &&
            this.props.driver.criminalConvictions.length > 0) {
            this.props.driver.criminalConvictions.forEach((criminalConviction) => {
                let option = { code: criminalConviction.reason && criminalConviction.reason.value, value: criminalConviction.guid };

                let criminalConvictionName = `New Criminal Conviction`;

                if (criminalConviction.reason && criminalConviction.reason.value) {
                    criminalConvictionName = `${criminalConviction.reason.value}`;
                }

                option.elements = [];

                option.elements.push({ code: `Label`, value: criminalConvictionName });

                driverCriminalConvictions.push(option);
            });
        }

        return driverCriminalConvictions;
    }

    getDriverClaims = () => {
        let driverClaims = [];

        if (this.props.driver &&
            this.props.driver.claims &&
            this.props.driver.claims.length > 0) {
            this.props.driver.claims.forEach((claim) => {
                let option = { code: claim.reaason && claim.reaason.value, value: claim.guid };

                let claimName = `New Claim`;

                if (claim.reason && claim.reason.value) {
                    claimName = `${claim.reason.value}`;
                }

                option.elements = [];

                option.elements.push({ code: `Label`, value: claimName });

                driverClaims.push(option);
            });
        }

        return driverClaims;
    }

    getDriverCancellationReasons = () => {
        let driverCancellationReasons = [];

        if (this.props.driver &&
            this.props.driver.cancellationReasons &&
            this.props.driver.cancellationReasons.length > 0) {
            this.props.driver.cancellationReasons.forEach((cancellationReason) => {
                let option = { code: cancellationReason.reason && cancellationReason.reason.value, value: cancellationReason.guid };

                let cancellationReasonName = `New Cancellation Reason`;

                if (cancellationReason.reason && cancellationReason.reason.value) {
                    cancellationReasonName = `${cancellationReason.reason.value}`;
                }

                option.elements = [];

                option.elements.push({ code: `Label`, value: cancellationReasonName });

                driverCancellationReasons.push(option);
            });
        }

        return driverCancellationReasons;
    }

    // Function to update child reference as per parent value
    updateChildRef = (childRef, code) => {

        let ref = childRef;

        if (code && ref) {
            ref = ref.filter((x) => x.elements[0].value === code);
        }

        return ref;
    }

    render() {
        let driverName = this.props.getDriverName() || `Driver ${this.props.driver.number}`;
        return (
            <React.Fragment>
                {this.state.mode === `driver` &&
                    <React.Fragment>
                        <BackButton clickEvent={this.props.backEvent} />
                        <Divider top={Sizes.md} />
                        <Header size={Sizes.md} textAlign={Alignments.left} text={driverName} />
                        <NameForm
                            label={`Name`}
                            name={this.props.driver &&
                                this.props.driver &&
                                this.props.driver.party &&
                                this.props.driver.party.name
                            }
                            person={this.props.driver &&
                                this.props.driver.person
                            }
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            reference={this.props.reference}
                            changeEvent={(dotProp, value) => this.props.doReducePolicyElement({ code: (dotProp === `person.gender`) ? `${this.props.dotProp}${dotProp}` : `${this.props.dotProp}party.name.${dotProp}`, value })}
                            allowEdit={this.props.allowEdit}
                        />
                        <PersonForm
                            label={`Person`}
                            person={this.props.driver &&
                                this.props.driver.person
                            }
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            reference={this.props.reference}
                            changeEvent={(dotProp, value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}person.${dotProp}`, value })}
                            allowEdit
                            culture={this.props.context.session.culture}
                        />
                        <Form
                            label={`Driver Details`}
                            allowEdit={this.props.allowEdit}
                            saveEvent={() => this.props.doUpsertPolicy()}
                            cancelEvent={() => this.props.doGetPolicy()}
                        >
                            <FormInput
                                label={<FormattedMessage id={`Number`} />}
                                value={this.props.driver &&
                                    this.props.driver.number}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}number`, value })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Applicant`} />}
                                value={this.props.driver &&
                                    { code: convertFromBoolean(this.props.driver.applicant) }}
                                references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}applicant`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormDropDown
                                label={<FormattedMessage id={`Relationship to Applicant`} />}
                                value={this.props.driver && this.props.driver.relationshipToApplicant}
                                references={this.props.reference.references && this.props.reference.references[`RelationToApplicant`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`RelationToApplicant`)}
                                changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}relationshipToApplicant`, value, this.props.doReducePolicyElement)}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Years Continuously Insured`} />}
                                value={this.props.driver &&
                                    this.props.driver.yearsContinuouslyInsured}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}yearsContinuouslyInsured`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Years With Previous Insurer`} />}
                                value={this.props.driver &&
                                    this.props.driver.yearsWithPreviousInsurer}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}yearsWithPreviousInsurer`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Years at Current Address`} />}
                                value={this.props.driver &&
                                    this.props.driver.yearsLivedAtCurrentAddress}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}yearsLivedAtCurrentAddress`, value })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Driver Training`} />}
                                value={this.props.driver &&
                                    { code: convertFromBoolean(this.props.driver.driverTraining) }}
                                references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}driverTraining`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Out of Country Experience`} />}
                                value={this.props.driver &&
                                    { code: convertFromBoolean(this.props.driver.outOfCountryExperience) }}
                                references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}outOfCountryExperience`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Number of Claims`} />}
                                value={this.props.driver &&
                                    this.props.driver.numberOfLosses}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}numberOfLosses`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Timeframe of Claims`} />}
                                value={this.props.driver &&
                                    this.props.driver.numberOfYearsForLosses}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}numberOfYearsForLosses`, value })}
                                allowEdit
                            />
                        </Form>
                        <Form
                            label={`License Details`}
                            allowEdit={this.props.allowEdit}
                            saveEvent={() => this.props.doUpsertPolicy()}
                            cancelEvent={() => this.props.doGetPolicy()}
                        >
                            <FormInput
                                label={<FormattedMessage id={`Age First Licensed`} />}
                                value={this.props.driver && this.props.driver.ageFirstLicensed}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}ageFirstLicensed`, value })}
                                allowEdit
                            />
                            <FormDropDown
                                label={<FormattedMessage id={`Issuing Country`} />}
                                value={this.props.driver.license &&
                                {
                                    code: this.props.driver.license.countryCode,
                                    value: this.props.driver.license.country
                                }
                                }
                                references={this.props.reference.references && this.props.reference.references[`Country`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`Country`)}
                                changeEvent={(value) => {
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}license.countryCode`, value: value.code });
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}license.country`, value: value.value });
                                }}
                                allowEdit
                            />
                            {(this.props.driver.license &&
                            (this.props.driver.license.countryCode === `` || this.props.driver.license.countryCode === `CAN` || this.props.driver.license.countryCode === `USA`)) ?
                                <FormDropDown
                                    label={<FormattedMessage id={`Issuing Province`} />}
                                    value={this.props.driver.license &&
                                    {
                                        code: this.props.driver.license.stateCode,
                                        value: this.props.driver.license.state
                                    }}
                                references={this.props.reference.references && this.updateChildRef(this.props.reference.references[`StateProvince`], this.props.driver.license.countryCode)}
                                    referencesEvent={() => this.props.reference.doGetReferences(`StateProvince`)}
                                    changeEvent={(value) => {
                                        this.props.doReducePolicyElement({ code: `${this.props.dotProp}license.stateCode`, value: value.code });
                                        this.props.doReducePolicyElement({ code: `${this.props.dotProp}license.state`, value: value.value });
                                    }}
                                    allowEdit
                                />
                                :
                                <FormInput
                                    label={<FormattedMessage id="covera.common.molecules.address.state" />}
                                    value={this.props.driver.license &&
                                        this.props.driver.license.state}
                                    changeEvent={(value) => {
                                        this.props.doReducePolicyElement({ code: `${this.props.dotProp}license.stateCode`, value: `` });
                                        this.props.doReducePolicyElement({ code: `${this.props.dotProp}license.state`, value: value });
                                    }}
                                    allowEdit
                                />
                            }
                            <FormInput
                                label={<FormattedMessage id={`License Number`} />}
                                value={this.props.driver &&
                                    this.props.driver.license &&
                                    this.props.driver.license.number}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}license.number`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`License Class`} />}
                                value={this.props.driver &&
                                    this.props.driver.license &&
                                    this.props.driver.license.class}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}license.class`, value })}
                                allowEdit
                            />
                            <FormDate
                                label={<FormattedMessage id={`Issued Date`} />}
                                value={this.props.driver &&
                                    this.props.driver.license &&
                                    this.props.driver.license.issuedDate}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}license.issuedDate`, value })}
                                culture={this.props.context.session.culture}
                                mode={`past`}
                                take={11}
                                allowEdit
                        />
                        <FormInput
                            label={<FormattedMessage id={`Number of Convictions`} />}
                            value={this.props.driver &&
                                this.props.driver.license &&
                                this.props.driver.license.numberOfConvictions}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}license.numberOfConvictions`, value })}
                            allowEdit
                        />
                        <FormInput
                            label={<FormattedMessage id={`Timeframe of Convictions`} />}
                            value={this.props.driver &&
                                this.props.driver.license &&
                                this.props.driver.license.numberOfYearsForConvictions}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}license.numberOfYearsForConvictions`, value })}
                            allowEdit
                        />
                        </Form>
                        <React.Fragment>
                            <Divider top={Sizes.xl} />
                            <Header size={Sizes.lg} textAlign={Alignments.left} text={`Driving Convictions`} />
                            <Container top={Sizes.xl} bottom={Sizes.xl}>
                                <SummaryGroup
                                    allowAdd={this.props.allowEdit}
                                    addEvent={() => {
                                        this.props.doExecutePolicyAction({ code: `CREATEDRIVERCONVICTION` });
                                    }}
                                    allowEdit
                                    editEvent={(conviction) => {
                                        this.props.doReduceParameter({ code: `DriverConviction`, value: conviction });
                                        this.setState({ current: conviction, mode: `conviction` });
                                    }}
                                    allowDelete={this.props.allowEdit}
                                    deleteEvent={(conviction) => {
                                        this.props.doReduceParameter({ code: `DriverConviction`, value: conviction });
                                        this.props.doExecutePolicyAction({ code: `DELETEDRIVERCONVICTION` });
                                        this.setState({ current: null, mode: `driver` });
                                    }}
                                    entities={this.getDriverConvictions()}
                                    icon={`drivingConviction`}
                                />
                            </Container>
                        </React.Fragment>
                        <React.Fragment>
                            <Divider top={Sizes.xl} />
                            <Header size={Sizes.lg} textAlign={Alignments.left} text={`Driving Suspensions`} />
                            <Container top={Sizes.xl} bottom={Sizes.xl}>
                                <SummaryGroup
                                    allowAdd={this.props.allowEdit}
                                    addEvent={() => {
                                        this.props.doReduceParameter({ code: `Driver`, value: this.props.driver.guid });
                                        this.props.doExecutePolicyAction({ code: `CREATEDRIVERSUSPENSION` });
                                    }}
                                    allowEdit
                                    editEvent={(suspension) => {
                                        this.props.doReduceParameter({ code: `DriverSuspension`, value: suspension });
                                        this.setState({ current: suspension, mode: `suspension` });
                                    }}
                                    allowDelete={this.props.allowEdit}
                                    deleteEvent={(suspension) => {
                                        this.props.doReduceParameter({ code: `DriverSuspension`, value: suspension });
                                        this.props.doExecutePolicyAction({ code: `DELETEDRIVERSUSPENSION` });
                                        this.setState({ current: null, mode: `driver` });
                                    }}
                                    entities={this.getDriverSuspensions()}
                                    icon={`drivingConviction`}
                                />
                            </Container>
                        </React.Fragment>
                        <React.Fragment>
                            <Divider top={Sizes.xl} />
                            <Header size={Sizes.lg} textAlign={Alignments.left} text={`Criminal Convictions`} />
                            <Container top={Sizes.xl} bottom={Sizes.xl}>
                                <SummaryGroup
                                    allowAdd={this.props.allowEdit}
                                    addEvent={() => {
                                        this.props.doExecutePolicyAction({ code: `CREATEDRIVERCRIMINALCONVICTION` });
                                    }}
                                    allowEdit
                                    editEvent={(criminalConviction) => {
                                        this.props.doReduceParameter({ code: `DriverCriminalConviction`, value: criminalConviction });
                                        this.setState({ current: criminalConviction, mode: `criminalConviction` });
                                    }}
                                    allowDelete={this.props.allowEdit}
                                    deleteEvent={(criminalConviction) => {
                                        this.props.doReduceParameter({ code: `DriverCriminalConviction`, value: criminalConviction });
                                        this.props.doExecutePolicyAction({ code: `DELETEDRIVERCRIMINALCONVICTION` });
                                        this.setState({ current: null, mode: `driver` });
                                    }}
                                    entities={this.getDriverCriminalConvictions()}
                                    icon={`criminalConviction`}
                                />
                            </Container>
                        </React.Fragment>
                        <React.Fragment>
                            <Divider top={Sizes.xl} />
                            <Header size={Sizes.lg} textAlign={Alignments.left} text={`Claims`} />
                            <Container top={Sizes.xl} bottom={Sizes.xl}>
                                <SummaryGroup
                                    allowAdd={this.props.allowEdit}
                                    addEvent={() => {
                                        this.props.doExecutePolicyAction({ code: `CREATEDRIVERCLAIM` });
                                    }}
                                    allowEdit
                                    editEvent={(claim) => {
                                        this.props.doReduceParameter({ code: `DriverClaim`, value: claim });
                                        this.setState({ current: claim, mode: `claim` });
                                    }}
                                    allowDelete={this.props.allowEdit}
                                    deleteEvent={(claim) => {
                                        this.props.doReduceParameter({ code: `DriverClaim`, value: claim });
                                        this.props.doExecutePolicyAction({ code: `DELETEDRIVERCLAIM` });
                                        this.setState({ current: null, mode: `driver` });
                                    }}
                                    entities={this.getDriverClaims()}
                                    icon={`claim`}
                                />
                            </Container>
                        </React.Fragment>
                        <React.Fragment>
                            <Divider top={Sizes.xl} />
                            <Header size={Sizes.lg} textAlign={Alignments.left} text={`Cancellations`} />
                            <Container top={Sizes.xl} bottom={Sizes.xl}>
                                <SummaryGroup
                                    allowAdd={this.props.allowEdit}
                                    addEvent={() => {
                                        this.props.doExecutePolicyAction({ code: `CREATEDRIVERCANCELLATIONREASON` });
                                    }}
                                    allowEdit
                                    editEvent={(cancellationReason) => {
                                        this.props.doReduceParameter({ code: `DriverCancellationReason`, value: cancellationReason });
                                        this.setState({ current: cancellationReason, mode: `cancellationReason` });
                                    }}
                                    allowDelete={this.props.allowEdit}
                                    deleteEvent={(cancellationReason) => {
                                        this.props.doReduceParameter({ code: `DriverCancellationReason`, value: cancellationReason });
                                        this.props.doExecutePolicyAction({ code: `DELETEDRIVERCANCELLATIONREASON` });
                                        this.setState({ current: null, mode: `driver` });
                                    }}
                                    entities={this.getDriverCancellationReasons()}
                                    icon={`cancellation`}
                                />
                            </Container>
                        </React.Fragment>
                    </React.Fragment>
                }
                {this.state.mode === `conviction` &&
                    <ProfileDriverConviction
                        conviction={this.props.driver.license.convictions.find(x => x.guid === this.state.current)}
                        dotProp={`${this.props.dotProp}license.convictions.${this.props.driver.license.convictions.findIndex(x => x.guid === this.state.current)}.`}
                        backEvent={() => this.setState({ mode: `driver`, currentDriver: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
                {this.state.mode === `suspension` &&
                    <ProfileDriverSuspension
                        suspension={this.props.driver.license.suspensions.find(x => x.guid === this.state.current)}
                        convictions={this.getDriverConvictions()}
                        dotProp={`${this.props.dotProp}license.suspensions.${this.props.driver.license.suspensions.findIndex(x => x.guid === this.state.current)}.`}
                        backEvent={() => this.setState({ mode: `driver`, currentDriver: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
                {this.state.mode === `criminalConviction` &&
                    <ProfileDriverCriminalConviction
                        criminalConviction={this.props.driver.criminalConvictions.find(x => x.guid === this.state.current)}
                        dotProp={`${this.props.dotProp}criminalConvictions.${this.props.driver.criminalConvictions.findIndex(x => x.guid === this.state.current)}.`}
                        backEvent={() => this.setState({ mode: `driver`, currentDriver: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
                {this.state.mode === `claim` &&
                    <ProfileDriverClaim
                        claim={this.props.driver.claims.find(x => x.guid === this.state.current)}
                        dotProp={`${this.props.dotProp}claims.${this.props.driver.claims.findIndex(x => x.guid === this.state.current)}.`}
                        backEvent={() => this.setState({ mode: `driver`, currentDriver: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
                {this.state.mode === `cancellationReason` &&
                    <ProfileDriverCancellationReason
                        cancellationReason={this.props.driver.cancellationReasons.find(x => x.guid === this.state.current)}
                        dotProp={`${this.props.dotProp}cancellationReasons.${this.props.driver.cancellationReasons.findIndex(x => x.guid === this.state.current)}.`}
                        backEvent={() => this.setState({ mode: `driver`, currentDriver: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
            </React.Fragment>
        );
    }
}

export default ProfileDriver;