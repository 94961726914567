import React from 'react';
import {
    BrowserRouter,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';

import { Container, Error } from 'metrix-common';

import Profile from '../../containers/Profile';
import Policies from '../../containers/Policies';

class Routes extends React.Component {
    render() {
        const fourOhFour = {
            errorStatus: "404",
            errorMsg: "Page Not Found.",
            redirectionLocation: "/policies",
            redirectionText: "Home."
        }

        const accessDenied = {
            errorStatus: "403",
            errorMsg: "Access Denied.",
            redirectionLocation: "/policies",
            redirectionText: "Home."
        }
        return (
            <BrowserRouter>
                <Container {...this.props}>
                    <Switch>
                        <Route exact path='/' render={(props) => <Policies {...props} {...this.props} />} >
                            <Redirect to='/policies' />
                        </Route>
                        <Route path='/policies' render={(props) => <Policies {...props} {...this.props} />} />
                        <Route exact path='/profile' render={(props) => <Profile {...props} {...this.props} />} />
                        <Route exact path='/404' render={(props) => <Error value={fourOhFour} />} />
                        <Route exact path='/403' render={(props) => <Error value={accessDenied} />} />
                        <Route exact path='/error' render={(props) => <Error value={{
                            errorMsg: "Unknown Error!", errorStatus: "---", redirectionLocation: "/search", redirectionText: "Home."
                        }} />} />

                        <Route render={(props) => <Error value={fourOhFour} />} />
                    </Switch>
                </Container>
            </BrowserRouter>
        );
    }
}

export default Routes;
