import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Alert,
    Alignments,
    Container,
    Divider,
    Form,
    FormRadio,
    Header,
    Sizes,
    SummaryGroup,
    getPageValidationInfo,
    convertFromBoolean,
    convertToBoolean,
} from 'metrix-common';

import ProfileSupernovaCommercialInsured from './Insured';

class ProfileSupernovaCommercialInsureds extends React.Component {
    componentDidMount() {
        const pageHasTooltipInfo = this.props.tooltipInfo && this.props.tooltipInfo.pages && this.props.tooltipInfo.pages.length && this.props.tooltipInfo.pages.some(p => p.code === this.props.pageCode);
        if (!pageHasTooltipInfo) {
            this.props.doGetTooltipInfo(this.props.policy.product);
        }

        this.setState({ currentHasAdditionalInsureds: this.props?.policy?.additionalInsuredsInfo?.hasAdditionalInsureds ?? false });
    }
    
    state = {
        mode: 'additionalInsuredsView',
        currentAdditionalInsured: null,
        currentHasAdditionalInsureds: null
    };

    handleSave = () => {

        let currentHasAdditionalInsureds = this.state.currentHasAdditionalInsureds;
        this.props.doReducePolicyElement({ code: 'additionalInsuredsInfo.hasAdditionalInsureds', value: currentHasAdditionalInsureds });

        if (currentHasAdditionalInsureds === true && this.props.policy?.additionalInsureds && this.props.policy.additionalInsureds.length === 0) {
            this.props.doExecutePolicyAction({ code: 'SN-COMMERCIAL.CREATEADDITIONALINSURED' });
        }
        else if (currentHasAdditionalInsureds === false && this.props.policy?.additionalInsureds && this.props.policy?.additionalInsureds.length > 0) {
            this.props.doExecutePolicyAction({ code: 'SN-COMMERCIAL.DELETEADDITIONALINSUREDS' });
        }
        else
        {
            this.props.doUpsertPolicy();
        }
    }

    getAdditionalInsureds = () => {
        let additionalInsureds  = [];

        if (this.props.policy?.additionalInsureds && this.props.policy.additionalInsureds.length > 0) {
            this.props.policy.additionalInsureds.forEach((additionalInsured) => {
                let option = { code: 'additionalInsuredOption', value: additionalInsured.guid };

                option.elements = [];
                option.elements.push({ code: 'Label', value: additionalInsured.label });

                additionalInsureds.push(option);
            });
        }

        return additionalInsureds;
    }

    handleHasAdditionalInsuredsFormRadio = (value) => {
        const valueAsBollean = convertToBoolean(value.code);
        this.setState({ currentHasAdditionalInsureds: valueAsBollean });

        this.props.doReducePolicyElement({
            code: 'additionalInsuredsInfo.hasAdditionalInsureds',
            value: valueAsBollean
        })
    }

    render() {
        const { error, warning } = getPageValidationInfo(this.props.pageCode, this.props.validationInfo);
        const additionalInsureds = this.getAdditionalInsureds();

        return (
            <React.Fragment>
                {
                    (error.message.length !== 0 || warning.message.length !== 0) &&
                    <Container top={Sizes.md}>
                        {
                            error.message.length !== 0 &&
                            <Alert
                                mode='danger'
                                header={error.title}
                                items={error.message}
                            />
                        }
                        {
                            warning.message.length !== 0 &&
                            <Alert
                                mode='warning'
                                header={warning.title}
                                items={warning.message}
                            />
                        }
                    </Container>
                }
                {
                    this.state.mode === 'additionalInsuredsView' &&
                    <React.Fragment>
                        <Form
                            pageCode={this.props.pageCode}
                            sectionCode='additionalInsuredsInfo'
                            saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                            reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                            tooltipInfo={this.props.tooltipInfo}
                            validationInfo={this.props.validationInfo}
                            header={<FormattedMessage id={'Additional Insureds Info'} />}
                            label={'Details'}
                            allowEdit={this.props.allowEdit}
                            saveEvent={() => this.handleSave()}
                            cancelEvent={() => this.props.doGetPolicy()}
                        >
                            <FormRadio
                                fieldCode='hasAdditionalInsureds'
                                label={<FormattedMessage id={'Do any clients or business associates require you to add them as an Additional Insured to your policy?'} />}
                                value={this.props.policy.additionalInsuredsInfo?.hasAdditionalInsureds !== undefined && { code: convertFromBoolean(this.props.policy.additionalInsuredsInfo.hasAdditionalInsureds) }}
                                references={this.props.reference?.references['YesNo']}
                                referencesEvent={() => this.props.reference.doGetReferences('YesNo')}
                                changeEvent={(value) => this.handleHasAdditionalInsuredsFormRadio(value)}
                                allowEdit />
                        </Form>
                        <React.Fragment>
                            <Divider top={Sizes.xl} />
                            <Header size={Sizes.lg} textAlign={Alignments.left} text={'Additional Insureds'} />
                            <Container top={Sizes.xl} bottom={Sizes.xl}>
                                <SummaryGroup
                                        allowAdd={(this.props.allowEdit && this.props.policy.additionalInsuredsInfo?.hasAdditionalInsureds) && (this.props.policy.additionalInsureds === undefined || additionalInsureds.length < 10)}
                                    addEvent={() => {
                                        this.props.doExecutePolicyAction({ code: 'SN-COMMERCIAL.CREATEADDITIONALINSURED' });
                                    }}
                                    allowEdit
                                    editEvent=
                                    {(insured) => {
                                        this.setState({ currentAdditionalInsured: insured, mode: 'insuredView' });
                                    }}
                                    allowDelete={this.props.allowEdit && additionalInsureds.length > 0}
                                    deleteEvent={(insured) => {
                                        this.props.doReduceParameter({ code: 'AdditionalInsuredGuid', value: insured });
                                        this.props.doExecutePolicyAction({ code: 'SN-COMMERCIAL.DELETEADDITIONALINSURED' });
                                        }}
                                    entities={additionalInsureds}
                                    icon={'person'}
                                />
                            </Container>
                        </React.Fragment>
                    </React.Fragment>
                }
                {
                    this.state.mode === 'insuredView' &&
                    <ProfileSupernovaCommercialInsured
                        rowId={this.state.currentAdditionalInsured}
                        pageCode={this.props.pageCode}
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        additionalInsured={this.props.policy.additionalInsureds.find(x => x.guid === this.state.currentAdditionalInsured)}
                        dotProp={`additionalInsureds.${this.props.policy.additionalInsureds.findIndex(x => x.guid === this.state.currentAdditionalInsured)}.`}
                        backEvent={() => this.setState({ mode: 'additionalInsuredsView', currentAdditionalInsured: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReduceParameter={this.props.doReduceParameter}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        doExecutePolicyAction={this.props.doExecutePolicyAction}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
            </React.Fragment>
        );
    }
}

export default ProfileSupernovaCommercialInsureds;