import React, { useEffect } from 'react';

import {
	Container
} from 'metrix-common';

import ListHeader from './Header';
import ListResults from './Results';
import ListFooter from './Footer';

const List = (props) => {
	useEffect(() => {
		props.doReduceView('list');
		
		if (props.query.list.search) {
			props.doQueryPolicies();			
		} else {
			props.doGetListPolicies();			
        }
	}, [props.query.list.search]);

	return (
		<Container>
			<ListHeader {...props} />
			<ListFooter {...props} />
			<ListResults {...props} />
			<ListFooter {...props} />
		</Container>
	);
}

export default List;