export const REDUCE_QUERY = 'REDUCE_QUERY';
export const REDUCE_QUERY_ELEMENT = 'REDUCE_QUERY_ELEMENT';
export const REDUCE_BOUND_POLICIES = 'REDUCE_BOUND_POLICIES';
export const REDUCE_LIST_POLICIES = 'REDUCE_LIST_POLICIES';
export const REDUCE_TRIAGE_POLICIES = 'REDUCE_TRIAGE_POLICIES';
export const REDUCE_VIEW = 'REDUCE_VIEW';
export const GET_BOUND_POLICIES = 'GET_BOUND_POLICIES';
export const GET_LIST_POLICIES = 'GET_LIST_POLICIES';
export const QUERY_POLICIES = 'QUERY_POLICIES';
export const GET_TRIAGE_POLICIES = 'GET_TRIAGE_POLICIES';
export const QUERY_TRIAGE_POLICIES = 'QUERY_TRIAGE_POLICIES';
export const EXECUTE_ASSIGNMENT_ACTION = 'EXECUTE_ASSIGNMENT_ACTION';
export const EXECUTE_POLICY_TRANSACTION_CUSTOM_ACTION = 'EXECUTE_POLICY_TRANSACTION_CUSTOM_ACTION';
export const QUERY_BOUND_POLICIES = 'QUERY_BOUND_POLICIES';
export const REDUCE_BOARD_POLICIES = 'REDUCE_BOARD_POLICIES';
export const GET_BOARD_POLICIES = 'GET_BOARD_POLICIES';