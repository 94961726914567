import { all, takeLatest, fork } from 'redux-saga/effects';
import {
    GET_POLICY,
    QUERY_POLICY,
    EXECUTE_POLICY_ACTION,
    UPSERT_POLICY,
    GET_POLICY_SUMMARY,
    GET_LOGS,
    GET_LOG_CONTENT,
    GET_POLICY_VALIDATION_INFO,
    GET_TOOLTIP_INFO,
    UPSERT_TOOLTIP_INFO, GET_OPTA,
    SEND_EMAIL_REQUEST,
    UPDATE_PLACE,
    UPSERT_NOTE,
    GET_NOTES,
    EXECUTE_POLICY_TRANSACTION_CUSTOM_ACTION
} from '../../constants/actions/policy';
import { getPolicySaga } from './getPolicySaga';
import { queryPolicySaga } from './queryPolicySaga';
import { executePolicyActionSaga } from './executePolicyActionSaga';
import { upsertPolicySaga } from './upsertPolicySaga';
import { getPolicySummarySaga } from './getPolicySummarySaga';
import { getLogsSaga } from './getLogsSaga';
import { getLogContentSaga } from './getLogContentSaga';
import { getPolicyValidationInfoSaga } from "./getPolicyValidationInfoSaga";
import { getTooltipInfoSaga } from './getTooltipInfoSaga';
import { upsertTooltipInfoSaga } from './upsertTooltipInfoSaga';
import { getOptaSaga } from './getOptaSaga';
import { sendEmailRequestSaga } from './sendEmailRequestSaga';
import { updatePlaceSaga } from './updatePlaceSaga';
import { getNotesSaga } from './getNotesSaga';
import { upsertNoteSaga } from './upsertNoteSaga';
import { executePolicyTransactionCustomActionSaga } from './executePolicyTransactionCustomActionSaga';

function* watchGetPolicy() {
    yield takeLatest(GET_POLICY, getPolicySaga);
}

function* watchGetPolicySummary() {
    yield takeLatest(GET_POLICY_SUMMARY, getPolicySummarySaga);
}

function* watchQueryPolicy() {
    yield takeLatest(QUERY_POLICY, queryPolicySaga);
}

function* watchExecutePolicyAction() {
    yield takeLatest(EXECUTE_POLICY_ACTION, executePolicyActionSaga);
}

function* watchUpsertPolicy() {
    yield takeLatest(UPSERT_POLICY, upsertPolicySaga);
}

function* watchGetLogs() {
    yield takeLatest(GET_LOGS, getLogsSaga);
}

function* watchGetLogContent() {
    yield takeLatest(GET_LOG_CONTENT, getLogContentSaga);
}

function* watchGetPolicyValidationInfo() {
    yield takeLatest(GET_POLICY_VALIDATION_INFO, getPolicyValidationInfoSaga);
}

function* watchGetTooltipInfo() {
    yield takeLatest(GET_TOOLTIP_INFO, getTooltipInfoSaga);
}

function* watchUpsertTooltipInfo() {
    yield takeLatest(UPSERT_TOOLTIP_INFO, upsertTooltipInfoSaga);
}

function* watchGetOpta() {
    yield takeLatest(GET_OPTA, getOptaSaga);
}

function* watchSendEmailRequest() {
    yield takeLatest(SEND_EMAIL_REQUEST, sendEmailRequestSaga);
}

function* watchUpdatePlace() {
    yield takeLatest(UPDATE_PLACE, updatePlaceSaga);
}

function* watchUpsertNote() {
	yield takeLatest(UPSERT_NOTE, upsertNoteSaga);
}

function* watchGetNote() {
	yield takeLatest(GET_NOTES, getNotesSaga);
}

function* watchExecutePolicyTransactionCustomAction() {
    yield takeLatest(EXECUTE_POLICY_TRANSACTION_CUSTOM_ACTION, executePolicyTransactionCustomActionSaga);
}

function* root() {
    return yield all([
        fork(watchGetPolicy),
        fork(watchGetPolicySummary),
        fork(watchQueryPolicy),
        fork(watchExecutePolicyAction),
        fork(watchUpsertPolicy),
        fork(watchGetLogs),
        fork(watchGetLogContent),
        fork(watchGetPolicyValidationInfo),
        fork(watchGetTooltipInfo),
        fork(watchUpsertTooltipInfo),
        fork(watchGetOpta),
        fork(watchSendEmailRequest),
        fork(watchUpdatePlace),
        fork(watchUpsertNote),
        fork(watchGetNote),
        fork(watchExecutePolicyTransactionCustomAction)
    ]);
}

export default root;