import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    convertFromBoolean,
    convertToBoolean,
    setAttributeEntity,
    Sizes,
    Alignments,
    BackButton,
    Divider,
    Header,
    Form,
    FormDropDown,
    FormInput,
    FormDate,
    FormRadio
} from 'metrix-common';

class ProfileDriverClaim extends React.Component {
    render() {
        return (
            <React.Fragment>
                <BackButton clickEvent={this.props.backEvent} />
                <Divider top={Sizes.md} />
                <Header size={Sizes.md} textAlign={Alignments.left} text={`Claim`} />
                <Form
                    label={`Claim Details`}
                    allowEdit={this.props.allowEdit}
                    saveEvent={() => this.props.doUpsertPolicy()}
                    cancelEvent={() => this.props.doGetPolicy()}
                >
                    <FormDate
                        label={<FormattedMessage id={`Date of Claim`} />}
                        value={this.props.claim && this.props.claim.claimDate}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}claimDate`, value })}
                        culture={this.props.context.session.culture}
                        mode={`past`}
                        allowEdit
                    />
                    <FormDropDown
                        label={<FormattedMessage id={`Claim Reason`} />}
                        value={this.props.claim && this.props.claim.reason}
                        references={this.props.reference.references && this.props.reference.references[`AutoClaim`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`AutoClaim`)}
                        changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}reason`, value, this.props.doReducePolicyElement)}
                        allowEdit
                    />
                    <FormInput
                        label={<FormattedMessage id={`Loss Amount`} />}
                        value={this.props.claim &&
                            this.props.claim.lossAmount &&
                            this.props.claim.lossAmount.value
                        }
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `${this.props.dotProp}lossAmount.value`, value });
                            this.props.doReducePolicyElement({ code: `${this.props.dotProp}lossAmount.currency`, value: `cad` });
                        }}
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={`At Fault?`} />}
                        value={this.props.claim &&
                            { code: convertFromBoolean(this.props.claim.atFault) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}atFault`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                </Form>
            </React.Fragment>
        );
    }
}

export default ProfileDriverClaim;