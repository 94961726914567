import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Sizes,
    Alignments,
    BackButton,
    Divider,
    Header,
    Form,
    FormDropDown,
    setAttributeEntity
} from 'metrix-common';

class ProfileDwellingLossPayee extends React.Component {
    render() {
        return (
            <React.Fragment>
                <BackButton clickEvent={this.props.backEvent} />
                <Divider top={Sizes.md} />
                <Header size={Sizes.md} textAlign={Alignments.left} text={`Loss Payee`} />
                <Form
                    label={`Loss Payee Details`}
                    allowEdit={this.props.allowEdit}
                    saveEvent={() => this.props.doUpsertPolicy()}
                    cancelEvent={() => this.props.doGetPolicy()}
                >
                    <FormDropDown
                        label={<FormattedMessage id={`Name`} />}
                        value={this.props.lossPayee}
                        references={this.props.reference.references && this.props.reference.references[`HomeLossPayee`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`HomeLossPayee`)}
                        changeEvent={(value) => setAttributeEntity(this.props.dotProp, value, this.props.doReducePolicyElement)}
                        allowEdit
                    />
                </Form>
            </React.Fragment>
        );
    }
}

export default ProfileDwellingLossPayee;