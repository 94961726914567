import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Form, FormInput, FormDate, FormSwitch
} from 'metrix-common';

class ProfileInterwestCyberInsuranceHistory extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            <Form
                header={<FormattedMessage id='Insurance History' />}
                label='Details'
                saveEvent={this.props.doUpsertPolicy}
                cancelEvent={this.props.doGetPolicy}
                allowEdit={this.props.allowEdit}
            >
                <FormSwitch
                    label={<FormattedMessage id='Does the Applicant currently purchase CyberRisk coverage?' />}
                    value={this.props.policy.interWestCyberCustomInfo.insuranceHistoryInfo.hasApplicantCurrentlyPurchasedCyberRiskCoverage}
                    changeEvent={
                        value => {
                            this.props.doReducePolicyElement({ code: 'interWestCyberCustomInfo.insuranceHistoryInfo.hasApplicantCurrentlyPurchasedCyberRiskCoverage', value: value });
                        }}
                    allowEdit
                />
                {
                    this.props.policy.interWestCyberCustomInfo.insuranceHistoryInfo.hasApplicantCurrentlyPurchasedCyberRiskCoverage &&
                    <FormInput
                        label='Expiring Carrier:'
                        value={this.props.policy.interWestCyberCustomInfo.insuranceHistoryInfo.expiringCarrierName || ''}
                        type='text'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestCyberCustomInfo.insuranceHistoryInfo.expiringCarrierName', value })}
                        allowEdit
                    />
                }
                {
                    this.props.policy.interWestCyberCustomInfo.insuranceHistoryInfo.hasApplicantCurrentlyPurchasedCyberRiskCoverage &&
                    <FormInput
                        label='Expiring Limit:'
                        value={this.props.policy.interWestCyberCustomInfo.insuranceHistoryInfo.expiringLimitAmount || 0}
                        type='number'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestCyberCustomInfo.insuranceHistoryInfo.expiringLimitAmount', value })}
                        allowEdit
                    />
                }
                {
                    this.props.policy.interWestCyberCustomInfo.insuranceHistoryInfo.hasApplicantCurrentlyPurchasedCyberRiskCoverage &&
                    <FormDate
                        label={<FormattedMessage id='Date coverage first purchased?' />}
                        value={this.props.policy.interWestCyberCustomInfo.insuranceHistoryInfo.expiringFirstPuchasedDate}
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestCyberCustomInfo.insuranceHistoryInfo.expiringFirstPuchasedDate', value })}
                        culture={this.props.context.session.culture}
                        mode='past'
                        allowEdit
                    />
                }
            </Form>
        );
    }
}

export default ProfileInterwestCyberInsuranceHistory;