import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Form,
    FormInput,
    FormDropDown,
    FormDate,
    FormRadio,
    convertFromBoolean,
    convertToBoolean,
    setAttributeEntity
} from 'metrix-common';

class ProfileBusiness extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            <React.Fragment>
                <Form
                    header={<FormattedMessage id={`Business Information`} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormInput
                        label={`Legal business name`}
                        value={this.props.policy && this.props.policy.btisglBusinessInfo && this.props.policy.btisglBusinessInfo.legalBusinessName }
                        type='text'
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisglBusinessInfo.legalBusinessName`, value })}
                        allowEdit
                    />
                    <FormInput
                        label={`DBA`}
                        value={this.props.policy && this.props.policy.btisglBusinessInfo && this.props.policy.btisglBusinessInfo.doingBusinessAs }
                        type='text'
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisglBusinessInfo.doingBusinessAs`, value })}
                        allowEdit
                    />
                    <FormDropDown
                        label={<FormattedMessage id={`Structure of the business`} />}
                        value={this.props.policy && this.props.policy.btisglBusinessInfo && { code: this.props.policy.btisglBusinessInfo.businessStructureCode, value: this.props.policy.btisglBusinessInfo?.businessStructureName }}
                        references={this.props.reference.references && this.props.reference.references[`BTIS-GL`] && this.props.reference.references[`BTIS-GL`][`BusinessStructure`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`BTIS-GL.BusinessStructure`)}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `btisglBusinessInfo.businessStructureCode`, value: value.code });
                            this.props.doReducePolicyElement({ code: `btisglBusinessInfo.businessStructureName`, value: value.value });
                        }}
                        allowEdit
                    />
                    <FormDropDown
                        label={<FormattedMessage id={`How many years has the applicant's current company been in business?`} />}
                        value={this.props.policy && this.props.policy.btisglBusinessInfo && { code: this.props.policy.btisglBusinessInfo.businessExperienceCode, value: this.props.policy.btisglBusinessInfo?.businessExperienceName }}
                        references={this.props.reference.references && this.props.reference.references[`BTIS-GL`] && this.props.reference.references[`BTIS-GL`][`BusinessExperience`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`BTIS-GL.BusinessExperience`)}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `btisglBusinessInfo.businessExperienceCode`, value: value.code });
                            this.props.doReducePolicyElement({ code: `btisglBusinessInfo.businessExperienceName`, value: value.value });
                        }}
                        allowEdit
                    />
                    <FormDropDown
                        label={<FormattedMessage id={`Applicant's industry experience`} />}
                        value={this.props.policy && this.props.policy.btisglBusinessInfo && { code: this.props.policy.btisglBusinessInfo.industryExperienceCode, value: this.props.policy.btisglBusinessInfo?.industryExperienceName  }}
                        references={this.props.reference.references && this.props.reference.references[`BTIS-GL`] && this.props.reference.references[`BTIS-GL`][`IndustryExperience`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`BTIS-GL.IndustryExperience`)}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `btisglBusinessInfo.industryExperienceCode`, value: value.code });
                            this.props.doReducePolicyElement({ code: `btisglBusinessInfo.industryExperienceName`, value: value.value });
                        }}
                        allowEdit
                    />
                    <FormInput
                        label={<FormattedMessage id={`Number of owners active in the field`} />}
                        value={this.props.policy && this.props.policy.btisglBusinessInfo && this.props.policy.btisglBusinessInfo.numberActiveOwners}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisglBusinessInfo.numberActiveOwners`, value })}
                        type='number'
                        allowEdit
                    />
                    <FormInput
                        label={<FormattedMessage id={`Annual gross receipts`} />}
                        value={this.props.policy && this.props.policy.btisglBusinessInfo && this.props.policy.btisglBusinessInfo.annualGrossReceipts }
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisglBusinessInfo.annualGrossReceipts`, value })}
                        type='currency'
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={`Does the Applicant have any employees?`} />}
                        value={this.props.policy && this.props.policy.btisglBusinessInfo && { code: convertFromBoolean(this.props.policy.btisglBusinessInfo.haveEmployees) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisglBusinessInfo.haveEmployees`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    <FormInput
                        label={<FormattedMessage id={`Number of Employees`} />}
                        value={this.props.policy && this.props.policy.btisglBusinessInfo && this.props.policy.btisglBusinessInfo.employeesCount}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisglBusinessInfo.employeesCount`, value })}
                        type='number'
                        allowEdit
                    />
                    <FormInput
                        label={<FormattedMessage id={`What is the annual employee payroll?`} />}
                        value={this.props.policy && this.props.policy.btisglBusinessInfo && this.props.policy.btisglBusinessInfo.annualEmployeePayroll}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisglBusinessInfo.annualEmployeePayroll`, value })}
                        type='currency'
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={`Does the Applicant hire subcontractors?`} />}
                        value={this.props.policy && this.props.policy.btisglBusinessInfo && { code: convertFromBoolean(this.props.policy.btisglBusinessInfo.usesSubcontractors ) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisglBusinessInfo.usesSubcontractors`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    <FormInput
                        label={<FormattedMessage id={`What are the annual subcontracting costs?`} />}
                        value={this.props.policy && this.props.policy.btisglBusinessInfo && this.props.policy.btisglBusinessInfo.annualSubcontractingCosts}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisglBusinessInfo.annualSubcontractingCosts`, value })}
                        type='currency'
                        allowEdit
                    />
                    <FormInput
                        label={<FormattedMessage id={`What percentage does subcontractor work?`} />}
                        value={this.props.policy && this.props.policy.btisglBusinessInfo && this.props.policy.btisglBusinessInfo.subcontractorWorkPercentage }
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisglBusinessInfo.subcontractorWorkPercentage`, value })}
                        type='number'
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={`Does the Applicant perform residential work prior to the certificate of occupancy?`} />}
                        value={this.props.policy && this.props.policy.btisglBusinessInfo && { code: convertFromBoolean(this.props.policy.btisglBusinessInfo.performNewResidentialWork) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisglBusinessInfo.performNewResidentialWork`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    <FormInput
                        label={<FormattedMessage id={`New residential work percentage`} />}
                        value={this.props.policy && this.props.policy.btisglBusinessInfo && this.props.policy.btisglBusinessInfo.newResidentialWorkPercentage}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisglBusinessInfo.newResidentialWorkPercentage`, value })}
                        type='number'
                        allowEdit
                    />
                    <FormInput
                        label={`Description of Operation`}
                        value={this.props.policy && this.props.policy.btisglBusinessInfo && this.props.policy.btisglBusinessInfo.descriptionOfOperations}
                        type='text'
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisglBusinessInfo.descriptionOfOperations`, value })}
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={`Is a Spanish speaking person desired for the inspection?`} />}
                        value={this.props.policy && this.props.policy.btisglBusinessInfo && { code: convertFromBoolean(this.props.policy.btisglBusinessInfo.requiresSpanishInspection) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisglBusinessInfo.requiresSpanishInspection`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    <FormInput
                        label={`Business License Number`}
                        value={this.props.policy && this.props.policy.btisglBusinessInfo && this.props.policy.btisglBusinessInfo.contractorLicenseNumber}
                        type='text'
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisglBusinessInfo.contractorLicenseNumber`, value })}
                        allowEdit
                    />
                </Form>
                <Form
                    header={<FormattedMessage id={`Insurance History`} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormRadio
                        label={`New in this Business?`}
                        value={this.props.policy && this.props.policy.btisglBusinessInfo && { code: convertFromBoolean(this.props.policy.btisglBusinessInfo.isNewBusiness) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisglBusinessInfo.isNewBusiness`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    <FormRadio
                        label={`Were you previously insured?`}
                        value={this.props.policy && this.props.policy.btisglBusinessInfo && { code: convertFromBoolean(this.props.policy.btisglBusinessInfo.hasPriorInsurance) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisglBusinessInfo.hasPriorInsurance`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    <FormDropDown
                        label={<FormattedMessage id={`Number of years of prior insurance`} />}
                        value={this.props.policy && this.props.policy.btisglBusinessInfo && { code: this.props.policy.btisglBusinessInfo.numberYearsInsuredCode, value: this.props.policy.btisglBusinessInfo?.numberYearsInsuredName }}
                        references={this.props.reference.references && this.props.reference.references[`BTIS-GL`] && this.props.reference.references[`BTIS-GL`][`PriorInsuranceYears`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`BTIS-GL.PriorInsuranceYears`)}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `btisglBusinessInfo.numberYearsInsuredCode`, value: value.code });
                        }}
                        allowEdit
                    />
                    <FormRadio
                        label={`Do you have any Lapse?`}
                        value={this.props.policy && this.props.policy.btisglBusinessInfo && { code: convertFromBoolean(this.props.policy.btisglBusinessInfo.hasInsuranceLapse) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisglBusinessInfo.hasInsuranceLapse`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    <FormRadio
                        label={`Was the lapse more than 60 days?`}
                        value={this.props.policy && this.props.policy.btisglBusinessInfo && { code: convertFromBoolean(this.props.policy.btisglBusinessInfo.moreThan60DaysLapse) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisglBusinessInfo.moreThan60DaysLapse`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    <FormRadio
                        label={`Any claims in the last 4 years?`}
                        value={this.props.policy && this.props.policy.btisglBusinessInfo && { code: convertFromBoolean(this.props.policy.btisglBusinessInfo.hasClaims) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisglBusinessInfo.hasClaims`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    <FormDropDown
                        label={<FormattedMessage id={`How many years ago was your last claim?`} />}
                        value={this.props.policy && this.props.policy.btisglBusinessInfo && { code: this.props.policy.btisglBusinessInfo.lastClaimYearsAgoCode, value: this.props.policy.btisglBusinessInfo?.lastClaimYearsAgoName }}
                        references={this.props.reference.references && this.props.reference.references[`BTIS-GL`] && this.props.reference.references[`BTIS-GL`][`ClaimAge`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`BTIS-GL.ClaimAge`)}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `btisglBusinessInfo.lastClaimYearsAgoCode`, value: value.code });
                        }}
                        allowEdit
                    />
                </Form>
            </React.Fragment>
        );
    }
}

export default ProfileBusiness;