import React from 'react';
import styled from "styled-components";
import { HeaderBar as AppBarComponent, Colors } from 'metrix-common';

import configs from './config.json';

const ClientAppBar = styled(AppBarComponent)`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${Colors.LightMode};
    box-shadow: rgb(238 238 238) 0px 2px 2px 0px;
`;

class AppBar extends React.Component {

    handleClick(target) {
        switch (target) {
            case "/home":
                window.location = `${this.props.context.configuration.corporateWeb}`;
                break;
            case "/logout":
                this.props.context.doExpireSession();
                window.location = this.props.context.configuration.clientWeb;
                break;
            case "/policies":
                this.props.history.push(target);
                break;
            default:
                window.location = `${this.props.context.configuration.clientWeb}${target}`;
                break;
        }
    }

    render() {
        const config = configs.filter(config => config.auth === this.props.context.authenticated && ((this.props.context.session && (config.userType === this.props.context.session.userType)) || config.userType === 'any'))[0];
        return (
            <ClientAppBar context={this.props.context}
                userName={this.props.context.session && this.props.context.session.firstName && this.props.context.session.firstName}                
                noOfElements={2}
                color={Colors.PrimaryText}
                logoWidth={`177`}
                logoHeight={`50`}
                companyNameColor={Colors.PrimaryText}
                userTypeColor="red"
                brandAvatarColor={Colors.Primary}
                {...this.props}
            />
        );
    }
}

export default AppBar;
