import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Container,
    Splash,
    Header,
    Label,
    Alert,
    Alignments,
    Sizes,
    Colors,
    getScreenSize,
    isMobile as tellIfMobile,
    isTablet as tellIfTablet, getPageValidationInfo
} from 'metrix-common';

import ProfileSummaryMenu from '../CommonComponents/Menu';
import ProfileSummaryHeader from './Header';
import ProfileSummaryQuotes from './Quotes';
import ProfileSummaryRisks from '../CommonComponents/Risks';
import ProfileSummaryRecap from '../CommonComponents/Recap';
// import ProfileSummaryAction from '../Summary/Action';

class ProfileSummary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            documentAccepted: false,
            displaySignaturePad: false
        };
    };

    getOperationMessages = () => {
        if (!this.props.context.operation || (this.props.context.operation && (!this.props.context.operation.errors && !this.props.context.operation.warnings && !this.props.context.operation.confirmations))) {
            return null;
        }

        let hasErrors = (this.props.context.operation.errors && this.props.context.operation.errors.length > 0);
        let hasWarnings = (this.props.context.operation.warnings && this.props.context.operation.warnings.length > 0);
        let hasConfirmations = (this.props.context.operation.confirmations && this.props.context.operation.confirmations.length > 0);

        let mode = hasErrors ? `danger` : hasWarnings ? `info` : ``;
        let header = hasErrors ? <FormattedMessage id={`app.components.CardList.ErrorCard.errorMessage`} /> : hasWarnings ? <FormattedMessage id={`app.components.Profile.Summary.warning`} /> : null;

        let messages = [];

        if (hasErrors) {
            this.props.context.operation.errors.forEach(item => messages.push({ code: item.code, value: item.value ? item.value : item.code, isError: true }));
        }

        if (hasWarnings) {
            this.props.context.operation.warnings.forEach(item => messages.push({ code: item.code, value: item.value ? item.value : item.code, isError: false }));
        }

        if (hasConfirmations) {
            this.props.context.operation.confirmations.forEach(item => messages.push({ code: item.code, value: item.value ? item.value : item.code, isError: false }));
        }

        return (<Alert mode={mode} header={header} items={messages} />);
    }

    /*closeSignaturePad = () => {
        this.setState({ displaySignaturePad: false });
    }

    openSignaturePad = () => {
        this.setState({ displaySignaturePad: true });
    }*/

    render() {
        const policySummary = this.props.policySummary;

        // let screenSize = getScreenSize();

        // const isMobile = tellIfMobile(screenSize);
        // const isTablet = tellIfTablet(screenSize);

        let isClient = (this.props.context.session.userType === `client`);
        let clientName = policySummary && policySummary?.participants?.insured?.party?.name?.firstName;

        const { error, warning } = getPageValidationInfo(this.props.pageCode, this.props.validationInfo);

        return (
            policySummary &&
            <Splash color={Colors.LightMode}>
                <ProfileSummaryHeader {...this.props} />
                <Container right={Sizes.xs} top={Sizes.md} left={Sizes.xs}>
                    {(this.props.context.session.userType === `broker` || this.props.context.session.userType === `administrator`) &&
                        <React.Fragment>
                            <ProfileSummaryMenu {...this.props} />
                            {this.getOperationMessages()}
                            {policySummary.messages && policySummary.messages.length > 0 &&
                                <Alert
                                    header={<FormattedMessage id={`app.components.Profile.Summary.attention`} />}
                                    items={policySummary.messages} />
                            }
                            {
                                error.message.length !== 0 &&
                                <Alert
                                    mode='danger'
                                    header={error.title}
                                    items={error.message}
                                />
                            }
                            {
                                warning.message.length !== 0 &&
                                <Alert
                                    mode='warning'
                                    header={warning.title}
                                    items={warning.message}
                                />
                            }
                        </React.Fragment>
                    }
                    {
                        isClient ?
                            <Container>
                                <Header text={`Choose Your Renewal`} textAlign={Alignments.center} size={Sizes.lg} top={Sizes.lg} />
                                <Label text={`Review the status of your current insurance policies and applications, add policies, or make changes.`} textAlign={Alignments.center} bottom={Sizes.md} />
                            </Container>
                            :
                            <Container>
                                <Header text={`${clientName}'s Insurance Quotes`} textAlign={Alignments.center} size={Sizes.lg} top={Sizes.lg} />
                                <Label text={`Review the status and make changes to this customer's policy information.`} textAlign={Alignments.center} bottom={Sizes.md} />
                            </Container>
                    }
                    {/*{
                        this.props.policySummary.status === 'quoteIssued' &&
                        <ProfileSummaryAction {...this.props} />
                    }*/}
                    {
                        policySummary.quotes &&
                        // !this.props.policySummary.status === 'quoteIssued' &&
                        <ProfileSummaryQuotes {...this.props} policySummary={policySummary} />
                    }
                    {
                        (this.props.policySummary.status === `quoteAccepted` ||
                        this.props.policySummary.status === `bindRequested` ||
                        this.props.policySummary.status === `bound` ||
                        this.props.policySummary.status === `issued`) &&
                        <ProfileSummaryRecap {...this.props} />
                    }

                    <ProfileSummaryRisks {...this.props} risks={this.props.policySummary.risks} />

                    {/*{*/}
                    {/*    <Container bottom={Sizes.xxl}>*/}
                    {/*        <Header size={Sizes.sm} bottom={Sizes.xs} top={Sizes.md} textAlign={Alignments.left} text={<FormattedMessage id={`Bind`} />} />*/}
                    {/*        <OptionContainer top={Sizes.xxs} bottom={Sizes.xxs}>*/}
                    {/*            <Grid>*/}
                    {/*                <InnerRow nogutter={!isMobile}>*/}
                    {/*                    {!isMobile &&*/}
                    {/*                        <Column xs={3} md={1.5} lg={1} gutterLeft={isTablet ? 0 : 20}>*/}
                    {/*                            <InnerContainer fullSize verticalAlignment={Alignments.center} isMobile={isMobile} >*/}
                    {/*                                <CheckBox value={true} />*/}
                    {/*                            </InnerContainer>*/}
                    {/*                        </Column>}*/}
                    {/*                    <Column xs={10} md={4.5} lg={9}>*/}
                    {/*                        <InnerContainer fullSize verticalAlignment={isMobile ? Alignments.top : Alignments.center} isMobile={isMobile}>*/}
                    {/*                            <Header size={isMobile ? Sizes.xxs : Sizes.sm} textAlign={Alignments.left} text={<FormattedMessage id={`Binding details`} />} />*/}
                    {/*                        </InnerContainer>*/}
                    {/*                    </Column>*/}
                    {/*                    <Column xs={2} md={2.3} lg={1} nogutter>*/}
                    {/*                        <InnerContainer fullSize verticalAlignment={isMobile ? Alignments.bottom : Alignments.center} isMobile={isMobile} top={isMobile ? Sizes.sm : Sizes.none}>*/}
                    {/*                            <ChevronIcon mode={`down`} />*/}
                    {/*                        </InnerContainer>*/}
                    {/*                    </Column>*/}
                    {/*                </InnerRow>*/}
                    {/*            </Grid>*/}
                    {/*        </OptionContainer>*/}
                    {/*        <OptionContainer top={Sizes.xxs} bottom={Sizes.xxs}>*/}
                    {/*            <Grid>*/}
                    {/*                <InnerRow nogutter={!isMobile}>*/}
                    {/*                    {!isMobile &&*/}
                    {/*                        <Column xs={3} md={1.5} lg={1} gutterLeft={isTablet ? 0 : 20}>*/}
                    {/*                            <InnerContainer fullSize verticalAlignment={Alignments.center} isMobile={isMobile} >*/}
                    {/*                                <CheckBox />*/}
                    {/*                            </InnerContainer>*/}
                    {/*                        </Column>}*/}
                    {/*                    <Column xs={10} md={4.5} lg={9}>*/}
                    {/*                        <InnerContainer fullSize verticalAlignment={isMobile ? Alignments.bottom : Alignments.center} isMobile={isMobile}>*/}
                    {/*                            <Header size={isMobile ? Sizes.xxs : Sizes.sm} textAlign={Alignments.left} text={<FormattedMessage id={`Request Signature`} />} />*/}
                    {/*                        </InnerContainer>*/}
                    {/*                    </Column>*/}
                    {/*                    <Column xs={2} md={2.3} lg={1} nogutter>*/}
                    {/*                        <InnerContainer fullSize verticalAlignment={isMobile ? Alignments.bottom : Alignments.center} isMobile={isMobile} top={isMobile ? Sizes.sm : Sizes.none}>*/}
                    {/*                            <ChevronIcon mode={`down`} />*/}
                    {/*                        </InnerContainer>*/}
                    {/*                    </Column>*/}
                    {/*                </InnerRow>*/}
                    {/*            </Grid>*/}
                    {/*        </OptionContainer>*/}
                    {/*        <OptionContainer top={Sizes.xxs} bottom={Sizes.xxs}>*/}
                    {/*            <Grid>*/}
                    {/*                <InnerRow nogutter={!isMobile}>*/}
                    {/*                    {!isMobile &&*/}
                    {/*                        <Column xs={3} md={1.5} lg={1} gutterLeft={isTablet ? 0 : 20}>*/}
                    {/*                            <InnerContainer fullSize verticalAlignment={Alignments.center} isMobile={isMobile} >*/}
                    {/*                                <CheckBox />*/}
                    {/*                            </InnerContainer>*/}
                    {/*                        </Column>}*/}
                    {/*                    <Column xs={10} md={4.5} lg={9}>*/}
                    {/*                        <InnerContainer fullSize verticalAlignment={isMobile ? Alignments.top : Alignments.center} isMobile={isMobile}>*/}
                    {/*                            <Header size={isMobile ? Sizes.xxs : Sizes.sm} textAlign={Alignments.left} text={<FormattedMessage id={`Collect payment`} />} />*/}
                    {/*                        </InnerContainer>*/}
                    {/*                    </Column>*/}
                    {/*                    <Column xs={2} md={2.3} lg={1} nogutter>*/}
                    {/*                        <InnerContainer fullSize verticalAlignment={isMobile ? Alignments.bottom : Alignments.center} isMobile={isMobile} top={isMobile ? Sizes.sm : Sizes.none}>*/}
                    {/*                            <ChevronIcon mode={`down`} />*/}
                    {/*                        </InnerContainer>*/}
                    {/*                    </Column>*/}
                    {/*                </InnerRow>*/}
                    {/*            </Grid>*/}
                    {/*        </OptionContainer>*/}
                            
                    {/*    </Container>*/}
                    {/*}*/}
                </Container>
            </Splash>
        );
    }
}

export default ProfileSummary;