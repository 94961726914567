import dotProp from 'dot-prop-immutable';

import {
    REDUCE_QUERY,
    REDUCE_QUERY_ELEMENT,
    REDUCE_LIST_POLICIES,
    REDUCE_TRIAGE_POLICIES,
    REDUCE_VIEW,
    REDUCE_BOUND_POLICIES,
    REDUCE_BOARD_POLICIES
} from '../../constants/actions/policies';

const initialState = {
    boundPolicies: [],
    boardPolicies: [],
    listPolicies: [],
    triagePolicies: [],
    view: 'list',
    query: {
        board: {
            brokerReferenceGuid: null,
            brokerCompanyReferenceGuid: null,
            transactionType: null
        },
        bound: {
            assignedTo: null,
            brokerCompanyGuid: null,
            product: null,
            take: 25,
            skip: 0,
            orderByDesc: true
        },
        list: {
            assignedTo: null,
            brokerCompanyGuid: null,
            product: null,
            take: 25,
            skip: 0,
            orderByDesc: true
        },
        triage: {
            assignedTo: null,
            brokerCompanyGuid: null,
            product: null,
            take: 25,
            skip: 0,
            orderByDesc: true
        }
    }
};

function policiesReducer(state = initialState, action) {
    switch (action.type) {
        case REDUCE_QUERY:
            return dotProp.set(state, `query.${state.view}`, { ...action.payload, orderByDesc: true });
        case REDUCE_QUERY_ELEMENT:
            let reset = action.payload.code === 'search' ? action.payload.value : action.payload.code;
            let queryState = null;
            reset
                ?
                queryState = dotProp.set(state, `query.${state.view}.${action.payload.code}`, action.payload.value)
                :
                queryState = dotProp.set(state, `query.${state.view}`, {search:  false});
            return queryState;
        case REDUCE_BOUND_POLICIES:
            return dotProp.set(state, 'boundPolicies', action.payload);
        case REDUCE_LIST_POLICIES:
            return dotProp.set(state, 'listPolicies', action.payload);
        case REDUCE_TRIAGE_POLICIES:
            return dotProp.set(state, 'triagePolicies', action.payload);
        case REDUCE_BOARD_POLICIES:
            return dotProp.set(state, 'boardPolicies', action.payload);
        case REDUCE_VIEW:
            return dotProp.set(state, 'view', action.payload);
        default:
            return state;
    }
}

export default policiesReducer;
