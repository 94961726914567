import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    BackButton,
    AddressForm,
} from 'metrix-common';

class ProfileInterwestFpBusinessInfoLocation extends React.Component {
    state = {
        mode: `locationView`,
        currentClassification: null
    };

    componentDidMount() {
    }

    getLocationName = () => {
        let locationName = null;

        if (this.props.location && this.props.location.locationName) {
            locationName = `${this.props.location.locationName}`;
        }

        return locationName;
    }

    render() {
        let locationName = this.getLocationName() || `New Location`;
        return (
            <React.Fragment>
                {this.state.mode === `locationView` &&
                    <React.Fragment>
                        <BackButton clickEvent={this.props.backEvent} />
                        {/*<Form
                            header={<FormattedMessage id={locationName} />}
                            label={`Details`}
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            allowEdit={this.props.allowEdit}
                        >
                            <FormInput
                                label={<FormattedMessage id={`Label`} />}
                                value={this.props.location?.locationName}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}locationName`, value })}
                                allowEdit
                            />
                        </Form>*/}
                        <AddressForm
                            {...this.props}
                            enableAddressSearch
                            addressSearchCountryCode={`USA`}
                            header={<FormattedMessage id={`Address Details`} />}
                            label={`Location Address`}
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            reference={this.props.reference}
                            changeEvent={(dotProp, value) => {
                                dotProp === `address` ?
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}address`, value })
                                    :
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}address.${dotProp}`, value })
                            }}
                            allowEdit
                            address={this.props.location && this.props.location.address}
                        />

                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default ProfileInterwestFpBusinessInfoLocation;