import { put, call, select } from 'redux-saga/effects';
import { reduceOperation, reduceLoading, getSessionSaga } from 'metrix-common';
import { reducePolicyLogs } from '../../actions/policy/reducePolicyLogs';

export function* getLogsSaga(action) {
    try {
        yield put(reduceLoading({ code: `getLogs`, value: true }));
        const session = yield getSessionSaga();
        let response = yield call(fetch, `/api/log?referenceCode=${action.payload.referenceCode}&productCode=${action.payload.productCode}`, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Authorization': session.authenticationToken.token,
                'Content-Type': 'application/json'
            }
        });
        let result = yield call([response, 'json']);
        yield put(reduceOperation(result.operation));
        if (!result.operation.status) {
            yield put(reduceLoading({ code: `getLogs`, value: false }));
            return;
        }
        yield put(reducePolicyLogs(result.apiLogs));
        yield put(reduceLoading({ code: `getLogs`, value: false }));
        return;
    }
    catch (error) {
        console.log("Error while calling Get API Logs - ", error);
        yield put(reduceLoading({ code: `getLogs`, value: false }));
        return;
    }
}
