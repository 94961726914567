import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Alignments,
    Container,
    Form,
    FormInput,
    FormDropDown,
    FormDate,
    FormTextArea,
    FormRadio,
    Label,
    convertFromBoolean,
    convertToBoolean
} from 'metrix-common';

class ProfileCGClassification extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
        this.props.reference.doGetReferences(`CG.ClassificationTypes`);
        this.props.reference.doGetReferences(`CG.IndustryTypes`);
    }

    render() {
        return (
            <React.Fragment>
                <Form header={<FormattedMessage id={`Industry and Classification`} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit>

                    <FormDropDown
                        label={<FormattedMessage id={`Industry`} />}
                        value={{ code: this.props.policy.cgCustomInfo.industryTypeCode, value: this.props.policy.cgCustomInfo.industryTypeName }}
                        references={this.props.reference?.references[`CG`] && this.props.reference.references[`CG`][`IndustryTypes`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`CG.IndustryTypes`)}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `cgCustomInfo.industryTypeCode`, value: value.code });
                            this.props.doReducePolicyElement({ code: `cgCustomInfo.industryTypeName`, value: value.value });
                        }}
                        allowEdit
                    />

                    <FormDropDown
                        label={<FormattedMessage id={`Classification`} />}
                        value={{ code: this.props.policy.cgCustomInfo.classificationTypeCode, value: this.props.policy.cgCustomInfo.classificationTypeName }}
                        references={this.props.reference?.references[`CG`] && this.props.reference.references[`CG`][`ClassificationTypes`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`CG.ClassificationTypes`)}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `cgCustomInfo.classificationTypeCode`, value: value.code });
                            this.props.doReducePolicyElement({ code: `cgCustomInfo.classificationTypeName`, value: value.value });
                        }}
                        allowEdit
                    />
                </Form>

            </React.Fragment>
        );
    }
}

export default ProfileCGClassification;