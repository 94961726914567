import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { getAndUseRoute } from '../../../utils';

import {
    Container,
    Splash,
    Separator,
    Header,
    Column,
    Label,
    Alert,
    ToolTip,
    Alignments,
    Colors,
    ClearIcon,
    Contact,
    Tabbed,
    Sizes,
    CheckBox,
    BulletList,
    PrimaryButton,
    SecondaryButton,
    getScreenSize,
    isMobile as tellIfMobile
} from 'metrix-common';


import ProfileRenewalMenu from '../CommonComponents/Menu';
import ProfileRenewalQuotes from '../CommonComponents/Quotes';
import ProfileRenewalRisks from '../CommonComponents/Risks';
import ProfileRenewalRecap from '../CommonComponents/Recap';
import Signature from '../CommonComponents/Signature';
import ProfileRenewalHeader from './Header';
import ProfileRenewalAction from './Action';
import CustomSlider from './Slider';
import ProductIcons from '../../ProductClassIcon';


const NotificationContainer = styled(Container)`
    border-radius: 2px;
    align-items: center;
`;

const NotificationTextContainer = styled(Container)`
    align-items: center;
`;

const ClearContainer = styled(Container)`
    cursor: pointer;
`;

const InfoContainer = styled(Container)`
    border: 1px solid ${Colors.StrokeLight};
    border-radius: 4px;
`;

const DecisionButton = styled(Container)`
    justify-content: center;
`;

const LandingContainer = styled(Container)`
    border: 1px solid ${Colors.StrokeLight};
    border-radius: 2px;
    justify-content: space-around;
    box-shadow: 5px 10px 15px ${Colors.StrokeDark};
`;

class ProfileRenewal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            displayNotification: true,
            statusValue: 0,
            documentAccepted: false,
            displaySignaturePad: false
        };
    };

    componentDidMount() {
        if (this.props.policySummary) {
            const clientStatus = this.props.policySummary && this.props.policySummary.status;
            this.updateProgressBar(clientStatus);
        }
    }

    getOperationMessages = () => {
        if (!this.props.context.operation || (this.props.context.operation && (!this.props.context.operation.errors && !this.props.context.operation.warnings && !this.props.context.operation.confirmations))) {
            return null;
        }

        let hasErrors = (this.props.context.operation.errors && this.props.context.operation.errors.length > 0);
        let hasWarnings = (this.props.context.operation.warnings && this.props.context.operation.warnings.length > 0);
        let hasConfirmations = (this.props.context.operation.confirmations && this.props.context.operation.confirmations.length > 0);

        let mode = hasErrors ? `danger` : hasWarnings ? `info` : ``;
        let header = hasErrors ? <FormattedMessage id={`app.components.CardList.ErrorCard.errorMessage`} /> : hasWarnings ? <FormattedMessage id={`app.components.Profile.Summary.warning`} /> : null;

        let messages = [];

        if (hasErrors) {
            this.props.context.operation.errors.forEach(item => messages.push({ code: item.code, value: item.value ? item.value : item.code, isError: true }));
        }

        if (hasWarnings) {
            this.props.context.operation.warnings.forEach(item => messages.push({ code: item.code, value: item.value ? item.value : item.code, isError: false }));
        }

        if (hasConfirmations) {
            this.props.context.operation.confirmations.forEach(item => messages.push({ code: item.code, value: item.value ? item.value : item.code, isError: false }));
        }

        return (<Alert mode={mode} header={header} items={messages} />);
    }

    getNotifyMessage = (status, isClient, clientName) => {
        let notificationText = '';
        let messageColor = Colors.Success;

        if (isClient) {
            switch (status) {
                case `quoted`:
                case `incomplete`:
                    notificationText = `Hi ${clientName}, your Home insurance is up for renewal, it's time to review!`;
                    messageColor = Colors.Error;
                    break;                
                case `quoteIssued`:
                    notificationText = `Please review and accept your preferred quote.`;
                    break;
                case `quoteAccepted`:
                    notificationText = `Your Renewal Docs are ready to sign. Please review and sign documents.`;
                    messageColor = Colors.Error;
                    break;                                
                case `bindRequested`:
                case `bound`:
                    notificationText = `Your Renewal Docs are signed. Your broker is working on your policy.`;
                    break;
                case `issued`:
                    notificationText = `Your Renewal Docs are signed and issued by your broker.`;
                    break;
                default:
                    notificationText = ``;
                    break;
            }
        }
        else {
            switch (status) {                
                case `incomplete`:
                    notificationText = `${clientName}'s renewal is pending review.`;
                    break;
                case `quoted`:
                    notificationText = `${clientName}'s renewal quotes need to be issued.`;
                    break;
                case `quoteIssued`:
                    notificationText = `Waiting for ${clientName} to accept a quote.`;
                    break;
                case `quoteAccepted`:
                    notificationText = `${clientName} has accepted the quote.`;
                    break;
                case `bindRequested`:
                    notificationText = `${clientName} has signed documents and waiting for you to bind the policy.`;
                    break;
                case `bound`:
                    notificationText = `Please finalize the policy and issue all documents.`;
                    break;
                default:
                    notificationText = ``;
                    break;
            }
        }

        return ({ notificationText, messageColor });
    }

    updateProgressBar = (clientStatus) => {
        if (clientStatus === `quoted` || clientStatus === `quoteIssued`) {
            this.setState({ statusValue: 50 });
        }
        else if (clientStatus === `quoteAccepted`) {
            this.setState({ statusValue: 75 });
        }
        else if (clientStatus === `bound` || clientStatus === `issued` || clientStatus === `bindRequested`) {
            this.setState({ statusValue: 100 });
        }
        else {
            this.setState({ statusValue: 25 });
        }
    }

    closeSignaturePad = () => {
        this.setState({ displaySignaturePad: false });
    }

    openSignaturePad = () => {
        this.setState({ displaySignaturePad: true });
    }

    handleContinueQuoteClick = async (e, payload) => {
        await getAndUseRoute(e, this.props.context.session, payload);
    };

    render() {

        let baseProps = {
            ...this.state,
            ...this.props
        };

        let screenSize = getScreenSize();

        const isDesktop = this.props.isDesktop;
        const isTablet = this.props.isTablet;
        const isMobile = tellIfMobile(screenSize);

        let isAdmin = (this.props.context.session.userType === `administrator`);
        let isBroker = (this.props.context.session.userType === `broker`);
        let isClient = (this.props.context.session.userType === `client`);


        let clientName = this.props.policySummary && this.props.policySummary.participants.insured.party.name.firstName;
        let clientStatus = this.props.policySummary && this.props.policySummary.status;
        let expiryDate = this.props.policySummary.expiresInDays;
        let { notificationText, messageColor } = this.getNotifyMessage(clientStatus, isClient, clientName, expiryDate);

        let productName = this.props.policySummary.productName;
        let productClass = this.props.policySummary.productClass;

        let riskChangeSummary = this.props.policySummary.riskChangeSummary;

        //This hardcoded for now but will come from server or status file.
        const labels = ['Renewal Review', 'Accept Quote', 'Sign Docs'];
        const productTabs = [{ code: `Home`, descdescription: `Home Owner` }, { code: `Landlord`, descdescription: `Landlord Owner` }, { code: `RV`, descdescription: `RV Owner` }];

        return (
            this.props.policySummary &&
            <Splash color={Colors.LightMode}>
                <ProfileRenewalHeader {...this.props} />
                <Container top={Sizes.md} right={Sizes.xs} left={Sizes.xs}>

                    {/*Landing Container from email to redirect*/}
                    {
                        clientStatus === `incomplete` && isClient &&
                        <LandingContainer inline>
                            <Container top={Sizes.md} bottom={Sizes.md}>
                                <ProductIcons productClass={`${productClass}`} size={Sizes.lg} />
                            </Container>
                            <Header text={`Your ${productName} Insurance`} color={Colors.Primary} size={Sizes.md} top={Sizes.md} bottom={Sizes.md} />
                            <PrimaryButton
                                text={`⭮ Review Renewal`}
                                clickEvent={(e) => this.handleContinueQuoteClick(e, { siteCode: 'corporateWeb', path: 'workflow', entity: this.props.policySummary.guid, isBlankPage: true })}
                                right={Sizes.xs}
                                top={Sizes.md} bottom={Sizes.md}
                            />
                        </LandingContainer>
                    }

                    {/*Document Acceptance and Sign Document section*/}
                    {
                        clientStatus === `quoteAccepted` && isClient &&
                        <Column xs={isMobile ? 12 : 11} >
                            <CheckBox
                                label={`I have reviewed and accepted the proposed quote.`}
                                value={this.state.documentAccepted}
                                clickEvent={(value) =>
                                    this.setState({ documentAccepted: !this.state.documentAccepted })
                                }
                                bottom={Sizes.md}
                            />

                            <DecisionButton inline>                                
                                    <PrimaryButton
                                        clickEvent={() => this.props.changeTabTo('documents')}
                                        text={<FormattedMessage id={'Download Quote'} />}
                                        right={Sizes.xs}
                                        />                                
                                <SecondaryButton text={`Sign Documents`}
                                    disabled={!this.state.documentAccepted}
                                    clickEvent={() =>
                                        this.openSignaturePad()
                                    } />
                            </DecisionButton>
                            {
                                this.state.displaySignaturePad && this.state.documentAccepted &&
                                <Signature closePad={() => this.closeSignaturePad()} updateProgressBar={(status) => this.updateProgressBar(status)} {...baseProps} />
                            }

                        </Column>
                    }

                    {(this.props.context.session.userType === `broker` || this.props.context.session.userType === `administrator`) &&
                        <React.Fragment>
                            <ProfileRenewalMenu {...this.props} />
                            {this.getOperationMessages()}
                            {this.props.policySummary.messages && this.props.policySummary.messages.length > 0 &&
                                <Alert
                                    header={<FormattedMessage id={`app.components.Profile.Summary.attention`} />}
                                    items={this.props.policySummary.messages} />
                            }
                        </React.Fragment>
                    }
                    {
                        isClient ?
                            <>
                                <Header text={`Choose Your Renewal`} textAlign={Alignments.left} size={Sizes.lg} top={Sizes.lg} />
                                <Label text={`Review the status of your current insurance policies and applications, add policies, or make changes.`} textAlign={Alignments.left} bottom={Sizes.md} />
                                {
                                    isMobile ?
                                        <Column xs={12}>
                                            <CustomSlider type={`ratio`} Labels={labels} changeEvent={() => { }}
                                                leftLabel={`Start`} rightLabel={`Finish`} value={this.state.statusValue} isMobile={isMobile} {...this.props} />
                                        </Column>
                                        :
                                        <Column md={12}>
                                            <CustomSlider type={`ratio`} Labels={labels} changeEvent={() => { }}
                                                leftLabel={`Start`} rightLabel={`Finish`} value={this.state.statusValue} />
                                        </Column>
                                }
                            </>
                            :
                            <>
                                <Header text={`${clientName}'s Insurance`} textAlign={Alignments.left} size={Sizes.lg} top={Sizes.lg} />
                                <Label text={`Review the status and make changes to this customer's policy information.`} textAlign={Alignments.left} />
                            </>
                    }
                    {
                        isMobile ?

                        notificationText != `` && this.state.displayNotification &&
                        <NotificationContainer color={messageColor} top={Sizes.md} inline>
                            <Column xs={11} >
                                <NotificationTextContainer inline>
                                    <ToolTip color={Colors.LightMode}>
                                        <Label text={`${notificationText}`} />
                                    </ToolTip>
                                    <Label text={`${notificationText}`} color={Colors.LightMode} left={Sizes.xs} top={Sizes.xs} bottom={Sizes.xs} />
                                </NotificationTextContainer>
                            </Column>

                            <Column xs={1} nogutterleft nogutterright>
                                <ClearContainer clickEvent={() => this.setState({ displayNotification: false })}>
                                    <ClearIcon color={Colors.LightMode} />
                                </ClearContainer>
                            </Column>

                        </NotificationContainer>
                        :
                        notificationText != `` && this.state.displayNotification &&
                        <NotificationContainer color={messageColor} top={Sizes.md} inline>
                            <Column md={11} >
                                <NotificationTextContainer inline>
                                    <ToolTip color={Colors.LightMode}>
                                        <Label text={`${notificationText}`} />
                                    </ToolTip>
                                    <Label text={`${notificationText}`} color={Colors.LightMode} left={Sizes.xs} top={Sizes.xs} bottom={Sizes.xs} />
                                </NotificationTextContainer>
                            </Column>

                            <Column md={1} >
                                <ClearContainer clickEvent={() => this.setState({ displayNotification: false })}>
                                    <ClearIcon color={Colors.LightMode} />
                                </ClearContainer>
                            </Column>

                        </NotificationContainer>
                    }
                    {
                        isClient &&
                        <>
                            <Tabbed items={productTabs} descriptionSize={Sizes.md} lg={4} />
                            <InfoContainer>
                                <Header text={`Shop your Renewal right here, in one place`} textAlign={Alignments.left} size={Sizes.md} left={Sizes.sm} top={Sizes.sm} />
                                <Label text={`Toggle between the best rates and pick the one that works for you best. Once you accept the quote, a broker will process it for you, don't worry if you need more changes, your broker can guide you through them. Once completed you will be notified that your documents are ready to sign.`} textAlign={Alignments.left} left={Sizes.sm} bottom={Sizes.sm} />
                            </InfoContainer>
                        </>
                    }
                    {
                        riskChangeSummary &&

                        <InfoContainer top={Sizes.md}>
                            <Header text={`Risk Change Summary`} textAlign={Alignments.left} size={Sizes.md} left={Sizes.sm} top={Sizes.sm} />
                            <BulletList references={riskChangeSummary.map((summary) => ({ code: Math.random().toString(), value: summary }))} size={Sizes.md} />
                        </InfoContainer>
                    }

                    <ProfileRenewalAction {...this.props} />                    
                        {(this.props.policySummary.status === `quoteAccepted` ||
                            this.props.policySummary.status === `bindRequested` ||
                            this.props.policySummary.status === `bound` ||
                            this.props.policySummary.status === `issued`) &&
                            <ProfileRenewalRecap {...this.props} />
                        }
                    
                    <ProfileRenewalRisks {...this.props} risks={this.props.policySummary.risks} />
                    {(this.props.policySummary.status === `quoted` || this.props.policySummary.status === `quoteIssued`) && <ProfileRenewalQuotes {...this.props} updateProgressBar={(status) => this.updateProgressBar(status)} />}
                </Container>
            </Splash>
        );
    }
}

export default ProfileRenewal;