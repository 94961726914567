import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Form,
    FormDate,
    FormInput, FormSwitch
} from 'metrix-common';

class ProfileInterwestGlpcPriorInsurance extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            <Form
                header={<FormattedMessage id='Prior Insurance' />}
                label='Details'
                saveEvent={this.props.doUpsertPolicy}
                cancelEvent={this.props.doGetPolicy}
                allowEdit={this.props.allowEdit}
            >
                <FormSwitch
                    label={<FormattedMessage id='Have you had prior Property Coverage for the last 3 years?' />}
                    value={this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasPriorInsurance ?? false}
                    changeEvent={
                        value => {
                            this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.priorInsuranceInfo.hasPriorInsurance', value: value });
                        }}
                    allowEdit
                />
                {
                    this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasPriorInsurance &&
                    <FormInput
                        label='Current Carrier'
                        value={this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.currentCarrierName}
                        type='text'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.priorInsuranceInfo.currentCarrierName', value })}
                        allowEdit
                    />
                }
                {
                    this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasPriorInsurance &&
                    <FormInput
                        label='Policy Number'
                        value={this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.currentCarrierPolicyNumber}
                        type='number'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.priorInsuranceInfo.currentCarrierPolicyNumber', value })}
                        allowEdit
                    />
                }
                {
                    this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasPriorInsurance &&
                    <FormDate
                        label={<FormattedMessage id='Effective Date' />}
                        value={this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.priorInsuranceCurrentCarrierEffectiveDate}
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.priorInsuranceInfo.priorInsuranceCurrentCarrierEffectiveDate', value })}
                        culture={this.props.context.session.culture}
                        mode='past'
                        allowEdit
                    />
                }
                {
                    this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasPriorInsurance &&
                    <FormSwitch
                        label={<FormattedMessage id='Any claims in past 5 Years?' />}
                        value={this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasClaimsInLastFiveYears ?? false}
                        changeEvent={
                            value => {
                                this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.priorInsuranceInfo.hasClaimsInLastFiveYears', value: value });
                            }}
                        allowEdit
                    />
                }
                {
                    this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasPriorInsurance &&
                    this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasClaimsInLastFiveYears &&
                    <FormDate
                        label={<FormattedMessage id='Date of Claim' />}
                        value={this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.claimOneEffectiveDate}
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.priorInsuranceInfo.claimOneEffectiveDate', value })}
                        culture={this.props.context.session.culture}
                        mode='past'
                        allowEdit
                    />
                }
                {
                    this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasPriorInsurance &&
                    this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasClaimsInLastFiveYears &&
                    <FormInput
                        label='Amount Paid'
                        value={this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.claimOnePaidAmount}
                        type='money'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.priorInsuranceInfo.claimOnePaidAmount', value })}
                        allowEdit
                    />
                }
                {
                    this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasPriorInsurance &&
                    this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasClaimsInLastFiveYears &&
                    <FormInput
                        label='Description of Loss'
                        value={this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.claimOneDescriptionOfLoss}
                        type='text'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.priorInsuranceInfo.claimOneDescriptionOfLoss', value })}
                        allowEdit
                    />
                }
                {
                    this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasPriorInsurance &&
                    this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasClaimsInLastFiveYears &&
                    <FormSwitch
                        label={<FormattedMessage id='Any more claims?' />}
                        value={this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasAnyMoreClaims ?? false}
                        changeEvent={
                            value => {
                                this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.priorInsuranceInfo.hasAnyMoreClaims', value: value });
                            }}
                        allowEdit
                    />
                }
                {
                    this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasPriorInsurance &&
                    this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasClaimsInLastFiveYears &&
                    this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasAnyMoreClaims &&
                    <FormDate
                        label={<FormattedMessage id='Date of Claim' />}
                        value={this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.claimTwoEffectiveDate}
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.priorInsuranceInfo.claimTwoEffectiveDate', value })}
                        culture={this.props.context.session.culture}
                        mode='past'
                        allowEdit
                    />
                }
                {
                    this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasPriorInsurance &&
                    this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasClaimsInLastFiveYears &&
                    this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasAnyMoreClaims &&
                    <FormInput
                        label='Amount Paid'
                        value={this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.claimTwoPaidAmount}
                        type='money'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.priorInsuranceInfo.claimTwoPaidAmount', value })}
                        allowEdit
                    />
                }
                {
                    this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasPriorInsurance &&
                    this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasClaimsInLastFiveYears &&
                    this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasAnyMoreClaims &&
                    <FormInput
                        label='Description of Loss'
                        value={this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.claimTwoDescriptionOfLoss}
                        type='text'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.priorInsuranceInfo.claimTwoDescriptionOfLoss', value })}
                        allowEdit
                    />
                }
                {
                    this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasPriorInsurance &&
                    <FormSwitch
                        label={<FormattedMessage id='Any policy or coverage declined, cancelled or non-renewed during the past 3 years?' />}
                        value={this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasCoverageCancelledInLastThreeYears ?? false}
                        changeEvent={
                            value => {
                                this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.priorInsuranceInfo.hasCoverageCancelledInLastThreeYears', value: value });
                            }}
                        allowEdit
                    />
                }
                {
                    this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasPriorInsurance &&
                    this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasCoverageCancelledInLastThreeYears &&
                    <FormInput
                        label='Carrier Name'
                        value={this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.stoppedCarrierName}
                        type='text'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.priorInsuranceInfo.stoppedCarrierName', value })}
                        allowEdit
                    />
                }
                {
                    this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasPriorInsurance &&
                    this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasCoverageCancelledInLastThreeYears &&
                    <FormInput
                        label='Policy Number'
                        value={this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.stoppedCarrierPolicy}
                        type='text'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.priorInsuranceInfo.stoppedCarrierPolicy', value })}
                        allowEdit
                    />
                }
                {
                    this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasPriorInsurance &&
                    this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasCoverageCancelledInLastThreeYears &&
                    <FormInput
                        label='Coverage stopped reason'
                        value={this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.stoppedReason}
                        type='text'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.priorInsuranceInfo.stoppedReason', value })}
                        allowEdit
                    />
                }
                {
                    this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasPriorInsurance &&
                    this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.hasCoverageCancelledInLastThreeYears &&
                    <FormDate
                        label={<FormattedMessage id='Approximate date coverage was stopped' />}
                        value={this.props.policy.interWestGLPCCustomInfo.priorInsuranceInfo.stoppedAproximateDate}
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.priorInsuranceInfo.stoppedAproximateDate', value })}
                        culture={this.props.context.session.culture}
                        mode='past'
                        allowEdit
                    />
                }
            </Form>
        );
    }
}

export default ProfileInterwestGlpcPriorInsurance;