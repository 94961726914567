import { put, call, select } from 'redux-saga/effects';
import { reduceOperation, reduceLoading, getSessionSaga } from 'metrix-common';
import { selectEntity } from '../../selectors/policy/selectEntity';
import { getPolicySaga } from './getPolicySaga';
import { reduceRiskChanged } from '../../actions/policy/reduceRiskChanged';

export function* updatePlaceSaga({ payload }) {
    try {
        yield put(reduceLoading({ code: 'updatePlace', value: true }));
        const session = yield getSessionSaga();
        const entity = yield select(selectEntity());
        
        const response = yield call(fetch, '/api/place/apply',
            {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': session.authenticationToken.token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    policyTransactionGuid: entity,
                    placeId: payload.placeId
                })
            }
        );
        const result = yield call([response, 'json']);

        if (result.operation.status) {
            const riskChangedParam = result.parameters?.find(param => param.code === 'RiskChanged' && param.value === 'True');
            if (riskChangedParam) {
                yield put(reduceRiskChanged(true));
                window.location.reload();
            } else {
                yield put(reduceRiskChanged(false));
                yield call(getPolicySaga, { payload: entity });
            }
        }

        yield put(reduceOperation(result.operation));
    }
    catch (error) {
        console.error('Error while Updating Place - ', error);
        window.location = '/error';
    } finally {
        yield put(reduceLoading({ code: 'updatePlace', value: false }));
    }
}
