import React from 'react';

class LowUpperScoreIcon extends React.Component {
        
    getIcon = (score) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} viewBox="0 0 138 138">
                <g data-name="LOW 0-30">
                    <path
                        d="M10.89 45.09a62.82 62.82 0 01116.48-.48"
                        fill="none"
                        stroke="#da1c5c"
                        strokeLinecap="round"
                        strokeMiterlimit={10}
                        strokeWidth={10}
                        strokeDasharray="250 10000"
                    />
                    <g data-name="BG Color">
                        <path
                            d="M69 137.08A68.08 68.08 0 11137.08 69 68.16 68.16 0 0169 137.08zm0-126.16A58.08 58.08 0 10127.08 69 58.14 58.14 0 0069 10.92z"
                            fill="#d91c5d"
                            data-name="Gradient Base"
                            opacity={0.15}
                        />
                    </g>
                </g>
                <text x="50%" y="50%" text-anchor="middle" stroke="#da1c5c" fill="#da1c5c" font-family="'Poppins'" stroke-width="2px" dy=".3em" fontSize="70px">{score}</text>                                    
            </svg>
            );
        }  

        render() {
            return (this.getIcon(this.props.score || 0));
        }    
}

export default LowUpperScoreIcon;