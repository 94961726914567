import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Form,
    FormDropDown,
    FormInput, FormSwitch,
    Divider,
    Sizes,
    Header,
    SummaryGroup,
    Alignments,
    Container
} from 'metrix-common';
import ProfileInterwestFpBusinessInfoLocation from './Location';

class ProfileInterwestFpBusinessInfo extends React.Component {
    state = {
        mode: 'locationSummaryView',
        current: null
    };

    getLocations = () => {
        let locations = [];

        if (this.props.policy?.interWestFPCustomInfo?.businessInfo?.locations && this.props.policy.interWestFPCustomInfo.businessInfo.locations.length > 0) {
            this.props.policy.interWestFPCustomInfo.businessInfo.locations.forEach((location) => {
                let option = { code: 'locationOption', value: location.guid };

                let locationName = `${location.locationName || 'New Location'}`;

                option.elements = [];
                option.elements.push({ code: 'Label', value: locationName });

                locations.push(option);
            });
        }

        return locations;
    }

    componentDidMount() {
        this.props.doGetPolicy();
        
        const pageHasTooltipInfo = this.props.tooltipInfo && this.props.tooltipInfo.pages && this.props.tooltipInfo.pages.length && this.props.tooltipInfo.pages.some(p => p.code === this.props.pageCode);
        if (!pageHasTooltipInfo) {
            this.props.doGetTooltipInfo(this.props.policy.product);
        }
    }

    render() {
        return (
            <>
                <Form
                    pageCode={this.props.pageCode}
                    sectionCode='businessInformation'
                    saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                    reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                    tooltipInfo={this.props.tooltipInfo}
                    validationInfo={this.props.validationInfo}
                    header={<FormattedMessage id='Business Information'/>}
                    label='Details'
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormInput
                        fieldCode='businessName'
                        label={<FormattedMessage id='Legal Business Name'/>}
                        value={this.props.policy.interWestFPCustomInfo.businessInfo.businessName}
                        type='text'
                        changeEvent={value => this.props.doReducePolicyElement({
                            code: 'interWestFPCustomInfo.businessInfo.businessName',
                            value
                        })}
                        allowEdit
                    />
                    <FormDropDown
                        fieldCode='businessEntityCode'
                        label={<FormattedMessage id='Entity Type'/>}
                        value={{code: this.props.policy.interWestFPCustomInfo.businessInfo.businessEntityCode}}
                        references={this.props.reference?.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].EntityTypes}
                        referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.EntityTypes')}
                        changeEvent={value => {
                            this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.businessInfo.businessEntityCode',
                                value: value.code
                            });
                        }}
                        allowEdit
                    />
                    <FormInput
                        fieldCode='website'
                        label={<FormattedMessage id='Website'/>}
                        value={this.props.policy.interWestFPCustomInfo.businessInfo.website}
                        type='text'
                        changeEvent={value => this.props.doReducePolicyElement({
                            code: 'interWestFPCustomInfo.businessInfo.website',
                            value
                        })}
                        allowEdit
                    />
                    <FormInput
                        fieldCode='federalTaxIdentificationNumber'
                        label={<FormattedMessage id='FEIN'/>}
                        value={this.props.policy.interWestFPCustomInfo.businessInfo.federalTaxIdentificationNumber}
                        type='number'
                        changeEvent={value => this.props.doReducePolicyElement({
                            code: 'interWestFPCustomInfo.businessInfo.federalTaxIdentificationNumber',
                            value
                        })}
                        overrideMaxLength={9}
                    />
                    <FormInput
                        fieldCode='establishedYearNumber'
                        label={<FormattedMessage id='Established Year'/>}
                        value={this.props.policy.interWestFPCustomInfo.businessInfo.establishedYearNumber}
                        type='number'
                        changeEvent={value => this.props.doReducePolicyElement({
                            code: 'interWestFPCustomInfo.businessInfo.establishedYearNumber',
                            value
                        })}
                        maxValue={9999}
                        allowEdit
                    />
                </Form>
                <Form
                    pageCode={this.props.pageCode}
                    sectionCode='additionalBusinessInfo'
                    saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                    reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                    tooltipInfo={this.props.tooltipInfo}
                    validationInfo={this.props.validationInfo}
                    header={<FormattedMessage id='Additional Business Information'/>}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormInput
                        fieldCode='grossAnnualReceiptsAmount'
                        label={<FormattedMessage id='Gross Annual Receipts'/>}
                        value={this.props.policy.interWestFPCustomInfo.businessInfo.grossAnnualReceiptsAmount}
                        type='money'
                        changeEvent={value => this.props.doReducePolicyElement({
                            code: 'interWestFPCustomInfo.businessInfo.grossAnnualReceiptsAmount',
                            value
                        })}
                        allowEdit
                    />
                </Form>
                <Form
                    pageCode={this.props.pageCode}
                    sectionCode='ownerInformation'
                    saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                    reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                    tooltipInfo={this.props.tooltipInfo}
                    validationInfo={this.props.validationInfo}
                    header={<FormattedMessage id='Owner Information'/>}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormInput
                        fieldCode='ownerInformationOneName'
                        label={<FormattedMessage id='1 Owner Name'/>}
                        value={this.props.policy.interWestFPCustomInfo.businessInfo.ownerInformationOneName}
                        type='text'
                        changeEvent={value => this.props.doReducePolicyElement({
                            code: 'interWestFPCustomInfo.businessInfo.ownerInformationOneName',
                            value
                        })}
                        allowEdit
                    />
                    <FormInput
                        fieldCode='ownerInformationOnePercent'
                        label={<FormattedMessage id='1 Owner Percent'/>}
                        value={this.props.policy.interWestFPCustomInfo.businessInfo.ownerInformationOnePercent}
                        type='number'
                        changeEvent={value => this.props.doReducePolicyElement({
                            code: 'interWestFPCustomInfo.businessInfo.ownerInformationOnePercent',
                            value
                        })}
                        allowEdit
                    />
                    {!(this.props.policy.interWestFPCustomInfo.businessInfo.businessEntityCode === 'SoleProprietorship') &&
                        <FormSwitch
                            fieldCode='hasOwnerInformationMoreThanOne'
                            label={<FormattedMessage id='Is there more than 1 owner?'/>}
                            value={this.props.policy.interWestFPCustomInfo.businessInfo.hasOwnerInformationMoreThanOne ?? false}
                            changeEvent={
                                (value) => {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.businessInfo.hasOwnerInformationMoreThanOne',
                                        value: value,
                                    })
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.businessInfo.ownerInformationTwoName',
                                        value: null,
                                    })
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.businessInfo.ownerInformationTwoPercent',
                                        value: null
                                    })
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.businessInfo.ownerInformationThreeName',
                                        value: null
                                    })
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.businessInfo.ownerInformationThreePercent',
                                        value: null
                                    })
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.businessInfo.ownerInformationFourName',
                                        value: null
                                    })
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.businessInfo.ownerInformationFourPercent',
                                        value: null
                                    })
                                    if (!this.props.policy.interWestFPCustomInfo.businessInfo.hasOwnerInformationMoreThanOne) {
                                        this.props.doReducePolicyElement({
                                            code: 'interWestFPCustomInfo.businessInfo.hasOwnerInformationMoreThanTwo',
                                            value: false
                                        })
                                        this.props.doReducePolicyElement({
                                            code: 'interWestFPCustomInfo.businessInfo.hasOwnerInformationMoreThanThree',
                                            value: false
                                        })
                                    }
                                }
                            }
                            allowEdit
                        />
                    }
                    {
                        this.props.policy.interWestFPCustomInfo.businessInfo.hasOwnerInformationMoreThanOne &&
                        <FormInput
                            fieldCode='ownerInformationTwoName'
                            label={<FormattedMessage id='2 Owner Name'/>}
                            value={this.props.policy.interWestFPCustomInfo.businessInfo.ownerInformationTwoName}
                            type='text'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.businessInfo.ownerInformationTwoName',
                                value
                            })}
                            allowEdit
                        />
                    }
                    {
                        this.props.policy.interWestFPCustomInfo.businessInfo.hasOwnerInformationMoreThanOne &&
                        <FormInput
                            fieldCode='ownerInformationTwoPercent'
                            label={<FormattedMessage id='2 Owner Percent'/>}
                            value={this.props.policy.interWestFPCustomInfo.businessInfo.ownerInformationTwoPercent}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.businessInfo.ownerInformationTwoPercent',
                                value
                            })}
                            allowEdit
                        />
                    }
                    {
                        this.props.policy.interWestFPCustomInfo.businessInfo.hasOwnerInformationMoreThanOne &&
                        <FormSwitch
                            fieldCode='hasOwnerInformationMoreThanTwo'
                            label={<FormattedMessage id='Is there more than 2 owner?'/>}
                            value={this.props.policy.interWestFPCustomInfo.businessInfo.hasOwnerInformationMoreThanTwo ?? false}
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.businessInfo.hasOwnerInformationMoreThanTwo',
                                        value: value
                                    }
                                    )
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.businessInfo.ownerInformationThreeName',
                                        value: null
                                    })
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.businessInfo.ownerInformationThreePercent',
                                        value: null
                                    })
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.businessInfo.ownerInformationFourName',
                                        value: null
                                    })
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.businessInfo.ownerInformationFourPercent',
                                        value: null
                                    })
                                    if (!this.props.policy.interWestFPCustomInfo.businessInfo.hasOwnerInformationMoreThanTwo) {
                                        this.props.doReducePolicyElement({
                                            code: 'interWestFPCustomInfo.businessInfo.hasOwnerInformationMoreThanThree',
                                            value: false
                                        })
                                    }
                                }}
                            allowEdit
                        />
                    }
                    {(this.props.policy.interWestFPCustomInfo.businessInfo.hasOwnerInformationMoreThanOne && this.props.policy.interWestFPCustomInfo.businessInfo.hasOwnerInformationMoreThanTwo)
                        &&
                        <FormInput
                            fieldCode='ownerInformationThreeName'
                            label={<FormattedMessage id='3 Owner Name'/>}
                            value={this.props.policy.interWestFPCustomInfo.businessInfo.ownerInformationThreeName}
                            type='text'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.businessInfo.ownerInformationThreeName',
                                value
                            })}
                            allowEdit
                        />
                    }
                    {
                        (this.props.policy.interWestFPCustomInfo.businessInfo.hasOwnerInformationMoreThanOne && this.props.policy.interWestFPCustomInfo.businessInfo.hasOwnerInformationMoreThanTwo)
                        &&
                        <FormInput
                            fieldCode='ownerInformationThreePercent'
                            label={<FormattedMessage id='3 Owner Percent'/>}
                            value={this.props.policy.interWestFPCustomInfo.businessInfo.ownerInformationThreePercent}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.businessInfo.ownerInformationThreePercent',
                                value
                            })}
                            allowEdit
                        />
                    }
                    {
                        (this.props.policy.interWestFPCustomInfo.businessInfo.hasOwnerInformationMoreThanOne &&
                            this.props.policy.interWestFPCustomInfo.businessInfo.hasOwnerInformationMoreThanTwo) &&
                        <FormSwitch
                            fieldCode='hasOwnerInformationMoreThanThree'
                            label={<FormattedMessage id='Is there more than 3 owner?'/>}
                            value={this.props.policy.interWestFPCustomInfo.businessInfo.hasOwnerInformationMoreThanThree ?? false}
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.businessInfo.hasOwnerInformationMoreThanThree',
                                        value: value
                                    })

                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.businessInfo.ownerInformationFourName',
                                        value: null
                                    })

                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.businessInfo.ownerInformationFourPercent',
                                        value: null
                                    })
                                }}
                            allowEdit
                        />
                    }
                    {
                        (this.props.policy.interWestFPCustomInfo.businessInfo.hasOwnerInformationMoreThanOne &&
                            this.props.policy.interWestFPCustomInfo.businessInfo.hasOwnerInformationMoreThanTwo &&
                            this.props.policy.interWestFPCustomInfo.businessInfo.hasOwnerInformationMoreThanThree) &&
                        <FormInput
                            fieldCode='ownerInformationFourPercent'
                            label={<FormattedMessage id='4 Owner Name'/>}
                            value={this.props.policy.interWestFPCustomInfo.businessInfo.ownerInformationFourName}
                            type='text'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.businessInfo.ownerInformationFourName',
                                value
                            })}
                            allowEdit
                        />
                    }
                    {
                        (this.props.policy.interWestFPCustomInfo.businessInfo.hasOwnerInformationMoreThanThree &&
                            this.props.policy.interWestFPCustomInfo.businessInfo.hasOwnerInformationMoreThanOne &&
                            this.props.policy.interWestFPCustomInfo.businessInfo.hasOwnerInformationMoreThanTwo
                        ) &&
                        <FormInput
                            fieldCode='ownerInformationFourPercent'
                            label={<FormattedMessage id='4 Owner Percent'/>}
                            value={this.props.policy.interWestFPCustomInfo.businessInfo.ownerInformationFourPercent}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.businessInfo.ownerInformationFourPercent',
                                value
                            })}
                            allowEdit
                        />
                    }
                </Form>
                <Form
                    pageCode={this.props.pageCode}
                    sectionCode='employeeInformation'
                    saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                    reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                    tooltipInfo={this.props.tooltipInfo}
                    validationInfo={this.props.validationInfo}
                    header={<FormattedMessage id='Employee Information'/>}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormSwitch
                        fieldCode='hasEmployees'
                        label={<FormattedMessage id='Do you have employees?'/>}
                        value={this.props.policy.interWestFPCustomInfo.businessInfo.hasEmployees ?? false}
                        changeEvent={
                            value => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.businessInfo.hasEmployees',
                                    value: value
                                })
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.businessInfo.employeeFullTimeCount',
                                    value: null
                                })

                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.businessInfo.employeePartTimeCount',
                                    value: null
                                })
                            }}
                        allowEdit
                    />
                    {this.props.policy.interWestFPCustomInfo.businessInfo.hasEmployees &&
                        <FormInput
                            fieldCode='employeeFullTimeCount'
                            label={<FormattedMessage id='Full-time Employee Count'/>}
                            value={this.props.policy.interWestFPCustomInfo.businessInfo.employeeFullTimeCount}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.businessInfo.employeeFullTimeCount',
                                value
                            })}
                            allowEdit
                        />
                    }
                    {this.props.policy.interWestFPCustomInfo.businessInfo.hasEmployees &&
                        <FormInput
                            fieldCode='employeePartTimeCount'
                            label={<FormattedMessage id='Part-time Employee Count'/>}
                            value={this.props.policy.interWestFPCustomInfo.businessInfo.employeePartTimeCount}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.businessInfo.employeePartTimeCount',
                                value
                            })}
                            allowEdit
                        />
                    }
                </Form>
                {
                    this.state.mode === 'locationSummaryView' &&
                    <React.Fragment>
                        <Divider top={Sizes.xl} />
                        <Header size={Sizes.lg} textAlign={Alignments.left} text='Locations' />
                        <Container top={Sizes.xl} bottom={Sizes.xl}>
                            <SummaryGroup
                                allowAdd={this.props.allowEdit}
                                addEvent={() => {
                                    this.props.doReduceParameters([]);
                                    this.props.doExecutePolicyAction({ code: 'INTERWEST-FP.CREATELOCATION' });
                                }}
                                allowEdit
                                editEvent={location => {
                                    this.setState({ current: location, mode: 'locationView' });
                                    this.props.doReduceParameters([{ code: 'LocationGuid', value: location }]);
                                }}
                                allowDelete={this.props.allowEdit}
                                deleteEvent={location => {
                                    this.props.doReduceParameters([{ code: 'LocationGuid', value: location }]);
                                    this.props.doExecutePolicyAction({ code: 'INTERWEST-FP.DELETELOCATION' });
                                }}
                                entities={this.getLocations()}
                                icon='home'
                            />
                        </Container>
                    </React.Fragment>
                }
                {
                    this.state.mode === 'locationView' &&
                    <ProfileInterwestFpBusinessInfoLocation
                        pageCode={this.props.pageCode}
                        sectionCode='locations'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        location={this.props.policy.interWestFPCustomInfo.businessInfo.locations.find(x => x.guid === this.state.current)}
                        dotProp={`interWestFPCustomInfo.businessInfo.locations.${this.props.policy.interWestFPCustomInfo.businessInfo.locations.findIndex(x => x.guid === this.state.current)}.`}
                        backEvent={() => this.setState({ mode: 'locationSummaryView', current: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReduceParameter={this.props.doReduceParameter}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        doExecutePolicyAction={this.props.doExecutePolicyAction}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
            </>
        )
    }
}

export default ProfileInterwestFpBusinessInfo