import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Sizes,
    Alignments,
    Grid,
    Row,
    Column,
    Header,
    Container,
    Alert,
    Label,
    Link,
    PrimaryButton,
    GenericModal,
    Tabbed,
    getElementValueByCode,
    Colors,
    getScreenSize,
    isMobile as tellIfMobile,
} from 'metrix-common';

import ProfileSummaryAction from '../Action';
import SelectionItems from './selectionItems.js';

function groupCoverages(coverages) {
    let groupedCoverages = [];

    coverages.forEach(function (coverage) {
        var group = groupedCoverages.find(x => x.code === coverage.coverageGroup.code);
        if (!group) {
            group = {
                code: coverage.coverageGroup.code,
                sequence: coverage.coverageGroup.value,
                coverages: []
            }
            groupedCoverages.push(group);
        }

        group.coverages.push(coverage);
    });

    return groupedCoverages;
}

class ProfileQuotes extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentQuote: 0,
            acceptPopup: false
        };
    };

    componentDidMount() {
        if (this.props.reference &&
            this.props.reference.doGetReferences &&
            this.props.reference.references &&
            !this.props.reference.references[`Insurer`]) {
            this.props.reference.doGetReferences(`Insurer`);
        }
    }

    getQuoteTabs = () => {
        let quoteTabs = [];

        if (this.props.policySummary &&
            this.props.policySummary.quotes &&
            this.props.policySummary.quotes.length > 0) {
            this.props.policySummary.quotes.map((quote) => {
                if (this.props.reference &&
                    this.props.reference.references &&
                    this.props.reference.references[`Insurer`])
                quoteTabs.push({
                    code: getElementValueByCode(this.props.reference.references[`Insurer`], quote.insurer) || quote.title,
                    description: quote.formattedEstimatedAnnualGross || `N/A`
                });
            });
        }

        return quoteTabs;
    }

    getIncluded = (coverageSummary, coverage, dotProp, mode) => {
        return <SelectionItems coverageSummary={coverageSummary} status={coverage.status} coverage={coverage} dotPropUpdated={dotProp} mode={mode} {...this.props} />
    }

    render() {
        const isAcceptQuoteBtnHidden = this.props.productSettings?.isSingleQuote;
        const isCoverageDetailsHidden = this.props.productSettings?.coverageDetailsHiddenInSummary;
        let screenSize = getScreenSize();
        const isMobile = tellIfMobile(screenSize);

        let isClient = (this.props.context.session.userType === `client`);
        let quotesCount = this.props.policySummary.quotes && (this.props.policySummary.quotes.length || 0);
        let quotes = (this.props.policySummary.quotes && this.props.policySummary.quotes.length > 0) && this.props.policySummary.quotes;
        let currentQuote = quotes && quotes[this.state.currentQuote];

        let coveragesSummary = (currentQuote && currentQuote.risks && currentQuote.risks.length > 0 && currentQuote.risks[0]?.coverages) && currentQuote.risks[0].coverages || [];

        let lgTabs =
            quotesCount === 1
                ? 12
                : quotesCount === 2 || quotesCount === 4
                    ? 6
                    : 4;

        let amounts = {
            estimatedAnnualPremium: currentQuote && currentQuote.formattedEstimatedAnnualPremium || 0,
            estimatedAnnualGross: currentQuote && currentQuote.formattedEstimatedAnnualGross || 0,
            estimatedMonthlyGross: currentQuote && currentQuote.formattedEstimatedMonthlyGross || 0
        };

        let estimatedTaxes = currentQuote && currentQuote.estimatedTaxes && currentQuote.estimatedTaxes.value || 0;

        let groupedCoverages = groupCoverages(coveragesSummary);

        return (
            <Container bottom={Sizes.xs}>
                {currentQuote &&
                    <React.Fragment>
                        <ProfileSummaryAction {...this.props} policySummary={this.props.policySummary} />
                        <Container>
                            <Tabbed items={this.getQuoteTabs()} clickEvent={(value) => this.setState({ currentQuote: value })} descriptionSize={Sizes.md} lg={lgTabs} />
                        </Container>
                        {(this.props.context.session.userType === `broker` || this.props.context.session.userType === `administrator`) && 
                            <React.Fragment>
                                {currentQuote.messages && currentQuote.messages.length > 0 &&
                                    <Alert
                                        mode={`danger`}
                                        header={<FormattedMessage id={`app.components.Profile.Summary.messagesHeader`} />}
                                        items={currentQuote.messages} />
                                }
                                {currentQuote.discounts &&
                                    currentQuote.discounts.length > 0 &&
                                    <Alert
                                        mode={`success`}
                                        header={<FormattedMessage id={`app.components.Profile.Summary.discounts`} />}
                                        items={currentQuote.discounts.map((x) => {
                                            return {
                                                code: x.code,
                                                value: `${x.code} - ${x.description}`
                                            }
                                        })} />
                                }
                                {currentQuote.surcharges &&
                                    currentQuote.surcharges.length > 0 &&
                                    <Alert
                                        mode={`info`}
                                        header={<FormattedMessage id={`app.components.Profile.Summary.surcharges`} />}
                                        items={currentQuote.surcharges.map((x) => {
                                            return {
                                                code: x.code,
                                                value: `${x.code} - ${x.description}`
                                            }
                                        })} />
                            }
                            </React.Fragment>
                        }
                        <Container color={Colors.LightMode}>
                            <Grid>
                                <Row>
                                    <Column xs={1} md={2} />
                                    <Column xs={10} md={8}>
                                        <Header size={Sizes.md} bottom={Sizes.xs} text={estimatedTaxes > 0 && <FormattedMessage id={`app.components.RecapCard.taxesIncluded`} values={amounts} /> || amounts.estimatedAnnualGross} />
                                        {!this.props.policy.isSinglePayment && <Header size={Sizes.sm} bottom={Sizes.xs} text={<FormattedMessage id={`app.components.RecapCard.savings`} values={amounts} />} />}
                                        {
                                            currentQuote.portalUrl ?
                                                <Link size={Sizes.md} bottom={Sizes.xs} text={currentQuote.referenceNumber} href={currentQuote.portalUrl} newTab />
                                                :
                                                <Label size={Sizes.md} bottom={Sizes.xs} text={currentQuote.referenceNumber} />
                                        }
                                        {
                                            ((this.props.policySummary.status === `quoted` || this.props.policySummary.status === `quoteIssued`) && !isAcceptQuoteBtnHidden) &&
                                            <Container inline horizontalAlignment={Alignments.center}>
                                                <PrimaryButton
                                                    disabled={(!(currentQuote.estimatedAnnualGross && currentQuote.estimatedAnnualGross.value > 0))} bottom={Sizes.md}
                                                    text={`Accept Quote`} clickEvent={() => {
                                                        if (isClient) {
                                                            this.setState({ acceptPopup: true });
                                                        } else {
                                                            this.props.doReduceParameter({ code: `AcceptedQuote`, value: currentQuote.guid });
                                                            this.props.doExecutePolicyAction({ code: `ACCEPTQUOTE` });
                                                            }
                                                    }}
                                                />
                                            </Container>
                                        }
                                        {
                                            currentQuote.referralReasons && currentQuote.referralReasons.length > 0 &&
                                                <Alert
                                                    header={`Referral Reasons`}
                                                    items={currentQuote.referralReasons.map((x, id) => ({ code: id, value: x }))} />
                                        }
                                        {
                                            this.state.acceptPopup && isClient &&
                                            <GenericModal
                                                animate
                                                visible
                                                closeEvent={() => this.setState({ acceptPopup: false })}
                                                items={
                                                    <React.Fragment>
                                                        <Container>
                                                            <Header text={`Accepting a quote`} size={Sizes.lg} bottom={Sizes.md} />
                                                            <Label text={`Your documents will be sent to your broker to be processed. Are you sure?`} bold textAlign={Alignments.left} bottom={Sizes.md} />
                                                            <Label text={`You will be notified by email when they are ready to sign, as soon as possible.`} textAlign={Alignments.left} bottom={Sizes.xs} />
                                                        </Container>
                                                    </React.Fragment>
                                                }
                                                actionButtons={[
                                                    {
                                                        text: <FormattedMessage id="covera.common.organisms.confirmationModal.yes" />,
                                                        clickEvent: () => {
                                                            this.props.doReduceParameter({ code: `AcceptedQuote`, value: currentQuote.guid });
                                                            this.props.doExecutePolicyAction({ code: `ACCEPTQUOTE` });
                                                            this.props.updateProgressBar && this.props.updateProgressBar(`quoteAccepted`);
                                                            this.setState({ acceptPopup: false });
                                                        }
                                                    },
                                                    {
                                                        text: <FormattedMessage id="covera.common.general.cancel" />,
                                                        clickEvent: () => { this.setState({ acceptPopup: false }) }
                                                    }
                                                ]}
                                            />
                                        }
                                    </Column>
                                    <Column xs={1} md={2} />
                                </Row>
                            </Grid>
                        </Container>

                    {
                        groupedCoverages &&
                            groupedCoverages.map((coverageGroup) => {
                                return (
                                    <React.Fragment>
                                        {coverageGroup.coverages.length > 0 && <Header size={isMobile ? Sizes.sm : Sizes.md} top={Sizes.sm} textAlign={Alignments.left} text={coverageGroup.code} />}
                                        {!isCoverageDetailsHidden && coverageGroup.coverages.length > 0 && coverageGroup.coverages.map((coverageSummary) => {
                                            const coverage = coveragesSummary.find(x => x.coverageGuid === coverageSummary.coverageGuid);
                                            let dotProp = [`${this.props.dotProp}coverages.${coveragesSummary.findIndex(x => x.coverageGuid === coverage.coverageGuid)}.`];

                                            var coverageType = `included`;
                                            if (coverageSummary.type !== `Mandatory` && (coverageSummary.grossPremium && coverageSummary.grossPremium.value !== 0)) {
                                                coverageType = `include`;
                                            }

                                            return (this.getIncluded(coverageSummary, coverage, dotProp, coverageType));
                                        })}
                                    </React.Fragment>
                                );
                            })
                    }

                    </React.Fragment>
                }
            </Container>
        );
    }
}

export default ProfileQuotes;
