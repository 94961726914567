import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
    Alignments,
    Sizes,
    Colors,
    Row,
    Column,
    Header,
    Grid,
    isMobile as isMobileDisplay,
    isTablet as isTabletDisplay,
    Container
} from 'metrix-common';

import Icon from './Icon';
import CoverageToolTip from './ToolTip';

const OptionContainer = styled(Container)`
    border: 1px solid ${Colors.StrokeDark};
    background: ${Colors.LightMode};
`;

const InnerContainer = styled(Container)`
    ${({ isMobile }) => isMobile ? `height: 70px` : `height: 80px`};    
`;

class SelectionItems extends Component {
    constructor(props) {
        super(props);
    }

    getIcon = (coverage) => {
        return (<Icon code={coverage} />);
    }

    render() {

        const isMobile = isMobileDisplay(this.context.screenSize);
        const isTablet = isTabletDisplay(this.context.screenSize);

        let code = this.props.coverageSummary.coverageCode;
        let icon = this.getIcon(code);
        let header = this.props.coverageSummary.label;

        let formattedPremiumAmount = this.props.coverage.formattedGrossPremium;
        return (
            <OptionContainer key={code} top={Sizes.xxs} bottom={Sizes.xxs} >
                <Grid>
                    <Container>
                        <Row>
                            <Column xs={2} md={1.5} lg={1} gutterLeft={isMobile ? 5 : 20}>
                                <InnerContainer fullSize verticalAlignment={Alignments.center} isMobile={isMobile}>
                                    {icon}
                                </InnerContainer>
                            </Column>
                            <Column xs={1.5} md={0.5} lg={1}>
                                <InnerContainer fullSize verticalAlignment={Alignments.center} horizontalAlignment={Alignments.center} isMobile={isMobile}>
                                    <CoverageToolTip mode={`modal`} code={code} title={header} />
                                </InnerContainer>
                            </Column>
                            <Column xs={6} md={8} lg={8}>
                                <InnerContainer fullSize verticalAlignment={Alignments.center} isMobile={isMobile}>
                                    <Header size={isMobile ? Sizes.xxs : Sizes.sm} textAlign={Alignments.left} text={header} />
                                </InnerContainer>
                            </Column>
                            <Column xs={2} md={2} lg={2} gutterRight={isMobile ? 5 : 20}>
                                <InnerContainer fullSize verticalAlignment={Alignments.center} isMobile={isMobile}>
                                    <Header size={isMobile ? Sizes.xxs : Sizes.sm} textAlign={Alignments.right} text={formattedPremiumAmount} />
                                </InnerContainer>
                            </Column>
                        </Row>
                    </Container>
                </Grid>
            </OptionContainer>);
    }
}

SelectionItems.contextTypes = {
    screenSize: PropTypes.string
};

export default SelectionItems;