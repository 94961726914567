
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {    
    BackButton,
    Form, 
    FormInput,
    FormDropDown,
    FormSwitch,
    FormLabel
} from 'metrix-common';

class ProfileFluxExposure extends React.Component {
    state = {
        mode: `exposureView`,
        current: null
    };

    getExposureName = () => {
        let exposureName = null;

        if (this.props.exposure &&
            this.props.exposure.exposureName) {
            exposureName = `${this.props.exposure.exposureName}`;
        }

        return exposureName;
    }

    render() {
        let exposureName = this.getExposureName() || `New Operation`;
        return (
            <React.Fragment>
                {this.state.mode === `exposureView` &&
                    <React.Fragment>
                        <BackButton clickEvent={this.props.backEvent} />                        
                        <Form
                            header={<FormattedMessage id={exposureName} />}
                            label={`Operation / Exposure Details`}
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            allowEdit={this.props.allowEdit}
                        >
                            <FormInput
                                label={<FormattedMessage id={`Operation / Exposure Name`} />}
                                value={this.props.exposure &&
                                    this.props.exposure.exposureName}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}exposureName`, value })}
                                allowEdit
                            />                                                       
                        </Form>  
                      
                        <Form
                            header={<FormattedMessage id={`General Liability Rating Questions`} />}
                            label={`Details`}
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            allowEdit={this.props.allowEdit}
                        >

                            <FormDropDown
                                label={<FormattedMessage id={`Please select a classification occurring at this location or an exposure requiring GL coverage?`} />}
                                value={this.props.exposure && this.props.exposure.glClassificationCode && { code: this.props.exposure.glClassificationCode, value: this.props.exposure?.glClassificationName }}
                                references={this.props.reference.references && this.props.reference.references[`FLUX-GL`] && this.props.reference.references[`FLUX-GL`][`GLClassification`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`FLUX-GL.GLClassification`)}
                                changeEvent={(value) => {
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}glClassificationCode`, value: value.code });
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}glClassificationName`, value: value.value });
                                }}
                                allowEdit
                            />
                        {
                            (this.props.exposure.glClassificationCode === '10100'
                            || this.props.exposure.glClassificationCode === '15699a'
                            || this.props.exposure.glClassificationCode === '16604'
                            || this.props.exposure.glClassificationCode === '52911'
                            || this.props.exposure.glClassificationCode === '51315'
                            || this.props.exposure.glClassificationCode === '54444'
                            || this.props.exposure.glClassificationCode === '61212'
                            || this.props.exposure.glClassificationCode === '65781'
                            || this.props.exposure.glClassificationCode === '68706'
                            || this.props.exposure.glClassificationCode === '97002'
                            || this.props.exposure.glClassificationCode === '68606'
                            || this.props.exposure.glClassificationCode === '91583'
                            || this.props.exposure.glClassificationCode === '91585')
                            &&
                            <FormInput
                                label={<FormattedMessage id={`Square footage of the building applicable for this exposure.`} />}
                                value={this.props.exposure?.occupiedSquareFootageAmount}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}occupiedSquareFootageAmount`, value })}
                                type='number'
                                allowEdit
                            />
                        }
                        {
                            (this.props.exposure.glClassificationCode === '11039'
                            || this.props.exposure.glClassificationCode === '12375'
                            || this.props.exposure.glClassificationCode === '14279'
                            || this.props.exposure.glClassificationCode === '13720'
                            || this.props.exposure.glClassificationCode === '16750'
                            || this.props.exposure.glClassificationCode === '18707'
                            || this.props.exposure.glClassificationCode === '18708'
                            || this.props.exposure.glClassificationCode === '45900'
                            || this.props.exposure.glClassificationCode === '47474'
                            || this.props.exposure.glClassificationCode === '97047'
                            || this.props.exposure.glClassificationCode === '97050'
                            || this.props.exposure.glClassificationCode === '99793'
                            || this.props.exposure.glClassificationCode === '94099')
                            &&
                            <FormInput
                                label={<FormattedMessage id={`What is total sales for this exposure?`} />}
                                value={this.props.exposure &&
                                    this.props.exposure.salesAmount}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}salesAmount`, value })}
                                type='currency'
                                allowEdit
                            />
                        }
                        {
                            (this.props.exposure.glClassificationCode === '15699b' || this.props.exposure.glClassificationCode === '15699c') &&
                            <FormInput
                                label={<FormattedMessage id={`How many acres are used for cultivation?`} />}
                                value={this.props.exposure?.acreage}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}acreage`, value })}
                                type='number'
                                allowEdit
                            />
                        }
                        {
                            (this.props.exposure.glClassificationCode === '61224'
                            || this.props.exposure.glClassificationCode === '66561'
                            || this.props.exposure.glClassificationCode === '60010'
                            || this.props.exposure.glClassificationCode === '63010'
                            || this.props.exposure.glClassificationCode === '49950'
                            || this.props.exposure.glClassificationCode === '49950-1')
                            &&
                            <FormInput
                                label={<FormattedMessage id={`How many offices / medical offices / dwellings / apartment units are there at this location?`} />}
                                value={this.props.exposure &&
                                    this.props.exposure.unitCount}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}unitCount`, value })}
                                type='number'
                                allowEdit
                            />
                        }
                        </Form>
       
                        <Form
                            header={<FormattedMessage id={`Property Exposure Rating Questions`} />}
                            label={`Details`}
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            allowEdit={this.props.allowEdit}
                        >
                            <FormDropDown
                                label={<FormattedMessage id={`Please select a classification occurring at this location or an exposure requiring Property coverage?`} />}
                                value={this.props.exposure && this.props.exposure.propertyClassificationCode && { code: this.props.exposure.propertyClassificationCode, value: this.props.exposure.propertyClassificationName }}
                                references={this.props.reference.references && this.props.reference.references[`FLUX-GL`] && this.props.reference.references[`FLUX-GL`][`PropertyClassification`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`FLUX-GL.PropertyClassification`)}
                                changeEvent={(value) => {
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}propertyClassificationCode`, value: value.code });
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}propertyClassificationName`, value: value.value });
                                }}
                                allowEdit
                            />

                            <FormInput
                                label={<FormattedMessage id={`What is the value of personal property?`} />}
                                value={this.props.exposure &&
                                    this.props.exposure.businessPersonalPropertyAmount}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}businessPersonalPropertyAmount`, value })}
                                type='currency'                                
                                allowEdit
                            />

                            <FormInput
                                label={<FormattedMessage id={`What is the estimated coverage amount needed for business interruption?`} />}
                                value={this.props.exposure &&
                                    this.props.exposure.businessInterruptionAmount}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}businessInterruptionAmount`, value })}
                                type='currency'
                                allowEdit
                            />

                            <FormDropDown
                                label={<FormattedMessage id={`Amount of limit available any given month during the period of restoration:`} />}
                                value={this.props.exposure && this.props.exposure.limitAvailableDuringRestorationPeriodCode && { code: this.props.exposure?.limitAvailableDuringRestorationPeriodCode, value: this.props.exposure?.limitAvailableDuringRestorationPeriodName }}
                                references={this.props.reference.references && this.props.reference.references[`FLUX-GL`] && this.props.reference.references[`FLUX-GL`][`RestorationLimit`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`FLUX-GL.RestorationLimit`)}
                                changeEvent={(value) => {
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}limitAvailableDuringRestorationPeriodCode`, value: value.code });
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}limitAvailableDuringRestorationPeriodName`, value: value.value });
                                }}
                                allowEdit
                            />

                            <FormInput
                                label={<FormattedMessage id={`What is the value of tenant improvements and betterments?`} />}
                                value={this.props.exposure &&
                                    this.props.exposure.tenantImprovementBettermentAmount}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}tenantImprovementBettermentAmount`, value })}
                                type='currency'                                
                                allowEdit
                            />

                            <FormInput
                                label={<FormattedMessage id={`What is the value of finished stock?`} />}
                                value={this.props.exposure &&
                                    this.props.exposure.finishedStockAmount}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}finishedStockAmount`, value })}
                                type='currency'                               
                                allowEdit
                            />
                        </Form>

                        <Form
                            header={<FormattedMessage id={`Property Exposure - Supplementals / Endorsements`} />}
                            label={`Details`}
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            allowEdit={this.props.allowEdit}
                        >                            
                            <FormInput
                                label={<FormattedMessage id={`What is the estimated coverage amount needed for equipment breakdown?`} />}
                                value={this.props.exposure &&
                                    this.props.exposure.equipmentBreakdownAmount}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}equipmentBreakdownAmount`, value })}
                                type='currency'
                                allowEdit
                            />

                            <FormInput
                                label={<FormattedMessage id={`What is the estimated coverage amount needed for completed stock? - Completed Stock is defined as Manufactured Products ready for sale or packaged and sealed inventory containing marijuana buds and/or its derivatives. No harvested or growing plants fall under this category.`} />}
                                value={this.props.exposure &&
                                    this.props.exposure.completedStockAmount}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}completedStockAmount`, value })}
                                type='currency'
                                allowEdit
                            />

                            <FormInput
                                label={<FormattedMessage id={`What is the estimated coverage amount needed for goods in process? - Goods in Process is defined as Cannabis Buds and Flowers that have been harvested and are in the curing phase of production. No Stock, crop or growing plants fall under this category`} />}
                                value={this.props.exposure &&
                                    this.props.exposure.goodsInProgressAmount}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}goodsInProgressAmount`, value })}
                                type='currency'
                                allowEdit
                            />

                            <FormSwitch
                                label={<FormattedMessage id={`Does this property have a triple net lease?`} />}
                                value={this.props.exposure && this.props.exposure?.hasTripleNetLease}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}hasTripleNetLease`, value: value })}
                                allowEdit
                            />
                     
                            <FormInput
                                label={<FormattedMessage id={`Property Deductible?`} />}
                                value={this.props.exposure &&
                                    this.props.exposure?.propertyDeductibleAmount}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}propertyDeductibleAmount`, value })}
                                type='number'
                                allowEdit
                            />

                            <FormInput
                                label={<FormattedMessage id={`Property Coinsurance?`} />}
                                value={this.props.exposure &&
                                    this.props.exposure.propertyCoinsuranceAmount}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}propertyCoinsuranceAmount`, value })}
                                type='number'
                                allowEdit
                            />
                         
                            <FormDropDown
                                label={<FormattedMessage id={`Ordinance or Law:`} />}
                                value={this.props.exposure && this.props.exposure.ordinanceLawCoverageTypeCode && { code: this.props.exposure?.ordinanceLawCoverageTypeCode, value: this.props.exposure?.ordinanceLawCoverageTypeName}}
                                references= {this.props.reference.references && this.props.reference.references[`FLUX-GL`] && this.props.reference.references[`FLUX-GL`][`OrdinanceLawCoverage`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`FLUX-GL.OrdinanceLawCoverage`)}
                                changeEvent={(value) => {
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}ordinanceLawCoverageTypeCode`, value: value.code });
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}ordinanceLawCoverageTypeName`, value: value.value });
                                }}
                                allowEdit
                            />

                            <FormLabel
                                text={`Coverage A - Coverage to loss of the Undamaged Portion of the Building.`}
                            />
                            <FormLabel
                                text={`Coverage B - Demolition Cost Coverage`}
                            />
                            <FormLabel
                                text={`Coverage C - Increased Cost of Construction Coverage / Recovery Costs`}
                            />

                            <FormInput
                                label={<FormattedMessage id={`Ordinance or Law Coverage Amount?`} />}
                                value={this.props.exposure &&
                                    this.props.exposure.ordinanceLawCoverageAmount}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}ordinanceLawCoverageAmount`, value })}
                                type='number'
                                allowEdit
                            />
                        </Form>

                        <Form
                            header={<FormattedMessage id={`Crop Coverage Table`} />}
                            label={`Details`}
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            allowEdit={this.props.allowEdit}
                        >
                           
                            <FormInput
                                label={<FormattedMessage id={`Number of seedling plants:`} />}
                                value={this.props.exposure &&
                                    this.props.exposure?.numberOfSeedlingPlants}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}numberOfSeedlingPlants`, value })}
                                type='number'
                                allowEdit
                            />

                            <FormInput
                                label={<FormattedMessage id={`Value of each seedling plant:`} />}
                                value={this.props.exposure &&
                                    this.props.exposure?.valueOfOneSeedlingPlant}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}valueOfOneSeedlingPlant`, value })}
                                type='number'
                                allowEdit
                            />

                            <FormInput
                                label={<FormattedMessage id={`Seedling plants total coverage amount:`} />}
                                value={this.props.exposure &&
                                    this.props.exposure?.seedlingPlantsCoverageAmount}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}seedlingPlantsCoverageAmount`, value })}
                                type='number'                                
                            />

                            <FormInput
                                label={<FormattedMessage id={`Number of vegetative plants:`} />}
                                value={this.props.exposure &&
                                    this.props.exposure?.numberOfVegetativePlants}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}numberOfVegetativePlants`, value })}
                                type='number'
                                allowEdit
                            />

                            <FormInput
                                label={<FormattedMessage id={`Value of each vegetative plant:`} />}
                                value={this.props.exposure &&
                                    this.props.exposure.valueOfOneVegetativePlant}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}valueOfOneVegetativePlant`, value })}
                                type='number'
                                allowEdit
                            />

                            <FormInput
                                label={<FormattedMessage id={`Vegetative plants total coverage amount:`} />}
                                value={this.props.exposure &&
                                    this.props.exposure.vegetativePlantsCoverageAmount}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}vegetativePlantsCoverageAmount`, value })}
                                type='number'                                
                            />

                            <FormInput
                                label={<FormattedMessage id={`Number of flowering plants:`} />}
                                value={this.props.exposure &&
                                    this.props.exposure?.numberOfFloweringPlants}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}numberOfFloweringPlants`, value })}
                                type='number'
                                allowEdit
                            />

                            <FormInput
                                label={<FormattedMessage id={`Value of each flowering plant:`} />}
                                value={this.props.exposure &&
                                    this.props.exposure.valueOfOneFloweringPlant}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}valueOfOneFloweringPlant`, value })}
                                type='number'
                                allowEdit
                            />

                            <FormInput
                                label={<FormattedMessage id={`Flowering plants total coverage amount`} />}
                                value={this.props.exposure &&
                                    this.props.exposure.floweringPlantsCoverageAmount}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}floweringPlantsCoverageAmount`, value })}
                                type='number'                                
                            />
                            
                        </Form>
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default ProfileFluxExposure;