import React from 'react';

import {
    Sizes,
    Alignments,
    Container,
    Divider,
    Header,
    SummaryGroup
} from 'metrix-common';

import ProfileDwelling from './Dwelling';

class ProfileDwellings extends React.Component {
    state = {
        mode: `summary`,
        current: null
    };

    componentDidMount() {
        this.props.doGetPolicy();
    }

    getDwellings = () => {
        let dwellings = [];

        if (this.props.policy &&
            this.props.policy.dwellings &&
            this.props.policy.dwellings.length > 0) {
            this.props.policy.dwellings.forEach((dwelling) => {
                let option = { code: `dwelling`, value: dwelling.guid };

                let dwellingName = `Dwelling ${dwelling.number || ``}`;

                if (dwelling.construction && dwelling.construction.yearBuilt && dwelling.construction && dwelling.construction.type && dwelling.construction.type.value) {
                    dwellingName = `${dwelling.construction.yearBuilt} ${dwelling.construction.type.value}`;
                }

                option.elements = [];

                option.elements.push({ code: `Label`, value: dwellingName });

                dwellings.push(option);
            });
        }

        return dwellings;
    }

    render() {
        let dwellings = this.getDwellings();
        return (
            <Container>
                {this.state.mode === `summary` &&
                    <React.Fragment>
                        <Divider top={Sizes.xl} />
                        <Header size={Sizes.lg} textAlign={Alignments.left} text={`Dwellings`} />
                        <Container top={Sizes.xl} bottom={Sizes.xl}>
                            <SummaryGroup
                                allowAdd={this.props.allowEdit}
                                addEvent={() => {
                                    this.props.doReduceParameters([]);
                                    this.props.doExecutePolicyAction({ code: `CREATEDWELLING` });
                                }}
                                allowEdit
                                editEvent={(dwelling) => {
                                    this.setState({ current: dwelling, mode: `dwelling` });
                                    this.props.doReduceParameters([{ code: `Dwelling`, value: dwelling }]);
                                }}
                                allowDelete={this.props.allowEdit}
                                deleteEvent={(dwelling) => {
                                    this.props.doReduceParameters([{ code: `Dwelling`, value: dwelling }]);
                                    this.props.doExecutePolicyAction({ code: `DELETEDWELLING` });
                                }}
                                entities={dwellings}
                                icon={`home`}
                            />
                        </Container>
                    </React.Fragment>
                }
                {this.state.mode === `dwelling` &&
                    <ProfileDwelling dwelling={this.props.policy.dwellings.find(x => x.guid === this.state.current)}
                        dotProp={`dwellings.${this.props.policy.dwellings.findIndex(x => x.guid === this.state.current)}.`}
                        backEvent={() => this.setState({ mode: `summary`, current: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        doReduceParameter={this.props.doReduceParameter}
                        doExecutePolicyAction={this.props.doExecutePolicyAction}
                        reference={this.props.reference}
                        context={this.props.context}
                         allowEdit={this.props.allowEdit}
                    />
                }
            </Container>
        );
    }
}

export default ProfileDwellings;