import { put, call } from 'redux-saga/effects';
import { reduceOperation, reduceLoading, getSessionSaga } from 'metrix-common';

export function* executePolicyTransactionCustomActionSaga({ payload }) {
    try {
        yield put(reduceLoading({ code: 'executePolicyTransactionCustomAction', value: true }));
        const session = yield getSessionSaga();
        
        const response = yield call(fetch, '/api/PolicyTransactionCustomAction/IssueQuoteAndSendPaymentLink', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Authorization': session.authenticationToken.token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        
        let result = yield call([response, 'json']);

        yield put(reduceOperation(result.operation));

        window.location.reload();
    } catch (error) {
        console.log('Error while executing Policy Transaction Custom Action', error);
    } finally {
        yield put(reduceLoading({ code: 'executePolicyTransactionCustomAction', value: false }));
    }
}