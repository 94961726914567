import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Alignments,
    Form,
    FormInput,
    FormDropDown,
    Sizes,
    Label,
    GenericModal,
    Header,
    FormSwitch
} from 'metrix-common';

class ProfileEmployersWcBusiness extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ybe: undefined,
            showPopup: false
        };
    };

    componentDidMount() {
        this.props.reference.doGetReferences(`EMPLOYERS-WC.Associations.${this.props.policy?.participants?.insured?.party?.address?.stateCode}`);
        this.setState({ ybe: this.props.policy.employersWcCustomInfo.businessInfo?.yearsInBusinessNumber })

        const pageHasTooltipInfo = this.props.tooltipInfo?.pages && this.props.tooltipInfo.pages.length && this.props.tooltipInfo.pages.some(p => p.code === this.props.pageCode);
        if (!pageHasTooltipInfo) {
            this.props.doGetTooltipInfo(this.props.policy.product);
        }
    }

    saveBIHandler = () => {
        this.props.doUpsertPolicy();
        const newYbe = this.props.policy.employersWcCustomInfo.businessInfo?.yearsInBusinessNumber;
        this.setState(prevState => {
            if (prevState.ybe !== undefined && ((newYbe > prevState.ybe && newYbe <= 4) || newYbe < prevState.ybe)) {
                return { showPopup: true };
            } else {
                return { ybe: newYbe };
            }
        })
    }

    render() {
        return (
            <Form
                pageCode={this.props.pageCode}
                sectionCode='businessInformation'
                saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                tooltipInfo={this.props.tooltipInfo}
                validationInfo={this.props.validationInfo}
                header={<FormattedMessage id={`Business Information`} />}
                label={`Details`}
                saveEvent={this.saveBIHandler}
                cancelEvent={this.props.doGetPolicy}
                allowEdit={this.props.allowEdit}
            >
                {
                    this.state.showPopup &&
                    <GenericModal
                        animate
                        visible
                        closeEvent={() => {
                            this.props.doReducePolicyElement({ code: 'employersWcCustomInfo.businessInfo.yearsInBusinessNumber', value: this.state.ybe });
                            this.props.doUpsertPolicy();
                            this.setState({ showPopup: false });
                        }}
                        items={
                            <React.Fragment>
                                <Header size={Sizes.md} text='Warning' bottom={Sizes.xs} />
                                <Label size={Sizes.sm} textAlign={Alignments.center} text={'Changed the Years of Business Experience field. Please re-create your claims on the Insurance History page, if applicable.'} />
                            </React.Fragment>
                        }
                        actionButtons={[
                            {
                                primary: true,
                                text: <FormattedMessage id='covera.common.general.ok' />,
                                disabled: false,
                                clickEvent: () => {
                                    this.setState({
                                        ybe: this.props.policy.employersWcCustomInfo.businessInfo?.yearsInBusinessNumber,
                                        showPopup: false
                                    });
                                }
                            }, {
                                primary: false,
                                text: <FormattedMessage id='covera.common.general.cancel' />,
                                disabled: false,
                                clickEvent: () => {
                                    this.props.doReducePolicyElement({ code: 'employersWcCustomInfo.businessInfo.yearsInBusinessNumber', value: this.state.ybe });
                                    this.props.doUpsertPolicy();
                                    this.setState({ showPopup: false });
                                }
                            }
                        ]}
                    />
                }
                <FormInput
                    fieldCode='legalBusinessName'
                    label={`Business Name`}
                    value={this.props.policy.employersWcCustomInfo.businessInfo?.legalBusinessName}
                    type='text'
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `employersWcCustomInfo.businessInfo.legalBusinessName`, value })}
                    allowEdit
                />
                <FormInput
                    fieldCode='businessDescription'
                    label={`Describe your business`}
                    value={this.props.policy.employersWcCustomInfo.businessInfo?.businessDescription}
                    type='text'
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `employersWcCustomInfo.businessInfo.businessDescription`, value })}
                    allowEdit
                />
                <FormInput
                    fieldCode='website'
                    label={`Website`}
                    value={this.props.policy.employersWcCustomInfo.businessInfo?.website}
                    type='text'
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `employersWcCustomInfo.businessInfo.website`, value })}
                    allowEdit
                />
                <FormInput
                    fieldCode='federalEmployerIdentificationNumber'
                    label={`FEIN Number`}
                    value={this.props.policy.employersWcCustomInfo.businessInfo?.federalEmployerIdentificationNumber}
                    type='number'
                    changeEvent={value => this.props.doReducePolicyElement({ code: 'employersWcCustomInfo.businessInfo.federalEmployerIdentificationNumber', value })}
                    overrideMaxLength={9}
                    allowEdit
                />
                <FormDropDown
                    fieldCode='legalEntityTypeCode'
                    label={<FormattedMessage id={`Legal Entity Type`} />}
                    value={{ code: this.props.policy.employersWcCustomInfo.businessInfo?.legalEntityTypeCode, value: this.props.policy.employersWcCustomInfo.businessInfo?.legalEntityTypeValue }}
                    references={this.props.reference?.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`LegalEntities`] && this.props.reference.references[`EMPLOYERS-WC`][`LegalEntities`]}
                    referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.LegalEntities`)}
                    changeEvent={(value) => {
                        this.props.doReducePolicyElement({ code: 'employersWcCustomInfo.businessInfo.legalEntityTypeCode', value: value.code });
                        this.props.doReducePolicyElement({ code: 'employersWcCustomInfo.businessInfo.legalEntityTypeValue', value: value.value });
                    }}
                    allowEdit
                />
                <FormInput
                    fieldCode='yearsInBusinessNumber'
                    label={`Years of Business Experience`}
                    value={this.props.policy.employersWcCustomInfo.businessInfo?.yearsInBusinessNumber}
                    type='number'
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: 'employersWcCustomInfo.businessInfo.yearsInBusinessNumber', value })}
                    allowEdit
                />
                <FormInput
                    fieldCode='yearsInIndustryNumber'
                    label={`Years of Industry Experience`}
                    value={this.props.policy.employersWcCustomInfo.businessInfo?.yearsInIndustryNumber}
                    type='number'
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `employersWcCustomInfo.businessInfo.yearsInIndustryNumber`, value })}
                    allowEdit
                />
                <FormSwitch
                    fieldCode='hasAssociationMembership'
                    label={<FormattedMessage id={'Is the applicant a member of an association?'} />}
                    value={this.props.policy.employersWcCustomInfo.businessInfo?.hasAssociationMembership}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `employersWcCustomInfo.businessInfo.hasAssociationMembership`, value: value })}
                    allowEdit
                />
                {
                    this.props.policy.employersWcCustomInfo.businessInfo?.hasAssociationMembership === true ?
                    this.props.reference?.references[`EMPLOYERS-WC`] 
                    && this.props.reference.references[`EMPLOYERS-WC`][`Associations`]
                    && this.props.reference.references[`EMPLOYERS-WC`][`Associations`][this.props.policy.participants.insured.party.address.stateCode]
                    ?
                    <FormDropDown
                        fieldCode='associationTypeCode'
                        label={<FormattedMessage id={`Please select Association Membership`} />}
                        value={{ code: this.props.policy.employersWcCustomInfo?.businessInfo?.associationTypeCode, value: this.props.policy.employersWcCustomInfo?.businessInfo?.associationTypeValue }}
                        references={this.props.reference.references[`EMPLOYERS-WC`][`Associations`][this.props.policy.participants.insured.party.address.stateCode]}
                        referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.Associations.${this.props.policy.participants.insured.party.address.stateCode}`)}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `employersWcCustomInfo.businessInfo.associationTypeCode`, value: value.code });
                            this.props.doReducePolicyElement({ code: `employersWcCustomInfo.businessInfo.associationTypeValue`, value: value.value });
                        }}
                        allowEdit
                    />
                    :
                    <FormDropDown
                        fieldCode='associationTypeCode'
                        label={<FormattedMessage id={`Please select Association Membership`} />}
                        value={{ code: this.props.policy.employersWcCustomInfo?.businessInfo?.associationTypeCode, value: this.props.policy.employersWcCustomInfo?.businessInfo?.associationTypeValue }}
                        references={this.props.reference.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`Associations`] && this.props.reference.references[`EMPLOYERS-WC`][`Associations`][`OTHER`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.Associations.OTHER`)}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `employersWcCustomInfo.businessInfo.associationTypeCode`, value: value.code });
                            this.props.doReducePolicyElement({ code: `employersWcCustomInfo.businessInfo.associationTypeValue`, value: value.value });
                        }}
                        allowEdit
                    />
                    : false
                }
                {
                    this.props.policy.employersWcCustomInfo.businessInfo?.hasAssociationMembership === true && this.props.policy.employersWcCustomInfo?.businessInfo?.associationTypeCode &&
                    <FormInput
                        fieldCode='associationMembershipId'
                        label={`Association MembershipId`}
                        value={this.props.policy.employersWcCustomInfo.businessInfo?.associationMembershipId}
                        type='text'
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `employersWcCustomInfo.businessInfo.associationMembershipId`, value })}
                        allowEdit
                    />
                }

                {/*If you have answered true to either of the Anthem Integrated Medicomp Program Questions (EMPCO-1104/EMPCO-1105), you must also send an Anthem Group Id as shown in the field definitions below.*/}
                {
                    (this.props.policy.employersWcCustomInfo?.additionalInformation?.general_EMPCO1104 || this.props.policy.employersWcCustomInfo?.additionalInformation?.general_EMPCO1105) &&
                    <FormInput
                        fieldCode='healthInsGroupId'
                        label={`Anthem Group Id`}
                        value={this.props.policy.employersWcCustomInfo.businessInfo?.healthInsGroupId}
                        type='text'
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `employersWcCustomInfo.businessInfo.healthInsGroupId`, value })}
                        allowEdit
                    />
                }
            </Form>
        );
    }
}

export default ProfileEmployersWcBusiness;