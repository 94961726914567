import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Form,
    FormInput,
    FormDropDown,
    FormRadio,
    convertFromBoolean,
    convertToBoolean,
    FormChecklist
} from 'metrix-common';

class ProfileCGOther extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        let product = this.props.policy.product;
        let isCGOther = product === `cg-other`;
        let isCGAuto = product === `cg-auto`;
        let isCGGeneralLiability = product === `cg-generalliability`;
        let isCGBuildersRisk = product === `cg-buildersrisk`;
        let isCGBop = product === `cg-bop`;
        let isCGPl = product === `cg-pl`;
        let isCGProperty = product === `cg-property`;
        let isCGWorkersComp = product === `cg-workerscomp`;
        let isCGBonds = product === `cg-bonds`;

        return (
            <React.Fragment>
                <Form header={<FormattedMessage id={`Business Info`} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit>
                    <FormInput
                        label={`Legal Business Name`}
                        value={this.props.policy.cgCustomInfo.legalBusinessName}
                        type='text'
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `cgCustomInfo.legalBusinessName`, value })}
                        allowEdit
                    />
                    <FormInput
                        label={`Description of Operation`}
                        value={this.props.policy.cgCustomInfo.descriptionOfOperations}
                        type='text'
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `cgCustomInfo.descriptionOfOperations`, value })}
                        allowEdit
                    />
                    {
                        (isCGOther) &&
                        <FormInput
                            label={`Describe your business`}
                            value={this.props.policy.cgCustomInfo.descriptionOfBusiness}
                            type='text'
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `cgCustomInfo.descriptionOfBusiness`, value })}
                            allowEdit
                        />
                    }
                    {
                        (isCGAuto || isCGGeneralLiability || isCGPl || isCGWorkersComp) &&
                        <FormDropDown
                            label={<FormattedMessage id={`Legal Entity Type`} />}
                            value={{ code: this.props.policy.cgCustomInfo.legalEntityTypeCode, value: this.props.policy.cgCustomInfo.legalEntityTypeName }}
                            references={this.props.reference?.references[`CG`] && this.props.reference.references[`CG`][`LegalEntities`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`CG.LegalEntities`)}
                            changeEvent={(value) => {
                                this.props.doReducePolicyElement({ code: `cgCustomInfo.legalEntityTypeCode`, value: value.code });
                                this.props.doReducePolicyElement({ code: `cgCustomInfo.legalEntityTypeName`, value: value.value });
                            }}
                            allowEdit
                        />
                    }
                    {
                        (isCGGeneralLiability || isCGPl || isCGWorkersComp) &&
                        <FormDropDown
                            label={<FormattedMessage id={`Annual Receipts`} />}
                            value={{ code: this.props.policy.cgCustomInfo.annualGrossReceiptCode, value: this.props.policy.cgCustomInfo.annualGrossReceiptName }}
                            references={this.props.reference?.references[`CG`] && this.props.reference.references[`CG`][`AnnualGrossReceiptsOptions`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`CG.AnnualGrossReceiptsOptions`)}
                            changeEvent={(value) => {
                                this.props.doReducePolicyElement({ code: `cgCustomInfo.annualGrossReceiptCode`, value: value.code });
                                this.props.doReducePolicyElement({ code: `cgCustomInfo.annualGrossReceiptName`, value: value.value });
                            }}
                            allowEdit
                        />
                    }
                    {
                        (isCGOther || isCGAuto || isCGBuildersRisk) &&
                        <FormInput
                            label={`Number of Years in Business`}
                            value={this.props.policy.cgCustomInfo.numberYearsInBusiness}
                            type='number'
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `cgCustomInfo.numberYearsInBusiness`, value })}
                            allowEdit
                        />
                    }
                </Form>
                {
                    (isCGGeneralLiability || isCGPl || isCGBuildersRisk || isCGWorkersComp) &&
                    <Form header={<FormattedMessage id={`Employee Info`} />}
                        label={`Details`}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit>
                        {
                            (isCGBuildersRisk) &&
                            <FormInput
                                label={`Number of Employees`}
                                value={
                                    this.props.policy.cgCustomInfo.employeesAmount}
                                type='number'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `cgCustomInfo.employeesAmount`, value })}
                                allowEdit
                            />
                        }
                        {
                            (isCGGeneralLiability || isCGPl || isCGWorkersComp) &&
                            <FormInput
                                label={`Number of active Owners`}
                                value={
                                    this.props.policy.cgCustomInfo.numberActiveOwners}
                                type='number'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `cgCustomInfo.numberActiveOwners`, value })}
                                allowEdit
                            />
                        }
                        {
                            (isCGGeneralLiability || isCGPl || isCGWorkersComp) &&
                            <FormInput
                                label={`Full-time Employees`}
                                value={
                                    this.props.policy.cgCustomInfo.fullTimeEmployeesCount}
                                type='number'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `cgCustomInfo.fullTimeEmployeesCount`, value })}
                                allowEdit
                            />
                        }
                        {
                            (isCGGeneralLiability || isCGPl || isCGWorkersComp) &&
                            <FormInput
                                label={`Part-time Employees`}
                                value={
                                    this.props.policy.cgCustomInfo.partTimeEmployeesCount}
                                type='number'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `cgCustomInfo.partTimeEmployeesCount`, value })}
                                allowEdit
                            />
                        }
                        {
                            (isCGGeneralLiability || isCGPl || isCGWorkersComp) &&
                            <FormDropDown
                                label={<FormattedMessage id={`Employee Payroll`} />}
                                value={{ code: this.props.policy.cgCustomInfo.annualEmployeePayrollCode, value: this.props.policy.cgCustomInfo.annualEmployeePayrollName }}
                                references={this.props.reference?.references[`CG`] && this.props.reference.references[`CG`][`EmployeePayrollOptions`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`CG.EmployeePayrollOptions`)}
                                changeEvent={(value) => {
                                    this.props.doReducePolicyElement({ code: `cgCustomInfo.annualEmployeePayrollCode`, value: value.code });
                                    this.props.doReducePolicyElement({ code: `cgCustomInfo.annualEmployeePayrollName`, value: value.value });
                                }}
                                allowEdit
                            />
                        }
                    </Form>
                }
                {
                    (isCGGeneralLiability || isCGPl || isCGWorkersComp) &&
                    <Form header={<FormattedMessage id={`Insurance Info`} />}
                        label={`Details`}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit>
                        <FormRadio
                            label={<FormattedMessage id={`Previously Insured?`} />}
                            value={this.props.policy && this.props.policy.cgCustomInfo && { code: convertFromBoolean(this.props.policy.cgCustomInfo.hasPriorInsurance) }}
                            references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `cgCustomInfo.hasPriorInsurance`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                        <FormRadio
                            label={<FormattedMessage id={`Any previous claims?`} />}
                            value={this.props.policy && this.props.policy.cgCustomInfo && { code: convertFromBoolean(this.props.policy.cgCustomInfo.hasClaims) }}
                            references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `cgCustomInfo.hasClaims`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                    </Form>
                }
                {
                    (isCGBonds) &&
                    <Form header={<FormattedMessage id={`Bond Info`} />}
                        label={`Details`}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit>
                        <FormDropDown
                            label={<FormattedMessage id={`Type of Bond`} />}
                            value={{ code: this.props.policy.cgCustomInfo.bondTypeCode, value: this.props.policy.cgCustomInfo.bondTypeName }}
                            references={this.props.reference?.references[`CG`] && this.props.reference.references[`CG`][`BondOptions`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`CG.BondOptions`)}
                            changeEvent={(value) => {
                                this.props.doReducePolicyElement({ code: `cgCustomInfo.bondTypeCode`, value: value.code });
                                this.props.doReducePolicyElement({ code: `cgCustomInfo.bondTypeName`, value: value.value });
                            }}
                            allowEdit
                        />

                    </Form>
                }
                {
                    (isCGAuto) &&
                    <Form header={<FormattedMessage id={`Auto`} />}
                        label={`Details`}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit>
                        <FormInput
                            label={`Driver Count`}
                            value={
                                this.props.policy.customData.driversCount}
                            type='number'
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `customData.driversCount`, value })}
                            allowEdit
                        />
                        <FormInput
                            label={`Vehicle Count`}
                            value={
                                this.props.policy.customData.vehiclesCount}
                            type='number'
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `customData.vehiclesCount`, value })}
                            allowEdit
                        />
                    </Form>
                }
                {
                    (isCGBop || isCGProperty) &&
                    <Form header={<FormattedMessage id={`Property`} />}
                        label={`Details`}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit>
                        <FormDropDown
                            label={<FormattedMessage id={`Property Description`} />}
                            value={{ code: this.props.policy.cgCustomInfo.propertyTypeCode, value: this.props.policy.cgCustomInfo.propertyTypeName }}
                            references={this.props.reference?.references[`CG`] && this.props.reference.references[`CG`][`PropertyOptions`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`CG.PropertyOptions`)}
                            changeEvent={(value) => {
                                this.props.doReducePolicyElement({ code: `cgCustomInfo.propertyTypeCode`, value: value.code });
                                this.props.doReducePolicyElement({ code: `cgCustomInfo.propertyTypeName`, value: value.value });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {
                    (isCGOther || isCGBop || isCGProperty) &&
                    <Form header={<FormattedMessage id={`Coverage`} />}
                        label={`Details`}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit>
                        {(isCGOther || isCGBop || isCGProperty) &&
                            <FormDropDown
                                label={<FormattedMessage id={`Desired Coverage Amount`} />}
                                value={{ code: this.props.policy.cgCustomInfo.desiredCoverageOptionCode, value: this.props.policy.cgCustomInfo.desiredCoverageOptionName }}
                                references={this.props.reference?.references[`CG`] && this.props.reference.references[`CG`][`DesiredCoverageOptions`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`CG.DesiredCoverageOptions`)}
                                changeEvent={(value) => {
                                    this.props.doReducePolicyElement({ code: `cgCustomInfo.desiredCoverageOptionCode`, value: value.code });
                                    this.props.doReducePolicyElement({ code: `cgCustomInfo.desiredCoverageOptionName`, value: value.value });
                                }}
                                allowEdit
                                />
                            }
                        {
                            (isCGOther) &&
                            <FormInput
                                label={`Describe the type of coverage you are looking for`}
                                    value={this.props.policy.cgCustomInfo.descriptionOfDesiredCoverage}
                                type='text'
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `cgCustomInfo.descriptionOfDesiredCoverage`, value })}
                                allowEdit
                            />
                        }
                    </Form>
                }
                <Form header={<FormattedMessage id={`Other coverage interests`} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit>
                    <FormChecklist
                        label={<FormattedMessage id={`Check all that apply`} />}
                        value={this.props.policy.cgCustomInfo.coverageTypeSelectedItems}
                        references={this.props.reference?.references[`CG`] && this.props.reference.references[`CG`][`CoverageTypes`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`CG.CoverageTypes`)}
                        changeEvent={(values) => {
                            this.props.doReducePolicyElement({ code: `cgCustomInfo.coverageTypeSelectedItems`, value: values });
                        }}
                        allowEdit />
                </Form>
            </React.Fragment>
        );
    }
}

export default ProfileCGOther;