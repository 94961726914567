import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import {
    Sizes,
    Alignments,
    Colors,
    Grid,
    Row,
    Column,
    Alert,
    Container,
    Header,
    Label,
    Link,
    SecondaryButton,
    CalendarIcon,
    HourglassIcon,
    AutoProduct,
    HomeProduct,
    BundleProduct,
    getFormattedCurrency
} from 'metrix-common';

import ProfileSummaryDiscounts from '../../CommonComponents/DiscountBadges'

const PolicyFactsContainer = styled(Container)`
    border: 1px solid ${Colors.StrokeLight};
    border-radius: 4px;
`;

const HeaderCell = styled(Container)`
    background: ${Colors.StrokeVeryLight};
    padding: 10px;
`;

const BodyCell = styled(Container)`
    ${({ firstRow }) => firstRow && `border-top: 1px solid ${Colors.StrokeVeryLight};`}
    border-bottom: 1px solid ${Colors.StrokeVeryLight};
    ${({ firstCell }) => firstCell && `border-left: 1px solid ${Colors.StrokeVeryLight};`}
    border-right: 1px solid ${Colors.StrokeVeryLight};
    padding: 5px;
`;

class ProfileSummaryAction extends React.Component {
    render() {
        let policyStatus = this.props.policySummary.status;
        let insurerSelected = (policyStatus === `quoteAccepted` ||
                                policyStatus === `quoteIssued` ||
                                policyStatus === `bindRequested` ||
                                policyStatus === `bound` ||
                                policyStatus === `issued`);

        let insurer = (insurerSelected &&
                        (this.props.policySummary.participants.insurer && 
                        this.props.policySummary.participants.insurer.commercialName && 
                        this.props.policySummary.participants.insurer.commercialName.name))
            ? this.props.policySummary.participants.insurer.commercialName.name
            : ``;

        let currency = `CAD`;
        let culture = this.props.context.session.culture;

        let premiumAmount = this.props.policySummary.estimatedAnnualPremium.value;
        let taxesAmount = this.props.policySummary.estimatedTaxes.value;
        let feesAmount = this.props.policySummary.estimatedFees.value;            

        if (this.props.policySummary.risks) {
            for (var i = 0; i < this.props.policySummary.risks.length; i++) {
                let risk = this.props.policySummary.risks[i];
                for (var j = 0; j < risk.coverages.length; j++) {
                    let coverage = risk.coverages[0];

                }
            }
        }

        return (
            <React.Fragment>
                {this.props.context &&
                    this.props.context.session &&
                    this.props.context.session.userType === `broker` &&
                    this.props.policySummary.role &&
                    this.props.policySummary.role === `master` &&
                    <PolicyFactsContainer top={Sizes.sm}
                        bottom={Sizes.sm}
                    >
                        <Grid>
                            <Row>
                                <Column>
                                    <Header top={Sizes.xs}
                                        size={Sizes.lg}
                                        text={`This is a package policy.`}
                                    />
                                </Column>
                            </Row>
                            <Row>
                                <Column>
                                    <Label text={`To make changes, update the underlier policies in the package directly.`} />
                                </Column>
                            </Row>
                            {this.props.policySummary &&
                                this.props.policySummary.relatedPolicyTransactions &&
                                this.props.policySummary.relatedPolicyTransactions.length > 0 &&
                                this.props.policySummary.relatedPolicyTransactions.map((relatedPolicyTransaction) => {
                                    return (<Row>
                                        <Column xs={0} md={3} />
                                        <Column xs={12} md={6}>
                                            <Container inline
                                                horizontalAlignment={Alignments.left}
                                                verticalAlignment={Alignments.center}
                                            >
                                                <Grid>
                                                    <Row>
                                                        <Column xs={2}>
                                                            <Container fullSize
                                                                horizontalAlignment={Alignments.left}
                                                                verticalAlignment={Alignments.center}
                                                            >
                                                                {relatedPolicyTransaction.product === `auto` ? <AutoProduct color={Colors.Primary} /> : <HomeProduct color={Colors.Primary} />}
                                                            </Container>
                                                        </Column>
                                                        <Column xs={5}>
                                                            <Container fullSize
                                                                horizontalAlignment={Alignments.left}
                                                                verticalAlignment={Alignments.center}
                                                            >
                                                                <Label bold
                                                                    left={Sizes.xs}
                                                                    textAlign={Alignments.left}
                                                                    text={relatedPolicyTransaction.product === `auto` ? `Auto` : `Home`}
                                                                />
                                                            </Container>
                                                        </Column>
                                                        <Column xs={5}>
                                                            <Container
                                                                horizontalAlignment={Alignments.right}
                                                                verticalAlignment={Alignments.center}
                                                            >
                                                                <SecondaryButton text={`Summary`} clickEvent={() => window.location = `${this.props.context.configuration.policyWeb}/profile?entity=${relatedPolicyTransaction.entity}`} />
                                                            </Container>
                                                        </Column>
                                                    </Row>
                                                </Grid>
                                            </Container>
                                        </Column>
                                        <Column xs={0} md={3} />
                                    </Row>);
                                })
                            }
                        </Grid>
                    </PolicyFactsContainer>
                }
                {this.props.context &&
                    this.props.context.session &&
                    this.props.context.session.userType === `broker` &&
                    this.props.policySummary.role &&
                    this.props.policySummary.role === `underlier` &&
                    <PolicyFactsContainer top={Sizes.sm}
                        bottom={Sizes.sm}
                    >
                        <Grid>
                            <Row>
                                <Column>
                                    <Header top={Sizes.xs}
                                        size={Sizes.lg}
                                        text={`This policy is part of a package.`}
                                    />
                                </Column>
                            </Row>
                            <Row>
                                <Column>
                                    <Label top={Sizes.sm}
                                        text={`To renew this policy, trigger the renewal on the master policy.`}
                                    />
                                </Column>
                            </Row>
                        {this.props.policySummary &&
                            this.props.policySummary.relatedPolicyTransactions &&
                            this.props.policySummary.relatedPolicyTransactions.length > 0 &&
                            this.props.policySummary.relatedPolicyTransactions.map((relatedPolicyTransaction) => {
                                return (<Row>
                                    <Column xs={0} md={3} />
                                    <Column xs={12} md={6}>
                                        <Container inline
                                            horizontalAlignment={Alignments.left}
                                            verticalAlignment={Alignments.center}
                                        >
                                            <Grid>
                                                <Row>
                                                    <Column xs={2}>
                                                        <Container fullSize
                                                            horizontalAlignment={Alignments.left}
                                                            verticalAlignment={Alignments.center}
                                                        >
                                                            <BundleProduct color={Colors.Primary} />
                                                        </Container>
                                                    </Column>
                                                    <Column xs={5}>
                                                        <Container fullSize
                                                            horizontalAlignment={Alignments.left}
                                                            verticalAlignment={Alignments.center}
                                                        >
                                                            <Label bold
                                                                left={Sizes.xs}
                                                                textAlign={Alignments.left}
                                                                text={relatedPolicyTransaction.product === `ppackage` && `Home & Auto`}
                                                            />
                                                        </Container>
                                                    </Column>
                                                    <Column xs={5}>
                                                        <Container
                                                            horizontalAlignment={Alignments.right}
                                                            verticalAlignment={Alignments.center}
                                                        >
                                                            <SecondaryButton text={`Summary`} clickEvent={() => window.location = `${this.props.context.configuration.policyWeb}/profile?entity=${relatedPolicyTransaction.entity}`} />
                                                        </Container>
                                                    </Column>
                                                </Row>
                                            </Grid>
                                        </Container>
                                    </Column>
                                    <Column xs={0} md={3} />
                                </Row>);
                            })
                        }
                        </Grid>
                    </PolicyFactsContainer>
                }
                <PolicyFactsContainer top={Sizes.sm}
                    bottom={Sizes.sm}
                >
                    <Grid>
                        <Row>
                            <Column>
                                <Header top={Sizes.xs}
                                    size={Sizes.lg}
                                    text={<FormattedMessage id={`app.components.Profile.Summary.Action.${policyStatus}`} />}
                                />
                            </Column>
                        </Row>
                        <Row>
                            <Column xs={6}>
                                <Container inline
                                    horizontalAlignment={Alignments.left}
                                    verticalAlignment={Alignments.center}
                                >
                                    <CalendarIcon primary={Colors.TertiaryText} />
                                    <Label bold
                                        left={Sizes.xs}
                                        textAlign={Alignments.left}
                                        text={<FormattedMessage id={`app.components.Profile.Summary.policyStarts`}
                                            values={{ date: this.props.policySummary.formattedEffectiveDate }}
                                        />}
                                    />
                                </Container>
                            </Column>
                            <Column xs={6}>
                                <Container inline
                                           horizontalAlignment={Alignments.left}
                                           verticalAlignment={Alignments.center}
                                >
                                    <HourglassIcon primary={Colors.TertiaryText} />
                                    {(policyStatus === 'quoted' || policyStatus === 'quoteIssued')
                                        ?
                                        <Label bold
                                               left={Sizes.xs}
                                               textAlign={Alignments.left}
                                               text={
                                                   <FormattedMessage id={`app.components.Profile.Summary.quoteExpires`}
                                                                     values={{ date: this.props.policySummary.formattedExpiryDate }}
                                                   />}
                                        />
                                        :
                                        <Label bold
                                               left={Sizes.xs}
                                               textAlign={Alignments.left}
                                               text={<FormattedMessage id={`app.components.Profile.Summary.policyEnds`}
                                                                       values={{ date: this.props.policySummary.formattedExpiryDate }}
                                               />}
                                        />
                                    }
                                </Container>
                            </Column>
                        </Row>
                        <Row>
                            <Column xs={insurerSelected ? 6 : 12}>
                                {this.props.policySummary.status === `issued` ?
                                    <Label bottom={Sizes.xs}
                                        textAlign={insurerSelected ? Alignments.left : Alignments.center}
                                        text={
                                            <FormattedMessage id={`app.components.Profile.Summary.policyNumber`}
                                                values={{
                                                    number: this.props.policySummary.number
                                                }}
                                            />
                                        }
                                    />
                                    :
                                <Label bottom={Sizes.xs}
                                    textAlign={insurerSelected ? Alignments.left : Alignments.center}
                                    text={
                                        <FormattedMessage id={`app.components.Profile.Summary.binderNumber`}
                                            values={{
                                                number: this.props.policySummary.submissionReference
                                            }}
                                        />
                                    }
                                />
                                }
                            </Column>
                            {insurerSelected &&
                                <Column xs={6}>
                                    <Label bottom={Sizes.xs}
                                        textAlign={Alignments.left}
                                        text={
                                            <FormattedMessage id={`app.components.Profile.Summary.selectedInsurer`}
                                                values={{
                                                    insurer: insurer
                                                }}
                                            />
                                        }
                                    />
                                </Column>
                            }
                        </Row>
                        {this.props.context.session.userType === `client` &&
                        <Row>
                            <Column>
                                <Label bottom={Sizes.xs}
                                    textAlign={Alignments.center}
                                    text={
                                        <FormattedMessage id={`app.components.MainContentsCard.pleaseContact`}
                                            values={{
                                                contact:
                                                    <Link inline
                                                        text={
                                                            <FormattedMessage id={`app.components.MainContentsCard.contact`} />
                                                        }
                                                        clickEvent={() => window.scrollTo({
                                                            top: 2000,
                                                            left: 0,
                                                            behavior: 'smooth'
                                                        })}
                                                    />
                                            }}
                                        />
                                    }
                                />
                            </Column>
                        </Row>
                        }
                    </Grid>
                    {
                        (this.props.policySummary.status === 'quoteAccepted' ||
                        this.props.policySummary.status === 'quoteIssued' ||
                        this.props.policySummary.status === 'bindRequested' ||
                        this.props.policySummary.status === 'bound' ||
                        this.props.policySummary.status === 'issued') &&
                        <Container left={Sizes.xs} right={Sizes.xs} bottom={Sizes.xs} >
                        <Grid>
                            <Row nogutter>
                                <Column xs={12} nogutter>
                                    <HeaderCell fullSize>
                                        <Label bold textAlign={Alignments.center} text={<FormattedMessage id={`app.components.PolicySummaryCard.Summary.header`} />} />
                                    </HeaderCell>
                                </Column>
                            </Row>
                            {this.props.policySummary.quoteReference &&
                                <Row nogutter>
                                    <Column xs={8} nogutter>
                                        <BodyCell firstRow firstCell fullSize>
                                            <Label textAlign={Alignments.left} text={<FormattedMessage id={`Quote Reference`} />} />
                                        </BodyCell>
                                    </Column>
                                    <Column xs={4} nogutter>
                                        <BodyCell firstRow fullSize>
                                        <Link textAlign={Alignments.right} text={this.props.policySummary.quoteReference} href={this.props.policySummary.portalUrl} newTab />
                                        </BodyCell>
                                    </Column>
                                </Row>
                            }
                            <Row nogutter>
                                <Column xs={8} nogutter>
                                    <BodyCell firstRow={!this.props.policySummary.quoteReference} firstCell fullSize>
                                        <Label textAlign={Alignments.left} text={<FormattedMessage id={`app.components.PolicySummaryCard.Summary.premium`} />} />
                                    </BodyCell>
                                </Column>
                                <Column xs={4} nogutter>
                                    <BodyCell firstRow={!this.props.policySummary.quoteReference} fullSize>
                                        <Label textAlign={Alignments.right} text={this.props.policySummary.formattedEstimatedAnnualPremium} />
                                    </BodyCell>
                                </Column>
                            </Row>
                            <Row nogutter>
                                <Column xs={8} nogutter>
                                    <BodyCell firstCell fullSize>
                                            <Label textAlign={Alignments.left} text={<FormattedMessage id={`app.components.PolicySummaryCard.Summary.taxes`} />} />
                                    </BodyCell>
                                </Column>
                                <Column xs={4} nogutter>
                                    <BodyCell fullSize>
                                        <Label textAlign={Alignments.right} text={this.props.policySummary.formattedEstimatedTaxes} />
                                    </BodyCell>
                                </Column>
                            </Row>
                            <Row nogutter>
                                <Column xs={8} nogutter>
                                    <BodyCell firstCell fullSize>
                                        <Label textAlign={Alignments.left} text={<FormattedMessage id={`app.components.PolicySummaryCard.Summary.fees`} />} />
                                    </BodyCell>
                                </Column>
                                <Column xs={4} nogutter>
                                    <BodyCell fullSize>
                                        <Label textAlign={Alignments.right} text={this.props.policySummary.formattedEstimatedFees} />
                                    </BodyCell>
                                </Column>
                            </Row>
                            <Row nogutter>
                                <Column xs={8} nogutter>
                                    <BodyCell firstCell fullSize>
                                        <Label textAlign={Alignments.left} text={<FormattedMessage id={`app.components.PolicySummaryCard.Summary.premiumTaxes`} />} />
                                    </BodyCell>
                                </Column>
                                <Column xs={4} nogutter>
                                    <BodyCell fullSize>
                                        <Label textAlign={Alignments.right} text={getFormattedCurrency(premiumAmount + taxesAmount, currency, culture)} />
                                    </BodyCell>
                                </Column>
                            </Row>
                            <Row nogutter>
                                <Column xs={8} nogutter>
                                    <BodyCell firstCell fullSize>
                                        <Label textAlign={Alignments.left} text={<FormattedMessage id={`app.components.PolicySummaryCard.Summary.premiumTaxesFees`} />} />
                                    </BodyCell>
                                </Column>
                                <Column xs={4} nogutter>
                                    <BodyCell fullSize>
                                        <Label textAlign={Alignments.right} text={getFormattedCurrency(premiumAmount + taxesAmount + feesAmount, currency, culture)} />
                                    </BodyCell>
                                </Column>
                            </Row>
                        </Grid>
                    </Container>
                    }
                </PolicyFactsContainer>

                {this.props.context.session.userType === `broker` &&
                    <React.Fragment>
                        {this.props.policySummary.discounts &&
                            this.props.policySummary.discounts.length > 0 &&
                            <Alert
                                mode={`success`}
                                header={<FormattedMessage id={`app.components.Profile.Summary.discounts`} />}
                                items={this.props.policySummary.discounts.map((x) => {
                                    return {
                                        code: x.code,
                                        value: `${x.code} - ${x.description}`
                                    }
                                })} />
                        }
                        {this.props.policySummary.surcharges &&
                            this.props.policySummary.surcharges.length > 0 &&
                            <Alert
                                mode={`info`}
                                header={<FormattedMessage id={`app.components.Profile.Summary.surcharges`} />}
                                items={this.props.policySummary.surcharges.map((x) => {
                                    return {
                                        code: x.code,
                                        value: `${x.code} - ${x.description}`
                                    }
                                })} />
                        }
                    </React.Fragment>
                }
                {this.props.context.session.userType !== `broker` &&
                    this.props.policySummary.discounts &&
                    this.props.policySummary.discounts.length > 0 &&
                    <ProfileSummaryDiscounts discounts={this.props.policySummary.discounts} />
                }
            </React.Fragment>
        );
    }
}
            
export default ProfileSummaryAction;
