import { all, takeLatest, fork } from 'redux-saga/effects';

import { GET_PRODUCT } from '../../constants/actions/product';
import { getProductSaga } from './getProductSaga';

function* watchGetProduct() {
    yield takeLatest(GET_PRODUCT, getProductSaga);
}

function* root() {
    return yield all([
        fork(watchGetProduct)
    ]);
}

export default root;