import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Form,
    FormDropDown,
    FormInput
} from 'metrix-common';

class ProfileInterwestGlpcLimits extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            <Form
                header={<FormattedMessage id='Limits' />}
                label='Details'
                saveEvent={this.props.doUpsertPolicy}
                cancelEvent={this.props.doGetPolicy}
                allowEdit={this.props.allowEdit}
            >
                <FormDropDown
                    label={<FormattedMessage id='General Liability Limit (per occurrence):' />}
                    value={{ code: this.props.policy.interWestGLPCCustomInfo.limitsInfo.generalLiabilityLimitsCode }}
                    references={this.props.reference?.references['InterWest-GLPC'] && this.props.reference.references['InterWest-GLPC'].GLLimits}
                    referencesEvent={() => this.props.reference.doGetReferences('InterWest-GLPC.GLLimits')}
                    changeEvent={value => {
                        this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.limitsInfo.generalLiabilityLimitsCode', value: value.code });
                    }}
                    allowEdit
                />
                <FormDropDown
                    label={<FormattedMessage id='General Liability Deductible:' />}
                    value={{ code: this.props.policy.interWestGLPCCustomInfo.limitsInfo.generalLiabilityDeductibleCode }}
                    references={this.props.reference?.references['InterWest-GLPC'] && this.props.reference.references['InterWest-GLPC'].GLDeductible}
                    referencesEvent={() => this.props.reference.doGetReferences('InterWest-GLPC.GLDeductible')}
                    changeEvent={value => {
                        this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.limitsInfo.generalLiabilityDeductibleCode', value: value.code });
                    }}
                    allowEdit
                />
                <FormInput
                    label='Business Personal Property (BPP) Limit:'
                    value={this.props.policy.interWestGLPCCustomInfo.limitsInfo.bppLimitAmount}
                    type='money'
                    changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.limitsInfo.bppLimitAmount', value })}
                    allowEdit
                />
                <FormInput
                    label='Building Limit:'
                    value={this.props.policy.interWestGLPCCustomInfo.limitsInfo.buildingLimitAmount}
                    type='money'
                    changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.limitsInfo.buildingLimitAmount', value })}
                    allowEdit
                />
                <FormDropDown
                    label={<FormattedMessage id='BPP and Building Deductible:' />}
                    value={{ code: this.props.policy.interWestGLPCCustomInfo.limitsInfo.generalLiabilityBPPAndBuildingDeductibleCode }}
                    references={this.props.reference?.references['InterWest-GLPC'] && this.props.reference.references['InterWest-GLPC'].GLBPPAndBuildingDeductible}
                    referencesEvent={() => this.props.reference.doGetReferences('InterWest-GLPC.GLBPPAndBuildingDeductible')}
                    changeEvent={value => {
                        this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.limitsInfo.generalLiabilityBPPAndBuildingDeductibleCode', value: value.code });
                    }}
                    allowEdit
                />
                <FormInput
                    label='Tenant Improvements & Betterments (TIB’s) Limit:'
                    value={this.props.policy.interWestGLPCCustomInfo.limitsInfo.tibLimitAmount}
                    type='money'
                    changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.limitsInfo.tibLimitAmount', value })}
                    allowEdit
                />
                <FormInput
                    label='Computers & Media Limit:'
                    value={this.props.policy.interWestGLPCCustomInfo.limitsInfo.computersMediaLimitAmount}
                    type='money'
                    changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.limitsInfo.computersMediaLimitAmount', value })}
                    allowEdit
                />
                <FormInput
                    label='Business Income Loss (per 12 months) Limit:'
                    value={this.props.policy.interWestGLPCCustomInfo.limitsInfo.businessIncomeLossLimitAmount}
                    type='money'
                    changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.limitsInfo.businessIncomeLossLimitAmount', value })}
                    allowEdit
                />
                <FormInput
                    label='Misc. Tools Limit (Inland Marine):'
                    value={this.props.policy.interWestGLPCCustomInfo.limitsInfo.toolsLimitAmount}
                    type='money'
                    changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.limitsInfo.toolsLimitAmount', value })}
                    allowEdit
                />
            </Form>
        );
    }
}

export default ProfileInterwestGlpcLimits;