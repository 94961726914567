import React from 'react';
import styled from 'styled-components';
import {
    Alignments,
    Sizes,
    Grid,
    Row,
    Column,
    Container,
    Separator,
    CarouselButton
} from 'metrix-common';

const ButtonContainer = styled(Container)`
    overflow: hidden;
`;

const Footer = ({ query, listPolicies, doReduceQueryElement, doQueryPolicies }) => {
    const handleLeftClick = () => {
        doReduceQueryElement({ code: 'search', value: true });
        doReduceQueryElement({ code: 'skip', value: query.list.skip - 25 });
        doQueryPolicies();
    };

    const handleRightClick = () => {
        doReduceQueryElement({ code: 'search', value: true });
        doReduceQueryElement({ code: 'skip', value: query.list.skip + 25 });
        doQueryPolicies();
    };

    return (
        <Grid>
            <Row noguttertop>
                <Column>
                    <Separator solid />
                </Column>
            </Row>
            <Row>
                <Column>
                    <ButtonContainer inline fullSize horizontalAlignment={Alignments.right} verticalAlignment={Alignments.center}>
                        {
                            query.list.skip > 0 &&
                            <CarouselButton direction='left' clickEvent={handleLeftClick} />
                        }
                        {
                            listPolicies?.length === 25 &&
                            <CarouselButton direction='right' left={Sizes.xs} clickEvent={handleRightClick} />
                        }
                    </ButtonContainer>
                </Column>
            </Row>
        </Grid>
    );
};

export default Footer;