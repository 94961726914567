import React from 'react';
import { FormattedMessage } from 'react-intl';

import {    
    BackButton,
    Form, 
    FormInput,
    FormRadio,
    FormDropDown,
    FormSwitch,
    convertFromBoolean,
    convertToBoolean
} from 'metrix-common';

class ProfileEmployersWcOwner extends React.Component {
    componentDidMount() {
        if (this.props?.legalEntityTypeCode) {
            this.props.reference.doGetReferences(`EMPLOYERS-WC.OwnerTitles.${this.props?.legalEntityTypeCode}`);
        }
    }

    render() {
        const ownerName = this.props.owner?.ownerTypeCode !== undefined ? this.props.owner?.ownerTypeValue : this.props.owner?.ownerPageName !== undefined ? this.props.owner.ownerPageName : 'New Owner';
        
        return (
            <React.Fragment>
                <BackButton clickEvent={this.props.backEvent} />
                <Form
                    rowId={this.props.rowId}
                    pageCode={this.props.pageCode}
                    sectionCode='ownerDetails'
                    saveTooltipInfo={this.props.saveTooltipInfo}
                    reduceTooltipInfoElement={this.props.reduceTooltipInfoElement}
                    tooltipInfo={this.props.tooltipInfo}
                    validationInfo={this.props.validationInfo}
                    header={<FormattedMessage id={ownerName} />}
                    label={`Owner Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormInput
                        fieldCode='ownerFirstName'
                        label={`First name`}
                        value={this.props.owner?.ownerFirstName}
                        type='text'
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}ownerFirstName`, value })}
                        allowEdit
                    />
                    <FormInput
                        fieldCode='ownerLastName'
                        label={`Last name`}
                        value={this.props.owner?.ownerLastName}
                        type='text'
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}ownerLastName`, value })}
                        allowEdit
                    />
                    <FormDropDown
                        fieldCode='ownerTitleTypeCode'
                        label={<FormattedMessage id={`Owner Title`} />}
                        value={this.props.owner?.ownerTitleTypeCode && { code: this.props.owner.ownerTitleTypeCode, value: this.props.owner.ownerTitleTypeValue }}
                        references={this.props.reference?.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`OwnerTitles`][this.props?.legalEntityTypeCode]}
                        referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.OwnerTitles.${this.props?.legalEntityTypeCode}`)}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `${this.props.dotProp}ownerTitleTypeCode`, value: value.code });
                            this.props.doReducePolicyElement({ code: `${this.props.dotProp}ownerTitleTypeValue`, value: value.value });
                        }}
                        allowEdit
                    />
                    <FormInput
                        fieldCode='ownershipPercentageAmount'
                        label={`Ownership %`}
                        value={this.props.owner?.ownershipPercentageAmount}
                        type='number'
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}ownershipPercentageAmount`, value })}
                        allowEdit
                    />
                    <FormInput
                        fieldCode='ownershipSalaryAmount'
                        label={<FormattedMessage id={`Ownership Salary Amount: Required ONLY for the state 'MT' and Owner is Included. (Although we collect ownership salary here, their payroll should also be included in the appropriate class payrolls along with regular employees)`} />}
                        value={this.props.owner?.ownershipSalaryAmount}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}ownershipSalaryAmount`, value })}
                        type='number'
                        allowEdit
                    />
                    <FormSwitch
                        fieldCode='isOwnerIncluded'
                        label={<FormattedMessage id={`Owner inclusion/exclusion`} />}
                        value={this.props.owner?.isOwnerIncluded}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}isOwnerIncluded`, value: value })}
                        allowEdit
                    />
                </Form>

                <Form
                    pageCode={this.props.pageCode}
                    sectionCode='operationTypes'
                    saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                    reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                    tooltipInfo={this.props.tooltipInfo}
                    validationInfo={this.props.validationInfo}
                    header={<FormattedMessage id={'Owner Questions'} />}
                    label={'Details'}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    {/*OWN-1*/}
                    {
                        this.props.owner?.ownerQuestion.ownerQuestionStatus?.hasOWN1Question &&
                        <FormRadio
                            fieldCode='isIndOrUnsalExecOfficerOfNonprofit'
                            label={<FormattedMessage id={'Is the individual an unsalaried executive officer of an unincorporated not-for-profit association?'} />}
                            value={this.props.owner?.ownerQuestion && { code: convertFromBoolean(this.props.owner.ownerQuestion.isIndOrUnsalExecOfficerOfNonprofit) }}
                            references={this.props.reference.references && this.props.reference.references['YesNo']}
                            referencesEvent={() => this.props.reference.doGetReferences('YesNo')}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}ownerQuestion.isIndOrUnsalExecOfficerOfNonprofit`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                    }
                    {/*OWN-2*/}
                    {
                        this.props.owner?.ownerQuestion.ownerQuestionStatus?.hasOWN2Question &&
                        <FormRadio
                            fieldCode='isIndOrExecOfficerOfRelCharEducMunCorpOrOrgVets'
                            label={<FormattedMessage id={'Is the individual an executive officers of religious, charitable, educational, or municipal corporation, an officer of any post or chapter of organizations of veterans of any war of the United States?'} />}
                            value={this.props.owner?.ownerQuestion && { code: convertFromBoolean(this.props.owner.ownerQuestion.isIndOrExecOfficerOfRelCharEducMunCorpOrOrgVets) }}
                            references={this.props.reference.references && this.props.reference.references['YesNo']}
                            referencesEvent={() => this.props.reference.doGetReferences('YesNo')}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}ownerQuestion.isIndOrExecOfficerOfRelCharEducMunCorpOrOrgVets`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                    }
                    {/*OWN-2-1*/}
                    {
                        (this.props.owner?.ownerQuestion.ownerQuestionStatus?.hasOWN2Question && this.props.owner?.ownerQuestion?.isIndOrExecOfficerOfRelCharEducMunCorpOrOrgVets === false) &&
                        <FormRadio
                            fieldCode='isIndOrUnsalExecOfficerOfNonprofitCorp'
                            label={<FormattedMessage id={'Is the individual an unsalaried executive officer of a non-profit corporation?'} />}
                            value={this.props.owner?.ownerQuestion && { code: convertFromBoolean(this.props.owner.ownerQuestion.isIndOrUnsalExecOfficerOfNonprofitCorp) }}
                            references={this.props.reference.references && this.props.reference.references['YesNo']}
                            referencesEvent={() => this.props.reference.doGetReferences('YesNo')}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}ownerQuestion.isIndOrUnsalExecOfficerOfNonprofitCorp`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                    }
                    {/*OWN-2-1-1*/}
                    {
                        (this.props.owner?.ownerQuestion.ownerQuestionStatus?.hasOWN2Question && this.props.owner?.ownerQuestion?.isIndOrExecOfficerOfRelCharEducMunCorpOrOrgVets === false && this.props.owner.ownerQuestion?.isIndOrUnsalExecOfficerOfNonprofitCorp === false) &&
                        <FormRadio
                            fieldCode='hasSoleExecOfficerOrTwoExecOfficersWith100PercentStock'
                            label={<FormattedMessage id={'Is there a sole executive officer that owns 100% of the stock or 2 executive officers that own 100% of the stock between them?'} />}
                            value={this.props.owner?.ownerQuestion && { code: convertFromBoolean(this.props.owner.ownerQuestion.hasSoleExecOfficerOrTwoExecOfficersWith100PercentStock) }}
                            references={this.props.reference.references && this.props.reference.references['YesNo']}
                            referencesEvent={() => this.props.reference.doGetReferences('YesNo')}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}ownerQuestion.hasSoleExecOfficerOrTwoExecOfficersWith100PercentStock`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                    }
                    {/*OWN-3*/}
                    {
                        this.props.owner?.ownerQuestion.ownerQuestionStatus?.hasOWN3Question &&
                        <FormRadio
                            fieldCode='isWorkingEmpAndTrusteeOfRevocableTrustAsGenPartner'
                            label={<FormattedMessage id={'Verify the following is true: Individual must be a working employee and Individual is the trustee of a revocable trust that is a General Partner, and has the power to revoke the trust\'s interest.'} />}
                            value={this.props.owner?.ownerQuestion && { code: convertFromBoolean(this.props.owner.ownerQuestion.isWorkingEmpAndTrusteeOfRevocableTrustAsGenPartner) }}
                            references={this.props.reference.references && this.props.reference.references['YesNo']}
                            referencesEvent={() => this.props.reference.doGetReferences('YesNo')}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}ownerQuestion.isWorkingEmpAndTrusteeOfRevocableTrustAsGenPartner`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                    }
                    {/*OWN-4*/}
                    {
                        this.props.owner?.ownerQuestion.ownerQuestionStatus?.hasOWN4Question &&
                        <FormRadio
                            fieldCode='isWorkingAsEmpWith10PercentStockAtLeast'
                            label={<FormattedMessage id={'Verify the following is true: Individual must be a working employee and an appointed officer or member of the Board of Directors, and Individual must own either: A) at least 10% of the  issued and outstanding stock, or B) at least 1% of the stock and has a parent, grandparent, sibling, spouse, or child who owns at least 10% of the issued and outstanding stock of the corporation and the individual is covered by a health insurance policy or a health service plan.'} />}
                            value={this.props.owner?.ownerQuestion && { code: convertFromBoolean(this.props.owner.ownerQuestion.isWorkingAsEmpWith10PercentStockAtLeast) }}
                            references={this.props.reference.references && this.props.reference.references['YesNo']}
                            referencesEvent={() => this.props.reference.doGetReferences('YesNo')}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}ownerQuestion.isWorkingAsEmpWith10PercentStockAtLeast`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                    }
                    {/*OWN-5*/}
                    {
                        this.props.owner?.ownerQuestion.ownerQuestionStatus?.hasOWN5Question &&
                        <FormRadio
                            fieldCode='isWorkingAsEmpWithoutStockWithPowerToRevoke'
                            label={<FormattedMessage id={'Verify the following is true: Individual must be a working employee and an appointed officer or member of the Board of Directors, and Individual owns no stock but is the trustee of a revocable trust that is a stockholder, and has the power to revoke the trust\'s share.'} />}
                            value={this.props.owner?.ownerQuestion && { code: convertFromBoolean(this.props.owner.ownerQuestion.isWorkingAsEmpWithoutStockWithPowerToRevoke) }}
                            references={this.props.reference.references && this.props.reference.references['YesNo']}
                            referencesEvent={() => this.props.reference.doGetReferences('YesNo')}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}ownerQuestion.isWorkingAsEmpWithoutStockWithPowerToRevoke`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                    }
                    {/*OWN-6*/}
                    {
                        this.props.owner?.ownerQuestion.ownerQuestionStatus?.hasOWN6Question &&
                        <FormRadio
                            fieldCode='isWorkingAsEmpWithStockForWhichCompanyWasCreated'
                            label={<FormattedMessage id={'Verify the following is true: Individual must be a working employee that owns stock and is a practitioner rendering professional services for which the corporation was organized, and is covered by a health care service plan or health insurance policy.'} />}
                            value={this.props.owner?.ownerQuestion && { code: convertFromBoolean(this.props.owner.ownerQuestion.isWorkingAsEmpWithStockForWhichCompanyWasCreated) }}
                            references={this.props.reference.references && this.props.reference.references['YesNo']}
                            referencesEvent={() => this.props.reference.doGetReferences('YesNo')}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}ownerQuestion.isWorkingAsEmpWithStockForWhichCompanyWasCreated`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                    }
                    {/*OWN-7*/}
                    {
                        this.props.owner?.ownerQuestion.ownerQuestionStatus?.hasOWN7Question &&
                        <FormRadio
                            fieldCode='isWorkingAsEmpAndHasComparableInScopeHealthInsurance'
                            label={<FormattedMessage id={'Verify the following is true: A) Individual must be a working employee and an appointed officer or member of the Board of Directors, and B) must be covered by a health care service plan or health insurance policy and a disability plan that is comparable in scope and coverage to a workers compensation policy.'} />}
                            value={this.props.owner?.ownerQuestion && { code: convertFromBoolean(this.props.owner.ownerQuestion.isWorkingAsEmpAndHasComparableInScopeHealthInsurance) }}
                            references={this.props.reference.references && this.props.reference.references['YesNo']}
                            referencesEvent={() => this.props.reference.doGetReferences('YesNo')}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}ownerQuestion.isWorkingAsEmpAndHasComparableInScopeHealthInsurance`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                    }
                </Form>
            </React.Fragment>
        );
    }
}

export default ProfileEmployersWcOwner;