import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Form, FormChecklist,
    FormInput, FormSwitch
} from 'metrix-common';

class ProfileInterwestPlMemberInfo extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            <>
                {
                    this.props.policy.interWestPLCustomInfo.businessInfo.hasOverOneHundredRevenue &&
                    <Form
                        header={<FormattedMessage id='Member Information' />}
                        label={<FormattedMessage id='Additional Services' />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormSwitch
                            label={<FormattedMessage id='Are there any practicing lawyers, real estate agents or brokers, registered investment advisers, or securities agents or brokers working at the firm?' />}
                            value={this.props.policy.interWestPLCustomInfo.memberInfo.hasMemberAdditionalServices ?? false}
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.memberInfo.hasMemberAdditionalServices', value: value });
                                }}
                            allowEdit
                        />
                        {
                            this.props.policy.interWestPLCustomInfo.memberInfo.hasMemberAdditionalServices &&
                            <FormInput
                                label={<FormattedMessage id='First Name'/>}
                                value={this.props.policy.interWestPLCustomInfo.memberInfo.additionalServicesEmployeeInformationOneFirst}
                                type='text'
                                changeEvent={
                                    value => {
                                        this.props.doReducePolicyElement({
                                            code: 'interWestPLCustomInfo.memberInfo.additionalServicesEmployeeInformationOneFirst',
                                            value: value
                                        });
                                    }}
                                allowEdit
                            />
                        }
                        {
                            this.props.policy.interWestPLCustomInfo.memberInfo.hasMemberAdditionalServices &&
                            <FormInput
                                label={<FormattedMessage id='Last Name' />}
                                value={this.props.policy.interWestPLCustomInfo.memberInfo.additionalServicesEmployeeInformationOneLast}
                                type='text'
                                changeEvent={
                                    value => {
                                        this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.memberInfo.additionalServicesEmployeeInformationOneLast', value: value });
                                    }}
                                allowEdit
                            />
                        }
                        {
                            this.props.policy.interWestPLCustomInfo.memberInfo.hasMemberAdditionalServices &&
                            <FormInput
                                label={<FormattedMessage id='Type(s) of License(s)' />}
                                value={this.props.policy.interWestPLCustomInfo.memberInfo.additionalServicesEmployeeInformationOneTypeOfLicense}
                                type='text'
                                changeEvent={
                                    value => {
                                        this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.memberInfo.additionalServicesEmployeeInformationOneTypeOfLicense', value: value });
                                    }}
                                allowEdit
                            />
                        }
                        {
                            this.props.policy.interWestPLCustomInfo.memberInfo.hasMemberAdditionalServices &&
                            <FormInput
                                label={<FormattedMessage id='Type(s) of Service(s) Provided' />}
                                value={this.props.policy.interWestPLCustomInfo.memberInfo.additionalServicesEmployeeInformationOneTypesOfServicesProvided}
                                type='text'
                                changeEvent={
                                    value => {
                                        this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.memberInfo.additionalServicesEmployeeInformationOneTypesOfServicesProvided', value: value });
                                    }}
                                allowEdit
                            />
                        }
                        {
                            this.props.policy.interWestPLCustomInfo.memberInfo.hasMemberAdditionalServices &&
                            <FormSwitch
                                label={<FormattedMessage id='Is separate Professional Liability Insurance in place?' />}
                                value={this.props.policy.interWestPLCustomInfo.memberInfo.isAdditionalServicesEmployeeInsuredOne ?? false}
                                changeEvent={
                                    value => {
                                        this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.memberInfo.isAdditionalServicesEmployeeInsuredOne', value: value });
                                    }}
                                allowEdit
                            />
                        }
                        {
                            this.props.policy.interWestPLCustomInfo.memberInfo.hasMemberAdditionalServices &&
                            this.props.policy.interWestPLCustomInfo.memberInfo.isAdditionalServicesEmployeeInsuredOne &&
                            <FormInput
                                label={<FormattedMessage id='Insurer' />}
                                value={this.props.policy.interWestPLCustomInfo.memberInfo.additionalServicesEmployeeInformationOneProfessionalLiabilityInsurer}
                                type='text'
                                changeEvent={
                                    value => {
                                        this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.memberInfo.additionalServicesEmployeeInformationOneProfessionalLiabilityInsurer', value: value });
                                    }}
                                allowEdit
                            />
                        }
                        {
                            this.props.policy.interWestPLCustomInfo.memberInfo.hasMemberAdditionalServices &&
                            this.props.policy.interWestPLCustomInfo.memberInfo.isAdditionalServicesEmployeeInsuredOne &&
                            <FormInput
                                label={<FormattedMessage id='Limit Amount' />}
                                value={this.props.policy.interWestPLCustomInfo.memberInfo.additionalServicesEmployeeInformationOneLiabilityLimitAmount}
                                type='number'
                                changeEvent={
                                    value => {
                                        this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.memberInfo.additionalServicesEmployeeInformationOneLiabilityLimitAmount', value: value });
                                    }}
                                allowEdit
                            />
                        }
                        {
                            this.props.policy.interWestPLCustomInfo.memberInfo.hasMemberAdditionalServices &&
                            this.props.policy.interWestPLCustomInfo.memberInfo.isAdditionalServicesEmployeeInsuredOne &&
                            <FormSwitch
                                label={<FormattedMessage id='Would you like to add another?' />}
                                value={this.props.policy.interWestPLCustomInfo.memberInfo.hasAnotherMemberAdditionalServices ?? false}
                                changeEvent={
                                    value => {
                                        this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.memberInfo.hasAnotherMemberAdditionalServices', value: value });
                                    }}
                                allowEdit
                            />
                        }
                        {
                            this.props.policy.interWestPLCustomInfo.memberInfo.hasMemberAdditionalServices &&
                            this.props.policy.interWestPLCustomInfo.memberInfo.isAdditionalServicesEmployeeInsuredOne &&
                            this.props.policy.interWestPLCustomInfo.memberInfo.hasAnotherMemberAdditionalServices &&
                            <FormInput
                                label={<FormattedMessage id='First Name' />}
                                value={this.props.policy.interWestPLCustomInfo.memberInfo.additionalServicesEmployeeInformationTwoFirst}
                                type='text'
                                changeEvent={
                                    value => {
                                        this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.memberInfo.additionalServicesEmployeeInformationTwoFirst', value: value });
                                    }}
                                allowEdit
                            />
                        }
                        {
                            this.props.policy.interWestPLCustomInfo.memberInfo.hasMemberAdditionalServices &&
                            this.props.policy.interWestPLCustomInfo.memberInfo.isAdditionalServicesEmployeeInsuredOne &&
                            this.props.policy.interWestPLCustomInfo.memberInfo.hasAnotherMemberAdditionalServices &&
                            <FormInput
                                label={<FormattedMessage id='Last Name' />}
                                value={this.props.policy.interWestPLCustomInfo.memberInfo.additionalServicesEmployeeInformationTwoLast}
                                type='text'
                                changeEvent={
                                    value => {
                                        this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.memberInfo.additionalServicesEmployeeInformationTwoLast', value: value });
                                    }}
                                allowEdit
                            />
                        }
                        {
                            this.props.policy.interWestPLCustomInfo.memberInfo.hasMemberAdditionalServices &&
                            this.props.policy.interWestPLCustomInfo.memberInfo.isAdditionalServicesEmployeeInsuredOne &&
                            this.props.policy.interWestPLCustomInfo.memberInfo.hasAnotherMemberAdditionalServices &&
                            <FormInput
                                label={<FormattedMessage id='Type(s) of License(s)' />}
                                value={this.props.policy.interWestPLCustomInfo.memberInfo.additionalServicesEmployeeInformationTwoTypeOfLicense}
                                type='text'
                                changeEvent={
                                    value => {
                                        this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.memberInfo.additionalServicesEmployeeInformationTwoTypeOfLicense', value: value });
                                    }}
                                allowEdit
                            />
                        }
                        {
                            this.props.policy.interWestPLCustomInfo.memberInfo.hasMemberAdditionalServices &&
                            this.props.policy.interWestPLCustomInfo.memberInfo.isAdditionalServicesEmployeeInsuredOne &&
                            this.props.policy.interWestPLCustomInfo.memberInfo.hasAnotherMemberAdditionalServices &&
                            <FormInput
                                label={<FormattedMessage id='Type(s) of Service(s) Provided' />}
                                value={this.props.policy.interWestPLCustomInfo.memberInfo.additionalServicesEmployeeInformationTwoTypesOfServicesProvided}
                                type='text'
                                changeEvent={
                                    value => {
                                        this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.memberInfo.additionalServicesEmployeeInformationTwoTypesOfServicesProvided', value: value });
                                    }}
                                allowEdit
                            />
                        }
                        {
                            this.props.policy.interWestPLCustomInfo.memberInfo.hasMemberAdditionalServices &&
                            this.props.policy.interWestPLCustomInfo.memberInfo.isAdditionalServicesEmployeeInsuredOne &&
                            this.props.policy.interWestPLCustomInfo.memberInfo.hasAnotherMemberAdditionalServices &&
                            <FormSwitch
                                label={<FormattedMessage id='Is separate Professional Liability Insurance in place?' />}
                                value={this.props.policy.interWestPLCustomInfo.memberInfo.isAdditionalServicesEmployeeInsuredTwo ?? false}
                                changeEvent={
                                    value => {
                                        this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.memberInfo.isAdditionalServicesEmployeeInsuredTwo', value: value });
                                    }}
                                allowEdit
                            />
                        }
                        {
                            this.props.policy.interWestPLCustomInfo.memberInfo.hasMemberAdditionalServices &&
                            this.props.policy.interWestPLCustomInfo.memberInfo.isAdditionalServicesEmployeeInsuredOne &&
                            this.props.policy.interWestPLCustomInfo.memberInfo.hasAnotherMemberAdditionalServices &&
                            this.props.policy.interWestPLCustomInfo.memberInfo.isAdditionalServicesEmployeeInsuredTwo &&
                            <FormInput
                                label={<FormattedMessage id='Insurer' />}
                                value={this.props.policy.interWestPLCustomInfo.memberInfo.additionalServicesEmployeeInformationTwoProfessionalLiabilityInsurer}
                                type='text'
                                changeEvent={
                                    value => {
                                        this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.memberInfo.additionalServicesEmployeeInformationTwoProfessionalLiabilityInsurer', value: value });
                                    }}
                                allowEdit
                            />
                        }
                        {
                            this.props.policy.interWestPLCustomInfo.memberInfo.hasMemberAdditionalServices &&
                            this.props.policy.interWestPLCustomInfo.memberInfo.isAdditionalServicesEmployeeInsuredOne &&
                            this.props.policy.interWestPLCustomInfo.memberInfo.hasAnotherMemberAdditionalServices &&
                            this.props.policy.interWestPLCustomInfo.memberInfo.isAdditionalServicesEmployeeInsuredTwo &&
                            <FormInput
                                label={<FormattedMessage id='Limit Amount' />}
                                value={this.props.policy.interWestPLCustomInfo.memberInfo.additionalServicesEmployeeInformationTwoLiabilityLimitAmount}
                                type='number'
                                changeEvent={
                                    value => {
                                        this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.memberInfo.additionalServicesEmployeeInformationTwoLiabilityLimitAmount', value: value });
                                    }}
                                allowEdit
                            />
                        }
                    </Form>
                }
                <Form
                    label={<FormattedMessage id='Claim Knowledge' />}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormSwitch
                        label={<FormattedMessage id='Does any person seeking coverage under this proposed policy have knowledge of any incident, act, error, or omission involving professional services that could reasonably be expected to be the basis of a claim?' />}
                        value={this.props.policy.interWestPLCustomInfo.memberInfo.doesKnowledgeOfPossibleClaim ?? false}
                        changeEvent={
                            value => {
                                this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.memberInfo.doesKnowledgeOfPossibleClaim', value: value });
                            }}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id='Has any claim involving professional services been made against the firm, any predecessor firm, or any current or former partner, officer, shareholder, or employee in the past 5 years or earlier if the claim is still pending?' />}
                        value={this.props.policy.interWestPLCustomInfo.memberInfo.hasMemberHaveAnyClaims ?? false}
                        changeEvent={
                            value => {
                                this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.memberInfo.hasMemberHaveAnyClaims', value: value });
                            }}
                        allowEdit
                    />
                </Form>
                <Form
                    label={<FormattedMessage id='Felonies or Suspensions' />}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormChecklist
                        label={<FormattedMessage id='Please check all that apply' />}
                        value={this.props.policy.interWestPLCustomInfo.memberInfo.memberSuspendedFelonySelectedItems}
                        references={this.props.reference.references['InterWest-PL'] && this.props.reference.references['InterWest-PL'].MemberSuspendedFelony}
                        referencesEvent={() => this.props.reference.doGetReferences('InterWest-PL.MemberSuspendedFelony')}
                        changeEvent={values => {
                            this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.memberInfo.memberSuspendedFelonySelectedItems', value: values });
                        }}
                        allowEdit
                    />
                    {
                        this.props.policy.interWestPLCustomInfo.memberInfo.memberSuspendedFelonySelectedItems?.length &&
                        <FormInput
                            label={<FormattedMessage id='Please describe what occurred' />}
                            value={this.props.policy.interWestPLCustomInfo.memberInfo.suspendedOrFelonyDescription}
                            type='text'
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.memberInfo.suspendedOrFelonyDescription', value: value });
                                }}
                            allowEdit
                        />
                    }
                </Form>
                <Form
                    label={<FormattedMessage id='Past 5 Years' />}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormChecklist
                        label={<FormattedMessage id='Please check all that are true' />}
                        value={this.props.policy.interWestPLCustomInfo.memberInfo.pastFiveYearsSelectedItems}
                        references={this.props.reference.references['InterWest-PL'] && this.props.reference.references['InterWest-PL'].PastFiveYears}
                        referencesEvent={() => this.props.reference.doGetReferences('InterWest-PL.PastFiveYears')}
                        changeEvent={values => {
                            this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.memberInfo.pastFiveYearsSelectedItems', value: values });
                        }}
                        allowEdit
                    />
                    {
                        this.props.policy.interWestPLCustomInfo.memberInfo.pastFiveYearsSelectedItems?.length &&
                        <FormInput
                            label={<FormattedMessage id='Please provide more details' />}
                            value={this.props.policy.interWestPLCustomInfo.memberInfo.pastFiveYearsDescription}
                            type='text'
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.memberInfo.pastFiveYearsDescription', value: value });
                                }}
                            allowEdit
                        />
                    }
                </Form>
            </>
        );
    }
}

export default ProfileInterwestPlMemberInfo;