import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    convertFromBoolean,
    convertToBoolean,
    Sizes,
    Alignments,
    BackButton,
    Divider,
    Header,
    Form,
    FormDropDown,
    FormInput,
    FormRadio
} from 'metrix-common';

class ProfileVehicleUse extends React.Component {
    render() {
        return (
            <React.Fragment>
                <BackButton clickEvent={this.props.backEvent} />
                <Divider top={Sizes.md} />
                <Header size={Sizes.md} textAlign={Alignments.left} text={`Vehicle Usage`} />
                <Form
                    label={`Driver Details`}
                    saveEvent={() => this.props.doUpsertPolicy()}
                    cancelEvent={() => this.props.doGetPolicy()}
                    allowEdit={this.props.allowEdit}
                >
                    <FormDropDown
                        label={<FormattedMessage id={`Driver`} />}
                        value={this.props.vehicleUse &&
                            {
                                code: this.props.vehicleUse.driver
                            }
                        }
                        references={this.props.drivers}
                        referencesEvent={() => { }}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `${this.props.dotProp}driver`, value: value.code });
                        }}
                        allowEdit
                    />
                    <FormInput
                        label={<FormattedMessage id={`Use Percentage`} />}
                        value={this.props.vehicleUse &&
                            this.props.vehicleUse.percentage
                        }
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `${this.props.dotProp}percentage`, value });
                        }}
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={`Primary Driver`} />}
                        value={this.props.vehicleUse &&
                            { code: convertFromBoolean(this.props.vehicleUse.primary) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}primary`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={`Vehicle Owner`} />}
                        value={this.props.vehicleUse &&
                            { code: convertFromBoolean(this.props.vehicleUse.owner) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}owner`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                </Form>
            </React.Fragment>
        );
    }
}

export default ProfileVehicleUse;