import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import {
    Layout,
    withAnalytics,
    withSagas,
    AuthenticatedProvider,
    DocumentProvider,
    isDesktop,
    getScreenSize
} from 'metrix-common';

import policySaga from '../../sagas/policy/saga';
import paymentSaga from '../../sagas/payment/saga';

import AppBar from '../../components/AppBar';
import { getPolicy } from '../../actions/policy/getPolicy';
import { getLogs } from '../../actions/policy/getLogs';
import { getLogContent } from '../../actions/policy/getLogContent';
import { queryPolicy } from '../../actions/policy/queryPolicy';
import { executePolicyAction } from '../../actions/policy/executePolicyAction';
import { executePolicyTransactionCustomAction } from '../../actions/policy/executePolicyTransactionCustomAction';
import { upsertPolicy } from '../../actions/policy/upsertPolicy';
import { reducePolicyElement } from '../../actions/policy/reducePolicyElement';
import { reduceEntity } from '../../actions/policy/reduceEntity';
import { selectPolicy } from '../../selectors/policy/selectPolicy';
import { selectPolicySummary } from '../../selectors/policy/selectPolicySummary';
import { selectPolicyHistory } from '../../selectors/policy/selectPolicyHistory';
import { selectPolicyLogs } from '../../selectors/policy/selectPolicyLogs';
import { selectEntity } from '../../selectors/policy/selectEntity';
import { selectPolicyValidationInfo } from '../../selectors/policy/selectPolicyValidationInfo';
import { getPolicySummary } from '../../actions/policy/getPolicySummary';
import { getPolicyValidationInfo } from '../../actions/policy/getPolicyValidationInfo';
import { getTooltipInfo } from '../../actions/policy/getTooltipInfo';
import { selectParameters } from '../../selectors/policy/selectParameters';
import { reduceParameter } from '../../actions/policy/reduceParameter';
import { reduceParameters } from '../../actions/policy/reduceParameters';
import { selectTooltipInfo } from '../../selectors/policy/selectTooltipInfo';
import { upsertTooltipInfo } from "../../actions/policy/upsertTooltipInfo";
import { reduceTooltipInfoElement } from "../../actions/policy/reduceTooltipInfoElement";
import { getOpta } from '../../actions/policy/getOpta';
import { selectAllowEdit } from '../../selectors/policy/selectAllowEdit';
import { sendEmailRequest } from '../../actions/policy/sendEmailRequest';
import { updatePlace } from '../../actions/policy/updatePlace';
import { selectRiskChanged } from '../../selectors/policy/selectRiskChanged';
import { sendPaymentLink } from '../../actions/payment/sendPaymentLink';
import { getPaymentEmail } from '../../actions/payment/getPaymentEmail';
import { selectPaymentEmail } from '../../selectors/payment/selectPaymentEmail';
import { reducePaymentEmail } from '../../actions/payment/reducePaymentEmail';
import { getNotes } from '../../actions/policy/getNotes';
import { upsertNote } from '../../actions/policy/upsertNotes';
import { selectNotes } from '../../selectors/policy/selectNotes';
import { getPaymentTransferResultRecords } from '../../actions/payment/getPaymentTransferResultRecords';
import { createPaymentTransferResultRecord } from '../../actions/payment/createPaymentTransferResultRecord';
import { selectPaymentTransferResultRecords } from '../../selectors/payment/selectPaymentTransferResultRecords';
import { selectPaymentTransferResultReadOnly } from '../../selectors/payment/selectPaymentTransferResultReadOnly';
import { reducePaymentTransferResultRecords } from '../../actions/payment/reducePaymentTransferResultRecords';
import { selectPaymentTransferResultErrors } from '../../selectors/payment/selectPaymentTransferResultErrors';
import { selectPaymentTransferResultHidden } from '../../selectors/payment/selectPaymentTransferResultHidden';

import { selectProductSettings } from '../../selectors/policy/selectProductSettings';

import ProfileEmployersWcBusiness from '../../components/Profile/Employers/WC/Business';
import ProfileEmployersWcPriorInsurance from '../../components/Profile/Employers/WC/InsuranceHistory';
import ProfileEmployersWcInsureds from '../../components/Profile/Employers/WC/Insureds';
import ProfileEmployersWcCoverage from '../../components/Profile/Employers/WC/Coverage';
import ProfileEmployersWcAdditionalInformation from '../../components/Profile/Employers/WC/AdditionalInformation';
import ProfileEmployersWcDisclaimer from '../../components/Profile/Employers/WC/Disclaimer';

import ProfileEmployersWC2Business from '../../components/Profile/Employers/WC2/Business';
import ProfileEmployersWC2PriorInsurance from '../../components/Profile/Employers/WC2/InsuranceHistory';
import ProfileEmployersWC2Insureds from '../../components/Profile/Employers/WC2/Insureds';
import ProfileEmployersWC2Coverage from '../../components/Profile/Employers/WC2/Coverage';
import ProfileEmployersWC2AdditionalInformation from '../../components/Profile/Employers/WC2/AdditionalInformation';
import ProfileEmployersWC2Disclaimer from '../../components/Profile/Employers/WC2/Disclaimer';

import ProfileBtisWcBusiness from '../../components/Profile/BTIS/WC/Business';
import ProfileBtisWcCoverage from '../../components/Profile/BTIS/WC/Coverage';
import ProfileBtisWcLocations from '../../components/Profile/BTIS/WC/Locations';
import ProfileBtisWcInsuranceHistory from '../../components/Profile/BTIS/WC/InsuranceHistory';
import ProfileBtisWcCreditOpportunity from '../../components/Profile/BTIS/WC/CreditOpportunity';
import ProfileBtisWcAdditionalInformation from '../../components/Profile/BTIS/WC/AdditionalInformation';

import ProfileEmphasizedCoverageSummary from '../../components/Profile/ProfileEmphasizedCoverageSummary';
import ProfileFluxGlCannabisExtraction from '../../components/Profile/FluxGl/CannabisExtraction';
import ProfileFluxGlCultivationLighting from '../../components/Profile/FluxGl/CultivationLighting';
import ProfileFluxGlCultivationOperations from '../../components/Profile/FluxGl/CultivationOperations';
import ProfileFluxGlBusiness from '../../components/Profile/FluxGl/Business';
import ProfileFluxGlInsuranceHistory from '../../components/Profile/FluxGl/InsuranceHistory';
import ProfileFluxGlProductSelection from '../../components/Profile/FluxGl/ProductSelection';
import ProfileFluxGlGoodsInTransit from '../../components/Profile/FluxGl/GoodsInTransit';
import ProfileFluxGlProductLiability from '../../components/Profile/FluxGl/ProductLiability';
import ProfileFluxLocations from '../../components/Profile/FluxGl/Locations';
import ProfileFluxGlLessorsRisk from '../../components/Profile/FluxGl/LessorsRisk';
import ProfileFluxGlManufacturing from '../../components/Profile/FluxGl/Manufacturing';
import ProfileFluxGlProcessing from '../../components/Profile/FluxGl/Processing';
import ProfileFluxGlCannabisRisk from '../../components/Profile/FluxGl/CannabisRisk';
import ProfileFluxGlExcessLiability from '../../components/Profile/FluxGl/ExcessLiability';
import ProfileFluxGlHiredNonOwnedAuto from '../../components/Profile/FluxGl/HiredNonOwnedAuto';
import ProfileFluxGlGeneralLiability from '../../components/Profile/FluxGl/GeneralLiability';
import ProfileFluxGlRetailOperation from '../../components/Profile/FluxGl/RetailOperation';
import LiabilityExposures from '../../components/Profile/LiabilityExposures';
import ProfileRenewal from '../../components/Profile/Renewal';
import ProfileSideBar from '../../components/Profile/SideBar';
import ProfilePolicy from '../../components/Profile/Policy';
import ProfileApplicant from '../../components/Profile/Applicant';
import ProfileBusiness from '../../components/Profile/Business';
import ProfileClassification from '../../components/Profile/Classification';
import ProfileDrivers from '../../components/Profile/Drivers';
import ProfileVehicles from '../../components/Profile/Vehicles';
import ProfileResidents from '../../components/Profile/Residents';
import ProfileDwellings from '../../components/Profile/Dwellings';
import ProfilePayment from '../../components/Profile/Payment';
import ProfileDocuments from '../../components/Profile/Documents';
import ProfileNotes from '../../components/Profile/Notes';
import ProfileHistory from '../../components/Profile/History';
import ProfileLogs from '../../components/Profile/Logs';
import ProfileSummary from '../../components/Profile/Summary';

import ProfileCGClassification from '../../components/Profile/CG/Classification';
import ProfileCGOther from '../../components/Profile/CG/Other';

import ProfileInterwestCyberBusiness from '../../components/Profile/Interwest-cyber/Business';
import ProfileInterwestCyberCyber from '../../components/Profile/Interwest-cyber/Cyber';
import ProfileInterwestCyberLimit from '../../components/Profile/Interwest-cyber/Limit';
import ProfileInterwestCyberInsuranceHistory from '../../components/Profile/Interwest-cyber/InsuranceHistory';
import ProfileInterwestCyberAuthorizedSigner from '../../components/Profile/Interwest-cyber/AuthorizedSigner';

import ProfileInterwestGlpcBusiness from '../../components/Profile/Interwest-glpc/Business';
import ProfileInterwestGlpcContact from '../../components/Profile/Interwest-glpc/Contact';
import ProfileInterwestGlpcPriorInsurance from '../../components/Profile/Interwest-glpc/PriorInsurance';
import ProfileInterwestGlpcLimits from '../../components/Profile/Interwest-glpc/Limits';
import ProfileInterwestGlpcLocation from '../../components/Profile/Interwest-glpc/Location';

import ProfileInterwestPlAreaOfPractice from '../../components/Profile/Interwest-pl/AreaOfPractice';
import ProfileInterwestPlBusinessInfo from '../../components/Profile/Interwest-pl/BusinessInfo';
import ProfileInterwestPlClientInfo from '../../components/Profile/Interwest-pl/ClientInfo';
import ProfileInterwestPlLossPrevention from '../../components/Profile/Interwest-pl/LossPrevention';
import ProfileInterwestPlMemberInfo from '../../components/Profile/Interwest-pl/MemberInfo';
import ProfileInterwestPlPriorInsurance from '../../components/Profile/Interwest-pl/PriorInsurance';
import ProfileInterwestPlAuthorizedSigner from '../../components/Profile/Interwest-pl/AuthorizedSigner';

import ProfileInterwestFpBusinessInfo from '../../components/Profile/Interwest-fp/BusinessInfo';
import ProfileInterwestFpOperations from '../../components/Profile/Interwest-fp/Operations';
import ProfileInterwestFpCoverage from '../../components/Profile/Interwest-fp/Coverage';
import ProfileInterwestFpAdditionalCoverages from "../../components/Profile/Interwest-fp/OtherCoverages";

import ProfileInterwestFp2Info from '../../components/Profile/Interwest-fp2/FitPakInfo';
import ProfileInterwestFp2AdditionalLocations from '../../components/Profile/Interwest-fp2/additionalLocations';

import ProfileSecureMyOfferOfferInfo from "../../components/SecureMyOffer/OfferInfo";

import ProfileSupernovaCommercialBusinessInfo from '../../components/Profile/SupernovaCommercial/BusinessInfo';
import ProfileSupernovaCommercialInsuranceHistory from '../../components/Profile/SupernovaCommercial/InsuranceHistory';
import ProfileSupernovaCommercialCoverages from '../../components/Profile/SupernovaCommercial/Coverages';

import ProfileSupernovaCommercialInsureds from '../../components/Profile/SupernovaCommercial/Insureds';
import ProfileSupernovaCommercialLossPayees from '../../components/Profile/SupernovaCommercial/LossPayees';

import ProductHelper from '../../helpers'

const Footer = styled.div`
    ${({ apply }) => apply && `height: 60px;`}
`;

class Profile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: null,
            entity: null
        };
    };

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        const entity = params.get('entity');
        this.props.doReduceEntity(entity);
        this.props.doGetPolicySummary();
        this.props.doGetPolicy();
        this.props.doGetPolicyValidationInfo();
    }

    render() {
        const desktop = isDesktop(getScreenSize());
        const isAdmin = (this.props.context.session.userType === 'administrator');
        const isBroker = (this.props.context.session.userType === 'broker');
        const isRenewal = this.props.policySummary && (this.props.policySummary.type === 'renewal');
        const isQuoted = this.props.policySummary && (this.props.policySummary.status === 'quoted');
        const allowEdit = this.props.allowEdit;

        const baseProps = {
            changeTabTo: value => {
                window.location.hash = value;
                this.setState({ activeTab: value });
            },
            ...this.state,
            ...this.props
        };
        const commonProps = {
            pageCode: this.state.activeTab,
            allowEdit: this.props.allowEdit,
            policy: this.props.policy,
            doGetPolicy: this.props.doGetPolicy,
            doUpsertPolicy: this.props.doUpsertPolicy,
            doReducePolicyElement: this.props.doReducePolicyElement,
            reference: this.props.reference,
            doReduceTooltipInfoElement: this.props.doReduceTooltipInfoElement,
            tooltipInfo: this.props.tooltipInfo,
            validationInfo: this.props.validationInfo,
            doUpsertTooltipInfo: this.props.doUpsertTooltipInfo,
            doUpdatePlace: this.props.doUpdatePlace,
            context: this.props.context,
            productSettings: this.props.productSettings,
        };

        return (
            <AuthenticatedProvider {...this.props}>
                <Layout
                    fullScreen
                    appbar={<AppBar {...this.props} />}
                    loading={this.props.context.loading}
                    sideContent={
                        (isAdmin || isBroker) && this.props.policySummary &&
                        <ProfileSideBar activeTab={this.state.activeTab}
                                        setActiveTab={value => this.setState({ activeTab: value })}
                                        {...this.props}
                        />}
                >
                    {
                        this.props.policy &&
                        <>
                            <DocumentProvider {...this.props}>
                                {(isAdmin || isBroker) && this.state.activeTab === 'documents' && <ProfileDocuments  pageCode={this.state.activeTab} riskChanged={this.props.riskChanged} allowEdit={allowEdit} {...this.props} />}
                                {
                                    (this.state.activeTab === 'summary' || this.state.activeTab === null) && !isRenewal && !isQuoted &&
                                    <ProfileSummary
                                        pageCode={this.state.activeTab}
                                        allowEdit={allowEdit}
                                        doSendPaymentLink={this.props.doSendPaymentLink}
                                        doGetPaymentEmail={this.props.doGetPaymentEmail}
                                        doReducePaymentEmail={this.props.doReducePaymentEmail}
                                        doExecutePolicyTransactionCustomAction={this.props.doExecutePolicyTransactionCustomAction}
                                        paymentEmail={this.props.paymentEmail}
                                        {...baseProps}
                                    />
                                }
                                {
                                    (this.state.activeTab === 'summary' || this.state.activeTab === null) && !isRenewal && isQuoted &&
                                    <ProfileEmphasizedCoverageSummary
                                        pageCode={this.state.activeTab}
                                        allowEdit={allowEdit} {...baseProps}
                                        doSendPaymentLink={this.props.doSendPaymentLink}
                                        doGetPaymentEmail={this.props.doGetPaymentEmail}
                                        doReducePaymentEmail={this.props.doReducePaymentEmail}
                                        doExecutePolicyTransactionCustomAction={this.props.doExecutePolicyTransactionCustomAction}
                                        paymentEmail={this.props.paymentEmail}
                                    />
                                }
                                { (this.state.activeTab === 'renewal' || this.state.activeTab === null) && isRenewal && <ProfileRenewal {...baseProps} />}
                            </DocumentProvider>

                            {((isAdmin || isBroker) && this.state.activeTab === 'liabilityExposure' && ProductHelper.hasLiabilityExposures(this.props.policySummary?.product)) && <LiabilityExposures  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'policy' && <ProfilePolicy  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'applicant' && <ProfileApplicant  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}

                            {(isAdmin || isBroker) && this.state.activeTab === 'employers-wc.business' && <ProfileEmployersWcBusiness  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'employers-wc.insuranceHistory' && <ProfileEmployersWcPriorInsurance  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'employers-wc.insureds' && <ProfileEmployersWcInsureds  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'employers-wc.coverage' && <ProfileEmployersWcCoverage  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'employers-wc.additionalInformation' && <ProfileEmployersWcAdditionalInformation  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'employers-wc.disclaimer' && <ProfileEmployersWcDisclaimer  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}

                            {(isAdmin || isBroker) && this.state.activeTab === 'employers-wc-2.business' && <ProfileEmployersWC2Business  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'employers-wc-2.insuranceHistory' && <ProfileEmployersWC2PriorInsurance  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'employers-wc-2.insureds' && <ProfileEmployersWC2Insureds  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'employers-wc-2.coverage' && <ProfileEmployersWC2Coverage  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'employers-wc-2.additionalInformation' && <ProfileEmployersWC2AdditionalInformation  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'employers-wc-2.disclaimer' && <ProfileEmployersWC2Disclaimer  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}

                            {(isAdmin || isBroker) && this.state.activeTab === `btis-gl.business` && <ProfileBusiness  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `btis-gl.classification` && <ProfileClassification  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `btis-wc.business` && <ProfileBtisWcBusiness  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `btis-wc.coverage` && <ProfileBtisWcCoverage  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `btis-wc.locations` && <ProfileBtisWcLocations  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `btis-wc.insuranceHistory` && <ProfileBtisWcInsuranceHistory  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `btis-wc.creditOpportunity` && <ProfileBtisWcCreditOpportunity  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `btis-wc.additionalInformation` && <ProfileBtisWcAdditionalInformation  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `auto.drivers` && <ProfileDrivers  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `auto.vehicles` && <ProfileVehicles  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `usauto.drivers` && <ProfileDrivers  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `usauto.vehicles` && <ProfileVehicles  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `home.residents` && <ProfileResidents  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `home.dwellings` && <ProfileDwellings  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `ushome.residents` && <ProfileResidents  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `ushome.dwellings` && <ProfileDwellings  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `flux-gl.productSelection` && <ProfileFluxGlProductSelection  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `flux-gl.business` && <ProfileFluxGlBusiness  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `flux-gl.insuranceHistory` && <ProfileFluxGlInsuranceHistory  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `flux-gl.locations` && <ProfileFluxLocations  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `flux-gl.lessorsRisk` && <ProfileFluxGlLessorsRisk  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `flux-gl.manufacturing` && <ProfileFluxGlManufacturing  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `flux-gl.processing` && <ProfileFluxGlProcessing  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `flux-gl.cannabisRisk` && <ProfileFluxGlCannabisRisk  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `flux-gl.excessLiability` && <ProfileFluxGlExcessLiability  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `flux-gl.hiredNonOwnedAuto` && <ProfileFluxGlHiredNonOwnedAuto  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `flux-gl.generalLiability` && <ProfileFluxGlGeneralLiability  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `flux-gl.retailOperations` && <ProfileFluxGlRetailOperation  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `flux-gl.cannabisExtraction` && <ProfileFluxGlCannabisExtraction  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `flux-gl.cultivationLighting` && <ProfileFluxGlCultivationLighting  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `flux-gl.cultivationOperations` && <ProfileFluxGlCultivationOperations  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `flux-gl.goodsInTransit` && <ProfileFluxGlGoodsInTransit  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `flux-gl.productLiability` && <ProfileFluxGlProductLiability  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}

                            {(isAdmin || isBroker) && this.state.activeTab === 'sn-commercial.business' && <ProfileSupernovaCommercialBusinessInfo {...commonProps} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'sn-commercial.insuranceHistory' && <ProfileSupernovaCommercialInsuranceHistory {...commonProps} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'sn-commercial.coverages' && <ProfileSupernovaCommercialCoverages {...commonProps} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'sn-commercial.additionalInsureds' && <ProfileSupernovaCommercialInsureds pageCode={this.state.activeTab} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'sn-commercial.lossPayees' && <ProfileSupernovaCommercialLossPayees pageCode={this.state.activeTab} {...this.props} />}

                            {(isAdmin || isBroker) && this.state.activeTab === 'sn-small-business-insurance.business' && <ProfileSupernovaCommercialBusinessInfo {...commonProps} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'sn-small-business-insurance.insuranceHistory' && <ProfileSupernovaCommercialInsuranceHistory {...commonProps} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'sn-small-business-insurance.coverages' && <ProfileSupernovaCommercialCoverages {...commonProps} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'sn-small-business-insurance.additionalInsureds' && <ProfileSupernovaCommercialInsureds pageCode={this.state.activeTab} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'sn-small-business-insurance.lossPayees' && <ProfileSupernovaCommercialLossPayees pageCode={this.state.activeTab} {...this.props} />}

                            {(isAdmin || isBroker) && this.state.activeTab === 'interwest-cyber.business' && <ProfileInterwestCyberBusiness  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'interwest-cyber.cyber' && <ProfileInterwestCyberCyber  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'interwest-cyber.limit' && <ProfileInterwestCyberLimit  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'interwest-cyber.insuranceHistory' && <ProfileInterwestCyberInsuranceHistory  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'interwest-cyber.authorizedSigner' && <ProfileInterwestCyberAuthorizedSigner  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}

                            {(isAdmin || isBroker) && this.state.activeTab === 'interwest-glpc.business' && <ProfileInterwestGlpcBusiness  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'interwest-glpc.contact' && <ProfileInterwestGlpcContact  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'interwest-glpc.priorInsurance' && <ProfileInterwestGlpcPriorInsurance  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'interwest-glpc.limits' && <ProfileInterwestGlpcLimits  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'interwest-glpc.location' && <ProfileInterwestGlpcLocation  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}

                            {(isAdmin || isBroker) && this.state.activeTab === 'interwest-pl.business' && <ProfileInterwestPlBusinessInfo  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'interwest-pl.areaOfPractice' && <ProfileInterwestPlAreaOfPractice  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'interwest-pl.client' && <ProfileInterwestPlClientInfo  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'interwest-pl.lossPrevention' && <ProfileInterwestPlLossPrevention  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'interwest-pl.member' && <ProfileInterwestPlMemberInfo  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'interwest-pl.priorInsurance' && <ProfileInterwestPlPriorInsurance  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'interwest-pl.authorizedSigner' && <ProfileInterwestPlAuthorizedSigner  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}

                            {(isAdmin || isBroker) && this.state.activeTab === 'interwest-fp.business' && <ProfileInterwestFpBusinessInfo  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'interwest-fp.operations' && <ProfileInterwestFpOperations  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'interwest-fp.coverage' && <ProfileInterwestFpCoverage  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'interwest-fp.additionalCoverages' && <ProfileInterwestFpAdditionalCoverages  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}

                            {(isAdmin || isBroker) && this.state.activeTab === 'interwest-fp2.info' && <ProfileInterwestFp2Info  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === 'interwest-fp2.additionalLocations' && <ProfileInterwestFp2AdditionalLocations pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}

                            {(isAdmin || isBroker) && this.state.activeTab === 'securemyoffer.secureMyOffer' && <ProfileSecureMyOfferOfferInfo  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}

                            {(isAdmin || isBroker) && ProductHelper.hasCGOtherTabInMenu(this.state.activeTab) && <ProfileCGOther  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && ProductHelper.hasCGClassificationTabInMenu(this.state.activeTab) && <ProfileCGClassification  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}

                            {(isAdmin || isBroker) && this.state.activeTab === `payment` && <ProfilePayment pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {
                                (isAdmin || isBroker) && this.state.activeTab === `notes` &&
                                <ProfileNotes
                                    context={this.props.context}
                                    notes={this.props.notes}
                                    doGetNotes={this.props.doGetNotes}
                                    doUpsertNote={this.props.doUpsertNote}
                                />
                            }
                            {(isAdmin || isBroker) && this.state.activeTab === `history` && <ProfileHistory  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            {(isAdmin || isBroker) && this.state.activeTab === `logs` && <ProfileLogs  pageCode={this.state.activeTab} allowEdit={allowEdit} {...this.props} />}
                            <Footer apply={!desktop} />
                        </>
                    }
                </Layout>
            </AuthenticatedProvider>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    policy: selectPolicy(),
    policySummary: selectPolicySummary(),
    productSettings: selectProductSettings(),
    policyHistory: selectPolicyHistory(),
    policyLogs: selectPolicyLogs(),
    entity: selectEntity(),
    parameters: selectParameters(),
    validationInfo: selectPolicyValidationInfo(),
    tooltipInfo: selectTooltipInfo(),
    allowEdit: selectAllowEdit(),
    riskChanged: selectRiskChanged(),
    paymentEmail: selectPaymentEmail(),
    paymentTransferResultRecords: selectPaymentTransferResultRecords(),
    paymentTransferResultErrors: selectPaymentTransferResultErrors(),
    paymentTransferResultReadOnly: selectPaymentTransferResultReadOnly(),
    paymentTransfersResultHidden: selectPaymentTransferResultHidden(),
    notes: selectNotes()
});

function mapDispatchToProps(dispatch) {
    return {
        doGetPolicy: () => dispatch(getPolicy()),
        doGetPolicySummary: () => dispatch(getPolicySummary()),
        doGetPolicyValidationInfo: () => dispatch(getPolicyValidationInfo()),
        doReducePolicyElement: (payload) => dispatch(reducePolicyElement(payload)),
        doQueryPolicy: () => dispatch(queryPolicy()),
        doExecutePolicyAction: (payload) => dispatch(executePolicyAction(payload)),
        doExecutePolicyTransactionCustomAction: (payload) => dispatch(executePolicyTransactionCustomAction(payload)),
        doUpsertPolicy: () => dispatch(upsertPolicy()),
        doReduceEntity: (payload) => dispatch(reduceEntity(payload)),
        doReduceParameter: (payload) => dispatch(reduceParameter(payload)),
        doReduceParameters: (payload) => dispatch(reduceParameters(payload)),
        doGetLogs: (payload) => dispatch(getLogs(payload)),
        doGetLogContent: (payload) => dispatch(getLogContent(payload)),
        doGetTooltipInfo: (payload) => dispatch(getTooltipInfo(payload)),
        doReduceTooltipInfoElement: (payload) => dispatch(reduceTooltipInfoElement(payload)),
        doUpsertTooltipInfo: (payload) => dispatch(upsertTooltipInfo(payload)),
        doGetOpta: () => dispatch(getOpta()),
        doSendEmailRequest: (payload) => dispatch(sendEmailRequest(payload)),
        doUpdatePlace: (payload) => dispatch(updatePlace(payload)),
        doSendPaymentLink: () => dispatch(sendPaymentLink()),
        doGetPaymentEmail: () => dispatch(getPaymentEmail()),
        doReducePaymentEmail: payload => dispatch(reducePaymentEmail(payload)),
        doGetPaymentTransferResultRecords: () => dispatch(getPaymentTransferResultRecords()),
        doCreatePaymentTransferResultRecord: () => dispatch(createPaymentTransferResultRecord()),
        doReducePaymentTransferResultRecords: payload => dispatch(reducePaymentTransferResultRecords(payload)),
        doGetNotes: payload => dispatch(getNotes(payload)),
        doUpsertNote: payload => dispatch(upsertNote(payload))
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withPolicySaga = withSagas({ key: 'policy', saga: policySaga });
const withPaymentSaga = withSagas({ key: 'payment', saga: paymentSaga });

const WrappedProfile = withAnalytics(Profile, true);

export default compose(withConnect, withPolicySaga, withPaymentSaga)(WrappedProfile);