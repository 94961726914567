import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Alert,
    Form,
    Sizes,
    FormInput,
    NameForm,
    AddressForm,
    CheckBox,
    FormFullPhoneNumber,
    Header,
    Label,
    Alignments,
    GenericModal,
    getPageValidationInfo, Container
} from 'metrix-common';

class ProfileApplicant extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sameMailingAddress: false,
            address: undefined,
            showPopup: false
        };
    };

    componentDidMount() {
        this.props.doGetPolicy();
        this.setState({ address: this.props.policy?.participants?.insured?.party?.address })

        const pageHasTooltipInfo = this.props.tooltipInfo?.pages?.length && this.props.tooltipInfo.pages.some(p => p.code === this.props.pageCode);
        if (!pageHasTooltipInfo) {
            this.props.doGetTooltipInfo(this.props.policy.product);
        }
    }

    /*validateCommunication(email) {
        let valid = true;

        if (email) {
            valid = validateEmail(email);
        }

        return valid;
    }*/

    saveAddressHandler = () => {
        this.props.doUpsertPolicy();

        if (this.props.policy?.product === 'employers-wc' || this.props.policy?.product === 'employers-wc-2') {
            this.setState(prevState => {
                if (prevState.address?.state !== undefined && prevState.address?.state !== this.props.policy?.participants?.insured?.party?.address?.state) this.setState({ showPopup: true });
            })
        }
    }

    render() {
        const { error, warning } = getPageValidationInfo(this.props.pageCode, this.props.validationInfo);
        return (
            <React.Fragment>
                {
                    (error.message.length !== 0 || warning.message.length !== 0) &&
                    <Container top={Sizes.md}>
                        {
                            error.message.length !== 0 &&
                            <Alert
                                mode='danger'
                                header={error.title}
                                items={error.message}
                            />
                        }
                        {
                            warning.message.length !== 0 &&
                            <Alert
                                mode='warning'
                                header={warning.title}
                                items={warning.message}
                            />
                        }
                    </Container>
                }
                <NameForm
                    pageCode={this.props.pageCode}
                    sectionCode='applicantDetails'
                    saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                    reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                    tooltipInfo={this.props.tooltipInfo}
                    validationInfo={this.props.validationInfo}
                    header={<FormattedMessage id='Applicant Details' />}
                    label={`Name`}
                    name={this.props.policy?.participants?.insured?.party?.name}
                    person={this.props.policy?.participants?.insured?.person}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    reference={this.props.reference}
                    changeEvent={(dotProp, value) => this.props.doReducePolicyElement({ code: dotProp === 'person.gender' ? `participants.insured.${dotProp}` : `participants.insured.party.name.${dotProp}`, value })}
                    allowEdit={this.props.allowEdit}
                />
                <Form
                    pageCode={this.props.pageCode}
                    sectionCode='communicationDetails'
                    saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                    reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                    tooltipInfo={this.props.tooltipInfo}
                    validationInfo={this.props.validationInfo}
                    header={<FormattedMessage id='Communication Details' />}
                    label='Contact Details'
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormInput
                        fieldCode='email'
                        label='Email'
                        value={this.props.policy?.participants.insured.party.communication?.email?.address}
                        type='email'
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: 'participants.insured.party.communication.email.address', value })}
                        allowEdit
                    />
                    <FormFullPhoneNumber
                        fieldCode='fullNumber'
                        label='Phone'
                        value={this.props.policy?.participants.insured.party.communication?.phone?.fullNumber}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: 'participants.insured.party.communication.phone.fullNumber', value })}
                        allowEdit
                    />

                    <FormFullPhoneNumber
                        fieldCode='mobileFullNumber'
                        label='Mobile'
                        value={this.props.policy?.participants.insured.party.communication?.mobile?.fullNumber}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: 'participants.insured.party.communication.mobile.fullNumber', value })}
                        allowEdit
                    />
                </Form>
                <AddressForm
                    {...this.props}
                    enableAddressSearch
                    pageCode={this.props.pageCode}
                    sectionCode='applicantAddressDetails'
                    saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                    reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                    tooltipInfo={this.props.tooltipInfo}
                    validationInfo={this.props.validationInfo}
                    addressSearchCountryCode={`${this.props.policy.productCountryCode ? this.props.policy.productCountryCode : 'USA'}`}
                    header={<FormattedMessage id='Address Details' />}
                    label='Location Details'
                    saveEvent={this.saveAddressHandler}
                    cancelEvent={this.props.doGetPolicy}
                    reference={this.props.reference}
                    changeEvent={(dotProp, value) => {
                        dotProp === 'address' ?
                        this.props.doReducePolicyElement({ code: 'participants.insured.party.address', value })
                            :
                        this.props.doReducePolicyElement({ code: `participants.insured.party.address.${dotProp}`, value })
                    }}
                    allowEdit={this.props.allowEdit}
                    address={this.props.policy?.participants?.insured?.party?.address}
                />
                {
                    this.state.showPopup &&
                    <GenericModal
                        animate
                        visible
                        closeEvent={() => {
                            this.props.doReducePolicyElement({ code: 'participants.insured.party.address', value: this.state.address });
                            this.props.doUpsertPolicy();
                            this.setState({ showPopup: false });
                        }}
                        items={
                            <React.Fragment>
                                <Header size={Sizes.md} text='Warning' bottom={Sizes.xs} />
                                <Label size={Sizes.sm} textAlign={Alignments.center} text={'Coverage limits vary by state. Please reselect a different coverage limit according to the new state.'} />
                            </React.Fragment>
                        }
                        actionButtons={[
                            {
                                primary: true,
                                text: <FormattedMessage id='covera.common.general.ok' />,
                                disabled: false,
                                clickEvent: () => {
                                    this.setState({
                                        address: this.props.policy?.participants?.insured?.party?.address,
                                        showPopup: false
                                    });
                                }
                            }, {
                                primary: false,
                                text: <FormattedMessage id='covera.common.general.cancel' />,
                                disabled: false,
                                clickEvent: () => {
                                    this.props.doReducePolicyElement({ code: 'participants.insured.party.address', value: this.state.address });
                                    this.props.doUpsertPolicy();
                                    this.setState({ showPopup: false });
                                }
                            }
                        ]}
                    />
                }

                <CheckBox label={'Mailing Address same as above address'}
                    value={this.state.sameMailingAddress}
                    clickEvent={() => {
                        this.props.doReducePolicyElement({ code: 'participants.insured.party.mailingAddress', value: this.props.policy.participants.insured.party.address })
                        this.setState({ sameMailingAddress: !this.state.sameMailingAddress })
                        this.props.doUpsertPolicy();
                    }}
                    left={Sizes.sm} bottom={Sizes.md} top={Sizes.md}
                />

                {/*Only for tenant*/}
                {
                    this.props.policy?.participants?.insured?.previousAddress &&
                    <AddressForm
                        {...this.props}
                        enableAddressSearch
                        pageCode={this.props.pageCode}
                        sectionCode='mailingAddress'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        addressSearchCountryCode={'USA'}
                        header={<FormattedMessage id={'Previous Address Details'} />}
                        label={'Location Details'}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        reference={this.props.reference}
                        changeEvent={(dotProp, value) => {
                            dotProp === 'address' ?
                                this.props.doReducePolicyElement({ code: 'participants.insured.party.address', value })
                                :
                                this.props.doReducePolicyElement({ code: 'participants.insured.previousAddress.${dotProp}', value })
                        }}
                        allowEdit={this.props.allowEdit}
                        address={this.props.policy?.participants?.insured?.previousAddress}
                    />
                }
                {
                    !this.state.sameMailingAddress &&
                    this.props.policy?.participants?.insured?.party &&
                    <AddressForm
                        {...this.props}
                        enableAddressSearch
                        pageCode={this.props.pageCode}
                        sectionCode='mailingAddress'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        addressSearchCountryCode={`${this.props.policy.productCountryCode ? this.props.policy.productCountryCode : 'USA'}`}
                        header={<FormattedMessage id={'Mailing Address Details'} />}
                        label={`Location Details`}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        reference={this.props.reference}
                        changeEvent={(dotProp, value) => {
                            dotProp === `address` ?
                                this.props.doReducePolicyElement({ code: `participants.insured.party.mailingAddress`, value })
                                :
                                this.props.doReducePolicyElement({ code: `participants.insured.party.mailingAddress.${dotProp}`, value })
                        }}
                        allowEdit={this.props.allowEdit}
                        // alwaysEditing
                        address={this.props.policy?.participants?.insured?.party?.mailingAddress}
                    />
                }
            </React.Fragment>
        );
    }
}

export default ProfileApplicant;