import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    setAttributeEntity,
    Sizes,
    Alignments,
    BackButton,
    Divider,
    Header,
    Form,
    FormDropDown,
    FormInput
} from 'metrix-common';

class ProfileDwellingGarage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <BackButton clickEvent={this.props.backEvent} />
                <Divider top={Sizes.md} />
                <Header size={Sizes.md} textAlign={Alignments.left} text={`Garage`} />
                <Form
                    label={`Garage Details`}
                    allowEdit={this.props.allowEdit}
                    saveEvent={() => this.props.doUpsertPolicy()}
                    cancelEvent={() => this.props.doGetPolicy()}
                >
                    <FormDropDown
                        label={<FormattedMessage id={`Type`} />}
                        value={this.props.garage && this.props.garage.type}
                        references={this.props.reference.references && this.props.reference.references[`GarageType`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`GarageType`)}
                        changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}type`, value, this.props.doReducePolicyElement)}
                        allowEdit
                    />
                    <FormInput
                        label={<FormattedMessage id={`Number of Cars`} />}
                        value={this.props.garage &&
                            this.props.garage.numberOfVehicles}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}numberOfVehicles`, value })}
                        allowEdit
                    />
                </Form>
            </React.Fragment>
        );
    }
}

export default ProfileDwellingGarage;