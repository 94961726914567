import { put, call, select } from 'redux-saga/effects';
import { reduceOperation, reduceLoading, getSessionSaga } from 'metrix-common';
import { selectEntity } from '../../selectors/policy/selectEntity';
import { reducePolicyValidationInfo } from '../../actions/policy/reducePolicyValidationInfo';

export function* getPolicyValidationInfoSaga() {
    try {
        yield put(reduceLoading({ code: 'getPolicyValidationInfo', value: true }));
        const session = yield getSessionSaga();
        const entity = yield select(selectEntity());
        
        let response = yield call(fetch, `/api/policyTransaction/validationInfo/${entity}`, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Authorization': session.authenticationToken.token,
                'Content-Type': 'application/json'
            }
        });
        
        let result = yield call([response, 'json']);
        
        yield put(reduceOperation(result.operation));
        if (!result.operation || !result.operation.status) return;
        
        yield put(reducePolicyValidationInfo(result.policyTransactionValidationInfo));
    }
    catch (error) {
        console.log("Error while calling Get Policy Validation Information API - ", error);
    }
    finally {
        yield put(reduceLoading({ code: 'getPolicyValidationInfo', value: false }));
    }
}
