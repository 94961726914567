import React from 'react';

import {
    Sizes,
    Alignments,
    Container,
    Divider,
    Header,
    SummaryGroup
} from 'metrix-common';

import Vehicle from './Vehicle';

class ProfileVehicles extends React.Component {
    state = {
        mode: `summary`,
        current: null
    };

    componentDidMount() {
        this.props.doGetPolicy();
    }

    getVehicles = () => {
        let vehicles = [];

        if (this.props.policy &&
            this.props.policy.vehicles &&
            this.props.policy.vehicles.length > 0) {
            this.props.policy.vehicles.forEach((vehicle) => {
                let option = { code: `vehicle`, value: vehicle.guid };

                let vehicleName = `Vehicle ${vehicle.number}`;

                if (vehicle.year && vehicle.make) {
                    vehicleName = `${vehicle.year} ${vehicle.make}`;
                }

                option.elements = [];

                option.elements.push({ code: `Label`, value: vehicleName });
                //option.elements.push({ code: `Status`, value: `Complete` });

                vehicles.push(option);
            });
        }

        return vehicles;
    }

    getDrivers = () => {
        let drivers = [];

        if (this.props.policy &&
            this.props.policy.drivers &&
            this.props.policy.drivers.length > 0) {
            this.props.policy.drivers.forEach((driver) => {
                let driverName = `Driver ${driver.number}`;

                if (driver.party.name) {
                    driverName = `${driver.party.name.firstName} ${driver.party.name.lastName}`;
                }

                let option = { code: driver.guid, value: driverName };

                drivers.push(option);
            });
        }

        return drivers;
    }

    render() {
        let vehicles = this.getVehicles();
        return (
            <Container>
                {this.state.mode === `summary` &&
                    <React.Fragment>
                        <Divider top={Sizes.xl} />
                        <Header size={Sizes.lg} textAlign={Alignments.left} text={`Vehicles`} />
                        <Container top={Sizes.xl} bottom={Sizes.xl}>
                        <SummaryGroup
                            allowAdd={this.props.allowEdit}
                            addEvent={() => {
                                this.props.doReduceParameters([]);
                                this.props.doExecutePolicyAction({ code: `CREATEVEHICLE` });
                            }}
                            allowEdit
                            editEvent={(vehicle) => {
                                this.setState({ current: vehicle, mode: `vehicle` });
                                this.props.doReduceParameters([{ code: `Vehicle`, value: vehicle }]);
                            }}
                            allowDelete={this.props.allowEdit}
                            deleteEvent={(vehicle) => {
                                this.props.doReduceParameters([{ code: `Vehicle`, value: vehicle }]);
                                this.props.doExecutePolicyAction({ code: `DELETEVEHICLE` });
                            }}
                            entities={vehicles}
                            icon={`vehicle`}
                        />
                        </Container>
                    </React.Fragment>
                }
                {this.state.mode === `vehicle` &&
                    <Vehicle vehicle={this.props.policy.vehicles.find(x => x.guid === this.state.current)}
                        vehicleUses={this.props.policy.vehicleUses}
                        drivers={this.getDrivers()}
                        dotProp={`vehicles.${this.props.policy.vehicles.findIndex(x => x.guid === this.state.current)}.`}
                        backEvent={() => this.setState({ mode: `summary`, current: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReduceParameter={this.props.doReduceParameter}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        doExecutePolicyAction={this.props.doExecutePolicyAction}
                        reference={this.props.reference}
                        context={this.props.context}
                         allowEdit={this.props.allowEdit}
                    />
                }
            </Container>
        );
    }
}

export default ProfileVehicles;