import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Sizes,
    Alignments,
    Container,
    Grid,
    Row,
    Column,
    Label,
    Header,
    Separator,
    EnterButton,
    Divider
} from 'metrix-common';

class ProfileHistory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fetching: false,
            policyFetch: false,
            queryFetch: false
        };
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate() {
        this.getData();
    }

    getData() {
        if (this.state.fetching) {
            if (this.state.policyFetch) {
                if (this.props.policy) {
                    this.setState({ fetching: false, policyFetch: false });
                }
            }
            if (this.state.queryFetch) {
                if (this.props.policyHistory) {
                    this.setState({ fetching: false, queryFetch: false });
                }
            }
        } else {
            if (!this.props.policy) {
                this.props.doGetPolicy();
                this.setState({ fetching: true, policyFetch: true });
            }
            if (!this.props.policyHistory &&
                this.props.policy &&
                this.props.policy.policy &&
                this.props.policy.policy.guid) {
                this.props.doQueryPolicy();
                this.setState({ fetching: true, queryFetch: true });
            }
        }
    }

    render() {
        return (
            <Container top={Sizes.sm} bottom={Sizes.xl}>
                <Grid>
                    <Row>
                        <Column>
                            <Container verticalAlignment={Alignments.center}
                                top={Sizes.md}
                                fullSize>
                                <Divider />
                                <Header size={Sizes.xl}
                                    textAlign={Alignments.left}
                                    text={<FormattedMessage id="History" />} />
                            </Container>
                        </Column>
                        <Column nogutter>
                            <Separator orientation="horizontal"
                                top={Sizes.sm}
                                bottom={Sizes.sm}
                                solid
                            />
                        </Column>
                    </Row>
                    {this.props.policyHistory && this.props.policyHistory.map((policy, i) => {
                        return (
                            <Row>
                                <Column xs={2}>
                                    <Container verticalAlignment={Alignments.center}
                                        top={Sizes.md}
                                        fullSize>
                                        <Label textAlign={Alignments.left}
                                            text={policy.product}
                                        />
                                    </Container>
                                </Column>
                                <Column xs={2}>
                                    <Container verticalAlignment={Alignments.center}
                                        top={Sizes.md}
                                        fullSize>
                                        <Label textAlign={Alignments.left}
                                            text={policy.type}
                                        />
                                    </Container>
                                </Column>
                                <Column xs={4} md={6}>
                                    <Container verticalAlignment={Alignments.center}
                                        top={Sizes.md}
                                        fullSize>
                                        <Label textAlign={Alignments.left}
                                            text={policy.status}
                                        />
                                    </Container>
                                </Column>
                                <Column xs={4} md={2}>
                                    <Container verticalAlignment={Alignments.center}
                                        top={Sizes.md}
                                        fullSize>
                                        <EnterButton text="Go"
                                            clickEvent={() => { window.location = `${this.props.context.configuration.policyWeb}/profile?entity=${policy.guid}` }}
                                        />
                                    </Container>
                                </Column>
                            </Row>
                        );
                    })}
                </Grid>
            </Container>
        );
    }
}

export default ProfileHistory;