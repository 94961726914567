import { put, call, select } from 'redux-saga/effects';
import { reduceOperation, reduceLoading, getSessionSaga } from 'metrix-common';
import { reducePolicy } from '../../actions/policy/reducePolicy';
import { selectPolicy } from '../../selectors/policy/selectPolicy';
import { reduceRiskChanged } from '../../actions/policy/reduceRiskChanged';

export function* upsertPolicySaga() {
    try {
        yield put(reduceLoading({ code: 'upsertPolicy', value: true }));
        const session = yield getSessionSaga();
        const policy = yield select(selectPolicy());

        const response = yield call(fetch, '/api/policyTransaction/upsert', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Authorization': session.authenticationToken.token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ policyTransaction: policy })
        });

        const result = yield call([response, 'json']);

        if (result.operation.status) {
            yield put(reducePolicy(result.policyTransaction));
        }

        const riskChangedParam = result.parameters?.find(param => param.code === 'RiskChanged' && param.value === 'True');
        if (riskChangedParam) {
            yield put(reduceRiskChanged(true));
            window.location.reload();
        } else {
            yield put(reduceRiskChanged(false));
        }

        yield put(reduceOperation(result.operation));
    }
    catch (error) {
        console.log('Error while calling Upsert Policy API - ', error);
    }
    finally {
        yield put(reduceLoading({ code: 'upsertPolicy', value: false }));
    }
}
