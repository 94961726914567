import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    convertFromBoolean,
    convertToBoolean,
    setAttributeEntity,
    Sizes,
    Alignments,
    BackButton,
    Divider,
    Header,
    Form,
    FormDropDown,
    FormRadio
} from 'metrix-common';

class ProfileDwellingSwimmingPool extends React.Component {
    render() {
        return (
            <React.Fragment>
                <BackButton clickEvent={this.props.backEvent} />
                <Divider top={Sizes.md} />
                <Header size={Sizes.md} textAlign={Alignments.left} text={`Swimming Pool`} />
                <Form
                    label={`Swimming Pool Details`}
                    allowEdit={this.props.allowEdit}
                    saveEvent={() => this.props.doUpsertPolicy()}
                    cancelEvent={() => this.props.doGetPolicy()}
                >
                    <FormDropDown
                        label={<FormattedMessage id={`Type`} />}
                        value={this.props.swimmingPool && this.props.swimmingPool.type}
                        references={this.props.reference.references && this.props.reference.references[`PoolType`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`PoolType`)}
                        changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}type`, value, this.props.doReducePolicyElement)}
                        allowEdit
                    />
                    <FormDropDown
                        label={<FormattedMessage id={`Construction`} />}
                        value={this.props.swimmingPool && this.props.swimmingPool.constructionType}
                        references={this.props.reference.references && this.props.reference.references[`PoolConstructionType`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`PoolConstructionType`)}
                        changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}constructionType`, value, this.props.doReducePolicyElement)}
                        allowEdit
                    />
                    <FormDropDown
                        label={<FormattedMessage id={`Condition`} />}
                        value={this.props.swimmingPool && this.props.swimmingPool.condition}
                        references={this.props.reference.references && this.props.reference.references[`PoolCondition`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`PoolCondition`)}
                        changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}condition`, value, this.props.doReducePolicyElement)}
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={`Approved Fence`} />}
                        value={this.props.swimmingPool &&
                            { code: convertFromBoolean(this.props.swimmingPool.approvedFence) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}approvedFence`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                </Form>
            </React.Fragment>
        );
    }
}

export default ProfileDwellingSwimmingPool;