import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Form,
    FormInput,
    convertToBoolean,
    FormRadio,
    convertFromBoolean
} from 'metrix-common';

class Questionnaire extends React.Component {
    render() {

        const getValue = (answerValue) => {
            if (answerValue === undefined || answerValue === null) {
                return `-`;
            } else {
                return `${answerValue}`;
            }
        }

        return (
            <Form
                header={<FormattedMessage id={`Liability Exposures`} />}
                label={`Liability Exposures`}
                saveEvent={() => {
                    this.props.doReducePolicyElement({ code: `liabilityExposure.guid`, value: this.props.policy.guid })
                    this.props.doUpsertPolicy();
                }}
                cancelEvent={() => this.props.doGetPolicy()}
                allowEdit={this.props.allowEdit}
            >
                <FormRadio
                    label={<FormattedMessage id={`Do you own/rent more than one location?`} />}
                    value={this.props.policy && this.props.policy.liabilityExposure && { code: convertFromBoolean(this.props.policy.liabilityExposure.hasMultipleLocations) }}
                    references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                    referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `liabilityExposure.hasMultipleLocations`, value: convertToBoolean(value.code) })}
                    allowEdit
                />
                <FormInput
                    label={<FormattedMessage id={`Number of weeks location rented to others?`} />}
                    value={getValue(this.props?.policy?.liabilityExposure?.numberOfWeeksLocationRented)}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `liabilityExposure.numberOfWeeksLocationRented`, value: parseInt(value, 10) })}
                    type={`number`}
                    allowEdit
                />
                <FormInput
                    label={<FormattedMessage id={`Number of rooms rented to others?`} />}
                    value={getValue(this.props?.policy?.liabilityExposure?.numberOfRoomsRentedToOthers)}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `liabilityExposure.numberOfRoomsRentedToOthers`, value: parseInt(value, 10) })}
                    type={`number`}
                    allowEdit
                />
                <FormInput
                    label={<FormattedMessage id={`Daycare operation - number of children`} />}
                    value={getValue(this.props?.policy?.liabilityExposure?.daycareOperationNumberOfChildren)}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `liabilityExposure.daycareOperationNumberOfChildren`, value: parseInt(value, 10) })}
                    type={`number`}
                    allowEdit
                />
                <FormRadio
                    label={<FormattedMessage id={`Do you own a trampoline?`} />}
                    value={this.props.policy && this.props.policy.liabilityExposure && { code: convertFromBoolean(this.props.policy.liabilityExposure.hasTrampoline) }}
                    references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                    referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `liabilityExposure.hasTrampoline`, value: convertToBoolean(value.code) })}
                    allowEdit
                />
                <FormRadio
                    label={<FormattedMessage id={`Do you have a garden tractor?`} />}
                    value={this.props.policy && this.props.policy.liabilityExposure && { code: convertFromBoolean(this.props.policy.liabilityExposure.hasGardenTractor) }}
                    references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                    referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `liabilityExposure.hasGardenTractor`, value: convertToBoolean(value.code) })}
                    allowEdit
                />
                <FormRadio
                    label={<FormattedMessage id={`Do you have a golf cart?`} />}
                    value={this.props.policy && this.props.policy.liabilityExposure && { code: convertFromBoolean(this.props.policy.liabilityExposure.hasGolfCart) }}
                    references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                    referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `liabilityExposure.hasGolfCart`, value: convertToBoolean(value.code) })}
                    allowEdit
                />
                <FormInput
                    label={<FormattedMessage id={`Number of saddle/draft animals?`} />}
                    value={getValue(this.props?.policy?.liabilityExposure?.numberOfSaddleAnimals)}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `liabilityExposure.numberOfSaddleAnimals`, value: parseInt(value, 10) })}
                    type={`number`}
                    allowEdit
                />
                <FormRadio
                    label={<FormattedMessage id={`Do you own any unlicensed recreational vehicles?`} />}
                    value={this.props.policy && this.props.policy.liabilityExposure && { code: convertFromBoolean(this.props.policy.liabilityExposure.hasUnlicensedRecreationalVehicles) }}
                    references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                    referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `liabilityExposure.hasUnlicensedRecreationalVehicles`, value: convertToBoolean(value.code) })}
                    allowEdit
                />
                <FormRadio
                    label={<FormattedMessage id={`Renewable energy installation on premises?`} />}
                    value={this.props.policy && this.props.policy.liabilityExposure && { code: convertFromBoolean(this.props.policy.liabilityExposure.hasRenewableEnergyInstallationOnPremises) }}
                    references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                    referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `liabilityExposure.hasRenewableEnergyInstallationOnPremises`, value: convertToBoolean(value.code) })}
                    allowEdit
                />
                <FormRadio
                    label={<FormattedMessage id={`Do you own any watercrafts?`} />}
                    value={this.props.policy && this.props.policy.liabilityExposure && { code: convertFromBoolean(this.props.policy.liabilityExposure.hasWatercrafts) }}
                    references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                    referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `liabilityExposure.hasWatercrafts`, value: convertToBoolean(value.code) })}
                    allowEdit
                />
                <FormInput
                    label={<FormattedMessage id={`Number of full time residence employees?`} />}
                    value={getValue(this.props?.policy?.liabilityExposure?.numberOfFullTimeResidenceEmployees)}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `liabilityExposure.numberOfFullTimeResidenceEmployees`, value: parseInt(value, 10) })}
                    type={`number`}
                    allowEdit
                />
                <FormRadio
                    label={<FormattedMessage id={`Is there a co-occupant that requires coverage?`} />}
                    value={this.props.policy && this.props.policy.liabilityExposure && { code: convertFromBoolean(this.props.policy.liabilityExposure.hasCoOccupantRequiringCoverage) }}
                    references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                    referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `liabilityExposure.hasCoOccupantRequiringCoverage`, value: convertToBoolean(value.code) })}
                    allowEdit
                />
                <FormInput
                    label={<FormattedMessage id={`Co-occupant name`} />}
                    value={this.props.policy && this.props.policy.liabilityExposure && this.props.policy.liabilityExposure.coOccupantName}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `liabilityExposure.coOccupantName`, value })}
                    allowEdit
                />

                <FormRadio
                    label={<FormattedMessage id={`Is there any kind of business operation?`} />}
                    value={this.props.policy && this.props.policy.liabilityExposure && { code: convertFromBoolean(this.props.policy.liabilityExposure.hasBusinessOperation) }}
                    references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                    referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `liabilityExposure.hasBusinessOperation`, value: convertToBoolean(value.code) })}
                    allowEdit
                />
                <FormInput
                    label={<FormattedMessage id={`if yes, describe business`} />}
                    value={this.props.policy && this.props.policy.liabilityExposure && this.props.policy.liabilityExposure.businessOperationDescription}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `liabilityExposure.businessOperationDescription`, value })}
                    allowEdit
                />

                <FormInput
                    label={<FormattedMessage id={`Number of dogs in the household?`} />}
                    value={getValue(this.props?.policy?.liabilityExposure?.numberOfDogs)}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `liabilityExposure.numberOfDogs`, value: parseInt(value, 10) })}
                    type={`number`}
                    allowEdit
                />
                <FormInput
                    label={<FormattedMessage id={`Breed(s) of dogs`} />}
                    value={this.props.policy && this.props.policy.liabilityExposure && this.props.policy.liabilityExposure.breedsOfDogs}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `liabilityExposure.breedsOfDogs`, value })}
                    allowEdit
                />
                <FormInput
                    label={<FormattedMessage id={`Total property area (if greater than 1 acre) in acre`} />}
                    value={getValue(this.props?.policy?.liabilityExposure?.propertyArea)}
                    changeEvent={(value) => { this.props.doReducePolicyElement({ code: `liabilityExposure.propertyArea`, value: parseInt(value, 10) }) }}
                    type={`number`}
                    allowEdit
                />

                <FormInput
                    label={<FormattedMessage id={`Number of cannabis plants grown on premises?`} />}
                    value={getValue(this.props?.policy?.liabilityExposure?.numberOfCannabisPlantsGrownOnPremises)}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `liabilityExposure.numberOfCannabisPlantsGrownOnPremises`, value: parseInt(value, 10) })}
                    type={`number`}
                    allowEdit
                />

                <FormInput
                    label={<FormattedMessage id={`Other exposures`} />}
                    value={this.props.policy && this.props.policy.liabilityExposure && this.props.policy.liabilityExposure.otherExposures}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `liabilityExposure.otherExposures`, value })}
                    allowEdit
                />

            </Form>
        );
    }
}

export default Questionnaire;