import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Form,
    FormInput, FormSwitch, Label
} from 'metrix-common';

class ProfileInterwestPlClientInfo extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            this.props.policy.interWestPLCustomInfo.businessInfo.hasOverOneHundredRevenue
            ?
            <>
                <Form
                    header={<FormattedMessage id='Client Information' />}
                    label={<FormattedMessage id='Clients 25% or greater' />}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormSwitch
                        label={<FormattedMessage id='Does any client represent 25% or more of the firm’s gross annual revenue?' />}
                        value={this.props.policy.interWestPLCustomInfo.clientInfo.doesClientRepresentTwentyFivePercentOrMore ?? false}
                        changeEvent={
                            value => {
                                this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.clientInfo.doesClientRepresentTwentyFivePercentOrMore', value: value });
                            }}
                        allowEdit
                    />
                    {
                        this.props.policy.interWestPLCustomInfo.clientInfo.doesClientRepresentTwentyFivePercentOrMore &&
                        <FormInput
                            label={<FormattedMessage id='Client Industry' />}
                            value={this.props.policy.interWestPLCustomInfo.clientInfo.clientRepresentTwentyFivePercentOrMoreOneClientIndustry}
                            type='text'
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.clientInfo.clientRepresentTwentyFivePercentOrMoreOneClientIndustry', value: value });
                                }}
                            allowEdit
                        />
                    }
                    {
                        this.props.policy.interWestPLCustomInfo.clientInfo.doesClientRepresentTwentyFivePercentOrMore &&
                        <FormInput
                            label={<FormattedMessage id='Services Provided' />}
                            value={this.props.policy.interWestPLCustomInfo.clientInfo.clientRepresentTwentyFivePercentOrMoreOneServicesProvided}
                            type='text'
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.clientInfo.clientRepresentTwentyFivePercentOrMoreOneServicesProvided', value: value });
                                }}
                            allowEdit
                        />
                    }
                    {
                        this.props.policy.interWestPLCustomInfo.clientInfo.doesClientRepresentTwentyFivePercentOrMore &&
                        <FormInput
                            label={<FormattedMessage id='Percent of Firm Revenue' />}
                            value={this.props.policy.interWestPLCustomInfo.clientInfo.clientRepresentTwentyFivePercentOrMoreOneFirmRevenuePercent}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.clientInfo.clientRepresentTwentyFivePercentOrMoreOneFirmRevenuePercent', value })}
                            maxValue={999}
                            allowEdit
                        />
                    }
                    {
                        this.props.policy.interWestPLCustomInfo.clientInfo.doesClientRepresentTwentyFivePercentOrMore &&
                        <FormInput
                            label={<FormattedMessage id='Number of Years as a Client' />}
                            value={this.props.policy.interWestPLCustomInfo.clientInfo.clientRepresentTwentyFivePercentOrMoreOneYearsAsClient}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.clientInfo.clientRepresentTwentyFivePercentOrMoreOneYearsAsClient', value })}
                            maxValue={999}
                            allowEdit
                        />
                    }
                    {
                        this.props.policy.interWestPLCustomInfo.clientInfo.doesClientRepresentTwentyFivePercentOrMore &&
                        <FormSwitch
                            label={<FormattedMessage id='Do you have any other clients that represents 25% or more of the firm’s gross annual revenue?' />}
                            value={this.props.policy.interWestPLCustomInfo.clientInfo.isMoreThanOneClientTwentyFivePercent ?? false}
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.clientInfo.isMoreThanOneClientTwentyFivePercent', value: value });
                                }}
                            allowEdit
                        />
                    }
                    {
                        this.props.policy.interWestPLCustomInfo.clientInfo.doesClientRepresentTwentyFivePercentOrMore &&
                        this.props.policy.interWestPLCustomInfo.clientInfo.isMoreThanOneClientTwentyFivePercent &&
                        <FormInput
                            label={<FormattedMessage id='Client Industry' />}
                            value={this.props.policy.interWestPLCustomInfo.clientInfo.clientRepresentTwentyFivePercentOrMoreTwoClientIndustry}
                            type='text'
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.clientInfo.clientRepresentTwentyFivePercentOrMoreTwoClientIndustry', value: value });
                                }}
                            allowEdit
                        />
                    }
                    {
                        this.props.policy.interWestPLCustomInfo.clientInfo.doesClientRepresentTwentyFivePercentOrMore &&
                        this.props.policy.interWestPLCustomInfo.clientInfo.isMoreThanOneClientTwentyFivePercent &&
                        <FormInput
                            label={<FormattedMessage id='Services Provided' />}
                            value={this.props.policy.interWestPLCustomInfo.clientInfo.clientRepresentTwentyFivePercentOrMoreTwoServicesProvided}
                            type='text'
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.clientInfo.clientRepresentTwentyFivePercentOrMoreTwoServicesProvided', value: value });
                                }}
                            allowEdit
                        />
                    }
                    {
                        this.props.policy.interWestPLCustomInfo.clientInfo.doesClientRepresentTwentyFivePercentOrMore &&
                        this.props.policy.interWestPLCustomInfo.clientInfo.isMoreThanOneClientTwentyFivePercent &&
                        <FormInput
                            label={<FormattedMessage id='Percent of Firm Revenue' />}
                            value={this.props.policy.interWestPLCustomInfo.clientInfo.clientRepresentTwentyFivePercentOrMoreTwoFirmRevenuePercent}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.clientInfo.clientRepresentTwentyFivePercentOrMoreTwoFirmRevenuePercent', value })}
                            maxValue={999}
                            allowEdit
                        />
                    }
                    {
                        this.props.policy.interWestPLCustomInfo.clientInfo.doesClientRepresentTwentyFivePercentOrMore &&
                        this.props.policy.interWestPLCustomInfo.clientInfo.isMoreThanOneClientTwentyFivePercent &&
                        <FormInput
                            label={<FormattedMessage id='Number of Years as a Client' />}
                            value={this.props.policy.interWestPLCustomInfo.clientInfo.clientRepresentTwentyFivePercentOrMoreTwoYearsAsClient}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.clientInfo.clientRepresentTwentyFivePercentOrMoreTwoYearsAsClient', value })}
                            maxValue={999}
                            allowEdit
                        />
                    }
                    {
                        this.props.policy.interWestPLCustomInfo.clientInfo.doesClientRepresentTwentyFivePercentOrMore &&
                        this.props.policy.interWestPLCustomInfo.clientInfo.isMoreThanOneClientTwentyFivePercent &&
                        <FormSwitch
                            label={<FormattedMessage id='Do you have any other clients that represents 25% or more of the firm’s gross annual revenue?' />}
                            value={this.props.policy.interWestPLCustomInfo.clientInfo.isMoreThanTwoClientTwentyFivePercent ?? false}
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.clientInfo.isMoreThanTwoClientTwentyFivePercent', value: value });
                                }}
                            allowEdit
                        />
                    }
                    {
                        this.props.policy.interWestPLCustomInfo.clientInfo.doesClientRepresentTwentyFivePercentOrMore &&
                        this.props.policy.interWestPLCustomInfo.clientInfo.isMoreThanOneClientTwentyFivePercent &&
                        this.props.policy.interWestPLCustomInfo.clientInfo.isMoreThanTwoClientTwentyFivePercent &&
                        <FormInput
                            label={<FormattedMessage id='Client Industry' />}
                            value={this.props.policy.interWestPLCustomInfo.clientInfo.clientRepresentTwentyFivePercentOrMoreThreeClientIndustry}
                            type='text'
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.clientInfo.clientRepresentTwentyFivePercentOrMoreThreeClientIndustry', value: value });
                                }}
                            allowEdit
                        />
                    }
                    {
                        this.props.policy.interWestPLCustomInfo.clientInfo.doesClientRepresentTwentyFivePercentOrMore &&
                        this.props.policy.interWestPLCustomInfo.clientInfo.isMoreThanOneClientTwentyFivePercent &&
                        this.props.policy.interWestPLCustomInfo.clientInfo.isMoreThanTwoClientTwentyFivePercent &&
                        <FormInput
                            label={<FormattedMessage id='Services Provided' />}
                            value={this.props.policy.interWestPLCustomInfo.clientInfo.clientRepresentTwentyFivePercentOrMoreThreeServicesProvided}
                            type='text'
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.clientInfo.clientRepresentTwentyFivePercentOrMoreThreeServicesProvided', value: value });
                                }}
                            allowEdit
                        />
                    }
                    {
                        this.props.policy.interWestPLCustomInfo.clientInfo.doesClientRepresentTwentyFivePercentOrMore &&
                        this.props.policy.interWestPLCustomInfo.clientInfo.isMoreThanOneClientTwentyFivePercent &&
                        this.props.policy.interWestPLCustomInfo.clientInfo.isMoreThanTwoClientTwentyFivePercent &&
                        <FormInput
                            label={<FormattedMessage id='Percent of Firm Revenue' />}
                            value={this.props.policy.interWestPLCustomInfo.clientInfo.clientRepresentTwentyFivePercentOrMoreThreeFirmRevenuePercent}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.clientInfo.clientRepresentTwentyFivePercentOrMoreThreeFirmRevenuePercent', value })}
                            maxValue={999}
                            allowEdit
                        />
                    }
                    {
                        this.props.policy.interWestPLCustomInfo.clientInfo.doesClientRepresentTwentyFivePercentOrMore &&
                        this.props.policy.interWestPLCustomInfo.clientInfo.isMoreThanOneClientTwentyFivePercent &&
                        this.props.policy.interWestPLCustomInfo.clientInfo.isMoreThanTwoClientTwentyFivePercent &&
                        <FormInput
                            label={<FormattedMessage id='Number of Years as a Client' />}
                            value={this.props.policy.interWestPLCustomInfo.clientInfo.clientRepresentTwentyFivePercentOrMoreThreeYearsAsClient}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.clientInfo.clientRepresentTwentyFivePercentOrMoreThreeYearsAsClient', value })}
                            maxValue={999}
                            allowEdit
                        />
                    }
                </Form>
                <Form
                    label={<FormattedMessage id='Indicate the total number of clients for the past 2 years' />}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormInput
                        label={<FormattedMessage id='Last Year' />}
                        value={this.props.policy.interWestPLCustomInfo.clientInfo.firmTotalNumberOfClientLastYearCount}
                        type='number'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.clientInfo.firmTotalNumberOfClientLastYearCount', value })}
                        maxValue={999}
                        allowEdit
                    />
                    <FormInput
                        label={<FormattedMessage id='Prior Year' />}
                        value={this.props.policy.interWestPLCustomInfo.clientInfo.firmTotalNumberOfClientPriorYearCount}
                        type='number'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.clientInfo.firmTotalNumberOfClientPriorYearCount', value })}
                        maxValue={999}
                        allowEdit
                    />
                </Form>
            </>
            :
            <Label text='There is nothing to show for now'/>
        );
    }
}

export default ProfileInterwestPlClientInfo;