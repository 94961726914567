import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    setAttributeEntity,
    Sizes,
    Alignments,
    BackButton,
    Divider,
    Header,
    Form,
    FormDropDown,
    FormInput,
    FormDate,
    FormRadio
} from 'metrix-common';

class ProfileDriverCancellationReason extends React.Component {
    render() {
        return (
            <React.Fragment>
                <BackButton clickEvent={this.props.backEvent} />
                <Divider top={Sizes.md} />
                <Header size={Sizes.md} textAlign={Alignments.left} text={`Cancellation`} />
                <Form
                    label={`Cancellation Details`}
                    allowEdit={this.props.allowEdit}
                    saveEvent={() => this.props.doUpsertPolicy()}
                    cancelEvent={() => this.props.doGetPolicy()}
                >
                    <FormDate
                        label={<FormattedMessage id={`Date of Cancellation`} />}
                        value={this.props.cancellationReason && this.props.cancellationReason.eventDate}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}eventDate`, value })}
                        culture={this.props.context.session.culture}
                        mode={`past`}
                        allowEdit
                    />
                    <FormDropDown
                        label={<FormattedMessage id={`Cancellation Reason`} />}
                        value={this.props.cancellationReason && this.props.cancellationReason.reason}
                        references={this.props.reference.references && this.props.reference.references[`CancellationReason`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`CancellationReason`)}
                        changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}reason`, value, this.props.doReducePolicyElement)}
                        allowEdit
                    />
                </Form>
            </React.Fragment>
        );
    }
}

export default ProfileDriverCancellationReason;