import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    toPascalCase,
    getTimeSpanText,
    Alignments,
    Row,
    Column,
    Label,
    Link,
    Grid
} from 'metrix-common';

class PolicyListItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        };
    }

    getDescription = () => {
        let description = null;

        if (this.props.policy.policyDescription) {
            description = this.props.policy.policyDescription;
        }

        if (this.props.policy.insuredName) {
            description = `${this.props.policy.insuredName} - ${description}`;
        }

        return description;
    }

    getAssignedTo = () => {
        let assignedTo = null;

        if (this.props.policy.assignedTo) {
            if (this.props.policy.assignedTo) {
                assignedTo = this.props.policy.assignedTo;
            }
        }

        return assignedTo;
    }

    render() {
        const profileLink = `${this.props.context.configuration.policyWeb}/profile?entity=${this.props.policy.policyTransactionGuid}`;
        
        return (
            <Grid>
                <Row>
                    {
                        !this.context.screen.isMobile &&
                        <Column md={this.context.screen.isTablet ? 2 : 1}>
                            <Link text={this.props.policy.submissionReferenceNumber} textAlign={Alignments.left} href={profileLink} />
                        </Column>
                    }
                    {
                        this.context.screen.isDesktop &&
                        <Column md={1}>
                            <Label text={this.props.policy.policyNumber} textAlign={Alignments.left} />
                        </Column>
                    }
                    {
                        this.context.screen.isMobile &&
                        <Column xs={5}>
                            <Label text={this.getDescription()} textAlign={Alignments.left} />
                            <Link text={this.props.policy.submissionReferenceNumber} href={profileLink} textAlign={Alignments.left} />
                        </Column>
                    }
                    {
                        !this.context.screen.isMobile &&
                        <React.Fragment>
                            <Column md={this.context.screen.isTablet ? 2 : 1}>
                                <Label text={this.props.policy.insuredName} textAlign={Alignments.left} />
                            </Column>
                            <Column md={2}>
                                    <Label text={this.props.policy.doingBusinessAs} textAlign={Alignments.left} />
                            </Column>
                        </React.Fragment>
                    }
                    {
                        !this.context.screen.isMobile &&
                        <React.Fragment>
                            <Column md={this.context.screen.isTablet ? 4 : 2}>
                                <Label text={this.props.policy.productDescription} textAlign={Alignments.left} />
                            </Column>
                            <Column md={1}>
                                    <Label text={this.props.policy.createdOnDate} textAlign={Alignments.left} />
                            </Column>
                        </React.Fragment>
                    }
                    {
                        this.context.screen.isDesktop &&
                            <Column md={2} lg={2}>
                                <Label text={this.getAssignedTo()} textAlign={Alignments.left} />
                            </Column>
                    }
                    <Column xs={2} md={1} lg={1}>
                        <Label text={this.props.policy.transactionType} textAlign={Alignments.left} />
                    </Column>
                </Row>
            </Grid>
        );
    }
}

PolicyListItem.contextTypes = {
    screen: PropTypes.object
};

export default PolicyListItem;