import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import SignaturePad from 'react-signature-canvas';

import {
    Container,
    Header,
    Label,
    Alignments,
    Colors,
    Sizes,
    GenericModal
} from 'metrix-common';

const SignContainer = styled(Container)`
    border: 1px solid ${Colors.DarkMode};
    border-radius: 4px;
    width: 100%;
`;

//This component needs following functions:
//1. closePad - Close Signature pad
//2. updateProgressBar - Update progress bar as per status after saving signature (optional)
//3. doReduceParameter - Set parameter for API call (refer saga)
//4. doExecutePolicyAction - Set Policy status for API call (refer saga)

const Signature = (props) => {

    let [error, setError] = useState(false);

    let signPad = React.createRef();
    let clientSign = null;

    return (
            <GenericModal
                animate
                visible
                closeEvent={() => props.closePad()}
                items={
                    <React.Fragment>
                        <Header text={`Sign docs`} size={Sizes.lg} />
                        <Label text={`Sign in the box below with a cursor or finger.`} bold textAlign={Alignments.left} bottom={Sizes.md} />
                        {error && <Label text={`Please Sign in the box before clicking save.`} color={Colors.Error} bold textAlign={Alignments.left} bottom={Sizes.md} />}
                        <SignContainer color={Colors.StrokeVeryLight}>
                            <SignaturePad ref={signPad} canvasProps={{ width: 350, height: 150 }} />
                        </SignContainer>
                    </React.Fragment>
                }
                actionButtons={[
                    {
                        text: <FormattedMessage id="covera.common.molecules.messaging.card.save" />,
                        clickEvent: () => {
                            if (!signPad.current.isEmpty()) {
                                clientSign = signPad.current.toDataURL();
                                props.doReduceParameter({ code: `Signature`, value: clientSign.split(',')[1] });
                                props.doExecutePolicyAction({ code: `RequestBind` });
                                props.closePad();
                                props.updateProgressBar && props.updateProgressBar(`bound`);
                            } else {
                                setError(true);
                            }
                        }
                    },
                    {
                        text: "Clear",
                        clickEvent: () => {
                            signPad.current.clear();
                        }
                    },
                    {
                        text: <FormattedMessage id="covera.common.general.cancel" />,
                        clickEvent: () => {
                            props.closePad();
                        }
                    }
                ]}
            />
        );

}

export default Signature;