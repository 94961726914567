import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Sizes,
    Alignments,
    BackButton,
    Divider,
    Header,
    Form,
    FormInput
} from 'metrix-common';

class ProfileDwellingImprovement extends React.Component {
    render() {
        return (
            <React.Fragment>
                <BackButton clickEvent={this.props.backEvent} />
                <Divider top={Sizes.md} />
                <Header size={Sizes.md} textAlign={Alignments.left} text={`Improvement`} />
                <Form
                    label={`Improvement Details`}
                    allowEdit={this.props.allowEdit}
                    saveEvent={() => this.props.doUpsertPolicy()}
                    cancelEvent={() => this.props.doGetPolicy()}
                >
                    <FormInput
                        label={<FormattedMessage id={`Description`} />}
                        value={this.props.improvement && this.props.improvement.otherImprovement && this.props.improvement.otherImprovement.value}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `${this.props.dotProp}otherImprovement.value`, value });
                        }}
                        allowEdit
                    />
                    <FormInput
                        label={<FormattedMessage id={`Value`} />}
                        value={this.props.improvement && this.props.improvement.otherImprovementAmount && this.props.improvement.otherImprovementAmount.value}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `${this.props.dotProp}otherImprovementAmount.value`, value });
                            this.props.doReducePolicyElement({ code: `${this.props.dotProp}otherImprovementAmount.currency`, value: `cad` });
                        }}
                        allowEdit
                    />
                </Form>
            </React.Fragment>
        );
    }
}

export default ProfileDwellingImprovement;