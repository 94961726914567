import { put, call, select } from 'redux-saga/effects';
import { reduceOperation, reduceLoading, getSessionSaga } from 'metrix-common';
import { reducePolicyHistory } from '../../actions/policy/reducePolicyHistory';
import { selectPolicy } from '../../selectors/policy/selectPolicy';

export function* queryPolicySaga(action) {
    try {
        yield put(reduceLoading({ code: `queryPolicy`, value: true }));
        const session = yield getSessionSaga();
        let policy = yield select(selectPolicy());
        let response = yield call(fetch, `/api/policyTransaction/query`, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Authorization': session.authenticationToken.token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ policyQuery: { policy: policy.policy.guid } })
        });
        let result = yield call([response, 'json']);
        yield put(reduceOperation(result.operation));
        if (!result.operation.status) {
            yield put(reduceLoading({ code: `queryPolicy`, value: false }));
            return;
        }
        yield put(reducePolicyHistory(result.policyTransactions));
        yield put(reduceLoading({ code: `queryPolicy`, value: false }));
        return;
    }
    catch (error) {
        console.log("Error while calling Query Policy API - ", error);
        yield put(reduceLoading({ code: `queryPolicy`, value: false }));
        return;
    }
}
