import { put, call, select } from 'redux-saga/effects';
import { reduceOperation, reduceLoading, getSessionSaga } from 'metrix-common';

export function* executeAssignmentActionSaga(action) {
    try {
        yield put(reduceLoading({ code: `executeAssignmentAction`, value: true }));
        let session = yield getSessionSaga();
        let response = yield call(fetch, `/api/policyTransaction/action/AssignBroker`, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Authorization': session.authenticationToken.token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                parameters: [{
                    code: 'BrokerReference',
                    value: action.payload.assignedTo
                }],
                policyTransaction: {
                    guid: action.payload.guid
                }
            })
        });
        let result = yield call([response, 'json']);

        yield put(reduceOperation(result.operation));
        if (!result.operation.status) {
            yield put(reduceLoading({ code: `executeAssignmentAction`, value: false }));
            return;
        }
        yield put(reduceLoading({ code: `executeAssignmentAction`, value: false }));
        return;
    }
    catch (error) {
        console.log("Error while Executing Assignment Action API - ", error);
        yield put(reduceLoading({ code: `executeAssignmentAction`, value: false }));
        return;
    }
}