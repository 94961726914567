import React from 'react';
import styled from 'styled-components';

import {
    Header,
    Label,
    BulletList,
    Sizes,
    Alignments,
    ToolTip
} from 'metrix-common';;

const LabelContainer = styled(Label)`
    text-align: justify;
    text-justify: inter-word;
    word-break:break-word;
`;

class CoverageTooltip extends React.Component {
    getToolTipContent = (header, code) => {
        let toolTipContent = null;
        let items = [];

        switch (code) {
            //                  ***************************************************************Commercial Icons***************************************************************
            case `CTOOL`: // Tool
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This covers your movable tools and equipment for repair or replacement, even in storage, if they are lost, damaged or stolen. Your items typically should be less than 5 years old.`} />
                    </React.Fragment>
                );
                break;
            case `CEQUIP`: // Equipment
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This covers equipment, machinery and  movable tools valued over $5,000, while you are renting them, if they are lost, damaged or stolen. In the event something happens, you would be covered for cost of replacement.`} />
                    </React.Fragment>
                );
                break;
            case `CRTE`: // Tool & Equipment Rented
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This covers movable tools while you are renting them, if they are lost, damaged or stolen. In the event something happens, you would be covered for cost of replacement.`} />
                    </React.Fragment>
                );
                break;
            case `GLPREMIUM`:
            case `CLIA`: // Liability
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This covers your business in the event you face a lawsuit or a claim from a third-party, such as legal costs and direct financial costs.`} />
                    </React.Fragment>
                );
                break;
            case `CLASSIFICATION_PREMIUM`: // BTIS-GL: Liability            
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This covers your business in the event you face a lawsuit or a claim from a third-party, such as legal costs and direct financial costs.`} />
                    </React.Fragment>
                );
                break;
            case `PROPERTYPREMIUM`:
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This cover damage to the property.`} />
                    </React.Fragment>
                );
                break;
            case `PACKAGEPREMIUM`:
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`Additional fees that the insured is required to pay beyond the policy premiums.`} />
                    </React.Fragment>
                );
                break;
            case `BROKER_FEE`:
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`A broker fee is an additional fee that the insured is required to pay beyond the policy premiums.`} />
                    </React.Fragment>
                );
                break;
            case `POLICY_FEE`: // BTIS-GL: Policy Fee
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`A policy fee is an additional fee that the insured is required to pay beyond the policy premiums.`} />
                    </React.Fragment>
                );
                break;
            case `CAGG`: // Aggregate
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This increases the maximum limit an insurance company will pay you for all covered losses while your policy is in effect.`} />
                    </React.Fragment>
                );
                break;
            case `CLIMPROV`: // Leaseholder Improvements
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This covers your leaseholder improvemens or betterments of your business locatiion.`} />
                    </React.Fragment>
                );
                break;
            case `CWKCOMP`: // Workman's Compensation
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This covers real cost of an employee's lost wages and medical expenses as your Liability will typically only cover legal costs.`} />
                    </React.Fragment>
                );
                break;
            case `CTHEALTH`: // Telehealth
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This covers medical or health services and visits, psychotherapy and consultations where the healthcare provider is using audio and or video technology to communicate, such as a phone or a computer.`} />
                    </React.Fragment>
                );
                break;
            case `CLUMBR`: // Liability Umbrella
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This covers for claims that may be excluded from general liability policies such as false arrest, slander and liability on rental units you own.`} />
                    </React.Fragment>
                );
                break;
            case `CCYBER`: // Cyber & Ransomware
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This covers your business against cyber security risks and data breaches, phishing scams, ransomware and social engineering attacks. The coverage can help in restoring customer identities, recover data and damages hardware or networks.`} />
                    </React.Fragment>
                );
                break;
            case `CDO`: // Directors and Officers Insurance
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This covers your business officers and directors from professional liability `} />
                    </React.Fragment>
                );
                break;

            //                  ***************************************************************Auto Icons***************************************************************
            case `AINCRPL`: // Monthly Income Replacement
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This covers you if you are injured or ill and will replace the income you would have earned, while you recover.`} />
                    </React.Fragment>
                );
                break;
            case `AAIRSURE`: // Airsure Flight Life
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This covers you for accidental death while on an airplane.`} />
                    </React.Fragment>
                );
                break;
            case `ARENTAL`: // Rental Car Coverage
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This covers you when you are driving a rental car and goes beyond the minimal rental car insurance covered in your auto policy, if you have one.`} />
                    </React.Fragment>
                );
                break;
            case `2`: // Driver Other Automobiles
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`Let's say you're driving a car that's not listed on your policy. This will provide you with Liability, Accident Benefits and Direct Compensation - Property Damage coverages when you are driving that car.`} />
                    </React.Fragment>
                );
                break;
            case `5A`: // Rented or Leased Vehicles
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This endorsement extends your coverage to a vehicle that is owned by a leasing company and driven by yourself.`} />
                    </React.Fragment>
                );
                break;
            case `8`: // Property Damage Reimbursement
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This endorsement restricts specified drivers from your insurance policy. For example, if the person named doesn't have the best driving record, or is a spouse who rarely drives the vehicle, this endorsement reduces their coverage while they're operating the vehicle, potentially allowing some premium savings. If the restricted driver gets into an accident while driving the vehicle, they would be responsible to pay for the damages.`} />
                    </React.Fragment>
                );
                break;
            case `13D`: // Comprehensive Cover - Limited Glass
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This limits the coverage on a vehicle's glass for a reduced premium.`} />
                    </React.Fragment>
                );
                break;
            case `20`: // Loss of Vehicle 
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`Let's say your vehicle is being replaced or repaired by your insurance, this endorsement will cover the cost to rent a vehicle in the interim.`} />
                    </React.Fragment>
                );
                break;
            case `23A`: // Mortgage Endorsement
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This endorsement is applicable when your vehicle has been financed. It will ensure the lender receives a copy of the policy and they will be informed if the policy is cancelled.`} />
                    </React.Fragment>
                );
                break;
            case `27`: // Liability for Damage to Non-Owned Vehicles
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This endorsement covers you for any physical damage that may occur to a vehicle that you are operating but do not personally own.`} />
                    </React.Fragment>
                );
                break;
            case `35`: // Emergency Road Service
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`For other non-accident incidents (such as mechanical problems), you'll need to have roadside assistance added to your policy to help cover the costs. Roadside assistance is usually limited to a certain dollar amount.`} />
                    </React.Fragment>
                );
                break;
            case `39`: // Accident Waiver - Premium Protector
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This endorsement protects your premium from increasing next year if you get into an accident this year.`} />
                    </React.Fragment>
                );
                break;
            case `43`: // Removing Depreciation Deduction
            case `43A`: // Removing Depreciation Deduction (for Lessee)
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This endorsement removes the right of the insurer to deduct depreciation from the value of your vehicle when settling a claim.`} />
                    </React.Fragment>
                );
                break;
            case `44`: // Family Protection
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This endorsement protects you and your eligible family members if they are invovled in an accident where you are not at fault and the other party does not have sufficent insurance or ran away from the scene of the accident.`} />
                    </React.Fragment>
                );
                break;
            case `AB`: // Accident Benefits
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`Let's say you get into a car accident, regardless of who is at fault, you are covered for medical and other expenses (such as Income Replacement, Death and Funeral Benefits, Caregiver Expenses etc.).`} />
                    </React.Fragment>
                );
                break;
            case `AP`: // All Perils
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`Combines Collision or Upset and Comprehensive coverages. Also covers loss or damage caused by theft by someone who lives in your home or has access to your vehicle.`} />
                    </React.Fragment>
                );
                break;
            case `CHHMB`: // Caregiver
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This endorsement upgrades the standard caregiver benefits so they may be claimed under all injuries. As part of standard accident benefits, this coverage can only be claimed under a catastrophic injury.`} />
                    </React.Fragment>
                );
                break;
            case `CIMRB`: // Catastrophic Impairment
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`Similar to Medical, Rehabilitation and Attendant Care coverage, but for catastrophic injuries (loss of a leg). `} />
                    </React.Fragment>
                );
                break;
            case `CMP`: // Comprehensive
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`Let's say you damage your vehicle but no car accident was involved (think falling objects). This covers any damage to your vehicle that may occur outstide car accidents (fire, lighting etc.) and theft as well.`} />
                    </React.Fragment>
                );
                break;
            case `COL`: // Collision
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`Let's say you get into a car accident and you happen to be at fault. This covers damage to your vehicle.`} />
                    </React.Fragment>
                );
                break;
            case `DCB`: // Dependant Care
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This endorsement pays for additional expenses incurred to care for any dependants you may have if you are employed and injured in an accident.`} />
                    </React.Fragment>
                );
                break;
            case `DFB`: // Death and Funeral
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This endorsement upgrades the standard death and funeral benefits potentially up to $50,000 to your eligible spouse, $20,000 to each dependant, and $8,000 for funeral expenses. Please ensure to read your policy limitations for exact amounts applicable to your policy.`} />
                    </React.Fragment>
                );
                break;
            case `IDB`: // Indexation Benefit
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This endorsement provides you with an annual adjustment for inflation for accident benefits as determined by the Consumer Price Index of Canada.`} />
                    </React.Fragment>
                );
                break;
            case `MRB`: // Medical Rehabilitation and Attendant Care
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This endorsement upgrades your standard medical, rehabilitation and attendant care benefits to potentially upto to $1,000,000 for non-catastrophic injuries.`} />
                    </React.Fragment>
                );
                break;
            case `SP`: // Specified Perils
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`Covers losses caused by a specific event, such as fire; theft or attempted theft; certain natural disasters; explosion; civil disturbance; and various other perils.`} />
                    </React.Fragment>
                );
                break;
            case `TPBI`: // Liability - Bodily Injury
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`Let's say you get into a car accident, you happen to be at fault and unfortunately someone gets injured (ouch!). This will cover the injured persons medical expenses and lost wages that were incurred as a result of the accident.`} />
                    </React.Fragment>
                );
                break;
            case `TPDC`: // Collision - Not At Fault
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`Let's say you get into a car accident out of no fault of your own. This will cover the damage to your vehicle and its contents (also called Direct Compensation).`} />
                    </React.Fragment>
                );
                break;            
            case `TPPD`: // Liability - Property Damage
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This covers the personal liability of any authorized driver for damage or injury to others.`} />
                    </React.Fragment>
                );
                break;
            case `UA`: // Uninsured Automobile
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This covers you and your vehicle if you are struck by an uninsured motorist.`} />
                    </React.Fragment>
                );
                break;
            case `UA1`: // Uninsured Automobile
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This covers you for any uninsured or underinsured in case of an accident for medical bills, lost wages, pain and suffering.`} />
                    </React.Fragment>
                );
                break;
            case `WIB`: // Income Replacement
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`If you cannot work as the result of an automobile accident, you may be eligible for basic weekly income replacement benefits of 70 per cent of your gross income `} />
                    </React.Fragment>
                );
                break;
            case `PAK01`: // Loss of Vehicle / Emergency Road Service / Waiver of Depreciation
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This endorsement pack includes the following coverages: Loss of Vehicle (you're covered for a car rental while you vehicle is being repaired), Emergency Road Service (you're covered for the cost of towing your vehicle in case of an accident) and Waiver of Depreciation (removes the right of the insurer to deduct depreciation from the value of your vehicle when settling a claim).`} />
                    </React.Fragment>
                );
                break;
            case `PAK02`: // Liability Non Owned Vehicles / Emergency Road Service/ Waiver of Depreciation
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This endorsement pack includes the following coverages: Liability for Damage to Non-Owned Vehicles (you're covered for any physical damage to a vehicle you're operating but don't own), Emergency Road Service (you're covered for the cost of towing your vehicle in case of an accident) and Waiver of Depreciation (removes the right of the insurer to deduct depreciation from the value of your vehicle when settling a claim).`} />
                    </React.Fragment>
                );
                break;
            case `PAK03`: // Loss of Vehicle / Waiver of Depreciation
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This endorsement pack includes the following coverages: Loss of Vehicle (you're covered for a car rental while you vehicle is being repaired) and Waiver of Depreciation (removes the right of the insurer to deduct depreciation from the value of your vehicle when settling a claim).`} />
                    </React.Fragment>
                );
                break;
            case `PAK04`: // Loss of Vehicle / Emergency Road Service
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This endorsement pack includes the following coverages: Loss of Vehicle (you're covered for a car rental while you vehicle is being repaired) and Emergency Road Service (you're covered for the cost of towing your vehicle in case of an accident).`} />
                    </React.Fragment>
                );
                break;
            case `PAK05`: // Loss of Vehicle / Liability Non Owned Vehicles / Waiver of Depreciation
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This endorsement pack includes the following coverages: Loss of Vehicle (you're covered for a car rental while you vehicle is being repaired), Liability for Damage to Non-Owned Vehicles (you're covered for any physical damage to a vehicle you're operating but don't own) and Waiver of Depreciation (removes the right of the insurer to deduct depreciation from the value of your vehicle when settling a claim).`} />
                    </React.Fragment>
                );
                break;
            case `PAK06`: // Loss of Vehicle / Liability Non Owned Vehicles
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This endorsement pack includes the following coverages: Loss of Vehicle (you're covered for a car rental while you vehicle is being repaired) and Liability for Damage to Non-Owned Vehicles (you're covered for any physical damage to a vehicle you're operating but don't own).`} />
                    </React.Fragment>
                );
                break;
            case `PAK07`: // Loss of Vehicle / Liability Non Owned Vehicles / Accident Benefits Injured Person
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This endorsement pack includes the following coverages: Loss of Vehicle (you're covered for a car rental while you vehicle is being repaired), Liability for Damage to Non-Owned Vehicles (you're covered for any physical damage to a vehicle you're operating but don't own) and Accident Benefits for Injured Persons. `} />
                    </React.Fragment>
                );
                break;
            case `PAK08`: // Loss of Vehicle / Liability Non Owned Vehicles / Emergency Road Service / Waiver of Depreciation
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This endorsement pack includes the following coverages: Loss of Vehicle (you're covered for a car rental while you vehicle is being repaired), Liability for Damage to Non-Owned Vehicles (you're covered for any physical damage to a vehicle you're operating but don't own), Emergency Road Service (you're covered for the cost of towing your vehicle in case of an accident) and Waiver of Depreciation (removes the right of the insurer to deduct depreciation from the value of your vehicle when settling a claim).`} />
                    </React.Fragment>
                );
                break;
            case `PAK09`: // Loss of Vehicle / Liability Non Owned Vehicles / Emergency Road Service
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This endorsement pack includes the following coverages: Loss of Vehicle (you're covered for a car rental while you vehicle is being repaired), Liability for Damage to Non-Owned Vehicles (you're covered for any physical damage to a vehicle you're operating but don't own) and Emergency Road Service (you're covered for the cost of towing your vehicle in case of an accident).`} />
                    </React.Fragment>
                );
                break;
            case `PAK10`: // Drive Others Vehicles / Loss of Vehicle / Waiver of Depreciation
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This endorsement pack includes the following coverages: Loss of Vehicle (you're covered for a car rental while you vehicle is being repaired) and Waiver of Depreciation (removes the right of the insurer to deduct depreciation from the value of your vehicle when settling a claim).`} />
                    </React.Fragment>
                );
                break;
            case `PAK11`: // Drive Other Vehicles / Loss of Vehicle / Liability Non Owned Vehicles / Accident Benefits Injured Person
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This endorsement pack includes the following coverages: Drive Other Automobiles (provides you with the same level of coverage when driving other vehicles), Loss of Vehicle (you're covered for a car rental while you vehicle is being repaired), Liability for Damage to Non-Owned Vehicles (you're covered for any physical damage to a vehicle you're operating but don't own) and Accident Benefits for Injured Persons. `} />
                    </React.Fragment>
                );
                break;
            case `PAK12`: // Drive Other Vehicles / Loss of Vehicle / Liability Non Owned Vehicles / Accident Benefits Injured Person
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This endorsement pack includes the following coverages:  Drive Other Automobiles (provides you with the same level of coverage when driving other vehicles), Loss of Vehicle (you're covered for a car rental while you vehicle is being repaired), Liability for Damage to Non-Owned Vehicles (you're covered for any physical damage to a vehicle you're operating but don't own) and Accident Benefits for Injured Persons. `} />
                    </React.Fragment>
                );
                break;
            case `PAK13`: // Driver Other Vehicles / Loss of Vehicle / Liability Non Owned Vehicles
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This endorsement pack includes the following coverages:  Drive Other Automobiles (provides you with the same level of coverage when driving other vehicles), Loss of Vehicle (you're covered for a car rental while you vehicle is being repaired) and Liability for Damage to Non-Owned Vehicles (you're covered for any physical damage to a vehicle you're operating but don't own).`} />
                    </React.Fragment>
                );
                break;
            case `PAK14`: // Driver Other Vehicles / Loss of Vehicle / Liability Non Owned Vehicles / Waiver of Depreciation
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This endorsement pack includes the following coverages:  Drive Other Automobiles (provides you with the same level of coverage when driving other vehicles), Loss of Vehicle (you're covered for a car rental while you vehicle is being repaired), Liability for Damage to Non-Owned Vehicles (you're covered for any physical damage to a vehicle you're operating but don't own) and Waiver of Depreciation (removes the right of the insurer to deduct depreciation from the value of your vehicle when settling a claim).`} />
                    </React.Fragment>
                );
                break;
            case `PAK15`: // Accident Benefits
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This replaces your monthly income and covers medical costs if injured in your vehicle.`} />
                    </React.Fragment>
                );
                break;

            //                  ***************************************************************Home Icons***************************************************************
            case `HTRAVEL`: // Deductible
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This covers you for medical coverage, medical bills, medical assistance when you are outside the country.`} />
                    </React.Fragment>
                );
                break;
            case `CCYBER`: // Cyber & Ransomware
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This covers your business against cyber security risks and data breaches, phishing scams, ransomware and social engineering attacks. The coverage can help in restoring customer identities, recover data and damages hardware or networks.`} />
                    </React.Fragment>
                );
                break;
            case `CTHEALTH`: // Telehealth
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This covers medical or health services and visits, psychotherapy and consultations where the healthcare provider is using audio and or video technology to communicate, such as a phone or a computer.`} />
                    </React.Fragment>
                );
            case `PP`: // Personal Property
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`Personal property includes all your personal items that you own or use, that are kept at your premises. Think about what it would cost to replace everything if you were to lose it all in a fire, or if it were stolen. Everything has a cost - underwear, socks, cutlery, toiletries etc. It’s always better to have personal property insurance and not need it, then needing and not having it.`} />
                    </React.Fragment>
                );
                break;
            case `IDTFT`: // IdentityTheft
                items = [
                    { code: `1`, value: `Lost pay if you have to miss work` },
                    { code: `2`, value: `Replacing your driver’s license, passport or birth certificate` },
                    { code: `3`, value: `Getting credit reports, notarizing affidavits and other similar documents` },
                    { code: `4`, value: `Phone, fax and mailing expenses` },
                    { code: `5`, value: `Re-applying for existing loans` },
                    { code: `6`, value: `Any legal costs you have` }
                ];
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`If your identity is unlawfully stolen, Identify Theft insurance provides $15,000 coverage to help with the cost of restoring and reclaiming including lost pay, legal costs or costs associated with obtaining credit reports, replacing your driver’s license, passport or birth certificate.`} />
                        <LabelContainer size={Sizes.md} top={Sizes.xs} alignment={Alignments.left}
                            text={`If you increased to 30,000, the coverage includes:`} />
                        <BulletList size={Sizes.md} references={items} top={Sizes.xs} />
                    </React.Fragment>
                );
                break;
            case `ERQK`: // Earthquake Building
            case `ERQKB`: // Earthquake Building
            case `ERQKC`: // Earthquake Contents
                items = [
                    { code: `1`, value: `Losses caused by earthquake directly or indirectly, such as smoke damage, an explosion, flooding, or theft` },
                    { code: `2`, value: `Seismic upgrading or land stabilisation costs` },
                    { code: `3`, value: `Damage resulting from a leaking sprinkler system or other safety device` }
                ];
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`Earthquake insurance covers the loss or damage caused to the property and its contents caused by the shaking of the earth; including but not limited to earthquake, subsidence, sinkhole collapse, landslide, earth sinking, tsunami or volcanic action. If the shaking of the earth results in a fire (caused by a broken gas main), only the resulting loss or damage from the fire would likely be covered under an ordinary home insurance policy. We will pay for the resulting loss or damage.`} />
                        <LabelContainer size={Sizes.md} top={Sizes.xs} alignment={Alignments.left}
                            text={`Some examples of damage are cracks in foundation, roof shift, destroyed contents.`} />
                        <LabelContainer size={Sizes.md} top={Sizes.xs} alignment={Alignments.left}
                            text={`What’s not covered by Earthquake coverage?`} />
                        <BulletList size={Sizes.md} references={items} top={Sizes.xs} />
                    </React.Fragment>
                );
                break;
            case `SEWER`: // Sewer Backup
                items = [
                    { code: `1`, value: `A severe rainstorm causes surface water to enter your home` },
                    { code: `2`, value: `A lake or river overflows, causing flood water to enter your home` }
                ];
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This coverage is for sewer backup damage protection. Did you know you’re twice as likely to have damage from a water or sewer backup problem than a fire or theft?`} />
                        <LabelContainer size={Sizes.md} top={Sizes.xs} alignment={Alignments.left}
                            text={`Sewer backup coverage takes care of some of the costs of damage from water or sewage that enters your home by accidental discharge or escape/overflow from a sewer, sump or septic tank.`} />
                        <LabelContainer size={Sizes.md} top={Sizes.xs} alignment={Alignments.left}
                            text={`Make sure you’re covered for the high cost of sewer backup damage with this upgrade. If you have concerns, or if you aren’t sure if you need this coverage, talk to one of our brokers. We can check the history of your rented property and see if you are at risk.`} />
                        <LabelContainer size={Sizes.md} top={Sizes.xs} alignment={Alignments.left}
                            text={`What’s not covered by Sewer backup coverage?`} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`These two below are not covered by Sewer backup coverage, but rather covered by Water Protection Coverage (already included in your policy).`} />
                        <BulletList size={Sizes.md} references={items} top={Sizes.xs} />
                    </React.Fragment>
                );
                break;
            case `PL`: // Legal Liability
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`Personal liability could protect you against damages caused by someone slipping and falling on your property, getting bit by your dog or if you cause a fire and your landlord sues you for damages. Personal liability does not cover criminal or intentional acts, most motorized vehicles, cyberbullying or business/work related activities.`} />
                    </React.Fragment>
                );
                break;
            case `DEDUCTIBLE`: // Deductible
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`A deductible means that you will have to cover any losses under $1,000. For a loss greater than that, you would subtract this amount from the total of your loss, then the rest is covered for you - to the maximum of your coverage amount.`} />
                    </React.Fragment>
                );
                break;
            case `ALVE`: // Living Expenses
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`Additional living expenses insurance covers your moving costs, food and living expenses while you are displaced from your home as a result of a claim.`} />
                    </React.Fragment>
                );
                break;
            case `CLUMBR`: // Liability Umbrella
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This covers for claims that may be excluded from general liability policies such as false arrest, slander and liability on rental units you own.`} />
                    </React.Fragment>
                );
                break;
            case `RVCMP`: // Voluntary Compensation for Residence
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={``} />
                    </React.Fragment>
                );
                break;
            case `ARIB`: // Improvements and Betterments
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={``} />
                    </React.Fragment>
                );
                break;
            case `DWELL`: // Dwelling - Residence
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`Coverage to help you repair your home if it gets damaged or destroyed by things like fire and lightning strikes. Make sure to read the policy limitation details to determine which perils are covered by your chosen carrier. Insured perils can include things like: Smoke, Theft, Wind, Aircraft of Vehicle Impact and Falling objects. `} />
                    </React.Fragment>
                );
                break;
            case `VLPD`: // Voluntary Property Damage
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`Let's say you or your family member accidentally causes damage to another individual, business or even some organization's property - this coverage will compensate the victim for their loss. This coverage can be triggered regardless of whether you are legally liable or not. Make sure to review the limit set by your insurance company. `} />
                    </React.Fragment>
                );
                break;
            case `MEDPM`: // Voluntary Medical Payments
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`Let's say you have a handy man over and they accidentally trip walking in and break their arm (Ouch!) - this coverage will compensate the handy man for minor medical costs up to the limit dictated by the carrier. Things like ambulance expenses, arm cast and physiotherapy would typically be covered for the injured party. This coverage can be triggered regardless of whether you are legally liable or not. Make sure to review the limit set by your insurance company. `} />
                    </React.Fragment>
                );
                break;
            case `GUARR`: // Guarenteed Replacement Cost
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This coverage guarantees that in the event your home requires repair, it will be repaired to its full value at the time the loss occurred. `} />
                    </React.Fragment>
                );
                break;
            case `HSL`: // Single Limit
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`Let's say a fire causes a loss which damages your home, garage, personal property and requires you to move out during repairs. Typically each loss would be subject to the limit set for individual coverages for your Dwelling, Detached Structures, Personal Property and Additional Living Expenses. A single limit coverage allows you to combine the individual limits of each coverage to determine the maximum amount of insurance available to cover your losses. `} />
                    </React.Fragment>
                );
                break;
            case `FDC`: // Fire Department Charges
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`Let's say that a potential fire at your house requires you to call in the fire department. If there happen to be any charges incurred for their visit, this coverage will cover those up to the limit specified by your insurance company.`} />
                    </React.Fragment>
                );
                break;
            case `OS`: // Other Structures
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`If you have a detached garage or tool shed, it is typically insured separately under this coverage. Make sure to review the limit set by your insurance company.`} />
                    </React.Fragment>
                );
                break;
            case `BYLAW`: // By Laws
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`If your home need to be rebuilt to meet current building codes and bylaws, this coverage ensures that any extra expenses that you may incur are insurable.`} />
                    </React.Fragment>
                );
                break;
            case `OVWAT`: // Overland Water
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`If you live near a body of water, you should consider adding this coverage. Let's say a rising river caused damage to your home, this coverage would protect you from the loss incurred.`} />
                    </React.Fragment>
                );
                break;
            case `SLEXT`: // Service Line Coverage
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`You can add service line coverage to your policy to avoid any costs associated with the replacement or repair of failed service lines on your property. Make sure to review the types of lines covered, causes of loss and what's exactly covered in the policy limitations specified by your insurer. `} />
                    </React.Fragment>
                );
                break;
            case `GRNDW`: // Ground Water
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This coverage will pay for losses or damages incurred by things like flood, surface water that enters your dwelling and ground water. `} />
                    </React.Fragment>
                );
                break;
            case `WINDC`: // Wind
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This coverage will pay for losses or damages caused by windstorms. Make sure to review the policy limitations set by your insurer.`} />
                    </React.Fragment>
                );
                break;
            case `HAILC`: // Hail
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`This coverage will pay for losses or damages caused by hailstorms. Make sure to review the policy limitations set by your insurer.`} />
                    </React.Fragment>
                );
                break;
            case `PROPERTYVALUE`: // SMO
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text='Property value fluctuation $250,000' />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`Securemyoffer will guarantee the offer amount in your purchase and sale agreement up to a $250,000 fluctuation which means; if your house sells for less value the second time on the market we cover the losses up to $250,000.`} />
                    </React.Fragment>
                );
                break;
            case `LEGALEXPENSES`: // SMO
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text='Legal Expenses - $50,000' />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`We cover you for any additional legal expenses incurred in remedying the sale with the existing buyer or taking action against them to recover any losses.`} />
                    </React.Fragment>
                );
                break;
            case `MAINTENANCE`: // SMO
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text='Property Maintenance Costs - $30,000' />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`Securemyoffer will cover necessary costs to maintain your property, should the buyer default. We will remove your cashflow responsibility for the property. Securemyoffer will assume the mortgage payments, property tax, utilities and general property maintenance as required up to $30,000.`} />
                    </React.Fragment>
                );
                break;
            case `HOMESTAGING`: // SMO
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text='Home Staging - $20,000' />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`Securemyoffer will ensure maximum resale value by staging the property after you move out.  We’ll work with your existing realtor to ensure the property is staged and attractive to buyers and will cover staging costs up to $20,000.`} />
                    </React.Fragment>
                );
                break;
            case `REALTORCOVERAGE`: // SMO
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text='Realtor Coverage - 3%' />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`In the event of a failed closing the seller is still liable to pay commissions to the selling Realtor - after all it’s not their fault the buyer defaulted. Although it is rare for a Selling Agent to enforce this, technically the seller can be held liable. Securemyoffer works with your existing realtor to resell the property a second time and we guarantee their commission on the initial agreement up to 3%`} />
                    </React.Fragment>
                );
                break;
            case `PROFITPROTECTION`: // SMO
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text='Profit Protection - Unlimited' />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`As a Lloyds of London backed product, Securemyoffer.com can never be at a profit, we are here for the sole purpose of insuring your losses which means that any profit resulting from an increased resale value is 100% yours to keep. We do not profit on the resale of your properties.`} />
                    </React.Fragment>
                );
                break;
            case `HOMESOLDGUARANTEE`: // SMO
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text='Home Sold Guarantee' />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={`We guarantee that if your property is not sold within 90 days of buyer default we will acquire the property at the original agreed upon price and compensate your real estate agent their agreed commission up to 3% of the property value.`} />
                    </React.Fragment>
                );
                break;
            case 'CONTENT_PREMIUM':
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer
                            text="Protects your business's equipment and stock from a wide range of risks, including natural disasters and sewer backups"
                            size={Sizes.md}
                            alignment={Alignments.left}
                        />
                    </React.Fragment>
                );
                break;
            case 'BUSINESS_INTERUPTION_PREM':
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer
                            text='Covers lost income and operating expenses if your business is forced to close due to a covered event.'
                            size={Sizes.md}
                            alignment={Alignments.left}
                        />
                    </React.Fragment>
                );
                break;
            case 'CRIME_PREM':
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer
                            text='Protects against financial losses from theft, fraud, or other criminal acts affecting your business.'
                            size={Sizes.md}
                            alignment={Alignments.left}
                        />
                    </React.Fragment>
                );
                break;
            case 'EQUIPMENT_BREAKDOWN':
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer
                            text='Covers repair or replacement costs for your business equipment if it breaks down due to mechanical or electrical failure.'
                            size={Sizes.md}
                            alignment={Alignments.left}
                        />
                    </React.Fragment>
                );
                break;
            case 'PRODUCTS_GL_PREM':
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer
                            text='The cost of coverage for claims related to injury or damage caused by products your business sells or manufactures.'
                            size={Sizes.md}
                            alignment={Alignments.left}
                        />
                    </React.Fragment>
                );
                break;
            case 'BLDG_PREMIUM':
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer
                            text='The cost of insurance coverage specifically for the structure of your business property.'
                            size={Sizes.md}
                            alignment={Alignments.left}
                        />
                    </React.Fragment>
                );
                break;
            case 'ON_PREM_GL_PREM':
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer
                            text='The cost of coverage protecting your business against claims of injury or property damage that occur on your premises.'
                            size={Sizes.md}
                            alignment={Alignments.left}
                        />
                    </React.Fragment>
                );
                break;
            case 'FLOOD_PREMIUM':
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer
                            text='The cost of insurance coverage specifically for damage caused by flooding.'
                            size={Sizes.md}
                            alignment={Alignments.left}
                        />
                    </React.Fragment>
                );
                break;
            case 'COMMERCIAL_GENERAL_LIABILITY':
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer
                            text='Commercial General Liability (CGL) Coverage is a type of insurance policy that provides protection to businesses against claims of liability for bodily injury, property damage, and personal and advertising injury that may arise from their operations, products, or services.'
                            size={Sizes.md}
                            alignment={Alignments.left}
                        />
                    </React.Fragment>
                );
                break;
            default:
                toolTipContent = (
                    <React.Fragment>
                        <Header size={Sizes.md} bottom={Sizes.sm} alignment={Alignments.left} text={header} />
                        <LabelContainer size={Sizes.md} alignment={Alignments.left}
                            text={code} />
                    </React.Fragment>
                );
        }


        return toolTipContent;
    }

    render() {
        return (
            <ToolTip
                mode={this.props.mode ? this.props.mode : `default`}
                children={this.getToolTipContent(this.props.title, this.props.code)}
            />);
    }
}

export default CoverageTooltip;
