import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Form,
    FormChecklist,
    FormDropDown
} from 'metrix-common';

class ProfileBtisWcCreditOpportunity extends React.Component {
    componentDidMount() {        
    }

    render() {
        return (
            <React.Fragment>
                <Form
                    header={<FormattedMessage id={`Credit Opportunity`} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormDropDown
                        label={<FormattedMessage id={`What is the applicant's annual employee turnover rate?`} />}
                        value={{ code: this.props.policy.btisWcCustomInfo.creditOpportunity?.employeeTurnoverCode, value: this.props.policy.btisWcCustomInfo.creditOpportunity?.employeeTurnoverName }}
                        references={this.props.reference?.references[`BTIS-WC`] && this.props.reference.references[`BTIS-WC`][`EmployeeTurnover`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`BTIS-WC.EmployeeTurnover`)}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `btisWcCustomInfo.creditOpportunity.employeeTurnoverCode`, value: value.code });
                            this.props.doReducePolicyElement({ code: `btisWcCustomInfo.creditOpportunity.employeeTurnoverName`, value: value.value });
                        }}
                        allowEdit
                    />
                    <FormDropDown
                        label={<FormattedMessage id={`How often does the business clean employee work areas, common areas and/or public areas?`} />}
                        value={{ code: this.props.policy.btisWcCustomInfo.creditOpportunity?.cleanWorkAreasCode, value: this.props.policy.btisWcCustomInfo.creditOpportunity?.cleanWorkAreasName }}
                        references={this.props.reference?.references[`BTIS-WC`] && this.props.reference.references[`BTIS-WC`][`CleanWorkAreas`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`BTIS-WC.CleanWorkAreas`)}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `btisWcCustomInfo.creditOpportunity.cleanWorkAreasCode`, value: value.code });
                            this.props.doReducePolicyElement({ code: `btisWcCustomInfo.creditOpportunity.cleanWorkAreasName`, value: value.value });
                        }}
                        allowEdit
                    />
                </Form>
                <Form
                    header={<FormattedMessage id={`Credit Opportunity Questions`} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormChecklist
                        label={<FormattedMessage id={`Check all that apply`} />}
                        value={this.props.policy.btisWcCustomInfo.creditOpportunity?.creditOpportunitySelectedItems}
                        references={this.props.reference?.references[`BTIS-WC`] && this.props.reference.references[`BTIS-WC`][`CreditQuestions`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`BTIS-WC.CreditQuestions`)}
                        changeEvent={(values) => {
                            this.props.doReducePolicyElement({ code: `btisWcCustomInfo.creditOpportunity.creditOpportunitySelectedItems`, value: values });
                        }}
                        allowEdit
                    />
                </Form>
            </React.Fragment>
        );
    }
}

export default ProfileBtisWcCreditOpportunity;