import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Form,
    FormChecklist,
    FormRadio,
    convertFromBoolean,
    convertToBoolean
} from 'metrix-common';

class ProfileEmployersWcAdditionalInformation extends React.Component {
    componentDidMount() {
        const pageHasTooltipInfo = this.props.tooltipInfo && this.props.tooltipInfo.pages && this.props.tooltipInfo.pages.length && this.props.tooltipInfo.pages.some(p => p.code === this.props.pageCode);
        if (!pageHasTooltipInfo) {
            this.props.doGetTooltipInfo(this.props.policy.product);
        }
    }

    getIndustries = () => {
        const industryCodes = this.props.policy.employersWcCustomInfo.locations.filter(location => location.industryTypeCode).map(location => location.industryTypeCode);

        return [...industryCodes];
    }

    getStates = () => {
        const stateCodes = this.props.policy.employersWcCustomInfo.locations.filter(location => location.address?.stateCode).map(location => location.address?.stateCode);

        return [...stateCodes];
    }

    render() {
        const industries = this.getIndustries();
        const states = this.getStates();

        return (
            <React.Fragment>
                <Form
                    pageCode={this.props.pageCode}
                    sectionCode='additionalInformation'
                    saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                    reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                    tooltipInfo={this.props.tooltipInfo}
                    validationInfo={this.props.validationInfo}
                    header={<FormattedMessage id={`Additional Information (General)`} />}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormRadio
                        fieldCode='general_EMPCO1000'
                        label={<FormattedMessage id={'Does the Applicant engage sub-contractors or independent contractors?'} />}
                        value={this.props.policy.employersWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.employersWcCustomInfo.additionalInformation?.general_EMPCO1000) }}
                        references={this.props.reference.references && this.props.reference.references['YesNo']}
                        referencesEvent={() => this.props.reference.doGetReferences('YesNo')}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: 'employersWcCustomInfo.additionalInformation.general_EMPCO1000', value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {
                        this.props.policy.employersWcCustomInfo?.additionalInformation?.general_EMPCO1000 &&
                        <FormRadio
                            fieldCode='general_EMPCO1100'
                            label={<FormattedMessage id={'Are Certificates of Insurance obtained when sub-contractors or independent contractors are used?'} />}
                            value={this.props.policy.employersWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.employersWcCustomInfo.additionalInformation?.general_EMPCO1100) }}
                            references={this.props.reference.references && this.props.reference.references['YesNo']}
                            referencesEvent={() => this.props.reference.doGetReferences('YesNo')}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: 'employersWcCustomInfo.additionalInformation.general_EMPCO1100', value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                    }
                    <FormRadio
                        fieldCode='general_EMPCO1001'
                        label={<FormattedMessage id={'Does the Applicant use motor vehicles for business purposes?'} />}
                        value={this.props.policy.employersWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.employersWcCustomInfo.additionalInformation?.general_EMPCO1001) }}
                        references={this.props.reference.references && this.props.reference.references['YesNo']}
                        referencesEvent={() => this.props.reference.doGetReferences('YesNo')}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: 'employersWcCustomInfo.additionalInformation.general_EMPCO1001', value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {
                        this.props.policy.employersWcCustomInfo?.additionalInformation?.general_EMPCO1001 &&
                        <FormRadio
                            fieldCode='general_EMPCO1101'
                            label={<FormattedMessage id={'Are Motor Vehicle Records checked?'} />}
                            value={this.props.policy.employersWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.employersWcCustomInfo.additionalInformation?.general_EMPCO1101) }}
                            references={this.props.reference.references && this.props.reference.references['YesNo']}
                            referencesEvent={() => this.props.reference.doGetReferences('YesNo')}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: 'employersWcCustomInfo.additionalInformation.general_EMPCO1101', value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                    }
                    <FormRadio
                        fieldCode='general_EMPCO1002'
                        label={<FormattedMessage id={'Does the Applicant provide group transportation, where five or more employees are in a vehicle at one time?'} />}
                        value={this.props.policy.employersWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.employersWcCustomInfo.additionalInformation?.general_EMPCO1002) }}
                        references={this.props.reference.references && this.props.reference.references['YesNo']}
                        referencesEvent={() => this.props.reference.doGetReferences('YesNo')}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: 'employersWcCustomInfo.additionalInformation.general_EMPCO1002', value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {
                        states.some(x => x !== 'MO') &&
                        <FormRadio
                            fieldCode='general_EMPCO1007'
                            label={<FormattedMessage id={'Any prior coverage declined/cancelled/non-renewed (other than for pandemic, risk class of business or carrier no longer writing) in the last three (3) years?'} />}
                            value={this.props.policy.employersWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.employersWcCustomInfo.additionalInformation?.general_EMPCO1007) }}
                            references={this.props.reference.references && this.props.reference.references['YesNo']}
                            referencesEvent={() => this.props.reference.doGetReferences('YesNo')}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: 'employersWcCustomInfo.additionalInformation.general_EMPCO1007', value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                    }
                    <FormRadio
                        fieldCode='general_EMPCO1004'
                        label={<FormattedMessage id={'Are employee health plans provided?'} />}
                        value={this.props.policy.employersWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.employersWcCustomInfo.additionalInformation?.general_EMPCO1004) }}
                        references={this.props.reference.references && this.props.reference.references['YesNo']}
                        referencesEvent={() => this.props.reference.doGetReferences('YesNo')}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: 'employersWcCustomInfo.additionalInformation.general_EMPCO1004', value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {
                        (this.props.policy.employersWcCustomInfo?.additionalInformation?.general_EMPCO1004 && states.some(x => x === 'CA')) &&
                        <FormRadio
                            fieldCode='general_EMPCO1104'
                            label={<FormattedMessage id={'If health coverage is provided by Anthem Blue Cross would you like to participate in an Integrated Medicomp Program?'} />}
                            value={this.props.policy.employersWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.employersWcCustomInfo.additionalInformation?.general_EMPCO1104) }}
                            references={this.props.reference.references && this.props.reference.references['YesNo']}
                            referencesEvent={() => this.props.reference.doGetReferences('YesNo')}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: 'employersWcCustomInfo.additionalInformation.general_EMPCO1104', value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                    }
                    {
                        (this.props.policy.employersWcCustomInfo?.additionalInformation?.general_EMPCO1004 && states.some(x => x === 'CO')) &&
                        <FormRadio
                            fieldCode='general_EMPCO1105'
                            label={<FormattedMessage id={'If health coverage is provided by Anthem Blue Cross/Blue Shield would you like to participate in an Integrated Medicomp Program?'} />}
                            value={this.props.policy.employersWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.employersWcCustomInfo.additionalInformation?.general_EMPCO1105) }}
                            references={this.props.reference.references && this.props.reference.references['YesNo']}
                            referencesEvent={() => this.props.reference.doGetReferences('YesNo')}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: 'employersWcCustomInfo.additionalInformation.general_EMPCO1105', value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                    }
                    <FormRadio
                        fieldCode='general_EMPCO1005'
                        label={<FormattedMessage id={'Do you lease employees to or from other employers?'} />}
                        value={this.props.policy.employersWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.employersWcCustomInfo.additionalInformation?.general_EMPCO1005) }}
                        references={this.props.reference.references && this.props.reference.references['YesNo']}
                        referencesEvent={() => this.props.reference.doGetReferences('YesNo')}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: 'employersWcCustomInfo.additionalInformation.general_EMPCO1005', value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    <FormRadio
                        fieldCode='general_EMPCO1006'
                        label={<FormattedMessage id={'Does Applicant own, operate or lease aircraft/watercraft?'} />}
                        value={this.props.policy.employersWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.employersWcCustomInfo.additionalInformation?.general_EMPCO1006) }}
                        references={this.props.reference.references && this.props.reference.references['YesNo']}
                        referencesEvent={() => this.props.reference.doGetReferences('YesNo')}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: 'employersWcCustomInfo.additionalInformation.general_EMPCO1006', value: convertToBoolean(value.code) })}
                        allowEdit
                    />

                    {/*<FormChecklist*/}
                    {/*    fieldCode='additionalInformationAcordSelectedItems_GENERAL'*/}
                    {/*    label={<FormattedMessage id={`Check all that apply`} />}*/}
                    {/*    value={this.props.policy.employersWcCustomInfo.additionalInformation?.additionalInformationAcordSelectedItems_GENERAL}*/}
                    {/*    references={this.props.reference?.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`][`GENERAL`]}*/}
                    {/*    referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.AcordQuestions.GENERAL`)}*/}
                    {/*    changeEvent={(values) => {*/}
                    {/*        this.props.doReducePolicyElement({ code: `employersWcCustomInfo.additionalInformation.additionalInformationAcordSelectedItems_GENERAL`, value: values });*/}
                    {/*    }}*/}
                    {/*    allowEdit*/}
                    {/*/>*/}
                </Form>
                {industries.some(x => x === 'ARCHITECT') &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='additionalInformation'
                        rowId={this.props.rowId}
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id={`Additional Information (Condition: ARCHITECT industry)`} />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormChecklist
                            fieldCode='additionalInformationAcordSelectedItems_ARCHITECT'
                            label={<FormattedMessage id={`Check all that apply`} />}
                            value={this.props.policy.employersWcCustomInfo.additionalInformation?.additionalInformationAcordSelectedItems_ARCHITECT}
                            references={this.props.reference?.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`][`ARCHITECT`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.AcordQuestions.ARCHITECT`)}
                            changeEvent={(values) => {
                                this.props.doReducePolicyElement({ code: `employersWcCustomInfo.additionalInformation.additionalInformationAcordSelectedItems_ARCHITECT`, value: values });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {industries.some(x => x === 'AUTOSERV') &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='additionalInformation'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id={`Additional Information (Condition: AUTOSERV industry)`} />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormChecklist
                            fieldCode='additionalInformationAcordSelectedItems_AUTOSERV'
                            label={<FormattedMessage id={`Check all that apply`} />}
                            value={this.props.policy.employersWcCustomInfo.additionalInformation?.additionalInformationAcordSelectedItems_AUTOSERV}
                            references={this.props.reference?.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`][`AUTOSERV`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.AcordQuestions.AUTOSERV`)}
                            changeEvent={(values) => {
                                this.props.doReducePolicyElement({ code: `employersWcCustomInfo.additionalInformation.additionalInformationAcordSelectedItems_AUTOSERV`, value: values });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {industries.some(x => x === 'BAR') &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='additionalInformation'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id={`Additional Information (Condition: BAR industry)`} />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormChecklist
                            fieldCode='additionalInformationAcordSelectedItems_BAR'
                            label={<FormattedMessage id={`Check all that apply`} />}
                            value={this.props.policy.employersWcCustomInfo.additionalInformation?.additionalInformationAcordSelectedItems_BAR}
                            references={this.props.reference?.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`][`BAR`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.AcordQuestions.BAR`)}
                            changeEvent={(values) => {
                                this.props.doReducePolicyElement({ code: `employersWcCustomInfo.additionalInformation.additionalInformationAcordSelectedItems_BAR`, value: values });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {industries.some(x => x === 'BEAUTY') &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='additionalInformation'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id={`Additional Information (Condition: BEAUTY industry)`} />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormChecklist
                            fieldCode='additionalInformationAcordSelectedItems_BEAUTY'
                            label={<FormattedMessage id={`Check all that apply`} />}
                            value={this.props.policy.employersWcCustomInfo.additionalInformation?.additionalInformationAcordSelectedItems_BEAUTY}
                            references={this.props.reference?.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`][`BEAUTY`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.AcordQuestions.BEAUTY`)}
                            changeEvent={(values) => {
                                this.props.doReducePolicyElement({ code: `employersWcCustomInfo.additionalInformation.additionalInformationAcordSelectedItems_BEAUTY`, value: values });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {industries.some(x => x === 'CLERICAL') &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='additionalInformation'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id={`Additional Information (Condition: CLERICAL industry)`} />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormChecklist
                            fieldCode='additionalInformationAcordSelectedItems_CLERICAL'
                            label={<FormattedMessage id={`Check all that apply`} />}
                            value={this.props.policy.employersWcCustomInfo.additionalInformation?.additionalInformationAcordSelectedItems_CLERICAL}
                            references={this.props.reference?.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`][`CLERICAL`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.AcordQuestions.CLERICAL`)}
                            changeEvent={(values) => {
                                this.props.doReducePolicyElement({ code: `employersWcCustomInfo.additionalInformation.additionalInformationAcordSelectedItems_CLERICAL`, value: values });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {industries.some(x => x === 'DAYCARE') &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='additionalInformation'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id={`Additional Information (Condition: DAYCARE industry)`} />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormChecklist
                            fieldCode='additionalInformationAcordSelectedItems_DAYCARE'
                            label={<FormattedMessage id={`Check all that apply`} />}
                            value={this.props.policy.employersWcCustomInfo.additionalInformation?.additionalInformationAcordSelectedItems_DAYCARE}
                            references={this.props.reference?.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`][`DAYCARE`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.AcordQuestions.DAYCARE`)}
                            changeEvent={(values) => {
                                this.props.doReducePolicyElement({ code: `employersWcCustomInfo.additionalInformation.additionalInformationAcordSelectedItems_DAYCARE`, value: values });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {industries.some(x => x === 'ELECTRICAL') &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='additionalInformation'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id={`Additional Information (Condition: ELECTRICAL industry)`} />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormChecklist
                            fieldCode='additionalInformationAcordSelectedItems_ELECTRICAL'
                            label={<FormattedMessage id={`Check all that apply`} />}
                            value={this.props.policy.employersWcCustomInfo.additionalInformation?.additionalInformationAcordSelectedItems_ELECTRICAL}
                            references={this.props.reference?.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`][`ELECTRICAL`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.AcordQuestions.ELECTRICAL`)}
                            changeEvent={(values) => {
                                this.props.doReducePolicyElement({ code: `employersWcCustomInfo.additionalInformation.additionalInformationAcordSelectedItems_ELECTRICAL`, value: values });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {industries.some(x => x === 'FLOORING') &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='additionalInformation'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id={`Additional Information (Condition: FLOORING industry)`} />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormChecklist
                            fieldCode='additionalInformationAcordSelectedItems_FLOORING'
                            label={<FormattedMessage id={`Check all that apply`} />}
                            value={this.props.policy.employersWcCustomInfo.additionalInformation?.additionalInformationAcordSelectedItems_FLOORING}
                            references={this.props.reference?.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`][`FLOORING`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.AcordQuestions.FLOORING`)}
                            changeEvent={(values) => {
                                this.props.doReducePolicyElement({ code: `employersWcCustomInfo.additionalInformation.additionalInformationAcordSelectedItems_FLOORING`, value: values });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {industries.some(x => x === 'FOODSRV') &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='additionalInformation'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id={`Additional Information (Condition: FOODSRV industry)`} />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormChecklist
                            fieldCode='additionalInformationAcordSelectedItems_FOODSRV'
                            label={<FormattedMessage id={`Check all that apply`} />}
                            value={this.props.policy.employersWcCustomInfo.additionalInformation?.additionalInformationAcordSelectedItems_FOODSRV}
                            references={this.props.reference?.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`][`FOODSRV`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.AcordQuestions.FOODSRV`)}
                            changeEvent={(values) => {
                                this.props.doReducePolicyElement({ code: `employersWcCustomInfo.additionalInformation.additionalInformationAcordSelectedItems_FOODSRV`, value: values });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {industries.some(x => x === 'GASSTATION') &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='additionalInformation'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id={`Additional Information (Condition: GASSTATION industry)`} />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormChecklist
                            fieldCode='additionalInformationAcordSelectedItems_GASSTATION'
                            label={<FormattedMessage id={`Check all that apply`} />}
                            value={this.props.policy.employersWcCustomInfo.additionalInformation?.additionalInformationAcordSelectedItems_GASSTATION}
                            references={this.props.reference?.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`][`GASSTATION`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.AcordQuestions.GASSTATION`)}
                            changeEvent={(values) => {
                                this.props.doReducePolicyElement({ code: `employersWcCustomInfo.additionalInformation.additionalInformationAcordSelectedItems_GASSTATION`, value: values });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {industries.some(x => x === 'JANITORIAL') &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='additionalInformation'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id={`Additional Information (Condition: JANITORIAL industry)`} />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormChecklist
                            fieldCode='additionalInformationAcordSelectedItems_JANITORIAL'
                            label={<FormattedMessage id={`Check all that apply`} />}
                            value={this.props.policy.employersWcCustomInfo.additionalInformation?.additionalInformationAcordSelectedItems_JANITORIAL}
                            references={this.props.reference?.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`][`JANITORIAL`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.AcordQuestions.JANITORIAL`)}
                            changeEvent={(values) => {
                                this.props.doReducePolicyElement({ code: `employersWcCustomInfo.additionalInformation.additionalInformationAcordSelectedItems_JANITORIAL`, value: values });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {industries.some(x => x === 'LANDSCAPE') &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='additionalInformation'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id={`Additional Information (Condition: LANDSCAPE industry)`} />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormChecklist
                            fieldCode='additionalInformationAcordSelectedItems_LANDSCAPE'
                            label={<FormattedMessage id={`Check all that apply`} />}
                            value={this.props.policy.employersWcCustomInfo.additionalInformation?.additionalInformationAcordSelectedItems_LANDSCAPE}
                            references={this.props.reference?.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`][`LANDSCAPE`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.AcordQuestions.LANDSCAPE`)}
                            changeEvent={(values) => {
                                this.props.doReducePolicyElement({ code: `employersWcCustomInfo.additionalInformation.additionalInformationAcordSelectedItems_LANDSCAPE`, value: values });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {industries.some(x => x === 'MEDSRV') &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='additionalInformation'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id={`Additional Information (Condition: MEDSRV industry)`} />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormChecklist
                            fieldCode='additionalInformationAcordSelectedItems_MEDSRV'
                            label={<FormattedMessage id={`Check all that apply`} />}
                            value={this.props.policy.employersWcCustomInfo.additionalInformation?.additionalInformationAcordSelectedItems_MEDSRV}
                            references={this.props.reference?.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`][`MEDSRV`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.AcordQuestions.MEDSRV`)}
                            changeEvent={(values) => {
                                this.props.doReducePolicyElement({ code: `employersWcCustomInfo.additionalInformation.additionalInformationAcordSelectedItems_MEDSRV`, value: values });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {industries.some(x => x === 'PAINT') &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='additionalInformation'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id={`Additional Information (Condition: PAINT industry)`} />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormChecklist
                            fieldCode='additionalInformationAcordSelectedItems_PAINT'
                            label={<FormattedMessage id={`Check all that apply`} />}
                            value={this.props.policy.employersWcCustomInfo.additionalInformation?.additionalInformationAcordSelectedItems_PAINT}
                            references={this.props.reference?.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`][`PAINT`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.AcordQuestions.PAINT`)}
                            changeEvent={(values) => {
                                this.props.doReducePolicyElement({ code: `employersWcCustomInfo.additionalInformation.additionalInformationAcordSelectedItems_PAINT`, value: values });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {industries.some(x => x === 'PHOTO') &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='additionalInformation'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id={`Additional Information (Condition: PHOTO industry)`} />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormChecklist
                            fieldCode='additionalInformationAcordSelectedItems_PHOTO'
                            label={<FormattedMessage id={`Check all that apply`} />}
                            value={this.props.policy.employersWcCustomInfo.additionalInformation?.additionalInformationAcordSelectedItems_PHOTO}
                            references={this.props.reference?.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`][`PHOTO`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.AcordQuestions.PHOTO`)}
                            changeEvent={(values) => {
                                this.props.doReducePolicyElement({ code: `employersWcCustomInfo.additionalInformation.additionalInformationAcordSelectedItems_PHOTO`, value: values });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {industries.some(x => x === 'PLUMBING') &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='additionalInformation'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id={`Additional Information (Condition: PLUMBING industry)`} />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormChecklist
                            fieldCode='additionalInformationAcordSelectedItems_PLUMBING'
                            label={<FormattedMessage id={`Check all that apply`} />}
                            value={this.props.policy.employersWcCustomInfo.additionalInformation?.additionalInformationAcordSelectedItems_PLUMBING}
                            references={this.props.reference?.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`][`PLUMBING`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.AcordQuestions.PLUMBING`)}
                            changeEvent={(values) => {
                                this.props.doReducePolicyElement({ code: `employersWcCustomInfo.additionalInformation.additionalInformationAcordSelectedItems_PLUMBING`, value: values });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {industries.some(x => x === 'PROPMGMT') &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='additionalInformation'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id={`Additional Information (Condition: PROPMGMT industry)`} />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormChecklist
                            fieldCode='additionalInformationAcordSelectedItems_PROPMGMT'
                            label={<FormattedMessage id={`Check all that apply`} />}
                            value={this.props.policy.employersWcCustomInfo.additionalInformation?.additionalInformationAcordSelectedItems_PROPMGMT}
                            references={this.props.reference?.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`][`PROPMGMT`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.AcordQuestions.PROPMGMT`)}
                            changeEvent={(values) => {
                                this.props.doReducePolicyElement({ code: `employersWcCustomInfo.additionalInformation.additionalInformationAcordSelectedItems_PROPMGMT`, value: values });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {industries.some(x => x === 'RETAIL') &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='additionalInformation'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id={`Additional Information (Condition: RETAIL industry)`} />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormChecklist
                            fieldCode='additionalInformationAcordSelectedItems_RETAIL'
                            label={<FormattedMessage id={`Check all that apply`} />}
                            value={this.props.policy.employersWcCustomInfo.additionalInformation?.additionalInformationAcordSelectedItems_RETAIL}
                            references={this.props.reference?.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`][`RETAIL`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.AcordQuestions.RETAIL`)}
                            changeEvent={(values) => {
                                this.props.doReducePolicyElement({ code: `employersWcCustomInfo.additionalInformation.additionalInformationAcordSelectedItems_RETAIL`, value: values });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {industries.some(x => x === 'SCHOOLS') &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='additionalInformation'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id={`Additional Information (Condition: SCHOOLS industry)`} />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormChecklist
                            fieldCode='additionalInformationAcordSelectedItems_SCHOOLS'
                            label={<FormattedMessage id={`Check all that apply`} />}
                            value={this.props.policy.employersWcCustomInfo.additionalInformation?.additionalInformationAcordSelectedItems_SCHOOLS}
                            references={this.props.reference?.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`][`SCHOOLS`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.AcordQuestions.SCHOOLS`)}
                            changeEvent={(values) => {
                                this.props.doReducePolicyElement({ code: `employersWcCustomInfo.additionalInformation.additionalInformationAcordSelectedItems_SCHOOLS`, value: values });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {industries.some(x => x === 'WINERY') &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='additionalInformation'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id={`Additional Information (Condition: WINERY industry)`} />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormChecklist
                            fieldCode='additionalInformationAcordSelectedItems_WINERY'
                            label={<FormattedMessage id={`Check all that apply`} />}
                            value={this.props.policy.employersWcCustomInfo.additionalInformation?.additionalInformationAcordSelectedItems_WINERY}
                            references={this.props.reference?.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`][`WINERY`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.AcordQuestions.WINERY`)}
                            changeEvent={(values) => {
                                this.props.doReducePolicyElement({ code: `employersWcCustomInfo.additionalInformation.additionalInformationAcordSelectedItems_WINERY`, value: values });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {industries.some(x => x === 'WOODMFG') &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='additionalInformation'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id={`Additional Information (Condition: WOODMFG industry)`} />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormChecklist
                            fieldCode='additionalInformationAcordSelectedItems_WOODMFG'
                            label={<FormattedMessage id={`Check all that apply`} />}
                            value={this.props.policy.employersWcCustomInfo.additionalInformation?.additionalInformationAcordSelectedItems_WOODMFG}
                            references={this.props.reference?.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`][`WOODMFG`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.AcordQuestions.WOODMFG`)}
                            changeEvent={(values) => {
                                this.props.doReducePolicyElement({ code: `employersWcCustomInfo.additionalInformation.additionalInformationAcordSelectedItems_WOODMFG`, value: values });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {states.some(x => x === 'TX') &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='additionalInformation'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id={`Additional Information (Condition: TX state)`} />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormChecklist
                            fieldCode='additionalInformationAcordSelectedItems_STATE_TX'
                            label={<FormattedMessage id={`Check all that apply`} />}
                            value={this.props.policy.employersWcCustomInfo.additionalInformation?.additionalInformationAcordSelectedItems_STATE_TX}
                            references={this.props.reference?.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`] && this.props.reference.references[`EMPLOYERS-WC`][`AcordQuestions`][`STATE_TX`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.AcordQuestions.STATE_TX`)}
                            changeEvent={(values) => {
                                this.props.doReducePolicyElement({ code: `employersWcCustomInfo.additionalInformation.additionalInformationAcordSelectedItems_STATE_TX`, value: values });
                            }}
                            allowEdit
                        />
                    </Form>
                }

            </React.Fragment>
        );
    }
}

export default ProfileEmployersWcAdditionalInformation;