import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Alignments,
    Form,
    FormDropDown,
    FormTextArea,
    FormRadio,
    FormInput,
    Label,
    convertFromBoolean,
    convertToBoolean
} from 'metrix-common';

class ProfileFluxGlCannabisExtraction extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            <React.Fragment>
                <Form
                    header={<FormattedMessage id={`Cannabis extraction operations questions (Solvent, CO2, etc.)`} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormDropDown
                    label={<FormattedMessage id={`Type of extraction method utilized by the insured:`} />}
                    value={{ code: this.props.policy.fluxCustomInfo.cannabisExtraction?.extractionMethodCode, value: this.props.policy.fluxCustomInfo.cannabisExtraction?.extractionMethodName }}
                    references={this.props.reference?.references[`FLUX-GL`] && this.props.reference.references[`FLUX-GL`][`OperationQuestions`]}
                    referencesEvent={() => this.props.reference.doGetReferences(`FLUX-GL.OperationQuestions`)}
                    changeEvent={(value) => {
                        this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.extractionMethodCode`, value: value.code });
                        this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.extractionMethodName`, value: value.value });
                    }}
                    allowEdit
                    />
                    {this.props.policy.fluxCustomInfo.cannabisExtraction?.extractionMethodCode === 'OTHER' &&
                        <Label bold text={<FormattedMessage id={`Please specify method of extraction`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.fluxCustomInfo.cannabisExtraction?.extractionMethodCode === 'OTHER' &&
                        <FormTextArea
                            text={this.props.policy.fluxCustomInfo.cannabisExtraction?.otherMethodOfExtraction}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.otherMethodOfExtraction`, value })}
                        />
                    }
                    <Label bold text={<FormattedMessage id={`Additional Information/Notes on the applicant's extraction process:`} />} textAlign={Alignments.left} />
                    <FormTextArea
                        text={this.props.policy.fluxCustomInfo.cannabisExtraction?.extractionAdditionalInformation}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.extractionAdditionalInformation`, value })}
                    />
                </Form>
                { this.props.policy.fluxCustomInfo.cannabisExtraction?.extractionMethodCode === 'HYDR' &&
                    <Form
                        header={<FormattedMessage id={`Questions for hydrocarbon or other flammable/combustible solvent extraction`} />}
                        label={`Details`}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <Label bold text={<FormattedMessage id={`Please specify solvents used:`} />} textAlign={Alignments.left} />
                        <FormTextArea
                            text={this.props.policy.fluxCustomInfo.cannabisExtraction?.hydrocarbonAdditionalInformation}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.hydrocarbonAdditionalInformation`, value })}
                        />
                        <FormRadio
                            label={<FormattedMessage id={`Does the insured use a closed loop system?`} />}
                            value={this.props.policy.fluxCustomInfo.cannabisExtraction?.hasClosedLoopSystem !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.hasClosedLoopSystem) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.hasClosedLoopSystem`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                        <FormRadio
                            label={<FormattedMessage id={`Are all employees that use extraction equipment thoroughly trained?`} />}
                            value={this.props.policy.fluxCustomInfo.cannabisExtraction?.areAllEmployeesThoroughlyTrained_hydro !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.areAllEmployeesThoroughlyTrained_hydro) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.areAllEmployeesThoroughlyTrained_hydro`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                        <FormRadio
                            label={<FormattedMessage id={`Are Standard Operating Procedures in place for operation of all extraction equipment? (Please attach a copy of SOP's)`} />}
                            value={this.props.policy.fluxCustomInfo.cannabisExtraction?.areSOPInPlaceOfOperation_hydro !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.areSOPInPlaceOfOperation_hydro) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.areSOPInPlaceOfOperation_hydro`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                        <FormRadio
                            label={<FormattedMessage id={`Is all extraction equipment under a routine maintenance program?`} />}
                            value={this.props.policy.fluxCustomInfo.cannabisExtraction?.isAllExtractionEquipmentUnderMaintenanceProgram_hydro !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.isAllExtractionEquipmentUnderMaintenanceProgram_hydro) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.isAllExtractionEquipmentUnderMaintenanceProgram_hydro`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                        <FormRadio
                            label={<FormattedMessage id={`Are extraction operations conducted in a dedicated room?`} />}
                            value={this.props.policy.fluxCustomInfo.cannabisExtraction?.areOperationsConductedInDedicatedRoom !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.areOperationsConductedInDedicatedRoom) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.areOperationsConductedInDedicatedRoom`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                        <FormRadio
                            label={<FormattedMessage id={`Is a ventilation system in place within the extraction area?`} />}
                            value={this.props.policy.fluxCustomInfo.cannabisExtraction?.isVentilationSystemInPlace !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.isVentilationSystemInPlace) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.isVentilationSystemInPlace`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                        <FormRadio
                            label={<FormattedMessage id={`Is there a gas detection system installed in the extraction area?`} />}
                            value={this.props.policy.fluxCustomInfo.cannabisExtraction?.isGasDetectionSystemInstalled !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.isGasDetectionSystemInstalled) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.isGasDetectionSystemInstalled`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                        <FormRadio
                            label={<FormattedMessage id={`Is the lab or extraction area sprinklered, or does it have a form of fire suppression system installed?`} />}
                            value={this.props.policy.fluxCustomInfo.cannabisExtraction?.isLabHasSuppressionSystem !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.isLabHasSuppressionSystem) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.isLabHasSuppressionSystem`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                        <FormRadio
                            label={<FormattedMessage id={`Are all flammable liquids stored in a UL approved container?`} />}
                            value={this.props.policy.fluxCustomInfo.cannabisExtraction?.useULApprovedContainer !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.useULApprovedContainer) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.useULApprovedContainer`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                        <FormRadio
                            label={<FormattedMessage id={`Is all equipment used according to manufacturer specifications?`} />}
                            value={this.props.policy.fluxCustomInfo.cannabisExtraction?.isEquipmentUsedAccordingly_hydro !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.isEquipmentUsedAccordingly_hydro) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.isEquipmentUsedAccordingly_hydro`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                        <FormRadio
                            label={<FormattedMessage id={`Has the applicant made any modifications to the equipment beyond what the manufacturer intended?`} />}
                            value={this.props.policy.fluxCustomInfo.cannabisExtraction?.hasBeyondManufacturerModifications_hydro !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.hasBeyondManufacturerModifications_hydro) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.hasBeyondManufacturerModifications_hydro`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                        <FormRadio
                            label={<FormattedMessage id={`Are any hand tools able to be used while extraction is underway?`} />}
                            value={this.props.policy.fluxCustomInfo.cannabisExtraction?.useHandTools !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.useHandTools) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.useHandTools`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                        <FormRadio
                            label={<FormattedMessage id={`Are all electronics (including cell phones) prohibited from the extraction area (unless certified as intrinsically safe)?`} />}
                            value={this.props.policy.fluxCustomInfo.cannabisExtraction?.isElectronicsProhibitedInExtractionArea !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.isElectronicsProhibitedInExtractionArea) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.isElectronicsProhibitedInExtractionArea`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                        <FormRadio
                            label={<FormattedMessage id={`Is extraction equipment in a room with any equipment that utilizes a pilot light? (water heaters, area heaters, stoves, furnaces, etc.)`} />}
                            value={this.props.policy.fluxCustomInfo.cannabisExtraction?.hasPilotLightWithExtractionEquipmentInRoom !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.hasPilotLightWithExtractionEquipmentInRoom) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.hasPilotLightWithExtractionEquipmentInRoom`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                    </Form>
                }
                {this.props.policy.fluxCustomInfo.cannabisExtraction?.extractionMethodCode === 'CARB' &&
                    <Form
                        header={<FormattedMessage id={`Questions for CO2 extraction`} />}
                        label={`Details`}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormRadio
                            label={<FormattedMessage id={`Are CO2 compressed gas cylinders secured to a fixed object to prevent falling?`} />}
                            value={this.props.policy.fluxCustomInfo.cannabisExtraction?.areCO2CylindersSecured !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.areCO2CylindersSecured) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.areCO2CylindersSecured`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                        <FormRadio
                            label={<FormattedMessage id={`Are pressure relief devices and blow-off valves piped to exterior of building?`} />}
                            value={this.props.policy.fluxCustomInfo.cannabisExtraction?.hasPressureReliefDevicesPipedInExteriorOfBuilding !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.hasPressureReliefDevicesPipedInExteriorOfBuilding) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.hasPressureReliefDevicesPipedInExteriorOfBuilding`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                        <FormRadio
                            label={<FormattedMessage id={`Is the extraction equipment installed with adequate clear space from any combustible materials?`} />}
                            value={this.props.policy.fluxCustomInfo.cannabisExtraction?.isEquipmentInstalledSafely !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.isEquipmentInstalledSafely) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.isEquipmentInstalledSafely`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                        <FormRadio
                            label={<FormattedMessage id={`Area all employees that use extraction equipment thoroughly trained?`} />}
                            value={this.props.policy.fluxCustomInfo.cannabisExtraction?.areAllEmployeesThoroughlyTrained_co2 !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.areAllEmployeesThoroughlyTrained_co2) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.areAllEmployeesThoroughlyTrained_co2`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                        <FormRadio
                            label={<FormattedMessage id={`Are Standard Operating Procedures in place for operation of all extraction equipment? (Please attach a copy of SOP's)`} />}
                            value={this.props.policy.fluxCustomInfo.cannabisExtraction?.areSOPInPlaceOfOperation_co2 !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.areSOPInPlaceOfOperation_co2) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.areSOPInPlaceOfOperation_co2`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                        <FormRadio
                            label={<FormattedMessage id={`Is all extraction equipment under a routine maintenance program?`} />}
                            value={this.props.policy.fluxCustomInfo.cannabisExtraction?.isAllExtractionEquipmentUnderMaintenanceProgram_co2 !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.isAllExtractionEquipmentUnderMaintenanceProgram_co2) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.isAllExtractionEquipmentUnderMaintenanceProgram_co2`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                        <FormRadio
                            label={<FormattedMessage id={`Is an approved, listed CO2 detector installed in the extraction room?`} />}
                            value={this.props.policy.fluxCustomInfo.cannabisExtraction?.isCO2DetectorInstalled !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.isCO2DetectorInstalled) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.isCO2DetectorInstalled`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                        <FormRadio
                            label={<FormattedMessage id={`Is all equipment used according to manufacturer specifications?`} />}
                            value={this.props.policy.fluxCustomInfo.cannabisExtraction?.isEquipmentUsedAccordingly_co2 !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.isEquipmentUsedAccordingly_co2) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.isEquipmentUsedAccordingly_co2`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                        <FormRadio
                            label={<FormattedMessage id={`Has the applicant made any modifications to the equipment beyond what the manufacturer intended?`} />}
                            value={this.props.policy.fluxCustomInfo.cannabisExtraction?.hasBeyondManufacturerModifications_co2 !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.hasBeyondManufacturerModifications_co2) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.hasBeyondManufacturerModifications_co2`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                    </Form>
                }
                <Form
                    header={<FormattedMessage id={`Training, Extraction Equipment and Certification Info.`} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    {   (this.props.policy.fluxCustomInfo.cannabisExtraction?.areAllEmployeesThoroughlyTrained_hydro === true || this.props.policy.fluxCustomInfo.cannabisExtraction?.areAllEmployeesThoroughlyTrained_co2 == true) &&
                        <FormRadio
                            label={<FormattedMessage id={`Is there any ongoing training provided?`} />}
                            value={this.props.policy.fluxCustomInfo.cannabisExtraction?.ongoingTrainingProvided !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.ongoingTrainingProvided) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.ongoingTrainingProvided`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                    }
                    {   (this.props.policy.fluxCustomInfo.cannabisExtraction?.areAllEmployeesThoroughlyTrained_hydro === true || this.props.policy.fluxCustomInfo.cannabisExtraction?.areAllEmployeesThoroughlyTrained_co2 == true) && this.props.policy.fluxCustomInfo.cannabisExtraction?.ongoingTrainingProvided === true &&
                        <FormInput
                            label={`How often are training provided?`}
                            value={this.props.policy.fluxCustomInfo.cannabisExtraction?.trainingFrequency && this.props.policy.fluxCustomInfo.cannabisExtraction.trainingFrequency}
                            type='text'
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.trainingFrequency`, value })}
                            allowEdit
                        />
                    }
                    <FormRadio
                        label={<FormattedMessage id={`Will your equipment be used or rented to others who are not the named applicant?`} />}
                        value={this.props.policy.fluxCustomInfo.cannabisExtraction?.equipmentIsRentedOut !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.equipmentIsRentedOut) }}
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.equipmentIsRentedOut`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.fluxCustomInfo.cannabisExtraction?.equipmentIsRentedOut === true &&
                        <FormRadio
                            label={<FormattedMessage id={`Will you require them to carry insurance and name you on their policy?`} />}
                        value={this.props.policy.fluxCustomInfo.cannabisExtraction?.equipmentRenterIsInsured !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.equipmentRenterIsInsured) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.equipmentRenterIsInsured`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                    }
                    <FormRadio
                        label={<FormattedMessage id={`Was extraction equipment installed by the manufacturer?`} />}
                        value={this.props.policy.fluxCustomInfo.cannabisExtraction?.equipmentInstalledByManufacturer !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.equipmentInstalledByManufacturer) }}
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.equipmentInstalledByManufacturer`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={`Is your extracting equipment UL certified?`} />}
                        value={this.props.policy.fluxCustomInfo.cannabisExtraction?.equipmentUlCertified !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.equipmentUlCertified) }}
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.equipmentUlCertified`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={`Is your extraction equipment installed in a Class 1 Division 1 (C1D1) or Class 1 Division 2 (C1D2) room as defined by NFPA 70`} />}
                        value={this.props.policy.fluxCustomInfo.cannabisExtraction?.isNfpa70Compliant !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.isNfpa70Compliant) }}
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.isNfpa70Compliant`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={`Is your extraction equipment commercially manufactured?`} />}
                        value={this.props.policy.fluxCustomInfo.cannabisExtraction?.equipmentCommerciallyManufactured !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.equipmentCommerciallyManufactured) }}
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.equipmentCommerciallyManufactured`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.fluxCustomInfo.cannabisExtraction?.equipmentCommerciallyManufactured === true &&
                        <Label bold text={<FormattedMessage id={`Please provide name of manufacturer/model number:`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.fluxCustomInfo.cannabisExtraction?.equipmentCommerciallyManufactured === true &&
                        <FormTextArea
                        text={this.props.policy.fluxCustomInfo.cannabisExtraction?.equipmentManufacturerAndModel}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.equipmentManufacturerAndModel`, value })}
                        />
                    }

                    <FormRadio
                        label={<FormattedMessage id={`Is your facility operating in accordance with NFPA 1, Chapter 38?`} />}
                        value={this.props.policy.fluxCustomInfo.cannabisExtraction?.isNfpa1Chapter38Compliant !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.isNfpa1Chapter38Compliant) }}
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.isNfpa1Chapter38Compliant`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={`Does your your facility have ASME (American Society of Mechanical Engineers) accreditation?`} />}
                        value={this.props.policy.fluxCustomInfo.cannabisExtraction?.hasAsmeAccreditation !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.hasAsmeAccreditation) }}
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.hasAsmeAccreditation`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={`Does your facility have EPR (Extended Producer's Responsibility) certification?`} />}
                        value={this.props.policy.fluxCustomInfo.cannabisExtraction?.hasEprCerfification !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cannabisExtraction.hasEprCerfification) }}
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisExtraction.hasEprCerfification`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                </Form>
            </React.Fragment>
        );
    }
}

export default ProfileFluxGlCannabisExtraction;