import { all, takeLatest, fork } from 'redux-saga/effects';

import {
    CREATE_PAYMENT_TRANSFER_RESULT_RECORD,
    GET_PAYMENT_EMAIL, GET_PAYMENT_TRANSFER_RESULT_RECORDS,
    SEND_PAYMENT_LINK
} from '../../constants/actions/payment';

import { sendPaymentLinkSaga } from './sendPaymentLinkSaga';
import { getPaymentEmailSaga } from './getPaymentEmailSaga';
import { getPaymentTransferResultRecordsSaga } from './getPaymentTransferResultRecordsSaga';
import { createPaymentTransferResultRecordSaga } from './createPaymentTransferResultRecordSaga';

function* watchSendPaymentLink() {
    yield takeLatest(SEND_PAYMENT_LINK, sendPaymentLinkSaga);
}

function* watchGetPaymentEmail() {
	yield takeLatest(GET_PAYMENT_EMAIL, getPaymentEmailSaga);
}

function* watchGetPaymentTransferResultRecords() {
    yield takeLatest(GET_PAYMENT_TRANSFER_RESULT_RECORDS, getPaymentTransferResultRecordsSaga);
}

function* watchCreatePaymentTransferResultRecord() {
    yield takeLatest(CREATE_PAYMENT_TRANSFER_RESULT_RECORD, createPaymentTransferResultRecordSaga);
}

function* root() {
    return yield all([
        fork(watchSendPaymentLink),
        fork(watchGetPaymentEmail),
        fork(watchGetPaymentTransferResultRecords),
        fork(watchCreatePaymentTransferResultRecord)
    ]);
}

export default root;