import { applyMiddleware, combineReducers, compose, createStore } from 'redux';

import createSagaMiddleware from 'redux-saga';

import {
    contextReducer,
    referenceReducer,
    documentReducer,    
} from 'metrix-common';

import policyReducer from '../reducers/policy';
import policiesReducer from '../reducers/policies';
import productReducer from '../reducers/product';
import paymentReducer from '../reducers/payment';

export default function configureStore(initialState = {}) {

    const reducers = {
        context: contextReducer,
        reference: referenceReducer,
        policy: policyReducer,
        policies: policiesReducer,
        product: productReducer,
        payment: paymentReducer,
        document: documentReducer        
    };

    const sagaMiddleware = createSagaMiddleware();

    const middleware = [
        sagaMiddleware
    ];

    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
        enhancers.push(window.devToolsExtension());
    }

    const rootReducer = combineReducers({
        ...reducers
    });

    const store = createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );

    store.injectedReducers = {};
    store.runSaga = sagaMiddleware.run;
    store.injectedSagas = {};

    return store;
}
