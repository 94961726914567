import { put, call, select } from 'redux-saga/effects';
import { reduceOperation, reduceLoading, getSessionSaga } from 'metrix-common';
import { reduceListPolicies } from '../../actions/policies/reduceListPolicies';
import { selectQuery } from '../../selectors/policies/selectQuery';

export function* getListPoliciesSaga() {
    try {
        yield put(reduceLoading({ code: 'getListPolicies', value: true }));
        const session = yield getSessionSaga();
        const query = yield select(selectQuery());

        const response = yield call(fetch, '/api/policyTransaction/search', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Authorization': session.authenticationToken.token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ policyQuery: query.list })
        });

        const result = yield call([response, 'json']);

        yield put(reduceOperation(result.operation));
        if (!result.operation.status) return;
        
        yield put(reduceListPolicies(result.searchResult));
    }
    catch (error) {
        console.log('Error while calling Get List Policies API - ', error);
    }
    finally {
        yield put(reduceLoading({ code: 'getListPolicies', value: false }));
    }
}
