import { put, call, select } from 'redux-saga/effects';
import { reduceOperation, reduceLoading, getSessionSaga } from 'metrix-common';
import { reducePaymentEmail } from '../../actions/payment/reducePaymentEmail';
import { selectEntity } from '../../selectors/policy/selectEntity';

export function* getPaymentEmailSaga() {
    try {
        yield put(reduceLoading({ code: 'getPaymentEmail', value: true }));
        const session = yield getSessionSaga();
        const entity = yield select(selectEntity());

        const response = yield call(fetch, `/api/payment/contactemailaddress/${entity}`, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Authorization': session.authenticationToken.token,
                'Content-Type': 'application/json'
            }
        });
        
        const result = yield call([response, 'json']);

	    if (result.operation.status) yield put(reducePaymentEmail(result.email));
        
        yield put(reduceOperation(result.operation));
    }
    catch (error) {
        console.log('Error while calling Payment Email API - ', error);
    }
    finally {
        yield put(reduceLoading({ code: 'getPaymentEmail', value: false }));
    }
}
