import React from 'react';
import PropTypes from 'prop-types';

import {
    Alignments,
    Grid,
    Row,
    Column,
    Container,
    Label,
    Separator, getScreenSize, isMobile, isTablet, isDesktop, Colors, Sizes
} from 'metrix-common';

import PolicyListItem from './PolicyListItem';
import styled from 'styled-components';

const LabelItem = styled(Label)`
    line-height: 1.5rem;
    font-size: .75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const AZSortIcon = ({ $isActive }) => (
    <svg xmlns='http://www.w3.org/2000/svg'
         width='16'
         height='16'
         cursor='pointer'
    >
        <path fill={$isActive ? Colors.Primary : Colors.StrokeVeryDark}
              d='m0 12 3-8h1.72l3 8H6.08l-.68-1.92H2.32L1.68 12H0Zm2.8-3.28h2.08l-.96-3H3.8l-1 3ZM9.36 12v-1.52l4.04-5.04H9.52V4h5.64v1.52l-4 5.04h4.04V12H9.36ZM5.6 2.4 8 0l2.4 2.4H5.6ZM8 16l-2.4-2.4h4.8L8 16Z'
        />
    </svg>

);

const SortIcon = ({ $isActive, isDesc }) => (
    <svg xmlns='http://www.w3.org/2000/svg'
         width='16'
         height='16'
         viewBox='0 0 16 16'
         cursor='pointer'
    >
        <path fillRule='evenodd'
              clipRule='evenodd'
              d='M3 4H1L5 0L9 4H7V9H3V4Z'
              fill={$isActive && !isDesc ? Colors.Primary : Colors.StrokeVeryDark}
        />
        <path fillRule='evenodd'
              clipRule='evenodd'
              d='M13 12L15 12L11 16L7 12L9 12L9 7L13 7L13 12Z'
              fill={$isActive && isDesc ? Colors.Primary : Colors.StrokeVeryDark}
        />
    </svg>
);

const Results = ({ listPolicies, history, context, query, doReduceQueryElement, doGetListPolicies }) => {
    const { list } = query;
    const mobile = isMobile(getScreenSize());
    const tablet = isTablet(getScreenSize());
    const desktop = isDesktop(getScreenSize());

    const sortBy = (list.orderByFieldNames && list.orderByFieldNames[0]) || 'creationDate';
    const orderByDesc = list.orderByDesc;

    const sortHandler = (columnName) => {
        if (sortBy === columnName) {
            doReduceQueryElement({ code: 'orderByDesc', value: !orderByDesc });
        } else {
            doReduceQueryElement({ code: 'orderByFieldNames', value: [columnName] });
            doReduceQueryElement({ code: 'orderByDesc', value: true });
        }
        doGetListPolicies();
    }

    return (
        <Container>
            <Grid>
                <Row>
                    {
                        !mobile &&
                        <Column md={tablet ? 2 : 1}>
                            <Label text='Reference Number' textAlign={Alignments.left} />
                        </Column>
                    }
                    {
                        desktop &&
                        <Column md={1}>
                            <Container clickEvent={() => sortHandler('policyNumber')}
                                       verticalAlignment={Alignments.center}
                                       inline
                            >
                                <LabelItem text={'Policy Number'}
                                           textAlign={Alignments.left}
                                           right={Sizes.xs}
                                           color={sortBy === 'policyNumber' ? Colors.Primary : Colors.StrokeVeryDark}
                                           bold={sortBy === 'policyNumber'}
                                           clickable
                                />
                                <AZSortIcon $isActive={sortBy === 'policyNumber'}
                                          isDesc={orderByDesc}
                                />
                            </Container>
                        </Column>
                    }
                    {
                        desktop &&
                        <Column md={1}>
                            <Container clickEvent={() => sortHandler('quoteNumber')}
                                       verticalAlignment={Alignments.center}
                                       inline
                            >
                                <LabelItem text={'Quote Number'}
                                           textAlign={Alignments.left}
                                           right={Sizes.xs}
                                           color={sortBy === 'quoteNumber' ? Colors.Primary : Colors.StrokeVeryDark}
                                           bold={sortBy === 'quoteNumber'}
                                           clickable
                                />
                                <AZSortIcon $isActive={sortBy === 'quoteNumber'}
                                          isDesc={orderByDesc}
                                />
                            </Container>
                        </Column>
                    }
                    {
                        mobile ?
                        <Column xs={5} md={2}>
                            <Container clickEvent={() => sortHandler('doingBusinessAs')}
                                       verticalAlignment={Alignments.center}
                                       inline
                            >
                                <LabelItem text={'Doing Business As'}
                                           textAlign={Alignments.left}
                                           right={Sizes.xs}
                                           color={sortBy === 'doingBusinessAs' ? Colors.Primary : Colors.StrokeVeryDark}
                                           bold={sortBy === 'doingBusinessAs'}
                                           clickable
                                />
                                <AZSortIcon $isActive={sortBy === 'doingBusinessAs'}
                                            isDesc={orderByDesc}
                                />
                            </Container>
                        </Column>
                        :
                        <>
                            <Column md={tablet ? 2 : 1}>
                                <Container clickEvent={() => sortHandler('insuredName')}
                                           verticalAlignment={Alignments.center}
                                           inline
                                >
                                    <LabelItem text={'Insured Name'}
                                               textAlign={Alignments.left}
                                               right={Sizes.xs}
                                               color={sortBy === 'insuredName' ? Colors.Primary : Colors.StrokeVeryDark}
                                               bold={sortBy === 'insuredName'}
                                               clickable
                                    />
                                    <AZSortIcon $isActive={sortBy === 'insuredName'}
                                                isDesc={orderByDesc}
                                    />
                                </Container>
                            </Column>
                            <Column md={2}>
                                <Container clickEvent={() => sortHandler('doingBusinessAs')}
                                           verticalAlignment={Alignments.center}
                                           inline
                                >
                                    <LabelItem text={'Doing Business As'}
                                               textAlign={Alignments.left}
                                               right={Sizes.xs}
                                               color={sortBy === 'doingBusinessAs' ? Colors.Primary : Colors.StrokeVeryDark}
                                               bold={sortBy === 'doingBusinessAs'}
                                               clickable
                                    />
                                    <AZSortIcon $isActive={sortBy === 'doingBusinessAs'}
                                              isDesc={orderByDesc}
                                    />
                                </Container>
                            </Column>
                        </>
                    }
                    {
                        !mobile &&
                        <>
                            <Column md={tablet ? 2 : 1}>
                                <Label text='Product' textAlign={Alignments.left} />
                            </Column>
                            <Column md={1}>
                                <Container clickEvent={() => sortHandler('creationDate')}
                                           verticalAlignment={Alignments.center}
                                           inline
                                >
                                    <LabelItem text={'Creation Date'}
                                               textAlign={Alignments.left}
                                               right={Sizes.xs}
                                               color={sortBy === 'creationDate' ? Colors.Primary : Colors.StrokeVeryDark}
                                               bold={sortBy === 'creationDate'}
                                               clickable
                                    />
                                    <SortIcon $isActive={sortBy === 'creationDate'}
                                              isDesc={orderByDesc}
                                    />
                                </Container>
                            </Column>
                        </>
                    }
                    <Column xs={4} md={tablet ? 2 : 1}>
                        <Label text='Status' textAlign={Alignments.left} />
                    </Column>
                    {
                        desktop &&
                        <Column md={1}>
                            <Label text='Assigned To' textAlign={Alignments.left} />
                        </Column>
                    }
                    {
                        desktop &&
                        <Column md={1}>
                            <Container clickEvent={() => sortHandler('quoteColorTag')}
                                       verticalAlignment={Alignments.center}
                                       inline
                            >
                                <LabelItem text={'Quote Color Tag'}
                                           textAlign={Alignments.left}
                                           right={Sizes.xs}
                                           color={sortBy === 'quoteColorTag' ? Colors.Primary : Colors.StrokeVeryDark}
                                           bold={sortBy === 'quoteColorTag'}
                                           clickable
                                />
                                <AZSortIcon $isActive={sortBy === 'quoteColorTag'}
                                            isDesc={orderByDesc}
                                />
                            </Container>
                        </Column>
                    }
                    <Column xs={2} md={1} lg={1}>
                        <Label text='Type' textAlign={Alignments.left} />
                    </Column>
                </Row>
                <Row noguttertop>
                    <Column>
                        <Separator solid />
                    </Column>
                </Row>
            </Grid>
            <Grid>
                {
                    listPolicies &&
                    listPolicies.map((policy, index) => (
                        <PolicyListItem
                            key={index}
                            policy={policy}
                            history={history}
                            context={context}
                        />
                    ))
                }
            </Grid>
        </Container>
    );
};

Results.propTypes = {
    listPolicies: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired
};

export default Results;