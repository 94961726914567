import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Form,
    FormSwitch
} from 'metrix-common';

class ProfileEmployersWcDisclaimer extends React.Component {
    componentDidMount() {
        const pageHasTooltipInfo = this.props.tooltipInfo && this.props.tooltipInfo.pages && this.props.tooltipInfo.pages.length && this.props.tooltipInfo.pages.some(p => p.code === this.props.pageCode);
        if (!pageHasTooltipInfo) {
            this.props.doGetTooltipInfo(this.props.policy.product);
        }
    }

    render() {
        let isCurrentStateCA = this.props.policy.employersWcCustomInfo.businessInfo?.primaryRiskStateCode === 'CA';
        return (             
            <Form
                pageCode={this.props.pageCode}
                sectionCode='disclaimers'
                saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                tooltipInfo={this.props.tooltipInfo}
                validationInfo={this.props.validationInfo}
                header={<FormattedMessage id={'Disclaimers'} />}
                label={'Details'}
                saveEvent={this.props.doUpsertPolicy}
                cancelEvent={this.props.doGetPolicy}
                allowEdit={this.props.allowEdit}
            >
                <FormSwitch
                    fieldCode='isAgreeWithNBQI'
                    label={'The Estimated Annual Premium shown is informational only and is a preview of your estimated insurance cost. Please fully complete the application to receive a final quote, as pricing and/or risk eligibility may be subject to change.'}
                    value={this.props.policy.employersWcCustomInfo.disclaimer?.isAgreeWithNBQI ?? true}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: 'employersWcCustomInfo.disclaimer.isAgreeWithNBQI', value })}
                    allowEdit
                />
                <FormSwitch
                    fieldCode='isAgreeWithOOEA'
                    label={'I attest that the Insured (my client) has complied with the applicable workers compensation laws of the states shown in Item 3.A of the policy information page, and I will maintain and make available upon request all required documentation in the Agency file.'}
                    value={this.props.policy.employersWcCustomInfo.disclaimer?.isAgreeWithOOEA ?? true}
                    changeEvent={(value) => this.props.doReducePolicyElement({ code: 'employersWcCustomInfo.disclaimer.isAgreeWithOOEA', value })}
                    allowEdit
                />
                {/*{(isCurrentStateCA && this.props.policy.employersWcCustomInfo.businessInfo?.legalEntityTypeCode === 'CP'
                    && this.props.policy.employersWcCustomInfo.disclaimer?.isCertifyPrivateProfessionalCorporationDisclaimer_CA !== true
                    && this.props.policy.employersWcCustomInfo.disclaimer?.isCertifyCooperativeCorporationDisclaimer_CA !== true
                ) &&
                    <FormSwitch
                        fieldCode='isCertifyPrivateOrQuasiPublicCorporationDisclaimer_CA'
                        label={'Named Insured is a Private or Quasi-Public Corporation (I hereby certify that I am an officer or member of the board of directors of a quasi-public or private corporation who owns at least 10% of the issued and outstanding stock of the corporation, or at least 1% of the issued and outstanding stock of the corporation if that officer�s or member�s immediate family member (parent, grandparent, sibling, spouse, or child) owns at least 10% of the issued and outstanding stock of the corporation and that officer or member is covered by a health insurance policy or a health care service plan or, I as an individual own no stock but I am a trustee of a revocable trust that is a stockholder as the Named Insured. As a Trustee, I have the power to revoke the Trust shares.)'}
                        value={this.props.policy.employersWcCustomInfo.disclaimer?.isCertifyPrivateOrQuasiPublicCorporationDisclaimer_CA}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: 'employersWcCustomInfo.disclaimer.isCertifyPrivateOrQuasiPublicCorporationDisclaimer_CA', value })}
                        allowEdit
                    />
                }
                {(isCurrentStateCA && this.props.policy.employersWcCustomInfo.businessInfo?.legalEntityTypeCode === 'CP'
                    && this.props.policy.employersWcCustomInfo.disclaimer?.isCertifyPrivateOrQuasiPublicCorporationDisclaimer_CA !== true
                    && this.props.policy.employersWcCustomInfo.disclaimer?.isCertifyCooperativeCorporationDisclaimer_CA !== true
                ) &&
                    <FormSwitch
                        fieldCode='isCertifyPrivateProfessionalCorporationDisclaimer_CA'
                        label={'Named Insured is a Private Professional Corporation (I hereby certify that I am an owner of a professional corporation, as defined in Section 13401 of the California Corporations Code, who is a practitioner rendering the professional services for which the professional corporation is organized and who states that he or she is covered by a health insurance policy or a health care service plan.)'}
                        value={this.props.policy.employersWcCustomInfo.disclaimer?.isCertifyPrivateProfessionalCorporationDisclaimer_CA}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: 'employersWcCustomInfo.disclaimer.isCertifyPrivateProfessionalCorporationDisclaimer_CA', value })}
                        allowEdit
                    />
                }
                {(isCurrentStateCA && this.props.policy.employersWcCustomInfo.businessInfo?.legalEntityTypeCode === 'CP'
                    && this.props.policy.employersWcCustomInfo.disclaimer?.isCertifyPrivateOrQuasiPublicCorporationDisclaimer_CA !== true
                    && this.props.policy.employersWcCustomInfo.disclaimer?.isCertifyPrivateProfessionalCorporationDisclaimer_CA !== true
                ) &&
                    <FormSwitch
                        fieldCode='isCertifyCooperativeCorporationDisclaimer_CA'
                        label={'Named Insured is a Cooperative Corporation (I hereby certify that I am a working employee and an appointed Corporate Officer or Member of the Board of Directors, as defined in Sections 12200-12704 of the California Corporations Code and who states that he or she is covered by a health insurance policy or a health care service plan.)'}
                        value={this.props.policy.employersWcCustomInfo.disclaimer?.isCertifyCooperativeCorporationDisclaimer_CA}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: 'employersWcCustomInfo.disclaimer.isCertifyCooperativeCorporationDisclaimer_CA', value })}
                        allowEdit
                    />
                }
                {(isCurrentStateCA && this.props.policy.employersWcCustomInfo.businessInfo?.legalEntityTypeCode === 'LL') &&
                    <FormSwitch
                        fieldCode='isCertifyLimitedLiabilityCompanyDisclaimer_CA'
                        label={'Named Insured is a Limited Liability Company (I hereby certify that I am a managing member of a limited liability company who is a qualifying managing member or, I am an LLC Manager and the Trustee of a revocable trust that is a member. As Trustee I have the power to revoke the Trust�s interest.)'}
                        value={this.props.policy.employersWcCustomInfo.disclaimer?.isCertifyLimitedLiabilityCompanyDisclaimer_CA}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: 'employersWcCustomInfo.disclaimer.isCertifyLimitedLiabilityCompanyDisclaimer_CA', value })}
                        allowEdit
                    />
                }
                {(isCurrentStateCA && this.props.policy.employersWcCustomInfo.businessInfo?.legalEntityTypeCode === 'GP') &&
                    <FormSwitch
                        fieldCode='isCertifyPartnershipDisclaimer_CA'
                        label={'Named Insured is a Partnership (I hereby certify that I am a general partner of a partnership who is a qualifying general partner or, I am the Trustee of a revocable trust that is a partner. As Trustee I have the power to revoke the Trust�s interest.)'}
                        value={this.props.policy.employersWcCustomInfo.disclaimer?.isCertifyPartnershipDisclaimer_CA}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: 'employersWcCustomInfo.disclaimer.isCertifyPartnershipDisclaimer_CA', value })}
                        allowEdit
                    />
                }*/}
            </Form>
        );
    }
}

export default ProfileEmployersWcDisclaimer;