import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    BackButton,
    Form,
    FormDropDown,
    FormInput,
    FormSwitch
} from 'metrix-common';


class ProfileBtisWcOwner extends React.Component {
    state = {
        mode: `ownerView`
    };

    render() {
        return (
            <React.Fragment>
                {this.state.mode === `ownerView` &&
                    <React.Fragment>
                        <BackButton clickEvent={this.props.backEvent} />
                        <Form
                            header={<FormattedMessage id={`${this.props.owner.ownerTitleTypeName || this.props.owner.ownerPageName}`} />}
                            label={`Owner details`}
                            allowEdit={this.props.allowEdit}
                            saveEvent={() => this.props.doUpsertPolicy()}
                            cancelEvent={() => this.props.doGetPolicy()}
                        >
                            <FormInput
                                label={`First name`}
                                value={this.props.owner?.firstName}
                                type='text'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}firstName`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={`Last name`}
                                value={this.props.owner?.lastName}
                                type='text'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}lastName`, value })}
                                allowEdit
                            />
                            <FormDropDown
                                label={<FormattedMessage id={`Title`} />}
                                value={{ code: this.props.owner?.ownerTitleTypeCode, value: this.props.owner?.ownerTitleTypeName }}
                                references={this.props.reference?.references[`BTIS-WC`] && this.props.reference.references[`BTIS-WC`][`OwnerTitleTypes`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`BTIS-WC.OwnerTitleTypes`)}
                                changeEvent={(value) => {
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}ownerTitleTypeCode`, value: value.code });
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}ownerTitleTypeName`, value: value.value });
                                }}
                                allowEdit
                            />
                            <FormInput
                                label={`Ownership %`}
                                value={this.props.owner?.ownershipPercentageAmount}
                                type='number'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}ownershipPercentageAmount`, value })}
                                allowEdit
                            />
                            <FormSwitch
                                label={<FormattedMessage id={`Owner inclusion/exclusion`} />}
                                value={this.props.owner?.isOwnerIncluded}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}isOwnerIncluded`, value: value })}
                                allowEdit
                            />
                        </Form>
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default ProfileBtisWcOwner;