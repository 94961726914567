import dotProp from 'dot-prop-immutable';

import {
    REDUCE_PRODUCT
} from '../../constants/actions/product';

const initialState = {
    products: []
};

function productReducer(state = initialState, action) {
    switch (action.type) {
        case REDUCE_PRODUCT:
            return dotProp.set(state, 'products', action.payload);
        default:
            return state;
    }
}

export default productReducer;
