import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Alert,
    Alignments,
    Container,
    Divider,
    Form,
    FormSwitch,
    Header,
    Sizes,
    SummaryGroup,
    getPageValidationInfo
} from 'metrix-common';

import ProfileInterwestFp2AdditionalLocation from './Location';

class ProfileInterwestFp2AdditionalLocations extends React.Component {
    componentDidMount() {
        const pageHasTooltipInfo = this.props.tooltipInfo && this.props.tooltipInfo.pages && this.props.tooltipInfo.pages.length && this.props.tooltipInfo.pages.some(p => p.code === this.props.pageCode);
        if (!pageHasTooltipInfo) {
            this.props.doGetTooltipInfo(this.props.policy.product);
        }
    }
    
    state = {
        mode: 'locationsView',
        currentLocation: null,
        currentHasAdditionalLocations: null
    };

    handleSave = () => {

        let currentHasAdditionalLocations = this.state.currentHasAdditionalLocations;
        this.props.doReducePolicyElement({ code: 'interWestFP2AdditionalLocationsInfo.hasAdditionalLocations', value: currentHasAdditionalLocations });

        if (currentHasAdditionalLocations === true && this.props.policy?.interWestFP2Locations && this.props.policy.interWestFP2Locations.length === 0) {
            this.props.doExecutePolicyAction({ code: 'INTERWEST-FP2.CREATELOCATION' });
        }
        if (currentHasAdditionalLocations === false && this.props.policy?.interWestFP2Locations && this.props.policy.interWestFP2Locations.length > 0) {
            this.props.doExecutePolicyAction({ code: 'INTERWEST-FP2.DELETELOCATIONS' });
        }

        return this.props.doUpsertPolicy;
    }

    getLocations = () => {
        let locations = [];

        if (this.props.policy?.interWestFP2Locations && this.props.policy.interWestFP2Locations.length > 0) {
            this.props.policy.interWestFP2Locations.forEach((location) => {
                let option = { code: 'locationOption', value: location.guid };

                option.elements = [];
                option.elements.push({ code: `Label`, value: location.address?.formattedAddress ?? location.locationName });

                locations.push(option);
            });
        }

        return locations;
    }

    handleHasAdditionalLocations = (value) =>
    {
        this.setState({ currentHasAdditionalLocations: value });
        this.props.doReducePolicyElement({ code: 'interWestFP2AdditionalLocationsInfo.hasAdditionalLocations', value });
    }

    render() {
        const { error, warning } = getPageValidationInfo(this.props.pageCode, this.props.validationInfo);
        const locations = this.getLocations();
        return (
            <React.Fragment>
                {
                    (error.message.length !== 0 || warning.message.length !== 0) &&
                    <Container top={Sizes.md}>
                        {
                            error.message.length !== 0 &&
                            <Alert
                                mode='danger'
                                header={error.title}
                                items={error.message}
                            />
                        }
                        {
                            warning.message.length !== 0 &&
                            <Alert
                                mode='warning'
                                header={warning.title}
                                items={warning.message}
                            />
                        }
                    </Container>
                }
                {
                    this.state.mode === 'locationsView' &&
                    <React.Fragment>
                        <Form
                            pageCode={this.props.pageCode}
                            sectionCode='additionalLocationsInfo'
                            saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                            reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                            tooltipInfo={this.props.tooltipInfo}
                            validationInfo={this.props.validationInfo}
                            header={<FormattedMessage id={'Additional Locations Info'} />}
                            label={'Details'}
                            allowEdit={this.props.allowEdit}
                            saveEvent={() => this.handleSave()}
                            cancelEvent={() => this.props.doGetPolicy()}
                        >
                            <FormSwitch
                                fieldCode='hasAdditionalLocations'
                                label={<FormattedMessage id={'Do you have additional locations?'} />}
                                value={this.props.policy.interWestFP2AdditionalLocationsInfo?.hasAdditionalLocations ?? false}
                                changeEvent={(value) => this.handleHasAdditionalLocations(value)}
                                allowEdit
                            />
                        </Form>
                        <React.Fragment>
                            <Divider top={Sizes.xl} />
                            <Header size={Sizes.lg} textAlign={Alignments.left} text={'Locations'} />
                            <Container top={Sizes.xl} bottom={Sizes.xl}>
                                <SummaryGroup
                                    allowAdd={(this.props.policy.interWestFP2Locations === undefined || locations.length < 5) && this.props.policy.interWestFP2AdditionalLocationsInfo?.hasAdditionalLocations === true}
                                    addEvent={() => {
                                        this.props.doReduceParameter([]);
                                        this.props.doExecutePolicyAction({ code: 'INTERWEST-FP2.CREATELOCATION' });
                                    }}
                                    allowEdit
                                    editEvent=
                                    {(location) => {
                                        this.setState({ currentLocation: location, mode: 'locationView' });
                                    }}
                                    allowDelete={true}
                                    deleteEvent={(location) => {
                                        this.props.doReduceParameter({ code: 'LocationGuid', value: location });
                                        this.props.doExecutePolicyAction({ code: 'INTERWEST-FP2.DELETELOCATION' });
                                    }}
                                    entities={locations}
                                    icon={'home'}
                                />
                            </Container>
                        </React.Fragment>
                    </React.Fragment>
                }
                {
                    this.state.mode === 'locationView' &&
                    <ProfileInterwestFp2AdditionalLocation
                        rowId={this.state.currentLocation}
                        pageCode={this.props.pageCode}
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        location={this.props.policy.interWestFP2Locations.find(x => x.guid === this.state.currentLocation)}
                        dotProp={`interWestFP2Locations.${this.props.policy.interWestFP2Locations.findIndex(x => x.guid === this.state.currentLocation)}.`}
                        backEvent={() => this.setState({ mode: 'locationsView', currentLocation: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReduceParameter={this.props.doReduceParameter}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        doExecutePolicyAction={this.props.doExecutePolicyAction}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
            </React.Fragment>
        );
    }
}

export default ProfileInterwestFp2AdditionalLocations;