import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Alignments,
    Divider,
    Container,
    Form,
    FormInput,
    FormDropDown,
    Header,
    FormSwitch,
    Sizes,
    SummaryGroup,
    FormFullPhoneNumber,
} from 'metrix-common';

import ProfileBtisWcOwner from './Owner';

class ProfileBtisWcBusiness extends React.Component {
    state = {
        mode: `ownerSummaryView`,
        currentOwner: null
    };

    componentDidMount() {        
    }

    getOwners = () => {
        let owners = [];

        if (this.props.policy?.btisWcCustomInfo?.owners && this.props.policy.btisWcCustomInfo.owners.length > 0)
        {
            this.props.policy.btisWcCustomInfo.owners.forEach((owner) => {
                let option = { code: `ownerOption`, value: owner.guid };

                let ownerTitle = `${owner.ownerTitleTypeName || owner.ownerPageName}`;

                option.elements = [];
                option.elements.push({ code: `Label`, value: ownerTitle });

                owners.push(option);
            });
        }

        return owners;
    }

    render() {
        return (
            <React.Fragment>
                {this.state.mode === `ownerSummaryView` &&
                    <React.Fragment>
                        <Form
                            header={<FormattedMessage id={`Business Information`} />}
                            label={`Details`}
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            allowEdit={this.props.allowEdit}
                        >
                            <FormInput
                                label={`Business Name`}
                                value={this.props.policy.btisWcCustomInfo.businessInfo?.legalBusinessName}
                                type='text'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.businessInfo.legalBusinessName`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={`DBA (Doing business as)`}
                                value={this.props.policy.btisWcCustomInfo.businessInfo?.doingBusinessAs}
                                type='text'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.businessInfo.doingBusinessAs`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={`Website`}
                                value={this.props.policy.btisWcCustomInfo.businessInfo?.website}
                                type='text'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.businessInfo.website`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={`Describe your business`}
                                value={this.props.policy.btisWcCustomInfo.businessInfo?.businessDescription}
                                type='text'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.businessInfo.businessDescription`, value })}
                                allowEdit
                            />
                            <FormDropDown
                                label={<FormattedMessage id={`Structure of the Business`} />}
                                value={{ code: this.props.policy.btisWcCustomInfo.businessInfo?.businessStructureCode, value: this.props.policy.btisWcCustomInfo.businessInfo?.businessStructureName }}
                                references={this.props.reference?.references[`BTIS-WC`] && this.props.reference.references[`BTIS-WC`][`BusinessStructure`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`BTIS-WC.BusinessStructure`)}
                                changeEvent={(value) => {
                                    this.props.doReducePolicyElement({ code: `btisWcCustomInfo.businessInfo.businessStructureCode`, value: value.code });
                                    this.props.doReducePolicyElement({ code: `btisWcCustomInfo.businessInfo.businessStructureName`, value: value.value });
                                }}
                                allowEdit
                            />
                            <FormInput
                                label={`FEIN Number`}
                                value={this.props.policy.btisWcCustomInfo.businessInfo?.federalEmployerIdentificationNumber}
                                type='number'
                                changeEvent={value => this.props.doReducePolicyElement({ code: 'btisWcCustomInfo.businessInfo.federalEmployerIdentificationNumber', value })}
                                overrideMaxLength={9}
                                allowEdit
                            />
                            <FormInput
                                label={`Year the Business was Established`}
                                value={this.props.policy.btisWcCustomInfo.businessInfo?.businessYearEstablishedNumber}
                                type='number'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.businessInfo.businessYearEstablishedNumber`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={`Years of Industry Experience`}
                                value={this.props.policy.btisWcCustomInfo.businessInfo?.yearsOfIndustryExperienceCount}
                                type='number'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.businessInfo.yearsOfIndustryExperienceCount`, value })}
                                allowEdit
                            />
                            <FormSwitch
                                label={<FormattedMessage id={`Blanket Waiver of Subrogation?`} />}
                                value={this.props.policy.btisWcCustomInfo.businessInfo?.hasBlanketWaiverSubrogation}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.businessInfo.hasBlanketWaiverSubrogation`, value: value })}
                                allowEdit
                            />
                            <FormInput
                                label={`Contractor License`}
                                value={this.props.policy.btisWcCustomInfo.businessInfo?.contractorLicenseNumberCode}
                                type='text'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.businessInfo.contractorLicenseNumberCode`, value })}
                                allowEdit
                        />
                            {this.props.policy?.participants?.insured?.party?.address?.stateCode === "CA" &&
                                <FormInput
                                    label={`MCP65 Or DMV License (CA only)`}
                                    value={this.props.policy.btisWcCustomInfo.businessInfo?.mcP65OrDMVLicenseCode}
                                    type='text'
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.businessInfo.mcP65OrDMVLicenseCode`, value })}
                                    allowEdit
                                />
                            }
                            <FormInput
                                label={`Experience Modifier`}
                                value={this.props.policy.btisWcCustomInfo.businessInfo?.experienceModifier}
                                type='decimal'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.businessInfo.experienceModifier`, value })}
                                allowEdit
                            />
                        </Form>
                        <Form
                            header={<FormattedMessage id={`Contact Info`} />}
                            label={`Details`}
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            allowEdit={this.props.allowEdit}
                        >
                            <FormInput
                                label={`Contact First Name`}
                                value={this.props.policy.btisWcCustomInfo.businessInfo?.contactFirstName}
                                type='text'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.businessInfo.contactFirstName`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={`Contact Middle Name`}
                                value={this.props.policy.btisWcCustomInfo.businessInfo?.contactMiddleName}
                                type='text'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.businessInfo.contactMiddleName`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={`Contact Last Name`}
                                value={this.props.policy.btisWcCustomInfo.businessInfo?.contactLastName}
                                type='text'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.businessInfo.contactLastName`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={`Email`}
                                value={this.props.policy.btisWcCustomInfo.businessInfo?.contactEmail}
                                type='email'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.businessInfo.contactEmail`, value })}
                                allowEdit
                            />                            
                            <FormFullPhoneNumber
                                label={`Phone`}
                                value={this.props.policy.btisWcCustomInfo.businessInfo?.contactPhone}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.businessInfo.contactPhone`, value })}
                                allowEdit
                            />
                    </Form>

                    <Divider top={Sizes.xl} />
                    <Header size={Sizes.lg} textAlign={Alignments.left} text={`Owners`} />
                    <Container top={Sizes.xl} bottom={Sizes.xl}>
                        <SummaryGroup
                            allowAdd={this.props.allowEdit}
                            addEvent={() => {
                                this.props.doReduceParameters([]);
                                this.props.doExecutePolicyAction({ code: `BTIS-WC.CREATEOWNER` });
                            }}
                            allowEdit
                            editEvent={(owner) => {
                                this.setState({ currentOwner: owner, mode: `ownerView` });
                            }}
                            allowDelete={this.props.allowEdit}
                            deleteEvent={(owner) => {
                                this.props.doReduceParameter({ code: `OwnerGuid`, value: owner });
                                this.props.doExecutePolicyAction({ code: `BTIS-WC.DELETEOWNER` });
                            }}
                            entities={this.getOwners()}
                            icon={`person`}
                        />
                    </Container>
                    </React.Fragment>
                }
                {this.state.mode === `ownerView` &&
                    <ProfileBtisWcOwner owner={this.props.policy.btisWcCustomInfo.owners.find(x => x.guid === this.state.currentOwner)}
                        dotProp={`btisWcCustomInfo.owners.${this.props.policy.btisWcCustomInfo.owners.findIndex(x => x.guid === this.state.currentOwner)}.`}
                        backEvent={() => this.setState({ mode: `ownerSummaryView`, currentOwner: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReduceParameter={this.props.doReduceParameter}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        doExecutePolicyAction={this.props.doExecutePolicyAction}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
            </React.Fragment>
        );
    }
}

export default ProfileBtisWcBusiness;