import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Alignments,
    Form, FormDate,
    FormDropDown,
    FormInput, FormSwitch, Sizes,
    Container, SecondaryButton, getElementValueByCode
} from 'metrix-common';

class ProfileSecureMyOfferOfferInfo extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();

        const pageHasTooltipInfo = this.props.tooltipInfo && this.props.tooltipInfo.pages && this.props.tooltipInfo.pages.length && this.props.tooltipInfo.pages.some(p => p.code === this.props.pageCode);
        if (!pageHasTooltipInfo) {
            this.props.doGetTooltipInfo(this.props.policy.product);
        }
    }

    render() {
        return (
            <>
                <Form
                    pageCode={this.props.pageCode}
                    sectionCode='buyerOffer'
                    saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                    reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                    tooltipInfo={this.props.tooltipInfo}
                    validationInfo={this.props.validationInfo}
                    header={<FormattedMessage id='Buyer Offer' />}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormSwitch
                        fieldCode='hasFirmOffer'
                        label={<FormattedMessage id='Do you have a firm offer?' />}
                        value={this.props.policy.secureMyOfferCustomInfo.hasFirmOffer ?? false}
                        changeEvent={
                            value => {
                                this.props.doReducePolicyElement({
                                    code: 'secureMyOfferCustomInfo.hasFirmOffer',
                                    value: value
                                });
                            }}
                        allowEdit
                    />
                    <FormSwitch
                        fieldCode='usedRealEstateAgent'
                        label={<FormattedMessage
                            id='Did you use a Real Estate Agent?' />}
                        value={this.props.policy.secureMyOfferCustomInfo.usedRealEstateAgent ?? false}
                        changeEvent={
                            value => {
                                this.props.doReducePolicyElement({
                                    code: 'secureMyOfferCustomInfo.usedRealEstateAgent',
                                    value: value
                                });
                            }}
                        allowEdit
                    />
                    <FormSwitch
                        fieldCode='hasSecuredDeposit'
                        label={<FormattedMessage
                            id='Is your real estate agent or lawyer holding a deposit in trust?' />}
                        value={this.props.policy.secureMyOfferCustomInfo.hasSecuredDeposit ?? false}
                        changeEvent={
                            value => {
                                this.props.doReducePolicyElement({
                                    code: 'secureMyOfferCustomInfo.hasSecuredDeposit',
                                    value: value
                                });
                            }}
                        allowEdit
                    />
                    {this.props.policy.secureMyOfferCustomInfo.usedRealEstateAgent &&
                        <FormInput
                            fieldCode='securedDepositAmount'
                            label={<FormattedMessage id='How much is the deposit?' />}
                            value={this.props.policy.secureMyOfferCustomInfo.securedDepositAmount}
                            type='currency'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'secureMyOfferCustomInfo.securedDepositAmount',
                                value
                            })}
                            maxValue={9999999}
                            allowEdit
                        />
                    }
                    <FormSwitch
                        fieldCode='isNotSeriousBuyer'
                        label={<FormattedMessage
                            id='Do you have any reason to suspect that the buyer will not proceed with the purchase?' />}
                        value={this.props.policy.secureMyOfferCustomInfo.isNotSeriousBuyer ?? false}
                        changeEvent={
                            value => {
                                this.props.doReducePolicyElement({
                                    code: 'secureMyOfferCustomInfo.isNotSeriousBuyer',
                                    value: value
                                });
                            }}
                        allowEdit
                    />
                    <FormDate
                        fieldCode='currentPropertySaleClosingDate'
                        label={<FormattedMessage id={`What is the closing date?`} />}
                        value={this.props.policy && this.props.policy.secureMyOfferCustomInfo.currentPropertySaleClosingDate}
                        changeEvent={(value) => this.props.doReducePolicyElement({
                            code: `secureMyOfferCustomInfo.currentPropertySaleClosingDate`,
                            value
                        })}
                        culture={this.props.context.session.culture}
                        allowEdit
                    />
                    <FormInput
                        fieldCode='saleAmount'
                        label={<FormattedMessage id='What is the sale price?' />}
                        value={this.props.policy.secureMyOfferCustomInfo.saleAmount}
                        type='currency'
                        changeEvent={value => this.props.doReducePolicyElement({
                            code: 'secureMyOfferCustomInfo.saleAmount',
                            value
                        })}
                        maxValue={9999999}
                        allowEdit
                    />
                    <FormSwitch
                        fieldCode='isRelatedToBuyer'
                        label={<FormattedMessage id='Do you have any prior relationship with the buyer?' />}
                        value={this.props.policy.secureMyOfferCustomInfo.isRelatedToBuyer ?? false}
                        changeEvent={
                            value => {
                                this.props.doReducePolicyElement({
                                    code: 'secureMyOfferCustomInfo.isRelatedToBuyer',
                                    value: value
                                });
                            }}
                        allowEdit
                    />
                </Form>
                <Form
                    pageCode={this.props.pageCode}
                    sectionCode='residentialMarketValuation'
                    saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                    reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                    tooltipInfo={this.props.tooltipInfo}
                    validationInfo={this.props.validationInfo}
                    header={<FormattedMessage id='Residential Market Valuation' />}
                    allowEdit={this.props.allowEdit}
                >
                    <FormInput
                        fieldCode='residentialMarketValuationRangeMinimumValue'
                        label={<FormattedMessage id='Lowest Valuation Range' />}
                        value={this.props.policy.secureMyOfferCustomInfo.residentialMarketValuationRangeMinimumValue}
                        type='currency'
                    />
                    <FormInput
                        fieldCode='residentialMarketValuationRangeMaximumValue'
                        label={<FormattedMessage id='Highest Valuation Range' />}
                        value={this.props.policy.secureMyOfferCustomInfo.residentialMarketValuationRangeMaximumValue}
                        type='currency'
                    />
                    <FormInput
                        fieldCode='residentialMarketValuationValue'
                        label={<FormattedMessage id='Actual Valuation' />}
                        value={this.props.policy.secureMyOfferCustomInfo.residentialMarketValuationValue}
                        type='currency'
                    />
                    <FormDate
                        fieldCode='residentialMarketValuationDate'
                        label={<FormattedMessage id={`Date of Valuation`} />}
                        value={this.props.policy && this.props.policy.secureMyOfferCustomInfo.residentialMarketValuationDate}
                        culture={this.props.context.session.culture}
                    />
                </Form>
                {
                    getElementValueByCode(this.props.policy.customInfo.booleanFields, 'OptaCheckRequired') &&
                    <Container horizontalAlignment={Alignments.right}>
                        <SecondaryButton
                            right={Sizes.xs}
                            text="Get Valuation"
                            clickEvent={this.props.doGetOpta}
                        />
                    </Container>
                }
                <Form
                    pageCode={this.props.pageCode}
                    sectionCode='mortgageInfo'
                    saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                    reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                    tooltipInfo={this.props.tooltipInfo}
                    validationInfo={this.props.validationInfo}
                    header={<FormattedMessage id='Mortgage Info' />}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormSwitch
                        fieldCode='isNewPropertyClosingDateKnown'
                        label={<FormattedMessage
                            id='Is the closing date for the new property known?' />}
                        value={this.props.policy.secureMyOfferCustomInfo.isNewPropertyClosingDateKnown ?? false}
                        changeEvent={
                            value => {
                                this.props.doReducePolicyElement({
                                    code: 'secureMyOfferCustomInfo.isNewPropertyClosingDateKnown',
                                    value: value
                                });
                            }}
                        allowEdit
                    />
                    {
                        this.props.policy.secureMyOfferCustomInfo.isNewPropertyClosingDateKnown &&
                        <FormDate
                            fieldCode='newPropertyClosingDate'
                            label={<FormattedMessage id={`What is the closing date?`} />}
                            value={this.props.policy && this.props.policy.secureMyOfferCustomInfo.newPropertyClosingDate}
                            changeEvent={(value) => this.props.doReducePolicyElement({
                                code: `secureMyOfferCustomInfo.newPropertyClosingDate`,
                                value
                            })}
                            culture={this.props.context.session.culture}
                            allowEdit
                        />
                    }
                    <FormDropDown
                        fieldCode='mortgageCountCode'
                        label={<FormattedMessage id='How many mortgages?' />}
                        value={{ code: this.props.policy.secureMyOfferCustomInfo.mortgageCountCode }}
                        references={this.props.reference?.references['SecureMyOffer'] && this.props.reference.references['SecureMyOffer'].MortgageCount}
                        referencesEvent={() => this.props.reference.doGetReferences('SecureMyOffer.MortgageCount')}
                        changeEvent={value => {
                            this.props.doReducePolicyElement({
                                code: 'secureMyOfferCustomInfo.mortgageCountName',
                                value: value.code
                            });
                            this.props.doReducePolicyElement({
                                code: 'secureMyOfferCustomInfo.mortgageCountCode',
                                value: value.code
                            });
                        }}
                        allowEdit
                    />
                    <FormInput
                        fieldCode='totalMortgageBalance'
                        label={<FormattedMessage id='How much is your total mortgage balance?' />}
                        value={this.props.policy.secureMyOfferCustomInfo.totalMortgageBalance}
                        type='currency'
                        changeEvent={value => this.props.doReducePolicyElement({
                            code: 'secureMyOfferCustomInfo.totalMortgageBalance',
                            value
                        })}
                        allowEdit
                    />
                    <FormSwitch
                        fieldCode='isMortgageTransferred'
                        label={<FormattedMessage
                            id='Will you be porting your existing mortgage to a new property?' />}
                        value={this.props.policy.secureMyOfferCustomInfo.isMortgageTransferred ?? false}
                        changeEvent={
                            value => {
                                this.props.doReducePolicyElement({
                                    code: 'secureMyOfferCustomInfo.isMortgageTransferred',
                                    value: value
                                });
                            }}
                        allowEdit
                    />
                </Form>
            </>
        )
    }
}

export default ProfileSecureMyOfferOfferInfo