import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Form,
    FormDropDown,
    FormRadio,
    FormTextArea,
    convertFromBoolean,
    convertToBoolean,
    Alignments,
    Label
} from 'metrix-common';

class ProfileBtisWcAdditionalInformation extends React.Component {
    componentDidMount() {        
    }

    render() {
        return (
            <React.Fragment>
                <Form
                    header={<FormattedMessage id={`Additional Information`} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >                   
                    <FormRadio
                        label={<FormattedMessage id={`Any other insurance with this insurer?`} />}
                        value={this.props.policy.btisWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.btisWcCustomInfo.additionalInformation.hasOtherInsuranceWithThisInsurer) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.hasOtherInsuranceWithThisInsurer`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasOtherInsuranceWithThisInsurer === true &&
                        <Label bold text={<FormattedMessage id={`If other insurance policies of any kind are in force with this insurer, identify the coverages, policy numbers and terms.`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasOtherInsuranceWithThisInsurer === true &&
                        <FormTextArea
                            text={this.props.policy.btisWcCustomInfo?.additionalInformation?.otherInsuranceWithThisInsurerDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.otherInsuranceWithThisInsurerDescription`, value })}
                        />
                    }

                    <FormRadio
                        label={<FormattedMessage id={`Any prior coverage declined / cancelled / non-renewed / expired (last 3 yrs.)?`} />}
                        value={this.props.policy.btisWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.btisWcCustomInfo.additionalInformation.hasStoppedPreviousCoverage) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.hasStoppedPreviousCoverage`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasStoppedPreviousCoverage === true &&
                        <FormDropDown
                            label={<FormattedMessage id={`Please select the reason`} />}
                            value={{ code: this.props.policy.btisWcCustomInfo.additionalInformation?.priorCoverageDeclinedCode, value: this.props.policy.btisWcCustomInfo.additionalInformation?.priorCoverageDeclinedName }}
                            references={this.props.reference?.references[`BTIS-WC`] && this.props.reference.references[`BTIS-WC`][`PriorCoverage`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`BTIS-WC.PriorCoverage`)}
                            changeEvent={(value) => {
                                this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.priorCoverageDeclinedCode`, value: value.code });
                                this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.priorCoverageDeclinedName`, value: value.value });
                            }}
                            allowEdit
                        />
                    }
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasStoppedPreviousCoverage === true &&
                        <Label bold text={<FormattedMessage id={`Provide specific details on each occurrence in the past three years`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasStoppedPreviousCoverage === true &&
                        <FormTextArea
                            text={this.props.policy.btisWcCustomInfo?.additionalInformation?.stoppedPreviousCoverageDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.stoppedPreviousCoverageDescription`, value })}
                        />
                    }

                    <FormRadio
                        label={<FormattedMessage id={`Does the Applicant own, operate or lease aircraft/watercraft?`} />}
                        value={this.props.policy.btisWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.btisWcCustomInfo.additionalInformation.hasAircraftOrWatercraft) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.hasAircraftOrWatercraft`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasAircraftOrWatercraft === true &&
                        <Label bold text={<FormattedMessage id={`Please provide details`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasAircraftOrWatercraft === true &&
                        <FormTextArea
                            text={this.props.policy.btisWcCustomInfo?.additionalInformation?.aircraftOrWatercraftDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.aircraftOrWatercraftDescription`, value })}
                        />
                    }

                    <FormRadio
                        label={<FormattedMessage id={`Do / have past, present or discontinued operations involve(d) storing, treating, discharging, applying, disposing, or transporting of hazardous material? (E.g., landfills, asbestos, wastes, fuel tanks, etc.)`} />}
                        value={this.props.policy.btisWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.btisWcCustomInfo.additionalInformation.hasDiscontinuedOperations) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.hasDiscontinuedOperations`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasDiscontinuedOperations === true &&
                        <Label bold text={<FormattedMessage id={`Explain the exposure and the precautionary measures implemented to handle hazardous materials.`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasDiscontinuedOperations === true &&
                        <FormTextArea
                            text={this.props.policy.btisWcCustomInfo?.additionalInformation?.discontinuedOperationsDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.discontinuedOperationsDescription`, value })}
                        />
                    }

                    <FormRadio
                        label={<FormattedMessage id={`Any work performed underground or above 15 feet?`} />}
                        value={this.props.policy.btisWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.btisWcCustomInfo.additionalInformation.hasUndegroundOr15FeetWork) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.hasUndegroundOr15FeetWork`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasUndegroundOr15FeetWork === true &&
                        <Label bold text={<FormattedMessage id={`Please provide details`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasUndegroundOr15FeetWork === true &&
                        <FormTextArea
                            text={this.props.policy.btisWcCustomInfo?.additionalInformation?.undegroundOr15FeetWorkDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.undegroundOr15FeetWorkDescription`, value })}
                        />
                    }

                    <FormRadio
                        label={<FormattedMessage id={`Any work performed on barges, vessels, docks, bridge over water?`} />}
                        value={this.props.policy.btisWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.btisWcCustomInfo.additionalInformation.hasWorkOverWater) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.hasWorkOverWater`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasWorkOverWater === true &&
                        <Label bold text={<FormattedMessage id={`Please provide details`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasWorkOverWater === true &&
                        <FormTextArea
                            text={this.props.policy.btisWcCustomInfo?.additionalInformation?.workOverWaterDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.workOverWaterDescription`, value })}
                        />
                    }

                    <FormRadio
                        label={<FormattedMessage id={`Is the Applicant engaged in any other type of business?`} />}
                        value={this.props.policy.btisWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.btisWcCustomInfo.additionalInformation.hasOtherTypeOfBusiness) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.hasOtherTypeOfBusiness`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasOtherTypeOfBusiness === true &&
                        <Label bold text={<FormattedMessage id={`List all other businesses and the carrier for that business's workers' compensation coverage.`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasOtherTypeOfBusiness === true &&
                        <FormTextArea
                            text={this.props.policy.btisWcCustomInfo?.additionalInformation?.otherTypeOfBusinessDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.otherTypeOfBusinessDescription`, value })}
                        />
                    }

                    <FormRadio
                        label={<FormattedMessage id={`Are subcontractors used?`} />}
                        value={this.props.policy.btisWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.btisWcCustomInfo.additionalInformation.hasSubcontractors) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.hasSubcontractors`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasSubcontractors === true &&
                        <Label bold text={<FormattedMessage id={`Explain the nature and frequency of any subcontracted work. Give the percent of work subcontracted. Are Certificates of Insurance required?`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasSubcontractors === true &&
                        <FormTextArea
                        text={this.props.policy.btisWcCustomInfo?.additionalInformation?.subcontractorsDescription}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.subcontractorsDescription`, value })}
                        />
                    }

                    <FormRadio
                        label={<FormattedMessage id={`Any work sublet without certificates of insurance? (payroll for this work must be included)`} />}
                        value={this.props.policy.btisWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.btisWcCustomInfo.additionalInformation.hasSubletWorkWithoutCertificatesOfInsurance) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.hasSubletWorkWithoutCertificatesOfInsurance`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasSubletWorkWithoutCertificatesOfInsurance === true &&
                        <Label bold text={<FormattedMessage id={`Describe the nature and frequency of the subcontracted work and indicate if the classifications and remuneration for such work have been included in the Rating Information section.`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasSubletWorkWithoutCertificatesOfInsurance === true &&
                        <FormTextArea
                            text={this.props.policy.btisWcCustomInfo?.additionalInformation?.subletWorkWithoutCertificatesOfInsuranceDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.subletWorkWithoutCertificatesOfInsuranceDescription`, value })}
                        />
                    }

                    <FormRadio
                        label={<FormattedMessage id={`Is a written safety program in operation?`} />}
                        value={this.props.policy.btisWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.btisWcCustomInfo.additionalInformation.hasSafetyProgramInOperation) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.hasSafetyProgramInOperation`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />

                    <FormRadio
                        label={<FormattedMessage id={`Any group transportation provided?`} />}
                        value={this.props.policy.btisWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.btisWcCustomInfo.additionalInformation.hasGroupTransportation) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.hasGroupTransportation`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasGroupTransportation === true &&
                        <Label bold text={<FormattedMessage id={`Is a van pool program in effect? Does the employer shuttle employees to job sites? What type of conveyance is used? How many employees are transported? How often? Over what distance?`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasGroupTransportation === true &&
                        <FormTextArea
                            text={this.props.policy.btisWcCustomInfo?.additionalInformation?.groupTransportationDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.groupTransportationDescription`, value })}
                        />
                    }

                    <FormRadio
                        label={<FormattedMessage id={`Any employees under 16 years of age?`} />}
                        value={this.props.policy.btisWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.btisWcCustomInfo.additionalInformation.hasEmployessUnder16OrOver60) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.hasEmployessUnder16OrOver60`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasEmployessUnder16OrOver60 === true &&
                        <Label bold text={<FormattedMessage id={`Specify the number of employees in each category and the duties they perform.`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasEmployessUnder16OrOver60 === true &&
                        <FormTextArea
                            text={this.props.policy.btisWcCustomInfo?.additionalInformation?.employessUnder16OrOver60Description}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.employessUnder16OrOver60Description`, value })}
                        />
                    }

                    <FormRadio
                        label={<FormattedMessage id={`Any seasonal employees?`} />}
                        value={this.props.policy.btisWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.btisWcCustomInfo.additionalInformation.hasSeasonalEmployees) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.hasSeasonalEmployees`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasSeasonalEmployees === true &&
                        <Label bold text={<FormattedMessage id={`How many employees? How many hours do they work? At what time of the year are they employed? What are their duties?`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasSeasonalEmployees === true &&
                        <FormTextArea
                            text={this.props.policy.btisWcCustomInfo?.additionalInformation?.seasonalEmployeesDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.seasonalEmployeesDescription`, value })}
                        />
                    }

                    <FormRadio
                        label={<FormattedMessage id={`Is there any volunteer or donated labor?`} />}
                        value={this.props.policy.btisWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.btisWcCustomInfo.additionalInformation.hasVolunterOrDonatedLabor) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.hasVolunterOrDonatedLabor`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasVolunterOrDonatedLabor === true &&
                        <Label bold text={<FormattedMessage id={`Explain the circumstances under which volunteer labor is used and the nature of the work.`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasVolunterOrDonatedLabor === true &&
                        <FormTextArea
                            text={this.props.policy.btisWcCustomInfo?.additionalInformation?.volunterOrDonatedLaborDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.volunterOrDonatedLaborDescription`, value })}
                        />
                    }

                    <FormRadio
                        label={<FormattedMessage id={`Any employees with physical handicaps?`} />}
                        value={this.props.policy.btisWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.btisWcCustomInfo.additionalInformation.hasEmployeeWithPhysicalHandicaps) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.hasEmployeeWithPhysicalHandicaps`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasEmployeeWithPhysicalHandicaps === true &&
                        <Label bold text={<FormattedMessage id={`Describe the nature of the work and explain the circumstances under which physically handicapped workers are employed. Indicate the number of employees and the type of handicaps. Is the applicant involved in a special community program for handicapped people? If eligible, has the employee been registered in a second injury fund?`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasEmployeeWithPhysicalHandicaps === true &&
                        <FormTextArea
                            text={this.props.policy.btisWcCustomInfo?.additionalInformation?.employeeWithPhysicalHandicapsDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.employeeWithPhysicalHandicapsDescription`, value })}
                        />
                    }

                    <FormRadio
                        label={<FormattedMessage id={`Do employees travel out of state?`} />}
                        value={this.props.policy.btisWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.btisWcCustomInfo.additionalInformation.isEmployeeTravellingOutOfState) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.isEmployeeTravellingOutOfState`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.isEmployeeTravellingOutOfState === true &&
                        <Label bold text={<FormattedMessage id={`Describe the nature of the travel and indicate the number of employees, frequency and mode of transportation.`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.isEmployeeTravellingOutOfState === true &&
                        <FormTextArea
                            text={this.props.policy.btisWcCustomInfo?.additionalInformation?.emplyeeTravellingOutOfStateDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.emplyeeTravellingOutOfStateDescription`, value })}
                        />
                    }

                    <FormRadio
                        label={<FormattedMessage id={`Are athletic teams sponsored?`} />}
                        value={this.props.policy.btisWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.btisWcCustomInfo.additionalInformation.isSponsoringAthleticTeams) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.isSponsoringAthleticTeams`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.isSponsoringAthleticTeams === true &&
                        <Label bold text={<FormattedMessage id={`Describe the nature of the athletic activities and indicate the number of employees involved (if any). Indicate whether the applicant provides an accident and health policy to cover athletic activities. This may include company, school or community teams or leagues, such as Little League.`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.isSponsoringAthleticTeams === true &&
                        <FormTextArea
                            text={this.props.policy.btisWcCustomInfo?.additionalInformation?.sponsoringAthleticTeamsDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.sponsoringAthleticTeamsDescription`, value })}
                        />
                    }

                    <FormRadio
                        label={<FormattedMessage id={`Are physicals required after offers of employment are made?`} />}
                        value={this.props.policy.btisWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.btisWcCustomInfo.additionalInformation.arePhysicalsRequiredAfterEmploymentOffers) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.arePhysicalsRequiredAfterEmploymentOffers`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.arePhysicalsRequiredAfterEmploymentOffers === true &&
                        <Label bold text={<FormattedMessage id={`Are employees required to undergo a physical examination after they have been made an offer for employment? Describe the extent of the physical examination and indicate which applicants are required to take them.`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.arePhysicalsRequiredAfterEmploymentOffers === true &&
                        <FormTextArea
                            text={this.props.policy.btisWcCustomInfo?.additionalInformation?.physicalsRequiredAfterEmploymentOffersDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.physicalsRequiredAfterEmploymentOffersDescription`, value })}
                        />
                    }

                    <FormRadio
                        label={<FormattedMessage id={`Are employee health plans provided?`} />}
                        value={this.props.policy.btisWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.btisWcCustomInfo.additionalInformation.hasEmployeeHealthPlans) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.hasEmployeeHealthPlans`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasEmployeeHealthPlans === true &&
                        <Label bold text={<FormattedMessage id={`Please provide details.`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasEmployeeHealthPlans === true &&
                        <FormTextArea
                            text={this.props.policy.btisWcCustomInfo?.additionalInformation?.employeeHealthPlansDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.employeeHealthPlansDescription`, value })}
                        />
                    }

                    <FormRadio
                        label={<FormattedMessage id={`Do any employees perform work for other businesses or subsidiaries?`} />}
                        value={this.props.policy.btisWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.btisWcCustomInfo.additionalInformation.areEmployeesPerfomWorkForOtherBusiness) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.areEmployeesPerfomWorkForOtherBusiness`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.areEmployeesPerfomWorkForOtherBusiness === true &&
                        <Label bold text={<FormattedMessage id={`Please provide details.`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.areEmployeesPerfomWorkForOtherBusiness === true &&
                        <FormTextArea
                            text={this.props.policy.btisWcCustomInfo?.additionalInformation?.employeesPerfomWorkForOtherBusinessDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.employeesPerfomWorkForOtherBusinessDescription`, value })}
                        />
                    }

                    <FormRadio
                        label={<FormattedMessage id={`Does the Applicant lease employees to or from other employers?`} />}
                        value={this.props.policy.btisWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.btisWcCustomInfo.additionalInformation.hasLeasedEmployees) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.hasLeasedEmployees`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasLeasedEmployees === true &&
                        <Label bold text={<FormattedMessage id={`Please provide details.`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasLeasedEmployees === true &&
                        <FormTextArea
                            text={this.props.policy.btisWcCustomInfo?.additionalInformation?.leasedEmployeesDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.leasedEmployeesDescription`, value })}
                        />
                    }

                    <FormRadio
                        label={<FormattedMessage id={`Do any employees predominantly work at home?`} />}
                        value={this.props.policy.btisWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.btisWcCustomInfo.additionalInformation.hasPredominantlyWorkedAtHomeEmployees) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.hasPredominantlyWorkedAtHomeEmployees`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasPredominantlyWorkedAtHomeEmployees === true &&
                        <Label bold text={<FormattedMessage id={`Indicate who works at home and what their hours of operation are.`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasPredominantlyWorkedAtHomeEmployees === true &&
                        <FormTextArea
                            text={this.props.policy.btisWcCustomInfo?.additionalInformation?.predominantlyWorkedAtHomeEmployeesDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.predominantlyWorkedAtHomeEmployeesDescription`, value })}
                        />
                    }

                    <FormRadio
                        label={<FormattedMessage id={`Any tax liens or bankruptcy within the last 5 years?`} />}
                        value={this.props.policy.btisWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.btisWcCustomInfo.additionalInformation.hasLiensOrBankruptcy) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.hasLiensOrBankruptcy`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasLiensOrBankruptcy === true &&
                        <Label bold text={<FormattedMessage id={`Please provide details.`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasLiensOrBankruptcy === true &&
                        <FormTextArea
                            text={this.props.policy.btisWcCustomInfo?.additionalInformation?.liensOrBankruptcyDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.liensOrBankruptcyDescription`, value })}
                        />
                    }

                    <FormRadio
                        label={<FormattedMessage id={`Any undisputed and unpaid workers compensation premium due from you or any company managed or owned enterprises?`} />}
                        value={this.props.policy.btisWcCustomInfo?.additionalInformation && { code: convertFromBoolean(this.props.policy.btisWcCustomInfo.additionalInformation.hasUnpaidWorkersCompensation) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.hasUnpaidWorkersCompensation`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasUnpaidWorkersCompensation === true &&
                        <Label bold text={<FormattedMessage id={`Please provide details.`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.btisWcCustomInfo?.additionalInformation?.hasUnpaidWorkersCompensation === true &&
                        <FormTextArea
                            text={this.props.policy.btisWcCustomInfo?.additionalInformation?.unpaidWorkersCompensationDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.unpaidWorkersCompensationDescription`, value })}
                        />
                    }
                </Form>

                <Form
                    header={<FormattedMessage id={`Comments`} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <Label bold text={<FormattedMessage id={`Explanations`} />} textAlign={Alignments.left} />
                    <FormTextArea
                        text={this.props.policy.btisWcCustomInfo?.additionalInformation?.additionalInformationDescription}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.additionalInformationDescription`, value })}
                    />
                    <Label bold text={<FormattedMessage id={`Comments`} />} textAlign={Alignments.left} />
                    <FormTextArea
                        text={this.props.policy.btisWcCustomInfo?.additionalInformation?.additionalInformationCommentsDescription}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.additionalInformation.additionalInformationCommentsDescription`, value })}
                    />
                </Form>
            </React.Fragment>
        );
    }
}

export default ProfileBtisWcAdditionalInformation;