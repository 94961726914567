import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Form, FormChecklist,
    FormDropDown,
    FormInput, FormSwitch
} from 'metrix-common';

class ProfileInterwestFpOperations extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();

        const pageHasTooltipInfo = this.props.tooltipInfo && this.props.tooltipInfo.pages && this.props.tooltipInfo.pages.length && this.props.tooltipInfo.pages.some(p => p.code === this.props.pageCode);
        if (!pageHasTooltipInfo) {
            this.props.doGetTooltipInfo(this.props.policy.product);
        }
    }

    render() {
        return (
            <>
                <Form
                    pageCode={this.props.pageCode}
                    sectionCode='operationTypes'
                    saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                    reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                    tooltipInfo={this.props.tooltipInfo}
                    validationInfo={this.props.validationInfo}
                    header={<FormattedMessage id='Operation Types' />}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormChecklist
                        fieldCode='operationSelectionSelectedItems'
                        label={<FormattedMessage id='Please check all that apply to your business' />}
                        value={this.props.policy.interWestFPCustomInfo.operationInfo.operationSelectionSelectedItems}
                        references={this.props.reference.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].Operations}
                        referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.Operations')}
                        changeEvent={values => {
                            this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.operationInfo.operationSelectionSelectedItems',
                                value: values
                            });
                        }}
                        allowEdit
                    />
                </Form>
                {(this.props.policy.interWestFPCustomInfo.operationInfo.operationSelectionSelectedItems.find(value => value?.code === 'PersonalTraining')) &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='personalTraining'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='Personal Training' />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormInput
                            fieldCode='personalTrainersCount'
                            label={<FormattedMessage id='How many personal trainers do you have?' />}
                            value={this.props.policy.interWestFPCustomInfo.operationInfo.personalTrainersCount}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.operationInfo.personalTrainersCount',
                                value
                            })}
                            allowEdit
                        />
                        <FormInput
                            fieldCode='personalTrainersCertifications'
                            label={<FormattedMessage id='What certifications or licenses do your trainers have?' />}
                            value={this.props.policy.interWestFPCustomInfo.operationInfo.personalTrainersCertifications}
                            type='text'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.operationInfo.personalTrainersCertifications',
                                value
                            })}
                            allowEdit
                        />
                    </Form>
                }
                {(this.props.policy.interWestFPCustomInfo.operationInfo.operationSelectionSelectedItems.find(value => value?.code === 'GroupTraining')) &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='groupTraining'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='Group Training' />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormInput
                            fieldCode='groupTrainingClassDescription'
                            label={<FormattedMessage id='Describe group training classes:' />}
                            value={this.props.policy.interWestFPCustomInfo.operationInfo.groupTrainingClassDescription}
                            type='text'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.operationInfo.groupTrainingClassDescription',
                                value
                            })}
                            allowEdit
                        />
                    </Form>
                }
                {(this.props.policy.interWestFPCustomInfo.operationInfo.operationSelectionSelectedItems.find(value => value?.code === 'CrossFit')) &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='crossFit'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='CrossFit' />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormInput
                            fieldCode='crossFitPercentage'
                            label={<FormattedMessage id='What percent of operations dedicated to Crossfit?' />}
                            value={this.props.policy.interWestFPCustomInfo.operationInfo.crossFitPercentage}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.operationInfo.crossFitPercentage',
                                value
                            })}
                            allowEdit
                        />
                    </Form>
                }
                {(this.props.policy.interWestFPCustomInfo.operationInfo.operationSelectionSelectedItems.find(value => value?.code === 'MixedMartialArts')) &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='mixedMartialArts'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='Mixed Martial Arts (MMA)' />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormDropDown
                            fieldCode='mmaTypeOfContactCode'
                            label={<FormattedMessage id='What type of MMA is done at the facility?' />}
                            value={{ code: this.props.policy.interWestFPCustomInfo.operationInfo.mmaTypeOfContactCode }}
                            references={this.props.reference?.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].MAContact}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.MAContact')}
                            changeEvent={value => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.mmaTypeOfContactCode',
                                    value: value.code
                                });
                            }}
                            allowEdit
                        />
                        <FormChecklist
                            fieldCode='mmaTypeOfTrainingSelectedItems'
                            label={<FormattedMessage id='Please indicate if there is the following:' />}
                            value={this.props.policy.interWestFPCustomInfo.operationInfo.mmaTypeOfTrainingSelectedItems}
                            references={this.props.reference.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].MATraining}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.MATraining')}
                            changeEvent={values => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.mmaTypeOfTrainingSelectedItems',
                                    value: values
                                });
                            }}
                            allowEdit
                        />
                        <FormChecklist
                            fieldCode='mmaTypeOfSafetyEquipmentSelectedItems'
                            label={<FormattedMessage id='Describe Safety equipment:' />}
                            value={this.props.policy.interWestFPCustomInfo?.operationInfo.mmaTypeOfSafetyEquipmentSelectedItems}
                            references={this.props.reference.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].MMASafety}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.MMASafety')}
                            changeEvent={values => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.mmaTypeOfSafetyEquipmentSelectedItems',
                                    value: values
                                });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {(this.props.policy.interWestFPCustomInfo.operationInfo.operationSelectionSelectedItems.find(value => value?.code === 'FreeWeights')) &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='freeWeights'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='Free Weights' />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormDropDown
                            fieldCode='freeWeightsCleaningFrequencyCode'
                            label={<FormattedMessage id='How often do you inspect and clean your equipment?' />}
                            value={{ code: this.props.policy.interWestFPCustomInfo.operationInfo.freeWeightsCleaningFrequencyCode }}
                            references={this.props.reference?.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].DailyWeeklyMonthly}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.DailyWeeklyMonthly')}
                            changeEvent={value => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.freeWeightsCleaningFrequencyCode',
                                    value: value.code
                                });
                            }}
                            allowEdit
                        />
                        <FormDropDown
                            fieldCode='freeWeightsHeaviestDumbbellCode'
                            label={<FormattedMessage id='What is the heaviest dumbbell set at your gym (lbs)?' />}
                            value={{ code: this.props.policy.interWestFPCustomInfo.operationInfo.freeWeightsHeaviestDumbbellCode }}
                            references={this.props.reference?.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].FWDumbbell}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.FWDumbbell')}
                            changeEvent={value => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.freeWeightsHeaviestDumbbellCode',
                                    value: value.code
                                });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {(this.props.policy.interWestFPCustomInfo.operationInfo.operationSelectionSelectedItems.find(value => value?.code === 'CardioWeightMachines')) &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='cardioAndWeightMachines'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='Cardio and Weight Machines' />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormDropDown
                            fieldCode='cardioWeightMachinesEquipmentInspectingCleaningFrequencyCode'
                            label={<FormattedMessage
                                id='How often do you inspect and clean your Cardio and Weight machines?' />}
                            value={{ code: this.props.policy.interWestFPCustomInfo.operationInfo.cardioWeightMachinesEquipmentInspectingCleaningFrequencyCode }}
                            references={this.props.reference?.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].DailyWeeklyMonthly}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.DailyWeeklyMonthly')}
                            changeEvent={value => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.cardioWeightMachinesEquipmentInspectingCleaningFrequencyCode',
                                    value: value.code
                                });
                            }}
                            allowEdit
                        />
                        <FormSwitch
                            fieldCode='hasCardioWeightMachinesSignsPostedForProperUserEquipment'
                            label={<FormattedMessage id='Are signs posted indicating how to properly use equipment?' />}
                            value={this.props.policy.interWestFPCustomInfo.operationInfo.hasCardioWeightMachinesSignsPostedForProperUserEquipment ?? false}
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.operationInfo.hasCardioWeightMachinesSignsPostedForProperUserEquipment',
                                        value: value
                                    });
                                }}
                            allowEdit
                        />
                    </Form>
                }
                {(this.props.policy.interWestFPCustomInfo.operationInfo.operationSelectionSelectedItems.find(value => value?.code === 'Racquetball')) &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='racquetball'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='Racquetball' />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormDropDown
                            fieldCode='racquetballCourtLocationCode'
                            label={<FormattedMessage id='Are the racquetball courts Indoor or outdoor?' />}
                            value={{ code: this.props.policy.interWestFPCustomInfo.operationInfo.racquetballCourtLocationCode }}
                            references={this.props.reference?.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].IndoorOutdoorBoth}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.IndoorOutdoorBoth')}
                            changeEvent={value => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.racquetballCourtLocationCode',
                                    value: value.code
                                });
                            }}
                            allowEdit
                        />
                        <FormInput
                            fieldCode='racquetballCourtCount'
                            label={<FormattedMessage id='Number of racquetball courts?' />}
                            value={this.props.policy.interWestFPCustomInfo.operationInfo.racquetballCourtCount}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.operationInfo.racquetballCourtCount',
                                value
                            })}
                            allowEdit
                        />
                    </Form>
                }
                {(this.props.policy.interWestFPCustomInfo.operationInfo.operationSelectionSelectedItems.find(value => value?.code === 'Basketball')) &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='basketball'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='Basketball' />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormDropDown
                            fieldCode='basketballCourtTypeCode'
                            label={<FormattedMessage id='Are the Basketball Courts Indoor or Outdoor?' />}
                            value={{ code: this.props.policy.interWestFPCustomInfo.operationInfo.basketballCourtLocationCode }}
                            references={this.props.reference?.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].IndoorOutdoorBoth}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.IndoorOutdoorBoth')}
                            changeEvent={value => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.basketballCourtLocationCode',
                                    value: value.code
                                });
                            }}
                            allowEdit
                        />
                        <FormDropDown
                            fieldCode='basketballCourtLocationCode'
                            label={<FormattedMessage id='Are the Basketball Courts full or half courts?' />}
                            value={{ code: this.props.policy.interWestFPCustomInfo.operationInfo.basketballCourtTypeCode }}
                            references={this.props.reference?.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].BCType}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.BCType')}
                            changeEvent={value => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.basketballCourtTypeCode',
                                    value: value.code
                                });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {(this.props.policy.interWestFPCustomInfo.operationInfo.operationSelectionSelectedItems.find(value => value?.code === 'Tennis')) &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='tennis'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='Tennis' />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormDropDown
                            fieldCode='tennisCourtLocationCode'
                            label={<FormattedMessage id='Are the Basketball Courts Indoor or Outdoor?' />}
                            value={{ code: this.props.policy.interWestFPCustomInfo.operationInfo.tennisCourtLocationCode }}
                            references={this.props.reference?.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].IndoorOutdoorBoth}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.IndoorOutdoorBoth')}
                            changeEvent={value => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.tennisCourtLocationCode',
                                    value: value.code
                                });
                            }}
                            allowEdit
                        />
                        <FormInput
                            fieldCode='tennisCourtNumber'
                            label={<FormattedMessage id='How many Tennis Courts?' />}
                            value={this.props.policy.interWestFPCustomInfo.operationInfo.tennisCourtNumber}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.operationInfo.tennisCourtNumber',
                                value
                            })}
                            allowEdit
                        />
                    </Form>
                }
                {(this.props.policy.interWestFPCustomInfo.operationInfo.operationSelectionSelectedItems.find(value => value?.code === 'SwimmingPool')) &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='swimmingPool'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='Swimming Pool' />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormInput
                            fieldCode='swimmingPoolCount'
                            label={<FormattedMessage id='How many pools are there in the facility?' />}
                            value={this.props.policy.interWestFPCustomInfo.operationInfo.swimmingPoolCount}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.operationInfo.swimmingPoolCount',
                                value
                            })}
                            allowEdit
                        />
                        <FormDropDown
                            fieldCode='swimmingPoolLocation'
                            label={<FormattedMessage id='Are the swimming pools indoor or outdoor?' />}
                            value={{ code: this.props.policy.interWestFPCustomInfo.operationInfo.swimmingPoolLocation }}
                            references={this.props.reference?.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].IndoorOutdoorBoth}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.IndoorOutdoorBoth')}
                            changeEvent={value => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.swimmingPoolLocation',
                                    value: value.code
                                });
                            }}
                            allowEdit
                        />
                        <FormInput
                            fieldCode='swimmingPoolSizeLength'
                            label={<FormattedMessage id='What is the size of the swimming pools?' />}
                            value={this.props.policy.interWestFPCustomInfo.operationInfo.swimmingPoolSizeLength}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.operationInfo.swimmingPoolSizeLength',
                                value
                            })}
                            allowEdit
                        />
                        <FormInput
                            fieldCode='swimmingPoolSizeShallowDepth'
                            label={<FormattedMessage id='What is the Shallow end Depth of the swimming pools (feet)?' />}
                            value={this.props.policy.interWestFPCustomInfo.operationInfo.swimmingPoolSizeShallowDepth}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.operationInfo.swimmingPoolSizeShallowDepth',
                                value
                            })}
                            allowEdit
                        />
                        <FormInput
                            fieldCode='swimmingPoolSizeDeepEndDepth'
                            label={<FormattedMessage id='What is the Deep End Depth of the swimming pools?' />}
                            value={this.props.policy.interWestFPCustomInfo.operationInfo.swimmingPoolSizeDeepEndDepth}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.operationInfo.swimmingPoolSizeDeepEndDepth',
                                value
                            })}
                            allowEdit
                        />
                        <FormChecklist
                            fieldCode='swimmingPoolAdditionalInformationSelectedItems'
                            label={<FormattedMessage id='Please check the following that are true:' />}
                            value={this.props.policy.interWestFPCustomInfo.operationInfo.swimmingPoolAdditionalInformationSelectedItems}
                            references={this.props.reference.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].SPAdditionalInfo}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.SPAdditionalInfo')}
                            changeEvent={values => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.swimmingPoolAdditionalInformationSelectedItems',
                                    value: values
                                });

                                if (!this.props.policy.interWestFPCustomInfo.operationInfo.swimmingPoolAdditionalInformationSelectedItems.find(value => value.code === 'Diving boards exist')) {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.operationInfo.isSwimmingPoolAdditionalInformationDivingBoardExceedFiveFeet',
                                        value: false
                                    })

                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.operationInfo.swimmingPoolAdditionalInformationDivingBoardHeight',
                                        value: null
                                    })
                                }

                                if (!this.props.policy.interWestFPCustomInfo.operationInfo.swimmingPoolAdditionalInformationSelectedItems.find(value => value.code === 'Slides exist')) {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.operationInfo.swimmingPoolAdditionalInformationSlideHeight',
                                        value: null
                                    })
                                }
                            }}
                            allowEdit
                        />
                        {
                            this.props.policy.interWestFPCustomInfo.operationInfo.swimmingPoolAdditionalInformationSelectedItems.find(value => value.code === 'Diving boards exist') &&
                            <FormSwitch
                                fieldCode='isSwimmingPoolAdditionalInformationDivingBoardExceedFiveFeet'
                                label={<FormattedMessage id='Do you have any diving boards more than 5 feet high?' />}
                                value={this.props.policy.interWestFPCustomInfo.operationInfo.isSwimmingPoolAdditionalInformationDivingBoardExceedFiveFeet ?? false}
                                changeEvent={
                                    value => {
                                        this.props.doReducePolicyElement({
                                            code: 'interWestFPCustomInfo.operationInfo.isSwimmingPoolAdditionalInformationDivingBoardExceedFiveFeet',
                                            value: value
                                        });

                                        this.props.doReducePolicyElement({
                                            code: 'interWestFPCustomInfo.operationInfo.swimmingPoolAdditionalInformationDivingBoardHeight',
                                            value: null
                                        })
                                    }}
                                allowEdit
                            />
                        }
                        {
                            (this.props.policy.interWestFPCustomInfo.operationInfo.swimmingPoolAdditionalInformationSelectedItems.find(value => value.code === 'Diving boards exist') &&
                                this.props.policy.interWestFPCustomInfo.operationInfo.isSwimmingPoolAdditionalInformationDivingBoardExceedFiveFeet) &&
                            <FormInput
                                fieldCode='swimmingPoolAdditionalInformationDivingBoardHeight'
                                label={<FormattedMessage id='How high are your diving boards?' />}
                                value={this.props.policy.interWestFPCustomInfo.operationInfo.swimmingPoolAdditionalInformationDivingBoardHeight}
                                type='number'
                                changeEvent={value => this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.swimmingPoolAdditionalInformationDivingBoardHeight',
                                    value
                                })}
                                allowEdit
                            />
                        }
                        {
                            this.props.policy.interWestFPCustomInfo.operationInfo.swimmingPoolAdditionalInformationSelectedItems.find(value => value.code === 'Slides exist') &&
                            <FormInput
                                fieldCode='swimmingPoolAdditionalInformationSlideHeight'
                                label={<FormattedMessage id='What is the height of your tallest slide?' />}
                                value={this.props.policy.interWestFPCustomInfo.operationInfo.swimmingPoolAdditionalInformationSlideHeight}
                                type='number'
                                changeEvent={value => this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.swimmingPoolAdditionalInformationSlideHeight',
                                    value
                                })}
                                allowEdit
                            />
                        }
                        <FormDropDown
                            fieldCode='swimmingPoolWaterTestFrequencyCode'
                            label={<FormattedMessage id='How often is the pool water tested?' />}
                            references={this.props.reference?.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].SSMonitor}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.SSMonitor')}
                            value={this.props.policy.interWestFPCustomInfo.operationInfo.swimmingPoolWaterTestFrequencyCode}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.operationInfo.swimmingPoolWaterTestFrequencyCode',
                                value
                            })}
                            allowEdit
                        />
                    </Form>
                }
                {(this.props.policy.interWestFPCustomInfo.operationInfo.operationSelectionSelectedItems.find(value => value?.code === 'KidsClub')) &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='kidsClub'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='Kids Club' />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormDropDown
                            fieldCode='kidsClubDetailsYoungestCode'
                            label={<FormattedMessage id='How old is the youngest child at the Kids Club?' />}
                            value={{ code: this.props.policy.interWestFPCustomInfo.operationInfo.kidsClubDetailsYoungestCode }}
                            references={this.props.reference?.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].KCYoungest}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.KCYoungest')}
                            changeEvent={value => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.kidsClubDetailsYoungestCode',
                                    value: value.code
                                });
                            }}
                            allowEdit
                        />
                        <FormDropDown
                            fieldCode='kidsClubDetailsOldestCode'
                            label={<FormattedMessage id='How old is the oldest child at the Kids Club?' />}
                            value={{ code: this.props.policy.interWestFPCustomInfo.operationInfo.kidsClubDetailsOldestCode }}
                            references={this.props.reference?.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].KCOldest}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.KCOldest')}
                            changeEvent={value => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.kidsClubDetailsOldestCode',
                                    value: value.code
                                });
                            }}
                            allowEdit
                        />
                        <FormDropDown
                            fieldCode='kidsClubDetailsKidToStaffRatioCode'
                            label={<FormattedMessage id='What is the Ratio of staff to children at the Kids Club?' />}
                            value={{ code: this.props.policy.interWestFPCustomInfo.operationInfo.kidsClubDetailsKidToStaffRatioCode }}
                            references={this.props.reference?.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].KCKidsStaffRatio}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.KCKidsStaffRatio')}
                            changeEvent={value => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.kidsClubDetailsKidToStaffRatioCode',
                                    value: value.code
                                });
                            }}
                            allowEdit
                        />
                        <FormChecklist
                            fieldCode='kidsClubAdditionalInformationSelectedItems'
                            label={<FormattedMessage id='Please check the following that are true:' />}
                            value={this.props.policy.interWestFPCustomInfo.operationInfo.kidsClubAdditionalInformationSelectedItems}
                            references={this.props.reference.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].KCAdditionalInfo}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.KCAdditionalInfo')}
                            changeEvent={values => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.kidsClubAdditionalInformationSelectedItems',
                                    value: values
                                });
                            }}
                            allowEdit
                        />
                        <FormInput
                            fieldCode='kidsClubActivities'
                            label={<FormattedMessage id='Activities occurring at the Kids Club:' />}
                            value={this.props.policy.interWestFPCustomInfo.operationInfo.kidsClubActivities}
                            type='text'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.operationInfo.kidsClubActivities',
                                value
                            })}
                            allowEdit
                        />
                    </Form>
                }
                {(this.props.policy.interWestFPCustomInfo.operationInfo.operationSelectionSelectedItems.find(value => value?.code === 'SteamSaunas')) &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='steamRoomsAndSaunas'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='Steam Rooms and Saunas' />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormDropDown
                            fieldCode='steamSaunasMonitorFrequencyCode'
                            label={<FormattedMessage
                                id='How often are the steam room or saunas monitored for usage during open hours?' />}
                            value={{ code: this.props.policy.interWestFPCustomInfo.operationInfo.steamSaunasMonitorFrequencyCode }}
                            references={this.props.reference?.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].SSMonitor}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.SSMonitor')}
                            changeEvent={value => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.steamSaunasMonitorFrequencyCode',
                                    value: value.code
                                });
                            }}
                            allowEdit
                        />
                        <FormChecklist
                            fieldCode='steamSaunasAdditionalInformationSelectedItems'
                            label={<FormattedMessage id='Please check the following that are true:' />}
                            value={this.props.policy.interWestFPCustomInfo.operationInfo.steamSaunasAdditionalInformationSelectedItems}
                            references={this.props.reference.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].SSAdditionalInfo}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.SSAdditionalInfo')}
                            changeEvent={values => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.steamSaunasAdditionalInformationSelectedItems',
                                    value: values
                                });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {(this.props.policy.interWestFPCustomInfo.operationInfo.operationSelectionSelectedItems.find(value => value?.code === 'HotTubs')) &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='hotTubs'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='Hot Tubs' />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormChecklist
                            fieldCode='hotTubsDetailsSelectedItems'
                            label={<FormattedMessage id='Please check the following that are true:' />}
                            value={this.props.policy.interWestFPCustomInfo.operationInfo.hotTubsDetailsSelectedItems}
                            references={this.props.reference.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].HTDetails}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.HTDetails')}
                            changeEvent={values => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.hotTubsDetailsSelectedItems',
                                    value: values
                                });
                            }}
                            allowEdit
                        />
                        <FormDropDown
                            fieldCode='hotTubsTestingFrequencyCode'
                            label={<FormattedMessage
                                id='How often are the steam room or saunas monitored for usage during open hours?' />}
                            value={{ code: this.props.policy.interWestFPCustomInfo.operationInfo.hotTubsTestingFrequencyCode }}
                            references={this.props.reference?.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].HTTesting}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.HTTesting')}
                            changeEvent={value => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.hotTubsTestingFrequencyCode',
                                    value: value.code
                                });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {(this.props.policy.interWestFPCustomInfo.operationInfo.operationSelectionSelectedItems.find(value => value?.code === 'Tanning')) &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='tanning'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='Tanning' />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormDropDown
                            fieldCode='tanningUnitCountCode'
                            label={<FormattedMessage id='How many tanning units?' />}
                            value={{ code: this.props.policy.interWestFPCustomInfo.operationInfo.tanningUnitCountCode }}
                            references={this.props.reference?.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].TUCount}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.TUCount')}
                            changeEvent={value => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.tanningUnitCountCode',
                                    value: value.code
                                });
                            }}
                            allowEdit
                        />
                        <FormDropDown
                            fieldCode='tanningUnitTypeCode'
                            label={<FormattedMessage id='Are they tanning booth or beds?' />}
                            value={{ code: this.props.policy.interWestFPCustomInfo.operationInfo.tanningUnitTypeCode }}
                            references={this.props.reference?.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].TUType}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.TUType')}
                            changeEvent={value => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.tanningUnitTypeCode',
                                    value: value.code
                                });
                            }}
                            allowEdit
                        />
                        <FormChecklist
                            fieldCode='tanningAdditionalInformationSelectedItems'
                            label={<FormattedMessage id='Please check the following that are true:' />}
                            value={this.props.policy.interWestFPCustomInfo?.operationInfo.tanningAdditionalInformationSelectedItems}
                            references={this.props.reference.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].TUAdditionalInfo}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.TUAdditionalInfo')}
                            changeEvent={values => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.tanningAdditionalInformationSelectedItems',
                                    value: values
                                });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {(this.props.policy.interWestFPCustomInfo.operationInfo.operationSelectionSelectedItems.find(value => value?.code === 'FullDayOperations')) &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='fullDayOperations'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='Full Day Operations' />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormDropDown
                            fieldCode='fullDayOperationsHasKeyPadOrCardAccessCode'
                            label={<FormattedMessage id='Is the Facility Keypad or access card accessible?' />}
                            value={{ code: this.props.policy.interWestFPCustomInfo.operationInfo.fullDayOperationsHasKeyPadOrCardAccessCode }}
                            references={this.props.reference?.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].FDAccess}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.FDAccess')}
                            changeEvent={value => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.fullDayOperationsHasKeyPadOrCardAccessCode',
                                    value: value.code
                                });
                            }}
                            allowEdit
                        />
                        <FormChecklist
                            fieldCode='fullDayOperationsAdditionalInformationSelectedItems'
                            label={<FormattedMessage id='Please check the following that are true:' />}
                            value={this.props.policy.interWestFPCustomInfo?.operationInfo.fullDayOperationsAdditionalInformationSelectedItems}
                            references={this.props.reference.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].FDAdditionalInfo}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.FDAdditionalInfo')}
                            changeEvent={values => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.fullDayOperationsAdditionalInformationSelectedItems',
                                    value: values
                                });

                                if (!this.props.policy.interWestFPCustomInfo.operationInfo.fullDayOperationsAdditionalInformationSelectedItems.find(value => value?.code === 'Staff present all day')) {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.operationInfo.hasFullDayOperationsAdditionalInformationStaffDuringDay',
                                        value: false
                                    });

                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.operationInfo.fullDayOperationsAdditionalInformationStaffDuringDayHoursStaffPresent',
                                        value: null
                                    })
                                }

                                if (!this.props.policy.interWestFPCustomInfo.operationInfo.fullDayOperationsAdditionalInformationSelectedItems.find(value => value?.code === 'Emergency action plan is in place')) {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.operationInfo.hasFullDayOperationsAdditionalInformationMemberAccessToEmergencyPlan',
                                        value: false
                                    });
                                }
                            }}
                            allowEdit
                        />
                        {this.props.policy.interWestFPCustomInfo.operationInfo.fullDayOperationsAdditionalInformationSelectedItems.find(value => value?.code === 'Staff present all day') &&
                            <FormSwitch
                                fieldCode='hasFullDayOperationsAdditionalInformationStaffDuringDay'
                                label={<FormattedMessage id='Is there staff present during the day?' />}
                                value={this.props.policy.interWestFPCustomInfo.operationInfo.hasFullDayOperationsAdditionalInformationStaffDuringDay ?? false}
                                changeEvent={
                                    value => {
                                        this.props.doReducePolicyElement({
                                            code: 'interWestFPCustomInfo.operationInfo.hasFullDayOperationsAdditionalInformationStaffDuringDay',
                                            value: value
                                        });

                                        this.props.doReducePolicyElement({
                                            code: 'interWestFPCustomInfo.operationInfo.fullDayOperationsAdditionalInformationStaffDuringDayHoursStaffPresent',
                                            value: null
                                        })
                                    }}
                                allowEdit
                            />
                        }
                        {
                            (this.props.policy.interWestFPCustomInfo.operationInfo.fullDayOperationsAdditionalInformationSelectedItems.find(value => value?.code === 'Staff present all day') &&
                                this.props.policy.interWestFPCustomInfo.operationInfo.hasFullDayOperationsAdditionalInformationStaffDuringDay) &&
                            <FormInput
                                fieldCode='fullDayOperationsAdditionalInformationStaffDuringDayHoursStaffPresent'
                                label={<FormattedMessage id='What hours are the staff present?' />}
                                value={this.props.policy.interWestFPCustomInfo.operationInfo.fullDayOperationsAdditionalInformationStaffDuringDayHoursStaffPresent}
                                type='text'
                                changeEvent={value => this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.fullDayOperationsAdditionalInformationStaffDuringDayHoursStaffPresent',
                                    value
                                })}
                                allowEdit
                            />
                        }
                        {
                            (this.props.policy.interWestFPCustomInfo.operationInfo.fullDayOperationsAdditionalInformationSelectedItems.find(value => value?.code === 'Emergency action plan is in place')) &&
                            <FormSwitch
                                fieldCode='hasFullDayOperationsAdditionalInformationMemberAccessToEmergencyPlan'
                                label={<FormattedMessage id='Do your members have access to Emergency Action Plan?' />}
                                value={this.props.policy.interWestFPCustomInfo.operationInfo.hasFullDayOperationsAdditionalInformationMemberAccessToEmergencyPlan ?? false}
                                changeEvent={
                                    value => {
                                        this.props.doReducePolicyElement({
                                            code: 'interWestFPCustomInfo.operationInfo.hasFullDayOperationsAdditionalInformationMemberAccessToEmergencyPlan',
                                            value: value
                                        });
                                    }}
                                allowEdit
                            />
                        }
                        <FormSwitch
                            fieldCode='hasFullDayOperationsSurveillance'
                            label={<FormattedMessage id='Is there 24 hour video surveillance?' />}
                            value={this.props.policy.interWestFPCustomInfo.operationInfo.hasFullDayOperationsSurveillance ?? false}
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.operationInfo.hasFullDayOperationsSurveillance',
                                        value: value
                                    });
                                }}
                            allowEdit
                        />
                    </Form>
                }
                {(this.props.policy.interWestFPCustomInfo.operationInfo.operationSelectionSelectedItems.find(value => value?.code === 'LockerRoom')) &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='lockerRoom'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='Locker Room' />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormSwitch
                            fieldCode='hasLockerRoomFloors'
                            label={<FormattedMessage id='Is there slip resistant flooring in the locker rooms?' />}
                            value={this.props.policy.interWestFPCustomInfo.operationInfo.hasLockerRoomFloors ?? false}
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.operationInfo.hasLockerRoomFloors',
                                        value: value
                                    });
                                }}
                            allowEdit
                        />
                        <FormSwitch
                            fieldCode='hasLockerRoomCellPhone'
                            label={<FormattedMessage
                                id='Do you have signage prohibiting the use of cell phones in locker rooms?' />}
                            value={this.props.policy.interWestFPCustomInfo.operationInfo.hasLockerRoomCellPhone ?? false}
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.operationInfo.hasLockerRoomCellPhone',
                                        value: value
                                    });
                                }}
                            allowEdit
                        />
                        <FormDropDown
                            fieldCode='lockerRoomAndShowerCleanedFrequencyCode'
                            label={<FormattedMessage id='How often are showers and locker rooms disinfected and cleaned?' />}
                            value={{ code: this.props.policy.interWestFPCustomInfo.operationInfo.lockerRoomAndShowerCleanedFrequencyCode }}
                            references={this.props.reference?.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].LRCleaning}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.LRCleaning')}
                            changeEvent={value => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.lockerRoomAndShowerCleanedFrequencyCode',
                                    value: value.code
                                });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {(this.props.policy.interWestFPCustomInfo.operationInfo.operationSelectionSelectedItems.find(value => value?.code === 'FoodSnackBar')) &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='foodSnackBar'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='Food and Snack Bar' />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormChecklist
                            fieldCode='foodOptionsSelectionSelectedItems'
                            label={<FormattedMessage id='Describe food offered for sale at the Food/Snack Bar' />}
                            value={this.props.policy.interWestFPCustomInfo.operationInfo.foodOptionsSelectionSelectedItems}
                            references={this.props.reference.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].FoodOptions}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.FoodOptions')}
                            changeEvent={values => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.foodOptionsSelectionSelectedItems',
                                    value: values
                                });
                            }}
                            allowEdit
                        />

                        <FormInput
                            fieldCode='foodSnackBarFood'
                            label={<FormattedMessage id='Other food' />}
                            value={this.props.policy.interWestFPCustomInfo.operationInfo.foodSnackBarFood}
                            type='text'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.operationInfo.foodSnackBarFood',
                                value
                            })}
                            allowEdit
                        />

                        <FormSwitch
                            fieldCode='hasFoodSnackBarCommercialEquipment'
                            label={<FormattedMessage id='Do you have commercial cooking equipment?' />}
                            value={this.props.policy.interWestFPCustomInfo.operationInfo.hasFoodSnackBarCommercialEquipment ?? false}
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.operationInfo.hasFoodSnackBarCommercialEquipment',
                                        value: value
                                    });

                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.operationInfo.foodSnackBarHasDeepFryersNumberCode',
                                        value: null
                                    });

                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.operationInfo.hasFoodSnackBarKitchenHoodSystem',
                                        value: false
                                    });
                                }
                            }
                            allowEdit
                        />
                        {
                            this.props.policy.interWestFPCustomInfo.operationInfo.hasFoodSnackBarCommercialEquipment &&
                            <FormDropDown
                                fieldCode='foodSnackBarHasDeepFryersNumberCode'
                                label={<FormattedMessage id='Number of deep fat fryers:' />}
                                value={{ code: this.props.policy.interWestFPCustomInfo.operationInfo.foodSnackBarHasDeepFryersNumberCode }}
                                references={this.props.reference?.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].FSFryer}
                                referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.FSFryer')}
                                changeEvent={value => {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.operationInfo.foodSnackBarHasDeepFryersNumberCode',
                                        value: value.code
                                    });
                                }}
                                allowEdit
                            />
                        }
                        {
                            this.props.policy.interWestFPCustomInfo.operationInfo.hasFoodSnackBarCommercialEquipment &&
                            <FormSwitch
                                fieldCode='hasFoodSnackBarKitchenHoodSystem'
                                label={<FormattedMessage id='Do you have a kitchen hood system?' />}
                                value={this.props.policy.interWestFPCustomInfo.operationInfo.hasFoodSnackBarKitchenHoodSystem ?? false}
                                changeEvent={
                                    value => {
                                        this.props.doReducePolicyElement({
                                            code: 'interWestFPCustomInfo.operationInfo.hasFoodSnackBarKitchenHoodSystem',
                                            value: value
                                        });
                                    }}
                                allowEdit
                            />
                        }
                        <FormInput
                            fieldCode='foodSnackBarSalesAmount'
                            label={<FormattedMessage id='What are the Annual receipts for restaurant/food/snack bar?' />}
                            value={this.props.policy.interWestFPCustomInfo.operationInfo.foodSnackBarSalesAmount}
                            type='money'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.operationInfo.foodSnackBarSalesAmount',
                                value
                            })}
                            allowEdit
                        />
                    </Form>
                }
                {(this.props.policy.interWestFPCustomInfo.operationInfo.operationSelectionSelectedItems.find(value => value?.code === 'Alcohol')) &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='alcohol'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='Alcohol' />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormChecklist
                            fieldCode='alcoholTypeSoldSelectedItems'
                            label={<FormattedMessage id='Which do you sell?' />}
                            value={this.props.policy.interWestFPCustomInfo?.operationInfo.alcoholTypeSoldSelectedItems}
                            references={this.props.reference.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].ALTypesSold}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.ALTypesSold')}
                            changeEvent={values => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.alcoholTypeSoldSelectedItems',
                                    value: values
                                });
                            }}
                            allowEdit
                        />
                        <FormDropDown
                            fieldCode='alcoholEmployeeOrVolunteersCode'
                            label={<FormattedMessage id='Are employees or volunteers responsible for serving alcohol?' />}
                            value={{ code: this.props.policy.interWestFPCustomInfo.operationInfo.alcoholEmployeeOrVolunteersCode }}
                            references={this.props.reference?.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].ALEmployeeOrVolunteer}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.ALEmployeeOrVolunteer')}
                            changeEvent={value => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.alcoholEmployeeOrVolunteersCode',
                                    value: value.code
                                });

                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.hasAlcoholEmployeeTIPSTraining',
                                    value: false
                                });
                            }}
                            allowEdit
                        />
                        {
                            this.props.policy.interWestFPCustomInfo.operationInfo.alcoholEmployeeOrVolunteersCode === 'Employees' &&
                            <FormSwitch
                                fieldCode='hasAlcoholEmployeeTIPSTraining'
                                label={<FormattedMessage id='Do your employees go through Tips training?' />}
                                value={this.props.policy.interWestFPCustomInfo.operationInfo.hasAlcoholEmployeeTIPSTraining ?? false}
                                changeEvent={
                                    value => {
                                        this.props.doReducePolicyElement({
                                            code: 'interWestFPCustomInfo.operationInfo.hasAlcoholEmployeeTIPSTraining',
                                            value: value
                                        });
                                    }}
                                allowEdit
                            />
                        }
                        <FormInput
                            fieldCode='alcoholSalesAmount'
                            label={<FormattedMessage id='What are the Gross annual alcohol sales?' />}
                            value={this.props.policy.interWestFPCustomInfo.operationInfo.alcoholSalesAmount}
                            type='money'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.operationInfo.alcoholSalesAmount',
                                value
                            })}
                            allowEdit
                        />
                    </Form>
                }
                {(this.props.policy.interWestFPCustomInfo.operationInfo.operationSelectionSelectedItems.find(value => value?.code === 'Yoga')) &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='yoga'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='Yoga' />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormChecklist
                            fieldCode='yogaDetailsSelectedItems'
                            label={<FormattedMessage id='Please check if the following are true:' />}
                            value={this.props.policy.interWestFPCustomInfo.operationInfo.yogaDetailsSelectedItems}
                            references={this.props.reference.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].YGDetails}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.YGDetails')}
                            changeEvent={values => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.yogaDetailsSelectedItems',
                                    value: values
                                });
                                if (!this.props.policy.interWestFPCustomInfo.operationInfo.yogaDetailsSelectedItems.find(value => value.code === 'Hot Yoga')) {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.operationInfo.yogaDetailsMaxTempCode',
                                        value: null
                                    });
                                }
                            }}
                            allowEdit
                        />
                        {
                            this.props.policy.interWestFPCustomInfo.operationInfo.yogaDetailsSelectedItems.find(value => value.code === 'Hot Yoga') &&
                            <FormDropDown
                                fieldCode='yogaDetailsMaxTempCode'
                                label={<FormattedMessage id='What is the max temperature (Degrees Fahrenheit)?' />}
                                value={{ code: this.props.policy.interWestFPCustomInfo.operationInfo.yogaDetailsMaxTempCode }}
                                references={this.props.reference?.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].YGMaxTemp}
                                referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.YGMaxTemp')}
                                changeEvent={value => {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestFPCustomInfo.operationInfo.yogaDetailsMaxTempCode',
                                        value: value.code
                                    });
                                }}
                                allowEdit
                            />
                        }
                        <FormDropDown
                            fieldCode='yogaAverageParticipantCountCode'
                            label={<FormattedMessage id='Average amount of participants in yoga each class?' />}
                            value={{ code: this.props.policy.interWestFPCustomInfo.operationInfo.yogaAverageParticipantCountCode }}
                            references={this.props.reference?.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].YGParticipant}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.YGParticipant')}
                            changeEvent={value => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.yogaAverageParticipantCountCode',
                                    value: value.code
                                });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {(this.props.policy.interWestFPCustomInfo.operationInfo.operationSelectionSelectedItems.find(value => value?.code === 'AerobicsDance')) &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='aerobicsDance'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='Aerobics and Dance' />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormChecklist
                            fieldCode='aerobicsDanceDetailsSelectedItems'
                            label={<FormattedMessage id='Please check if the following are true:' />}
                            value={this.props.policy.interWestFPCustomInfo.operationInfo.aerobicsDanceDetailsSelectedItems}
                            references={this.props.reference.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].ADDetails}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.ADDetails')}
                            changeEvent={values => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.aerobicsDanceDetailsSelectedItems',
                                    value: values
                                });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {(this.props.policy.interWestFPCustomInfo.operationInfo.operationSelectionSelectedItems.find(value => value?.code === 'Pilates')) &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='pilates'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='Pilates' />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormDropDown
                            fieldCode='pilatesUnitCountCode'
                            label={<FormattedMessage id='How many units in your pilates classes?' />}
                            value={{ code: this.props.policy.interWestFPCustomInfo.operationInfo.pilatesUnitCountCode }}
                            references={this.props.reference?.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].PUCount}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.PUCount')}
                            changeEvent={value => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.pilatesUnitCountCode',
                                    value: value.code
                                });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {(this.props.policy.interWestFPCustomInfo.operationInfo.operationSelectionSelectedItems.find(value => value?.code === 'Trampoline')) &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='trampoline'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='Trampoline' />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormDropDown
                            fieldCode='trampolineCountCode'
                            label={<FormattedMessage id='How many Trampolines do you have?' />}
                            value={{ code: this.props.policy.interWestFPCustomInfo.operationInfo.trampolineCountCode }}
                            references={this.props.reference?.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].TPCount}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.TPCount')}
                            changeEvent={value => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestFPCustomInfo.operationInfo.trampolineCountCode',
                                    value: value.code
                                });
                            }}
                            allowEdit
                        />
                    </Form>
                }

                {(this.props.policy.interWestFPCustomInfo.operationInfo.operationSelectionSelectedItems.find(value => value?.code === 'Other')) &&
                    <Form
                        pageCode={this.props.pageCode}
                        sectionCode='otherOperations'
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        header={<FormattedMessage id='Other Operations' />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormInput
                            fieldCode='personalTrainersCertifications'
                            label={<FormattedMessage id='List any other operations' />}
                            value={this.props.policy.interWestFPCustomInfo.operationInfo.otherOperation}
                            type='text'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFPCustomInfo.operationInfo.otherOperation',
                                value
                            })}
                            allowEdit
                        />
                    </Form>
                }
            </>
        )
    }
}

export default ProfileInterwestFpOperations