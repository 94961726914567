import React from 'react';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns'
import {
    Alignments,
    BackButton,
    Container,
    Divider,
    Form,
    FormDate,
    FormDropDown,
    FormInput,
    FormSwitch,
    Header,
    Label,
    Sizes,
    SummaryGroup
} from 'metrix-common';

import ProfileBtisWcClaim from './Claim';

class ProfileBtisWcPriorInsurance extends React.Component {
    state = {
        mode: `priorInsuranceView`,
        currentClaim: null
    };

    formDateMode = () => {
        if (this.props.priorInsurance?.priorInsuranceEndDate === undefined) {
            return 'past'
        } else {
            return ((Date.parse(this.props.priorInsurance.priorInsuranceEndDate)) - (new Date().getTime()) > 0 ? `` : `past`);
        }
    }

    getClaims = () => {
        let claims = [];

        if (this.props.claims && this.props.claims?.filter(x => x.priorInsuranceGuid === this.props.priorInsurance?.guid).length > 0) {
            this.props.claims.filter(x => x.priorInsuranceGuid === this.props.priorInsurance.guid).forEach(
                (claim) => {
                    let option =
                    {
                        code: `claimOption`, value: claim.guid
                    };
                    option.elements = [];
                    let claimsPageName = claim.date !== undefined ? format(claim.date, 'YYYY-MM-DD') : claim.claimPageName !== undefined ? claim.claimPageName : 'New Claim';
                    option.elements.push({ code: `Label`, value: claimsPageName });
                    claims.push(option);
                }
            )
        }
        return claims;
    }

    render() {
        return (

            <React.Fragment>
                {this.state.mode === `priorInsuranceView` &&
                    <React.Fragment>
                        <BackButton clickEvent={this.props.backEvent} />
                        <Divider top={Sizes.md} />
                        <Header size={Sizes.md} textAlign={Alignments.left} text={this.props.owner?.ownerTitle} />
                        <Form
                            label={`Prior Insurance details`}
                            allowEdit={this.props.allowEdit}
                            saveEvent={() => this.props.doUpsertPolicy()}
                            cancelEvent={() => this.props.doGetPolicy()}
                        >
                            <FormDate
                                label={<FormattedMessage id={`Start date of the prior insurance`} />}
                                value={this.props.priorInsurance?.priorInsuranceStartDate}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}priorInsuranceStartDate`, value })}
                                culture={this.props.context.session.culture}
                                mode={`past`}
                                allowEdit
                            />
                            <FormDate
                                label={<FormattedMessage id={`End date of the prior insurance`} />}
                                value={this.props.priorInsurance?.priorInsuranceEndDate}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}priorInsuranceEndDate`, value })}
                                mode={this.formDateMode()}
                                culture={this.props.context.session.culture}
                                allowEdit
                            />
                            <FormInput
                                label={`Carrier`}
                                value={this.props.priorInsurance?.priorInsuranceCarrierName}
                                type='text'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}priorInsuranceCarrierName`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={`Policy Number`}
                                value={this.props.priorInsurance?.priorInsurancePolicyNumber}
                                type='text'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}priorInsurancePolicyNumber`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={`Premium`}
                                value={this.props.priorInsurance?.priorInsuranceAnnualPremium}
                                type='number'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}priorInsuranceAnnualPremium`, value })}
                                allowEdit
                            />
                            <FormDropDown
                                label={<FormattedMessage id={`Lapse Reason:`} />}
                                value={this.props.priorInsurance?.reasonWhyCoverageStoppedCode && { code: this.props.priorInsurance.reasonWhyCoverageStoppedCode, value: this.props.priorInsurance?.reasonWhyCoverageStoppedName }}
                                references={this.props.reference?.references[`BTIS-WC`] && this.props.reference.references[`BTIS-WC`][`ReasonWhyCoverageStopped`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`BTIS-WC.ReasonWhyCoverageStopped`)}
                                changeEvent={(value) => {
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}reasonWhyCoverageStoppedCode`, value: value.code });
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}reasonWhyCoverageStoppedName`, value: value.value });
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}priorInsuranceLapseReasonDescription`, value: undefined });
                                }}
                                allowEdit
                            />
                            {this.props.priorInsurance?.reasonWhyCoverageStoppedCode === "other" &&
                                <FormInput
                                    label={`Please describe`}
                                    value={this.props.priorInsurance?.priorInsuranceLapseReasonDescription}
                                    type='text'
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}priorInsuranceLapseReasonDescription`, value })}
                                    allowEdit
                                />
                            }
                            <FormInput
                                label={`Number of claims`}
                                value={this.props.priorInsurance?.noOfClaimsCount}
                                type='number'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}noOfClaimsCount`, value })}
                                allowEdit
                            />
                        </Form>
                        <React.Fragment>
                            <Divider top={Sizes.xl} />
                            <Header size={Sizes.lg} textAlign={Alignments.left} text={`Claims`} />
                            <Container top={Sizes.xl} bottom={Sizes.xl}>
                                <SummaryGroup
                                    allowAdd={this.props.allowEdit && this.getClaims().length < 10}
                                    addEvent={() => {
                                        this.props.doReduceParameter({ code: `PriorInsuranceGuid`, value: this.props.priorInsurance.guid });
                                        this.props.doExecutePolicyAction({ code: `BTIS-WC.CREATECLAIM` });
                                    }}
                                    allowEdit
                                    editEvent={(claim) => {
                                        this.setState({ currentClaim: claim, mode: `claimView` });
                                    }}
                                    allowDelete={this.props.allowEdit}
                                    deleteEvent={(claim) => {
                                        this.props.doReduceParameter({ code: `ClaimGuid`, value: claim });
                                        this.props.doExecutePolicyAction({ code: `BTIS-WC.DELETECLAIM` });
                                    }}
                                    entities={this.getClaims()}
                                    icon={`person`}
                                />
                            </Container>
                        </React.Fragment>
                    </React.Fragment>
                }
                {this.state.mode === `claimView` &&
                    <ProfileBtisWcClaim
                        claim={this.props.claims.find(x => x.guid === this.state.currentClaim)}
                        dotProp={`btisWcCustomInfo.claims.${this.props.claims.findIndex(x => x.guid === this.state.currentClaim)}.`}
                        backEvent={() => this.setState({ mode: `priorInsuranceView`, currentClaim: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReduceParameter={this.props.doReduceParameter}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        doExecutePolicyAction={this.props.doExecutePolicyAction}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
            </React.Fragment>
        );
    }
}

export default ProfileBtisWcPriorInsurance;