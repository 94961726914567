import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Form,
    FormInput
} from 'metrix-common';

class ProfileInterwestGlpcContact extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            <Form
                header={<FormattedMessage id='Contact Info' />}
                label='Details'
                saveEvent={this.props.doUpsertPolicy}
                cancelEvent={this.props.doGetPolicy}
                allowEdit={this.props.allowEdit}
            >
                <FormInput
                    label={<FormattedMessage id='Fax Number' />}
                    value={this.props.policy.interWestGLPCCustomInfo.contactInfo.contactFaxNumber}
                    type='phone'
                    changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.contactInfo.contactFaxNumber', value })}
                    allowEdit
                />
            </Form>
        );
    }
}

export default ProfileInterwestGlpcContact;