import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Form,
    FormDropDown,
    FormInput, FormSwitch,
    FormDate, FormChecklist
} from 'metrix-common';

class ProfileInterwestFp2Info extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();

        const pageHasTooltipInfo = this.props.tooltipInfo && this.props.tooltipInfo.pages && this.props.tooltipInfo.pages.length && this.props.tooltipInfo.pages.some(p => p.code === this.props.pageCode);
        if (!pageHasTooltipInfo) {
            this.props.doGetTooltipInfo(this.props.policy.product);
        }
    }

    render() {
        return (
            <><Form
                pageCode={this.props.pageCode}
                sectionCode='fitPakBusinessInfoType'
                saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                tooltipInfo={this.props.tooltipInfo}
                validationInfo={this.props.validationInfo}
                header={<FormattedMessage id='Business Info' />}
                saveEvent={this.props.doUpsertPolicy}
                cancelEvent={this.props.doGetPolicy}
                allowEdit={this.props.allowEdit}
            >
                <FormInput
                    fieldCode='businessName'
                    label={<FormattedMessage id='Business Name' />}
                    value={this.props.policy.interWestFP2CustomInfo.businessName}
                    type='text'
                    changeEvent={value => this.props.doReducePolicyElement({
                        code: 'interWestFP2CustomInfo.businessName',
                        value
                    })}
                    allowEdit
                />
                <FormInput
                    fieldCode='doingBusinessAs'
                    label={<FormattedMessage id='Doing Business As' />}
                    value={this.props.policy.interWestFP2CustomInfo.doingBusinessAs}
                    type='text'
                    changeEvent={value => this.props.doReducePolicyElement({
                        code: 'interWestFP2CustomInfo.doingBusinessAs',
                        value
                    })}
                    allowEdit
                />
                <FormDropDown
                    fieldCode='entityTypesCode'
                    label={<FormattedMessage id='Business Entity Type' />}
                    value={{ code: this.props.policy.interWestFP2CustomInfo.entityTypesCode }}
                    references={this.props.reference?.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].EntityTypes}
                    referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.EntityTypes')}
                    changeEvent={value => {
                        this.props.doReducePolicyElement({
                            code: 'interWestFP2CustomInfo.entityTypesCode',
                            value: value.code
                        });
                    }}
                    allowEdit
                />
                <FormInput
                    fieldCode='website'
                    label={<FormattedMessage id='Website' />}
                    value={this.props.policy.interWestFP2CustomInfo.website}
                    type='text'
                    changeEvent={value => this.props.doReducePolicyElement({
                        code: 'interWestFP2CustomInfo.website',
                        value
                    })}
                    allowEdit
                />
                <FormInput
                    fieldCode='federalTaxIdentificationNumber'
                    label={<FormattedMessage id='Federal Employer Identification Number (FEIN)' />}
                    value={this.props.policy.interWestFP2CustomInfo.federalTaxIdentificationNumber}
                    type='number'
                    changeEvent={value => this.props.doReducePolicyElement({
                        code: 'interWestFP2CustomInfo.federalTaxIdentificationNumber',
                        value
                    })}
                    overrideMaxLength={9}
                    allowEdit
                />
                <FormInput
                    fieldCode='totalAnnualSalesAmount'
                    label={<FormattedMessage id='What are your total annual sales?' />}
                    value={this.props.policy.interWestFP2CustomInfo.totalAnnualSalesAmount}
                    type='currency'
                    changeEvent={value => this.props.doReducePolicyElement({
                        code: 'interWestFP2CustomInfo.totalAnnualSalesAmount',
                        value
                    })}
                    maxVal={99999999}
                    allowEdit
                />

                <FormInput
                    fieldCode='membershipCount'
                    label={<FormattedMessage id='How many members do you have?' />}
                    value={this.props.policy.interWestFP2CustomInfo.membershipCount}
                    type='number'
                    changeEvent={value => this.props.doReducePolicyElement({
                        code: 'interWestFP2CustomInfo.membershipCount',
                        value
                    })}
                    maxVal={999999}
                    allowEdit
                />

                <FormInput
                    fieldCode='membershipUnderEighteenCount'
                    label={<FormattedMessage id='How many members do you have under the age of 18?' />}
                    value={this.props.policy.interWestFP2CustomInfo.membershipUnderEighteenCount}
                    type='number'
                    changeEvent={value => this.props.doReducePolicyElement({
                        code: 'interWestFP2CustomInfo.membershipUnderEighteenCount',
                        value
                    })}
                    maxVal={999999}
                    allowEdit
                />

                <FormInput
                    fieldCode='yearsInBusiness'
                    label={<FormattedMessage id='How many years has your gym been open?' />}
                    value={this.props.policy.interWestFP2CustomInfo.yearsInBusiness}
                    type='number'
                    changeEvent={value => this.props.doReducePolicyElement({
                        code: 'interWestFP2CustomInfo.yearsInBusiness',
                        value
                    })}
                    maxVal={999}
                    allowEdit
                />
            </Form>

                <Form
                    pageCode={this.props.pageCode}
                    sectionCode='fitPackInsuranceInfo'
                    saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                    reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                    tooltipInfo={this.props.tooltipInfo}
                    validationInfo={this.props.validationInfo}
                    header={<FormattedMessage id='Insurance Info' />}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormDate
                        fieldCode='effectiveDate'
                        label={<FormattedMessage id={'Proposed Policy Effective Date'} />}
                        value={this.props.policy && this.props.policy.effectiveDate}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `effectiveDate`, value })}
                        culture={this.props.context.session.culture}
                        allowEdit
                    />
                    <FormSwitch
                        fieldCode='hasCurrentInsurancePolicy'
                        label={<FormattedMessage id='Do you have a current insurance policy in place?' />}
                        value={this.props.policy.interWestFP2CustomInfo.hasCurrentInsurancePolicy ?? false}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: 'interWestFP2CustomInfo.hasCurrentInsurancePolicy', value });
                            if (!value) {
                                this.props.doReducePolicyElement({ code: `interWestFP2CustomInfo.carrierName`, value: null });
                                this.props.doReducePolicyElement({ code: `interWestFP2CustomInfo.hasClaimsInLastFiveYears`, value: null });
                            }
                        }}
                        allowEdit
                    />
                    {
                        (this.props.policy.interWestFP2CustomInfo.hasCurrentInsurancePolicy) === true &&
                        <FormInput
                            fieldCode='carrierName'
                            label={<FormattedMessage id='Who is your carrier for general liability?' />}
                            value={this.props.policy.interWestFP2CustomInfo.carrierName}
                            type='text'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFP2CustomInfo.carrierName',
                                value
                            })}
                            maxVal={999999999}
                            allowEdit
                        />
                    }
                    {
                        (this.props.policy.interWestFP2CustomInfo.hasCurrentInsurancePolicy) === true &&
                        <FormSwitch
                            fieldCode='hasClaimsInLastFiveYears'
                            label={<FormattedMessage id='Have you had any liability claims in the last 5 years?' />}
                            value={this.props.policy.interWestFP2CustomInfo.hasClaimsInLastFiveYears ?? false}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: 'interWestFP2CustomInfo.hasClaimsInLastFiveYears', value })}
                            allowEdit
                        />
                    }
                    <FormSwitch
                        fieldCode='hasOccurrencesOrAllegations'
                        label={<FormattedMessage id='Are you aware of any occurrences or allegations of abuse, molestation, or sexual misconduct against your gym?' />}
                        value={this.props.policy.interWestFP2CustomInfo.hasOccurrencesOrAllegations ?? false}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: 'interWestFP2CustomInfo.hasOccurrencesOrAllegations', value })}
                        allowEdit
                    />
                    <FormSwitch
                        fieldCode='hasWrittenProcedures'
                        label={<FormattedMessage id='Do you have written procedures in place regarding the prevention and mitigation of abuse, molestation or sexual misconduct? If not, let us know and we will provide to you for free!' />}
                        value={this.props.policy.interWestFP2CustomInfo.hasWrittenProcedures ?? false}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: 'interWestFP2CustomInfo.hasWrittenProcedures', value })}
                        allowEdit
                    />
                    <FormInput
                        fieldCode='equipmentValue'
                        label={<FormattedMessage id='What is the total replacement cost of everything combined? Gym equipment, office supplies, Inventory, tenant improvements you�ve made to facility, etc.' />}
                        value={this.props.policy.interWestFP2CustomInfo.equipmentValue}
                        type='currency'
                        changeEvent={value => this.props.doReducePolicyElement({
                            code: 'interWestFP2CustomInfo.equipmentValue',
                            value
                        })}
                        maxVal={999999999}
                        allowEdit
                    />
                    <FormInput
                        fieldCode='largeEquipmentAmount'
                        label={<FormattedMessage id='How many pieces of larger equipment do you have? For example: weight machines, cardio machines, squat racks, Pilates units, Rowers, Stationary bikes' />}
                        value={this.props.policy.interWestFP2CustomInfo.largeEquipmentAmount}
                        type='number'
                        changeEvent={value => this.props.doReducePolicyElement({
                            code: 'interWestFP2CustomInfo.largeEquipmentAmount',
                            value
                        })}
                        maxVal={999999}
                        allowEdit
                    />

                    <FormSwitch
                        fieldCode='hasChildcareServices'
                        label={<FormattedMessage id='Do you have childcare services?' />}
                        value={this.props.policy.interWestFP2CustomInfo.hasChildcareServices ?? false}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: 'interWestFP2CustomInfo.hasChildcareServices', value })}
                        allowEdit
                    />

                    <FormSwitch
                        fieldCode='doEmployeesDriveTheirVehicles'
                        label={<FormattedMessage id='Do your employees drive their own vehicles for business or on behalf of your gym?' />}
                        value={this.props.policy.interWestFP2CustomInfo.doEmployeesDriveTheirVehicles ?? false}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: 'interWestFP2CustomInfo.doEmployeesDriveTheirVehicles', value })}
                        allowEdit
                    />

                </Form>
                <Form
                    pageCode={this.props.pageCode}
                    sectionCode='fitPackLocationInfomation'
                    saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                    reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                    tooltipInfo={this.props.tooltipInfo}
                    validationInfo={this.props.validationInfo}
                    header={<FormattedMessage id='Location Info' />}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormDropDown
                        fieldCode='tenantOrOwnerCode'
                        label={<FormattedMessage id='Are you a tenant or do you own your building?' />}
                        value={{ code: this.props.policy.interWestFP2CustomInfo.tenantOrOwnerCode }}
                        references={this.props.reference?.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].TenantOrOwner}
                        referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.TenantOrOwner')}
                        changeEvent={value => {
                            this.props.doReducePolicyElement({
                                code: 'interWestFP2CustomInfo.tenantOrOwnerCode',
                                value: value.code
                            });
                        }}
                        allowEdit
                    />

                    <FormInput
                        fieldCode='estimateYearOccupiedBuildingBuilt'
                        label={<FormattedMessage id='What is the estimated year built of the building you occupy?' />}
                        value={this.props.policy.interWestFP2CustomInfo.estimateYearOccupiedBuildingBuilt}
                        type='number'
                        changeEvent={value => this.props.doReducePolicyElement({
                            code: 'interWestFP2CustomInfo.estimateYearOccupiedBuildingBuilt',
                            value
                        })}
                        maxVal={9999}
                        allowEdit
                    />
                    <FormSwitch
                        fieldCode='hasBuildingSprinklerSystem'
                        label={<FormattedMessage id='Does your building have a sprinkler system?' />}
                        value={this.props.policy.interWestFP2CustomInfo.hasBuildingSprinklerSystem ?? false}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: 'interWestFP2CustomInfo.hasBuildingSprinklerSystem', value })}
                        allowEdit
                    />

                    <FormSwitch
                        fieldCode='isLocatedAtResidence'
                        label={<FormattedMessage id='Is your location at a residence?' />}
                        value={this.props.policy.interWestFP2CustomInfo.isLocatedAtResidence ?? false}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: 'interWestFP2CustomInfo.isLocatedAtResidence', value })}
                        allowEdit
                    />
                    <FormSwitch
                        fieldCode='hasDefibrillator'
                        label={<FormattedMessage id='Do you have an external defibrillator?' />}
                        value={this.props.policy.interWestFP2CustomInfo.hasDefibrillator ?? false}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: 'interWestFP2CustomInfo.hasDefibrillator', value })}
                        allowEdit
                    />
                    <FormSwitch
                        fieldCode='hasCPRAEDFirstAidCertifiedEmployee'
                        label={<FormattedMessage id='Is there at least one CPR/AED/First Aid Certified Employee on duty?' />}
                        value={this.props.policy.interWestFP2CustomInfo.hasCPRAEDFirstAidCertifiedEmployee ?? false}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: 'interWestFP2CustomInfo.hasCPRAEDFirstAidCertifiedEmployee', value })}
                        allowEdit
                    />
                    <FormSwitch
                        fieldCode='isOperatedTwentyFourHours'
                        label={<FormattedMessage id='Are you a 24-hour operation facility?' />}
                        value={this.props.policy.interWestFP2CustomInfo.isOperatedTwentyFourHours ?? false}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: 'interWestFP2CustomInfo.isOperatedTwentyFourHours', value })}
                        allowEdit
                    />

                    <FormInput
                        fieldCode='facilitySquareFootage'
                        label={<FormattedMessage id='What is the square footage of your facility?' />}
                        value={this.props.policy.interWestFP2CustomInfo.facilitySquareFootage}
                        type='number'
                        changeEvent={value => this.props.doReducePolicyElement({
                            code: 'interWestFP2CustomInfo.facilitySquareFootage',
                            value
                        })}
                        maxVal={999999}
                        allowEdit
                    />

                </Form>

                <Form
                    pageCode={this.props.pageCode}
                    sectionCode='fitPackEmployeeInfomation'
                    saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                    reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                    tooltipInfo={this.props.tooltipInfo}
                    validationInfo={this.props.validationInfo}
                    header={<FormattedMessage id='Employee Info' />}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormSwitch
                        fieldCode='hasEmployees'
                        label={<FormattedMessage id='Do you have employees?' />}
                        value={this.props.policy.interWestFP2CustomInfo.hasEmployees ?? false}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: 'interWestFP2CustomInfo.hasEmployees', value })
                            if (!value) {
                                this.props.doReducePolicyElement({ code: `interWestFP2CustomInfo.employeeCount`, value: null });
                                this.props.doReducePolicyElement({ code: `interWestFP2CustomInfo.isAllInstructorsEmployees`, value: null });
                                this.props.doReducePolicyElement({ code: `interWestFP2CustomInfo.employeePayrollAmount`, value: null });
                                this.props.doReducePolicyElement({ code: `interWestFP2CustomInfo.hasEmployeeBackgroundCheck`, value: null });
                                this.props.doReducePolicyElement({ code: `interWestFP2CustomInfo.hasEmployeeWrittenApplication`, value: null });
                            }
                        }}
                        allowEdit
                    />
                    {
                        (this.props.policy.interWestFP2CustomInfo.hasEmployees) === true &&
                        <FormInput
                            fieldCode='employeeCount'
                            label={<FormattedMessage id='How many employees do you have?' />}
                            value={this.props.policy.interWestFP2CustomInfo.employeeCount}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFP2CustomInfo.employeeCount',
                                value
                            })}
                            maxVal={999999}
                            allowEdit
                        />
                    }
                    {
                        (this.props.policy.interWestFP2CustomInfo.hasEmployees) === true &&
                        <FormSwitch
                            fieldCode='isAllInstructorsEmployees'
                            label={<FormattedMessage id='Are all instructors employees of your gym?' />}
                            value={this.props.policy.interWestFP2CustomInfo.isAllInstructorsEmployees ?? false}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: 'interWestFP2CustomInfo.isAllInstructorsEmployees', value })}
                            allowEdit
                        />
                    }
                    {
                        (this.props.policy.interWestFP2CustomInfo.hasEmployees) === true &&
                        <FormInput
                            fieldCode='employeePayrollAmount'
                            label={<FormattedMessage id='Estimated annual payroll for employees?' />}
                            value={this.props.policy.interWestFP2CustomInfo.employeePayrollAmount}
                            type='currency'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestFP2CustomInfo.employeePayrollAmount',
                                value
                            })}
                            maxVal={999999}
                            allowEdit
                        />
                    }
                    {
                        (this.props.policy.interWestFP2CustomInfo.hasEmployees) === true &&
                        <FormSwitch
                            fieldCode='hasEmployeeBackgroundCheck'
                            label={<FormattedMessage id='Do you perform background checks on incoming employees?' />}
                            value={this.props.policy.interWestFP2CustomInfo.hasEmployeeBackgroundCheck ?? false}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: 'interWestFP2CustomInfo.hasEmployeeBackgroundCheck', value })}
                            allowEdit
                        />
                    }
                    {
                        (this.props.policy.interWestFP2CustomInfo.hasEmployees) === true &&
                        <FormSwitch
                            fieldCode='hasEmployeeWrittenApplication'
                            label={<FormattedMessage id='Are written applications required?' />}
                            value={this.props.policy.interWestFP2CustomInfo.hasEmployeeWrittenApplication ?? false}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: 'interWestFP2CustomInfo.hasEmployeeWrittenApplication', value })}
                            allowEdit
                        />
                    }

                </Form>

                <Form
                    pageCode={this.props.pageCode}
                    sectionCode='fitPackAmenities'
                    saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                    reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                    tooltipInfo={this.props.tooltipInfo}
                    validationInfo={this.props.validationInfo}
                    header={<FormattedMessage id='Amenities' />}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormChecklist
                        fieldCode='amenitiesSelectedItems'
                        label={<FormattedMessage id='Please check all that apply to your business' />}
                        value={this.props.policy.interWestFP2CustomInfo.amenitiesSelectedItems}
                        references={this.props.reference.references['InterWest-FP'] && this.props.reference.references['InterWest-FP'].Operations}
                        referencesEvent={() => this.props.reference.doGetReferences('InterWest-FP.Operations')}
                        changeEvent={values => {
                            this.props.doReducePolicyElement({
                                code: 'interWestFP2CustomInfo.amenitiesSelectedItems',
                                value: values
                            });
                        }
                        }
                        allowEdit
                    />
                </Form>
            </>
        )
    }
}

export default ProfileInterwestFp2Info