import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormDropDown, FormInput, FormRadio, convertFromBoolean, convertToBoolean } from 'metrix-common';

const ProfileSupernovaCommercialInsuranceHistory = ({
    pageCode,
    doUpsertPolicy,
    doGetPolicy,
    allowEdit,
    policy,
    doReducePolicyElement,
    reference,
    validationInfo,
    tooltipInfo,
    doUpsertTooltipInfo,
    doReduceTooltipInfoElement
}) => (
    <>
        <Form
            pageCode={pageCode}
            sectionCode='insuranceHistory'
            header='Insurance History'
            tooltipInfo={tooltipInfo}
            validationInfo={validationInfo}
            saveTooltipInfo={() => doUpsertTooltipInfo(policy.product)}
            reduceTooltipInfoElement={doReduceTooltipInfoElement}
            saveEvent={doUpsertPolicy}
            cancelEvent={doGetPolicy}
            allowEdit={allowEdit}
        >
            <FormRadio
                fieldCode='snCommercialInsuranceHistory.hasClaims'
                label="Has there been any past insurance claims for your customer's business?"
                value={policy.snCommercialInsuranceHistory?.hasClaims !== undefined && { code: convertFromBoolean(policy.snCommercialInsuranceHistory.hasClaims) }}
                references={reference?.references['YesNo']}
                referencesEvent={() => reference.doGetReferences('YesNo')}
                changeEvent={(value) => doReducePolicyElement({
                    code: 'snCommercialInsuranceHistory.hasClaims',
                    value: convertToBoolean(value.code)
                })}
                allowEdit />
            <FormRadio
                fieldCode='snCommercialInsuranceHistory.hasDeniedInsurance'
                label='Has any insurance provider declined, canceled, or opted not to offer any form of coverage to the customer within the past three years?'
                value={policy.snCommercialInsuranceHistory?.hasDeniedInsurance !== undefined && { code: convertFromBoolean(policy.snCommercialInsuranceHistory.hasDeniedInsurance) }}
                references={reference?.references['YesNo']}
                referencesEvent={() => reference.doGetReferences('YesNo')}
                changeEvent={(value) => doReducePolicyElement({
                    code: 'snCommercialInsuranceHistory.hasDeniedInsurance',
                    value: convertToBoolean(value.code)
                })}
                allowEdit />
            <FormRadio
                fieldCode='snCommercialInsuranceHistory.hasNonPaymentInsurance'
                label='Has the customer missed any insurance premium payments in the past three years?'
                value={policy.snCommercialInsuranceHistory?.hasNonPaymentInsurance !== undefined && { code: convertFromBoolean(policy.snCommercialInsuranceHistory.hasNonPaymentInsurance) }}
                references={reference?.references['YesNo']}
                referencesEvent={() => reference.doGetReferences('YesNo')}
                changeEvent={(value) => doReducePolicyElement({
                    code: 'snCommercialInsuranceHistory.hasNonPaymentInsurance',
                    value: convertToBoolean(value.code)
                })}
                allowEdit />
        </Form>
        {
            policy.snCommercialInsuranceHistory.hasClaims &&
            <Form
                pageCode={pageCode}
                sectionCode='claimsHistory'
                header='Claims History'
                tooltipInfo={tooltipInfo}
                validationInfo={validationInfo}
                saveTooltipInfo={() => doUpsertTooltipInfo(policy.product)}
                reduceTooltipInfoElement={doReduceTooltipInfoElement}
                saveEvent={doUpsertPolicy}
                cancelEvent={doGetPolicy}
                allowEdit={allowEdit}
            >
                <FormDropDown
                    fieldCode='snCommercialInsuranceHistory.claimsInLastThreeYearsNumberCode'
                    label="How many insurance claims has your customer's business made in the past three years?"
                    value={{ code: policy.snCommercialInsuranceHistory.claimsInLastThreeYearsNumberCode }}
                    references={reference?.references['sn-commercial']?.NumberOfClaims}
                    referencesEvent={() => reference.doGetReferences('sn-commercial.NumberOfClaims')}
                    changeEvent={value => doReducePolicyElement({
                        code: 'snCommercialInsuranceHistory.claimsInLastThreeYearsNumberCode',
                        value: value.code
                    })}
                    allowEdit
                />
            </Form>
        }
        {
            (policy.snCommercialInsuranceHistory.hasClaims && policy.snCommercialInsuranceHistory.claimsInLastThreeYearsNumberCode >= 1) &&
            <Form
                pageCode={pageCode}
                sectionCode='claimOneDetails'
                header='Claim One Details'
                tooltipInfo={tooltipInfo}
                validationInfo={validationInfo}
                saveTooltipInfo={() => doUpsertTooltipInfo(policy.product)}
                reduceTooltipInfoElement={doReduceTooltipInfoElement}
                saveEvent={doUpsertPolicy}
                cancelEvent={doGetPolicy}
                allowEdit={allowEdit}
            >
                <FormInput
                    fieldCode='snCommercialInsuranceHistory.claimOneYear'
                    label='Claim Year'
                    value={policy.snCommercialInsuranceHistory.claimOneYear}
                    changeEvent={value => doReducePolicyElement({
                        code: 'snCommercialInsuranceHistory.claimOneYear',
                        value: value
                    })}
                    type='number'
                    allowEdit
                />
                <FormDropDown
                    fieldCode='snCommercialInsuranceHistory.claimOneTypeCode'
                    label='Claim Type'
                    value={{ code: policy.snCommercialInsuranceHistory.claimOneTypeCode }}
                    references={reference?.references['sn-commercial']?.ClaimType}
                    referencesEvent={() => reference.doGetReferences('sn-commercial.ClaimType')}
                    changeEvent={value => doReducePolicyElement({
                        code: 'snCommercialInsuranceHistory.claimOneTypeCode',
                        value: value.code
                    })}
                    allowEdit
                />
                <FormInput
                    fieldCode='snCommercialInsuranceHistory.claimOnePriorInsurer'
                    label='Insurance Company'
                    value={policy.snCommercialInsuranceHistory.claimOnePriorInsurer}
                    changeEvent={value => doReducePolicyElement({
                        code: 'snCommercialInsuranceHistory.claimOnePriorInsurer',
                        value
                    })}
                    type='text'
                    allowEdit
                />
                <FormInput
                    fieldCode='snCommercialInsuranceHistory.claimOneAmount'
                    label='Claim Amount'
                    value={policy.snCommercialInsuranceHistory.claimOneAmount}
                    changeEvent={value => doReducePolicyElement({
                        code: 'snCommercialInsuranceHistory.claimOneAmount',
                        value
                    })}
                    type='currency'
                    allowEdit
                />
            </Form>
        }
        {
            (policy.snCommercialInsuranceHistory.hasClaims && policy.snCommercialInsuranceHistory.claimsInLastThreeYearsNumberCode >= 2) &&
            <Form
                pageCode={pageCode}
                sectionCode='claimTwoDetails'
                header='Claim Two Details'
                tooltipInfo={tooltipInfo}
                validationInfo={validationInfo}
                saveTooltipInfo={() => doUpsertTooltipInfo(policy.product)}
                reduceTooltipInfoElement={doReduceTooltipInfoElement}
                saveEvent={doUpsertPolicy}
                cancelEvent={doGetPolicy}
                allowEdit={allowEdit}
            >
                <FormInput
                    fieldCode='snCommercialInsuranceHistory.claimTwoYear'
                    label='Claim Year'
                    value={policy.snCommercialInsuranceHistory.claimTwoYear}
                    changeEvent={value => doReducePolicyElement({
                        code: 'snCommercialInsuranceHistory.claimTwoYear',
                        value
                    })}
                    type='number'
                    allowEdit
                />
                <FormDropDown
                    fieldCode='snCommercialInsuranceHistory.claimTwoTypeCode'
                    label='Claim Type'
                    value={{ code: policy.snCommercialInsuranceHistory.claimTwoTypeCode }}
                    references={reference?.references['sn-commercial']?.ClaimType}
                    referencesEvent={() => reference.doGetReferences('sn-commercial.ClaimType')}
                    changeEvent={value => doReducePolicyElement({
                        code: 'snCommercialInsuranceHistory.claimTwoTypeCode',
                        value: value.code
                    })}
                    allowEdit
                />
                <FormInput
                    fieldCode='snCommercialInsuranceHistory.claimTwoPriorInsurer'
                    label='Insurance Company'
                    value={policy.snCommercialInsuranceHistory.claimTwoPriorInsurer}
                    changeEvent={value => doReducePolicyElement({
                        code: 'snCommercialInsuranceHistory.claimTwoPriorInsurer',
                        value
                    })}
                    type='text'
                    allowEdit
                />
                <FormInput
                    fieldCode='snCommercialInsuranceHistory.claimTwoAmount'
                    label='Claim Amount'
                    value={policy.snCommercialInsuranceHistory.claimTwoAmount}
                    changeEvent={value => doReducePolicyElement({
                        code: 'snCommercialInsuranceHistory.claimTwoAmount',
                        value
                    })}
                    type='currency'
                    allowEdit
                />
            </Form>
        }
        {
            (policy.snCommercialInsuranceHistory.hasClaims && policy.snCommercialInsuranceHistory.claimsInLastThreeYearsNumberCode >= 3) &&
            <Form
                pageCode={pageCode}
                sectionCode='claimThreeDetails'
                header='Claim Three Details'
                tooltipInfo={tooltipInfo}
                validationInfo={validationInfo}
                saveTooltipInfo={() => doUpsertTooltipInfo(policy.product)}
                reduceTooltipInfoElement={doReduceTooltipInfoElement}
                saveEvent={doUpsertPolicy}
                cancelEvent={doGetPolicy}
                allowEdit={allowEdit}
            >
                <FormInput
                    fieldCode='snCommercialInsuranceHistory.claimThreeYear'
                    label='Claim Year'
                    value={policy.snCommercialInsuranceHistory.claimThreeYear}
                    changeEvent={value => doReducePolicyElement({
                        code: 'snCommercialInsuranceHistory.claimThreeYear',
                        value
                    })}
                    type='number'
                    allowEdit
                />
                <FormDropDown
                    fieldCode='snCommercialInsuranceHistory.claimThreeTypeCode'
                    label='Claim Type'
                    value={{ code: policy.snCommercialInsuranceHistory.claimThreeTypeCode }}
                    references={reference?.references['sn-commercial']?.ClaimType}
                    referencesEvent={() => reference.doGetReferences('sn-commercial.ClaimType')}
                    changeEvent={value => doReducePolicyElement({
                        code: 'snCommercialInsuranceHistory.claimThreeTypeCode',
                        value: value.code
                    })}
                    allowEdit
                />
                <FormInput
                    fieldCode='snCommercialInsuranceHistory.claimThreePriorInsurer'
                    label='Insurance Company'
                    value={policy.snCommercialInsuranceHistory.claimThreePriorInsurer}
                    changeEvent={value => doReducePolicyElement({
                        code: 'snCommercialInsuranceHistory.claimThreePriorInsurer',
                        value
                    })}
                    type='text'
                    allowEdit
                />
                <FormInput
                    fieldCode='snCommercialInsuranceHistory.claimThreeAmount'
                    label='Claim Amount'
                    value={policy.snCommercialInsuranceHistory.claimThreeAmount}
                    changeEvent={value => doReducePolicyElement({
                        code: 'snCommercialInsuranceHistory.claimThreeAmount',
                        value
                    })}
                    type='currency'
                    allowEdit
                />
            </Form>
        }
    </>
);

export default ProfileSupernovaCommercialInsuranceHistory;

ProfileSupernovaCommercialInsuranceHistory.propTypes = {
    policy: PropTypes.object,
    pageCode: PropTypes.string,
    doGetPolicy: PropTypes.func,
    doUpsertPolicy: PropTypes.func,
    allowEdit: PropTypes.bool,
    reference: PropTypes.object,
    validationInfo: PropTypes.object,
    tooltipInfo: PropTypes.object,
    doReducePolicyElement: PropTypes.func,
    doReduceTooltipInfoElement: PropTypes.func,
    doUpsertTooltipInfo: PropTypes.func
};