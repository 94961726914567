import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    convertToBoolean,
    convertFromBoolean,
    setAttributeEntity,
    Sizes,
    Alignments,
    Container,
    Divider,
    Header,
    BackButton,
    Form,
    AddressForm,
    FormInput,
    FormRadio,
    FormDropDown,
    SummaryGroup
} from 'metrix-common';

import ProfileDwellingWaterMitigationSystem from './WaterMitigationSystem';
import ProfileDwellingHeatingSystem from './HeatingSystem';
import ProfileDwellingGarage from './Garage';
import ProfileDwellingSwimmingPool from './SwimmingPool';
import ProfileDwellingScheduledItem from './ScheduledItem';
import ProfileDwellingImprovement from './Improvement';
import ProfileDwellingLossPayee from './LossPayee';
import ProfileDwellingAnimal from './Animal';

class ProfileDwelling extends React.Component {
    state = {
        mode: `dwelling`,
        current: null,
    };

    getDwellingAnimals = () => {
        let dwellingAnimals = [];

        if (this.props.dwelling &&
            this.props.dwelling.animals &&
            this.props.dwelling.animals.length > 0) {
            this.props.dwelling.animals.forEach((animal) => {
                let option = { code: animal.type && animal.type.value, value: animal.guid };

                let animalName = `New Animal`;

                if (animal.type &&
                    animal.type.value) {
                    animalName = `${animal.type.value}`;

                    if (animal.breed &&
                        animal.breed.value) {
                        animalName = `${animalName} (${animal.breed.value})`;
                    }
                }

                option.elements = [];

                option.elements.push({ code: `Label`, value: animalName });

                dwellingAnimals.push(option);
            });
        }

        return dwellingAnimals;
    }

    getDwellingHeatingSystems = () => {
        let heatingSystems = [];

        if (this.props.dwelling &&
            this.props.dwelling.heatingSystems &&
            this.props.dwelling.heatingSystems.length > 0) {
            this.props.dwelling.heatingSystems.forEach((heatingSystem) => {
                let option = { code: heatingSystem.type && heatingSystem.type.value, value: heatingSystem.guid };

                let heatingSystemName = `New Heating System`;

                if (heatingSystem.type) {
                    heatingSystemName = `${heatingSystem.type.value}`;
                }

                if (`${heatingSystem.primary}` === `${true}`) {
                    heatingSystemName = `${heatingSystemName} (Primary)`;
                }

                option.elements = [];

                option.elements.push({ code: `Label`, value: heatingSystemName });

                heatingSystems.push(option);
            });
        }

        return heatingSystems;
    }

    getDwellingWaterMitigationSystems = () => {
        let waterMitigationSystems = [];

        if (this.props.dwelling &&
            this.props.dwelling.protection &&
            this.props.dwelling.protection.waterMitigations &&
            this.props.dwelling.protection.waterMitigations.length > 0) {
            this.props.dwelling.protection.waterMitigations.forEach((waterMitigation) => {
                let option = { code: waterMitigation.sumpPumpType && waterMitigation.sumpPumpType.value, value: waterMitigation.guid };

                let waterMitigationSystemName = `New Water Mitigation System`;

                if (waterMitigation.sumpPumpType) {
                    waterMitigationSystemName = `${waterMitigation.sumpPumpType.value}`;
                }

                if (`${waterMitigation.primary}` === `${true}`) {
                    waterMitigationSystemName = `${waterMitigationSystemName} (Primary)`;
                }

                option.elements = [];

                option.elements.push({ code: `Label`, value: waterMitigationSystemName });

                waterMitigationSystems.push(option);
            });
        }

        return waterMitigationSystems;
    }

    getDwellingGarages = () => {
        let garages = [];

        if (this.props.dwelling &&
            this.props.dwelling.garages &&
            this.props.dwelling.garages.length > 0) {
            this.props.dwelling.garages.forEach((garage) => {
                let option = { code: garage.type && garage.type.value, value: garage.guid };

                let garageName = `New Garage`;

                if (garage.type) {
                    garageName = `${garage.type.value}`;
                }

                option.elements = [];

                option.elements.push({ code: `Label`, value: garageName });

                garages.push(option);
            });
        }

        return garages;
    }

    getDwellingSwimmingPools = () => {
        let swimmingPools = [];

        if (this.props.dwelling &&
            this.props.dwelling.swimmingPools &&
            this.props.dwelling.swimmingPools.length > 0) {
            this.props.dwelling.swimmingPools.forEach((swimmingPool) => {
                let option = { code: swimmingPool.type && swimmingPool.type.value, value: swimmingPool.guid };

                let swimmingPoolName = `New Swimming Pool`;

                if (swimmingPool.type) {
                    swimmingPoolName = `${swimmingPool.type.value}`;
                }

                option.elements = [];

                option.elements.push({ code: `Label`, value: swimmingPoolName });

                swimmingPools.push(option);
            });
        }

        return swimmingPools;
    }

    getDwellingScheduledItems = () => {
        let scheduledItems = [];

        if (this.props.dwelling &&
            this.props.dwelling.scheduledItems &&
            this.props.dwelling.scheduledItems.length > 0) {
            this.props.dwelling.scheduledItems.forEach((scheduledItem) => {
                let option = { code: scheduledItem.type && scheduledItem.type.value, value: scheduledItem.guid };

                let scheduledItemName = `New Scheduled Item`;

                if (scheduledItem.type) {
                    scheduledItemName = `${scheduledItem.type.value}`;
                }

                option.elements = [];

                option.elements.push({ code: `Label`, value: scheduledItemName });

                scheduledItems.push(option);
            });
        }

        return scheduledItems;
    }

    getDwellingLossPayees = () => {
        let lossPayees = [];

        if (this.props.dwelling &&
            this.props.dwelling.lossPayees &&
            this.props.dwelling.lossPayees.length > 0) {
            this.props.dwelling.lossPayees.forEach((lossPayee) => {
                let option = { code: lossPayee.name, value: lossPayee.guid };

                let lossPayeeName = `New Loss Payee`;

                if (lossPayee.value) {
                    lossPayeeName = `${lossPayee.value}`;
                }

                option.elements = [];

                option.elements.push({ code: `Label`, value: lossPayeeName });

                lossPayees.push(option);
            });
        }

        return lossPayees;
    }

    getDwellingImprovements = () => {
        let improvements = [];

        if (this.props.dwelling &&
            this.props.dwelling.improvement &&
            this.props.dwelling.improvement.otherImprovements &&
            this.props.dwelling.improvement.otherImprovements.length > 0) {
            this.props.dwelling.improvement.otherImprovements.forEach((improvement) => {
                let option = { code: improvement.name, value: improvement.guid };

                let improvementName = `New Improvement`;

                if (improvement.otherImprovement) {
                    improvementName = `${improvement.otherImprovement.value}`;
                }

                option.elements = [];

                option.elements.push({ code: `Label`, value: improvementName });

                improvements.push(option);
            });
        }

        return improvements;
    }

    render() {
        let dwellingName = (this.props.dwelling && this.props.dwelling.construction && this.props.dwelling.construction.yearBuilt && this.props.dwelling.construction.type) ? `${this.props.dwelling.construction.yearBuilt} ${this.props.dwelling.construction.type.value}` : `Dwelling ${this.props.dwelling && this.props.dwelling.number}`;

        return (
            <React.Fragment>
                {this.state.mode === `dwelling` &&
                    <React.Fragment>
                        <BackButton clickEvent={this.props.backEvent} />
                        <Divider top={Sizes.md} />
                        <Header size={Sizes.md} textAlign={Alignments.left} text={dwellingName} />
                        <Form
                            label={`Dwelling Details`}
                            allowEdit={this.props.allowEdit}
                            saveEvent={() => this.props.doUpsertPolicy()}
                            cancelEvent={() => this.props.doGetPolicy()}
                        >
                            <FormInput
                                label={<FormattedMessage id={`Number`} />}
                                value={this.props.dwelling &&
                                    this.props.dwelling.number}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}number`, value })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Principal Unit`} />}
                                value={{ code: convertFromBoolean(this.props.dwelling && this.props.dwelling.principalUnit || false) }}
                                references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}principalUnit`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Replacement Cost`} />}
                                value={this.props.dwelling &&
                                    this.props.dwelling.replacementCost && this.props.dwelling.replacementCost.value}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}replacementCost.value`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Number of Loss Payees`} />}
                                value={this.props.dwelling && this.props.dwelling && this.props.dwelling.numberOfLossPayees}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}numberOfLossPayees`, value })}
                                allowEdit
                            />
                        </Form>
                    <AddressForm
                            {...this.props}
                            enableAddressSearch
                            addressSearchCountryCode={`USA`}
                            label={`Dwelling Address`}
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            reference={this.props.reference}
                            changeEvent={(dotProp, value) => {
                                dotProp === `address` ?
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}address`, value })
                                    :
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}address.${dotProp}`, value })
                            }}
                            allowEdit
                            address={this.props.dwelling &&
                                this.props.dwelling.address
                            }
                        />
                        <Form
                            label={`Construction`}
                            allowEdit={this.props.allowEdit}
                            saveEvent={() => this.props.doUpsertPolicy()}
                            cancelEvent={() => this.props.doGetPolicy()}
                        >
                            <FormDropDown
                                label={<FormattedMessage id={`Dwelling Type`} />}
                                value={this.props.dwelling && this.props.dwelling.construction && this.props.dwelling.construction.type}
                                references={this.props.reference.references && this.props.reference.references[`DwellingType`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`DwellingType`)}
                                changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}construction.type`, value, this.props.doReducePolicyElement)}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Year Built`} />}
                                value={this.props.dwelling && this.props.dwelling.construction && this.props.dwelling.construction.yearBuilt}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}construction.yearBuilt`, value })}
                                allowEdit
                            />
                            <FormDropDown
                                label={<FormattedMessage id={`Wall Framing`} />}
                                value={this.props.dwelling &&
                                    this.props.dwelling.construction &&
                                    this.props.dwelling.construction.wallFramingType
                                }
                                references={this.props.reference.references && this.props.reference.references[`WallFinish`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`WallFinish`)}
                                changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}construction.wallFramingType`, value, this.props.doReducePolicyElement)}
                                allowEdit
                            />
                            <FormDropDown
                                label={<FormattedMessage id={`Wall Exterior`} />}
                                value={this.props.dwelling &&
                                    this.props.dwelling.construction &&
                                    this.props.dwelling.construction.wallExteriorType
                                }
                                references={this.props.reference.references && this.props.reference.references[`ExteriorWallFinish`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`ExteriorWallFinish`)}
                                changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}construction.wallExteriorType`, value, this.props.doReducePolicyElement)}
                                allowEdit
                            />
                            <FormDropDown
                                label={<FormattedMessage id={`Roof Material`} />}
                                value={this.props.dwelling &&
                                    this.props.dwelling.construction &&
                                    this.props.dwelling.construction.roofingMaterials &&
                                    this.props.dwelling.construction.roofingMaterials.length > 0 &&
                                    this.props.dwelling.construction.roofingMaterials[0] &&
                                    this.props.dwelling.construction.roofingMaterials[0].type &&
                                    this.props.dwelling.construction.roofingMaterials[0].type
                                }
                                references={this.props.reference.references && this.props.reference.references[`RoofType`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`RoofType`)}
                                changeEvent={(value) => {
                                    if (this.props.dwelling &&
                                        this.props.dwelling.construction &&
                                        this.props.dwelling.construction.roofingMaterials &&
                                        this.props.dwelling.construction.roofingMaterials.length > 0 &&
                                        this.props.dwelling.construction.roofingMaterials[0] &&
                                        this.props.dwelling.construction.roofingMaterials[0].type) {
                                        setAttributeEntity(`${this.props.dotProp}construction.roofingMaterials.0.type`, value, this.props.doReducePolicyElement);
                                    } else {
                                        this.props.doReducePolicyElement({
                                            code: `${this.props.dotProp}construction.roofingMaterials`, value: [{ type: { code: value.code, value: value.value } }]
                                        });
                                    }
                                }}
                                allowEdit
                            />
                            <FormDropDown
                                label={<FormattedMessage id={`Roof Renovation Type`} />}
                                value={this.props.dwelling &&
                                    this.props.dwelling.improvement &&
                                    this.props.dwelling.improvement.roofingImprovement
                                }
                                references={this.props.reference.references && this.props.reference.references[`RenovationType`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`RenovationType`)}
                                changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}improvement.roofingImprovement`, value, this.props.doReducePolicyElement)}
                                allowEdit
                            />
                            {(this.props.dwelling &&
                                this.props.dwelling.improvement &&
                                this.props.dwelling.improvement.roofingImprovement &&
                                (this.props.dwelling.improvement.roofingImprovement.code === undefined ||
                                    this.props.dwelling.improvement.roofingImprovement.value !== `None`)) &&
                                <FormInput
                                    label={<FormattedMessage id={`Roof Renovation Year`} />}
                                    value={this.props.dwelling && this.props.dwelling.improvement && this.props.dwelling.improvement.roofingImprovementYear}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}improvement.roofingImprovementYear`, value })}
                                    allowEdit
                                />
                            }
                            <FormInput
                                label={<FormattedMessage id={`Number of Units`} />}
                                value={this.props.dwelling && this.props.dwelling.construction && this.props.dwelling.construction.numberOfUnits}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}construction.numberOfUnits`, value })}
                                allowEdit
                            />
                            <FormDropDown
                                label={<FormattedMessage id={`Number of Stories`} />}
                                value={this.props.dwelling && this.props.dwelling.construction && this.props.dwelling.construction.numberOfStories}
                                references={this.props.reference.references && this.props.reference.references[`Storeys`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`Storeys`)}
                                changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}construction.numberOfStories`, value, this.props.doReducePolicyElement)}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Number of Bathrooms`} />}
                                value={this.props.dwelling && this.props.dwelling.construction && this.props.dwelling.construction.numberOfBathrooms}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}construction.numberOfBathrooms`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Total Living Area`} />}
                                value={this.props.dwelling &&
                                    this.props.dwelling.construction &&
                                    this.props.dwelling.construction.totalLivingArea &&
                                    this.props.dwelling.construction.totalLivingArea.value
                                }
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}construction.totalLivingArea.value`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Finished Basement %`} />}
                                value={this.props.dwelling &&
                                    this.props.dwelling.construction &&
                                    this.props.dwelling.construction.basementFinishedPercentage}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}construction.basementFinishedPercentage`, value })}
                                allowEdit
                            />
                        </Form>
                        <Form
                            label={`Occupancy`}
                            allowEdit={this.props.allowEdit}
                            saveEvent={() => this.props.doUpsertPolicy()}
                            cancelEvent={() => this.props.doGetPolicy()}
                        >
                            <FormDropDown
                                label={<FormattedMessage id={`Occupancy Type`} />}
                                value={this.props.dwelling && this.props.dwelling.occupancy && this.props.dwelling.occupancy.type}
                                references={this.props.reference.references && this.props.reference.references[`OccupancyType`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`OccupancyType`)}
                                changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}occupancy.type`, value, this.props.doReducePolicyElement)}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Number of Families`} />}
                                value={this.props.dwelling && this.props.dwelling.occupancy && this.props.dwelling.occupancy.numberOfFamilies}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}occupancy.numberOfFamilies`, value })}
                                allowEdit
                            />
                            <FormDropDown
                                label={<FormattedMessage id={`Business Activity`} />}
                                value={this.props.dwelling && this.props.dwelling.occupancy && this.props.dwelling.occupancy.residenceBusinessType}
                                references={this.props.reference.references && this.props.reference.references[`BusinessType`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`BusinessType`)}
                                changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}occupancy.residenceBusinessType`, value, this.props.doReducePolicyElement)}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Smoke Free`} />}
                                value={this.props.dwelling && this.props.dwelling.occupancy &&
                                    { code: convertFromBoolean(this.props.dwelling.occupancy.smokeFree) }}
                                references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}occupancy.smokeFree`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                        </Form>
                        <Form
                            label={`Electrical System`}
                            allowEdit={this.props.allowEdit}
                            saveEvent={() => this.props.doUpsertPolicy()}
                            cancelEvent={() => this.props.doGetPolicy()}
                        >
                            <FormDropDown
                                label={<FormattedMessage id={`Wiring Type`} />}
                                value={this.props.dwelling && this.props.dwelling.construction && this.props.dwelling.construction.wiringType}
                                references={this.props.reference.references && this.props.reference.references[`WiringType`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`WiringType`)}
                                changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}construction.wiringType`, value, this.props.doReducePolicyElement)}
                                allowEdit
                            />
                            <FormDropDown
                                label={<FormattedMessage id={`Panel Type`} />}
                                value={this.props.dwelling && this.props.dwelling.construction && this.props.dwelling.construction.electricalPanelType}
                                references={this.props.reference.references && this.props.reference.references[`ElectricalPanel`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`ElectricalPanel`)}
                                changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}construction.electricalPanelType`, value, this.props.doReducePolicyElement)}
                                allowEdit
                            />
                            <FormDropDown
                                label={<FormattedMessage id={`Strength`} />}
                                value={this.props.dwelling && this.props.dwelling.construction && this.props.dwelling.construction.electricalStrength && { code: `${this.props.dwelling.construction.electricalStrength.value}` }}
                                references={this.props.reference.references && this.props.reference.references[`Ampers`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`Ampers`)}
                                changeEvent={(value) => {
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}construction.electricalStrength.value`, value: value.code });
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}construction.electricalStrength.type`, value: `A` });
                                }}
                                allowEdit
                            />
                            <FormDropDown
                                label={<FormattedMessage id={`Wiring Renovation Type`} />}
                                value={this.props.dwelling &&
                                    this.props.dwelling.improvement &&
                                    this.props.dwelling.improvement.wiringImprovement
                                }
                                references={this.props.reference.references && this.props.reference.references[`RenovationType`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`RenovationType`)}
                                changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}improvement.wiringImprovement`, value, this.props.doReducePolicyElement)}
                                allowEdit
                            />
                            {(this.props.dwelling &&
                                this.props.dwelling.improvement &&
                                this.props.dwelling.improvement.wiringImprovement &&
                                (this.props.dwelling.improvement.wiringImprovement.code === undefined ||
                                    this.props.dwelling.improvement.wiringImprovement.value !== `None`)) &&
                                <FormInput
                                    label={<FormattedMessage id={`Wiring Renovation Year`} />}
                                    value={this.props.dwelling && this.props.dwelling.improvement && this.props.dwelling.improvement.wiringImprovementYear}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}improvement.wiringImprovementYear`, value })}
                                    allowEdit
                                />
                            }
                        </Form>
                        <Form
                            label={`Water Heating System`}
                            allowEdit={this.props.allowEdit}
                            saveEvent={() => this.props.doUpsertPolicy()}
                            cancelEvent={() => this.props.doGetPolicy()}
                        >
                            <FormInput
                                label={<FormattedMessage id={`Water Tank Year`} />}
                                value={this.props.dwelling && this.props.dwelling.waterHeatingSystem && this.props.dwelling.waterHeatingSystem.year}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}waterHeatingSystem.year`, value })}
                                allowEdit
                            />
                            <FormDropDown
                                label={<FormattedMessage id={`Water Pipe Material`} />}
                                value={this.props.dwelling && this.props.dwelling.construction && this.props.dwelling.construction.plumbingType}
                                references={this.props.reference.references && this.props.reference.references[`PlumbingType`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`PlumbingType`)}
                                changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}construction.plumbingType`, value, this.props.doReducePolicyElement)}
                                allowEdit
                            />
                            <FormDropDown
                                label={<FormattedMessage id={`Plumbing Renovation Type`} />}
                                value={this.props.dwelling &&
                                    this.props.dwelling.improvement &&
                                    this.props.dwelling.improvement.plumbingImprovement
                                }
                                references={this.props.reference.references && this.props.reference.references[`RenovationType`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`RenovationType`)}
                                changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}improvement.plumbingImprovement`, value, this.props.doReducePolicyElement)}
                                allowEdit
                            />
                            {(this.props.dwelling &&
                                this.props.dwelling.improvement &&
                                this.props.dwelling.improvement.plumbingImprovement &&
                                (this.props.dwelling.improvement.plumbingImprovement.code === undefined ||
                                    this.props.dwelling.improvement.plumbingImprovement.value !== `None`)) &&
                                <FormInput
                                    label={<FormattedMessage id={`Wiring Renovation Year`} />}
                                    value={this.props.dwelling && this.props.dwelling.improvement && this.props.dwelling.improvement.plumbingImprovementYear}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}improvement.plumbingImprovementYear`, value })}
                                    allowEdit
                                />
                            }
                            <FormRadio
                                label={<FormattedMessage id={`Septic Tank`} />}
                                value={this.props.dwelling && this.props.dwelling.construction && { code: convertFromBoolean(this.props.dwelling.construction.septicTank) }}
                                references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}construction.septicTank`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                        </Form>
                        <Form
                            label={`Protection Systems`}
                            allowEdit={this.props.allowEdit}
                            saveEvent={() => this.props.doUpsertPolicy()}
                            cancelEvent={() => this.props.doGetPolicy()}
                        >
                            <FormInput
                                label={<FormattedMessage id={`Distance to Fire Hydrant (m)`} />}
                                value={this.props.dwelling &&
                                    this.props.dwelling.protection &&
                                    this.props.dwelling.protection.distanceToHydrant &&
                                    this.props.dwelling.protection.distanceToHydrant.value
                                }
                                changeEvent={(value) => {
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}protection.distanceToHydrant.value`, value });
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}protection.distanceToHydrant.type`, value: `m` });
                                }}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Distance to Fire Station (km)`} />}
                                value={this.props.dwelling &&
                                    this.props.dwelling.protection &&
                                    this.props.dwelling.protection.distanceToFireStation &&
                                    this.props.dwelling.protection.distanceToFireStation.value
                                }
                                changeEvent={(value) => {
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}protection.distanceToFireStation.value`, value });
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}protection.distanceToFireStation.type`, value: `km` });
                                }}
                                allowEdit
                            />
                            <FormDropDown
                                label={<FormattedMessage id={`Fire Alarm`} />}
                                value={this.props.dwelling && this.props.dwelling.protection && this.props.dwelling.protection.fireDevice}
                                references={this.props.reference.references && this.props.reference.references[`FireDevice`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`FireDevice`)}
                                changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}protection.fireDevice`, value, this.props.doReducePolicyElement)}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Number of Smoke Detectors`} />}
                                value={this.props.dwelling &&
                                    this.props.dwelling.protection &&
                                    this.props.dwelling.protection.numberOfSmokeAlarms
                                }
                                changeEvent={(value) => {
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}protection.numberOfSmokeAlarms`, value });
                                }}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Sprinklers`} />}
                                value={this.props.dwelling && this.props.dwelling.protection && { code: convertFromBoolean(this.props.dwelling.protection.sprinklers) }}
                                references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}protection.sprinklers`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            {this.props.dwelling && this.props.dwelling.protection && this.props.dwelling.protection.sprinklers === true &&
                                <FormRadio
                                    label={<FormattedMessage id={`Sprinkler Coverage`} />}
                                    value={this.props.dwelling && this.props.dwelling.protection && this.props.dwelling.protection.sprinklersCoverage}
                                    references={this.props.reference.references && this.props.reference.references[`RenovationType`]}
                                    referencesEvent={() => this.props.reference.doGetReferences(`RenovationType`)}
                                    changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}protection.sprinklersCoverage`, value, this.props.doReducePolicyElement)}
                                    allowEdit
                                />
                            }
                            <FormDropDown
                                label={<FormattedMessage id={`Burglary Alarm`} />}
                                value={this.props.dwelling && this.props.dwelling.protection && this.props.dwelling.protection.burglarDevice}
                                references={this.props.reference.references && this.props.reference.references[`BurglaryDevice`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`BurglaryDevice`)}
                                changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}protection.burglarDevice`, value, this.props.doReducePolicyElement)}
                                allowEdit
                            />
                        </Form>
                        
                        <React.Fragment>
                            <Divider top={Sizes.xl} />
                            <Header size={Sizes.lg} textAlign={Alignments.left} text={`Water Mitigation Systems`} />
                            <Container top={Sizes.xl} bottom={Sizes.xl}>
                                <SummaryGroup
                                    allowAdd={this.props.allowEdit}
                                    addEvent={() => {
                                        this.props.doReduceParameter({ code: `Dwelling`, value: this.props.dwelling.guid });
                                        this.props.doExecutePolicyAction({ code: `CREATEDWELLINGWATERMITIGATION` });
                                    }}
                                    allowEdit
                                    editEvent={(waterMitigationSystem) => {
                                        this.setState({ current: waterMitigationSystem, mode: `waterMitigationSystem` });
                                    }}
                                    allowDelete={this.props.allowEdit}
                                    deleteEvent={(waterMitigationSystem) => {
                                        this.props.doReduceParameter({ code: `Dwelling`, value: this.props.dwelling.guid });
                                        this.props.doReduceParameter({ code: `DwellingWaterMitigation`, value: waterMitigationSystem });
                                        this.props.doExecutePolicyAction({ code: `DELETEDWELLINGWATERMITIGATION` });
                                        this.setState({ current: null, mode: `dwelling` });
                                    }}
                                    entities={this.getDwellingWaterMitigationSystems()}
                                    icon={`waterMitigation`}
                                />
                                
                            </Container>
                        </React.Fragment>
                        <React.Fragment>
                            <Divider top={Sizes.xl} />
                            <Header size={Sizes.lg} textAlign={Alignments.left} text={`Heating Systems`} />
                            <Container top={Sizes.xl} bottom={Sizes.xl}>
                                <SummaryGroup
                                    allowAdd={this.props.allowEdit}
                                    addEvent={() => {
                                        this.props.doReduceParameter({ code: `Dwelling`, value: this.props.dwelling.guid });
                                        this.props.doExecutePolicyAction({ code: `CREATEDWELLINGHEATINGSYSTEM` });
                                    }}
                                    allowEdit
                                    editEvent={(heatingSystem) => {
                                        this.setState({ current: heatingSystem, mode: `heatingSystem` });
                                    }}
                                    allowDelete={this.props.allowEdit}
                                    deleteEvent={(heatingSystem) => {
                                        this.props.doReduceParameter({ code: `Dwelling`, value: this.props.dwelling.guid });
                                        this.props.doReduceParameter({ code: `DwellingHeatingSystem`, value: heatingSystem });
                                        this.props.doExecutePolicyAction({ code: `DELETEDWELLINGHEATINGSYSTEM` });
                                        this.setState({ current: null, mode: `dwelling` });
                                    }}
                                    entities={this.getDwellingHeatingSystems()}
                                    icon={`heatingSystem`}
                                />
                            </Container>
                        </React.Fragment>
                        <React.Fragment>
                            <Divider top={Sizes.xl} />
                            <Header size={Sizes.lg} textAlign={Alignments.left} text={`Garages`} />
                            <Container top={Sizes.xl} bottom={Sizes.xl}>
                                <SummaryGroup
                                    allowAdd={this.props.allowEdit}
                                    addEvent={() => {
                                        this.props.doReduceParameter({ code: `Dwelling`, value: this.props.dwelling.guid });
                                        this.props.doExecutePolicyAction({ code: `CREATEDWELLINGGARAGE` });
                                    }}
                                    allowEdit
                                    editEvent={(garage) => {
                                        this.setState({ current: garage, mode: `garage` });
                                    }}
                                    allowDelete={this.props.allowEdit}
                                    deleteEvent={(garage) => {
                                        this.props.doReduceParameter({ code: `Dwelling`, value: this.props.dwelling.guid });
                                        this.props.doReduceParameter({ code: `DwellingGarage`, value: garage });
                                        this.props.doExecutePolicyAction({ code: `DELETEDWELLINGGARAGE` });
                                        this.setState({ current: null, mode: `dwelling` });
                                    }}
                                    entities={this.getDwellingGarages()}
                                    icon={`garage`}
                                />
                            </Container>
                        </React.Fragment>
                        <React.Fragment>
                            <Divider top={Sizes.xl} />
                            <Header size={Sizes.lg} textAlign={Alignments.left} text={`Swimming Pools`} />
                            <Container top={Sizes.xl} bottom={Sizes.xl}>
                                <SummaryGroup
                                    allowAdd={this.props.allowEdit}
                                    addEvent={() => {
                                        this.props.doReduceParameter({ code: `Dwelling`, value: this.props.dwelling.guid });
                                        this.props.doExecutePolicyAction({ code: `CREATEDWELLINGSWIMMINGPOOL` });
                                    }}
                                    allowEdit
                                    editEvent={(swimmingPool) => {
                                        this.setState({ current: swimmingPool, mode: `swimmingPool` });
                                    }}
                                    allowDelete={this.props.allowEdit}
                                    deleteEvent={(swimmingPool) => {
                                        this.props.doReduceParameter({ code: `Dwelling`, value: this.props.dwelling.guid });
                                        this.props.doReduceParameter({ code: `DwellingSwimmingPool`, value: swimmingPool });
                                        this.props.doExecutePolicyAction({ code: `DELETEDWELLINGSWIMMINGPOOL` });
                                        this.setState({ current: null, mode: `dwelling` });
                                    }}
                                    entities={this.getDwellingSwimmingPools()}
                                    icon={`swimmingPool`}
                                />
                            </Container>
                        </React.Fragment>
                        <React.Fragment>
                            <Divider top={Sizes.xl} />
                            <Header size={Sizes.lg} textAlign={Alignments.left} text={`Scheduled Items`} />
                            <Container top={Sizes.xl} bottom={Sizes.xl}>
                                <SummaryGroup
                                    allowAdd={this.props.allowEdit}
                                    addEvent={() => {
                                        this.props.doReduceParameter({ code: `Dwelling`, value: this.props.dwelling.guid });
                                        this.props.doExecutePolicyAction({ code: `CREATEDWELLINGSCHEDULEDITEM` });
                                    }}
                                    allowEdit
                                    editEvent={(scheduledItem) => {
                                        this.setState({ current: scheduledItem, mode: `scheduledItem` });
                                    }}
                                    allowDelete={this.props.allowEdit}
                                    deleteEvent={(scheduledItem) => {
                                        this.props.doReduceParameter({ code: `Dwelling`, value: this.props.dwelling.guid });
                                        this.props.doReduceParameter({ code: `DwellingScheduledItem`, value: scheduledItem });
                                        this.props.doExecutePolicyAction({ code: `DELETEDWELLINGSCHEDULEDITEM` });
                                        this.setState({ current: null, mode: `dwelling` });
                                    }}
                                    entities={this.getDwellingScheduledItems()}
                                    icon={`scheduledItem`}
                                />
                            </Container>
                        </React.Fragment>
                        <React.Fragment>
                            <Divider top={Sizes.xl} />
                            <Header size={Sizes.lg} textAlign={Alignments.left} text={`Improvements`} />
                            <Container top={Sizes.xl} bottom={Sizes.xl}>
                                <SummaryGroup
                                    allowAdd={this.props.allowEdit}
                                    addEvent={() => {
                                        this.props.doReduceParameter({ code: `Dwelling`, value: this.props.dwelling.guid });
                                        this.props.doExecutePolicyAction({ code: `CREATEDWELLINGOTHERIMPROVEMENT` });
                                    }}
                                    allowEdit
                                    editEvent={(improvement) => {
                                        this.setState({ current: improvement, mode: `improvement` });
                                    }}
                                    allowDelete={this.props.allowEdit}
                                    deleteEvent={(improvement) => {
                                        this.props.doReduceParameter({ code: `Dwelling`, value: this.props.dwelling.guid });
                                        this.props.doReduceParameter({ code: `DwellingOtherImprovement`, value: improvement });
                                        this.props.doExecutePolicyAction({ code: `DELETEDWELLINGOTHERIMPROVEMENT` });
                                        this.setState({ current: null, mode: `dwelling` });
                                    }}
                                    entities={this.getDwellingImprovements()}
                                    icon={`highValueImprovement`}
                                />
                            </Container>
                        </React.Fragment>
                        <React.Fragment>
                            <Divider top={Sizes.xl} />
                            <Header size={Sizes.lg} textAlign={Alignments.left} text={`Loss Payees`} />
                            <Container top={Sizes.xl} bottom={Sizes.xl}>
                                <SummaryGroup
                                    allowAdd={this.props.allowEdit}
                                    addEvent={() => {
                                        this.props.doReduceParameter({ code: `Dwelling`, value: this.props.dwelling.guid });
                                        this.props.doExecutePolicyAction({ code: `CREATEDWELLINGLOSSPAYEE` });
                                    }}
                                    allowEdit
                                    editEvent={(lossPayee) => {
                                        this.setState({ current: lossPayee, mode: `lossPayee` });
                                    }}
                                    allowDelete={this.props.allowEdit}
                                    deleteEvent={(lossPayee) => {
                                        this.props.doReduceParameter({ code: `Dwelling`, value: this.props.dwelling.guid });
                                        this.props.doReduceParameter({ code: `DwellingLossPayee`, value: lossPayee });
                                        this.props.doExecutePolicyAction({ code: `DELETEDWELLINGLOSSPAYEE` });
                                        this.setState({ current: null, mode: `dwelling` });
                                    }}
                                    entities={this.getDwellingLossPayees()}
                                    icon={`mortgage`}
                                />
                            </Container>
                        </React.Fragment>
                        <React.Fragment>
                            <Divider top={Sizes.xl} />
                            <Header size={Sizes.lg} textAlign={Alignments.left} text={`Animals`} />
                            <Container top={Sizes.xl} bottom={Sizes.xl}>
                                <SummaryGroup
                                    allowAdd={this.props.allowEdit}
                                    addEvent={() => {
                                        this.props.doReduceParameter({ code: `Dwelling`, value: this.props.dwelling.guid });
                                        this.props.doExecutePolicyAction({ code: `CREATEDWELLINGANIMAL` });
                                    }}
                                    allowEdit
                                    editEvent={(animal) => {
                                        this.setState({ current: animal, mode: `animal` });
                                    }}
                                    allowDelete={this.props.allowEdit}
                                    deleteEvent={(animal) => {
                                        this.props.doReduceParameter({ code: `Dwelling`, value: this.props.dwelling.guid });
                                        this.props.doReduceParameter({ code: `DwellingAnimal`, value: animal });
                                        this.props.doExecutePolicyAction({ code: `DELETEDWELLINGANIMAL` });
                                        this.setState({ current: null, mode: `dwelling` });
                                    }}
                                    entities={this.getDwellingAnimals()}
                                    icon={`pet`}
                                />
                            </Container>
                        </React.Fragment>
                    </React.Fragment>
                }
                {this.state.mode === `waterMitigationSystem` &&
                    <ProfileDwellingWaterMitigationSystem
                        waterMitigationSystem={this.props.dwelling.protection.waterMitigations.find(x => x.guid === this.state.current)}
                        allowPrimary={!this.props.dwelling.protection.waterMitigations.some(x => x.primary === true) || this.props.dwelling.protection.waterMitigations.find(x => x.guid === this.state.current).primary}
                        dotProp={`${this.props.dotProp}protection.waterMitigations.${this.props.dwelling.protection.waterMitigations.findIndex(x => x.guid === this.state.current)}.`}
                        backEvent={() => this.setState({ mode: `dwelling`, current: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
                {this.state.mode === `heatingSystem` &&
                    <ProfileDwellingHeatingSystem
                        heatingSystem={this.props.dwelling.heatingSystems.find(x => x.guid === this.state.current)}
                        allowPrimary={!this.props.dwelling.heatingSystems.some(x => x.primary === true) || this.props.dwelling.heatingSystems.find(x => x.guid === this.state.current).primary}
                        improvement={this.props.dwelling && this.props.dwelling.improvement}
                        dotProp={`${this.props.dotProp}heatingSystems.${this.props.dwelling.heatingSystems.findIndex(x => x.guid === this.state.current)}.`}
                        baseDotProp={this.props.dotProp}
                        backEvent={() => this.setState({ mode: `dwelling`, current: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
                {this.state.mode === `garage` &&
                    <ProfileDwellingGarage
                        garage={this.props.dwelling.garages.find(x => x.guid === this.state.current)}
                        dotProp={`${this.props.dotProp}garages.${this.props.dwelling.garages.findIndex(x => x.guid === this.state.current)}.`}
                        backEvent={() => this.setState({ mode: `dwelling`, current: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
                {this.state.mode === `swimmingPool` &&
                    <ProfileDwellingSwimmingPool
                        swimmingPool={this.props.dwelling.swimmingPools.find(x => x.guid === this.state.current)}
                        dotProp={`${this.props.dotProp}swimmingPools.${this.props.dwelling.swimmingPools.findIndex(x => x.guid === this.state.current)}.`}
                        backEvent={() => this.setState({ mode: `dwelling`, current: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
                {this.state.mode === `improvement` &&
                    <ProfileDwellingImprovement
                        improvement={this.props.dwelling.improvement.otherImprovements.find(x => x.guid === this.state.current)}
                        dotProp={`${this.props.dotProp}improvement.otherImprovements.${this.props.dwelling.improvement.otherImprovements.findIndex(x => x.guid === this.state.current)}.`}
                        backEvent={() => this.setState({ mode: `dwelling`, current: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
                {this.state.mode === `scheduledItem` &&
                    <ProfileDwellingScheduledItem
                        scheduledItem={this.props.dwelling.scheduledItems.find(x => x.guid === this.state.current)}
                        dotProp={`${this.props.dotProp}scheduledItems.${this.props.dwelling.scheduledItems.findIndex(x => x.guid === this.state.current)}.`}
                        backEvent={() => this.setState({ mode: `dwelling`, current: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
                {this.state.mode === `lossPayee` &&
                    <ProfileDwellingLossPayee
                        lossPayee={this.props.dwelling.lossPayees.find(x => x.guid === this.state.current)}
                        dotProp={`${this.props.dotProp}lossPayees.${this.props.dwelling.lossPayees.findIndex(x => x.guid === this.state.current)}`}
                        backEvent={() => this.setState({ mode: `dwelling`, current: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
                {this.state.mode === `animal` &&
                    <ProfileDwellingAnimal
                        animal={this.props.dwelling.animals.find(x => x.guid === this.state.current)}
                        dotProp={`${this.props.dotProp}animals.${this.props.dwelling.animals.findIndex(x => x.guid === this.state.current)}.`}
                        backEvent={() => this.setState({ mode: `dwelling`, current: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
            </React.Fragment>
        );
    }
}

export default ProfileDwelling;