import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    BulletList, Colors,
    Form, FormDate,
    FormInput,
    FormRadio, getFormattedCurrency, Sizes
} from 'metrix-common';

class ProfilePayment extends React.Component {
    state = {
        showPopup: false,
    }
    componentDidMount() {
        this.props.doGetPolicy();
        this.props.doGetPaymentTransferResultRecords();
    }

    handleTransferFieldChange = (fieldCode, value) => {
        const updatedRecord = {
            ...this.props.paymentTransferResultRecords[0],
            [fieldCode]: value
        };

        this.props.doReducePaymentTransferResultRecords([updatedRecord]);
    }

    render() {
        const { policy, context } = this.props;
        const culture = context && context.session.culture;
        const payment = policy && policy.payment;

        if (!payment) {
            return (
                <Form
                    header={<FormattedMessage id='app.components.ProfileSections.PaymentSection.sectionHeader' />}
                    label={<FormattedMessage id='app.components.ProfileSections.PaymentSection.formHeader' />}
                    allowEdit={false}
                >
                    <FormInput
                        label='Payment Mode'
                        value=''
                        allowEdit={false}
                    />
                    <FormInput
                        label='Payment Status'
                        value=''
                        allowEdit={false}
                    />
                    <FormInput
                        label='Payment details'
                        value=''
                        allowEdit={false}
                    />
                    <FormInput
                        label={'Payment Amount'}
                        value=''
                        allowEdit={false}
                    />
                </Form>
            )
        }

        const paymentMode = (payment && payment.paymentMode && payment.paymentMode !== 'none') ? payment.paymentMode : null;
        const currency = payment && payment.currencyCode;

        const isFullPayment = paymentMode === 'fullPayment';
        const isPartialPayment = paymentMode === 'installmentPayment';

        const fullPaymentSuccessfull = isFullPayment && payment.creditCardFullChargeSuccessful;
        const fullPaymentAmount = getFormattedCurrency(payment.creditCardFullChargeAmount, currency, culture);

        const downPaymentReceived = isPartialPayment && payment.creditCardPartialChargeSuccessful;
        const bankAccountInfoCaptured = isPartialPayment && payment.initiateDebitSetupIntentSuccessful;
        const monthlySubscriptionCreated = isPartialPayment && payment.debitSetupIntentSuccessful;
        const downPaymentCharged = getFormattedCurrency(payment.creditCardFullChargeAmount, currency, culture);
        const monthlyAmount = getFormattedCurrency(payment.debitCycleChargeAmount, currency, culture);
        const startDate = payment.debitCycleChargeDay;

        const isSuccess = fullPaymentSuccessfull || (downPaymentReceived || bankAccountInfoCaptured || monthlySubscriptionCreated);
        let paymentDetails;
        if (isFullPayment) paymentDetails = 'Payment Completed for full Amount';
        if (isPartialPayment) {
            if (downPaymentReceived) paymentDetails = 'Downpayment Received';
            if (bankAccountInfoCaptured) paymentDetails = 'The process to capture the bank account information was successful';
            if (monthlySubscriptionCreated) paymentDetails = 'The monthly subscription has been successfully created';
        }

        const failedReasonDebit1 = payment.initiateDebitSetupIntentFailedReason;
        const failedReasonDebit2 = payment.debitSetupIntentFailedReason;
        const cumulativeFailedReason =
            (failedReasonDebit1 && failedReasonDebit2) ? `${failedReasonDebit1}; ${failedReasonDebit2}` :
                (failedReasonDebit1) ? failedReasonDebit1 :
                    (failedReasonDebit2) ? failedReasonDebit2 :
                        '';

        const paymentTransferResultReadOnly = this.props.paymentTransferResultReadOnly;
        const isPaymentTransferResultHidden = this.props.paymentTransferResultHidden && this.props.context.session.userType === 'broker';
        const paymentTransferResultErrors = this.props.paymentTransferResultErrors?.map(error => ({ ...error, value: error.code }));

        const handleSavePaymentTransfer = () => {
            if (validate()) {
                this.props.doCreatePaymentTransferResultRecord();
            } else {
                this.setState({ showPopup: true });
            }
        };

        const handleCancelPaymentTransfer = () => {
            this.props.doGetPaymentTransferResultRecords();
        };

        const validate = () => {
            return !Number.isNaN(parseFloat(this.props.paymentTransferResultRecords[0]?.transferedAmount)) && this.props.paymentTransferResultRecords[0]?.transferedOn && this.props.paymentTransferResultRecords[0]?.transferReference;
        }

        return (
            <React.Fragment>
                {
                    (this.props.policy.product === 'employers-wc' || this.props.policy.product === 'employers-wc-2') &&
                    <Form
                        header={<FormattedMessage id={`app.components.ProfileSections.PaymentSection.sectionHeader`} />}
                        label={<FormattedMessage id={`app.components.ProfileSections.PaymentSection.formHeader`} />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormRadio
                            label={<FormattedMessage id={`app.components.ProfileSections.PaymentSection.paymentPlan`} />}
                            value={this.props.policy.employersWcCustomInfo?.employersWcPaymentPlanSettings && { code: this.props.policy.employersWcCustomInfo.employersWcPaymentPlanSettings.paymentPlanTypeCode }}
                            references={this.props.reference?.references[`CUSTOMREFERENCE|EMPLOYERS-WC|${this.props.policy.guid}|PAYMENTPLANS|${this.props.policy.employersWcCustomInfo?.employersWcPaymentPlanSettings?.currentPaymentPlanVersion}`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`CUSTOMREFERENCE|EMPLOYERS-WC|${this.props.policy.guid}|PAYMENTPLANS|${this.props.policy.employersWcCustomInfo?.employersWcPaymentPlanSettings?.currentPaymentPlanVersion}`)}
                            changeEvent={
                                (value) => {
                                    this.props.doReducePolicyElement({ code: 'employersWcCustomInfo.employersWcPaymentPlanSettings.paymentPlanTypeCode', value: value.code })
                                    this.props.doReducePolicyElement({ code: 'employersWcCustomInfo.employersWcPaymentPlanSettings.paymentPlanTypeValue', value: value.value });
                                }
                            }
                            allowEdit
                            inline={false}
                        />
                    </Form>
                }
                <Form
                    header={<FormattedMessage id="app.components.ProfileSections.PaymentSection.sectionHeader" />}
                    label={<FormattedMessage id="app.components.ProfileSections.PaymentSection.formHeader" />}
                    allowEdit={false}
                >
                    {
                        paymentMode &&
                        <FormInput
                            label='Payment Mode'
                            value={payment.paymentModeText}
                            allowEdit={false}
                        />
                    }
                    {
                        paymentMode &&
                        <FormInput
                            label='Payment Status'
                            value={isSuccess ? 'Success' : 'Failed'}
                            allowEdit={false}
                        />
                    }
                    {
                        paymentMode && isSuccess &&
                        <FormInput
                            label='Payment details'
                            value={paymentDetails}
                            allowEdit={false}
                        />
                    }
                    {
                        (paymentMode && isFullPayment) &&
                        <FormInput
                            label={'Payment Amount'}
                            value={fullPaymentAmount}
                            allowEdit={false}
                        />
                    }
                    {
                        (paymentMode && isPartialPayment) &&
                        <React.Fragment>
                            {
                                payment.creditCardFullChargeAmount &&
                                <FormInput
                                    label='Down payment Amount'
                                    value={downPaymentCharged}
                                    allowEdit={false}
                                />
                            }
                            {
                                payment.debitCycleChargeAmount &&
                                <FormInput
                                    label={'Monthly Amount'}
                                    value={monthlyAmount}
                                    allowEdit={false}
                                />
                            }
                            {
                                startDate &&
                                <FormInput
                                    label={'Start Date of Monthly charges'}
                                    value={startDate}
                                    allowEdit={false}
                                />
                            }
                        </React.Fragment>
                    }
                    {
                        !isSuccess &&
                        <React.Fragment>
                            {
                                cumulativeFailedReason &&
                                <FormInput
                                    label='Failed Reason for Debit'
                                    value={cumulativeFailedReason}
                                    allowEdit={false}
                                />
                            }
                        </React.Fragment>
                    }
                </Form>
                {
                    !isPaymentTransferResultHidden &&
                    <React.Fragment>
                        <Form
                            header={'Payment Transfer'}
                            label={'Transfer Details'}
                            sectionCode='paymentTransfer'
                            saveEvent={handleSavePaymentTransfer}
                            cancelEvent={handleCancelPaymentTransfer}
                            pageCode={this.props.pageCode}
                            saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                            reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                            tooltipInfo={this.props.tooltipInfo}
                            validationInfo={this.props.validationInfo}
                            allowEdit={!paymentTransferResultReadOnly}
                            validate={validate}
                        >
                            {
                                (paymentTransferResultErrors && paymentTransferResultErrors.length > 0) &&
                                <BulletList size={Sizes.md} color={Colors.Error} references={paymentTransferResultErrors} bottom={Sizes.md} />
                            }
                            <FormInput
                                label={'Transferred Amount'}
                                fieldCode='transferedAmount'
                                value={this.props.paymentTransferResultRecords[0]?.transferedAmount || ''}
                                type='text'
                                changeEvent={value => this.handleTransferFieldChange('transferedAmount', value)}
                                allowEdit
                            />
                            <FormDate
                                label={'Transferred Date'}
                                fieldCode='transferedOn'
                                value={this.props.paymentTransferResultRecords[0]?.transferedOn || ''}
                                changeEvent={value => this.handleTransferFieldChange('transferedOn', value)}
                                culture={this.props.context.session.culture}
                                allowEdit
                            />
                            <FormInput
                                label={'Transfer Reference Code'}
                                fieldCode='transferReference'
                                value={this.props.paymentTransferResultRecords[0]?.transferReference || ''}
                                type='text'
                                changeEvent={value => this.handleTransferFieldChange('transferReference', value)}
                                allowEdit
                            />
                        </Form>
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default ProfilePayment;