import { put, call, select } from 'redux-saga/effects';
import { reduceOperation, reduceLoading, getSessionSaga } from 'metrix-common';
import { reduceNotes } from '../../actions/policy/reduceNotes';
import { reduceSingleNote } from '../../actions/policy/reduceSingleNote';
import { selectEntity } from '../../selectors/policy/selectEntity';

export function* getNotesSaga(action) {
    try {
        yield put(reduceLoading({ code: 'getNotes', value: true }));

        const session = yield getSessionSaga();
        const entity = yield select(selectEntity());

        const noteGuid = action.payload ? action.payload.noteGuid : undefined;
        let url = `/api/policyTransaction/notes/${entity}`;
        if (noteGuid) url += `/${noteGuid}`;

        const response = yield call(fetch, url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Authorization': session.authenticationToken.token,
                'Content-Type': 'application/json'
            }
        });

        const result = yield call([response, 'json']);

        yield put(reduceOperation(result.operation));

        if (result.operation && result.operation.status) {
            const notes = result.notes;

            if (noteGuid) {
                yield put(reduceSingleNote(notes));
            } else {
                yield put(reduceNotes(notes));
            }
        }
    } catch (error) {
        console.log('Error while calling Get Notes API - ', error);
    } finally {
        yield put(reduceLoading({ code: 'getNotes', value: false }));
    }
}