import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
    Alignments,
    Splash,
    Colors,
    Container
} from 'metrix-common';

const HeroContainer = styled(Container)`
    min-height: ${({ isMobile }) => isMobile ? `185` : `300`}px;
    width: ${({ isMobile }) => isMobile ? `375px` : `100%`};
    max-width: 960px;
    margin: 0 auto;
`;

class Hero extends Component {
    getHero = () => {
        if (this.context.screen.isMobile) {
            return (
                <svg width="200" height="250" version="1.1" xmlns="http://www.w3.org/2000/svg">

                    <rect x="200" y="200" rx="200" ry="200" width="30" height="30" stroke="#CCCCCC" fill="#CCCCCC" stroke-width="5" />

                </svg>

            );
        } else {
            return (
                <svg width="200" height="250" version="1.1" xmlns="http://www.w3.org/2000/svg">

                    <rect x="200" y="200" rx="200" ry="200" width="30" height="30" stroke="#CCCCCC" fill="#CCCCCC" stroke-width="5" />

                </svg>

            );
        }
    }

    render() {
        return (
            <Splash background={this.getHero()}>
                <HeroContainer fullSize
                    isMobile={this.context.screen.isMobile}
                    verticalAlignment={Alignments.center}
                    horizontalAlignment={Alignments.center}
                >
                    {this.props.children}
                </HeroContainer>
            </Splash>
        );
    }
}

Hero.contextTypes = {
    screen: PropTypes.object
};

export default Hero;