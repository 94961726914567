import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
    Sizes,
    Alignments,
    Colors,
    Grid,
    Row,
    Column,
    Container,
    Label,
    DiscountBadge
} from 'metrix-common';

const DiscountsContainer = styled(Container)`
    border: 0px;
	margin-top: 30px;
    background: ${Colors.LightMode};
    border: 1px solid ${Colors.Primary};
    justify-content: center;
`;

const DiscountContainer = styled(Container)`
    max-height: 85px;
    min-height: 85px; 
`;

const DiscountLabel = styled(Label)`
    max-height: 13px;
`;

const CornerRibbon = styled(Container)`
    background: ${Colors.Primary};
    top: ${({ mobile }) => mobile ? `93px` : `95px`};
    left: ${({ mobile }) => mobile ? `-33px` : `-31px`};;
    text-align: center;
    line-height: ${({ mobile }) => mobile ? `15px` : `20px`};
    border: 1px solid ${Colors.Primary};
  
    color: #f0f0f0;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    position: relative;
    box-shadow: 0 0 3px rgba(0,0,0,.3);
    max-width: 106px;
    min-width: 106px;
`;

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

class ProfileDiscounts extends React.Component {
    getDiscountCodes = () => {
        let allCodes = [];

        if (this.props.discounts && this.props.discounts.length > 0) {
            let uniqueCodes = this.props.discounts.map((discount) => discount.code).filter(onlyUnique);

            let singleCodes = [];
            uniqueCodes.forEach((x) => {
                switch (x) {
                    case 'DISCF':
                    case 'DISCO':
                        singleCodes.push(`DISCF`);
                        break;
                    case 'DISMP':
                        singleCodes.push(`DISMP`);
                        break;
                    case 'DISMV':
                        singleCodes.push(`DISMV`);
                        break;
                    case 'DISNB':
                        singleCodes.push(`DISNB`);
                        break;
                    case 'DISNC':
                    case 'DISNO':
                        singleCodes.push(`DISNC`);
                    case 'DISSN':
                    case 'ZDSWT':
                        singleCodes.push(`DISSN`);
                        break;
                    case 'ZDSAB':
                        singleCodes.push(`ZDSAB`);
                        break;
                    default:
                        singleCodes.push(`GENERIC`);
                        break;
                }
            });

            return singleCodes.sort().filter(onlyUnique)
        }
        else {
            return allCodes;
        }

        return allCodes;
    }

    getDiscountBadge = (discount, i, mobile, columnSize) => {
        if (mobile === true && i >= 4) return null;

        let label1 = `Carrier`;
        let label2 = `Discount`;

        switch (discount) {
            case 'DISCF':
            case 'DISCO':
                label1 = `Conviction`;
                label2 = `Free`;
                break;
            case 'DISMP':
                label1 = `Multi`;
                label2 = `Line`;
                break;
            case 'DISMV':
                label1 = `Multi`;
                label2 = `Vehicle`;
                break;
            case 'DISNB':
                label1 = `New`;
                label2 = `Customer`;
                break;
            case 'DISNC':
            case 'DISNO':
                label1 = `No`;
                label2 = `Claims`;
                break;
            case 'DISSN':
            case 'ZDSWT':
                label1 = `Winter`;
                label2 = `Tires`;
                break;
            case 'ZDSAB':
                label1 = `Autonomous`;
                label2 = `Braking`;
                break;
        }

        return (
            <Column xs={columnSize} nogutter>
                <DiscountContainer horizontalAlignment={Alignments.center} top={Sizes.xs} bottom={Sizes.xs} left={mobile ? Sizes.sm : Sizes.none}>
                    <DiscountBadge code={discount} size={48} />
                    <DiscountLabel size={Sizes.none} fontSize={12} lineHeight={13} textAlign={Alignments.center} text={`[b]${label1}[b]`} />
                    <DiscountLabel size={Sizes.none} fontSize={12} lineHeight={13} textAlign={Alignments.center} text={`[b]${label2}[b]`} />
                </DiscountContainer>
            </Column>);
    }

    render() {
        const isMobile = this.context.screen.isMobile;

        let showDiscounts = this.props.discounts && this.props.discounts.length > 0;
        let discountCodes = showDiscounts && this.getDiscountCodes();
        let discountColumns = showDiscounts && (isMobile ? Math.min(4, discountCodes.length) : discountCodes.length);

        return (
            <React.Fragment>
                <Container>
                    <CornerRibbon mobile={isMobile}>
                        <Label text={` Discounts `}
                            size={`custom`}
                            fontSize={isMobile ? `12` : `14`}
                            lineHeight={isMobile ? `17` : `21`}
                            inverse />
                    </CornerRibbon>
                    <DiscountsContainer horizontalAlignment={Alignments.center} verticalAlignment={Alignments.center} left={Sizes.xs} right={Sizes.xs}>
                        <Grid>
                            <Row nogutter>
                                {discountCodes.map((discount, i) => this.getDiscountBadge(discount, i, isMobile, 12 / discountColumns))}
                            </Row>
                        </Grid>
                    </DiscountsContainer>
                </Container>
            </React.Fragment>
        );
    }
}

ProfileDiscounts.contextTypes = {
    screen: PropTypes.object
};

export default ProfileDiscounts;
