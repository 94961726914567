import React, { useEffect } from "react";

import {
	Container
} from 'metrix-common';

import TriageHeader from './Header';
import TriageResults from './Results';
import TriageFooter from './Footer';

const Triage = (props) => {
	useEffect(() => {
		props.doReduceView('triage');
		
		if (props.query.triage.search) {
			props.doQueryTriagePolicies();			
		} else {
			props.doGetTriagePolicies();			
        }
	}, [props.query.triage.search]);

	return (
		<Container>
			<TriageHeader {...props} />
			<TriageFooter {...props} />
			<TriageResults {...props} />
			<TriageFooter {...props} />
		</Container>
	);
}

export default Triage;