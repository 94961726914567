import { put, call, select } from 'redux-saga/effects';
import { reduceOperation, reduceLoading, getSessionSaga } from 'metrix-common';
import { selectEntity } from '../../selectors/policy/selectEntity';
import { reducePaymentTransferResultReadOnly } from '../../actions/payment/reducePaymentTransferResultReadOnly';
import { reducePaymentTransferResultRecords } from '../../actions/payment/reducePaymentTransferResultRecords';
import { reducePaymentTransferResultErrors } from '../../actions/payment/reducePaymentTransferResultErrors';
import { reducePaymentTransferResultHidden } from '../../actions/payment/reducePaymentTransferResultHidden';

export function* getPaymentTransferResultRecordsSaga() {
    try {
        yield put(reduceLoading({ code: 'getPaymentTransferResultRecords', value: true }));
        const session = yield getSessionSaga();
        const entity = yield select(selectEntity());

        const response = yield call(fetch, `/api/PaymentTransfer/${entity}`, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Authorization': session.authenticationToken.token,
                'Content-Type': 'application/json'
            }
        });
        
        const result = yield call([response, 'json']);

	    if (result.operation.status) {
            yield put(reducePaymentTransferResultRecords(result.paymentTransferResultRecords));
            yield put(reducePaymentTransferResultReadOnly(result.isReadOnly));
            yield put(reducePaymentTransferResultErrors([]));
            yield put(reducePaymentTransferResultHidden(result.isPaymentTransfersSectionHidden));
        }
        
        yield put(reduceOperation(result.operation));
    }
    catch (error) {
        console.log('Error while calling getting Payment Transfer Records API - ', error);
    }
    finally {
        yield put(reduceLoading({ code: 'getPaymentTransferResultRecords', value: false }));
    }
}
