import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    convertFromBoolean,
    convertToBoolean,
    setAttributeEntity,
    Sizes,
    Alignments,
    Container,
    Divider,
    Header,
    BackButton,
    Form,
    NameForm,
    PersonForm,
    AddressForm,
    FormInput,
    FormRadio,
    FormDropDown,
    FormDate,
    SummaryGroup
} from 'metrix-common';

import ProfileResidentCriminalConviction from './CriminalConviction';
import ProfileResidentClaim from './Claim';
import ProfileResidentCancellationReason from './CancellationReason';

class ProfileResident extends React.Component {
    state = {
        mode: `resident`,
        current: null
    };

    getResidentCriminalConvictions = () => {
        let residentCriminalConvictions = [];

        if (this.props.resident &&
            this.props.resident.criminalConvictions &&
            this.props.resident.criminalConvictions.length > 0) {
            this.props.resident.criminalConvictions.forEach((criminalConviction) => {
                let option = { code: criminalConviction.reasonDescription, value: criminalConviction.guid };

                let criminalConvictionName = `New Criminal Conviction`;

                if (criminalConviction.reason &&
                    criminalConviction.reason.value) {
                    criminalConvictionName = `${criminalConviction.reason.value}`;
                }

                option.elements = [];

                option.elements.push({ code: `Label`, value: criminalConvictionName });

                residentCriminalConvictions.push(option);
            });
        }

        return residentCriminalConvictions;
    }

    getResidentClaims = () => {
        let residentClaims = [];

        if (this.props.resident &&
            this.props.resident.claims &&
            this.props.resident.claims.length > 0) {
            this.props.resident.claims.forEach((claim) => {
                let option = { code: claim.reasonDescription, value: claim.guid };

                let claimName = `New Claim`;

                if (claim.reason &&
                    claim.reason.value) {
                    claimName = `${claim.reason.value}`;
                }

                option.elements = [];

                option.elements.push({ code: `Label`, value: claimName });

                residentClaims.push(option);
            });
        }

        return residentClaims;
    }

    getResidentCancellationReasons = () => {
        let residentCancellationReasons = [];

        if (this.props.resident &&
            this.props.resident.cancellationReasons &&
            this.props.resident.cancellationReasons.length > 0) {
            this.props.resident.cancellationReasons.forEach((cancellationReason) => {
                let option = { code: cancellationReason.reasonDescription, value: cancellationReason.guid };

                let cancellationReasonName = `New Cancellation Reason`;

                if (cancellationReason.reason &&
                    cancellationReason.reason.value) {
                    cancellationReasonName = `${cancellationReason.reason.value}`;
                }

                option.elements = [];

                option.elements.push({ code: `Label`, value: cancellationReasonName });

                residentCancellationReasons.push(option);
            });
        }

        return residentCancellationReasons;
    }

    getResidentName = () => {
        let residentName = null;

        if (this.props.resident &&
            this.props.resident.party &&
            this.props.resident.party.name &&
            (this.props.resident.party.name.firstName || this.props.resident.party.name.lastName)) {
            residentName = `${this.props.resident.party.name.firstName} ${this.props.resident.party.name.lastName}`;
        }

        return residentName;
    }

    render() {
        let residentName = this.getResidentName() || `Resident ${this.props.resident.number || ``}`;
        return (
            <React.Fragment>
                {this.state.mode === `resident` &&
                    <React.Fragment>
                        <BackButton clickEvent={this.props.backEvent} />
                        <Divider top={Sizes.md} />
                        <Header size={Sizes.md} textAlign={Alignments.left} text={residentName} />
                        <Form
                            label={`Resident Details`}
                            allowEdit={this.props.allowEdit}
                            saveEvent={() => this.props.doUpsertPolicy()}
                            cancelEvent={() => this.props.doGetPolicy()}
                            allowEdit={this.props.allowEdit}
                        >
                            <FormInput
                                label={<FormattedMessage id={`Number`} />}
                                value={this.props.resident &&
                                    this.props.resident.number}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}number`, value })}
                                allowEdit
                            />
                            <FormDropDown
                                label={<FormattedMessage id={`Type`} />}
                                value={this.props.resident && this.props.resident.applicantType}
                                references={this.props.reference.references && this.props.reference.references[`Applicant`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`Applicant`)}
                                changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}applicantType`, value, this.props.doReducePolicyElement)}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Applicant`} />}
                                value={this.props.resident &&
                                    { code: convertFromBoolean(this.props.resident.isInsured) }}
                                references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}isInsured`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Co-Applicant`} />}
                                value={this.props.resident &&
                                    { code: convertFromBoolean(this.props.resident.isCoInsured) }}
                                references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}isCoInsured`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormDropDown
                                label={<FormattedMessage id={`Relationship to Applicant`} />}
                                value={this.props.resident && this.props.resident.relationshipToInsured}
                                references={this.props.reference.references && this.props.reference.references[`RelationToApplicant`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`RelationToApplicant`)}
                                changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}relationshipToInsured`, value, this.props.doReducePolicyElement)}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Bankruptcy`} />}
                                value={this.props.resident &&
                                    { code: convertFromBoolean(this.props.resident.bankruptcy) }}
                                references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}bankruptcy`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Years Continuously Insured`} />}
                                value={this.props.resident &&
                                    this.props.resident.yearsContinuouslyInsured}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}yearsContinuouslyInsured`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Years Insured at Current Address`} />}
                                value={this.props.resident &&
                                    this.props.resident.yearsContinuouslyInsuredCurrentAddress}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}yearsContinuouslyInsuredCurrentAddress`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Years At Current Address`} />}
                                value={this.props.resident &&
                                    this.props.resident.addressTimeLivedAt}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}addressTimeLivedAt`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Number of Losses`} />}
                                value={this.props.resident &&
                                    this.props.resident.numberOfLosses}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}numberOfLosses`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Number of Years for Losses`} />}
                                value={this.props.resident &&
                                    this.props.resident.numberOfYearsForLosses}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}numberOfYearsForLosses`, value })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Loss Within 3 Years`} />}
                                value={this.props.resident &&
                                    { code: convertFromBoolean(this.props.resident.lossWithinLastThreeYears) }}
                                references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}lossWithinLastThreeYears`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                        </Form>
                        {(this.props.resident &&
                            this.props.resident.addressTimeLivedAt &&
                            this.props.resident.addressTimeLivedAt <= 3) &&
                            <AddressForm
                                header={<FormattedMessage id={`Previous Address Details`} />}
                                label={`Location Details`}
                                saveEvent={this.props.doUpsertPolicy}
                                cancelEvent={this.props.doGetPolicy}
                                reference={this.props.reference}
                                changeEvent={(dotProp, value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}previousAddress.${dotProp}`, value })}
                                allowEdit={this.props.allowEdit}
                                address={this.props.resident &&
                                    this.props.resident.previousAddress
                                }
                            />
                        }
                        <NameForm
                            label={`Name`}
                            name={this.props.resident &&
                                this.props.resident.party &&
                                this.props.resident.party.name
                            }
                            person={this.props.resident &&
                                this.props.resident.person
                            }
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            reference={this.props.reference}
                            changeEvent={(dotProp, value) => this.props.doReducePolicyElement({ code: (dotProp === `person.gender`) ? `${this.props.dotProp}${dotProp}` : `${this.props.dotProp}party.name.${dotProp}`, value })}
                            allowEdit={this.props.allowEdit}
                        />
                        <PersonForm
                            label={`Person`}
                            person={this.props.resident &&
                                this.props.resident.person
                            }
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            reference={this.props.reference}
                            changeEvent={(dotProp, value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}person.${dotProp}`, value })}
                            allowEdit={this.props.allowEdit}
                            culture={this.props.context.session.culture}
                        />
                        <React.Fragment>
                            <Divider top={Sizes.xl} />
                            <Header size={Sizes.lg} textAlign={Alignments.left} text={`Criminal Convictions`} />
                            <Container top={Sizes.xl} bottom={Sizes.xl}>
                                <SummaryGroup
                                    allowAdd={this.props.allowEdit}
                                    addEvent={() => {
                                        this.props.doExecutePolicyAction({ code: `CREATERESIDENTCRIMINALCONVICTION` });
                                    }}
                                    allowEdit
                                    editEvent={(criminalConviction) => {
                                        this.setState({ current: criminalConviction, mode: `criminalConviction` });
                                    }}
                                    allowDelete={this.props.allowEdit}
                                    deleteEvent={(criminalConviction) => {
                                        this.props.doReduceParameter({ code: `ResidentCriminalConviction`, value: criminalConviction });
                                        this.props.doExecutePolicyAction({ code: `DELETERESIDENTCRIMINALCONVICTION` });
                                        this.setState({ current: null, mode: `resident` });
                                    }}
                                    entities={this.getResidentCriminalConvictions()}
                                    icon={`criminalConviction`}
                                />
                            </Container>
                        </React.Fragment>
                        <React.Fragment>
                            <Divider top={Sizes.xl} />
                            <Header size={Sizes.lg} textAlign={Alignments.left} text={`Claims`} />
                            <Container top={Sizes.xl} bottom={Sizes.xl}>
                                <SummaryGroup
                                    allowAdd={this.props.allowEdit}
                                    addEvent={() => {
                                        this.props.doExecutePolicyAction({ code: `CREATERESIDENTCLAIM` });
                                    }}
                                    allowEdit
                                    editEvent={(claim) => {
                                        this.setState({ current: claim, mode: `claim` });
                                    }}
                                    allowDelete={this.props.allowEdit}
                                    deleteEvent={(claim) => {
                                        this.props.doReduceParameter({ code: `ResidentClaim`, value: claim });
                                        this.props.doExecutePolicyAction({ code: `DELETERESIDENTCLAIM` });
                                        this.setState({ current: null, mode: `resident` });
                                    }}
                                    entities={this.getResidentClaims()}
                                    icon={`claim`}
                                />
                            </Container>
                        </React.Fragment>
                        <React.Fragment>
                            <Divider top={Sizes.xl} />
                            <Header size={Sizes.lg} textAlign={Alignments.left} text={`Cancellations`} />
                            <Container top={Sizes.xl} bottom={Sizes.xl}>
                                <SummaryGroup
                                    allowAdd={this.props.allowEdit}
                                    addEvent={() => {
                                        this.props.doExecutePolicyAction({ code: `CREATERESIDENTCANCELLATIONREASON` });
                                    }}
                                    allowEdit
                                    editEvent={(cancellationReason) => {
                                        this.setState({ current: cancellationReason, mode: `cancellationReason` });
                                    }}
                                    allowDelete={this.props.allowEdit}
                                    deleteEvent={(cancellationReason) => {
                                        this.props.doReduceParameter({ code: `ResidentCancellationReason`, value: cancellationReason });
                                        this.props.doExecutePolicyAction({ code: `DELETERESIDENTCANCELLATIONREASON` });
                                        this.setState({ current: null, mode: `resident` });
                                    }}
                                    entities={this.getResidentCancellationReasons()}
                                    icon={`cancellation`}
                                />
                            </Container>
                        </React.Fragment>
                    </React.Fragment>
                }
                {this.state.mode === `criminalConviction` &&
                    <ProfileResidentCriminalConviction
                        criminalConviction={this.props.resident.criminalConvictions.find(x => x.guid === this.state.current)}
                        dotProp={`${this.props.dotProp}criminalConvictions.${this.props.resident.criminalConvictions.findIndex(x => x.guid === this.state.current)}.`}
                        backEvent={() => this.setState({ mode: `resident`, current: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
                {this.state.mode === `claim` &&
                    <ProfileResidentClaim
                        claim={this.props.resident.claims.find(x => x.guid === this.state.current)}
                        dotProp={`${this.props.dotProp}claims.${this.props.resident.claims.findIndex(x => x.guid === this.state.current)}.`}
                        backEvent={() => this.setState({ mode: `resident`, current: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
                {this.state.mode === `cancellationReason` &&
                    <ProfileResidentCancellationReason
                        cancellationReason={this.props.resident.cancellationReasons.find(x => x.guid === this.state.current)}
                        dotProp={`${this.props.dotProp}cancellationReasons.${this.props.resident.cancellationReasons.findIndex(x => x.guid === this.state.current)}.`}
                        backEvent={() => this.setState({ mode: `resident`, current: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
            </React.Fragment>
        );
    }
}

export default ProfileResident;