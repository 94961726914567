import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Alignments,
    Form,
    FormInput,
    FormTextArea,
    FormSwitch,
    Label
} from 'metrix-common';

class ProfileFluxGlHiredNonOwnedAuto extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            <React.Fragment>
                <Form
                    header={<FormattedMessage id={`Hired / Non-Owned Auto`} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormSwitch
                        label={<FormattedMessage id={`Do all drivers maintain a personal auto policy, and keep it in force at all times?`} />}
                        value={this.props.policy.fluxCustomInfo.hiredNonOwnedAuto?.hasAllDriversWithPersonalAuto}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.hiredNonOwnedAuto.hasAllDriversWithPersonalAuto`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Is any driver allowed to drive with any DUI, DWI, or reckless driving violations?`} />}
                        value={this.props.policy.fluxCustomInfo.hiredNonOwnedAuto?.hasAllowDriverToDriveWithViolation}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.hiredNonOwnedAuto.hasAllowDriverToDriveWithViolation`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Are MVRs collected for all drivers employed by the applicant?`} />}
                        value={this.props.policy.fluxCustomInfo.hiredNonOwnedAuto?.hasCollectMVRForDriver}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.hiredNonOwnedAuto.hasCollectMVRForDriver`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Does applicant or employees of applicant make any deliveries directly to patients or customers from the retail location?`} />}
                        value={this.props.policy.fluxCustomInfo.hiredNonOwnedAuto?.isDeliveringDirectlyToCustomer}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.hiredNonOwnedAuto.isDeliveringDirectlyToCustomer`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Does the applicant own any commercial vehicles?`} />}
                        value={this.props.policy.fluxCustomInfo.hiredNonOwnedAuto?.hasCommercialVehicles}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.hiredNonOwnedAuto.hasCommercialVehicles`, value: value })}
                        allowEdit
                    />
                    <FormInput
                        label={<FormattedMessage id={`What is the maximum distance a non-owned auto may be driven from the applicant's premises?`} />}
                        value={this.props.policy.fluxCustomInfo.hiredNonOwnedAuto?.maximumDistanceForNonOwnedAutoCount}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.hiredNonOwnedAuto.maximumDistanceForNonOwnedAutoCount`, value })}
                        type='number'
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Do employees use their own vehicles for business use?`} />}
                        value={this.props.policy.fluxCustomInfo.hiredNonOwnedAuto?.isEmployeeUsePersonalAutoInBusiness}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.hiredNonOwnedAuto.isEmployeeUsePersonalAutoInBusiness`, value: value })}
                        allowEdit
                    />
                    {this.props.policy.fluxCustomInfo.hiredNonOwnedAuto?.isEmployeeUsePersonalAutoInBusiness === true &&
                        <Label bold text={<FormattedMessage id={`Please describe usage:`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.fluxCustomInfo.hiredNonOwnedAuto?.isEmployeeUsePersonalAutoInBusiness === true &&
                        <FormTextArea
                            text={this.props.policy.fluxCustomInfo.hiredNonOwnedAuto?.employeeUsePersonalAutoInBusinessDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.hiredNonOwnedAuto.employeeUsePersonalAutoInBusinessDescription`, value })}
                        />
                    }
                    <FormInput
                        label={<FormattedMessage id={`How many employees does the applicant have that pay drive for business purposes?`} />}
                        value={this.props.policy.fluxCustomInfo.hiredNonOwnedAuto?.payableDrivingEmployeeCount}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.hiredNonOwnedAuto.payableDrivingEmployeeCount`, value })}
                        type='number'
                        allowEdit
                    />
                    <FormInput
                        label={<FormattedMessage id={`Total estimated mileage from non-owned vehicles for business annually:`} />}
                        value={this.props.policy.fluxCustomInfo.hiredNonOwnedAuto?.nonOwnedAutosEstimatedMileageCount}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.hiredNonOwnedAuto.nonOwnedAutosEstimatedMileageCount`, value })}
                        type='number'
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Does the applicant rent vehicles for business use?`} />}
                        value={this.props.policy.fluxCustomInfo.hiredNonOwnedAuto?.isRentVehiclesForBusiness}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.hiredNonOwnedAuto.isRentVehiclesForBusiness`, value: value })}
                        allowEdit
                    />
                    {this.props.policy.fluxCustomInfo.hiredNonOwnedAuto?.isRentVehiclesForBusiness === true &&
                        <Label bold text={<FormattedMessage id={`Please describe usage:`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.fluxCustomInfo.hiredNonOwnedAuto?.isRentVehiclesForBusiness === true &&
                        <FormTextArea
                            text={this.props.policy.fluxCustomInfo.hiredNonOwnedAuto?.rentVehiclesForBusinessDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.hiredNonOwnedAuto.rentVehiclesForBusinessDescription`, value })}
                        />
                    }
                    <FormInput
                        label={<FormattedMessage id={`Estimated number of vehicles rented annually:`} />}
                        value={this.props.policy.fluxCustomInfo.hiredNonOwnedAuto?.annualEstimatedRentedVehiclesCount}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.hiredNonOwnedAuto.annualEstimatedRentedVehiclesCount`, value })}
                        type='number'
                        allowEdit
                    />
                    <FormInput
                        label={<FormattedMessage id={`Total estimated receipts on rental vehicles annually:`} />}
                        value={this.props.policy.fluxCustomInfo.hiredNonOwnedAuto?.annualEstimatedRentedVehicleReceiptsAmount}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.hiredNonOwnedAuto.annualEstimatedRentedVehicleReceiptsAmount`, value })}
                        type='currency'
                        allowEdit
                    />
                </Form>
            </React.Fragment>
        );
    }
}

export default ProfileFluxGlHiredNonOwnedAuto;