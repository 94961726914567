import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Alignments,
    Container,
    Form,
    FormInput,
    FormDropDown,
    FormDate,
    FormTextArea,
    FormRadio,
    Label,
    convertFromBoolean,
    convertToBoolean
} from 'metrix-common';

class ProfileFluxGlBusiness extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
        this.props.reference.doGetReferences(`FLUX-GL.Organizations`);
    }

    render() {
        return (
            <React.Fragment>
                <Form
                    header={<FormattedMessage id={`Business Information`} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormInput
                        label={`DBA (Doing business as)`}
                        value={this.props.policy.fluxCustomInfo.businessInfo?.doingBusinessAs}
                        type='text'
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.doingBusinessAs`, value })}
                        allowEdit
                    />
                    <FormInput
                        label={`Website`}
                        value={this.props.policy.fluxCustomInfo.businessInfo?.website}
                        type='text'
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.website`, value })}
                        allowEdit
                    />
                    <FormDropDown
                        label={<FormattedMessage id={`Structure of the business`} />}
                        value={{ code: this.props.policy.fluxCustomInfo.businessInfo?.businessStructureCode, value: this.props.policy.fluxCustomInfo.businessInfo?.businessStructureName }}
                        references={this.props.reference?.references[`FLUX-GL`] && this.props.reference.references[`FLUX-GL`][`BusinessStructure`] }
                        referencesEvent={() => this.props.reference.doGetReferences(`FLUX-GL.BusinessStructure`)}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.businessStructureCode`, value: value.code });
                            this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.businessStructureName`, value: value.value });
                        }}
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={`Are you new in this business?`} />}
                        value={this.props.policy.fluxCustomInfo.businessInfo?.isNewVenture !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.businessInfo.isNewVenture) }}
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.isNewVenture`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.fluxCustomInfo.businessInfo?.isNewVenture === true &&
                        <FormRadio
                        label={<FormattedMessage id={`Do any of the principals have a minimum of 1 year in the cannabis, CBD, or hemp industry?`} />}
                        value={this.props.policy.fluxCustomInfo.businessInfo?.hasMinimumOneExperienceForNewVenture !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.businessInfo.hasMinimumOneExperienceForNewVenture) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.hasMinimumOneExperienceForNewVenture`, value: convertToBoolean(value.code) })}
                            allowEdit
                     />}
                    {this.props.policy.fluxCustomInfo.businessInfo?.isNewVenture === false &&
                    <FormInput
                        label={<FormattedMessage id={`How many years has your current company been in business?`} />}
                        value={ this.props.policy.fluxCustomInfo.businessInfo?.numberYearsInBusiness}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.numberYearsInBusiness`, value })}
                        type='number'
                        allowEdit
                    />}
                    {this.props.policy.fluxCustomInfo.businessInfo?.isNewVenture === false &&
                    <FormDate
                        label={<FormattedMessage id={`Date established`} />}
                        value={this.props.policy.fluxCustomInfo.businessInfo?.dateEstablished}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.dateEstablished`, value })}
                        culture={this.props.context.session.culture}
                        mode={`past`}
                        allowEdit
                    />
                    }
                    <Label bold text={<FormattedMessage id={`Inspection contact name and information`} />} textAlign={Alignments.left} />
                    <FormTextArea
                        text={this.props.policy.fluxCustomInfo.businessInfo?.inspectionInformation}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.inspectionInformation`, value })}
                    />
                    <FormRadio
                        label={<FormattedMessage id={`Has any applicant or any principal, partner, owner, officer, director, manager, or managing member of the applicant or any person(s) or organization(s) proposed for this insurance or any predecessor, subsidiary or affiliated organization been convicted of a felony or DUI in the last 10 years?`} />}
                        value={this.props.policy.fluxCustomInfo.businessInfo?.hasLawViolation !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.businessInfo.hasLawViolation) }}
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.hasLawViolation`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.fluxCustomInfo.businessInfo?.hasLawViolation === true &&
                        <Label bold text={<FormattedMessage id={`Law violation details`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.fluxCustomInfo.businessInfo?.hasLawViolation === true &&
                        <FormTextArea
                            text={this.props.policy.fluxCustomInfo.businessInfo?.lawViolationInfo}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.lawViolationInfo`, value })}
                        />
                    }
                    <FormRadio
                        label={<FormattedMessage id={`Is the applicant in compliance with all local and state laws regarding the manufacture, control, or dispensing of cannabis?`} />}
                        value={this.props.policy.fluxCustomInfo.businessInfo?.isCompliance !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.businessInfo.isCompliance) }}
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.isCompliance`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={`Does the insured currently hold a cannabis license/permit?`} />}
                        value={this.props.policy.fluxCustomInfo.businessInfo?.hasCannabisLicensee !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.businessInfo.hasCannabisLicensee) }}
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.hasCannabisLicensee`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.fluxCustomInfo.businessInfo?.hasCannabisLicensee === false &&
                        <FormDate
                            label={<FormattedMessage id={`When do they expect to be licensed?`} />}
                            value={this.props.policy.fluxCustomInfo.businessInfo?.cannabisLicenseDateExpectation}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.cannabisLicenseDateExpectation`, value })}
                            culture={this.props.context.session.culture}
                            mode={`future`}
                            allowEdit
                        />
                    }
                    <FormRadio
                        label={<FormattedMessage id={`Is the insured a member of any cannabis / marijuana / or hemp trade associations?`} />}
                        value={this.props.policy.fluxCustomInfo.businessInfo?.isMemberOfCannabisAssociations !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.businessInfo.isMemberOfCannabisAssociations) }}
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.isMemberOfCannabisAssociations`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.fluxCustomInfo.businessInfo?.isMemberOfCannabisAssociations === true &&
                        <FormDropDown
                            label={<FormattedMessage id={`Please select organization`} />}
                            value={{ code: this.props.policy.fluxCustomInfo.businessInfo?.cannabisOrganizationTypeCode, value: this.props.policy.fluxCustomInfo.businessInfo?.cannabisOrganizationTypeName }}
                            references={this.props.reference?.references[`FLUX-GL`] && this.props.reference.references[`FLUX-GL`][`Organizations`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`FLUX-GL.Organizations`)}
                            changeEvent={(value) => {
                                this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.cannabisOrganizationTypeCode`, value: value.code });
                                this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.cannabisOrganizationTypeName`, value: value.value });
                            }}
                            allowEdit
                        />
                    }
                    {this.props.policy.fluxCustomInfo.businessInfo?.cannabisOrganizationTypeCode === 'OTHER' &&
                        <FormInput
                            label={<FormattedMessage id={`Please tell to us name of organization`} />}
                            value={this.props.policy.fluxCustomInfo.businessInfo?.cannabisOrganizationOtherName}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.cannabisOrganizationOtherName`, value })}
                            type='text'
                            allowEdit
                        />
                    }
                    <FormRadio
                        label={<FormattedMessage id={`Has any applicant or principal filed for Bankruptcy in the last 5 years?`} />}
                        value={this.props.policy.fluxCustomInfo.businessInfo?.hasBankruptcy !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.businessInfo.hasBankruptcy) }}
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.hasBankruptcy`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.fluxCustomInfo.businessInfo?.hasBankruptcy === true &&
                        <FormDropDown
                            label={<FormattedMessage id={`Bankruptcy type?`} />}
                            value={{ code: this.props.policy.fluxCustomInfo.businessInfo?.bankruptcyTypeCode, value: this.props.policy.fluxCustomInfo.businessInfo?.bankruptcyTypeName }}
                            references={this.props.reference?.references[`FLUX-GL`] && this.props.reference.references[`FLUX-GL`][`BankruptcyType`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`FLUX-GL.BankruptcyType`)}
                            changeEvent={(value) => {
                                this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.bankruptcyTypeCode`, value: value.code });
                                this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.bankruptcyTypeName`, value: value.value });
                            }}
                            allowEdit
                        />
                    }
                    <Label bold text={<FormattedMessage id={`Description of operations`} />} textAlign={Alignments.left} />
                    <FormTextArea
                        text={this.props.policy.fluxCustomInfo.businessInfo?.operationsInfo}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.operationsInfo`, value })}
                    />
                    <Label bold text={<FormattedMessage id={`List of subsidiaries and their operations`} />} textAlign={Alignments.left} />
                    <FormTextArea
                        text={this.props.policy.fluxCustomInfo.businessInfo?.subsidiariesInfo}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.subsidiariesInfo`, value })}
                    />
                    <Label bold text={<FormattedMessage id={`List any additional offices and provide location information`} />} textAlign={Alignments.left} />
                    <FormTextArea
                        text={this.props.policy.fluxCustomInfo.businessInfo?.additionalOfficesInfo}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.additionalOfficesInfo`, value })}
                    />
                    <FormRadio
                        label={<FormattedMessage id={`Have any of the principals engaged in this or similar operations under a different name?`} />}
                        value={this.props.policy.fluxCustomInfo.businessInfo?.hasSimilarOperationsUnderDifferentName !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.businessInfo.hasSimilarOperationsUnderDifferentName) }}
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.hasSimilarOperationsUnderDifferentName`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.fluxCustomInfo.businessInfo?.hasSimilarOperationsUnderDifferentName === true &&
                        <Label bold text={<FormattedMessage id={`Please list entity and details of the operation`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.fluxCustomInfo.businessInfo?.hasSimilarOperationsUnderDifferentName === true &&
                        <FormTextArea
                            text={this.props.policy.fluxCustomInfo.businessInfo?.similarOperationsUnderDifferentNameInfo}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.similarOperationsUnderDifferentNameInfo`, value })}
                        />
                    }          
                </Form>
                <Form
                    header={<FormattedMessage id={`Product Use`} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormDropDown
                        label={<FormattedMessage id={`Description of product use`} />}
                        value={{ code: this.props.policy.fluxCustomInfo.businessInfo?.cannabisProductUsingTypeCode, value: this.props.policy.fluxCustomInfo.businessInfo?.cannabisProductUsingTypeName}}
                        references={this.props.reference?.references[`FLUX-GL`] && this.props.reference.references[`FLUX-GL`][`CannabisProductUsingTypes`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`FLUX-GL.CannabisProductUsingTypes`)}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.cannabisProductUsingTypeCode`, value: value.code });
                            this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.cannabisProductUsingTypeName`, value: value.value });
                        }}
                        allowEdit
                    />
                    {this.props.policy.fluxCustomInfo.businessInfo?.cannabisProductUsingTypeCode === 'OTHER' &&
                        <Label bold text={<FormattedMessage id={`Please provide details of the product use.`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.fluxCustomInfo.businessInfo?.cannabisProductUsingTypeCode === 'OTHER' &&
                        <FormTextArea
                            text={this.props.policy.fluxCustomInfo.businessInfo?.otherProductUse}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.otherProductUse`, value })}
                        />
                    }                   
                </Form>
                {this.props.policy.fluxCustomInfo.businessInfo?.isNewVenture === false &&
                    <Container>
                        <Form
                            header={<FormattedMessage id={'Estimated business financial information for the next year'} />}
                            label={`Details`}
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            allowEdit={this.props.allowEdit}
                        >
                            <FormInput
                                label={<FormattedMessage id={`Year`} />}
                                value={this.props.policy.fluxCustomInfo.businessInfo.nextYearRevenue?.year}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.nextYearRevenue.year`, value })}
                                type='number'
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Domestic sales`} />}
                                value={this.props.policy.fluxCustomInfo.businessInfo.nextYearRevenue?.domesticSales}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.nextYearRevenue.domesticSales`, value })}
                                type='currency'
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Payroll`} />}
                                value={this.props.policy.fluxCustomInfo.businessInfo.nextYearRevenue?.payroll}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.nextYearRevenue.payroll`, value })}
                                type='currency'
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`# Of employees`} />}
                                value={this.props.policy.fluxCustomInfo.businessInfo.nextYearRevenue?.amountOfEmployees}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.nextYearRevenue.amountOfEmployees`, value })}
                                type='number'
                                allowEdit
                            />
                        </Form>
                        <Form
                            header={<FormattedMessage id={'Business financial information for the last year'} />}
                            label={`Details`}
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            allowEdit={this.props.allowEdit}
                        >
                            <FormInput
                                label={<FormattedMessage id={`Year`} />}
                                value={this.props.policy.fluxCustomInfo.businessInfo.firstPriorYearRevenue?.year}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.firstPriorYearRevenue.year`, value })}
                                type='number'
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Domestic sales`} />}
                                value={this.props.policy.fluxCustomInfo.businessInfo.firstPriorYearRevenue?.domesticSales}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.firstPriorYearRevenue.domesticSales`, value })}
                                type='currency'
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Payroll`} />}
                                value={this.props.policy.fluxCustomInfo.businessInfo.firstPriorYearRevenue?.payroll}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.firstPriorYearRevenue.payroll`, value })}
                                type='currency'
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`# Of employees`} />}
                                value={this.props.policy.fluxCustomInfo.businessInfo.firstPriorYearRevenue?.amountOfEmployees}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.firstPriorYearRevenue.amountOfEmployees`, value })}
                                type='number'
                                allowEdit
                            />
                        </Form>
                        <Form
                            header={<FormattedMessage id={'Business financial information for the 2nd year prior'} />}
                            label={`Details`}
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            allowEdit={this.props.allowEdit}
                        >
                            <FormInput
                                label={<FormattedMessage id={`Year`} />}
                                value={this.props.policy.fluxCustomInfo.businessInfo.secondPriorYearRevenue?.year}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.secondPriorYearRevenue.year`, value })}
                                type='number'
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Domestic sales`} />}
                                value={this.props.policy.fluxCustomInfo.businessInfo.secondPriorYearRevenue?.domesticSales}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.secondPriorYearRevenue.domesticSales`, value })}
                                type='currency'
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Payroll`} />}
                                value={this.props.policy.fluxCustomInfo.businessInfo.secondPriorYearRevenue?.payroll}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.secondPriorYearRevenue.payroll`, value })}
                                type='currency'
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`# Of employees`} />}
                                value={this.props.policy.fluxCustomInfo.businessInfo.secondPriorYearRevenue?.amountOfEmployees}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.secondPriorYearRevenue.amountOfEmployees`, value })}
                                type='number'
                                allowEdit
                            />
                        </Form>
                        <Form
                            header={<FormattedMessage id={'Business financial information for the 3rd year prior'} />}
                            label={`Details`}
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            allowEdit={this.props.allowEdit}
                        >
                            <FormInput
                                label={<FormattedMessage id={`Year`} />}
                                value={this.props.policy.fluxCustomInfo.businessInfo.thirdPriorYearRevenue?.year}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.thirdPriorYearRevenue.year`, value })}
                                type='number'
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Domestic sales`} />}
                                value={this.props.policy.fluxCustomInfo.businessInfo.thirdPriorYearRevenue?.domesticSales}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.thirdPriorYearRevenue.domesticSales`, value })}
                                type='currency'
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Payroll`} />}
                                value={this.props.policy.fluxCustomInfo.businessInfo.thirdPriorYearRevenue?.payroll}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.thirdPriorYearRevenue.payroll`, value })}
                                type='currency'
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`# Of employees`} />}
                                value={this.props.policy.fluxCustomInfo.businessInfo.thirdPriorYearRevenue?.amountOfEmployees}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.thirdPriorYearRevenue.amountOfEmployees`, value })}
                                type='number'
                                allowEdit
                            />
                        </Form>
                        <Form
                            header={<FormattedMessage id={'Business financial information for the 4th year prior'} />}
                            label={`Details`}
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            allowEdit={this.props.allowEdit}
                        >
                            <FormInput
                                label={<FormattedMessage id={`Year`} />}
                                value={this.props.policy.fluxCustomInfo.businessInfo.fourthPriorYearRevenue?.year}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.fourthPriorYearRevenue.year`, value })}
                                type='number'
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Domestic sales`} />}
                                value={this.props.policy.fluxCustomInfo.businessInfo.fourthPriorYearRevenue?.domesticSales}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.fourthPriorYearRevenue.domesticSales`, value })}
                                type='number'
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Payroll`} />}
                                value={this.props.policy.fluxCustomInfo.businessInfo.fourthPriorYearRevenue?.payroll}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.fourthPriorYearRevenue.payroll`, value })}
                                type='number'
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`# Of employees`} />}
                                value={this.props.policy.fluxCustomInfo.businessInfo.fourthPriorYearRevenue?.amountOfEmployees}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.businessInfo.fourthPriorYearRevenue.amountOfEmployees`, value })}
                                type='number'
                                allowEdit
                            />
                        </Form>
                    </Container>
            }
            </React.Fragment>
        );
    }
}

export default ProfileFluxGlBusiness;