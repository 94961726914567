import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    convertFromBoolean,
    convertToBoolean,
    setAttributeEntity,
    Sizes,
    Alignments,
    Container,
    Divider,
    Header,
    BackButton,
    Form,
    AddressForm,
    FormInput,
    FormRadio,
    FormDropDown,
    FormDate,
    SummaryGroup
} from 'metrix-common';

import ProfileVehicleUse from './VehicleUse';

class ProfileVehicle extends React.Component {
    state = {
        mode: `vehicle`,
        current: null
    };

    getVehicleUses = () => {
        let vehicleUses = [];

        if (this.props.vehicleUses &&
            this.props.vehicleUses.filter(x => x.vehicle === this.props.vehicle.guid).length > 0) {
            this.props.vehicleUses.filter(x => x.vehicle === this.props.vehicle.guid).forEach((vehicleUse) => {
                let option = { code: `vehicleUse`, value: vehicleUse.guid };

                let vehicleUseName = `New Usage`;

                if (this.props.drivers && vehicleUse.driver && this.props.drivers.some(x => x.code === vehicleUse.driver)) {
                    vehicleUseName = `${this.props.drivers.find(x => x.code === vehicleUse.driver).value}`;
                }

                option.elements = [];

                option.elements.push({ code: `Label`, value: vehicleUseName });

                vehicleUses.push(option);
            });
        }

        return vehicleUses;
    }

    render() {
        let vehicleName = (this.props.vehicle && this.props.vehicle.year && this.props.vehicle.make) ? `${this.props.vehicle.year} ${this.props.vehicle.make}` : `Vehicle ${this.props.vehicle && this.props.vehicle.number}`;
        return (
            <React.Fragment>
                {this.state.mode === `vehicle` &&
                    <React.Fragment>
                        <BackButton clickEvent={this.props.backEvent} />
                        <Divider top={Sizes.md} />
                        <Header size={Sizes.md} textAlign={Alignments.left} text={vehicleName} />
                        <Form
                            label={`Vehicle Details`}
                            allowEdit={this.props.allowEdit}
                            saveEvent={() => this.props.doUpsertPolicy()}
                            cancelEvent={() => this.props.doGetPolicy()}
                        >
                            <FormInput
                                label={<FormattedMessage id={`Number`} />}
                                value={this.props.vehicle &&
                                    this.props.vehicle.number}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}number`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Year`} />}
                                value={this.props.vehicle &&
                                    this.props.vehicle.year}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}year`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Make`} />}
                                value={this.props.vehicle &&
                                    this.props.vehicle.make}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}make`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Model`} />}
                                value={this.props.vehicle &&
                                    this.props.vehicle.model}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}model`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`VICC`} />}
                                value={this.props.vehicle &&
                                    this.props.vehicle.viccCodeExt}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}viccCodeExt`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`VIN`} />}
                                value={this.props.vehicle &&
                                    this.props.vehicle.vin}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}vin`, value })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Additional Alarm`} />}
                                value={this.props.vehicle &&
                                    { code: convertFromBoolean(this.props.vehicle.additionalAlarm) }}
                                references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}additionalAlarm`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Engravings`} />}
                                value={this.props.vehicle &&
                                    { code: convertFromBoolean(this.props.vehicle.engravings) }}
                                references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}engravings`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Tracking`} />}
                                value={this.props.vehicle &&
                                    { code: convertFromBoolean(this.props.vehicle.tracking) }}
                                references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}tracking`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormDropDown
                                label={<FormattedMessage id={`Garage Type`} />}
                                value={this.props.policy && this.props.policy.garageType}
                                references={this.props.reference.references && this.props.reference.references[`ParkingType`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`ParkingType`)}
                                changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}garageType`, value, this.props.doReducePolicyElement)}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Garaged at Insured Address`} />}
                                value={this.props.vehicle &&
                                    { code: convertFromBoolean(this.props.vehicle.garagedAtInsuredAddress) }}
                                references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}garagedAtInsuredAddress`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                        </Form>
                        {
                            this.props.vehicle && this.props.vehicle.garagedAtInsuredAddress === false &&
                            <AddressForm
                                label={`Garaging Address`}
                                saveEvent={this.props.doUpsertPolicy}
                                cancelEvent={this.props.doGetPolicy}
                                reference={this.props.reference}
                                changeEvent={(dotProp, value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}garageAddress.${dotProp}`, value })}
                                allowEdit={this.props.allowEdit}
                                address={this.props.vehicle &&
                                    this.props.vehicle.garageAddress
                                }
                            />
                        }
                        <Form
                            label={`Ownership Details`}
                            allowEdit={this.props.allowEdit}
                            saveEvent={() => this.props.doUpsertPolicy()}
                            cancelEvent={() => this.props.doGetPolicy()}
                        >
                            <FormRadio
                                label={<FormattedMessage id={`Owned`} />}
                                value={this.props.vehicle &&
                                    { code: convertFromBoolean(this.props.vehicle.owned) }}
                                references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}owned`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            {(this.props.vehicle && this.props.vehicle.owned === false) &&
                                <FormDropDown
                                    label={<FormattedMessage id={`Lessor`} />}
                                    value={this.props.vehicle && this.props.vehicle.lessor}
                                    references={this.props.reference.references && this.props.reference.references[`AutoFinancer`]}
                                    referencesEvent={() => this.props.reference.doGetReferences(`AutoFinancer`)}
                                    changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}lessor`, value, this.props.doReducePolicyElement)}
                                    allowEdit
                                />
                            }
                            {(this.props.vehicle && this.props.vehicle.owned === true) &&
                                <FormRadio
                                    label={<FormattedMessage id={`Financed`} />}
                                    value={this.props.vehicle &&
                                        { code: convertFromBoolean(this.props.vehicle.financed) }}
                                    references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                                    referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}financed`, value: convertToBoolean(value.code) })}
                                    allowEdit
                                />
                            }
                            {(this.props.vehicle && this.props.vehicle.financed === true) &&
                                <FormDropDown
                                    label={<FormattedMessage id={`Financer Lender`} />}
                                    value={this.props.vehicle && this.props.vehicle.financeLender}
                                    references={this.props.reference.references && this.props.reference.references[`AutoFinancer`]}
                                    referencesEvent={() => this.props.reference.doGetReferences(`AutoFinancer`)}
                                    changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}financeLender`, value, this.props.doReducePolicyElement)}
                                    allowEdit
                                />
                            }
                            {(this.props.vehicle && this.props.vehicle.financed === true) &&
                                <FormInput
                                    label={<FormattedMessage id={`Finance Rate`} />}
                                    value={this.props.vehicle &&
                                        this.props.vehicle.financeRate}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}financeRate`, value })}
                                    allowEdit
                                />
                            }
                            <FormRadio
                                label={<FormattedMessage id={`Purchased Used`} />}
                                value={this.props.vehicle &&
                                    { code: convertFromBoolean(this.props.vehicle.used) }}
                                references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}used`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormDate
                                label={<FormattedMessage id={`Purchase Date`} />}
                                value={this.props.vehicle && this.props.vehicle.purchaseDate}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}purchaseDate`, value })}
                                culture={this.props.context.session.culture}
                                mode={`past`}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Purchase Value`} />}
                                value={this.props.vehicle &&
                                    this.props.vehicle.purchaseValue &&
                                    this.props.vehicle.purchaseValue.value
                                }
                                changeEvent={(value) => {
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}purchaseValue.value`, value });
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}purchaseValue.currency`, value: `cad` });
                                }}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Current Value`} />}
                                value={this.props.vehicle &&
                                    this.props.vehicle.presentValue &&
                                    this.props.vehicle.presentValue.value
                                }
                                changeEvent={(value) => {
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}presentValue.value`, value });
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}presentValue.currency`, value: `cad` });
                                }}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`New Value`} />}
                                value={this.props.vehicle &&
                                    this.props.vehicle.newValue &&
                                    this.props.vehicle.newValue.value
                                }
                                changeEvent={(value) => {
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}newValue.value`, value });
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}newValue.currency`, value: `cad` });
                                }}
                                allowEdit
                            />
                        </Form>
                        <Form
                            label={`Usage Details`}
                            allowEdit={this.props.allowEdit}
                            saveEvent={() => this.props.doUpsertPolicy()}
                            cancelEvent={() => this.props.doGetPolicy()}
                        >
                            <FormInput
                                label={<FormattedMessage id={`Leisure Activity`} />}
                                value={this.props.vehicle &&
                                    this.props.vehicle.leisureActivity}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}leisureActivity`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Daily Commute`} />}
                                value={this.props.vehicle &&
                                    this.props.vehicle.commuteActivity}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}commuteActivity`, value })}
                                allowEdit
                            />
                            <FormInput
                                label={<FormattedMessage id={`Business Activity`} />}
                                value={this.props.vehicle &&
                                    this.props.vehicle.businessActivity}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}businessActivity`, value })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Rideshare`} />}
                                value={this.props.vehicle &&
                                    { code: convertFromBoolean(this.props.vehicle.rideShare) }}
                                references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}rideShare`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Winter Tires`} />}
                                value={this.props.vehicle &&
                                    { code: convertFromBoolean(this.props.vehicle.winterTires) }}
                                references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}winterTires`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            <FormRadio
                                label={<FormattedMessage id={`Stored for winter`} />}
                                value={this.props.vehicle &&
                                    { code: convertFromBoolean(this.props.vehicle.stored) }}
                                references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}stored`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                            {(this.props.vehicle && this.props.vehicle.stored === true) &&
                                <FormDate
                                    label={<FormattedMessage id={`Storage Start`} />}
                                    value={this.props.vehicle && this.props.vehicle.storageStartDate}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}storageStartDate`, value })}
                                    culture={this.props.context.session.culture}
                                    allowEdit
                                />
                            }
                            {(this.props.vehicle && this.props.vehicle.stored === true) &&
                                <FormDate
                                    label={<FormattedMessage id={`Storage End`} />}
                                    value={this.props.vehicle && this.props.vehicle.storageEndDate}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}storageEndDate`, value })}
                                    culture={this.props.context.session.culture}
                                    allowEdit
                                />
                            }
                        </Form>
                        <Divider top={Sizes.xl} />
                        <Header size={Sizes.lg} textAlign={Alignments.left} text={`Vehicle Usage`} />
                        <Container top={Sizes.xl} bottom={Sizes.xl}>
                            <SummaryGroup
                                allowAdd={this.props.allowEdit}
                                addEvent={() => {
                                    this.props.doReduceParameter({ code: `Vehicle`, value: this.props.vehicle.guid });
                                    this.props.doExecutePolicyAction({ code: `CREATEVEHICLEUSE` });
                                }}
                                allowEdit
                                editEvent={(vehicleUse) => {
                                    this.setState({ current: vehicleUse, mode: `vehicleUsage` });
                                }}
                                allowDelete={this.props.allowEdit}
                                deleteEvent={(vehicleUse) => {
                                    this.props.doReduceParameter({ code: `VehicleUse`, value: vehicleUse });
                                    this.props.doExecutePolicyAction({ code: `DELETEVEHICLEUSE` });
                                    this.setState({ current: null, mode: `vehicle` });
                                }}
                                entities={this.getVehicleUses()}
                                icon={`person`}
                            />
                        </Container>
                    </React.Fragment>
                }
                {this.state.mode === `vehicleUsage` &&
                    <ProfileVehicleUse
                        vehicleUse={this.props.vehicleUses.find(x => x.guid === this.state.current)}
                        dotProp={`vehicleUses.${this.props.vehicleUses.findIndex(x => x.guid === this.state.current)}.`}
                        backEvent={() => this.setState({ mode: `vehicle`, current: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        reference={this.props.reference}
                        context={this.props.context}
                        drivers={this.props.drivers}
                        allowEdit={this.props.allowEdit}
                    />
                }
            </React.Fragment>
        );
    }
}

export default ProfileVehicle;