import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Container,
    Divider,
    Sizes,
    Header,
    Alignments,
    IconButton,
    RenewalSmallIcon,
    Colors,
    PrimaryButton,
    Row,
    Column,
    Grid,
    Label,
    EditIcon,
    getColor,
    Opacity,
    Radius,
    getFormattedDate,
    GenericModal,
    TextArea,
} from 'metrix-common';
import styled from 'styled-components';

const Button = styled.div`
    opacity: 0.75;
    :hover {
        opacity: 1;
    }
`;

const StyledTextArea = styled(TextArea)`
    border: 1px solid ${getColor(Colors.StrokeDark, Opacity.None)};
    border-radius: ${Radius.small};
    //padding: .25rem;
    //height: 150px;
`;

const LeftHalfBorderColumn = styled(Column)`
    padding: 1rem 0 1rem 1rem;
    border-left: 1px solid ${getColor(Colors.StrokeDark, Opacity.None)};
    border-bottom: 1px solid ${getColor(Colors.StrokeDark, Opacity.None)};
    border-top: 1px solid ${getColor(Colors.StrokeDark, Opacity.None)};
    border-radius: ${Radius.small};
`;

const RightHalfColumn = styled(Column)`
    padding: 1rem 1rem 1rem 0;
    border-right: 1px solid ${getColor(Colors.StrokeDark, Opacity.None)};
    border-bottom: 1px solid ${getColor(Colors.StrokeDark, Opacity.None)};
    border-top: 1px solid ${getColor(Colors.StrokeDark, Opacity.None)};
    border-radius: ${Radius.small};
`;

const MiddleColumn = styled(Column)`
    padding: 1rem 0;
    border-bottom: 1px solid ${getColor(Colors.StrokeDark, Opacity.None)};
    border-top: 1px solid ${getColor(Colors.StrokeDark, Opacity.None)};
    border-radius: ${Radius.small};
`;

const ProfileNotes = ({ context, notes, doGetNotes, doUpsertNote }) => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [remark, setRemark] = useState('');
    const [editingNote, setEditingNote] = useState(null);

    useEffect(() => {
        doGetNotes();
    }, []);

    const addNoteBtnClick = () => {
        setShowAddModal(true);
    };

    const editNoteBtnClick = (note) => {
        setEditingNote(note);
        setRemark(note.remark);
        setShowEditModal(true);
    };

    const closeAddModal = () => {
        setRemark('');
        setShowAddModal(false);
    };

    const closeEditModal = () => {
        setRemark('');
        setEditingNote(null);
        setShowEditModal(false);
    };

    const saveNoteHandler = () => {
        doUpsertNote({
            remark,
            createdBy: context.session.user,
            createdByName: context.session.fullName,
            createdOn: new Date().toISOString()
        });
        closeAddModal();
    }

    const updateNoteHandler = () => {
        doUpsertNote({
            noteGuid: editingNote.guid,
            remark,
        });
        closeEditModal();
    }

    return (
        <Container>
            <Divider top={Sizes.xl} />
            <Header size={Sizes.lg}
                    textAlign={Alignments.left}
                    text='Notes'
            />
            <Divider top={Sizes.lg} />
            <Header size={Sizes.md}
                    bottom={Sizes.xs}
                    textAlign={Alignments.left}
                    text='Action'
            />
            <Container top={Sizes.xs}
                       bottom={Sizes.sm}
                       inline
            >
                <IconButton text='Refresh'
                            size={Sizes.sm}
                            right={Sizes.sm}
                            icon={<RenewalSmallIcon color={Colors.LightMode} />}
                            clickEvent={doGetNotes}
                />
                <PrimaryButton text='Add new'
                               size={Sizes.sm}
                               clickEvent={addNoteBtnClick}
                />
            </Container>
            {
                notes?.length > 0 &&
                <Grid>
                    {
                        notes?.map(note => (
                            <Row key={note.guid}>
                                <LeftHalfBorderColumn nogutter
                                                      xs={8}
                                >
                                    <Label textAlign={Alignments.left}
                                           text={note.remark}
                                           size={Sizes.lg}
                                           bold
                                    />
                                </LeftHalfBorderColumn>
                                <MiddleColumn nogutter
                                              xs={3}
                                >
                                    <Container fullSize
                                               verticalAlignment={Alignments.top}
                                               horizontalAlignment={Alignments.left}
                                    >
                                        <Label textAlign={Alignments.left}
                                               text={`by: ${note.modifiedByName} / ${getFormattedDate(note.modifiedOn, context.session.culture)}`}
                                               left={Sizes.xs}
                                        />
                                    </Container>
                                </MiddleColumn>
                                <RightHalfColumn nogutter
                                                 xs={.5}
                                >
                                    <Container fullSize
                                               verticalAlignment={Alignments.center}
                                               horizontalAlignment={Alignments.right}
                                    >
                                        <Button onClick={() => editNoteBtnClick(note)}>
                                            <EditIcon />
                                        </Button>
                                    </Container>
                                </RightHalfColumn>
                            </Row>
                        ))
                    }
                </Grid>
            }
            {
                showAddModal &&
                <GenericModal
                    animate
                    visible
                    closeEvent={closeAddModal}
                    items={
                        <React.Fragment>
                            <Header text='Add new Note'
                                    size={Sizes.md}
                                    bottom={Sizes.md}
                            />
                            <Container>
                                <StyledTextArea
                                    text={remark}
                                    changeEvent={setRemark}
                                    placeholder='Enter your note here'
                                />
                            </Container>
                        </React.Fragment>
                    }
                    actionButtons={[
                        {
                            primary: false,
                            text: 'Cancel',
                            disabled: false,
                            clickEvent: closeAddModal
                        },
                        {
                            primary: true,
                            text: 'Add',
                            disabled: false,
                            clickEvent: saveNoteHandler
                        }
                    ]}
                />
            }
            {
                showEditModal &&
                <GenericModal
                    animate
                    visible
                    closeEvent={closeEditModal}
                    items={
                        <React.Fragment>
                            <Header text='Edit Note'
                                    size={Sizes.md}
                                    bottom={Sizes.md}
                            />
                            <Container>
                                <StyledTextArea
                                    text={remark}
                                    changeEvent={setRemark}
                                />
                            </Container>
                        </React.Fragment>
                    }
                    actionButtons={[
                        {
                            primary: false,
                            text: 'Cancel',
                            disabled: false,
                            clickEvent: closeEditModal
                        },
                        {
                            primary: true,
                            text: 'Update',
                            disabled: false,
                            clickEvent: updateNoteHandler
                        }
                    ]}
                />
            }
        </Container>
    );
}

export default ProfileNotes;

ProfileNotes.propTypes = {
    context: PropTypes.object.isRequired,
    notes: PropTypes.array,
    doGetNotes: PropTypes.func.isRequired,
    doUpsertNote: PropTypes.func.isRequired
};
