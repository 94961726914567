import { put, call } from 'redux-saga/effects';
import { reduceOperation, reduceLoading, getSessionSaga } from 'metrix-common';
import { reduceTriagePolicies } from '../../actions/policies/reduceTriagePolicies';

export function* getTriagePoliciesSaga(action) {
    try {
        yield put(reduceLoading({ code: 'getTriagePolicies', value: true }));
        const session = yield getSessionSaga();
        
        const response = yield call(fetch, '/api/policyTransaction/triage', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Authorization': session.authenticationToken.token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ policyQuery: { take: 25, skip: 0 } })
        });
        
        const result = yield call([response, 'json']);
        
        yield put(reduceOperation(result.operation));
        if (!result.operation.status) return;
        
        yield put(reduceTriagePolicies(result.searchResult));
    }
    catch (error) {
        console.log('Error while calling Get List Policies API - ', error);
    }
    finally {
        yield put(reduceLoading({ code: 'getTriagePolicies', value: false }));
    }
}
