import React from 'react';

import {
    Alert,
    Sizes,
    Alignments,
    Container,
    Divider,
    Header,
    SummaryGroup,
    getPageValidationInfo
} from 'metrix-common';

import ProfileEmployersWcInsured from './Insured';

class ProfileEmployersWcInsureds extends React.Component {
    state = {
        mode: `insuredSummaryView`,
        currentInsured: null
    };

    componentDidMount() {
        const pageHasTooltipInfo = this.props.tooltipInfo && this.props.tooltipInfo.pages && this.props.tooltipInfo.pages.length && this.props.tooltipInfo.pages.some(p => p.code === this.props.pageCode);
        if (!pageHasTooltipInfo) {
            this.props.doGetTooltipInfo(this.props.policy.product);
        }
    }

    getInsureds = () => {
        let insureds = [];

        if (this.props.policy?.employersWcCustomInfo?.insureds && this.props.policy.employersWcCustomInfo.insureds.length > 0) {
            this.props.policy.employersWcCustomInfo.insureds.forEach((insured) => {
                let option = { code: 'insuredOption', value: insured.guid };

                let insuredName = `${insured.insuredName || 'New insured'}`;

                option.elements = [];
                option.elements.push({ code: 'Label', value: insuredName });

                insureds.push(option);
            });
        }

        return insureds;
    }

    render() {
        const insureds = this.getInsureds();

        const { error, warning } = getPageValidationInfo(this.props.pageCode, this.props.validationInfo);
        return (
            <React.Fragment>
                {
                    (error.message.length !== 0 || warning.message.length !== 0) &&
                    <Container top={Sizes.md}>
                        {
                            error.message.length !== 0 &&
                            <Alert
                                mode='danger'
                                header={error.title}
                                items={error.message}
                            />
                        }
                        {
                            warning.message.length !== 0 &&
                            <Alert
                                mode='warning'
                                header={warning.title}
                                items={warning.message}
                            />
                        }
                    </Container>
                }
                <Container>
                {
                    this.state.mode === 'insuredSummaryView' &&
                    <React.Fragment>
                        <Divider top={Sizes.xl} />
                        <Header size={Sizes.lg} textAlign={Alignments.left} text={`Insureds`} />
                        <Container top={Sizes.xl} bottom={Sizes.xl}>
                        <SummaryGroup
                            allowAdd={this.props.allowEdit && insureds.length === 0}
                            addEvent={() => {
                                this.props.doReduceParameters([]);
                                this.props.doExecutePolicyAction({ code: `EMPLOYERS-WC.CREATEINSURED` });
                            }}
                            allowEdit
                            editEvent={(insured) => {
                                this.setState({ currentInsured: insured, mode: `insuredView` });
                            }}
                            allowDelete={this.props.allowEdit && insureds.length > 1 }
                            deleteEvent={(insured) => {
                                this.props.doReduceParameters([{ code: `AdditionalInsuredGuid`, value: insured }]);
                                this.props.doExecutePolicyAction({ code: `EMPLOYERS-WC.DELETEINSURED` });
                            }}
                            entities={insureds}
                            icon={`person`}
                            />
                        </Container>
                    </React.Fragment>
                }
                {
                    this.state.mode === 'insuredView' &&
                    <ProfileEmployersWcInsured
                        rowId={this.state.currentInsured}
                        pageCode={this.props.pageCode}
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        primaryRiskStateCode={this.props.policy.employersWcCustomInfo.businessInfo?.primaryRiskStateCode}
                        legalEntityTypeCode={this.props.policy.employersWcCustomInfo.businessInfo?.legalEntityTypeCode}
                        legalEntityTypeValue={this.props.policy.employersWcCustomInfo.businessInfo?.legalEntityTypeValue}
                        legalBusinessName={this.props.policy.employersWcCustomInfo.businessInfo?.legalBusinessName}
                        federalEmployerIdentificationNumber={this.props.policy.employersWcCustomInfo.businessInfo?.federalEmployerIdentificationNumber}
                        insured={this.props.policy.employersWcCustomInfo.insureds.find(x => x.guid === this.state.currentInsured)}
                        locations={this.props.policy.employersWcCustomInfo.locations}
                        owners={this.props.policy.employersWcCustomInfo.owners}
                        classifications={this.props.policy.employersWcCustomInfo.classifications}
                        dotProp={`employersWcCustomInfo.insureds.${this.props.policy.employersWcCustomInfo.insureds.findIndex(x => x.guid === this.state.currentInsured) }.`}
                        backEvent={() => this.setState({ mode: `insuredSummaryView`, currentInsured: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReduceParameter={this.props.doReduceParameter}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        doExecutePolicyAction={this.props.doExecutePolicyAction}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
                </Container>
            </React.Fragment>
        );
    }
}

export default ProfileEmployersWcInsureds;