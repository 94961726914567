import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Alignments,
    Form, FormChecklist, FormDate,
    FormDropDown,
    FormInput, FormSwitch, Label, Separator, Sizes,
} from 'metrix-common';

class ProfileInterwestPlBusinessInfo extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            <>
                <Form
                    header={<FormattedMessage id='Business Info' />}
                    label={<FormattedMessage id='Information' />}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormDropDown
                        label={<FormattedMessage id='Please select an additional insured type:' />}
                        value={{ code: this.props.policy.interWestPLCustomInfo.businessInfo.businessEntityTypeCode }}
                        references={this.props.reference?.references['InterWest-PL'] && this.props.reference.references['InterWest-PL'].EntityTypes}
                        referencesEvent={() => this.props.reference.doGetReferences('InterWest-PL.EntityTypes')}
                        changeEvent={value => {
                            this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.businessInfo.businessEntityTypeCode', value: value.code });
                        }}
                        allowEdit
                    />
                    <FormInput
                        label={<FormattedMessage id='Business Website' />}
                        value={this.props.policy.interWestPLCustomInfo.businessInfo.businessWebsite}
                        type='text'
                        changeEvent={value => this.props.doReducePolicyElement({
                            code: 'interWestPLCustomInfo.businessInfo.businessWebsite',
                            value
                        })}
                        allowEdit
                    />
                    <FormInput
                        label={<FormattedMessage id='Year of Establishment' />}
                        value={this.props.policy.interWestPLCustomInfo.businessInfo.businessYearEstablishedNumber}
                        type='number'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.businessInfo.businessYearEstablishedNumber', value })}
                        maxValue={9999}
                        allowEdit
                    />
                </Form>
                <Form
                    label={<FormattedMessage id='Additional Information' />}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormSwitch
                        label={<FormattedMessage id='Would you like your agent to provide a Business Owners Policy quote?' />}
                        value={this.props.policy.interWestPLCustomInfo.businessInfo.bopRequested ?? false}
                        changeEvent={
                            value => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestPLCustomInfo.businessInfo.bopRequested',
                                    value: value
                                });
                            }}
                        allowEdit
                    />
                    <FormInput
                        label={<FormattedMessage id='Would you like to provide any additional information? Please provide any additional information including memberships to any professional organizations (NAFT, NAEA, CSEA, etc.).' />}
                        value={this.props.policy.interWestPLCustomInfo.businessInfo.additionalInformationOne}
                        type='multiline'
                        changeEvent={value => this.props.doReducePolicyElement({
                            code: 'interWestPLCustomInfo.businessInfo.additionalInformationOne',
                            value
                        })}
                        allowEdit
                    />
                </Form>
                <Form
                    label={<FormattedMessage id='Peer Review' />}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormSwitch
                        label={<FormattedMessage id='Is the firm subject to Peer Review?' />}
                        value={this.props.policy.interWestPLCustomInfo.businessInfo.isFirmSubjectToPeerReview ?? false}
                        changeEvent={
                            value => {
                                this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.businessInfo.isFirmSubjectToPeerReview', value: value });
                            }}
                        allowEdit
                    />
                    {
                        this.props.policy.interWestPLCustomInfo.businessInfo.isFirmSubjectToPeerReview &&
                        <FormSwitch
                            label={<FormattedMessage id='Is the firm in compliance with all peer review requirements?' />}
                            value={this.props.policy.interWestPLCustomInfo.businessInfo.isFirmCompliantWithAllPeerReviewRequirements ?? false}
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.businessInfo.isFirmCompliantWithAllPeerReviewRequirements', value: value });
                                }}
                            allowEdit
                        />
                    }
                    {
                        this.props.policy.interWestPLCustomInfo.businessInfo.isFirmSubjectToPeerReview &&
                        <FormDate
                            label={<FormattedMessage id='Date of most recent review' />}
                            value={this.props.policy.interWestPLCustomInfo.businessInfo.mostRecentReviewDate}
                            changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.businessInfo.mostRecentReviewDate', value })}
                            culture={this.props.context.session.culture}
                            // mode='past'
                            allowEdit
                        />
                    }
                    {
                        this.props.policy.interWestPLCustomInfo.businessInfo.isFirmSubjectToPeerReview &&
                        <FormDropDown
                            label={<FormattedMessage id='Result of most recent review' />}
                            value={{ code: this.props.policy.interWestPLCustomInfo.businessInfo.mostRecentReviewCode }}
                            references={this.props.reference?.references['InterWest-PL'] && this.props.reference.references['InterWest-PL'].MostRecentReviewResults}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-PL.MostRecentReviewResults')}
                            changeEvent={value => {
                                this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.businessInfo.mostRecentReviewCode', value: value.code });
                            }}
                            allowEdit
                        />
                    }
                </Form>
                <Form
                    label={<FormattedMessage id='Revenue' />}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormSwitch
                        label={<FormattedMessage id='Is the firm’s annual revenue from the prior fiscal year, or anticipated revenue for the current fiscal year, more than $100,000?' />}
                        value={this.props.policy.interWestPLCustomInfo.businessInfo.hasOverOneHundredRevenue ?? false}
                        changeEvent={
                            value => {
                                this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.businessInfo.hasOverOneHundredRevenue', value: value });
                            }}
                        allowEdit
                    />
                    {
                        this.props.policy.interWestPLCustomInfo.businessInfo.hasOverOneHundredRevenue &&
                        <FormInput
                            label={<FormattedMessage
                                id='What was the firm’s actual 12 month revenue for the prior fiscal year?' />}
                            value={this.props.policy.interWestPLCustomInfo.businessInfo.firmCurrentRevenueAmount}
                            type='number'
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({
                                        code: 'interWestPLCustomInfo.businessInfo.firmCurrentRevenueAmount',
                                        value: value
                                    });
                                }
                            }
                            allowEdit
                        />
                    }
                    {
                        this.props.policy.interWestPLCustomInfo.businessInfo.hasOverOneHundredRevenue &&
                        <FormInput
                            label={<FormattedMessage id='What is the firm’s anticipated 12 month revenue for the current fiscal year? Combine the actual revenue to date and estimated revenue until the end of the fiscal year.' />}
                            value={this.props.policy.interWestPLCustomInfo.businessInfo.firmPriorRevenueAmount}
                            type='number'
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.businessInfo.firmPriorRevenueAmount', value: value });
                                }
                            }
                            allowEdit
                        />
                    }
                </Form>
                {
                    !this.props.policy.interWestPLCustomInfo.businessInfo.hasOverOneHundredRevenue &&
                    <Form
                        label={<FormattedMessage id='General' />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormChecklist
                            label={<FormattedMessage id='Please check if true' />}
                            value={this.props.policy.interWestPLCustomInfo.businessInfo.ltoApplicantQuestionsSelectedItems}
                            references={this.props.reference.references['InterWest-PL'] && this.props.reference.references['InterWest-PL'].LTOApplicantQuestions}
                            referencesEvent={() => this.props.reference.doGetReferences('InterWest-PL.LTOApplicantQuestions')}
                            changeEvent={values => {
                                this.props.doReducePolicyElement({
                                    code: 'interWestPLCustomInfo.businessInfo.ltoApplicantQuestionsSelectedItems',
                                    value: values
                                });
                            }}
                            allowEdit
                        />
                    </Form>
                }
                {
                    this.props.policy.interWestPLCustomInfo.businessInfo.hasOverOneHundredRevenue &&
                    <Form
                        label={<FormattedMessage id='Firm Staff' />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormInput
                            label={<FormattedMessage id='Total Owners, Partners, Shareholders' />}
                            value={this.props.policy.interWestPLCustomInfo.businessInfo.totalOwnersPartnersShareholdersCount}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestPLCustomInfo.businessInfo.totalOwnersPartnersShareholdersCount',
                                value
                            })}
                            allowEdit
                        />
                        <FormInput
                            label={<FormattedMessage id='CPA Owners, Partners, Shareholders' />}
                            value={this.props.policy.interWestPLCustomInfo.businessInfo.cpaOwnersPartnersShareholdersCount}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestPLCustomInfo.businessInfo.cpaOwnersPartnersShareholdersCount',
                                value
                            })}
                            allowEdit
                        />
                        <Label
                            bold
                            text={`Non-CPA: ${parseInt(this.props.policy.interWestPLCustomInfo.businessInfo.totalOwnersPartnersShareholdersCount ?? 0) - parseInt(this.props.policy.interWestPLCustomInfo.businessInfo.cpaOwnersPartnersShareholdersCount ?? 0)}`}
                            textAlign={Alignments.right}
                        />
                        <FormInput
                            label={<FormattedMessage id='Total All Other Accounting or Tax Professionals' />}
                            value={this.props.policy.interWestPLCustomInfo.businessInfo.totalOtherAccountTaxProfessionalsCount}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestPLCustomInfo.businessInfo.totalOtherAccountTaxProfessionalsCount',
                                value
                            })}
                            allowEdit
                        />
                        <FormInput
                            label={<FormattedMessage id='CPA All Other Accounting or Tax Professionals' />}
                            value={this.props.policy.interWestPLCustomInfo.businessInfo.cpaOtherAccountTaxProfessionalsCount}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestPLCustomInfo.businessInfo.cpaOtherAccountTaxProfessionalsCount',
                                value
                            })}
                            allowEdit
                        />
                        <Label
                            bold
                            text={`Non-CPA: ${parseInt(this.props.policy.interWestPLCustomInfo.businessInfo.totalOtherAccountTaxProfessionalsCount ?? 0) - parseInt(this.props.policy.interWestPLCustomInfo.businessInfo.cpaOtherAccountTaxProfessionalsCount ?? 0)}`}
                            textAlign={Alignments.right}
                        />
                        <FormInput
                            label={<FormattedMessage id='Total Other Consulting Professionals (not included above)' />}
                            value={this.props.policy.interWestPLCustomInfo.businessInfo.totalOtherConsultingProfessionalsCount}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestPLCustomInfo.businessInfo.totalOtherConsultingProfessionalsCount',
                                value
                            })}
                            allowEdit
                        />
                        <FormInput
                            label={<FormattedMessage id='CPA Other Consulting Professionals (not included above)' />}
                            value={this.props.policy.interWestPLCustomInfo.businessInfo.cpaOtherConsultingProfessionalsCount}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestPLCustomInfo.businessInfo.cpaOtherConsultingProfessionalsCount',
                                value
                            })}
                            allowEdit
                        />
                        <Label
                            bold
                            text={`Non-CPA: ${parseInt(this.props.policy.interWestPLCustomInfo.businessInfo.totalOtherConsultingProfessionalsCount ?? 0) - parseInt(this.props.policy.interWestPLCustomInfo.businessInfo.cpaOtherConsultingProfessionalsCount ?? 0)}`}
                            textAlign={Alignments.right}
                        />
                        <FormInput
                            label={<FormattedMessage id='Total Administrative Staff' />}
                            value={this.props.policy.interWestPLCustomInfo.businessInfo.totalAdministrativeCount}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestPLCustomInfo.businessInfo.totalAdministrativeCount',
                                value
                            })}
                            allowEdit
                        />
                        <FormInput
                            label={<FormattedMessage id='CPA Administrative Staff' />}
                            value={this.props.policy.interWestPLCustomInfo.businessInfo.cpaAdministrativeCount}
                            type='number'
                            changeEvent={value => this.props.doReducePolicyElement({
                                code: 'interWestPLCustomInfo.businessInfo.cpaAdministrativeCount',
                                value
                            })}
                            allowEdit
                        />
                        <Label
                            bold
                            text={`Non-CPA: ${parseInt(this.props.policy.interWestPLCustomInfo.businessInfo.totalAdministrativeCount ?? 0) - parseInt(this.props.policy.interWestPLCustomInfo.businessInfo.cpaAdministrativeCount ?? 0)}`}
                            textAlign={Alignments.right}
                        />
                        <Separator orientation="horizontal"
                            top={Sizes.sm}
                            bottom={Sizes.sm}
                            solid
                        />
                        <Label
                            bold
                            text={`Total of all staff: ${parseInt(this.props.policy.interWestPLCustomInfo.businessInfo.totalOwnersPartnersShareholdersCount ?? 0) +
                                parseInt(this.props.policy.interWestPLCustomInfo.businessInfo.totalOtherAccountTaxProfessionalsCount ?? 0) +
                                parseInt(this.props.policy.interWestPLCustomInfo.businessInfo.totalOtherConsultingProfessionalsCount ?? 0) +
                                parseInt(this.props.policy.interWestPLCustomInfo.businessInfo.totalAdministrativeCount ?? 0)
                                }`}
                            textAlign={Alignments.right}
                        />
                    </Form>
                }
                {
                    this.props.policy.interWestPLCustomInfo.businessInfo.hasOverOneHundredRevenue &&
                    <Form
                        label={<FormattedMessage id='Possible Changes' />}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormSwitch
                            label={<FormattedMessage id='Have there been in the past 12 months, or are there anticipated in the next 12 months, any material changes to the firm including mergers, acquisitions, restructuring, or the addition of a new client industry or change to the scope of practice?' />}
                            value={this.props.policy.interWestPLCustomInfo.businessInfo.hasFirmOrIndustryPossibleChanges ?? false}
                            changeEvent={
                                value => {
                                    this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.businessInfo.hasFirmOrIndustryPossibleChanges', value: value });
                                }}
                            allowEdit
                        />
                        {
                            this.props.policy.interWestPLCustomInfo.businessInfo.hasFirmOrIndustryPossibleChanges &&
                            <FormInput
                                label={<FormattedMessage id='If yes, please explain' />}
                                value={this.props.policy.interWestPLCustomInfo.businessInfo.firmOrIndustryPossibleChangesExplaination}
                                type='text'
                                changeEvent={
                                    value => {
                                        this.props.doReducePolicyElement({ code: 'interWestPLCustomInfo.businessInfo.firmOrIndustryPossibleChangesExplaination', value: value });
                                    }
                                }
                                allowEdit
                            />
                        }
                    </Form>
                }
            </>
        );
    }
}

export default ProfileInterwestPlBusinessInfo;