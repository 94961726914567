import React from 'react';

import {
    Sizes,
    Alignments,
    Container,
    Divider,
    Header,
    SummaryGroup
} from 'metrix-common';

import Resident from './Resident';

class ProfileResidents extends React.Component {
    state = {
        mode: `summary`,
        current: null
    };

    componentDidMount() {
        this.props.doGetPolicy();
    }

    getResidents = () => {
        let residents = [];

        if (this.props.policy &&
            this.props.policy.residents &&
            this.props.policy.residents.length > 0) {
            this.props.policy.residents.forEach((resident) => {
                let option = { code: `resident`, value: resident.guid };

                let residentName = `Resident ${resident.number || ``}`;

                if (resident.party && resident.party.name) {
                    residentName = `${resident.party.name.firstName} ${resident.party.name.lastName}`;
                }

                option.elements = [];

                option.elements.push({ code: `Label`, value: residentName });

                residents.push(option);
            });
        }

        return residents;
    }

    render() {
        let residents = this.getResidents();
        return (
            <Container>
                {this.state.mode === `summary` &&
                    <React.Fragment>
                        <Divider top={Sizes.xl} />
                        <Header size={Sizes.lg} textAlign={Alignments.left} text={`Residents`} />
                        <Container top={Sizes.xl} bottom={Sizes.xl}>
                        <SummaryGroup
                            allowAdd={this.props.allowEdit}
                            addEvent={() => {
                                this.props.doReduceParameters([]);
                                this.props.doExecutePolicyAction({ code: `CREATERESIDENT` });
                            }}
                            allowEdit
                            editEvent={(resident) => {
                                this.setState({ current: resident, mode: `resident` });
                                this.props.doReduceParameters([{ code: `Resident`, value: resident }]);
                            }}
                            allowDelete={this.props.allowEdit}
                            deleteEvent={(resident) => {
                                this.props.doReduceParameters([{ code: `Resident`, value: resident }]);
                                this.props.doExecutePolicyAction({ code: `DELETERESIDENT` });
                            }}
                            entities={residents}
                            icon={`person`}
                        />
                        </Container>
                    </React.Fragment>
                }
                {this.state.mode === `resident` &&
                    <Resident resident={this.props.policy.residents.find(x => x.guid === this.state.current)}
                        dotProp={`residents.${this.props.policy.residents.findIndex(x => x.guid === this.state.current)}.`}
                        backEvent={() => this.setState({ mode: `summary`, current: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReduceParameter={this.props.doReduceParameter}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        doExecutePolicyAction={this.props.doExecutePolicyAction}
                        reference={this.props.reference}
                        context={this.props.context}
                          allowEdit={this.props.allowEdit}
                    />
                }
            </Container>
        );
    }
}

export default ProfileResidents;