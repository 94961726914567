import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Alignments,
    FormSwitch,
    Form,
    FormTextArea,
    FormRadio,
    FormInput,
    Label,
    convertFromBoolean,
    convertToBoolean
} from 'metrix-common';

class ProfileFluxGlLessorsRisk extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            <React.Fragment>
                <Form
                    header={<FormattedMessage id={`Type of Occupancy?`} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormSwitch
                        label={<FormattedMessage id={`Dispensaries/Retail`} />}
                        value={this.props.policy.fluxCustomInfo.lessorsRisk?.hasDispensariesOrRetail}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.lessorsRisk.hasDispensariesOrRetail`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Garden/Hydroponics`} />}
                        value={this.props.policy.fluxCustomInfo.lessorsRisk?.hasGardenOrHydroponics}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.lessorsRisk.hasGardenOrHydroponics`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Indoor/Greenhouse`} />}
                        value={this.props.policy.fluxCustomInfo.lessorsRisk?.hasIndoorOrGreenhouse}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.lessorsRisk.hasIndoorOrGreenhouse`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Laboratories`} />}
                        value={this.props.policy.fluxCustomInfo.lessorsRisk?.hasLaboratories}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.lessorsRisk.hasLaboratories`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Management Offices`} />}
                        value={this.props.policy.fluxCustomInfo.lessorsRisk?.hasManagementOffices}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.lessorsRisk.hasManagementOffices`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Manufacturers`} />}
                        value={this.props.policy.fluxCustomInfo.lessorsRisk?.hasManufacturers}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.lessorsRisk.hasManufacturers`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Outdoor Cultivation`} />}
                        value={this.props.policy.fluxCustomInfo.lessorsRisk?.hasOutdoorCultivation}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.lessorsRisk.hasOutdoorCultivation`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Solvent Extraction`} />}
                        value={this.props.policy.fluxCustomInfo.lessorsRisk?.hasSolventExtraction}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.lessorsRisk.hasSolventExtraction`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Third Party Processors/Harvesters`} />}
                        value={this.props.policy.fluxCustomInfo.lessorsRisk?.hasThirdPartyProcessorsOrHarvesters}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.lessorsRisk.hasThirdPartyProcessorsOrHarvesters`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Wholesale/Distributors`} />}
                        value={this.props.policy.fluxCustomInfo.lessorsRisk?.hasWholesaleORDistributors}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.lessorsRisk.hasWholesaleORDistributors`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Other`} />}
                        value={this.props.policy.fluxCustomInfo.lessorsRisk?.hasOtherOccupancy}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.lessorsRisk.hasOtherOccupancy`, value: value })}
                        allowEdit
                    />
                    {this.props.policy.fluxCustomInfo.lessorsRisk?.hasOtherOccupancy === true &&
                        <Label bold text={<FormattedMessage id={`Please describe:`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.fluxCustomInfo.lessorsRisk?.hasOtherOccupancy === true &&
                        <FormTextArea
                            text={this.props.policy.fluxCustomInfo.lessorsRisk?.otherOccupancyDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.lessorsRisk.otherOccupancyDescription`, value })}
                        />
                    }
                </Form>
                <Form
                    header={<FormattedMessage id={`Lessors risk exposures`} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <Label bold text={<FormattedMessage id={`List names of tenants (or attach List):`} />} textAlign={Alignments.left} />
                    <FormTextArea
                        text={this.props.policy.fluxCustomInfo.lessorsRisk?.tenantsInformation}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.lessorsRisk.tenantsInformation`, value })}
                    />
                    <FormInput
                        label={<FormattedMessage id={`Occupied square footage of all buildings to be covered:`} />}
                        value={this.props.policy.fluxCustomInfo.lessorsRisk?.occupiedSquareFootageInAllBuildings}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.lessorsRisk.occupiedSquareFootageInAllBuildings`, value })}
                        type='number'
                        allowEdit
                    />
                    <FormInput
                        label={<FormattedMessage id={`Vacant square footage (if any):`} />}
                        value={this.props.policy.fluxCustomInfo.lessorsRisk?.vacantSquareFootage}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.lessorsRisk.vacantSquareFootage`, value })}
                        type='number'
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={`Who is responsible for the care and maintenance of the property? (Buildings, sidewalks, and parking lots)`} />}
                        value={{ code: this.props.policy.fluxCustomInfo.lessorsRisk?.maintenanceResponsibleTypeCode }}
                        references={this.props.reference?.references[`FLUX-GL`] && this.props.reference.references[`FLUX-GL`][`MaintenanceResponsibleTypes`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`FLUX-GL.MaintenanceResponsibleTypes`)}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `fluxCustomInfo.lessorsRisk.maintenanceResponsibleTypeCode`, value: value.code });
                            this.props.doReducePolicyElement({ code: `fluxCustomInfo.lessorsRisk.maintenanceResponsibleTypeName`, value: value.value });
                        }}
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={`Do lease agreements contain Hold Harmless wording in insured's favor?`} />}
                        value={this.props.policy.fluxCustomInfo.lessorsRisk?.hasHoldHarmlessInLeaseAgreement !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.lessorsRisk.hasHoldHarmlessInLeaseAgreement) }}
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.lessorsRisk.hasHoldHarmlessInLeaseAgreement`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={`Does insured have common ownership and/or financial interest in any of the tenant's businesses?`} />}
                        value={this.props.policy.fluxCustomInfo.lessorsRisk?.hasFinancialInterestInTenantsBusiness !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.lessorsRisk.hasFinancialInterestInTenantsBusiness) }}
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.lessorsRisk.hasFinancialInterestInTenantsBusiness`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.fluxCustomInfo.lessorsRisk?.hasFinancialInterestInTenantsBusiness === true && 
                        <Label bold text={<FormattedMessage id={`Please describe:`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.fluxCustomInfo.lessorsRisk?.hasFinancialInterestInTenantsBusiness === true &&
                        <FormTextArea
                            text={this.props.policy.fluxCustomInfo.lessorsRisk?.financialInterestInTenantsBusinessDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.lessorsRisk.financialInterestInTenantsBusinessDescription`, value })}
                        />
                    }
                    <FormRadio
                        label={<FormattedMessage id={`Will tenant(s) perform Extractions?`} />}
                        value={this.props.policy.fluxCustomInfo.lessorsRisk?.hasTenantsPerformingExtraction !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.lessorsRisk.hasTenantsPerformingExtraction) }}
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.lessorsRisk.hasTenantsPerformingExtraction`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                </Form>
                <Form
                    header={<FormattedMessage id={`Insurance requirements`} />}
                    label={`Extraction exposures require tenants to have $300,000 in Damage to Rented Premises`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormRadio
                        label={<FormattedMessage id={`Are all tenants required to carry their own Commercial General Liability coverage?`} />}
                        value={this.props.policy.fluxCustomInfo.lessorsRisk?.areTenantsCarryAboutCGLCoverage !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.lessorsRisk.areTenantsCarryAboutCGLCoverage) }}
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.lessorsRisk.areTenantsCarryAboutCGLCoverage`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.fluxCustomInfo.lessorsRisk?.areTenantsCarryAboutCGLCoverage === true &&
                        <FormInput
                            label={<FormattedMessage id={`What limits are required?`} />}
                            value={this.props.policy.lessorsRisk?.tenantsCarryAboutCGLCoverageLimitAmount}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.lessorsRisk.tenantsCarryAboutCGLCoverageLimitAmount`, value })}
                            type='number'
                            allowEdit
                        />
                    }
                    <FormRadio
                        label={<FormattedMessage id={`Are all tenants required to name the insured as Additional Insured on their CGL policies?`} />}
                        value={this.props.policy.fluxCustomInfo.lessorsRisk?.isApplicantAsAdditionalInsuredInTennantsInsurance !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.lessorsRisk.isApplicantAsAdditionalInsuredInTennantsInsurance) }}
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.lessorsRisk.isApplicantAsAdditionalInsuredInTennantsInsurance`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={`Does insured collect Certificates of Insurance on an annual basis from all tenants?`} />}
                        value={this.props.policy.fluxCustomInfo.lessorsRisk?.hasCollectCOIFromTenants !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.lessorsRisk.hasCollectCOIFromTenants) }}
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.lessorsRisk.hasCollectCOIFromTenants`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                </Form>
            </React.Fragment>
        );
    }
}

export default ProfileFluxGlLessorsRisk;