import React, { Component } from 'react';

import {
    NavBar
} from 'metrix-common';

import configs from './config.json';
import IconList from '../../../icons';
import ProductHelper from '../../../helpers'

class ProfileSideBar extends Component {
    clickEventHandler = value => {
        if (value === 'policies') {
            window.location = '/';
        } else {
            this.props.setActiveTab(value);
            window.location.hash = value;
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }

    render() {
        let tabs = [];
        let product = null;
        let defaultTab = '';
        let isRenewal = this.props.policySummary && (this.props.policySummary.type === 'renewal');
        const isAPILogsVisible = this.props.context.configuration.apiLogsVisibleToBroker;
        const isBroker = this.props.context.session.userType === 'broker';

        if (this.props.policy && this.props.policy.product) {
            product = this.props.policy.product;
        }

        if (!product && this.props.policySummary && this.props.policySummary.product) {
            product = this.props.policySummary.product;
        }

        if (product) {
            let userMenu = configs.filter(config => config.userType === this.props.context.session.userType)[0].menu;

            // History tab is temporary hidden
            userMenu = userMenu.filter(config => config.code !== 'history');

            if (isBroker && !isAPILogsVisible) {
                userMenu = userMenu.filter(config => config.code !== 'logs');
            }

            userMenu.forEach((config) => {
                if (config.code.includes('.') && product !== 'ppkge') {
                    let tabCode = config.code.split('.')[0];
                    if (tabCode === product.toLowerCase()) {
                        tabs.push(config);
                    }
                }
                else {
                    if (isRenewal && config.code === 'summary') {
                        defaultTab = 'renewal';
                    }
                    else if (!isRenewal && config.code === 'renewal') {
                        defaultTab = 'summary';
                    }
                    else if (ProductHelper.hasLiabilityExposures(this.props.policySummary?.product) && config.code === 'liabilityExposure') {
                        defaultTab = 'summary';
                        tabs.push(config);
                    }
                    else if (config.code !== 'liabilityExposure'){
                        tabs.push(config);
                    }
                }
            });
        }

        let tabName = window.location.hash;
        tabName = tabName.split('#')[1];
        if (tabName) {
            defaultTab = tabName;
        }

        if (!this.props.activeTab) {
            this.props.setActiveTab(defaultTab);
        }

        return (
            <React.Fragment>
                {
                    this.props.policySummary &&
                    <NavBar
                        tabs={tabs}
                        clickEvent={this.clickEventHandler}
                        defaultTab={defaultTab}
                        IconList={IconList}
                        validationInfo={this.props.validationInfo} />
                }
            </React.Fragment>
        );
    }
}

export default ProfileSideBar;