import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    convertFromBoolean,
    convertToBoolean,
    setAttributeEntity,
    Sizes,
    Alignments,
    BackButton,
    Divider,
    Header,
    Form,
    FormDropDown,
    FormRadio
} from 'metrix-common';

class ProfileDwellingWaterMitigationSystem extends React.Component {
    render() {
        return (
            <React.Fragment>
                <BackButton clickEvent={this.props.backEvent} />
                <Divider top={Sizes.md} />
                <Header size={Sizes.md} textAlign={Alignments.left} text={`Water Mitigation System`} />
                <Form
                    label={`Water Mitigation Details`}
                    allowEdit={this.props.allowEdit}
                    saveEvent={() => this.props.doUpsertPolicy()}
                    cancelEvent={() => this.props.doGetPolicy()}
                >
                    <FormDropDown
                        label={<FormattedMessage id={`Sump Pump Type`} />}
                        value={this.props.waterMitigationSystem && this.props.waterMitigationSystem.sumpPumpType}
                        references={this.props.reference.references && this.props.reference.references[`SumpPumpType`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`SumpPumpType`)}
                        changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}sumpPumpType`, value, this.props.doReducePolicyElement)}
                        allowEdit
                    />
                    <FormDropDown
                        label={<FormattedMessage id={`Backup Valve Type`} />}
                        value={this.props.waterMitigationSystem && this.props.waterMitigationSystem.backupValveType}
                        references={this.props.reference.references && this.props.reference.references[`SewerBackupValve`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`SewerBackupValve`)}
                        changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}backupValveType`, value, this.props.doReducePolicyElement)}
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={`Auxiliary Power`} />}
                        value={this.props.waterMitigationSystem &&
                            { code: convertFromBoolean(this.props.waterMitigationSystem.auxiliaryPower) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}auxiliaryPower`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={`Connected To Alarm`} />}
                        value={this.props.waterMitigationSystem &&
                            { code: convertFromBoolean(this.props.waterMitigationSystem.connectedToAlarm) }}
                        references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}connectedToAlarm`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.allowPrimary &&
                        <FormRadio
                            label={<FormattedMessage id={`Primary`} />}
                            value={this.props.waterMitigationSystem &&
                                { code: convertFromBoolean(this.props.waterMitigationSystem.primary) }}
                            references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}primary`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                    }
                </Form>
            </React.Fragment>
        );
    }
}

export default ProfileDwellingWaterMitigationSystem;