import { put, call, select } from 'redux-saga/effects';
import { reduceOperation, reduceLoading, getSessionSaga } from 'metrix-common';
import { selectQuery } from '../../selectors/policies/selectQuery';
import { selectView } from '../../selectors/policies/selectView';
import { reduceBoundPolicies } from '../../actions/policies/reduceBoundPolicies';

export function* queryBoundPoliciesSaga(action) {
    try {
        yield put(reduceLoading({ code: 'queryBoundPolicies', value: true }));
        const session = yield getSessionSaga();
        const view = yield select(selectView());
        let query = yield select(selectQuery());

        query = query[`${view}`];

        const response = yield call(fetch, '/api/policyTransaction/bound', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Authorization': session.authenticationToken.token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ policyQuery: { ...action.payload, ...query } })
        });

        const result = yield call([response, 'json']);
        
        yield put(reduceOperation(result.operation));
        
        if (!result.operation.status) return;

        yield put(reduceBoundPolicies(result.searchResult));
    }
    catch (error) {
        console.log('Error while calling Query Policies API - ', error);
    }
    finally {
        yield put(reduceLoading({ code: 'queryBoundPolicies', value: false }));
    }
}
