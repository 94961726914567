import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Form,
    FormDropDown,
    FormInput
} from 'metrix-common';

class ProfileInterwestGlpcBusiness extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            <>
                <Form
                    header={<FormattedMessage id='Business Information' />}
                    label='Details'
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormInput
                        label={<FormattedMessage id='Legal Business Name' />}
                        value={this.props.policy.interWestGLPCCustomInfo.businessInfo.businessName}
                        type='text'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.businessInfo.businessName', value })}
                        allowEdit
                    />
                    <FormDropDown
                        label={<FormattedMessage id='Business Structure' />}
                        value={{ code: this.props.policy.interWestGLPCCustomInfo.businessInfo.businessEntityTypeCode }}
                        references={this.props.reference?.references['InterWest-GLPC'] && this.props.reference.references['InterWest-GLPC'].EntityTypes}
                        referencesEvent={() => this.props.reference.doGetReferences('InterWest-GLPC.EntityTypes')}
                        changeEvent={value => {
                            this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.businessInfo.businessEntityTypeCode', value: value.code });
                        }}
                        allowEdit
                    />
                    <FormInput
                        label='Year the Business was Established'
                        value={this.props.policy.interWestGLPCCustomInfo.businessInfo.businessYearEstablishedNumber}
                        type='number'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.businessInfo.businessYearEstablishedNumber', value })}
                        maxValue={9999}
                        allowEdit
                    />
                </Form>
                <Form
                    header={<FormattedMessage id='Employee Information' />}
                    label='Details'
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormInput
                        label={<FormattedMessage id='Owner Payroll' />}
                        value={this.props.policy.interWestGLPCCustomInfo.businessInfo.ownerPayrollAmount}
                        type='money'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.businessInfo.ownerPayrollAmount', value })}
                        allowEdit
                    />
                    <FormInput
                        label={<FormattedMessage id='Number of Owners' />}
                        value={this.props.policy.interWestGLPCCustomInfo.businessInfo.ownerCount}
                        type='number'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.businessInfo.ownerCount', value })}
                        allowEdit
                    />
                    <FormInput
                        label={<FormattedMessage id='Full-time Employee Payroll' />}
                        value={this.props.policy.interWestGLPCCustomInfo.businessInfo.fullTimeEmployeePayrollAmount}
                        type='money'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.businessInfo.fullTimeEmployeePayrollAmount', value })}
                        allowEdit
                    />
                    <FormInput
                        label={<FormattedMessage id='Number of Full-Time Employees' />}
                        value={this.props.policy.interWestGLPCCustomInfo.businessInfo.fullTimeEmployeeCount}
                        type='number'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.businessInfo.fullTimeEmployeeCount', value })}
                        allowEdit
                    />
                    <FormInput
                        label={<FormattedMessage id='Part-time Employee Payroll' />}
                        value={this.props.policy.interWestGLPCCustomInfo.businessInfo.partTimeEmployeePayrollAmount}
                        type='money'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.businessInfo.partTimeEmployeePayrollAmount', value })}
                        allowEdit
                    />
                    <FormInput
                        label={<FormattedMessage id='Number of Part-time Employees' />}
                        value={this.props.policy.interWestGLPCCustomInfo.businessInfo.partTimeEmployeeCount}
                        type='number'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.businessInfo.partTimeEmployeeCount', value })}
                        allowEdit
                    />
                </Form>
                <Form
                    header={<FormattedMessage id='Sales' />}
                    label='Details'
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormInput
                        label={<FormattedMessage id='Internet Sales' />}
                        value={this.props.policy.interWestGLPCCustomInfo.businessInfo.internetSalesAmount}
                        type='money'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.businessInfo.internetSalesAmount', value })}
                        allowEdit
                    />
                    <FormInput
                        label={<FormattedMessage id='Retail Sales' />}
                        value={this.props.policy.interWestGLPCCustomInfo.businessInfo.retailSalesAmount}
                        type='money'
                        changeEvent={value => this.props.doReducePolicyElement({ code: 'interWestGLPCCustomInfo.businessInfo.retailSalesAmount', value })}
                        allowEdit
                    />
                </Form>
            </>
        );
    }
}

export default ProfileInterwestGlpcBusiness;