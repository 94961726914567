import React from 'react';

const Icon = props => {
    const getIcon = (code, color) => {
        let icon = null;

        switch (code) {
            case `Dashboard`:
                icon = (
                    <svg id="Dashboard_selected" data-name="Dashboard selected" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                        <path id="Path_12553" data-name="Path 12553" d="M19,5V7H15V5h4M9,5v6H5V5H9m10,8v6H15V13h4M9,17v2H5V17H9M21,3H13V9h8ZM11,3H3V13h8Zm10,8H13V21h8ZM11,15H3v6h8Z" transform="translate(-3 -3)" fill={color} />
                    </svg>
                );
                break;
            case `Clients`:
                icon = (
                    <svg id="Clients" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                        <path id="Path_12555" data-name="Path 12555" d="M9,12A3.5,3.5,0,1,0,5.5,8.5,3.5,3.5,0,0,0,9,12ZM9,7A1.5,1.5,0,1,1,7.5,8.5,1.5,1.5,0,0,1,9,7Zm.05,10H4.77A10.374,10.374,0,0,1,9,16c.11,0,.23.01.34.01a4.58,4.58,0,0,1,1.64-1.81A11.726,11.726,0,0,0,9,14c-2.34,0-7,1.17-7,3.5V19H9V17.5A2.734,2.734,0,0,1,9.05,17Zm7.45-2.5c-1.84,0-5.5,1.01-5.5,3V19H22V17.5C22,15.51,18.34,14.5,16.5,14.5Zm1.21-1.82a2.5,2.5,0,1,0-2.42,0,2.448,2.448,0,0,0,2.42,0Z" transform="translate(-2 -5)" fill={color} />
                    </svg>
                );
                break;
            case 'Leads':
            case 'Newquote':
                icon = (
                    <svg id="Leads" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                        <path id="Path_12563" data-name="Path 12563" d="M13,11H11v3H8v2h3v3h2V16h3V14H13Zm1-9H6A2.006,2.006,0,0,0,4,4V20a2,2,0,0,0,1.99,2H18a2.006,2.006,0,0,0,2-2V8Zm4,18H6V4h7V9h5Z" transform="translate(-4 -2)" fill={color} />
                    </svg>
                );
                break;
            case `Policies`:
                icon = (
                    <svg id="Cases" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                        <path id="Path_12557" data-name="Path 12557" d="M7,15h7v2H7Zm0-4H17v2H7ZM7,7H17V9H7ZM19,3H14.82A2.988,2.988,0,0,0,9.18,3H5a1.752,1.752,0,0,0-.4.04A2.021,2.021,0,0,0,3.16,4.23,1.926,1.926,0,0,0,3,5V19a2.052,2.052,0,0,0,.16.78,2.119,2.119,0,0,0,.43.64,2.008,2.008,0,0,0,1.01.55A2.6,2.6,0,0,0,5,21H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3Zm-7-.25a.75.75,0,1,1-.75.75A.755.755,0,0,1,12,2.75ZM19,19H5V5H19Z" transform="translate(-3 -1)" fill={color} />
                    </svg>
                );
                break;
            case `Brokerages`:
                icon = (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill={color}><path d="M0 0h24v24H0V0z" fill="none" /><path d="M18.36 9l.6 3H5.04l.6-3h12.72M20 4H4v2h16V4zm0 3H4l-1 5v2h1v6h10v-6h4v6h2v-6h1v-2l-1-5zM6 18v-4h6v4H6z" /></svg>
                );
                break;
            case `Brokers`:
                icon = (
                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill={color}><rect fill="none" height="24" width="24" /><g><path d="M4,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C2,12.1,2.9,13,4,13z M5.13,14.1C4.76,14.04,4.39,14,4,14 c-0.99,0-1.93,0.21-2.78,0.58C0.48,14.9,0,15.62,0,16.43V18l4.5,0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,13c1.1,0,2-0.9,2-2 c0-1.1-0.9-2-2-2s-2,0.9-2,2C18,12.1,18.9,13,20,13z M24,16.43c0-0.81-0.48-1.53-1.22-1.85C21.93,14.21,20.99,14,20,14 c-0.39,0-0.76,0.04-1.13,0.1c0.4,0.68,0.63,1.46,0.63,2.29V18l4.5,0V16.43z M16.24,13.65c-1.17-0.52-2.61-0.9-4.24-0.9 c-1.63,0-3.07,0.39-4.24,0.9C6.68,14.13,6,15.21,6,16.39V18h12v-1.61C18,15.21,17.32,14.13,16.24,13.65z M8.07,16 c0.09-0.23,0.13-0.39,0.91-0.69c0.97-0.38,1.99-0.56,3.02-0.56s2.05,0.18,3.02,0.56c0.77,0.3,0.81,0.46,0.91,0.69H8.07z M12,8 c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S11.45,8,12,8 M12,6c-1.66,0-3,1.34-3,3c0,1.66,1.34,3,3,3s3-1.34,3-3 C15,7.34,13.66,6,12,6L12,6z" /></g></svg>
                );
                break;
            case `Analytics`:
                icon = (
                    <svg id="Analytics" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                        <path id="Path_12561" data-name="Path 12561" d="M5,9.2H8V19H5ZM10.6,5h2.8V19H10.6Zm5.6,8H19v6H16.2Z" transform="translate(-5 -5)" fill={color} />
                    </svg>
                );
                break;
            case `Hamburger`:
                icon = (
                    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Hamburger" transform="translate(-5.000000, -5.000000)" fill={color}>
                                <g transform="translate(5.000000, 5.000000)">
                                    <path d="M28.5626,3.0001 L1.4376,3.0001 C0.6466,3.0001 0.000599999999,2.3531 0.000599999999,1.5631 L0.000599999999,1.4381 C0.000599999999,0.6471 0.6466,0.0001 1.4376,0.0001 L28.5626,0.0001 C29.3536,0.0001 29.9996,0.6471 29.9996,1.4381 L29.9996,1.5631 C29.9996,2.3531 29.3536,3.0001 28.5626,3.0001" id="Fill-1"></path>
                                    <path d="M28.562,22 L1.437,22 C0.646,22 0,21.353 0,20.563 L0,20.438 C0,19.647 0.646,19 1.437,19 L28.562,19 C29.353,19 29.999,19.647 29.999,20.438 L29.999,20.563 C29.999,21.353 29.353,22 28.562,22" id="Fill-3"></path>
                                    <path d="M28.562,12.50005 L1.437,12.50005 C0.646,12.50005 0,11.85305 0,11.06305 L0,10.93805 C0,10.14705 0.646,9.50005 1.437,9.50005 L28.562,9.50005 C29.353,9.50005 29.999,10.14705 29.999,10.93805 L29.999,11.06305 C29.999,11.85305 29.353,12.50005 28.562,12.50005" id="Fill-6"></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                );
                break;
            case `Configuration`:
                icon = (
                    <svg id="Claims" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path id="Path_12559" data-name="Path 12559" d="M4,7H20V9H4Zm0,6H20V11H4Zm0,4h7V15H4Zm0,4h7V19H4Zm11.41-2.83L14,16.75l-1.41,1.41L15.41,21,20,16.42,18.58,15ZM4,3V5H20V3Z" transform="translate(-4 -3)" fill={color} />
                    </svg>
                    );
                break;
            case `Back`:
                icon = (
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                    </svg>
                    );
                break;
            default:
                break;
        }


        return icon;
    }

    return getIcon(props.code, props.color);
}

export default Icon;
