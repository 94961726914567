import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Form,
    FormDropDown
} from 'metrix-common';

class ProfileFluxGlExcessLiability extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            <React.Fragment>
                <Form
                    header={<FormattedMessage id={`Excess Liability`} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormDropDown
                        label={<FormattedMessage id={`Limits (NOTE: Excess cannot be applied if $2,000,000 occurrence was requested under the General Liability Limits section. Each excess layer added will apply to both the occurrence and aggregate limits. This Excess Liability applies to General Liability only and does not apply to product liability.`} />}
                        value={{ code: this.props.policy.fluxCustomInfo.excessLiability?.excessLiabilityTypeCode, value: this.props.policy.fluxCustomInfo.excessLiability?.excessLiabilityTypeName }}
                        references={this.props.reference?.references[`FLUX-GL`] && this.props.reference.references[`FLUX-GL`][`ExcessLiabilityTypes`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`FLUX-GL.ExcessLiabilityTypes`)}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `fluxCustomInfo.excessLiability.excessLiabilityTypeCode`, value: value.code });
                            this.props.doReducePolicyElement({ code: `fluxCustomInfo.excessLiability.excessLiabilityTypeName`, value: value.value });
                        }}
                        allowEdit
                    />
                </Form>
            </React.Fragment>
        );
    }
}

export default ProfileFluxGlExcessLiability;