import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    convertFromBoolean,
    convertToBoolean,
    setAttributeEntity,
    Sizes,
    Alignments,
    BackButton,
    Divider,
    Header,
    Form,
    FormDropDown,
    FormInput,
    FormRadio
} from 'metrix-common';

class ProfileDwellingHeatingSystem extends React.Component {
    render() {
        return (
            <React.Fragment>
                <BackButton clickEvent={this.props.backEvent} />
                <Divider top={Sizes.md} />
                <Header size={Sizes.md} textAlign={Alignments.left} text={`Heating System`} />
                <Form
                    label={`Heating System Details`}
                    allowEdit={this.props.allowEdit}
                    saveEvent={() => this.props.doUpsertPolicy()}
                    cancelEvent={() => this.props.doGetPolicy()}
                >
                    <FormDropDown
                        label={<FormattedMessage id={`Type`} />}
                        value={this.props.heatingSystem && this.props.heatingSystem.type}
                        references={this.props.reference.references && this.props.reference.references[`HeaterType`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`HeaterType`)}
                        changeEvent={(value) => {
                            setAttributeEntity(`${this.props.dotProp}type`, value, this.props.doReducePolicyElement);
                            if (value.code === `I`) {
                                this.props.doReducePolicyElement({ code: `${this.props.dotProp}isFirePlace`, value: true });
                            } else {
                                this.props.doReducePolicyElement({ code: `${this.props.dotProp}isFirePlace`, value: false });
                            }
                        }}
                        allowEdit
                    />
                    <FormDropDown
                        label={<FormattedMessage id={`Fuel Type`} />}
                        value={this.props.heatingSystem && this.props.heatingSystem.fuelType}
                        references={this.props.reference.references && this.props.reference.references[`PrimaryFuel`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`PrimaryFuel`)}
                        changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}fuelType`, value, this.props.doReducePolicyElement)}
                        allowEdit
                    />
                    {(this.props.heatingSystem && this.props.heatingSystem.fuelType && this.props.heatingSystem.fuelType.code === `W`) &&
                        <FormInput
                            label={<FormattedMessage id={`Number of Chords`} />}
                            value={this.props.heatingSystem && this.props.heatingSystem.numberOfChords}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}numberOfChords`, value })}
                            allowEdit
                        />
                    }
                    {(this.props.heatingSystem && ((this.props.heatingSystem.fuelType && this.props.heatingSystem.fuelType.code === `W`) || this.props.heatingSystem.isFirePlace === true)) &&
                        <FormDropDown
                            label={<FormattedMessage id={`Chimney Type`} />}
                            value={this.props.heatingSystem && this.props.heatingSystem.chimneyType}
                            references={this.props.reference.references && this.props.reference.references[`ChimneyType`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`ChimneyType`)}
                            changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}chimneyType`, value, this.props.doReducePolicyElement)}
                            allowEdit
                        />
                    }
                    {(this.props.heatingSystem && this.props.heatingSystem.fuelType && this.props.heatingSystem.fuelType.code === `I`) &&
                        <FormInput
                            label={<FormattedMessage id={`Oil Tank Year`} />}
                            value={this.props.heatingSystem && this.props.heatingSystem.oilTankYear}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}oilTankYear`, value })}
                            allowEdit
                        />
                    }
                    {(this.props.heatingSystem && this.props.heatingSystem.fuelType && this.props.heatingSystem.fuelType.code === `I`) &&
                        <FormDropDown
                            label={<FormattedMessage id={`Oil Tank Location`} />}
                            value={this.props.heatingSystem && this.props.heatingSystem.fuelStorageLocation}
                            references={this.props.reference.references && this.props.reference.references[`OilTankLocation`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`OilTankLocation`)}
                            changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}fuelStorageLocation`, value, this.props.doReducePolicyElement)}
                            allowEdit
                        />
                    }
                    <FormInput
                        label={<FormattedMessage id={`Year`} />}
                        value={this.props.heatingSystem && this.props.heatingSystem.year}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}year`, value })}
                        allowEdit
                    />
                    {
                        (this.props.allowPrimary) &&
                        <FormRadio
                            label={<FormattedMessage id={`Primary`} />}
                            value={this.props.heatingSystem &&
                                { code: convertFromBoolean(this.props.heatingSystem.primary) }}
                            references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}primary`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                    }
                    {(this.props.heatingSystem.primary === true) &&
                        <FormDropDown
                            label={<FormattedMessage id={`Renovation Type`} />}
                            value={this.props.improvement && this.props.improvement.primaryHeatingImprovement}
                            references={this.props.reference.references && this.props.reference.references[`RenovationType`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`RenovationType`)}
                            changeEvent={(value) => setAttributeEntity(`${this.props.baseDotProp}improvement.primaryHeatingImprovement`, value, this.props.doReducePolicyElement)}
                            allowEdit
                        />
                    }
                    {(this.props.heatingSystem.primary === false) &&
                        <FormDropDown
                            label={<FormattedMessage id={`Renovation Type`} />}
                            value={this.props.improvement && this.props.improvement.auxiliaryHeatingImprovement}
                            references={this.props.reference.references && this.props.reference.references[`RenovationType`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`RenovationType`)}
                            changeEvent={(value) => setAttributeEntity(`${this.props.baseDotProp}improvement.auxiliaryHeatingImprovement`, value, this.props.doReducePolicyElement)}
                            allowEdit
                        />
                    }
                    {(this.props.heatingSystem.primary === true &&
                        this.props.improvement &&
                        this.props.improvement.primaryHeatingImprovement &&
                        (this.props.improvement.primaryHeatingImprovement.code === undefined ||
                            this.props.improvement.primaryHeatingImprovement.value !== `None`)) &&
                        <FormInput
                            label={<FormattedMessage id={`Renovation Year`} />}
                            value={this.props.improvement && (this.props.heatingSystem.primary === true) ? this.props.improvement.primaryHeatingImprovementYear : this.props.improvement.auxiliaryHeatingImprovementYear}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.baseDotProp}improvement.primaryHeatingImprovementYear`, value })}
                            allowEdit
                        />
                    }
                    {(this.props.heatingSystem.primary === false &&
                        this.props.improvement &&
                        this.props.improvement.auxiliaryHeatingImprovement &&
                        (this.props.improvement.auxiliaryHeatingImprovement.code === undefined ||
                            this.props.improvement.auxiliaryHeatingImprovement.value !== `None`)) &&
                        <FormInput
                            label={<FormattedMessage id={`Renovation Year`} />}
                            value={this.props.improvement && (this.props.heatingSystem.primary === true) ? this.props.improvement.primaryHeatingImprovementYear : this.props.improvement.auxiliaryHeatingImprovementYear}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.baseDotProp}improvement.auxiliaryHeatingImprovementYear`, value })}
                            allowEdit
                        />
                    }
                </Form>
            </React.Fragment>
        );
    }
}

export default ProfileDwellingHeatingSystem;