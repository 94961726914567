import React, { useEffect } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import {
    Sizes,
    Container,
    Layout,
    getScreenSize,
    isDesktop,
    withAnalytics,
    withSagas,
    AuthenticatedProvider,
    NavBar as NewSideBar
} from 'metrix-common';

import configs from './config.json';
import IconList from '../../icons';

import policiesSaga from '../../sagas/policies/saga';
import productSaga from '../../sagas/product/saga';

import AppBar from '../../components/AppBar';

import { getBoundPolicies } from '../../actions/policies/getBoundPolicies';
import { getBoardPolicies } from '../../actions/policies/getBoardPolicies';
import { getListPolicies } from '../../actions/policies/getListPolicies';
import { getTriagePolicies } from '../../actions/policies/getTriagePolicies';
import { getProduct } from '../../actions/product/getProduct';

import { executeAssignmentAction } from '../../actions/policies/executeAssignmentAction';

import { selectQuery } from '../../selectors/policies/selectQuery';
import { selectPolicies } from '../../selectors/policies/selectPolicies';
import { queryPolicies } from '../../actions/policies/queryPolicies';
import { queryTriagePolicies } from '../../actions/policies/queryTriagePolicies';
import { reduceQueryElement } from '../../actions/policies/reduceQueryElement';

import PoliciesHeader from '../../components/Policies/Header';
import PoliciesList from '../../components/Policies/List';
import PoliciesIncomplete from '../../components/Policies/Incomplete';
import PoliciesBound from '../../components/Policies/Bound';
import PoliciesBoard from '../../components/Policies/Board';
import { selectProducts } from '../../selectors/product/selectProducts';
import { selectView } from '../../selectors/policies/selectView';
import { reduceView } from '../../actions/policies/reduceView';
import { queryBoundPolicies } from '../../actions/policies/queryBoundPolicies';

const PoliciesContainer = styled(Container)`
    ${({ desktop }) => desktop && 'width: 83vw;'}
`;

const Policies = (props) => {
    const { path } = useRouteMatch();
    const menu = window.location.pathname.split('/')[2] || 'policies';
    
    if (props.context.session.userType !== 'administrator' && props.context.session.userType !== 'broker') {
        window.location = '/403';
    }

    useEffect(() => {
        props.doGetProduct();
        props.reference.doGetReferences('ActiveBrokers');
        props.reference.doGetReferences('ActiveBrokerCompanies');

        if (!props.context.storefrontUrl) {
            props.context.doGetStorefrontUrl();
        }
    }, []);
    
    const config = configs.filter(config => config.auth === props.context.authenticated && ((props.context.session && (config.userType === props.context.session.userType)) || config.userType === 'any'))[0];
    let tabs = config.links;
    if (!props.context.storefrontUrl) {
        tabs = tabs.filter(tab => tab.value !== 'newquote');
    }
    
    const desktop = isDesktop(getScreenSize());
    const handleClick = (target) => {
        const clientWeb = props?.context?.configuration?.clientWeb;

        switch (target) {
            case 'policies':
                props.history.push(`/${target}`);
                break;
            case 'search':
                window.location = `${clientWeb}/search`;
                break;
            case 'leads':
                window.location = `${clientWeb}/leads`;
                break;
            case 'newquote':
                window.open(props.context.storefrontUrl, '_blank');
                setTimeout(() => {
                    props.history.push('/');
                }, 1000);
                break;
            default:
                props.history.push('/404');
                break;
        }

    }

    let assignees = props.reference.references && props.reference.references['ActiveBrokers'] || [];
    assignees = [{ code: null, value: 'All Brokers' }, ...assignees];

    let brokerCompanies = props.reference.references && props.reference.references['ActiveBrokerCompanies'] || [];
    brokerCompanies = [{ code: null, value: 'All Brokerage' }, ...brokerCompanies];

    return (
        <AuthenticatedProvider {...props}>
            <Layout
                appbar={<AppBar {...props} />}
                sideContent={<NewSideBar
                    tabs={tabs}
                    defaultTab={menu}
                    clickEvent={value => handleClick(value)}
                    IconList={IconList}
                    {...props}
                />}
                loading={props.context.loading}
                noWidth
            >
                <PoliciesHeader
                    {...props}
                    path={path}
                    assignees={assignees}
                    brokerCompanies={brokerCompanies}
                />
                <PoliciesContainer desktop={desktop} bottom={Sizes.xs}>
                    <Route exact path={path}>
                        <PoliciesList
                            context={props.context}
                            history={props.history}
                            query={props.query}
                            products={props.products}
                            reference={props.reference}
                            doReduceView={props.doReduceView}
                            doReduceQueryElement={props.doReduceQueryElement}
                            listPolicies={props.listPolicies}
                            doGetListPolicies={props.doGetListPolicies}
                            doQueryPolicies={props.doQueryPolicies}
                        />
                    </Route>
                    <Route exact path={`${path}/incomplete`}>
                        <PoliciesIncomplete
                            context={props.context}
                            history={props.history}
                            query={props.query}
                            products={props.products}
                            reference={props.reference}
                            doReduceView={props.doReduceView}
                            doReduceQueryElement={props.doReduceQueryElement}
                            incompletePolicies={props.incompletePolicies}
                            doGetTriagePolicies={props.doGetTriagePolicies}
                            doQueryTriagePolicies={props.doQueryTriagePolicies}
                        />
                    </Route>
                    <Route exact path={`${path}/bound`}>
                        <PoliciesBound
                            context={props.context}
                            history={props.history}
                            query={props.query}
                            products={props.products}
                            reference={props.reference}
                            doReduceView={props.doReduceView}
                            doReduceQueryElement={props.doReduceQueryElement}
                            boundPolicies={props.boundPolicies}
                            doGetBoundPolicies={props.doGetBoundPolicies}
                            doQueryBoundPolicies={props.doQueryBoundPolicies}
                        />
                    </Route>
                    <Route exact path={`${path}/board`}>
                        <PoliciesBoard
                            context={props.context}
                            history={props.history}
                            query={props.query}
                            products={props.products}
                            reference={props.reference}
                            doReduceView={props.doReduceView}
                            boardPolicies={props.boardPolicies}
                            doGetBoardPolicies={props.doGetBoardPolicies}
                        />
                    </Route>
                </PoliciesContainer>
            </Layout>
        </AuthenticatedProvider>
    );
}

const mapStateToProps = createStructuredSelector({
    boundPolicies: selectPolicies('boundPolicies'),
    boardPolicies: selectPolicies('boardPolicies'),
    listPolicies: selectPolicies('listPolicies'),
    incompletePolicies: selectPolicies('triagePolicies'),
    query: selectQuery(),
    products: selectProducts(),
    view: selectView()
});

function mapDispatchToProps(dispatch) {
    return {
        doGetProduct: () => dispatch(getProduct()),
        doGetBoardPolicies: (payload) => dispatch(getBoardPolicies(payload)),
        doGetBoundPolicies: (payload) => dispatch(getBoundPolicies(payload)),
        doGetListPolicies: () => dispatch(getListPolicies()),
        doGetTriagePolicies: () => dispatch(getTriagePolicies()),
        doQueryPolicies: (payload) => dispatch(queryPolicies(payload)),
        doQueryTriagePolicies: (payload) => dispatch(queryTriagePolicies(payload)),
        doQueryBoundPolicies: (payload) => dispatch(queryBoundPolicies(payload)),
        doReduceQueryElement: (payload) => dispatch(reduceQueryElement(payload)),
        doExecuteAssignmentAction: (payload) => dispatch(executeAssignmentAction(payload)),
        doReduceView: (payload) => dispatch(reduceView(payload))
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withPoliciesSaga = withSagas({ key: 'policies', saga: policiesSaga });
const withProductSaga = withSagas({ key: 'product', saga: productSaga });

const WrappedPolicies = withAnalytics(Policies, true);

export default compose(withConnect, withPoliciesSaga, withProductSaga)(WrappedPolicies);