import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Alert,
    Alignments,
    Container,
    Divider,
    Form,
    FormRadio,
    Header,
    Sizes,
    SummaryGroup,
    getPageValidationInfo,
    convertFromBoolean,
    convertToBoolean,
} from 'metrix-common';

import ProfileSupernovaCommercialLossPayee from './LossPayee';

class ProfileSupernovaCommercialLossPayees extends React.Component {
    componentDidMount() {
        const pageHasTooltipInfo = this.props.tooltipInfo && this.props.tooltipInfo.pages && this.props.tooltipInfo.pages.length && this.props.tooltipInfo.pages.some(p => p.code === this.props.pageCode);
        if (!pageHasTooltipInfo) {
            this.props.doGetTooltipInfo(this.props.policy.product);
        }

        this.setState({ currentHasLossPayees: this.props?.policy?.lossPayeesInfo?.hasLossPayees ?? false });
    }

    state = {
        mode: 'lossPayeesView',
        currentLossPayee: null,
        currentHasLossPayees: null
    };

    handleSave = () => {

        let currentHasLossPayees = this.state.currentHasLossPayees;
        this.props.doReducePolicyElement({ code: 'lossPayeesInfo.hasLossPayees', value: currentHasLossPayees });

        if (currentHasLossPayees === true && this.props.policy?.lossPayees && this.props.policy.lossPayees.length === 0) {
            this.props.doExecutePolicyAction({ code: 'SN-COMMERCIAL.CREATELOSSPAYEE' });
        }
        else if (currentHasLossPayees === false && this.props.policy?.lossPayees && this.props.policy?.lossPayees.length > 0) {
            this.props.doExecutePolicyAction({ code: 'SN-COMMERCIAL.DELETELOSSPAYEES' });
        } else {
            this.props.doUpsertPolicy();
        }
    }

    getLossPayees = () => {
        let lossPayees = [];

        if (this.props.policy?.lossPayees && this.props.policy.lossPayees.length > 0) {
            this.props.policy.lossPayees.forEach((lossPayee) => {
                let option = { code: 'lossPayeeOption', value: lossPayee.guid };

                option.elements = [];
                option.elements.push({ code: 'Label', value: lossPayee.label });

                lossPayees.push(option);
            });
        }

        return lossPayees;
    }

    handleHasLossPayeesFormRadio = (value) => {
        const valueAsBollean = convertToBoolean(value.code);
        this.setState({ currentHasLossPayees: valueAsBollean });

        this.props.doReducePolicyElement({
            code: 'lossPayeesInfo.hasLossPayees',
            value: valueAsBollean
        })
    }

    render() {
        const { error, warning } = getPageValidationInfo(this.props.pageCode, this.props.validationInfo);
        const lossPayees = this.getLossPayees();

        return (
            <React.Fragment>
                {
                    (error.message.length !== 0 || warning.message.length !== 0) &&
                    <Container top={Sizes.md}>
                        {
                            error.message.length !== 0 &&
                            <Alert
                                mode='danger'
                                header={error.title}
                                items={error.message}
                            />
                        }
                        {
                            warning.message.length !== 0 &&
                            <Alert
                                mode='warning'
                                header={warning.title}
                                items={warning.message}
                            />
                        }
                    </Container>
                }
                {
                    this.state.mode === 'lossPayeesView' &&
                    <React.Fragment>
                        <Form
                            pageCode={this.props.pageCode}
                            sectionCode='lossPayeesInfo'
                            saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                            reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                            tooltipInfo={this.props.tooltipInfo}
                            validationInfo={this.props.validationInfo}
                            header={<FormattedMessage id={'Loss Payees Info'} />}
                            label={'Details'}
                            allowEdit={this.props.allowEdit}
                            saveEvent={() => this.handleSave()}
                            cancelEvent={() => this.props.doGetPolicy()}
                        >
                            <FormRadio
                                fieldCode='hasLossPayees'
                                label={<FormattedMessage id={'Do you have any Loss Payees?'} />}
                                value={this.props.policy.lossPayeesInfo?.hasLossPayees !== undefined && { code: convertFromBoolean(this.props.policy.lossPayeesInfo.hasLossPayees) }}
                                references={this.props.reference?.references['YesNo']}
                                referencesEvent={() => this.props.reference.doGetReferences('YesNo')}
                                changeEvent={(value) => this.handleHasLossPayeesFormRadio(value)}
                                allowEdit />
                        </Form>
                        <React.Fragment>
                            <Divider top={Sizes.xl} />
                            <Header size={Sizes.lg} textAlign={Alignments.left} text={'Loss Payees'} />
                            <Container top={Sizes.xl} bottom={Sizes.xl}>
                                <SummaryGroup
                                    allowAdd={(this.props.allowEdit && this.props.policy.lossPayeesInfo?.hasLossPayees) && (this.props.policy.lossPayees === undefined || lossPayees.length < 5)}
                                    addEvent={() => {
                                        this.props.doExecutePolicyAction({ code: 'SN-COMMERCIAL.CREATELOSSPAYEE' });
                                    }}
                                    allowEdit={ true }
                                    editEvent=
                                    {(lossPayee) => {
                                        this.setState({ currentLossPayee: lossPayee, mode: 'lossPayeeView' });
                                    }}
                                    allowDelete={this.props.allowEdit && lossPayees.length > 0}
                                    deleteEvent={(lossPayee) => {
                                        this.props.doReduceParameter({ code: 'LossPayeeGuid', value: lossPayee });
                                        this.props.doExecutePolicyAction({ code: 'SN-COMMERCIAL.DELETELOSSPAYEE' });
                                    }}
                                    entities={lossPayees}
                                    icon={'person'}
                                />
                            </Container>
                        </React.Fragment>
                    </React.Fragment>
                }
                {
                    this.state.mode === 'lossPayeeView' &&
                    <ProfileSupernovaCommercialLossPayee
                        rowId={this.state.currentLossPayee}
                        pageCode={this.props.pageCode}
                        saveTooltipInfo={() => this.props.doUpsertTooltipInfo(this.props.policy.product)}
                        reduceTooltipInfoElement={this.props.doReduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        lossPayee={this.props.policy.lossPayees.find(x => x.guid === this.state.currentLossPayee)}
                        dotProp={`lossPayees.${this.props.policy.lossPayees.findIndex(x => x.guid === this.state.currentLossPayee)}.`}
                        backEvent={() => this.setState({ mode: 'lossPayeesView', currentHasLossPayees: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReduceParameter={this.props.doReduceParameter}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        doExecutePolicyAction={this.props.doExecutePolicyAction}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
            </React.Fragment>
        );
    }
}

export default ProfileSupernovaCommercialLossPayees;