import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Alignments,
    Form,
    FormTextArea,
    FormRadio,
    Label,
    convertFromBoolean,
    convertToBoolean
} from 'metrix-common';

class ProfileFluxGlGoodsInTransit extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            <React.Fragment>
                <Form
                    header={<FormattedMessage id={`Goods in transit - Explain any "No" answer`} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormRadio //1
                        label={<FormattedMessage id={`Is all transportation of Finished Stock, Harvested Cannabis Material, or Cash done in an unassuming vehicle?`} />}
                        value={this.props.policy.fluxCustomInfo.goodsInTransitInfo?.isUnassumingVehicle !== undefined //one
                            && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.goodsInTransitInfo.isUnassumingVehicle) }} //two
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({
                            code: `fluxCustomInfo.goodsInTransitInfo.isUnassumingVehicle`, //three
                            value: convertToBoolean(value.code)
                        })}
                        allowEdit />
                    <FormRadio //2 //adding a literal enter spaces the characters out so that the question and answer were not side by side. We will need to add cell spacing.
                        label={<FormattedMessage id={`Do at least two employees travel in the vehicle transporting Finished Stock, Harvested Cannabis Material, or Cash?`} />}
                        value={this.props.policy.fluxCustomInfo.goodsInTransitInfo?.hasTwoEmployeesPerVehicle !== undefined //one
                            && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.goodsInTransitInfo.hasTwoEmployeesPerVehicle) }} //two
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({
                            code: `fluxCustomInfo.goodsInTransitInfo.hasTwoEmployeesPerVehicle`, //three
                            value: convertToBoolean(value.code)
                        })}
                        allowEdit />
                    <FormRadio //3
                        label={<FormattedMessage id={`Does one employee remain in the vehicle at all times?`} />}
                        value={this.props.policy.fluxCustomInfo.goodsInTransitInfo?.isOneEmployeePresent !== undefined //one
                            && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.goodsInTransitInfo.isOneEmployeePresent) }} //two
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({
                            code: `fluxCustomInfo.goodsInTransitInfo.isOneEmployeePresent`, //three
                            value: convertToBoolean(value.code)
                        })}
                        allowEdit />
                    <FormRadio //4
                        label={<FormattedMessage id={`Does the insured collect all identity cards of employees and uniforms (if applicable) who leave their service?`} />}
                        value={this.props.policy.fluxCustomInfo.goodsInTransitInfo?.isIdentityCollectedOnTermination !== undefined //one
                            && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.goodsInTransitInfo.isIdentityCollectedOnTermination) }} //two
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({
                            code: `fluxCustomInfo.goodsInTransitInfo.isIdentityCollectedOnTermination`, //three
                            value: convertToBoolean(value.code)
                        })}
                        allowEdit />
                    <FormRadio //5
                        label={<FormattedMessage id={`Is a shipping manifest created prior to transport?`} />}
                        value={this.props.policy.fluxCustomInfo.goodsInTransitInfo?.isManifestCreatedBeforeTransport !== undefined //one
                            && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.goodsInTransitInfo.isManifestCreatedBeforeTransport) }} //two
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({
                            code: `fluxCustomInfo.goodsInTransitInfo.isManifestCreatedBeforeTransport`, //three
                            value: convertToBoolean(value.code)
                        })}
                        allowEdit />
                    <FormRadio //6
                        label={<FormattedMessage id={`Is a GPS tracking device utilized on vehicles transporting cannabis (finished stock or harvested), or cash?`} />}
                        value={this.props.policy.fluxCustomInfo.goodsInTransitInfo?.isGpsUsedOnVehicle !== undefined //one
                            && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.goodsInTransitInfo.isGpsUsedOnVehicle) }} //two
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({
                            code: `fluxCustomInfo.goodsInTransitInfo.isGpsUsedOnVehicle`, //three
                            value: convertToBoolean(value.code)
                        })}
                        allowEdit />
                    <FormRadio //7
                        label={<FormattedMessage id={`Does the transport vehicle have an active alarm system?`} />}
                        value={this.props.policy.fluxCustomInfo.goodsInTransitInfo?.hasAlarmOnVehicle !== undefined //one
                            && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.goodsInTransitInfo.hasAlarmOnVehicle) }} //two
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({
                            code: `fluxCustomInfo.goodsInTransitInfo.hasAlarmOnVehicle`, //three
                            value: convertToBoolean(value.code)
                        })}
                        allowEdit />
                    <FormRadio //8
                        label={<FormattedMessage id={`Are cannabis goods or cash kept in a safe or lockbox during transit?`} />}
                        value={this.props.policy.fluxCustomInfo.goodsInTransitInfo?.isSafeUsed !== undefined //one
                            && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.goodsInTransitInfo.isSafeUsed) }} //two
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({
                            code: `fluxCustomInfo.goodsInTransitInfo.isSafeUsed`, //three
                            value: convertToBoolean(value.code)
                        })}
                        allowEdit />
                    {(this.props.policy.fluxCustomInfo.goodsInTransitInfo?.isUnassumingVehicle === false ||
                        this.props.policy.fluxCustomInfo.goodsInTransitInfo?.hasTwoEmployeesPerVehicle == false ||
                        this.props.policy.fluxCustomInfo.goodsInTransitInfo?.isOneEmployeePresent === false ||
                        this.props.policy.fluxCustomInfo.goodsInTransitInfo?.isIdentityCollectedOnTermination === false ||
                        this.props.policy.fluxCustomInfo.goodsInTransitInfo?.isManifestCreatedBeforeTransport === false ||
                        this.props.policy.fluxCustomInfo.goodsInTransitInfo?.isGpsUsedOnVehicle === false ||
                        this.props.policy.fluxCustomInfo.goodsInTransitInfo?.hasAlarmOnVehicle === false ||
                        this.props.policy.fluxCustomInfo.goodsInTransitInfo?.isSafeUsed === false) &&
                        <Label bold text={<FormattedMessage id={`If any answer above is "No", please explain below:`} />} textAlign={Alignments.left} />
                    }
                    {(this.props.policy.fluxCustomInfo.goodsInTransitInfo?.isUnassumingVehicle === false ||
                        this.props.policy.fluxCustomInfo.goodsInTransitInfo?.hasTwoEmployeesPerVehicle === false ||
                        this.props.policy.fluxCustomInfo.goodsInTransitInfo?.isOneEmployeePresent === false ||
                        this.props.policy.fluxCustomInfo.goodsInTransitInfo?.isIdentityCollectedOnTermination === false ||
                        this.props.policy.fluxCustomInfo.goodsInTransitInfo?.isManifestCreatedBeforeTransport === false ||
                        this.props.policy.fluxCustomInfo.goodsInTransitInfo?.isGpsUsedOnVehicle === false ||
                        this.props.policy.fluxCustomInfo.goodsInTransitInfo?.hasAlarmOnVehicle === false ||
                        this.props.policy.fluxCustomInfo.goodsInTransitInfo?.isSafeUsed === false) &&
                        <FormTextArea
                            text={this.props.policy.fluxCustomInfo.goodsInTransitInfo?.goodsInTransitReasonOne}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.goodsInTransitInfo.goodsInTransitReasonOne`, value })}
                        />
                    }
                </Form>
                <Form
                    header={<FormattedMessage id={`Goods in transit - Explain any "Yes" answer`} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormRadio //9
                        label={<FormattedMessage id={`Are vehicles transporting cannabis goods or cash allowed to make unnecessary stops?`} />}
                        value={this.props.policy.fluxCustomInfo.goodsInTransitInfo?.isUnnecessaryStopsAllowed !== undefined //one
                            && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.goodsInTransitInfo.isUnnecessaryStopsAllowed) }} //two
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({
                            code: `fluxCustomInfo.goodsInTransitInfo.isUnnecessaryStopsAllowed`, //three
                            value: convertToBoolean(value.code)
                        })}
                        allowEdit />
                    <FormRadio //10
                        label={<FormattedMessage id={`Are drivers allowed to make personal stops while transporting cannabis goods or cash?`} />}
                        value={this.props.policy.fluxCustomInfo.goodsInTransitInfo?.isPersonalStopsAllowed !== undefined //one
                            && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.goodsInTransitInfo.isPersonalStopsAllowed) }} //two
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({
                            code: `fluxCustomInfo.goodsInTransitInfo.isPersonalStopsAllowed`, //three
                            value: convertToBoolean(value.code)
                        })}
                        allowEdit />
                    <FormRadio //11
                        label={<FormattedMessage id={`Is Finished Stock, Harvested Cannabis Material, or Cash left alone overnight in a vehicle that is kept outside?`} />}
                        value={this.props.policy.fluxCustomInfo.goodsInTransitInfo?.isProductStoredInVehicleOvernight !== undefined //one
                            && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.goodsInTransitInfo.isProductStoredInVehicleOvernight) }} //two
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({
                            code: `fluxCustomInfo.goodsInTransitInfo.isProductStoredInVehicleOvernight`, //three
                            value: convertToBoolean(value.code)
                        })}
                        allowEdit />
                    <FormRadio //12
                        label={<FormattedMessage id={`Does the insured transport any product across state lines?`} />}
                        value={this.props.policy.fluxCustomInfo.goodsInTransitInfo?.isTransportCrossStateLines !== undefined //one
                            && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.goodsInTransitInfo.isTransportCrossStateLines) }} //two
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({
                            code: `fluxCustomInfo.goodsInTransitInfo.isTransportCrossStateLines`, //three
                            value: convertToBoolean(value.code)
                        })}
                        allowEdit />
                        {
                            (this.props.policy.fluxCustomInfo.goodsInTransitInfo?.isUnnecessaryStopsAllowed === true ||
                                this.props.policy.fluxCustomInfo.goodsInTransitInfo?.isPersonalStopsAllowed === true ||
                                this.props.policy.fluxCustomInfo.goodsInTransitInfo?.isProductStoredInVehicleOvernight === true ||
                                this.props.policy.fluxCustomInfo.goodsInTransitInfo?.isTransportCrossStateLines === true) &&
                            <Label bold text={<FormattedMessage id={`If any answer above is "Yes", please explain below:`} />} textAlign={Alignments.left} />
                        }
                        {
                            (this.props.policy.fluxCustomInfo.goodsInTransitInfo?.isUnnecessaryStopsAllowed === true ||
                                this.props.policy.fluxCustomInfo.goodsInTransitInfo?.isPersonalStopsAllowed === true ||
                                this.props.policy.fluxCustomInfo.goodsInTransitInfo?.isProductStoredInVehicleOvernight === true ||
                                this.props.policy.fluxCustomInfo.goodsInTransitInfo?.isTransportCrossStateLines === true) &&
                            <FormTextArea
                                text={this.props.policy.fluxCustomInfo.goodsInTransitInfo?.goodsInTransitReasonTwo}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.goodsInTransitInfo.goodsInTransitReasonTwo`, value })}
                            />
                        }
                </Form>
            </React.Fragment>
        );
    }
}

export default ProfileFluxGlGoodsInTransit;