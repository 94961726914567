import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Sizes,
    Alignments,
    Colors,
    Container,
    Divider,
    GenericModal,
    Header,
    BackButton,
    Form,
    FormDropDown,
    AddressForm,
    FormInput,
    FormSwitch,
    SummaryGroup,
    Label
} from 'metrix-common';

import ProfileEmployersWcClassification from './Classification';
import ProfileEmployersWcOwner from './Owner';

import ProductHelper from '../../../../../../../helpers'

class ProfileEmployersWcLocation extends React.Component {
    state = {
        mode: `locationView`,
        currentClassification: null,
        currentOwner: null,
        showPopup: false,
        address: null,
        industryTypeCode: null,
        industryTypeValue: null,
    };

    componentDidMount() {
        this.setState({ address: this.props.location?.address })
        this.setState({ industryTypeCode: this.props.location?.industryTypeCode });
        this.setState({ industryTypeValue: this.props.location?.industryTypeValue });

        if (this.props?.legalEntityTypeCode) {
            this.props.reference.doGetReferences(`EMPLOYERS-WC.OwnerTitles.${this.props?.legalEntityTypeCode}`);
        }
    }

    getClassificationReference = (stateCode) => {
        let handledStateCode = stateCode !== undefined ? stateCode : this.props.location?.address?.stateCode;

        if (handledStateCode && this.props.location?.industryTypeCode !== undefined) {
            this.props.reference.doGetReferences(`EMPLOYERS-WC.Classifications.${handledStateCode}.${this.props.location?.industryTypeCode}`);
        }
    }

    isClassificationAlreadyDownloaded = () => {
        let isClassesDownloaded = (this.props.reference.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`Classifications`]) ? true : false;
        let isClassesByStateDownloaded = (isClassesDownloaded && this.props.reference.references[`EMPLOYERS-WC`][`Classifications`][this.props.location?.address?.stateCode]) ? true : false;
        let isClassesByStateAndIndustryDownloaded = (isClassesByStateDownloaded && this.props.reference.references[`EMPLOYERS-WC`][`Classifications`][this.props.location?.address?.stateCode][this.props.location?.industryTypeCode]) ? true : false;

        return isClassesByStateAndIndustryDownloaded;
    }

    hasFilledRequiredClassificationFields = () => {
        return this.props.location.address?.stateCode && this.props.location?.industryTypeCode;
    }

    isClassificationExist = () => {
        if (!this.isClassificationAlreadyDownloaded()) {
            this.getClassificationReference(this.props.location?.address?.stateCode);
        }

        return this.isClassificationAlreadyDownloaded();
    }

    changeAddress = (dotProp, value) => {

        if (dotProp === `address`) {
            this.props.doReducePolicyElement({ code: `${this.props.dotProp}address`, value })
        } else {
            this.props.doReducePolicyElement({ code: `${this.props.dotProp}address.${dotProp}`, value })
        }
    }

    getLocationName = () => {
        let locationName = null;

        if (this.props.location && this.props.location.locationName) {
            locationName = `${this.props.location.locationName}`;
        }
        else
        {
            locationName = `New Location`;
        }

        return locationName;
    }

    getClassifications = () => {
        let classifications = [];

        if (this.props.classifications &&
            this.props.classifications.filter(x => x.locationGuid === this.props.location.guid).length > 0) {
            this.props.classifications.filter(x => x.locationGuid === this.props.location.guid).forEach((classification) => {
                let option = { code: `classificationOption`, value: classification.guid, unDeletable: classification.isCompanionClassification };

                let classificationPageName = classification.classificationTypeCode !== undefined ? classification.classificationTypeValue : classification.classificationPageName !== undefined ? classification.classificationPageName : 'New Classification';

                option.elements = [];
                option.elements.push({ code: `Label`, value: ProductHelper.truncateString(classificationPageName, 80) });

                classifications.push(option);
            });
        }

        return classifications;
    }

    getOwners = () => {
        let owners = [];

        if (this.props.owners &&
            this.props.owners.filter(x => x.locationGuid === this.props.location.guid).length > 0) {
            this.props.owners.filter(x => x.locationGuid === this.props.location.guid).forEach((owner) => {
                let option = { code: `ownerOption`, value: owner.guid };

                let ownerPageName = owner.ownerTitleTypeCode !== undefined ? owner.ownerTitleTypeValue : owner.ownerPageName !== undefined ? owner.ownerPageName : 'New Owner';

                option.elements = [];
                option.elements.push({ code: `Label`, value: ownerPageName });

                owners.push(option);
            });
        }

        return owners;
    }

    handleLocationSaveEventForm = () => {
        if (!this.isClassificationAlreadyDownloaded()) {
            this.getClassificationReference(this.props.location?.address?.stateCode);
        }
        return this.props.doUpsertPolicy;
    }

    saveLocationHandler = () => {
        this.props.doUpsertPolicy();

        let newAddress = this.props.location?.address;
        let newIndustryTypeCode = this.props.location?.industryTypeCode;
        let newIndustryTypeValue = this.props.location?.industryTypeValue;

        this.setState(prevState => {
            if
            (
                (prevState.address?.state !== undefined && prevState.address?.state !== newAddress.state)
                || (prevState.industryTypeCode !== undefined && prevState.industryTypeCode !== newIndustryTypeCode)
            )
            {
                return { showPopup: true };
            }
            else {
                return {
                    address: newAddress,
                    industryTypeCode: newIndustryTypeCode,
                    industryTypeValue: newIndustryTypeValue,
                };
            }
        });

        if (!this.isClassificationAlreadyDownloaded()) {
            this.getClassificationReference(this.props.location?.address?.stateCode);
        }
    }

    render() {
        let locationName = this.getLocationName();
        let classifications = this.getClassifications();
        let owners = this.getOwners();
        return (
            <React.Fragment>
                {
                    this.state.mode === 'locationView' &&
                    <React.Fragment>
                        <BackButton clickEvent={this.props.backEvent} />
                        <Form
                            rowId={this.props.rowId}
                            pageCode={this.props.pageCode}
                            sectionCode='locationView'
                            saveTooltipInfo={this.props.saveTooltipInfo}
                            reduceTooltipInfoElement={this.props.reduceTooltipInfoElement}
                            tooltipInfo={this.props.tooltipInfo}
                            validationInfo={this.props.validationInfo}
                            header={<FormattedMessage id={locationName} />}
                            label={`Details`}
                            saveEvent={this.saveLocationHandler}
                            cancelEvent={this.props.doGetPolicy}
                            allowEdit={this.props.allowEdit}
                        >
                            <FormInput
                                fieldCode='locationName'
                                label={<FormattedMessage id={`Label`} />}
                                value={this.props.location?.locationName}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}locationName`, value })}
                                allowEdit
                            />
                            <FormDropDown
                                fieldCode='industryTypeCode'
                                label={<FormattedMessage id={`Industry Type`} />}
                                value={{ code: this.props.location.industryTypeCode, value: this.props.location.industryTypeValue }}
                                references={this.props.reference?.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`Industries`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.Industries`)}
                                changeEvent={(value) => {
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}industryTypeCode`, value: value.code });
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}industryTypeValue`, value: value.value });
                                }}
                                allowEdit
                            />
                            {this.props.location.address.stateCode === `NJ` &&
                                <FormInput
                                    fieldCode='taxPayerId'
                                    label={<FormattedMessage id={`Tax Payer Id`} />}
                                    value={this.props.location?.taxPayerId}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}taxPayerId`, value })}
                                    allowEdit
                                />
                            }
                            {(this.props.location.address.stateCode === `HI` || this.props.location.address.stateCode === `ME` || this.props.location.address.stateCode === `NJ` || this.props.location.address.stateCode === `RI` || this.props.location.address.stateCode === `MN` || this.props.location.address.stateCode === `IA`) &&
                                <FormInput
                                    fieldCode='unemploymentId'
                                    label={<FormattedMessage id={`Unemployment Id`} />}
                                    value={this.props.location?.unemploymentId}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}unemploymentId`, value })}
                                    allowEdit
                                />
                            }
                            {/*experienceModifier will be defaulted to 1 in the EMPLOYERS side
                            We dont have an experienceModifier, experienceModifierType, experienceModifierValue in the BE and our questionare
                            {this.props?.experienceModifierType === `'EMOD` && this.props.location.address.stateCode !== undefined && this.props.location.address.stateCode !== `MN` &&
                                <FormInput
                                    label={<FormattedMessage id={`BureauId Id`} />}
                                    value={this.props.location?.bureauId}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}bureauId`, value })}
                                    allowEdit
                                />
                            }
                            {this.props?.experienceModifierType === `'EMOD` && this.props.location.address.stateCode !== undefined && this.props.location.address.stateCode !== `MN` &&
                                <FormDropDown
                                    label={<FormattedMessage id={`Bureau Type`} />}
                                    value={{ code: this.props.location.sourceBureauTypeCode, value: this.props.location.sourceBureauTypeValue }}
                                    references={this.props.reference?.references[`EMPLOYERS-WC`] && this.props.reference.references[`EMPLOYERS-WC`][`SourceBureaus`]}
                                    referencesEvent={() => this.props.reference.doGetReferences(`EMPLOYERS-WC.SourceBureaus`)}
                                    changeEvent={(value) => {
                                        this.props.doReducePolicyElement({ code: `${this.props.dotProp}sourceBureauTypeCode`, value: value.code });
                                        this.props.doReducePolicyElement({ code: `${this.props.dotProp}sourceBureauTypeValue`, value: value.value });
                                    }}
                                    allowEdit
                                />
                            }
                            {this.props.location?.sourceBureauTypeCode === `OTHER` && this.props?.experienceModifierType === `'EMOD` && this.props.location.address.stateCode !== undefined && this.props.location.address.stateCode !== `MN` &&
                                <FormInput
                                    label={<FormattedMessage id={`Please specify:`} />}
                                    value={this.props.location?.otherSourceBureauTypeCode}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}otherSourceBureauTypeCode`, value })}
                                    allowEdit
                                />
                            }*/}
                            <FormInput
                                fieldCode='numberOfEmployeesCount'
                                label={`Number of Employees`}
                                value={this.props.location?.numberOfEmployeesCount}
                                type='number'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}numberOfEmployeesCount`, value })}
                                allowEdit
                            />
                            <FormInput
                                fieldCode='shift1EmployeesCount'
                                label={`Shift1 Number of Employees`}
                                value={this.props.location?.shift1EmployeesCount}
                                type='number'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}shift1EmployeesCount`, value })}
                                allowEdit
                            />
                            <FormInput
                                fieldCode='shift2EmployeesCount'
                                label={`Shift2 Number of Employees`}
                                value={this.props.location?.shift2EmployeesCount}
                                type='number'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}shift2EmployeesCount`, value })}
                                allowEdit
                            />
                            <FormInput
                                fieldCode='shift3EmployeesCount'
                                label={`Shift3 Number of Employees`}
                                value={this.props.location?.shift3EmployeesCount}
                                type='number'
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}shift3EmployeesCount`, value })}
                                allowEdit
                            />
                            <FormSwitch
                                fieldCode='isPrimary'
                                label={<FormattedMessage id={`Is it primary location?`} />}
                                value={this.props.location?.isPrimary}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}isPrimary`, value: value })}
                                allowEdit={false}
                            />
                        </Form>
                        <AddressForm
                            label={'Location Address'}
                            header={<FormattedMessage id={'Address Details'} />}
                            sectionCode='addressDetails'
                            rowId={this.props.rowId}
                            pageCode={this.props.pageCode}
                            validationInfo={this.props.validationInfo}
                            enableAddressSearch
                            addressSearchCountryCode={'USA'}
                            changeEvent={(dotProp, value) => this.changeAddress(dotProp, value)}
                            saveEvent={this.saveLocationHandler}
                            cancelEvent={this.props.doGetPolicy}
                            reference={this.props.reference}
                            address={this.props.location && this.props.location.address}
                            allowEdit
                            {...this.props}
                        />
                        {
                            this.state.showPopup &&
                            <GenericModal
                                animate
                                visible
                                closeEvent={() => {
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}address`, value: this.state.address });
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}industryTypeCode`, value: this.state.industryTypeCode });
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}industryTypeValue`, value: this.state.industryTypeValue });
                                    this.setState({ showPopup: false });
                                    this.props.doUpsertPolicy();
                                }}
                                items={
                                    <React.Fragment>
                                        <Header size={Sizes.md} text='Warning' bottom={Sizes.xs} />
                                        <Label size={Sizes.sm} textAlign={Alignments.center} text={'Classifications depends of Location state and industry. State or industry was changed.'} />
                                    </React.Fragment>
                                }
                                actionButtons={[
                                    {
                                        primary: true,
                                        text: <FormattedMessage id='covera.common.general.ok' />,
                                        disabled: false,
                                        clickEvent: () => {
                                            this.setState({
                                                address: this.props.location?.address,
                                                industryTypeCode: this.props.location?.industryTypeCode,
                                                industryTypeValue: this.props.location?.industryTypeValue,
                                                showPopup: false
                                            });
                                            this.props.doReduceParameter({ code: 'LocationGuid', value: this.props.location.guid });
                                            this.props.doExecutePolicyAction({ code: 'EMPLOYERS-WC.DELETECLASSIFICATIONS' });
                                        }
                                    }, {
                                        primary: false,
                                        text: <FormattedMessage id='covera.common.general.cancel' />,
                                        disabled: false,
                                        clickEvent: () => {
                                            this.props.doReducePolicyElement({ code: `${this.props.dotProp}address`, value: this.state.address });
                                            this.props.doReducePolicyElement({ code: `${this.props.dotProp}industryTypeCode`, value: this.state.industryTypeCode });
                                            this.props.doReducePolicyElement({ code: `${this.props.dotProp}industryTypeValue`, value: this.state.industryTypeValue });
                                            this.setState({ showPopup: false });
                                            this.props.doUpsertPolicy();
                                        }
                                    }
                                ]}
                            />
                        }
                        <Divider top={Sizes.xl} />
                        <Header size={Sizes.lg} textAlign={Alignments.left} text={`Classifications at ` + locationName} />
                        {
                            !this.hasFilledRequiredClassificationFields() &&
                            <Label text={`Classification depends on Location State and Location Industry.`} textAlign={Alignments.left} left={Sizes.xs} color={Colors.Error} />
                        }
                        {
                            (this.hasFilledRequiredClassificationFields() && !this.isClassificationExist()) &&
                            <Label text={`Location State does not have classification according to the Location Industry.`} textAlign={Alignments.left} left={Sizes.xs} color={Colors.Error} />
                        }
                        {
                            this.state.mode === `locationView` &&
                                <Container top={Sizes.xl} bottom={Sizes.xl}>
                                    {
                                        (this.hasFilledRequiredClassificationFields() && this.isClassificationExist()) &&
                                        <SummaryGroup
                                            allowAdd={this.props.allowEdit && (this.props.classifications === undefined || classifications.length < 6)}
                                            addEvent={() => {
                                                this.props.doReduceParameter({ code: `LocationGuid`, value: this.props.location.guid });
                                                this.props.doExecutePolicyAction({ code: `EMPLOYERS-WC.CREATECLASSIFICATION` });
                                            }}
                                            allowEdit
                                            editEvent={(classification) => {
                                                this.setState({ currentClassification: classification, mode: `classificationView` });
                                    }}
                                            allowDelete={this.props.allowEdit && classifications.length > 1}
                                            deleteEvent={(classification) => {
                                                this.props.doReduceParameter({ code: `ClassificationGuid`, value: classification });
                                                this.props.doExecutePolicyAction({ code: `EMPLOYERS-WC.DELETECLASSIFICATION` });
                                                this.setState({ deleteModal: false });
                                                this.setState({ currentClassification: null, mode: `locationView` });
                                            }}
                                            entities={classifications}
                                            icon={`home`}
                                        />
                                    }
                                </Container>
                        }

                        {
                            this.state.mode === `locationView` &&
                            <React.Fragment>
                                <Divider top={Sizes.xl} />
                                <Header size={Sizes.lg} textAlign={Alignments.left} text={`Owners at ` + locationName} />
                                {
                                    this.props.hasOwners !== true &&
                                    <Label text={'Current Legal Entity Type does not have owners endorsement eligibility.'} textAlign={Alignments.left} left={Sizes.xs} color={Colors.Error} />
                                }
                                <Container top={Sizes.xl} bottom={Sizes.xl}>
                                    <SummaryGroup
                                        allowAdd={this.props.allowEdit && (this.props.owners === undefined || owners.length < 4) && this.props.location?.isPrimary === true && this.props.hasOwners === true}
                                        addEvent={() => {
                                            this.props.doReduceParameter({ code: `LocationGuid`, value: this.props.location.guid });
                                            this.props.doExecutePolicyAction({ code: `EMPLOYERS-WC.CREATEOWNER` });
                                        }}
                                        allowEdit
                                        editEvent={(owner) => {
                                            this.setState({ currentOwner: owner, mode: `ownerView` });
                                        }}
                                        allowDelete={this.props.allowEdit}
                                        deleteEvent={(owner) => {
                                            this.props.doReduceParameter({ code: `OwnerGuid`, value: owner });
                                            this.props.doExecutePolicyAction({ code: `EMPLOYERS-WC.DELETEOWNER` });
                                            this.setState({ deleteModal: false });
                                            this.setState({ currentOwner: null, mode: `locationView` });
                                        }}
                                        entities={owners}
                                        icon={'person'}
                                    />
                                </Container>
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
                {
                    this.state.mode === `classificationView` &&
                    <ProfileEmployersWcClassification
                        rowId={this.state.currentClassification}
                        pageCode={this.props.pageCode}
                        saveTooltipInfo={this.props.saveTooltipInfo}
                        reduceTooltipInfoElement={this.props.reduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        classification={this.props.classifications.find(x => x.guid === this.state.currentClassification)}
                        locationStateCode={this.props.location?.address?.stateCode}
                        locationIndustryTypeCode={this.props.location?.industryTypeCode}
                        dotProp={`employersWcCustomInfo.classifications.${this.props.classifications.findIndex(x => x.guid === this.state.currentClassification)}.`}
                        backEvent={() => this.setState({ mode: `locationView`, currentClassification: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReduceParameter={this.props.doReduceParameter}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        doExecutePolicyAction={this.props.doExecutePolicyAction}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
                {
                    this.state.mode === `ownerView` &&
                    <ProfileEmployersWcOwner
                        rowId={this.state.currentOwner}
                        pageCode={this.props.pageCode}
                        saveTooltipInfo={this.props.saveTooltipInfo}
                        reduceTooltipInfoElement={this.props.reduceTooltipInfoElement}
                        tooltipInfo={this.props.tooltipInfo}
                        validationInfo={this.props.validationInfo}
                        owner={this.props.owners.find(x => x.guid === this.state.currentOwner)}
                        legalEntityTypeCode={this.props.legalEntityTypeCode}
                        dotProp={`employersWcCustomInfo.owners.${this.props.owners.findIndex(x => x.guid === this.state.currentOwner)}.`}
                        backEvent={() => this.setState({ mode: `locationView`, currentOwner: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReduceParameter={this.props.doReduceParameter}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        doExecutePolicyAction={this.props.doExecutePolicyAction}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
            </React.Fragment>
        );
    }
}

export default ProfileEmployersWcLocation;