import React from "react";
import styled from 'styled-components';

import equal from 'fast-deep-equal';

import {
	getColor,
	Opacity,
	Alignments,
	Colors,
	Sizes,
	Grid,
	Row,
	Column,
	Container,
	Separator,
	Header
} from 'metrix-common';

import BoardCard from '../Card';

const BoardColumnComponent = styled(Container)`
	min-height: 50px;
	
`;

const CardWrapper = styled(Container)`
	flex-wrap: wrap;
`;

class BoardColumn extends React.Component {
	constructor(props) {
		super(props);
		this.state = ({
			hovering: null
		});
	}
	shouldComponentUpdate(nextProps, nextState) {
		return !equal(this.props, nextProps) || !equal(this.state, nextState);
	}

	render() {
		return (
			<BoardColumnComponent fullSize
				left={Sizes.xxs}
				right={Sizes.xxs}>
				{
					this.props.showHeader &&
					<React.Fragment>
						<Header size={Sizes.xxs} textAlign={Alignments.left} text={`${this.props.name} (${this.props.policies.length || 0})`} />
						<Separator solid bottom={Sizes.xxs} />
					</React.Fragment>
				}
				<Container fullSize
					color={this.state.hovering === true ? Colors.StrokeLight : getColor(Colors.StrokeVeryLight, Opacity.Dark)}
					>
					<Grid>
						<Row>
							<Column nogutter>
								<CardWrapper inline>
									{this.props.policies &&
										this.props.policies.map((policy) => {
											return (
												<Container bottom={Sizes.xs} key={Math.random().toString()}>
													<BoardCard
														policy={policy}
														key={policy.policyTransactionGuid}
														reference={this.props.reference}
														history={this.props.history}
														assignmentEvent={this.props.assignmentEvent}
														context={this.props.context}
														products={this.props.products}
													/>
												</Container>
											);
										})}
								</CardWrapper>
							</Column>
						</Row>
					</Grid>
				</Container>
			</BoardColumnComponent>
		);
	}
}

export default BoardColumn;
