import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Form,
    FormSwitch
} from 'metrix-common';

class ProfileFluxGlCannabisRisk extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            <React.Fragment>
                <Form
                    header={<FormattedMessage id={`Enforcement of the Controlled Substance Act (Cannabis risks only)`} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormSwitch
                        label={<FormattedMessage id={`Does the applicant prevent the distribution of marijuana/cannabis to minors?`} />}
                        value={this.props.policy.fluxCustomInfo.cannabisRisk?.isPreventDistributionToMinors}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisRisk.isPreventDistributionToMinors`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Does the applicant prevent the revenue from sale of marijuana/cannabis from going to criminal enterprises?`} />}
                        value={this.props.policy.fluxCustomInfo.cannabisRisk?.isPreventRevenueFromCriminal}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisRisk.isPreventRevenueFromCriminal`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Does the applicant prevent possible diversion of marijuana/cannabis from states where medicinal and/or recreational use of cannabis products is legal under state law to states where medicinal and/or recreational use of cannabis products is not legal under state law?`} />}
                        value={this.props.policy.fluxCustomInfo.cannabisRisk?.isPreventDiversionToProhibitedStates}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisRisk.isPreventDiversionToProhibitedStates`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Does the applicant prevent the use of state-authorized marijuana/cannabis activity as a cover or pretext for the trafficking of other illegal drugs or other illegal activity?`} />}
                        value={this.props.policy.fluxCustomInfo.cannabisRisk?.isPreventTraffikingIllegalDrugs}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisRisk.isPreventTraffikingIllegalDrugs`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Does the applicant have a program or safeguards in place to prevent violence and the use of firearms in the cultivation and distribution of marijuana/cannabis?`} />}
                        value={this.props.policy.fluxCustomInfo.cannabisRisk?.hasProgramToPreventViolance}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisRisk.hasProgramToPreventViolance`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Does the applicant prevent drugged driving or other possibly adverse public health consequences associated with marijuana/cannabis use?`} />}
                        value={this.props.policy.fluxCustomInfo.cannabisRisk?.isPreventDruggedDriving}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisRisk.isPreventDruggedDriving`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Does the applicant either grow or purchase marijuana/cannabis grown on public lands?`} />}
                        value={this.props.policy.fluxCustomInfo.cannabisRisk?.hasPublicLandsForCannabisGrowing}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisRisk.hasPublicLandsForCannabisGrowing`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Does the applicant prevent the possession or use of their product on federal property?`} />}
                        value={this.props.policy.fluxCustomInfo.cannabisRisk?.isPreventPossessionInFederalProperty}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cannabisRisk.isPreventPossessionInFederalProperty`, value: value })}
                        allowEdit
                    />
                </Form>
            </React.Fragment>
        );
    }
}

export default ProfileFluxGlCannabisRisk;