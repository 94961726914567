import React from 'react';
import styled, { keyframes } from 'styled-components';

import {
    Sizes,
    Colors,
    Alignments,
    Container,
    Grid,
    Row,
    Column,
    Label,
    Link,
    Header,
    Separator,
    GenericModal,
    Divider,
    isMobile,
    getScreenSize,
    Spinner
} from 'metrix-common';

const ModelContainer = styled.div`
    height: 50vh;
    overflow-y: auto;
    border: 1px solid #000;
`;

const fadeIn = keyframes`
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
`;

const CopiedLabel = styled(Label)`
    transform: scale(1);
	transition: all 0.5s;
    animation: 0.5s ${fadeIn} ease-in-out
`;

const CallContainer = styled.div`
    ${({ inline }) => inline ? `
        display: contents;
        `
    :
    `margin-right: 40px;`
    }
`;

class ProfileLogs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            displayModel: false,
            modelId: null,
            modelType: null,
            textCopied: false
        };
    }

    componentDidMount() {
        this.props.doGetPolicy();
        let logAction = { referenceCode: this.props?.policy?.guid, productCode: this.props?.policy?.product };
        this.props.doGetLogs(logAction);
    }

    closeModel = () => {
        this.setState({ displayModel: false });
        this.setState({ modelId: null });
        this.setState({ modelType: null });
    }

    openModel = (referenceCode, type) => {
        this.setState({ modelId: referenceCode });
        this.setState({ displayModel: true });
        this.setState({ modelType: type });
        this.setState({ textCopied: false });
    }

    copyText = async (text) => {
        await navigator.clipboard.writeText(text);
        this.setState({ textCopied: true });
    }

    getModel = () => {
        let content = this.props.policyLogs.find(log => log.eventCode === this.state.modelId);
        let logContent = content.logContent;
        return (
            <GenericModal
                animate
                visible
                closeEvent={() => this.closeModel()}
                size={Sizes.lg}
                items={
                    <React.Fragment>
                            <Header size={Sizes.md} text={`${this.state.modelType}`} bottom={Sizes.md} />
                            <ModelContainer>
                            {
                                logContent ?
                                    <Label textAlign={Alignments.left}
                                        text={`${logContent}`}
                                    />
                                    :
                                    <Spinner />
                            }
                            </ModelContainer>
                        {
                            this.state.textCopied &&
                            <CopiedLabel top={Sizes.md} textAlign={Alignments.center} color={Colors.Success} text={'Text Copied!'} />
                        }
                    </React.Fragment>
                }
                actionButtons={[
                    {
                        primary: true,
                        text: `Close`,
                        clickEvent: () => this.closeModel()
                    },
                    {
                        primary: false,
                        text: `Copy`,
                        clickEvent: () => this.copyText(logContent)
                    }
                ]}
            />
            )
    }

    render() {
        let mobile = isMobile(getScreenSize());

        return (
            <Container top={Sizes.sm} bottom={Sizes.xl}>
                <Grid>
                    {/*Page Header*/}
                    <Row>
                        <Column>
                            <Container verticalAlignment={Alignments.center}
                                top={Sizes.md}
                                fullSize>
                                <Divider />
                                <Header size={Sizes.xl}
                                    textAlign={Alignments.left}
                                    text={`API Logs`} />
                            </Container>
                        </Column>
                        <Column nogutter>
                            <Separator orientation="horizontal"
                                top={Sizes.sm}
                                bottom={Sizes.sm}
                                solid
                            />
                        </Column>
                    </Row>
                    {/*Column Header*/}
                    {
                        this.props.policyLogs &&

                        <Row>
                            <Column xs={4} md={4}>
                                <Container verticalAlignment={Alignments.center}
                                    top={Sizes.md}
                                    fullSize>
                                    <Header size={Sizes.md} textAlign={Alignments.left}
                                        text={`Event`}
                                    />
                                </Container>
                            </Column>
                            {
                                mobile ?
                                    <Column xs={4} md={2}>
                                        <Container verticalAlignment={Alignments.center}
                                            top={Sizes.md}
                                            fullSize>
                                            <Header size={Sizes.md} textAlign={Alignments.left}
                                                text={`Calls`}
                                            />
                                        </Container>
                                    </Column>
                                    :
                                    <React.Fragment>
                                        <Column xs={2} md={2}>
                                            <Container verticalAlignment={Alignments.center}
                                                top={Sizes.md}
                                                fullSize>
                                                <Header size={Sizes.md} textAlign={Alignments.left}
                                                    text={`Request`}
                                                />
                                            </Container>
                                        </Column>
                                        <Column xs={2} md={2}>
                                            <Container verticalAlignment={Alignments.center}
                                                top={Sizes.md}
                                                fullSize>
                                                <Header size={Sizes.md} textAlign={Alignments.left}
                                                    text={`Response`}
                                                />
                                            </Container>
                                        </Column>
                                        <Column xs={2} md={2}>
                                            <Container verticalAlignment={Alignments.center}
                                                top={Sizes.md}
                                                fullSize>
                                                <Header size={Sizes.md} textAlign={Alignments.left}
                                                    text={`Error`}
                                                />
                                            </Container>
                                            </Column>
                                            </React.Fragment>                          
                            }
                            <Column xs={mobile ? 4 : 2} md={2}>
                                <Container verticalAlignment={Alignments.center}
                                    top={Sizes.md}
                                    fullSize>
                                    <Header size={Sizes.md} textAlign={Alignments.left}
                                        text={`Timestamp (UTC)`}
                                    />
                                </Container>
                            </Column>
                        </Row>
                    }
                    {/*Row Logs*/}
                    {
                        (this.props.policyLogs && this.props.policyLogs.length > 0) ?
                            this.props.policyLogs.map((log, idx) => {
                                let eventCode = log.eventCode;
                                let timeStampArr = log.timeStamp.split('T');
                                let date = timeStampArr[0]
                                let time = timeStampArr[1]
                                return (
                                    <Row key={`Log-${idx}`}>
                                        <Column xs={4} md={4}>
                                            <Container verticalAlignment={Alignments.center}
                                                top={Sizes.md}
                                                fullSize>
                                                <Label textAlign={Alignments.left} bold
                                                    text={`${log.sourceType}`}
                                                />
                                                <Label textAlign={Alignments.left} size={Sizes.sm}
                                                    text={`${eventCode}`}
                                                />
                                            </Container>
                                        </Column>
                                        <CallContainer inline={!mobile}>
                                            <Column xs={2} md={2}>
                                            <Container verticalAlignment={Alignments.center}
                                                top={Sizes.md}
                                                clickEvent={() => {
                                                    if (log.request) {
                                                        this.props.doGetLogContent({ eventCode, logName: log.request });
                                                        this.openModel(eventCode, 'Request');
                                                    }
                                                }}
                                                fullSize>
                                                <Link textAlign={Alignments.left}
                                                    text={`${log.request ? `Request` : ``}`}
                                                />
                                            </Container>
                                        </Column>
                                            <Column xs={2} md={2}>
                                            <Container verticalAlignment={Alignments.center}
                                                top={Sizes.md}
                                                clickEvent={() => {
                                                    if (log.response) {
                                                        this.props.doGetLogContent({ eventCode, logName: log.response });
                                                        this.openModel(eventCode, 'Response');
                                                    }
                                                }}
                                                fullSize>
                                                <Link textAlign={Alignments.left}
                                                    text={`${log.response ? `Response` : ``}`}
                                                />
                                            </Container>
                                        </Column>
                                            <Column xs={2} md={2}>
                                            <Container verticalAlignment={Alignments.center}
                                                top={Sizes.md}
                                                clickEvent={() => {
                                                    if (log.responseError || log.error) {
                                                        this.props.doGetLogContent({ eventCode, logName: log.responseError || log.error });
                                                        this.openModel(eventCode, 'Error');
                                                    }
                                                }}
                                                fullSize>
                                                    <Link textAlign={Alignments.left}
                                                        text={`${log.responseError || log.error ? `Error` : ``}`}
                                                />
                                            </Container>
                                            </Column>
                                        </CallContainer>
                                        <Column xs={mobile ? 4 : 2} md={2}>
                                            <Container verticalAlignment={mobile ? `` : Alignments.center}
                                                top={Sizes.md}
                                                fullSize>
                                                <Label textAlign={Alignments.left}
                                                    text={`${date}`}
                                                />
                                                <Label textAlign={Alignments.left}
                                                    text={`${time}`}
                                                />
                                            </Container>
                                        </Column>
                                    </Row>
                                );
                            })
                            :
                            (<Label textAlign={Alignments.Center} top={Sizes.md} text={'No logs yet!'} />)
                    }
                    {/*Action Model*/}
                    {this.state.displayModel && this.getModel()}
                </Grid>
            </Container>
        );
    }
}

export default ProfileLogs;