import dotProp from 'dot-prop-immutable';
import {
	REDUCE_PAYMENT_EMAIL,
	REDUCE_PAYMENT_TRANSFER_RESULT_RECORDS,
	REDUCE_PAYMENT_TRANSFER_RESULT_READ_ONLY,
	REDUCE_PAYMENT_TRANSFER_RESULT_ERRORS,
	REDUCE_PAYMENT_TRANSFER_RESULT_HIDDEN
} from '../../constants/actions/payment';

const initialState = {
	paymentEmail: null,
	paymentTransferResultRecords: [],
	paymentTransferResultErrors: [],
	paymentTransferResultReadOnly: true,
	isPaymentTransfersResultHidden: false,
};

function paymentReducer(state = initialState, action) {
	switch (action.type) {
		case REDUCE_PAYMENT_EMAIL:
			return dotProp.set(state, 'paymentEmail', action.payload);
		case REDUCE_PAYMENT_TRANSFER_RESULT_RECORDS:
			return dotProp.set(state, 'paymentTransferResultRecords', action.payload);
		case REDUCE_PAYMENT_TRANSFER_RESULT_READ_ONLY:
			return dotProp.set(state, 'paymentTransferResultReadOnly', action.payload);
		case REDUCE_PAYMENT_TRANSFER_RESULT_ERRORS:
			return dotProp.set(state, 'paymentTransferResultErrors', action.payload);
		case REDUCE_PAYMENT_TRANSFER_RESULT_HIDDEN:
			return dotProp.set(state, 'isPaymentTransfersResultHidden', action.payload);
		default:
			return state;
	}
}

export default paymentReducer;