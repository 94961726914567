import dotProp from 'dot-prop-immutable';

import {
    REDUCE_PARAMETER,
    REDUCE_PARAMETERS,
    REDUCE_POLICY_ELEMENT,
    REDUCE_POLICY,
    REDUCE_POLICY_SUMMARY,
    REDUCE_PRODUCT_SETTINGS,
    REDUCE_POLICY_HISTORY,
    REDUCE_POLICY_LOGS,
    REDUCE_POLICY_LOG_CONTENT,
    REDUCE_ENTITY,
    REDUCE_POLICY_VALIDATION_INFO,
    REDUCE_TOOLTIP_INFO,
    REDUCE_TOOLTIP_INFO_ELEMENT,
    REDUCE_ALLOW_EDIT,
    REDUCE_RISK_CHANGED,
    REDUCE_NOTES, REDUCE_SINGLE_NOTE
} from '../../constants/actions/policy';

const initialState = {
    parameters: [],
    policy: null,
    policySummary: null,
    productSettings: null,
    policyHistory: null,
    policyLogs: null,
    entity: null,
    validationInfo: null,
    tooltipInfo: null,
    allowEdit: true,
    riskChanged: false,
    notes: []
};

function policyReducer(state = initialState, action) {
    let elementIndex = 0;
    switch (action.type) {
        case REDUCE_POLICY_ELEMENT:
            if (state.policy) {
                let classificationInfo = action.payload.code.slice(0, action.payload.code.length - 1);
                if (action.payload.action === `remove`) {
                    elementIndex = state.policy.btisglClassificationInfos.findIndex(ele => ele.id === parseInt(action.payload.code[action.payload.code.length - 1], 10));
                    if(elementIndex >= 0) {
                        return dotProp.delete(state, `policy.${action.payload.code.slice(0, action.payload.code.length - 1)}${elementIndex}`, action.payload.value);
                    } else {
                        return dotProp.delete(state, `policy.${action.payload.code}`, action.payload.value);
                    }
                }
                if (state.policy.btisglClassificationInfos && classificationInfo === 'btisglClassificationInfos.') {
                    elementIndex = state.policy.btisglClassificationInfos.findIndex(ele => ele.id === parseInt(action.payload.code[action.payload.code.length - 1], 10));
                    if (elementIndex >= 0) {
                        return dotProp.set(state, `policy.${action.payload.code.slice(0, action.payload.code.length - 1)}${elementIndex}`, action.payload.value);
                    }
                }
                if (!state.policy.btisglClassificationInfos && classificationInfo === 'btisglClassificationInfos.') {
                    return dotProp.set(state, `policy.btisglClassificationInfos`, [action.payload.value]);
                }
                return dotProp.set(state, `policy.${action.payload.code}`, action.payload.value ?? '');
            } else {
                let policy = dotProp.set({}, action.payload.code, action.payload.value ?? '');
                return dotProp.set(state, 'policy', policy);
            }
        case REDUCE_POLICY:
            return dotProp.set(state, 'policy', action.payload);
        case REDUCE_POLICY_SUMMARY:
            return dotProp.set(state, 'policySummary', action.payload);
        case REDUCE_PRODUCT_SETTINGS:
            return dotProp.set(state, 'productSettings', action.payload);
        case REDUCE_POLICY_HISTORY:
            return dotProp.set(state, 'policyHistory', action.payload);
        case REDUCE_POLICY_LOGS:
            return dotProp.set(state, 'policyLogs', action.payload);
        case REDUCE_POLICY_LOG_CONTENT:
            let idx = state.policyLogs.findIndex(log => log.eventCode === action.payload.code);
            return dotProp.set(state, `policyLogs.${idx}.logContent`, action.payload.value);
        case REDUCE_ENTITY:
            return dotProp.set(state, 'entity', action.payload);
        case REDUCE_PARAMETER:
            let parameterIndex = 0;
            if (state.parameters && state.parameters.some(x => x.code === action.payload.code))
            {
                parameterIndex = state.parameters.findIndex(x => x.code === action.payload.code);
            }
            else
            {
                if (!state.parameters)
                {
                    state.parameters = [];
                }
                parameterIndex = state.parameters.length;
            }
            return dotProp.set(state, `parameters.${parameterIndex}`, { code: action.payload.code, value: action.payload.value });
        case REDUCE_PARAMETERS:
            return dotProp.set(state, `parameters`, action.payload);
        case REDUCE_POLICY_VALIDATION_INFO:
            return dotProp.set(state, 'validationInfo', action.payload);
        case REDUCE_TOOLTIP_INFO:
            return dotProp.set(state, 'tooltipInfo', action.payload);
        case REDUCE_TOOLTIP_INFO_ELEMENT:
            const { newTooltip, pageCode, sectionCode, fieldCode } = action.payload;
            let updatedTooltipInfo = state.tooltipInfo ? { ...state.tooltipInfo } : { pages: [] };
            let pageFound = false;
            let sectionFound = false;

            for (const page of updatedTooltipInfo.pages) {
                if (page.code === pageCode) {
                    pageFound = true;
                    for (const section of page.sections) {
                        if (section.code === sectionCode) {
                            sectionFound = true;
                            for (const field of section.fields) {
                                if (field.code === fieldCode) {
                                    // Field found, update the tooltip
                                    field.tooltip = newTooltip;

                                    return dotProp.set(state, 'tooltipInfo', updatedTooltipInfo);
                                }
                            }

                            // Field not found, add a new field
                            const newField = {
                                tooltip: newTooltip,
                                code: fieldCode
                            };
                            section.fields.push(newField);

                            return dotProp.set(state, 'tooltipInfo', updatedTooltipInfo);
                        }
                    }

                    // Section not found, add a new section and field
                    const newSection = {
                        fields: [
                            {
                                tooltip: newTooltip,
                                code: fieldCode
                            }
                        ],
                        code: sectionCode
                    };
                    page.sections.push(newSection);
                }
            }

            // Page not found, add a new page, section, and field
            const newField = {
                tooltip: newTooltip,
                code: fieldCode
            };
            const newSection = {
                fields: [newField],
                code: sectionCode
            };
            const newPage = {
                sections: [newSection],
                code: pageCode
            };
            updatedTooltipInfo.pages.push(newPage);
            return dotProp.set(state, 'tooltipInfo', updatedTooltipInfo);
        case REDUCE_ALLOW_EDIT:
            return dotProp.set(state, 'allowEdit', !action.payload);
        case REDUCE_RISK_CHANGED:
            return dotProp.set(state, 'riskChanged', action.payload);
        case REDUCE_NOTES:
            return dotProp.set(state, 'notes', action.payload);
        case REDUCE_SINGLE_NOTE:
            const noteIndex = state.notes.findIndex(note => note.noteGuid === action.payload.noteGuid);
            if (noteIndex >= 0) {
                return dotProp.set(state, `notes.${noteIndex}`, action.payload);
            } else {
                return dotProp.set(state, 'notes', [...state.notes, action.payload]);
            }
        default:
            return state;
    }
}

export default policyReducer;