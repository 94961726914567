import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    setAttributeEntity,
    Sizes,
    Alignments,
    BackButton,
    Divider,
    Header,
    Form,
    FormInput,
    FormDropDown
} from 'metrix-common';

class ProfileDwellingScheduledItem extends React.Component {
    render() {
        return (
            <React.Fragment>
                <BackButton clickEvent={this.props.backEvent} />
                <Divider top={Sizes.md} />
                <Header size={Sizes.md} textAlign={Alignments.left} text={`Scheduled Item`} />
                <Form
                    label={`Scheduled Item Details`}
                    allowEdit={this.props.allowEdit}
                    saveEvent={() => this.props.doUpsertPolicy()}
                    cancelEvent={() => this.props.doGetPolicy()}
                >
                    <FormDropDown
                        label={<FormattedMessage id={`Type`} />}
                        value={this.props.scheduledItem && this.props.scheduledItem.type}
                        references={this.props.reference.references && this.props.reference.references[`ScheduledItems`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`ScheduledItems`)}
                        changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}type`, value, this.props.doReducePolicyElement)}
                        allowEdit
                    />
                    <FormInput
                        label={<FormattedMessage id={`Insured Value`} />}
                        value={this.props.scheduledItem && this.props.scheduledItem.insuredValue && this.props.scheduledItem.insuredValue.value}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `${this.props.dotProp}insuredValue.value`, value });
                            this.props.doReducePolicyElement({ code: `${this.props.dotProp}insuredValue.currency`, value: `cad` });
                        }}
                        allowEdit
                    />
                </Form>
            </React.Fragment>
        );
    }
}

export default ProfileDwellingScheduledItem;