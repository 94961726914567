import React from 'react';

import {
    Colors,
    Sizes,
    getSize,
    TenantProduct,
    HomeProduct,
    AutoProduct,
    BundleProduct,
    CondoProduct,
    LandlordProduct,
    RoommateProduct,
    CommercialProduct,
    MotorcycleProduct,
    RVProduct,
    PleasurecraftProduct
} from 'metrix-common';

class ProductClassIcon extends React.Component {

    getIcon = (productClass, size, color) => {
        let icon = null;

        switch (productClass) {
            case `tenant`:
                icon = (<TenantProduct size={size} color={color} />);
                break;
            case `home`:
                icon = (<HomeProduct size={size} color={color} />);
                break;
            case `auto`:
            case `flux-commauto`:
                icon = (<AutoProduct size={size} color={color} />);
                break;
            case `ppackage`:
                icon = (<BundleProduct size={size} color={color} />);
                break;
            case `condo`:
                icon = (<CondoProduct size={size} color={color} />);
                break;
            case `landlord`:
                icon = (<LandlordProduct size={size} color={color} />);
                break;
            case `roommate`:
                icon = (<RoommateProduct size={size} color={color} />);
                break;
            case `motorcycle`:
                icon = (<MotorcycleProduct size={size} color={color} />);
                break;
            case `rv`:
                icon = (<RVProduct size={size} color={color} />);
                break;
            case `pleasurecraft`:
                icon = (<PleasurecraftProduct size={size} color={color} />);
                break;
            default:
                icon = (<CommercialProduct size={size} color={color} />);
                break;
        }

        return icon;
    }

    render() {
        let size = this.props.size && getSize(this.props.size);
        return (
            this.getIcon(this.props.productClass, size || 25, Colors.Primary)
        );
    }
}

export default ProductClassIcon;
