import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Form,
    FormDropDown,
    FormSwitch
} from 'metrix-common';

class ProfileFluxGlProcessing extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            <React.Fragment>
                <Form
                    header={<FormattedMessage id={`Processing`} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormDropDown
                        label={<FormattedMessage id={`Please select processing operation:`} />}
                        value={{ code: this.props.policy.fluxCustomInfo.processing?.processingTypeOperationTypeCode, value: this.props.policy.fluxCustomInfo.processing?.processingTypeOperationTypeName }}
                        references={this.props.reference?.references[`FLUX-GL`] && this.props.reference.references[`FLUX-GL`][`ProcessingOperationsTypes`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`FLUX-GL.ProcessingOperationsTypes`)}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `fluxCustomInfo.processing.processingTypeOperationTypeCode`, value: value.code });
                            this.props.doReducePolicyElement({ code: `fluxCustomInfo.processing.processingTypeOperationTypeName`, value: value.value });
                        }}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Will there be any storage of finished stock?`} />}
                        value={this.props.policy.fluxCustomInfo.processing?.hasCannabisProductsGrownTest}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.processing.hasCannabisProductsGrownTest`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Does the applicant test 100% of cannabis products grown?`} />}
                        value={this.props.policy.fluxCustomInfo.processing?.hasProcessingElectricalSupplyWithBackupSystem}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.processing.hasProcessingElectricalSupplyWithBackupSystem`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Does the electrical supply have a backup system?`} />}
                        value={this.props.policy.fluxCustomInfo.processing?.hasFinishedStockStorage}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.processing.hasFinishedStockStorage`, value: value })}
                        allowEdit
                    />
                </Form>
            </React.Fragment>
        );
    }
}

export default ProfileFluxGlProcessing;