import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Form, FormChecklist, FormRadio, convertFromBoolean, convertToBoolean
} from 'metrix-common';

class ProfileInterwestCyberCyber extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            <>
                <Form
                    header={<FormattedMessage id='Underwriting questions' />}
                    label='Check all that is true:'
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormChecklist
                        // label={<FormattedMessage id={`Up-to-date, active firewall technology`} />}
                        value={this.props.policy.interWestCyberCustomInfo.cyberInfo.underwritingQuestionsSelectedItems}
                        references={this.props.reference.references['InterWest-Cyber'] && this.props.reference.references['InterWest-Cyber'].UnderwritingQuestions}
                        referencesEvent={() => this.props.reference.doGetReferences('InterWest-Cyber.UnderwritingQuestions')}
                        changeEvent={values => {
                            this.props.doReducePolicyElement({ code: 'interWestCyberCustomInfo.cyberInfo.underwritingQuestionsSelectedItems', value: values });
                        }}
                        allowEdit
                    />
                </Form>
                <Form
                    header={<FormattedMessage id='Encryption' />}
                    label='Indicate whether the Applicant encrypts private or sensitive data:'
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormRadio
                        label={<FormattedMessage id={'While at rest in the Applicant’s database or on the Applicant’s network'} />}
                        value={{ code: this.props.policy.interWestCyberCustomInfo.cyberInfo.hasEncryptedDatabase }}
                        references={this.props.reference?.references['YesNoNA']}
                        referencesEvent={() => this.props.reference.doGetReferences('YesNoNA')}
                        changeEvent={value => {
                            this.props.doReducePolicyElement({ code: 'interWestCyberCustomInfo.cyberInfo.hasEncryptedDatabase', value: value.code });
                        }}
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={'While in transit in electronic form'} />}
                        value={{ code: this.props.policy.interWestCyberCustomInfo.cyberInfo.hasEncryptedTransit }}
                        references={this.props.reference?.references['YesNoNA']}
                        referencesEvent={() => this.props.reference.doGetReferences('YesNoNA')}
                        changeEvent={value => {
                            this.props.doReducePolicyElement({ code: 'interWestCyberCustomInfo.cyberInfo.hasEncryptedTransit', value: value.code });
                        }}
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={'While on mobile devices'} />}
                        value={{ code: this.props.policy.interWestCyberCustomInfo.cyberInfo.hasEncryptedMobile }}
                        references={this.props.reference?.references['YesNoNA']}
                        referencesEvent={() => this.props.reference.doGetReferences('YesNoNA')}
                        changeEvent={value => {
                            this.props.doReducePolicyElement({ code: 'interWestCyberCustomInfo.cyberInfo.hasEncryptedMobile', value: value.code });
                        }}
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={'While on employee owned devices'} />}
                        value={{ code: this.props.policy.interWestCyberCustomInfo.cyberInfo.hasEncryptedEmployee }}
                        references={this.props.reference?.references['YesNoNA']}
                        referencesEvent={() => this.props.reference.doGetReferences('YesNoNA')}
                        changeEvent={value => {
                            this.props.doReducePolicyElement({ code: 'interWestCyberCustomInfo.cyberInfo.hasEncryptedEmployee', value: value.code });
                        }}
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={'While in the care, custody, and control of a third party service provider'} />}
                        value={{ code: this.props.policy.interWestCyberCustomInfo.cyberInfo.hasEncryptedThirdParty }}
                        references={this.props.reference?.references['YesNoNA']}
                        referencesEvent={() => this.props.reference.doGetReferences('YesNoNA')}
                        changeEvent={value => {
                            this.props.doReducePolicyElement({ code: 'interWestCyberCustomInfo.cyberInfo.hasEncryptedThirdParty', value: value.code });
                        }}
                        allowEdit
                    />
                </Form>
                <Form
                    header={<FormattedMessage id='Multifactor Authentication' />}
                    label='Details'
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormRadio
                        label={<FormattedMessage id={'Does your business have multi-factor authentication for remote access to email and other systems and programs that contain private or sensitive data in bulk?'} />}
                        value={{ code: this.props.policy.interWestCyberCustomInfo.cyberInfo.hasMultiFactorAuthentication }}
                        references={this.props.reference?.references['YesNoNA']}
                        referencesEvent={() => this.props.reference.doGetReferences('YesNoNA')}
                        changeEvent={value => {
                            this.props.doReducePolicyElement({ code: 'interWestCyberCustomInfo.cyberInfo.hasMultiFactorAuthentication', value: value.code });
                        }}
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={'Multi-Factor authentication is required for all employees when accessing email through a website or cloud based service.'} />}
                        value={{ code: this.props.policy.interWestCyberCustomInfo.cyberInfo.hasMultiFactorAuthenticationRequirementWebsiteEmail }}
                        references={this.props.reference?.references['YesNoEmail']}
                        referencesEvent={() => this.props.reference.doGetReferences('YesNoEmail')}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: 'interWestCyberCustomInfo.cyberInfo.hasMultiFactorAuthenticationRequirementWebsiteEmail', value: value.code });
                        }}
                        allowEdit
                    />
                    <FormChecklist
                        label={<FormattedMessage id={'You must have MFA controls in order to meet Travelers minimum eligibility requirements to qualify for coverage and all MFA Attestation answers must be yes.'} />}
                        value={this.props.policy.interWestCyberCustomInfo.cyberInfo.multiFactorAuthenticationQuestionsSelectedItems}
                        references={this.props.reference.references['InterWest-Cyber'] && this.props.reference.references['InterWest-Cyber'].MultiFactorAuthenticationQuestions}
                        referencesEvent={() => this.props.reference.doGetReferences('InterWest-Cyber.MultiFactorAuthenticationQuestions')}
                        changeEvent={values => {
                            this.props.doReducePolicyElement({ code: 'interWestCyberCustomInfo.cyberInfo.multiFactorAuthenticationQuestionsSelectedItems', value: values });
                        }}
                        allowEdit
                    />
                </Form>
                <Form
                    header={<FormattedMessage id='Compliancy' />}
                    label='Details'
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormRadio
                        label={<FormattedMessage id={'Is your business currently compliant with Payment Card Industry Data Security Standards (PCIDSS)?'} />}
                        value={{ code: this.props.policy.interWestCyberCustomInfo.cyberInfo.hasPCIDSSCompliance }}
                        references={this.props.reference?.references['YesNoNA']}
                        referencesEvent={() => this.props.reference.doGetReferences('YesNoNA')}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: 'interWestCyberCustomInfo.cyberInfo.hasPCIDSSCompliance', value: value.code });
                        }}
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={'Is your business HIPAA compliant?'} />}
                        value={{ code: this.props.policy.interWestCyberCustomInfo.cyberInfo.hasHIPAACompliance }}
                        references={this.props.reference?.references['YesNoNA']}
                        referencesEvent={() => this.props.reference.doGetReferences('YesNoNA')}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: 'interWestCyberCustomInfo.cyberInfo.hasHIPAACompliance', value: value.code });
                        }}
                        allowEdit
                    />
                </Form>
                <Form
                    header={<FormattedMessage id='History' />}
                    label='Details'
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormRadio
                        label={<FormattedMessage id={'In the past three years, has the applicant experienced: (1) a network or computer system disruption due to an intentional attack or system failure; (2) an actual or suspected data breach; or (3) a cyber extortion demand?'} />}
                        value={{ code: convertFromBoolean(this.props.policy.interWestCyberCustomInfo.cyberInfo.hasExperiencedCyberAttack) }}
                        references={this.props.reference?.references['YesNo']}
                        referencesEvent={() => this.props.reference.doGetReferences('YesNo')}
                        changeEvent={value => {
                            console.log()
                            this.props.doReducePolicyElement({ code: 'interWestCyberCustomInfo.cyberInfo.hasExperiencedCyberAttack', value: convertToBoolean(value.code) });
                        }}
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={'In the past three years, has the applicant received any complaints, claims, or been subject to any litigation involving: Matters of data protection law, intellectual property rights, defamation, rights of privacy, identity theft, denial of service attacks, computer virus infections, theft of information, damage to third-party networks, or access to the Applicant’s network?'} />}
                        value={{ code: convertFromBoolean(this.props.policy.interWestCyberCustomInfo.cyberInfo.hasExperiencedLitigationOnDataRights) }}
                        references={this.props.reference?.references['YesNo']}
                        referencesEvent={() => this.props.reference.doGetReferences('YesNo')}
                        changeEvent={value => {
                            this.props.doReducePolicyElement({ code: 'interWestCyberCustomInfo.cyberInfo.hasExperiencedLitigationOnDataRights', value: convertToBoolean(value.code) });
                        }}
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={'Is the Applicant, any Subsidiary, or any person proposed for this insurance aware of any circumstance that could give rise to a claim against them under this CyberRisk coverage?'} />}
                        value={{ code: convertFromBoolean(this.props.policy.interWestCyberCustomInfo.cyberInfo.hasAnyPartyReasonToClaimAgainstThem) }}
                        references={this.props.reference?.references['YesNo']}
                        referencesEvent={() => this.props.reference.doGetReferences('YesNo')}
                        changeEvent={value => {
                            this.props.doReducePolicyElement({ code: 'interWestCyberCustomInfo.cyberInfo.hasAnyPartyReasonToClaimAgainstThem', value:convertToBoolean(value.code) });
                        }}
                        allowEdit
                    />
                </Form>
            </>
        );
    }
}

export default ProfileInterwestCyberCyber;