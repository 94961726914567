import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Form,
    Classification,
    Sizes
} from 'metrix-common';

class ProfileClassification extends React.Component {

    componentDidMount() {
        this.props.doGetPolicy();
    }

    validatePercentage = () => {
        let totalPer = 0;
        this.props.policy.btisglClassificationInfos.map(ele => totalPer += parseInt(ele.percentage, 10));
        if (totalPer === 100) {
            return true;
        } else {
            return false;
        }
    }

    render() {

        if (!(this.props.reference.references && this.props.reference.references[`BTIS-GL`] && this.props.reference.references[`BTIS-GL`][`Classification`])) {
            this.props.reference.doGetReferences(`BTIS-GL.Classification`);
        }
        let selectedClassifications = [];

        if (this.props?.policy?.btisglClassificationInfos) {
            selectedClassifications = this.props.policy.btisglClassificationInfos.map((ele, id) => ({ ...ele, id: id + 1, percentage: parseInt(ele.percentage, 10) }));
        }

        return (
            <Form
                header={<FormattedMessage id={`Classification`} />}
                label={`Details`}
                saveEvent={this.props.doUpsertPolicy}
                cancelEvent={this.props.doGetPolicy}
                validate={this.validatePercentage}
                allowEdit={this.props.allowEdit}
            >
                <Classification
                    references={this.props.reference.references && this.props.reference.references[`BTIS-GL`] && this.props.reference.references[`BTIS-GL`][`Classification`]}
                    label={`What is your type of work?`}
                    selectedValues={selectedClassifications}
                    bottom={Sizes.lg}
                    changeEvent={(value) => {
                        let id = parseInt(value.componentCode[value.componentCode.length - 1], 10) - 1;
                        this.props.doReducePolicyElement({ code: `btisglClassificationInfos.${id}`, value: { id, code: value.code, value: value.value, percentage: String(value.percentage) }, action: value.action });
                    }}
                    placeholder={`Start typing to tell us what you do or scroll to find a match.`}
                />
            </Form>
        );
    }
}

export default ProfileClassification;