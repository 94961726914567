import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Alignments,
    Form,
    FormInput,
    FormTextArea,
    FormRadio,
    FormDropDown,
    Label,
    convertFromBoolean,
    convertToBoolean,
    FormSwitch
} from 'metrix-common';

class ProfileFluxGlCultivationOperations extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            <React.Fragment>
                <Form
                    header={<FormattedMessage id={`Cultivation operations`} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormRadio
                        label={<FormattedMessage id={`Does the applicant grow any marijuana that is intended to be distributed for recreational purposes?`} />}
                        value={this.props.policy.fluxCustomInfo.cultivationOperation?.isGrowForRecreationalPurposes !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationOperation.isGrowForRecreationalPurposes) }}
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.isGrowForRecreationalPurposes`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.fluxCustomInfo.cultivationOperation?.isGrowForRecreationalPurposes === true &&
                        <FormInput
                        label={<FormattedMessage id={`What percentage of revenue is derived from these operations?`} />}
                        value={this.props.policy.fluxCustomInfo.cultivationOperation?.revenuePercentageFromRecreationalPurposes}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.revenuePercentageFromRecreationalPurposes`, value })}
                            type='number'
                            allowEdit
                        />
                    }
                    <FormRadio
                        label={<FormattedMessage id={`Does the applicant maintain separate records for medical and recreational cannabis products?`} />}
                        value={this.props.policy.fluxCustomInfo.cultivationOperation?.hasMedicalAndRecreationalSeparatedRecords !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationOperation.hasMedicalAndRecreationalSeparatedRecords) }}
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.hasMedicalAndRecreationalSeparatedRecords`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    <FormInput
                        label={<FormattedMessage id={`What is the maximum number of plants on the premises at any one time?`} />}
                        value={this.props.policy.fluxCustomInfo.cultivationOperation?.plantsMaximumAmount}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.plantsMaximumAmount`, value })}
                        type='number'
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={`Are any products containing cannabis manufactured, mixed, labelled, or relabeled by the applicant including: cannabis infused edible products, infused oils or lotions, other food products, or smoking accessories?`} />}
                        value={this.props.policy.fluxCustomInfo.cultivationOperation?.hasLabeledProducts !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationOperation.hasLabeledProducts) }}
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.hasLabeledProducts`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    <FormRadio
                        label={<FormattedMessage id={`Does the applicant use a third party testing lab to test their cannabis and products containing cannabis?`} />}
                        value={this.props.policy.fluxCustomInfo.cultivationOperation?.useThirdPartyTesting !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationOperation.useThirdPartyTesting) }}
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.useThirdPartyTesting`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                    {this.props.policy.fluxCustomInfo.cultivationOperation?.useThirdPartyTesting === false &&
                        <Label bold text={<FormattedMessage id={`How does the insured ensure product purity?`} />} textAlign={Alignments.left} />
                    }
                    {this.props.policy.fluxCustomInfo.cultivationOperation?.useThirdPartyTesting === false &&
                        <FormTextArea
                            text={this.props.policy.fluxCustomInfo.cultivationOperation?.customEnsuringProductPurityDescription}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.customEnsuringProductPurityDescription`, value })}
                        />
                    }
                    <FormRadio
                        label={<FormattedMessage id={`Is cannabis or any products containing cannabis ever released into the stream of commerce (i.e. to dispensaries, other distributors or infused product manufacturers) before testing reports confirming products are free from any contaminants (e.g. pesticides, mold, fungus, heavy metals, etc.) are received from the third party testing laboratory?`} />}
                        value={this.props.policy.fluxCustomInfo.cultivationOperation?.hasSalesBeforeTesting !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationOperation.hasSalesBeforeTesting) }}
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.hasSalesBeforeTesting`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />
                </Form>
                <Form
                    header={<FormattedMessage id={`Cultivation area locations`} />}
                    label={`Are cannabis cultivation areas located in`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormSwitch
                        label={<FormattedMessage id={`Indoors`} />}
                        value={this.props.policy.fluxCustomInfo.cultivationOperation?.hasIndoorCultivationAreas}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.hasIndoorCultivationAreas`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Outdoors`} />}
                        value={this.props.policy.fluxCustomInfo.cultivationOperation?.hasOutdoorCultivationAreas}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.hasOutdoorCultivationAreas`, value: value })}
                        allowEdit
                    />
                    <FormSwitch
                        label={<FormattedMessage id={`Greenhouse`} />}
                        value={this.props.policy.fluxCustomInfo.cultivationOperation?.hasGreenhouseCultivationAreas}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.hasGreenhouseCultivationAreas`, value: value })}
                        allowEdit
                    />
                </Form>
                {this.props.policy.fluxCustomInfo.cultivationOperation?.hasOutdoorCultivationAreas === true &&
                    <Form
                        header={<FormattedMessage id={`Outdoors information`} />}
                        label={`Details`}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormInput
                            label={<FormattedMessage id={`Please provide the approximate size of the growing area in acres:`} />}
                            value={this.props.policy.fluxCustomInfo.cultivationOperation?.outdoorCultivationAreasAcresAmount}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.outdoorCultivationAreasAcresAmount`, value })}
                            type='number'
                            allowEdit
                        />
                        <FormRadio
                            label={<FormattedMessage id={`Are the cultivation areas surrounded by a fence?`} />}
                            value={this.props.policy.fluxCustomInfo.cultivationOperation?.hasOutdoorCultivationAreaFence !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationOperation.hasOutdoorCultivationAreaFence) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.hasOutdoorCultivationAreaFence`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />

                        {this.props.policy.fluxCustomInfo.cultivationOperation?.hasOutdoorCultivationAreaFence === true &&
                            <Label bold text={<FormattedMessage id={`Please describe the fence (i.e. height, material, electrified, etc.)`} />} textAlign={Alignments.left} />
                        }
                        {this.props.policy.fluxCustomInfo.cultivationOperation?.hasOutdoorCultivationAreaFence === true &&
                            <FormTextArea
                                text={this.props.policy.fluxCustomInfo.cultivationOperation?.outdoorCultivationAreaFenceDescription}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.outdoorCultivationAreaFenceDescription`, value })}
                            />
                        }
                        {this.props.policy.fluxCustomInfo.cultivationOperation?.hasOutdoorCultivationAreaFence === true &&
                            <FormRadio
                                label={<FormattedMessage id={`If electrified fencing, barbed wire, or razor wire is used, are there are warning signs posted on the property?`} />}
                                value={this.props.policy.fluxCustomInfo.cultivationOperation?.hasPostedWarningSignsInProperty !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationOperation.hasPostedWarningSignsInProperty) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.hasPostedWarningSignsInProperty`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                        }
                        {this.props.policy.fluxCustomInfo.cultivationOperation?.hasOutdoorCultivationAreaFence === true &&
                            <FormRadio
                                label={<FormattedMessage id={`Does the fencing meet all local, municipal, or state requirements for cannabis cultivation facilities?`} />}
                                value={this.props.policy.fluxCustomInfo.cultivationOperation?.isFenceMeetRequirements !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationOperation.isFenceMeetRequirements) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.isFenceMeetRequirements`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                        }
                        {this.props.policy.fluxCustomInfo.cultivationOperation?.hasOutdoorCultivationAreaFence === true &&
                            <FormRadio
                                label={<FormattedMessage id={`Is the fenced in area locked at all times?`} />}
                                value={this.props.policy.fluxCustomInfo.cultivationOperation?.hasLockedGates !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationOperation.hasLockedGates) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.hasLockedGates`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                        }
                        {this.props.policy.fluxCustomInfo.cultivationOperation?.hasOutdoorCultivationAreaFence === true &&
                            <FormRadio
                                label={<FormattedMessage id={`Are there locked gates at all entrances to the property and/or growing area?`} />}
                                value={this.props.policy.fluxCustomInfo.cultivationOperation?.isFencedAreaLockedAllTimes !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationOperation.isFencedAreaLockedAllTimes) }}
                                references={this.props.reference?.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.isFencedAreaLockedAllTimes`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                        }
                    </Form>
                }
                {this.props.policy.fluxCustomInfo.cultivationOperation?.hasGreenhouseCultivationAreas === true &&
                    <Form
                        header={<FormattedMessage id={`Greenhouse information`} />}
                        label={`Details`}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormRadio
                            label={<FormattedMessage id={`Will the greenhouse be fully enclosed with locking doors?`} />}
                            value={this.props.policy.fluxCustomInfo.cultivationOperation?.hasLockingDoorsInGreenhouse !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationOperation.hasLockingDoorsInGreenhouse) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.hasLockingDoorsInGreenhouse`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                        {this.props.policy.fluxCustomInfo.cultivationOperation?.hasLockingDoorsInGreenhouse === false &&
                            <Label bold text={<FormattedMessage id={`Please describe how the greenhouse will be secured to prevent unauthorized entry?`} />} textAlign={Alignments.left} />
                        }
                        {this.props.policy.fluxCustomInfo.cultivationOperation?.hasLockingDoorsInGreenhouse === false &&
                            <FormTextArea
                                text={this.props.policy.fluxCustomInfo.cultivationOperation?.preventUnauthorizedEntryDescription}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.preventUnauthorizedEntryDescription`, value })}
                            />
                        }
                    </Form>
                }
                {this.props.policy.fluxCustomInfo.cultivationOperation?.useThirdPartyTesting === true &&
                    <Form
                        header={<FormattedMessage id={`Laboratory results`} />}
                        label={`Do all testing reports received from the laboratory indicate the following? (please check all that apply)`}
                        saveEvent={this.props.doUpsertPolicy}
                        cancelEvent={this.props.doGetPolicy}
                        allowEdit={this.props.allowEdit}
                    >
                        <FormSwitch
                            label={<FormattedMessage id={`Products are not contaminated with pesticides?`} />}
                            value={this.props.policy.fluxCustomInfo.cultivationOperation?.hasNotPesticides}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.hasNotPesticides`, value: value })}
                            allowEdit
                        />
                        <FormSwitch
                            label={<FormattedMessage id={`Products are not contaminated by bacteria?`} />}
                            value={this.props.policy.fluxCustomInfo.cultivationOperation?.hasNotBacteria}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.hasNotBacteria`, value: value })}
                            allowEdit
                        />
                        <FormSwitch
                            label={<FormattedMessage id={`Products are not contaminated by mold/fungus?`} />}
                            value={this.props.policy.fluxCustomInfo.cultivationOperation?.hasNotMold}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.hasNotMold`, value: value })}
                            allowEdit
                        />
                        <FormSwitch
                            label={<FormattedMessage id={`Products are not contaminated by mycotoxins?`} />}
                            value={this.props.policy.fluxCustomInfo.cultivationOperation?.hasNotMycotoxins}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.hasNotMycotoxins`, value: value })}
                            allowEdit
                        />
                        <FormSwitch
                            label={<FormattedMessage id={`Products are not contaminated by heavy metals?`} />}
                            value={this.props.policy.fluxCustomInfo.cultivationOperation?.hasNotHeavyMetals}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.hasNotHeavyMetals`, value: value })}
                            allowEdit
                        />
                        <FormSwitch
                            label={<FormattedMessage id={`Products are not contaminated by residual solvents?`} />}
                            value={this.props.policy.fluxCustomInfo.cultivationOperation?.hasNotResidualSolvents}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.hasNotResidualSolvents`, value: value })}
                            allowEdit
                        />
                        <FormSwitch
                            label={<FormattedMessage id={`Cannabinoid profiles? (e.g. THCA, delta8-THC, delta9-THC, CBDA, CBD, CBG, CBN, etc.)`} />}
                            value={this.props.policy.fluxCustomInfo.cultivationOperation?.hasCannabinoidProfiles}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.hasCannabinoidProfiles`, value: value })}
                            allowEdit
                        />
                        <FormSwitch
                            label={<FormattedMessage id={`Cannabinoid dosage per serving (milligrams per serving for each cannabinoid?)`} />}
                            value={this.props.policy.fluxCustomInfo.cultivationOperation?.hasCannabinoidDosagePerServing}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.hasCannabinoidDosagePerServing`, value: value })}
                            allowEdit
                        />
                        <FormSwitch
                            label={<FormattedMessage id={`Terpene profiles?`} />}
                            value={this.props.policy.fluxCustomInfo.cultivationOperation?.hasTerpeneProfiles}
                            changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.hasTerpeneProfiles`, value: value })}
                            allowEdit
                        />
                    </Form>
                }

                <Form
                    header={<FormattedMessage id={`Pest Controls`} />}
                    label={`Details`}
                    saveEvent={this.props.doUpsertPolicy}
                    cancelEvent={this.props.doGetPolicy}
                    allowEdit={this.props.allowEdit}
                >
                    <FormDropDown
                        label={<FormattedMessage id={`What form of pest prevention is the applicant using?`} />}
                        value={{ code: this.props.policy.fluxCustomInfo.cultivationOperation?.pestPreventionTypeCode }}
                        references={this.props.reference?.references[`FLUX-GL`] && this.props.reference.references[`FLUX-GL`][`PestPreventionTypes`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`FLUX-GL.PestPreventionTypes`)}
                        changeEvent={(value) => {
                            this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.pestPreventionTypeCode`, value: value.code });
                            this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.pestPreventionTypeName`, value: value.value });
                        }}
                        allowEdit
                    />
                    {this.props.policy.fluxCustomInfo.cultivationOperation?.pestPreventionTypeCode === 'OTHER' &&
                        <FormInput
                            label={<FormattedMessage id={`Please provide details of the pest control method used?`} />}
                        value={this.props.policy.fluxCustomInfo.cultivationOperation?.pestPreventionTypeOther}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.pestPreventionTypeOther`, value })}
                            type='number'
                            allowEdit
                        />
                    }
                    {this.props.policy.fluxCustomInfo.cultivationOperation?.pestPreventionTypeCode !== 'NA' && this.props.policy.fluxCustomInfo.cultivationOperation?.pestPreventionTypeCode !== undefined && 
                        <FormRadio
                        label={<FormattedMessage id={`Does the applicant apply their own pesticides?`} />}
                        value={this.props.policy.fluxCustomInfo.cultivationOperation?.applyOwnPesticide !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationOperation.applyOwnPesticide) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.applyOwnPesticide`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                    }
                    {this.props.policy.fluxCustomInfo.cultivationOperation?.pestPreventionTypeCode !== 'NA' && this.props.policy.fluxCustomInfo.cultivationOperation?.applyOwnPesticide === false &&
                        <FormRadio
                        label={<FormattedMessage id={`Does the applicant get a copy of the contracted company's insurance before any work begins?`} />}
                        value={this.props.policy.fluxCustomInfo.cultivationOperation?.hasCopyOfContractedPesticideCompanyInsuranceContract !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationOperation.hasCopyOfContractedPesticideCompanyInsuranceContract) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.hasCopyOfContractedPesticideCompanyInsuranceContract`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                    }

                    {this.props.policy.fluxCustomInfo.cultivationOperation?.pestPreventionTypeCode !== 'NA' && this.props.policy.fluxCustomInfo.cultivationOperation?.applyOwnPesticide === true &&
                        <FormRadio
                        label={<FormattedMessage id={`Does the applicant follow all state and federal laws with regards to the use, storage, and disposal of pesticides?`} />}
                        value={this.props.policy.fluxCustomInfo.cultivationOperation?.followPesticideHandlingGuidelines !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationOperation.followPesticideHandlingGuidelines) }}
                            references={this.props.reference?.references[`YesNo`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.followPesticideHandlingGuidelines`, value: convertToBoolean(value.code) })}
                            allowEdit
                        />
                    }
                    <FormRadio
                        label={<FormattedMessage id={`Is the applicant aware of any past or current pesticide issues that would result in a loss or claim?`} />}
                        value={this.props.policy.fluxCustomInfo.cultivationOperation?.isAwareOfPastPesticideIssues !== undefined && { code: convertFromBoolean(this.props.policy.fluxCustomInfo.cultivationOperation.isAwareOfPastPesticideIssues) }}
                        references={this.props.reference?.references[`YesNo`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `fluxCustomInfo.cultivationOperation.isAwareOfPastPesticideIssues`, value: convertToBoolean(value.code) })}
                        allowEdit
                    />                                      
                </Form>
            </React.Fragment>
        );
    }
}

export default ProfileFluxGlCultivationOperations;