import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import {
    toPascalCase,
    Alignments,
    Sizes,
    Grid,
    Row,
    Column,
    Container,
    Label,
    Input,
    SecondaryButton,
    DropDown
} from 'metrix-common';

const SearchHeader = ({ doQueryPolicies, query, products, reference, intl, doReduceQueryElement, doGetListPolicies }) => {
    const { list } = query;

    const [localFilters, setLocalFilters] = useState({
        brokerReferenceGuid: list.brokerReferenceGuid || null,
        product: list.product || null,
        status: list.status || null,
        policyTransactionType: list.policyTransactionType || null,
        clientFirstName: list.clientFirstName || '',
        clientLastName: list.clientLastName || '',
        clientPhoneFullNumber: list.clientPhoneFullNumber || '',
        clientEmail: list.clientEmail || '',
        referenceNumber: list.referenceNumber || '',
        policyNumber: list.policyNumber || '',
        quoteNumber: list.quoteNumber || '',
        quoteColorTag: list.quoteColorTag || '',
        doingBusinessAs: list.doingBusinessAs || ''
    });

    let assignees = [{ code: null, value: 'All Brokers' }];
    if (reference && reference.references && reference.references['ActiveBrokers']) {
        reference.references['ActiveBrokers'].forEach(activeBroker => {
            assignees.push({ code: activeBroker.code, value: activeBroker.value });
        });
    }

    let productsList = [{ code: null, value: 'All Products' }];
    if (products) {
        products.forEach(product => {
            productsList.push({ code: product.code, value: product.name });
        });
    }

    const statuses = [
        { code: null, value: 'All Transaction Status' },
        { code: 'abandoned', value: 'Abandoned' },
        { code: 'bindRequested', value: 'Bind Requested' },
        { code: 'bound', value: 'Bound' },
        { code: 'incomplete', value: 'Incomplete' },
        { code: 'issued', value: 'Issued' },
        { code: 'lost', value: 'Lost' },
        { code: 'quoted', value: 'Quoted' },
        { code: 'quoteAccepted', value: 'Quote Accepted' },
        { code: 'quoteIssued', value: 'Quote Issued' },
        { code: 'referred', value: 'Referred' },
        { code: 'rejected', value: 'Rejected' },
        { code: 'submitted', value: 'Submitted' }
    ];

    const transactionTypes = [
        { code: null, value: 'All Transaction Types' },
        { code: 'renewal', value: 'Renewal' },
        { code: 'new', value: 'New Business' }
    ];

    const handleSearchClick = () => {
        Object.keys(localFilters).forEach(key => {
            doReduceQueryElement({ code: key, value: localFilters[key] });
        });
        doReduceQueryElement({ code: 'skip', value: 0 });
        doReduceQueryElement({ code: 'take', value: 25 });
        doQueryPolicies();
    };

    const handleClearClick = () => {
        const clearedFilters = {
            brokerReferenceGuid: null,
            product: null,
            status: null,
            policyTransactionType: null,
            clientFirstName: '',
            clientLastName: '',
            clientPhoneFullNumber: '',
            clientEmail: '',
            referenceNumber: '',
            policyNumber: '',
            quoteNumber: '',
            quoteColorTag: '',
            doingBusinessAs: '',
        };
        setLocalFilters(clearedFilters);
        Object.keys(clearedFilters).forEach(key => {
            doReduceQueryElement({ code: key, value: clearedFilters[key] });
        });
        doReduceQueryElement({ code: 'skip', value: 0 });
        doReduceQueryElement({ code: 'take', value: 25 });
        doGetListPolicies();
    };

    const handleInputChange = (field, value) => {
        setLocalFilters(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    const getFilterText = () => {
        const filterText = [];
        const { list } = query;

        if (list.brokerReferenceGuid) {
            filterText.push(`[b]Assigned To[b]: ${assignees.find(x => x.code === list.brokerReferenceGuid).value}`);
        }

        if (list.product) {
            const productName = products.find(product => product.code === list.product).name;
            filterText.push(`[b]Product[b]: ${productName}`);
        }

        if (list.status) {
            filterText.push(`[b]Transaction Status[b]: ${toPascalCase(list.status)}`);
        }

        if (list.policyTransactionType) {
            const displayedType = list.policyTransactionType;
            filterText.push(`[b]Transaction Type[b]: ${toPascalCase(displayedType)}`);
        }

        if (list.clientFirstName) {
            filterText.push(`[b]First Name[b]: ${list.clientFirstName}`);
        }

        if (list.clientLastName) {
            filterText.push(`[b]Last Name[b]: ${list.clientLastName}`);
        }

        if (list.clientPhoneFullNumber) {
            filterText.push(`[b]Phone[b]: ${list.clientPhoneFullNumber}`);
        }

        if (list.clientEmail) {
            filterText.push(`[b]Email[b]: ${list.clientEmail}`);
        }

        if (list.referenceNumber) {
            filterText.push(`[b]Reference Number[b]: ${list.referenceNumber}`);
        }

        if (list.policyNumber) {
            filterText.push(`[b]Policy Number[b]: ${list.policyNumber}`);
        }

        if (list.quoteNumber) {
            filterText.push(`[b]Quote Number[b]: ${list.quoteNumber}`);
        }

        if (list.quoteColorTag) {
            filterText.push(`[b]Quote Color Tag[b]: ${list.quoteColorTag}`);
        }

        if (list.doingBusinessAs) {
            filterText.push(`[b]Doing Business as[b]: ${list.doingBusinessAs}`);
        }

        return filterText.join(', ');
    };

    return (
        <Container>
            <Container top={Sizes.xs} right={Sizes.xs} left={Sizes.xs}>
                <Container top={Sizes.none} bottom={Sizes.xs}>
                    <Grid>
                        <Row>
                            <Column xs={11} nogutter>
                                <Label text={getFilterText()} textAlign={Alignments.left} />
                            </Column>
                        </Row>
                        <Row nogutter>
                            <Column xs={0} md={0} lg={2} />
                            <Column xs={12} md={12}>
                                <Grid>
                                    <Row nogutter>
                                        <Column xs={6} md={3}>
                                            <DropDown
                                                top={Sizes.xs}
                                                placeholder='Assigned To'
                                                value={{ code: localFilters.brokerReferenceGuid }}
                                                references={assignees}
                                                referencesEvent={() => reference.doGetReferences('ActiveBrokers')}
                                                changeEvent={value => handleInputChange('brokerReferenceGuid', value.code)}
                                                allowEdit
                                            />
                                        </Column>
                                        <Column xs={6} md={3}>
                                            <DropDown
                                                top={Sizes.xs}
                                                placeholder={intl.formatMessage({ id: 'Products' })}
                                                value={{ code: localFilters.product }}
                                                references={productsList}
                                                referencesEvent={() => {}}
                                                changeEvent={value => handleInputChange('product', value.code)}
                                                allowEdit
                                            />
                                        </Column>
                                        <Column xs={6} md={3}>
                                            <DropDown
                                                top={Sizes.xs}
                                                placeholder={intl.formatMessage({ id: 'Transaction Status' })}
                                                value={{ code: localFilters.status }}
                                                references={statuses}
                                                referencesEvent={() => {}}
                                                changeEvent={value => handleInputChange('status', value.code)}
                                                allowEdit
                                            />
                                        </Column>
                                        <Column xs={6} md={3}>
                                            <DropDown
                                                top={Sizes.xs}
                                                placeholder={intl.formatMessage({ id: 'Transaction Type' })}
                                                value={{ code: localFilters.policyTransactionType }}
                                                references={transactionTypes}
                                                referencesEvent={() => {}}
                                                changeEvent={value => handleInputChange('policyTransactionType', value.code)}
                                                allowEdit
                                            />
                                        </Column>
                                        <Column xs={6} md={3}>
                                            <Input
                                                top={Sizes.xs}
                                                inverse
                                                placeholder={intl.formatMessage({ id: 'app.components.SearchBar.fname' })}
                                                value={localFilters.clientFirstName}
                                                changeEvent={value => handleInputChange('clientFirstName', value)}
                                            />
                                        </Column>
                                        <Column xs={6} md={3}>
                                            <Input
                                                top={Sizes.xs}
                                                inverse
                                                placeholder={intl.formatMessage({ id: 'app.components.SearchBar.lname' })}
                                                value={localFilters.clientLastName}
                                                changeEvent={value => handleInputChange('clientLastName', value)}
                                            />
                                        </Column>
                                        <Column xs={6} md={3}>
                                            <Input
                                                top={Sizes.xs}
                                                inverse
                                                placeholder='Insured Phone'
                                                value={localFilters.clientPhoneFullNumber}
                                                changeEvent={value => handleInputChange('clientPhoneFullNumber', value)}
                                            />
                                        </Column>
                                        <Column xs={6} md={3}>
                                            <Input
                                                top={Sizes.xs}
                                                inverse
                                                placeholder='Insured Email'
                                                value={localFilters.clientEmail}
                                                changeEvent={value => handleInputChange('clientEmail', value)}
                                            />
                                        </Column>
                                        <Column xs={6} md={3}>
                                            <Input
                                                top={Sizes.xs}
                                                inverse
                                                placeholder={'Reference Number'}
                                                value={localFilters.referenceNumber}
                                                changeEvent={value => handleInputChange('referenceNumber', value)}
                                            />
                                        </Column>
                                        <Column xs={6} md={3}>
                                            <Input
                                                top={Sizes.xs}
                                                inverse
                                                placeholder={'Policy Number'}
                                                value={localFilters.policyNumber}
                                                changeEvent={value => handleInputChange('policyNumber', value)}
                                            />
                                        </Column>
                                        <Column xs={6} md={3}>
                                            <Input
                                                top={Sizes.xs}
                                                inverse
                                                placeholder={'Quote Number'}
                                                value={localFilters.quoteNumber}
                                                changeEvent={value => handleInputChange('quoteNumber', value)}
                                            />
                                        </Column>
                                        <Column xs={0} md={3} />
                                        <Column xs={6} md={3}>
                                            <Input
                                                top={Sizes.xs}
                                                inverse
                                                placeholder={'Quote Color Tag'}
                                                value={localFilters.quoteColorTag}
                                                changeEvent={value => handleInputChange('quoteColorTag', value)}
                                            />
                                        </Column>
                                        <Column xs={6} md={3}>
                                            <Input
                                                top={Sizes.xs}
                                                inverse
                                                placeholder={'Doing Business as'}
                                                value={localFilters.doingBusinessAs}
                                                changeEvent={value => handleInputChange('doingBusinessAs', value)}
                                            />
                                        </Column>
                                        <Column xs={6} md={12} lg={12}>
                                            <Container inline fullSize top={Sizes.xs} hoizontalAlignment={Alignments.center}>
                                                <SecondaryButton
                                                    text={<FormattedMessage id='covera.common.general.search' />}
                                                    bottom={Sizes.xs}
                                                    clickEvent={handleSearchClick}
                                                />
                                                <SecondaryButton
                                                    left={Sizes.xs}
                                                    bottom={Sizes.xs}
                                                    text='Clear'
                                                    clickEvent={handleClearClick}
                                                />
                                            </Container>
                                        </Column>
                                    </Row>
                                </Grid>
                            </Column>
                            <Column xs={0} md={0} lg={2} />
                        </Row>
                    </Grid>
                </Container>
            </Container>
        </Container>
    );
};

SearchHeader.propTypes = {
    doQueryPolicies: PropTypes.func.isRequired,
    query: PropTypes.object.isRequired,
    reference: PropTypes.object.isRequired,
    products: PropTypes.array.isRequired,
    intl: PropTypes.object.isRequired
};

export default injectIntl(SearchHeader);