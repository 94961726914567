import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import {
    Sizes,
    Alignments,
    Separator,
    Grid,
    Row,
    Column,
    Container,
    Header,
    Label,
    PolicyAttention,
    PolicyBind,
    PolicyQuery,
    PolicyQuote,
    PolicyQuoteAccept,
    PolicyIssue
} from 'metrix-common';
import Badge from '../Badge';

const HeaderContentFlex = styled.div`
	display: flex;
	flex: 1;
	gap: 1rem;
	justify-content: ${({ $align }) => ($align === 'left' ? 'flex-start' : $align === 'right' ? 'flex-end' : 'center')};
	align-items: center;
	padding: 0 5px;
    margin-top: 30px;
	box-sizing: border-box;
	overflow: hidden;
`;

class ProfileSummaryHeader extends React.Component {
    renderIcon(policyStatus) {
        let icon = null;
        switch (policyStatus) {
            case 'incomplete':
            case 'submitted':
            case 'accepted':
                icon = <PolicyQuery />;
                break;
            case 'quoted':
            case 'quoteIssued':
                icon = <PolicyQuote />;
                break;
            case 'quoteAccepted':
                icon = <PolicyQuoteAccept />;
                break;
            case 'rejected':
            case 'abandoned':
            case 'lost':
                icon = <PolicyAttention />;
                break;
            case 'bindRequested':
            case 'bound':
                icon = <PolicyBind />;
                break;
            case 'issued':
                icon = <PolicyIssue />;
                break;
            default:
                icon = <PolicyBind />;
                break;
        }

        return icon;
    }

    getBadgeColor(status, type) {
        const colors = {
            badgeDocumentIssueStatus: {
                'Policy Issued': 'green',
                'Proposal Issued': 'yellow',
                'Not Issued': 'red'
            },
            badgePaymentStatus: {
                'Paid': 'green',
                'Not Paid': 'red'
            },
            badgePolicyStatusDescription: {
                'None': 'grey',
                'Incomplete': 'yellow',
                'Submitted': 'yellow',
                'Abandoned': 'yellow',
                'Accepted': 'yellow',
                'Quoted': 'yellow',
                'Quoted (indicative)': 'orange',
                'Underwriting Required': 'yellow',
                'Referred': 'yellow',
                'Quote Issued': 'yellow',
                'Bind Requested': 'yellow',
                'Bound': 'yellow',
                'Issued': 'green',
                'Lost': 'red',
                'Cancelled': 'red',
                'Quote Accepted': 'yellow',
                'Quoting': 'yellow'
            }
        };
        return colors[type][status] || 'grey';
    }

    render() {
        const { policySummary } = this.props;
        const showIndicativeQuoteBadge = policySummary.isIndicativeQuote && policySummary.status  === 'quoted';
        const paymentStatus = policySummary.badgePaymentStatus;
        const policyStatus = showIndicativeQuoteBadge ? 'Quoted (indicative)' : policySummary.badgePolicyStatusDescription;
        const documentIssueStatus = policySummary.badgeDocumentIssueStatus;

        const showPaymentBadge = paymentStatus ?? false;
        const showPolicyBadge = policyStatus ?? false;
        const showDocumentBadge = documentIssueStatus ?? false;
        const showHeaderContentFlex = showPaymentBadge || showPolicyBadge || showDocumentBadge;

        return (
            <Container fullWidth>
                <Grid>
                    <Row>
                        <Column>
                            <Container inline top={Sizes.xl} horizontalAlignment={Alignments.center} >
                                {this.renderIcon(this.props.policySummary.status)}
                            </Container>
                            <Header
                                size={Sizes.xl}
                                top={Sizes.sm}
                                bottom={Sizes.xs}
                                text={
                                    <FormattedMessage
                                        id={`app.components.Profile.Summary.Header.${this.props.policySummary.status}Header`}
                                    />}
                            />
                            <Label
                                text={
                                    <FormattedMessage
                                        id={`app.components.Profile.Summary.Header.${this.props.policySummary.status}Label`}
                                    />
                                }
                            />
                            {showHeaderContentFlex && (
                                <HeaderContentFlex>
                                    {showPaymentBadge && <Badge text={paymentStatus} color={this.getBadgeColor(paymentStatus, 'badgePaymentStatus')} />}
                                    {showPolicyBadge && <Badge text={policyStatus} color={this.getBadgeColor(policyStatus, 'badgePolicyStatusDescription')} />}
                                    {showDocumentBadge && <Badge text={documentIssueStatus} color={this.getBadgeColor(documentIssueStatus, 'badgeDocumentIssueStatus')} />}
                                </HeaderContentFlex>
                            )}
                        </Column>
                    </Row>
                </Grid>
                <Separator solid top={Sizes.lg} />
            </Container>
        );
    }
}

export default ProfileSummaryHeader;
