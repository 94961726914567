import { put, call, select } from 'redux-saga/effects';
import { reduceOperation, reduceLoading, getSessionSaga } from 'metrix-common';
import { reduceTooltipInfo } from '../../actions/policy/reduceTooltipInfo';

export function* getTooltipInfoSaga({ payload }) {
    try {
        yield put(reduceLoading({ code: 'getTooltipInfo', value: true }));
        const session = yield getSessionSaga();
        let response = yield call(fetch, `/api/product/${payload}/tooltip/`, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Authorization': session.authenticationToken.token,
                'Content-Type': 'application/json'
            }
        });
        let result = yield call([response, 'json']);
        yield put(reduceOperation(result.operation));
        if (!result.operation || !result.operation.status) {
            yield put(reduceLoading({ code: 'getTooltipInfo', value: false }));
            return;
        }
        yield put(reduceTooltipInfo({ pages: result.pages }));
        yield put(reduceLoading({ code: 'getTooltipInfo', value: false }));
        return;
    }
    catch (error) {
        console.log('Error while calling Get Tooltip Information API - ', error);
        yield put(reduceLoading({ code: 'getTooltipInfo', value: false }));
        return;
    }
}
