import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Alignments,
    Divider,
    Container,
    Form,
    FormInput,
    FormDropDown,
    FormDate,
    Header,
    FormSwitch,
    Sizes,
    SummaryGroup
} from 'metrix-common';

import ProfileBtisWcPriorInsurance from './PriorInsurance';

class ProfileBtisWcInsuranceHistory extends React.Component {
    state = {
        mode: `priorInsuranceSummaryView`,
        currentPriorInsurance: null
    };

    componentDidMount() {
    }

    getPriorInsurances = () => {
        let priorInsurances = [];

        if (this.props.policy?.btisWcCustomInfo?.priorInsurances && this.props.policy.btisWcCustomInfo.priorInsurances.length > 0) {
            this.props.policy.btisWcCustomInfo.priorInsurances.forEach((priorInsurance) => {
                let option = { code: `priorInsuranceOption`, value: priorInsurance.guid };

                option.elements = [];
                option.elements.push({ code: `Label`, value: priorInsurance.priorInsurancePeriodPageName });

                priorInsurances.push(option);
            });
        }

        return priorInsurances;
    }

    getIntegerValue = (input) => {
        const parsed = parseInt(input, 10);
        if (isNaN(parsed)) { return 0; }
        return parsed;
    }

    render() {
        return (
            <React.Fragment>
                {this.state.mode === `priorInsuranceSummaryView` &&
                    <React.Fragment>
                        <Form
                            header={<FormattedMessage id={`Insurance History`} />}
                            label={`Details`}
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            allowEdit={this.props.allowEdit}
                        >

                            {/*HAS PREVIOUS COVERAGE?*/}
                            <FormSwitch
                                label={<FormattedMessage id={`Have you had prior workers compensation coverage?`} />}
                                value={this.props.policy.btisWcCustomInfo.insuranceHistory?.hasPriorWorkersCompensationCoverage ?? false}
                                changeEvent={
                                    (value) => {
                                        this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.hasPriorWorkersCompensationCoverage`, value: value });
                                        this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.notCurrentlyCoveredReasonCode`, value: undefined });
                                        this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.businessNotCoveredReasonCode`, value: undefined });
                                        this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.businessNotCoveredReasonName`, value: undefined });
                                        this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.priorInsuranceYearsCode`, value: undefined });
                                        this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.priorInsuranceYearsName`, value: undefined });
                                        this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.amountOfClaimsMadeInLastFiveYears`, value: undefined });
                                        this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.empWorkStartDate`, value: undefined });
                                        this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.hasWorkersCompensationInsuranceClaims`, value: undefined });
                                        this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.hasWorkersCompensationStopped`, value: undefined });
                                        this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.notCurrentlyCoveredDescription`, value: undefined });
                                        this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.reasonWhyCoverageStoppedCode`, value: undefined });
                                    }}
                                allowEdit
                            />

                            {/*HAS PRIOR COVERAGE*/}
                            {/*HOW MANY YEARS?*/}
                            {(
                                (this.props.policy.btisWcCustomInfo.insuranceHistory?.hasPriorWorkersCompensationCoverage === true)
                            )
                                &&
                                <FormDropDown
                                    label={<FormattedMessage id={`How many years of prior coverage does the Applicant have?`} />}
                                value={
                                    {
                                    code: this.props.policy.btisWcCustomInfo.insuranceHistory?.priorInsuranceYearsCode,
                                    value: this.props.policy.btisWcCustomInfo.insuranceHistory?.priorInsuranceYearsName
                                    }
                                }
                                    references={this.props.reference?.references[`BTIS-WC`] && this.props.reference.references[`BTIS-WC`][`PriorInsuranceYears`]}
                                    referencesEvent={() => this.props.reference.doGetReferences(`BTIS-WC.PriorInsuranceYears`)}
                                    changeEvent={(value) => {
                                        this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.priorInsuranceYearsCode`, value: value.code });
                                        this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.priorInsuranceYearsName`, value: value.value });
                                    }}
                                    allowEdit
                                />
                            }

                            {/*HAS PRIOR COVERAGE*/}
                            {/*DO THEY HAVE CURRENT COVERAGE? :: DEFAULT TO YES IF NULL*/}
                            {
                                (
                                    (this.props.policy.btisWcCustomInfo.insuranceHistory?.hasPriorWorkersCompensationCoverage === true)
                                )
                                &&
                                <FormSwitch
                                    label={<FormattedMessage id={`Does the applicant currently have Workers Compensation Insurance?`} />}
                                    value={this.props.policy.btisWcCustomInfo.insuranceHistory?.hasCurrentWorkersCompensationInsurance ?? true}
                                    changeEvent={(value) => {
                                        this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.hasCurrentWorkersCompensationInsurance`, value: value });
                                        this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.businessNotCoveredReasonCode`, value: undefined });
                                        this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.priorInsuranceYearsCode`, value: undefined });
                                        this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.priorInsuranceYearsName`, value: undefined });
                                    }
                                    }
                                    allowEdit
                                />
                            }

                            {/* DOES NOT HAVE PRIOR COVERAGE. EXPLAIN WHY.*/}
                            {/* NOT CURRENT COVERED REASON*/}
                            {
                                (
                                    (this.props.policy.btisWcCustomInfo.insuranceHistory?.hasPriorWorkersCompensationCoverage === false)
                                )
                                &&
                                <FormDropDown
                                    label={<FormattedMessage id={`Why has coverage not been placed?`} />}
                                    value={{ code: this.props.policy.btisWcCustomInfo.insuranceHistory?.notCurrentlyCoveredReasonCode, value: this.props.policy.btisWcCustomInfo.insuranceHistory?.notCurrentlyCoveredReasonName }}
                                    references={this.props.reference?.references[`BTIS-WC`] && this.props.reference.references[`BTIS-WC`][`NotCurrentlyCoveredReason`]}
                                    referencesEvent={() => this.props.reference.doGetReferences(`BTIS-WC.NotCurrentlyCoveredReason`)}
                                    changeEvent={(value) => {
                                        this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.notCurrentlyCoveredReasonCode`, value: value.code });
                                        this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.notCurrentlyCoveredReasonName`, value: value.value });
                                        this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.notCurrentlyCoveredDescription`, value: undefined });
                                    }}
                                    allowEdit
                                />
                            }

                            {/*HAS PRIOR COVERAGE*/}
                            {/*DOES NOT HAVE CURRENT COVERAGE*/}
                            {/*EXPLAIN WHY*/}
                            {
                                (
                                    (this.props.policy.btisWcCustomInfo.insuranceHistory?.hasCurrentWorkersCompensationInsurance === false) &&
                                    (this.props.policy.btisWcCustomInfo.insuranceHistory?.hasPriorWorkersCompensationCoverage === true)

                                )
                                &&
                                <FormDropDown
                                    label={<FormattedMessage id={`Why are you or your business not currently covered?`} />}
                                    value={{ code: this.props.policy.btisWcCustomInfo.insuranceHistory?.businessNotCoveredReasonCode, value: this.props.policy.btisWcCustomInfo.insuranceHistory?.businessNotCoveredReasonName }}
                                    references={this.props.reference?.references[`BTIS-WC`] && this.props.reference.references[`BTIS-WC`][`BusinessNotCoveredReason`]}
                                    referencesEvent={() => this.props.reference.doGetReferences(`BTIS-WC.BusinessNotCoveredReason`)}
                                    changeEvent={(value) => {
                                        this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.businessNotCoveredReasonCode`, value: value.code });
                                        this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.businessNotCoveredReasonName`, value: value.value });
                                        this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.notCurrentlyCoveredDescription`, value: undefined });
                                    }}
                                    allowEdit
                                />
                            }
                            {/*OTHER*/}
                            {
                                (this.props.policy.btisWcCustomInfo.insuranceHistory?.notCurrentlyCoveredReasonCode === `Other` ||
                                    this.props.policy.btisWcCustomInfo.insuranceHistory?.businessNotCoveredReasonCode === `Other`)
                                &&
                                <FormInput
                                    label={`Please describe`}
                                    value={this.props.policy.btisWcCustomInfo.insuranceHistory?.notCurrentlyCoveredDescription}
                                    type='text'
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.notCurrentlyCoveredDescription`, value })}
                                    allowEdit
                                />
                            }

                            {/*HAS PRIOR COVERAGE*/}
                            {/*DID IT STOP?*/}
                            {
                                (
                                    (this.props.policy.btisWcCustomInfo.insuranceHistory?.hasPriorWorkersCompensationCoverage === true)
                                ) &&
                                <FormSwitch
                                    label={<FormattedMessage id={`Have you ever had previous workers compensation coverage stopped for any reason?`} />}
                                    value={this.props.policy.btisWcCustomInfo.insuranceHistory?.hasWorkersCompensationStopped ?? false}
                                    changeEvent={
                                        (value) => {
                                            this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.hasWorkersCompensationStopped`, value: value });
                                            this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.reasonWhyCoverageStoppedCode`, value: undefined });
                                            this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.reasonWhyCoverageStoppedName`, value: undefined });
                                        }
                                    }
                                    allowEdit
                                />
                            }
                            {/*WHY DID IT STOP?*/}
                            {
                                (
                                    (this.props.policy.btisWcCustomInfo.insuranceHistory?.hasWorkersCompensationStopped === true)
                                ) &&
                                <FormDropDown
                                    label={<FormattedMessage id={`Reason why your previous coverage was stopped.`} />}
                                    value={this.props.policy.btisWcCustomInfo.insuranceHistory?.reasonWhyCoverageStoppedCode &&
                                    {
                                        code: this.props.policy.btisWcCustomInfo.insuranceHistory?.reasonWhyCoverageStoppedCode,
                                        value: this.props.policy.btisWcCustomInfo.insuranceHistory?.reasonWhyCoverageStoppedName
                                    }}
                                    references={this.props.reference?.references[`BTIS-WC`] && this.props.reference.references[`BTIS-WC`][`ReasonWhyCoverageStopped`]}
                                    referencesEvent={() => this.props.reference.doGetReferences(`BTIS-WC.ReasonWhyCoverageStopped`)}
                                    changeEvent={(value) => {
                                        this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.reasonWhyCoverageStoppedCode`, value: value.code });
                                        this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.reasonWhyCoverageStoppedName`, value: value.value });
                                        this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.priorInsuranceLapseReasonDescription`, value: undefined });
                                    }}
                                    allowEdit
                                />
                            }
                            {/*OTHER*/}
                            {
                                (
                                    (this.props.policy.btisWcCustomInfo.insuranceHistory?.reasonWhyCoverageStoppedCode === `other`
                                    )
                                )
                                &&
                                <FormInput
                                    label={`Please describe`}
                                    value={this.props.policy.btisWcCustomInfo.insuranceHistory?.priorInsuranceLapseReasonDescription}
                                    type='text'
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.priorInsuranceLapseReasonDescription`, value })}
                                    allowEdit
                                />
                            }

                            {
                                (
                                    (this.props.policy.btisWcCustomInfo.insuranceHistory?.hasPriorWorkersCompensationCoverage === true)
                                ) &&

                                <FormSwitch
                                    label={<FormattedMessage id={`Have you or your business had any previous workers compensation insurance claims?`} />}
                                    value={this.props.policy.btisWcCustomInfo.insuranceHistory?.hasWorkersCompensationInsuranceClaims ?? false}
                                    changeEvent={
                                        (value) => {
                                            this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.hasWorkersCompensationInsuranceClaims`, value: value });
                                        }
                                    }
                                    allowEdit
                                />
                            }

                            {
                                ((this.props.policy.btisWcCustomInfo.insuranceHistory?.hasWorkersCompensationInsuranceClaims === true)
                                )
                                &&
                                <FormDropDown
                                    label={<FormattedMessage id={`Total number of workers compensation claims made in the last 5 years`} />}
                                    value={
                                    {
                                        code: this.props.policy.btisWcCustomInfo.insuranceHistory?.amountOfClaimsMadeInLastFiveYearsCode,
                                        value: this.props.policy.btisWcCustomInfo.insuranceHistory?.amountOfClaimsMadeInLastFiveYears
                                    }}
                                    references={this.props.reference?.references[`BTIS-WC`] && this.props.reference.references[`BTIS-WC`][`AmountOfClaimsMadeInLastFiveYears`]}
                                    referencesEvent={() => this.props.reference.doGetReferences(`BTIS-WC.AmountOfClaimsMadeInLastFiveYears`)}
                                    changeEvent={(value) => {
                                        this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.amountOfClaimsMadeInLastFiveYearsCode`, value: value.code });
                                        this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.amountOfClaimsMadeInLastFiveYears`, value: value.value })
                                    }}
                                    allowEdit
                                />
                            }
                            {/*DOES NOT HAVE PRIOR COVERAGE*/}
                            {/*DOES NOT HAVE NO EMPLOYEES*/}
                            {
                                (this.props.policy.btisWcCustomInfo.insuranceHistory?.hasPriorWorkersCompensationCoverage === false
                                    && this.props.policy.btisWcCustomInfo.insuranceHistory?.notCurrentlyCoveredReasonCode !== `NoEmployees`
                                    && this.props.policy.btisWcCustomInfo.insuranceHistory?.notCurrentlyCoveredReasonCode !== undefined
                                )
                                &&
                                <FormDate
                                    label={<FormattedMessage id={`When did employees begin working for the Applicant?`} />}
                                    value={this.props.policy.btisWcCustomInfo.insuranceHistory?.empWorkStartDate}
                                    changeEvent={(value) => this.props.doReducePolicyElement({ code: `btisWcCustomInfo.insuranceHistory.empWorkStartDate`, value })}
                                    culture={this.props.context.session.culture}
                                    mode={`past`}
                                    allowEdit
                                />
                            }
                        </Form>

                        {
                            ((this.getIntegerValue(this.props.policy.btisWcCustomInfo.insuranceHistory?.priorInsuranceYearsCode) > 0) &&
                                (this.props.policy.btisWcCustomInfo.insuranceHistory?.hasPriorWorkersCompensationCoverage !== false
                                    || this.props.policy.btisWcCustomInfo.insuranceHistory?.notCurrentlyCoveredReasonCode !== `NoEmployees`)
                            )
                            &&
                            <React.Fragment>
                                <Divider top={Sizes.xl} />
                                <Header size={Sizes.lg} textAlign={Alignments.left} text={`Prior Insurances`} />
                                <Container top={Sizes.xl} bottom={Sizes.xl}>
                                    <SummaryGroup
                                        allowAdd={this.props.allowEdit && this.getPriorInsurances().length < 4}
                                        addEvent={() => {
                                            this.props.doReduceParameters([]);
                                            this.props.doExecutePolicyAction({ code: `BTIS-WC.CREATEPRIORINSURANCE` });
                                        }}
                                        allowEdit
                                        editEvent={(priorInsurance) => {
                                            this.setState({ currentPriorInsurance: priorInsurance, mode: `priorInsuranceView` });
                                        }}
                                        allowDelete={this.props.allowEdit}
                                        deleteEvent={(priorInsurance) => {
                                            this.props.doReduceParameter({ code: `PriorInsuranceGuid`, value: priorInsurance });
                                            this.props.doExecutePolicyAction({ code: `BTIS-WC.DELETEPRIORINSURANCE` });
                                        }}
                                        entities={this.getPriorInsurances()}
                                        icon={`person`}
                                    />
                                </Container>
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
                {this.state.mode === `priorInsuranceView` &&
                    <ProfileBtisWcPriorInsurance
                        priorInsurance={this.props.policy.btisWcCustomInfo.priorInsurances.find(x => x.guid === this.state.currentPriorInsurance)}
                        claims={this.props.policy.btisWcCustomInfo.claims}
                        dotProp={`btisWcCustomInfo.priorInsurances.${this.props.policy.btisWcCustomInfo.priorInsurances.findIndex(x => x.guid === this.state.currentPriorInsurance)}.`}
                        backEvent={() => this.setState({ mode: `priorInsuranceSummaryView`, currentPriorInsurance: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReduceParameter={this.props.doReduceParameter}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        doExecutePolicyAction={this.props.doExecutePolicyAction}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
            </React.Fragment>
        );
    }
}

export default ProfileBtisWcInsuranceHistory;