import { put, call, select } from 'redux-saga/effects';
import { reduceOperation, reduceLoading, getSessionSaga } from 'metrix-common';

export function* sendEmailRequestSaga({ payload }) {
    try {
        yield put(reduceLoading({ code: 'sendEmail', value: true }));
        const session = yield getSessionSaga();

        const response = yield call(fetch, '/api/clientnotification/send-email', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Authorization': session.authenticationToken.token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                policyTransactionGuid: payload.policyTransactionGuid,
                templateType: payload.templateType,
                documentAttachmentGuids: payload.documentAttachmentGuids
            })
        });

        const result = yield call([response, 'json']);

        if (result.operation.status) {
            // yield put(sendEmailSuccess());
        } else {
            // yield put(sendEmailFailure(result.operation.message));
        }

        yield put(reduceOperation(result.operation));
    }
    catch (error) {
        console.error(error);
    }
    finally {
        yield put(reduceLoading({ code: 'sendEmail', value: false }));
    }
}