import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    Sizes,
    Alignments,
    Container,
    Divider,
    Header,
    BackButton,
    Form,
    AddressForm,
    FormInput,
    FormSwitch,
    SummaryGroup,
    Label
} from 'metrix-common';

import ProfileBtisWcClassification from './Classification';

class ProfileBtisWcLocation extends React.Component {
    state = {
        mode: `locationView`,
        currentClassification: null
    };

    componentDidMount() {
        this.props.reference.doGetReferences(`BTIS-WC.Classifications.${this.props.location?.address?.stateCode}`);
    }

    getLocationName = () => {
        let locationName = null;

        if (this.props.location && this.props.location.locationName) {
            locationName = `${this.props.location.locationName}`;
        }

        return locationName;
    }

    getClassifications = () => {
        let classifications = [];

        if (this.props.classifications &&
            this.props.classifications.filter(x => x.locationGuid === this.props.location.guid).length > 0) {
            this.props.classifications.filter(x => x.locationGuid === this.props.location.guid).forEach((classification) => {
                let option = { code: `classificationOption`, value: classification.guid };

                let classificationPageName = classification.classificationCode !== undefined ? classification.classificationName : classification.classificationPageName !== undefined ? classification.classificationPageName : 'New Classification';

                option.elements = [];
                option.elements.push({ code: `Label`, value: classificationPageName });

                classifications.push(option);
            });
        }

        return classifications;
    }

    render() {
        let locationName = this.getLocationName() || `New Location`;
        return (
            <React.Fragment>
                {this.state.mode === `locationView` &&
                    <React.Fragment>
                        <BackButton clickEvent={this.props.backEvent} />
                        <Form
                            header={<FormattedMessage id={locationName} />}
                            label={`Details`}
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            allowEdit={this.props.allowEdit}
                        >
                            <FormInput
                                label={<FormattedMessage id={`Label`} />}
                                value={this.props.location?.locationName}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}locationName`, value })}
                                allowEdit
                            />
                            <FormSwitch
                                label={<FormattedMessage id={`Is it primary location?`} />}
                                value={this.props.location?.isPrimary}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}isPrimary`, value: value })}
                                allowEdit
                            />
                        </Form>
                        <AddressForm
                            {...this.props}
                            enableAddressSearch
                            addressSearchCountryCode={`USA`}
                            header={<FormattedMessage id={`Address Details`} />}
                            label={`Location Address`}
                            saveEvent={this.props.doUpsertPolicy}
                            cancelEvent={this.props.doGetPolicy}
                            reference={this.props.reference}
                            changeEvent={(dotProp, value) => {
                                dotProp === `address` ?
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}address`, value })
                                    :
                                    this.props.doReducePolicyElement({ code: `${this.props.dotProp}address.${dotProp}`, value })
                            }}
                            allowEdit={this.props.allowEdit}
                            address={this.props.location && this.props.location.address}
                        />

                        <Divider top={Sizes.xl} />
                        <Header size={Sizes.lg} textAlign={Alignments.left} text={`Classifications at ` + locationName} />
                        {
                            !this.props.location.address.stateCode &&
                            <Label text={`Classification depends on Location State.`} textAlign={Alignments.left} left={Sizes.xs} />
                        }
                        <Container top={Sizes.xl} bottom={Sizes.xl}>
                            {
                                this.props.location.address.stateCode &&
                                this.props.location.address.stateCode != `WA` &&
                                this.props.location.address.stateCode != `ND` &&
                                this.props.location.address.stateCode != `WY` &&
                                this.props.location.address.stateCode != `OH` &&
                            <SummaryGroup
                                allowAdd={this.props.allowEdit && (this.props.classifications === undefined || this.getClassifications().length < 4)}
                                addEvent={() => {
                                    this.props.doReduceParameter({ code: `LocationGuid`, value: this.props.location.guid });
                                    this.props.doExecutePolicyAction({ code: `BTIS-WC.CREATECLASSIFICATION` });
                                }}
                                allowEdit
                                editEvent={(classification) => {
                                    this.setState({ currentClassification: classification, mode: `classificationView` });
                                }}
                                allowDelete={this.props.allowEdit}
                                deleteEvent={(classification) => {
                                    this.props.doReduceParameter({ code: `ClassificationGuid`, value: classification });
                                    this.props.doExecutePolicyAction({ code: `BTIS-WC.DELETECLASSIFICATION` });
                                    this.setState({ deleteModal: false });
                                    this.setState({ currentClassification: null, mode: `locationView` });
                                }}
                                entities={this.getClassifications()}
                                icon={`home`}
                            />
                        }
                        </Container>
                    </React.Fragment>
                }
                {this.state.mode === `classificationView` &&

                    <ProfileBtisWcClassification
                        classification={this.props.classifications.find(x => x.guid === this.state.currentClassification)}
                        locationStateCode={this.props.location?.address?.stateCode}
                        dotProp={`btisWcCustomInfo.classifications.${this.props.classifications.findIndex(x => x.guid === this.state.currentClassification)}.`}
                        backEvent={() => this.setState({ mode: `locationView`, currentClassification: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReduceParameter={this.props.doReduceParameter}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        doExecutePolicyAction={this.props.doExecutePolicyAction}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
            </React.Fragment>
        );
    }
}

export default ProfileBtisWcLocation;