import React from 'react';

import ProfileApplicantSales from './Sales';
import ProfileApplicantServicing from './Servicing';
import ProfileApplicantVitals from './Vitals';

class ProfilePolicy extends React.Component {
    componentDidMount() {
        this.props.doGetPolicy();
    }

    render() {
        return (
            <React.Fragment>
                <ProfileApplicantVitals {...this.props} />
                {/*<ProfileApplicantSales {...this.props} />*/}
                {/*<ProfileApplicantServicing {...this.props} />*/}
            </React.Fragment>
        );
    }
}

export default ProfilePolicy;