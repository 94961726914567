import { put, call, select } from 'redux-saga/effects';
import { reduceOperation, reduceLoading, getSessionSaga } from 'metrix-common';
import { reduceTooltipInfo } from "../../actions/policy/reduceTooltipInfo";
import { selectTooltipInfo } from "../../selectors/policy/selectTooltipInfo";

export function* upsertTooltipInfoSaga({ payload }) {
    try {
        yield put(reduceLoading({ code: 'upsertTooltipInfo', value: true }));
        let session = yield getSessionSaga();
        const tooltip = yield select(selectTooltipInfo());
        const response = yield call(fetch, `/api/product/${payload}/tooltip/upsert`,
            {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': session.authenticationToken.token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(tooltip)
            }
        );
        const result = yield call([response, 'json']);
        if (!result.operation.status) {
            yield put(reduceLoading({ code: 'upsertTooltipInfo', value: false }));
            return;
        }
        yield put(reduceTooltipInfo({ pages: result.pages }));
        yield put(reduceOperation(result.operation));
        yield put(reduceLoading({ code: 'upsertTooltipInfo', value: false }));
        return;
    }
    catch (error) {
        console.error("Error while Updating ToolTip Information - ", error);
        window.location = '/error';
        yield put(reduceLoading({ code: 'upsertTooltipInfo', value: false }));
        return;
    }
}
