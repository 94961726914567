import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    convertFromBoolean,
    convertToBoolean,
    setAttributeEntity,
    Sizes,
    Alignments,
    BackButton,
    Divider,
    Header,
    Form,
    FormDropDown,
    FormInput,
    FormRadio
} from 'metrix-common';

class ProfileDwellingAnimal extends React.Component {
    render() {
        return (
            <React.Fragment>
                <BackButton clickEvent={this.props.backEvent} />
                <Divider top={Sizes.md} />
                <Header size={Sizes.md} textAlign={Alignments.left} text={`Animal`} />
                <Form
                    label={`Animal Details`}
                    allowEdit={this.props.allowEdit}
                    saveEvent={() => this.props.doUpsertPolicy()}
                    cancelEvent={() => this.props.doGetPolicy()}
                >
                    <FormInput
                        label={<FormattedMessage id={`Number`} />}
                        value={this.props.animal &&
                            this.props.animal.number}
                        changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}number`, value })}
                        allowEdit
                    />
                    <FormDropDown
                        label={<FormattedMessage id={`Type`} />}
                        value={this.props.animal && this.props.animal.type}
                        references={this.props.reference.references && this.props.reference.references[`AnimalType`]}
                        referencesEvent={() => this.props.reference.doGetReferences(`AnimalType`)}
                        changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}type`, value, this.props.doReducePolicyElement)}
                        allowEdit
                    />
                    {
                        (this.props.animal && this.props.animal.type && this.props.animal.type.code === `Dog`) &&
                        <FormDropDown
                            label={<FormattedMessage id={`Breed`} />}
                            value={this.props.animal && this.props.animal.breed}
                            references={this.props.reference.references && this.props.reference.references[`DogBreed`]}
                            referencesEvent={() => this.props.reference.doGetReferences(`DogBreed`)}
                            changeEvent={(value) => setAttributeEntity(`${this.props.dotProp}breed`, value, this.props.doReducePolicyElement)}
                            allowEdit
                        />
                    }
                    {
                        (this.props.animal && this.props.animal.type && this.props.animal.type.code === `Dog`) &&
                            <FormRadio
                                label={<FormattedMessage id={`Bite History`} />}
                                value={this.props.animal &&
                                    { code: convertFromBoolean(this.props.animal.biteHistory) }}
                                references={this.props.reference.references && this.props.reference.references[`YesNo`]}
                                referencesEvent={() => this.props.reference.doGetReferences(`YesNo`)}
                                changeEvent={(value) => this.props.doReducePolicyElement({ code: `${this.props.dotProp}biteHistory`, value: convertToBoolean(value.code) })}
                                allowEdit
                            />
                    }
                </Form>
            </React.Fragment>
        );
    }
}

export default ProfileDwellingAnimal;