import { put, call, select } from 'redux-saga/effects';
import { reduceOperation, reduceLoading, selectConfiguration, getSessionSaga } from 'metrix-common';
import { reducePolicy } from '../../actions/policy/reducePolicy';
import { selectPolicy } from '../../selectors/policy/selectPolicy';
import { selectPolicySummary } from '../../selectors/policy/selectPolicySummary';
import { selectEntity } from '../../selectors/policy/selectEntity';
import { getPolicySummarySaga } from './getPolicySummarySaga';
import { reduceParameters } from '../../actions/policy/reduceParameters';
import { selectParameters } from '../../selectors/policy/selectParameters';

export function* executePolicyActionSaga(action) {
    try {
        yield put(reduceLoading({ code: 'executePolicyAction', value: true }));
        const session = yield getSessionSaga();
        let policy = yield select(selectPolicy());
        let parameters = yield select(selectParameters());
        
        if (!policy || !policy.guid) {
            const entity = yield select(selectEntity());
            if (!policy) {
                policy = {};
            }
            if (!policy.guid) {
                policy.guid = entity;
            }
        }
        
        const response = yield call(fetch, `/api/policyTransaction/action/${action.payload.code}`, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Accept': 'application/json',
                'Authorization': session.authenticationToken.token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                parameters,
                policyTransaction: policy
            })
        });
        
        const result = yield call([response, 'json']);
        yield put(reduceOperation(result.operation));
        
        if (result.operation.status) {
            yield put(reduceParameters(result.parameters && result.parameters.filter(x => x.code && !x.code.startsWith('Set.') && !x.code.startsWith('Delete.'))));
            yield put(reducePolicy(result.policyTransaction));
            
            if (action.payload.hooks && action.payload.hooks.length > 0) {
                yield action.payload.hooks.map((hook) => executeActionHook(hook, result.policyTransaction.guid));
            } else {
                const summary = yield select(selectPolicySummary());
                if (summary) {
                    yield getPolicySummarySaga();
                }
            }
        }
        
        yield put(reduceLoading({ code: 'executePolicyAction', value: false }));

        if (action.payload.code === 'GetQuote' || action.payload.code === 'ReQuote' || action.payload.code === 'ACCEPTQUOTE' || action.payload.code === 'RequestBind' || action.payload.code === 'ConfirmBind') {
            window.location.reload();
        }
        else if (action.payload.code === 'SN-COMMERCIAL.CREATEADDITIONALINSURED' || action.payload.code === 'SN-COMMERCIAL.DELETEADDITIONALINSURED' || action.payload.code === 'SN-COMMERCIAL.DELETEADDITIONALINSUREDS' ||
            action.payload.code === 'SN-COMMERCIAL.CREATELOSSPAYEE' || action.payload.code === 'SN-COMMERCIAL.DELETELOSSPAYEE' || action.payload.code === 'SN-COMMERCIAL.DELETELOSSPAYEES') {
            window.location.reload();
        }
    } catch (error) {
        console.log("Error while Executing Policy Action API - ", error);
        yield put(reduceLoading({ code: 'executePolicyAction', value: false }));
    }
}

function executeActionHook(hook, entity) {
    if (hook.code === 'redirection') {
        if (hook.application) {
            const configuration = select(selectConfiguration());
            window.location = `${configuration[hook.application]}/${hook.value}`;
        }
        else {
            window.location = `/${hook.value}?entity=${entity}`;
        }
    }
    else if (hook.code === 'reloadPage') {
        window.location.reload(false);
    }
}