import React from 'react';

import {
    Sizes,
    Alignments,
    Container,
    Divider,
    Header,
    SummaryGroup
} from 'metrix-common';

import Driver from './Driver';

class ProfileDrivers extends React.Component {
    state = {
        mode: `summary`,
        currentDriver: null
    };

    componentDidMount() {
        this.props.doGetPolicy();
    }

    getDrivers = () => {
        let drivers = [];

        if (this.props.policy &&
            this.props.policy.drivers &&
            this.props.policy.drivers.length > 0) {
            this.props.policy.drivers.forEach((driver) => {
                let option = { code: `driver`, value: driver.guid };

                let driverName = `Driver ${driver.number}`;

                if (driver.party &&
                    driver.party.name) {
                    driverName = `${driver.party.name.firstName} ${driver.party.name.lastName}`;
                }

                option.elements = [];

                option.elements.push({ code: `Label`, value: driverName });

                drivers.push(option);
            });
        }

        return drivers;
    }

    getDriverName = (driver) => {
        let driverName = null;

        if (!driver) {
            driver = this.state.currentDriver;
        }

        if (this.props.policy &&
            this.props.policy.drivers &&
            this.props.policy.drivers.some(x => x.guid === driver)) {
            let name = this.props.policy.drivers.find(x => x.guid === driver).name;

            if (name) {
                driverName = `${name.firstName} ${name.lastName}`;
            }
        }

        return driverName;
    }

    render() {
        let drivers = this.getDrivers();
        return (
            <Container>
                {this.state.mode === `summary` &&
                    <React.Fragment>
                        <Divider top={Sizes.xl} />
                        <Header size={Sizes.lg} textAlign={Alignments.left} text={`Drivers`} />
                        <Container top={Sizes.xl} bottom={Sizes.xl}>
                        <SummaryGroup
                            allowAdd={this.props.allowEdit}
                            addEvent={() => {
                                this.props.doReduceParameters([]);
                                this.props.doExecutePolicyAction({ code: `CREATEDRIVER` });
                            }}
                            allowEdit
                            editEvent={(driver) => {
                                this.setState({ currentDriver: driver, mode: `driver` });
                                this.props.doReduceParameters([{ code: `Driver`, value: driver }]);
                            }}
                            allowDelete={this.props.allowEdit}
                            deleteEvent={(driver) => {
                                this.props.doReduceParameters([{ code: `Driver`, value: driver }]);
                                this.props.doExecutePolicyAction({ code: `DELETEDRIVER` });
                            }}
                            entities={drivers}
                            icon={`person`}
                        />
                        </Container>
                    </React.Fragment>
                }
                {this.state.mode === `driver` &&
                    <Driver driver={this.props.policy.drivers.find(x => x.guid === this.state.currentDriver)}
                        dotProp={`drivers.${this.props.policy.drivers.findIndex(x => x.guid === this.state.currentDriver)}.`}
                        getDriverName={this.getDriverName}
                        backEvent={() => this.setState({ mode: `summary`, currentDriver: null })}
                        doUpsertPolicy={this.props.doUpsertPolicy}
                        doGetPolicy={this.props.doGetPolicy}
                        doReducePolicyElement={this.props.doReducePolicyElement}
                        doReduceParameter={this.props.doReduceParameter}
                        doExecutePolicyAction={this.props.doExecutePolicyAction}
                        reference={this.props.reference}
                        context={this.props.context}
                        allowEdit={this.props.allowEdit}
                    />
                }
            </Container>
        );
    }
}

export default ProfileDrivers;