import React from 'react';

import HighUpperScoreIcon from './HighUpper';
import HighLowerScoreIcon from './HighLower';
import MediumUpperScoreIcon from './MediumUpper';
import MediumLowerScoreIcon from './MediumLower';
import LowUpperScoreIcon from './LowUpper';
import LowLowerScoreIcon from './LowLower';

class InsurabilityScoreIcon extends React.Component {

    getIcon = (score) => {
        let icon = null;
        
        switch (true) {
            case (score < 0):
                icon = (<LowLowerScoreIcon score={0} />);
                break;
            case (score >= 0 && score <= 15):
                icon = (<LowLowerScoreIcon score={score} />);
                break;            
            case (score >= 16 && score <= 30):                
                icon = (<LowUpperScoreIcon score={score} />);
                break;
            case (score >= 31 && score <= 45):                
                icon = (<MediumLowerScoreIcon score={score} />);
                break;
            case (score >= 46 && score <= 60):                
                icon = (<MediumUpperScoreIcon score={score} />);
                break;
            case (score >= 61 && score <= 75):                
                icon = (<HighLowerScoreIcon score={score} />);
                break;
            case (score >= 76 && score <= 99):                
                icon = (<HighUpperScoreIcon score={score} />);
                break;            
            case (score >= 100):
                icon = (<HighUpperScoreIcon score={99} />);
                break;   
        }
        
        return icon;
    }

    render() {
        return (
            this.getIcon(this.props.score)
        );
    }
}

export default InsurabilityScoreIcon;
